import { styled } from 'styled-components'
import theme from 'styles/theme'

export const Wrapper = styled.main``

export const Content = styled.div`
  height: 100px;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 400;
  color: #e9e9f0;
  text-align: center;

  span {
    font-weight: 700;
    color: ${theme.COLORS.WHITE_2};
  }
`

export const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #e9e9f0;
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
`
