import React from 'react'
import { ConfigProvider } from 'antd'

const AntProvider = ({ children }: { children: React.ReactNode }) => (
  <ConfigProvider
    theme={{
      components: {
        Input: {
          activeBorderColor: '##fcc100',
          colorPrimary: '#fcc100',
          colorPrimaryHover: '#fcc100',
          borderRadius: 8,
          borderRadiusLG: 4,
          borderRadiusSM: 6,
        },
        Select: {
          borderRadius: 8,
          borderRadiusLG: 4,
          borderRadiusSM: 6,
          optionSelectedBg: '#fcc100',
          optionActiveBg: '#FFC000',
        },
        Menu: {
          itemHoverBg: 'red',
          darkItemHoverColor: 'yellow',
          darkItemSelectedColor: 'green',
        },
        Tabs: {
          inkBarColor: 'rgb(255, 192, 0)',
          itemSelectedColor: 'rgb(255, 192, 0)',
          itemColor: 'rgb(233, 233, 240)',
          colorBorderSecondary: 'rgb(46, 46, 46)',
          fontSize: 12,
          lineWidth: 2,
        },
        Modal: {
          titleColor: 'rgba(226, 226, 226, 0.88)',
          headerBg: 'rgba(255, 255, 255, 0)',
          footerBg: 'rgba(0, 0, 0, 0)',
          contentBg: 'rgb(22, 23, 23)',
          boxShadow: '',
          colorIcon: 'rgb(250, 173, 20)',
          colorIconHover: 'rgba(250, 173, 20, 0.82)',
        },
        Switch: {
          colorPrimary: 'rgb(48, 35, 1)',
          colorPrimaryHover: 'rgb(48, 35, 1)',
          handleBg: 'rgb(255, 192, 0)',
        },
        DatePicker: {
          activeBorderColor: 'rgb(231, 150, 0)',
          hoverBorderColor: 'rgb(250, 173, 20)',
          activeBg: 'rgb(10, 10, 10)',
          hoverBg: 'rgb(10, 10, 10)',
          colorBgContainer: 'rgb(10, 10, 10)',
          colorTextPlaceholder: 'rgba(255, 255, 255, 0.25)',
          colorText: 'rgba(249, 249, 249, 0.88)',
          colorPrimary: 'rgb(250, 173, 20)',
          colorPrimaryBorder: 'rgb(250, 173, 20)',
          colorBorder: 'rgb(233, 233, 240)',
          colorBgElevated: 'rgb(17, 17, 17)',
          controlItemBgActive: 'rgb(255, 249, 230)',
          cellRangeBorderColor: 'rgb(250, 173, 20)',
          cellHoverWithRangeBg: 'rgb(250, 173, 20)',
          cellActiveWithRangeBg: 'rgb(250, 173, 20)',
          colorTextHeading: 'rgba(242, 240, 240, 0.88)',
          colorIcon: 'rgb(250, 173, 20)',
          colorIconHover: 'rgb(250, 140, 22)',
          colorTextTertiary: 'rgba(231, 231, 231, 0.45)',
          colorTextDisabled: 'rgba(206, 203, 203, 0.25)',
        },
      },
    }}
  >
    {children}
  </ConfigProvider>
)

export default AntProvider
