import styled from 'styled-components'

export const Row = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  border-left: 5px solid ${props => props.borderColor || 'transparent'};
  height: 32px;
  width: 100%;
  background-color: ${props => (props.index % 2 === 0 ? '#0A0A0A' : '#111')};
  padding: 8px;

  img {
    max-width: 16px;
  }

  .text-item {
    min-width: 130px;
    padding: 0 8px;
  }

  .text {
    width: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    gap: 8px;
    margin-top: 4px;
  }

  .values {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .wrapper-progress {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .percent {
    width: 50px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 8px;
    margin-top: 4px;
  }
`
export const ProgressContainer = styled.div``
export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
