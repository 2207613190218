import { faker } from '@faker-js/faker'
import { Political } from '../types/mentionsServiceTypes'

const generateFakeData = () => {
  const fakePolitica: Political = {
    name: faker.person.fullName(),
    withProfile: faker.datatype.boolean(),
    themes: {
      positive: [
        {
          name: faker.company.buzzVerb(),
          is_active: faker.datatype.boolean(),
          is_recommended: faker.datatype.boolean(0.3),
          is_positive: faker.datatype.boolean(0.9),
        },
      ],
      negative: [
        {
          name: faker.company.buzzVerb(),
          is_active: faker.datatype.boolean(),
          is_recommended: faker.datatype.boolean(0.3),
          is_positive: faker.datatype.boolean(0.1),
        },
      ],
    },
  }

  return fakePolitica
}

export const MOCK_PESTAL = Array.from({ length: 5 }, () => generateFakeData())
