import styled from 'styled-components'

export const VotingDataContainer = styled.div`
  background-color: #161717;
  width: 36rem;
  height: 44rem;
  border-radius: 8px;
  color: white;
  padding: 1rem;
`
export const VotesDisplay = styled.div`
  position: relative;
  font-size: 2.5rem;
  font-weight: 900;
  font-family: Roboto;
  color: #e9e9f0;
  text-align: center;
  margin-top: -15rem;
  margin-bottom: 1.25rem;
`
export const TotalVotesDisplay = styled.div`
  position: absolute;
  font-size: 1rem;
  font-weight: 900;
  font-family: Roboto;
  color: #e9e9f0;
  text-align: center;
  width: 1.87rem;
  height: 1.87rem;
  top: 42.52rem;
  left: 84.11rem;
  transform: translate(-50%, -50%) translateY(-11.25rem);
  z-index: 1;
`
export const Mark9Display = styled.div`
  position: absolute;
  font-size: 1rem;
  font-weight: 900;
  color: #e9e9f0;
  text-align: center;
  width: 1.87rem;
  height: 1.87rem;
  top: 48.5%;
  left: 65%;
  transform: translate(-50%, -50%);
  z-index: 1;
`
export const Mark16Display = styled.div`
  position: absolute;
  font-size: 1rem;
  font-weight: 900;
  color: #e9e9f0;
  text-align: center;
  width: 1.87rem;
  height: 1.87rem;
  top: 40.5%;
  left: 72%;
  transform: translate(-50%, -50%);
  z-index: 1;
`
export const Mark22Display = styled.div`
  position: absolute;
  font-size: 1rem;
  font-weight: 900;
  color: #e9e9f0;
  text-align: center;
  width: 1.87rem;
  height: 1.87rem;
  top: 42.5%;
  left: 79%;
  transform: translate(-50%, -50%);
  z-index: 1;
`
export const Mark25Display = styled.div`
  position: absolute;
  font-size: 1rem;
  font-weight: 900;
  color: #e9e9f0;
  text-align: center;
  width: 1.87rem;
  height: 1.87rem;
  top: 46%;
  left: 82%;
  transform: translate(-50%, -50%);
  z-index: 1;
`
export const StartVotesDisplay = styled.div`
  position: absolute;
  font-size: 1rem;
  font-weight: 900;
  font-family: Roboto;
  color: #e9e9f0;
  text-align: center;
  width: 1.87rem;
  height: 1.87rem;
  top: 42.52rem;
  left: 62.86rem;
  transform: translate(-50%, -50%) translateY(-180px);
  z-index: 1;
`
export const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  text-align: left;
  font-family: 'Roboto';
  color: #e9e9f0;
`
export const MetricContainer = styled.div`
  background-color: #161717;
  border-radius: 8px;
  border: 1px solid #4f4f4f;
  padding: 0.62rem;
  margin-bottom: 0.62rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`
export const ProgressBarRitmo = styled.progress`
  width: 100%;
  height: 1.25rem;
  -webkit-appearance: none;
  appearance: none;
  &::-webkit-progress-bar {
    background-color: #161717;
    border-radius: 0.62rem;
  }
  &::-webkit-progress-value {
    background-color: #00e23f;
    border-radius: 0.62rem;
  }
  &::-moz-progress-bar {
    background-color: #00e23f;
    border-radius: 0.62rem;
  }
`
export const ProgressBarMeta = styled.progress`
  width: 100%;
  height: 1.25rem;
  -webkit-appearance: none;
  appearance: none;
  &::-webkit-progress-bar {
    background-color: #333;
    border-radius: 0.62rem;
  }
  &::-webkit-progress-value {
    background-color: #e9e9f0;
    border-radius: 0.62rem;
  }
  &::-moz-progress-bar {
    background-color: #e9e9f0;
    border-radius: 0.62rem;
  }
`
export const WrapperData = styled.div`
  display: flex;
  flex-direction: column;
`
export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 23px;

  .date_picker {
    flex: 1;
  }

  .date-box-input {
    display: flex;
    justify-content: start;
    flex-direction: column;
  }
`
export const DateLabel = styled.div`
  font-size: 0.75rem;
  color: #ccc;
  margin-bottom: 0.3rem;
`
export const DateInput = styled.input`
  background-color: #161717;
  border: 1px solid #4f4f4f;
  color: #cecece;
  padding: 0.3rem;
  border-radius: 4px;
  font-size: 1.12rem;
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  font-family: 'Roboto';
`
export const MetricsAndDateContainer = styled.div`
  margin-top: 2.25rem;
`
export const VotosValidosRoot = styled.div`
  font-size: 0.75rem;
  font-weight: 300;
  font-family: Roboto;
  color: #e9e9f0;
  text-align: left;
  display: inline-block;
  width: 14.56rem;
  margin-bottom: 0.3rem;
`
export const VectorLine = styled.div`
  position: absolute;
  width: 29.44rem;
  background-color: #e9e9f0;
  top: 33.37rem;
  left: 50.56rem;
`
export const RitmoValueDisplay = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  font-family: Roboto;
  color: #00e23f;
  text-align: left;
  display: inline-block;
  width: 1.25rem;
  position: absolute;
  left: calc(96% - 9.37rem);
  top: calc(50% + 12.5rem);
  transform: translateY(-50%);
  z-index: 2;
`
export const Metas1ValueDisplay = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  font-family: Roboto;
  color: #e9e9f0;
  text-align: left;
  display: inline-block;
  width: 1.25rem;
  position: absolute;
  left: calc(96% - 9.37rem);
  top: calc(56% + 12.5rem);
  transform: translateY(-50%);
  z-index: 2;
`
export const ProjValueDisplay = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  font-family: Roboto;
  color: #00e23f;
  text-align: left;
  display: inline-block;
  width: 1.25rem;
  position: absolute;
  left: calc(95.7% - 9.37rem);
  top: calc(69% + 12.5rem);
  transform: translateY(-50%);
  z-index: 2;
`
export const Metas2ValueDisplay = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  font-family: Roboto;
  color: #e9e9f0;
  text-align: left;
  display: inline-block;
  width: 1.25rem;
  position: absolute;
  left: calc(95.7% - 9.37rem);
  top: calc(75.5% + 12.5rem);
  transform: translateY(-50%);
  z-index: 2;
`
export const Line = styled.div`
  border-bottom: 1px solid #4f4f4f;
  margin-bottom: 0.3rem;
`

export const GaugeBox = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  margin-top: 4px;
  align-items: center;
`
