import React, { useEffect, useState } from "react";
import TabsCustom from "componentsNew/Tabs";
import { UnderConstruction } from "componentsNew/UnderConstruction";
import Spin from "componentsNew/Spin";
import PBIDash from "componentsNew/PBIDash";
import { useReport } from "global/ReportContext";
import { useTab } from "global/TabContext";
import { useDiagnosisFilter } from "global/DiagnosisFilterContext";
import { useCandidates } from "hooks/useCandidates";
import * as S from "./styles";

const labels = [
  {
    label: "Resultado das",
    sub_label: "Eleições",
    key: "tab_election_diagnosis_result",
  },
  {
    label: "Evolução de",
    sub_label: "Candidaturas",
    key: "tab_election_diagnosis_evolution_of_applications",
  },
  {
    label: "Perfil de",
    sub_label: "Candidatos",
    key: "tab_election_diagnosis_candidates_profile",
    disabled: true,
  },
  {
    label: "Raio-X",
    sub_label: "Campanhas",
    key: "tab_election_diagnosis_x_ray_campaigns",
  },
  {
    label: "Raio-X",
    sub_label: "Doações",
    key: "tab_election_diagnosis_x_ray_donations",
    disabled: true,
  },
  {
    label: "Comparativo Histórico de",
    sub_label: "Eleições",
    key: "tab_election_diagnosis_historical_comparison",
    disabled: true,
  },
  {
    label: "Painel de",
    sub_label: "Campanhas",
    key: "tab_election_diagnosis_campaigns_dashboard",
  },
];

type ReportNamesType = {
  [key in (typeof labels)[number]["key"]]: string;
};

const reportNames: ReportNamesType = {
  tab_election_diagnosis_result: "election_results",
  tab_election_diagnosis_evolution_of_applications: "election_history",
  tab_election_diagnosis_candidates_profile: "",
  tab_election_diagnosis_x_ray_campaigns: "x_ray_campaigns",
  tab_election_diagnosis_x_ray_donations: "",
  tab_election_diagnosis_historical_comparison: "",
  tab_election_diagnosis_campaigns_dashboard: "campaing_panel",
};

const content: React.ReactNode[] = [
  <PBIDash reportName="election_results" />,
  <PBIDash reportName="election_history" />,
  <UnderConstruction />,
  <PBIDash reportName="x_ray_campaigns" />,
  <UnderConstruction />,
  <UnderConstruction />,
  <PBIDash reportName="campaing_panel" />,
];

export const ElectionDiagnosis = () => {
  const [activeTab, setActiveTab] = useState("tab_election_diagnosis_result");
  const { isLoadedCandidates } = useCandidates();
  const { setReportName } = useReport();
  const { setActiveTabContext } = useTab();
  const { filters } = useDiagnosisFilter();
  const [filterKey, setFilterKey] = useState(Date.now()); // Chave única para PBIDash

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  useEffect(() => {
    setReportName(reportNames[activeTab]);
  }, [activeTab, setReportName]);

  useEffect(() => {
    setFilterKey(Date.now());
  }, [filters]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  const changePoliticalTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={content.map((comp, index) =>
            React.cloneElement(comp as React.ReactElement, {
              key: `${filterKey}-${index}`,
            }),
          )}
          onChange={(key) => changePoliticalTab(key)}
        />
      </S.Content>
    </S.Wrapper>
  );
};
