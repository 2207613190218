import React from "react";
import { PersonalData, Biography } from "pages/SettingsProfile/components";
import SocialLinks from "pages/SettingsProfile/components/SocialLinks/SocialLinks";
import usePlanType from "pages/SettingsProfile/hooks/usePlanType";
import useAdvisor from "pages/SettingsProfile/hooks/useAdvisor";

export const PersonalDataPage: React.FC = () => {
  const planType = usePlanType();
  const { isAdvisor, idAdvisor } = useAdvisor();

  return (
    <div style={{ gap: "16px" }}>
      <div style={{ marginBottom: "16px" }}>
        <PersonalData />
      </div>
      {planType === "Advisor" && isAdvisor && idAdvisor && (
        <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
          <Biography idAdvisor={idAdvisor} />
          <SocialLinks idAdvisor={idAdvisor} />
        </div>
      )}
    </div>
  );
};
