import { faker } from '@faker-js/faker'
import { ThemeResult } from '../services/types/macroEnvServicesTypes'

export const TOPIC_NAMES = ['Política', 'Economia', 'Tecnologia', 'Ambiental', 'Legal', 'Social']

const generateRandomSelectOption = (topic_name: string): any => {
  const theme_name = faker.animal.dog()
  return {
    id: faker.number.int({ max: 50 }),
    topic: faker.number.int(),
    name: theme_name,
    theme_name,
    created: faker.date.anytime(),
    updated: faker.date.anytime(),
    label: topic_name,
    value: faker.number.int({ max: 100 }),
    topic_name,
    active: false,
  }
}

export const MOCK_SELECT_LIST: ReturnType<typeof generateRandomSelectOption>[] = TOPIC_NAMES.map(topic =>
  generateRandomSelectOption(topic)
)

const generateRandomMockSelectionPositiveItem = (
  topic: string
): {
  id: number
  topic: string
  topic_name: string
  selection_list: ThemeResult[]
  selected_values: ThemeResult[]
  theme_list: ThemeResult[]
} => {
  const id = faker.number.int()
  return {
    id,
    topic,
    topic_name: topic,
    selection_list: Array.from({ length: 10 }).map(() => generateRandomSelectOption(topic)),
    selected_values: [],
    theme_list: Array.from({ length: 8 }),
  }
}

export const MOCK_SELECTION_POSITIVE: ReturnType<typeof generateRandomMockSelectionPositiveItem>[] = TOPIC_NAMES.map(
  topic => generateRandomMockSelectionPositiveItem(topic)
)

export const MOCK_SELECTION_NEGATIVE: ReturnType<typeof generateRandomMockSelectionPositiveItem>[] = TOPIC_NAMES.map(
  topic => generateRandomMockSelectionPositiveItem(topic)
)
