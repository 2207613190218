import React from 'react'
import styled from 'styled-components'
import { IoIosLock } from 'react-icons/io'
import Text from 'componentsNew/Text'

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.92);
  display: flex;
  justify-content: start;
  align-items: center;
  z-index: 77000;
  color: white;
  font-size: 24px;
  flex-direction: column;
  padding-top: 140px;
`

const LockIcon = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  svg {
    width: 100%;
    height: 100%;
    color: yellow;
  }
`

const OverlayMessage = () => {
  return (
    <Overlay>
      <LockIcon>
        <IoIosLock />
      </LockIcon>
      <Text size="medium" color="yellow">
        Em breve
      </Text>
    </Overlay>
  )
}

export default OverlayMessage
