import { useEffect } from 'react'
import L from 'leaflet'
import 'leaflet.heat'
import 'leaflet/dist/leaflet.css'
import { MapContainer } from '../styles'
import gerarIDFake from "../../../utils/generateUniqId";

type MapData = {
  total_votes: number
  longitude: number
  latitude: number
  name: string
}

interface Props {
  mapData: MapData[]
  height?: string
  titleCard?: string | null
}

const Map = ({ mapData, height, titleCard = 'Votos' }: Props) => {
  const uuid = gerarIDFake()

  useEffect(() => {
    if (!mapData || !Array.isArray(mapData)) return

    const map = L?.map(uuid)?.setView([-15.7801, -47.9292], 4)

    L?.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
    }).addTo(map)

    const filteredMapData = mapData.filter(item => item && item.latitude && item.longitude && item.total_votes)

    const heatData = filteredMapData.map(item => [item?.latitude, item?.longitude, item?.total_votes])

    // @ts-ignore
    const heatLayer = L?.heatLayer(heatData, {
      radius: 6,
      blur: 5,
      minOpacity: 1,
      gradient: {
        0.4: '#7dadff',
        0.8: '#005fff',
        1.0: '#00348f',
      },
      scaleRadius: false,
    }).addTo(map)

    const bounds = L.latLngBounds(filteredMapData.map(item => [item.latitude, item.longitude]))

    if (bounds.isValid()) {
      map.fitBounds(bounds)
    }

    mapData?.forEach(point => {
      const content = `
        <div>
          <strong>${point?.name}</strong><br />
          ${titleCard}: ${point?.total_votes}
        </div>
      `

      const marker = L?.marker([point.latitude, point.longitude], {
        opacity: 0,
      }).addTo(map)

      bounds?.extend(marker.getLatLng())

      marker?.bindTooltip(content, {
        permanent: false,
        direction: 'auto',
      })

      marker?.on('click', () => {
        console.log('clicked,', point)
      })
    })

    if (bounds?.isValid()) {
      map?.fitBounds(bounds)
    }

    // eslint-disable-next-line consistent-return
    return () => {
      map.remove()
    }
  }, [mapData, height, titleCard])

  return <MapContainer id={uuid} height={height} />
}

export default Map
