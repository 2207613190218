import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import Button from "componentsNew/Button";
import ModalSubMenu from "componentsNew/ModalSubMenu";
import SelectCandidatesCustom from "componentsNew/SelectCandidatesCustom";
import avatarDefault from "assets/avatar-default.png";
import { AUTH_TOKEN } from "utils/constants";
import { deleteItemStorage } from "utils/persistenceUtils";
import Text from "../Text";
import ButtonSibBar from "../ButtonSidBar";
import Avatars from "../index";
import { useUser } from "../../global/UserContext";
import { useSidBar } from "../../hooks/useSideBar";
import { useCandidates } from "../../hooks/useCandidates";
import { useDrawerState } from "global/DrawerContext";

import sibBarMenu from "./sibBarMenu";
import { ButtonToggle, Container, WrapperFooter, WrapperMenu } from "./styles";
import usePingOnline from "microfrontends/hooks/usePingOnline";

const SidBar = () => {
  const history = useNavigate();
  const { pathname } = useLocation();
  const { open, setOpen } = useSidBar();
  const { user } = useUser();
  const { setActivePlanFeatures } = useDrawerState();
  const userPlan = user?.person?.plan;
  const { candidates, selectedCandidate, setSelectedCandidate } =
    useCandidates();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(true);
  const [activeSubMenuTitle, setActiveSubMenuTitle] = useState<string | null>(
    null,
  );

  let selectedMenu = sibBarMenu;

  let userPlanDescription = "";

  let planFeatures = [];

  try {
    const planObject = localStorage.getItem("planFeatures");
    planFeatures = planObject ? JSON.parse(planObject) : [];

    userPlanDescription = planFeatures?.name;

    const userPlanNamesFeatures = planFeatures?.features_with_limits?.map(
      (features_with_limit: any) => {
        return features_with_limit.feature.name;
      },
    );
    const userPlanDescriptionFeatures = planFeatures?.features_with_limits?.map(
      (features_with_limit: any) => {
        return features_with_limit.feature.description;
      },
    );

    let featuresResult = sibBarMenu.filter(
      (menu) =>
        userPlanNamesFeatures.includes(menu.title) ||
        userPlanDescriptionFeatures.includes(menu.title),
    );

    for (let i = 0; i < featuresResult.length; i++) {
      let subMenu = featuresResult[i].subMenu;
      if (subMenu && subMenu.length > 0) {
        featuresResult[i].subMenu = subMenu.filter(
          (subMenu) =>
            userPlanNamesFeatures.includes(subMenu.title) ||
            userPlanDescriptionFeatures.includes(subMenu.title),
        );
      }
    }

    selectedMenu = featuresResult.length > 0 ? featuresResult : sibBarMenu;
  } catch (error) {
    console.error("Error on get plan features", error);
  }

  useEffect(() => {
    const matchingMenu = selectedMenu.find((menu) =>
      menu.subMenu?.some((sub) => pathname.startsWith(sub.path)),
    );
    if (matchingMenu && matchingMenu.title !== "Cockpit") {
      setActiveSubMenuTitle(matchingMenu.title);
      setIsSubMenuOpen(true);
    } else {
      setActiveSubMenuTitle(null);
      setIsSubMenuOpen(false);
    }
    setActivePlanFeatures(planFeatures);
  }, [pathname]);

  const isCandidateOptionsRoute = (pathname: any) => {
    const baseRoutes = [
      "/dashboard/political-vox",
      "/dashboard/sponsored-applications",
      "/dashboard/all-applications",
    ];

    return baseRoutes.some((baseRoute) => pathname.startsWith(baseRoute));
  };

  const candidateOptions = isCandidateOptionsRoute(pathname) && user?.person;

  const selectedAvatar = candidateOptions?.image_url || avatarDefault;

  usePingOnline();

  const logout = () => {
    deleteItemStorage(AUTH_TOKEN);
    history("/login");
  };

  const memoizedCallback = useCallback(() => {
    return (
      <Avatars
        img={candidateOptions ? selectedAvatar : selectedCandidate?.image_url}
        size={{ width: open ? 56 : 32, height: open ? 56 : 32 }}
      />
    );
  }, [selectedCandidate, open]);

  return (
    <Container open={open}>
      <WrapperMenu>
        <ButtonToggle open={open} onClick={() => setOpen(!open)}>
          {open ? <FaAngleLeft /> : <FaAngleRight />}
        </ButtonToggle>
        <div className="avatar">
          {open && (
            <Text size="small">
              {candidateOptions ? "Usuário" : "Candidato"}:
            </Text>
          )}
          {memoizedCallback()}
          {open && <SelectCandidatesCustom />}
        </div>

        {selectedMenu.map((menu: any) => {
          return (
            <ButtonSibBar
              label={menu?.label}
              path={menu.path}
              title={menu.title}
              icon={menu.icon}
              isActive={menu.isActive}
              subMenu={menu.subMenu}
              key={menu.title}
            />
          );
        })}
      </WrapperMenu>
      {isSubMenuOpen && activeSubMenuTitle && (
        <ModalSubMenu
          subMenu={
            selectedMenu.find((menu) => menu.title === activeSubMenuTitle)
              ?.subMenu || []
          }
          title={activeSubMenuTitle}
          closeSubMenu={() => setIsSubMenuOpen(false)}
        />
      )}
      <WrapperFooter>
        {open && (
          <>
            {!candidateOptions && (
              <div className="user">
                <Text size="small">Usuário logado:</Text>
                <Text size="small" bold>
                  {user?.person?.name}
                </Text>
              </div>
            )}

            <div className="plan">
              <Text size="small">
                Plano: <b>{userPlanDescription}</b>
              </Text>
              <Button size="small" variant="minimal" fullWidth>
                Alterar Plano
              </Button>
            </div>
          </>
        )}
        <button type="button" onClick={logout}>
          <Text bold color="yellow" size="small">
            Sair
          </Text>
        </button>
      </WrapperFooter>
    </Container>
  );
};

export default SidBar;
