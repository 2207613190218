import { css, styled } from 'styled-components'
import { readableColor } from 'polished'
import { InternalButtonProps } from '.'

export type WrapperProps = {
  hasIcon: boolean
  textColor?: string
} & Pick<InternalButtonProps, 'size' | 'variant' | 'background'>

const wrapperModifiers = {
  outline: (background?: string) => css`
    border: 1px solid ${background || '#ffc000'};
    background: transparent;
    color: ${background || '#ffc000'};
  `,
  minimal: (background?: string) => css`
    border: none;
    background: transparent;
    color: #ffc000;
    span {
      position: relative;
      text-decoration: none;
      color: #ffc000;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        background-color: #ffc000;
        transition: width 0.3s ease;
      }

      &::before {
        left: 50%;
        transform: translateX(-50%);
      }

      &::after {
        right: 50%;
        transform: translateX(50%);
      }

      &:hover::before,
      &:hover::after {
        width: 100%;
      }
    }
  `,
  primary: (background?: string) => css`
    background: ${background || '#ffc000'};
  `,

  withBackground: (background: string) => {
    return css`
      border-color: ${background};
      background: ${background};
      color: ${readableColor(background)};
    `
  },
}

export const Wrapper = styled.button<WrapperProps>`
  ${({ textColor, variant, background }) => css`
    all: unset;
    font-size: 14px;
    height: 21px;
    min-width: 68px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-family: 'Roboto', sans-serif;
    padding: 0px 8px 0px 8px;
    cursor: pointer;
    width: fit-content;
    color: ${textColor || '#fff'};

    span {
      width: max-content;
    }

    ${!!variant && wrapperModifiers[variant](background)};

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
      background: #595959;
      color: white;

      svg {
        color: white !important;
      }

      &:hover {
        background: #595959;
        opacity: 0.7;
      }
    }
  `}
`
