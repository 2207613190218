import { DatePicker } from 'antd'
import styled from 'styled-components'


export const ContainerInput = styled(DatePicker)`
background: transparent ;

&:hover {
background: transparent ;
  border-color: rgb(250, 173, 20);
}
/*   & .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
    transform: translate(14px, 8px) scale(1);
  }
  & .MuiInputLabel-root {
    color: transparent; // Cor da label
  }
  & .MuiInputLabel-root.Mui-focused {
    color: #cecece; // Cor da label quando focado
  }
  & .MuiInputBase-root {
    color: #cecece; // Cor do texto dentro do input
  }
  & .MuiInputBase-input {
    height: 10px;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #cccccc; // Cor padrão da borda
    }
    &:hover fieldset {
      border-color: white; // Cor da borda ao passar o mouse
    }
    &.Mui-focused fieldset {
      border-color: #cecece; // Removendo a cor da borda ao focar
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #cccccc; // Cor padrão da borda
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #999999; // Cor da borda ao passar o mouse
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent; // Removendo a cor da borda ao focar
  }
  & .MuiSvgIcon-root {
    // Ícone do DatePicker
    fill: white; // Pode definir a cor do ícone aqui
  } */
`
