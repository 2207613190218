import noop from 'lodash/noop'
import { Empty } from 'componentsNew/Empty'
import { formatLocation } from 'componentsNew/PoliticalVox/services/advancedFilters'
import { Tooltip } from 'antd'
import Spin from 'componentsNew/Spin'

import * as S from './styles'
import ALL_STATE_LIST from "../../utils/location";

interface FilterBarDataProps {
  data?: { region?: string; count: number; label: string; value?: string | number; city?: string }[]
  title?: string
  handleClickBar?: (value: string, label: string) => void
  valueProperty?: 'label' | 'value'
  selectedBar?: string
  loading?: boolean
  type?: string
}

export const FilterBar = ({
  data = [],
  title,
  handleClickBar = noop,
  valueProperty = 'label',
  selectedBar = '',
  loading,
  type,
}: FilterBarDataProps) => {
  const handleFilters = (label: string, region?: string, value?: any) => {
    const valueProp = valueProperty === 'label' ? label : value
    let newValue = valueProp

    if (selectedBar === valueProp || selectedBar === label) {
      newValue = ''
    }

    if (region) {
      const isRegion = ALL_STATE_LIST.find(item => item.nome === region)
      const newCity = formatLocation({ state: isRegion?.codigo || '', city: newValue })
      handleClickBar(newCity, label)
    } else {
      handleClickBar(newValue, label)
    }
  }

  const sortedData = data.length > 0 ? data.sort((a, b) => b.count - a.count) : []

  const transformedData = sortedData.map(item => ({
    ...item,
    transformedCount: Math.sqrt(item.count),
  }))

  const maxTransformedCount = Math.max(...transformedData.map(item => item.transformedCount))

  return (
    <S.Wrapper>
      <S.Heading>{title}</S.Heading>
      <S.WrapperBars>
        {loading ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: '1', height: '100%' }}>
            <Spin />
          </div>
        ) : (
          <>
            {data.length === 0 ? (
              <S.EmptyBox>
                <Empty />
              </S.EmptyBox>
            ) : (
              transformedData.map((item, index) => {
                const percent = (item.transformedCount / maxTransformedCount) * 100
                const city = selectedBar?.split('.')?.slice(2)?.join('.')
                const isRegion = type === 'locality' && item?.city === city
                const selectedValue = isRegion || selectedBar === item.label || selectedBar === item?.value
                return (
                  <S.ContentBar
                    key={`${item.count + index}_${item.label}`}
                    onClick={() => handleFilters(item.label, item?.region, item?.value)}
                  >
                    <Tooltip title={item.label}>
                      <S.Label>{item.label}</S.Label>
                    </Tooltip>
                    <S.ProgressBar
                      initial={{ width: 0 }}
                      animate={{ width: `${percent}%` }}
                      transition={{ duration: 0.2 }}
                      style={{ height: '100%', backgroundColor: selectedValue ? 'rgb(188 95 0)' : '#947003' }}
                    >
                      <S.ProgressLabel>{item.count}</S.ProgressLabel>
                    </S.ProgressBar>
                  </S.ContentBar>
                )
              })
            )}
          </>
        )}
      </S.WrapperBars>
    </S.Wrapper>
  )
}
