import { useState } from 'react'
import { toast } from 'react-toastify'
import {
  getPartyId,
  getStates,
  getCitiesIdUf,
  getCandsIdUfCity,
  transformCands,
} from '../services/allApplicationsServices'
import {
  TransformedCandsData,
  TransformedCityData,
  TransformedPartyData,
  TransformedStateData,
} from '../types/allApplicationsTypes'

const usePartyData = () => {
  const [partyData, setPartyData] = useState<TransformedPartyData[] | null>(null)
  const [statesData, setStatesData] = useState<TransformedStateData[]>([])
  const [citiesData, setCitiesData] = useState<{ [key: string]: TransformedCityData[] }>({})
  const [candidatesData, setCandidatesData] = useState<{ [key: string]: TransformedCandsData }>({})
  const [loading, setLoading] = useState(false)

  const fetchParty = async (partyId: number) => {
    setLoading(true)
    try {
      const data = await getPartyId(partyId)
      if (!('message' in data)) {
        setPartyData(data)
      } else {
        toast.error('Erro em buscar os partidos')
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const fetchStates = async (partyId: number) => {
    setLoading(true)
    try {
      const data = await getStates(partyId)
      if (!('message' in data)) {
        setStatesData(data)
      } else {
        toast.error('Erro em buscar os estados')
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const fetchCities = async (partyId: number, stateAcronym: string) => {
    setLoading(true)
    try {
      const data = await getCitiesIdUf(partyId, stateAcronym)
      if (!('message' in data)) {
        setCitiesData((prev: any) => ({ ...prev, [stateAcronym]: data }))
      } else {
        toast.error('Erro em buscar as cidades')
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const fetchCandidates = async (partyId: number, stateAcronym: string, city: string) => {
    setLoading(true)
    try {
      const data = await getCandsIdUfCity(partyId, stateAcronym, city)
      if (!('message' in data)) {
        const key = `${stateAcronym}-${city}`
        const transformedData = transformCands(data)
        setCandidatesData((prev: any) => ({
          ...prev,
          [`${stateAcronym}-${city}`]: transformedData,
        }))
      } else {
        toast.error('Erro em buscar os candidatos do partido')
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return {
    partyData,
    statesData,
    citiesData,
    candidatesData,
    loading,
    fetchParty,
    fetchStates,
    fetchCities,
    fetchCandidates,
  }
}

export default usePartyData
