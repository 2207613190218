import styled from 'styled-components'

export const Container = styled.div<any>`
  display: flex;
  width: 100%;
  text-align: left;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  .container-progress {
    display: flex;
    align-items: center;
  }

  .key-feature-card-title {
    width: 160px;
  }

  .key-feature-card-sub {
    width: 200px;
  }

  .key-feature-card-porcent {
    text-align: right;
    width: 60px;
  }
`
