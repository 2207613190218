import styled, { css, DefaultTheme } from 'styled-components'

interface IAvatar {
  size: 'small' | 'medium' | 'large'
}

const wrapperModifiers = {
  small: (theme: DefaultTheme) => css`
    width: 24px;
    height: 24px;
  `,
  medium: (theme: DefaultTheme) => css`
    width: 40px;
    height: 40px;
  `,
  large: (theme: DefaultTheme) => css`
    width: 60px;
    height: 60px;
  `,
}
export const Container = styled.div<IAvatar>`
  ${({ size }) => wrapperModifiers[size]};
  border-radius: 50%;
  overflow: hidden;
  background: #333;
  img {
    width: 100%;
    object-fit: cover;
  }
`
