import React from 'react'

import type { MenuProps } from 'antd'
import { Dropdown, Space } from 'antd'
import Checkbox from 'componentsNew/CheckBox'
import * as S from './styles'

interface ButtonDropdownProps {
  children: React.ReactNode
  items: MenuProps['items']
}

const defaultItems: MenuProps['items'] = [
  {
    key: '1',
    label: (
      <S.RenderItem onClick={domEvent => domEvent.stopPropagation()}>
        <Checkbox label="Item 1" />
      </S.RenderItem>
    ),
  },
  {
    key: '2',
    label: (
      <S.RenderItem>
        <Checkbox label="Item 2" />
      </S.RenderItem>
    ),
  },
  {
    key: '3',
    label: (
      <S.RenderItem>
        <Checkbox label="Item 3" />
      </S.RenderItem>
    ),
  },
]

const stylesMenu = {
  background: '#161717',

  fontSize: '16px',
  fontWeight: '400',
}

const ButtonDropdown = ({ children, items = defaultItems }: ButtonDropdownProps) => (
  <Dropdown trigger={['click']} menu={{ items, style: stylesMenu }}>
    <Space>{children}</Space>
  </Dropdown>
)

export {stylesMenu};

export default ButtonDropdown
