import {
  MOCK_SELECTION_NEGATIVE,
  MOCK_SELECTION_POSITIVE,
  MOCK_SELECT_LIST,
} from 'componentsNew/ElectionStrategyComponents/MOCK/WIZARD_ELECTION_MOCK'
import React, { createContext, useContext, useState, ReactNode } from 'react'
import { SelectTypes, TopicListTypes } from './types'

interface MacroEnvContextProps {
  topicPositiveList: TopicListTypes[]
  topicNegativeList: TopicListTypes[]
  updatePositiveList: (list: TopicListTypes[]) => void
  updateNegativeList: (list: TopicListTypes[]) => void
  updateSelectList: any
  defaultSelect: SelectTypes[]
  changeSelect: (data: any) => void
  updateListByTopic: (positive: boolean, wizard: any) => void
}

const MacroEnvContext = createContext<MacroEnvContextProps | undefined>(undefined)

interface MacroEnvProviderProps {
  children: ReactNode
}

export const MacroEnvProvider: React.FC<MacroEnvProviderProps> = ({ children }) => {
  const [topicPositiveList, setTopicPositiveList] = useState<TopicListTypes[]>(MOCK_SELECTION_POSITIVE)
  const [topicNegativeList, setTopicNegativeList] = useState<TopicListTypes[]>(MOCK_SELECTION_NEGATIVE)
  const [defaultSelect, setDefaultSelect] = useState<SelectTypes[]>(MOCK_SELECT_LIST)

  const changeSelect = (data: any) => {
    setDefaultSelect(data)
  }

  const updateListByTopic = (positive: boolean, wizard: any) => {
    if (positive) {
      setTopicPositiveList(prevTopics => {
        const newTopics = [...prevTopics]
        const topicIndex = newTopics.findIndex(topic => topic.topic === wizard.topic_name)
        if (topicIndex !== -1) {
          const index = newTopics[topicIndex].theme_list.findIndex(item => item === undefined)
          if (index !== -1) {
            newTopics[topicIndex].theme_list[index] = { ...wizard, label: wizard?.theme_name, value: wizard?.id } // Usando o ID do wizard como valor
          }
        }
        return newTopics
      })
    } else {
      setTopicNegativeList(prevTopics => {
        const newTopics = [...prevTopics]
        const topicIndex = newTopics.findIndex(topic => topic.topic === wizard.topic_name)
        if (topicIndex !== -1) {
          const index = newTopics[topicIndex].theme_list.findIndex(item => item === undefined)
          if (index !== -1) {
            newTopics[topicIndex].theme_list[index] = { ...wizard, label: wizard?.theme_name, value: wizard?.id } // Usando o ID do wizard como valor
          }
        }
        return newTopics
      })
    }
  }

  const updatePositiveList = (list: TopicListTypes[]) => {
    setTopicPositiveList(list)
  }

  const updateNegativeList = (list: TopicListTypes[]) => {
    setTopicNegativeList(list)
  }

  const updateSelectList = (topic_name: string, data: any) => {
    const newValues = topicPositiveList.map(topic => {
      if (topic.topic_name === topic_name) {
        return {
          ...topic,
          selection_list: topic.selection_list.map(option => {
            if (option.name === data?.theme_name) {
              return { ...option, active: true, name: data?.theme_name, is_recommended: true }
            }
            return option
          }),
        }
      }
      return topic
    })

    setTopicPositiveList(newValues)
  }

  return (
    <MacroEnvContext.Provider
      value={{
        updateListByTopic,
        changeSelect,
        defaultSelect,
        topicPositiveList,
        topicNegativeList,
        updatePositiveList,
        updateNegativeList,
        updateSelectList,
      }}
    >
      {children}
    </MacroEnvContext.Provider>
  )
}

export const useMacroEnv = () => {
  const context = useContext(MacroEnvContext)
  if (!context) {
    throw new Error('useMacroEnv deve ser usado dentro de um MacroEnvProvider')
  }
  return context
}
