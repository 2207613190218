import styled, { css } from 'styled-components'

export const Wrapper = styled.main`
  min-height: 64px;
  width: 100%;
  border: 1px solid #494949;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  padding: 4px;
  border-radius: 8px;
`

export const Title = styled.h4`
  font-size: 12px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  color: #e9e9f0;
`

export const WrapperBar = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const LabelBar = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #e9e9f0;
  min-width: 60px;
`

export const BarContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
`

export const BarMarker = styled.span<{ color?: string }>`
  ${({ color }) => css`
    color: ${color};
    margin-left: 8px;
    font-size: 12px;
    font-weight: 500;
  `}
`

export const BarProgress = styled.div<{ percent: number; color?: string }>`
  ${({ color, percent }) => css`
    height: 6px;
    background: ${color};
    border-radius: 8px;
    width: ${percent}%;
  `}
`
