import { AllyProvider } from '../hooks/useAllies'
import { Ally } from '../templates/Ally'

export const AllyTemplate = () => {
  return (
    <AllyProvider>
      <Ally />
    </AllyProvider>
  )
}
