import { apiPortifolio } from "services/apiService";

export const getAvatar = async (candidate_id: string) => {
  try {
    const { data } = await apiPortifolio.get(
      `/candidate-portfolio/candidate/${candidate_id}/image`,
    );

    return {
      url: data?.image_url,
    };
  } catch (e) {
    console.log(e);
    return { message: "erro: busca do avatar" };
  }
};
