export const translateText = (text: string) => {
  switch (text) {
    case 'all':
      return 'Todos '
    case 'sentiment':
      return 'Sentimento'
    case 'topic':
      return 'Tópico'
    case 'includedWords':
      return 'Palavras incluidas'
    case 'excludedWords':
      return 'Palavras excluidas'
    case 'subtopics':
      return 'Subtópicos'
    case 'theme':
      return 'Tema'
    case 'city':
      return 'Localidade'
    case 'topic_lvl_2':
      return 'Subtema'
    case 'source':
      return 'Fonte'
    case 'position':
      return 'Cargo'
    case 'year':
      return 'Ano Eleições'
    case 'state':
      return 'Estados'
    case 'neighborhood':
      return 'Bairros'
    case 'round':
      return 'Turno'
    default:
      return text
  }
}

export const translateValue = (text: string) => {
  switch (text) {
    case 'all':
      return 'Todos '
    case 'positive':
      return 'Positivo'
    case 'negative':
      return 'Negativo'
    default:
      return text
  }
}
