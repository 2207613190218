import axios from 'axios'
import { api } from 'services/apiService'
import { AdversaryObject, CandidateCreatePayload, ResponseDisputedElection } from './types/candidateTypes'

interface ErrorResponse {
  message: string
}

type AllAdversaryResponse = AdversaryObject[] | ErrorResponse

export async function createCandidate(params: CandidateCreatePayload) {
  try {
    const url = ``
    const { data } = await api.get(url)

    return data
  } catch (e) {
    console.error('Erro na requisição:', e)
    return { message: 'Não foi possível buscar menções.' }
  }
}

export async function getAllAdversary(): Promise<AllAdversaryResponse> {
  try {
    const url = `/electoral-intelligence/adversary/`
    const { data } = await api.get(url)

    return data?.results?.filter((item: any) => item?.is_listening)
  } catch (e) {
    console.error('Erro na requisição:', e)
    return { message: 'Não foi possível buscar menções.' }
  }
}

export const getDisputedElection = async (
  candidate_id = '774996579396048100370'
): Promise<ResponseDisputedElection | { message: string }> => {
  try {
    const { data } = await axios.get(
      `https://dbwft9uf52.execute-api.sa-east-1.amazonaws.com/api/candidate-portfolio/candidate/${candidate_id}/disputed-elections`
    )

    return data
  } catch (e) {
    console.log(e)
    return { message: 'Erro na busca de imagens' }
  }
}
