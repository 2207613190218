import React, { useState } from 'react'
import Button from 'componentsNew/Button'
import Text from 'componentsNew/Text'
import Modal from 'componentsNew/Modal'
import { ModalVox } from 'componentsNew/PoliticalVox/components/ModalVox'
import { useOverview } from 'componentsNew/PoliticalVox/hooks/useOverview'
import { noop } from 'lodash'
import * as S from './styles'

interface confirmDialogProps {
  open: boolean
  onClose?: () => void
  title?: string
  textAction?: string | React.ReactNode
  onConfirm?: () => void
  buttonRemove?: boolean
  withConfirmation?: boolean
  closeText?: string
}

export const ConfirmAction = ({
  open,
  onClose = noop,
  onConfirm = noop,
  textAction,
  title = 'CONFIRMAÇÃO',
  buttonRemove = false,
  withConfirmation = true,
  closeText,
}: confirmDialogProps) => {
  const { selectedFilter, changeSelectedFilter, changeFilter } = useOverview()
  const [saveLoad, setSaveLoad] = useState(false)
  const [showModalConfirmation, setShowModalConfirmation] = useState(false)

  const closeModalConfirmation = () => {
    setShowModalConfirmation(false)
  }

  const saveFilter = async () => {
    onConfirm()
    onClose()
    setShowModalConfirmation(true)
  }

  const handleSelectFilter = (filter: any) => {
    changeSelectedFilter(filter)
  }

  const Footer = () => {
    return (
      <S.Footer>
        <Button variant="outline" onClick={onClose}>
          Cancelar
        </Button>
        {buttonRemove && !closeText ? (
          <Button variant="red" onClick={saveFilter} disabled={saveLoad}>
            Remover
          </Button>
        ) : (
          <Button onClick={saveFilter} disabled={saveLoad}>
            {closeText || 'Incluir'}
          </Button>
        )}
      </S.Footer>
    )
  }
  return (
    <>
      <ModalVox open={open} onCancel={onClose} title={title} actionsBox={<Footer />}>
        <S.Wrapper>
          <S.Content>
            <S.Title>{textAction} </S.Title>
          </S.Content>
        </S.Wrapper>
      </ModalVox>

      {withConfirmation && showModalConfirmation && (
        <Modal
          closeModal={closeModalConfirmation}
          title="CONFIRMAÇÃO"
          onPrimaryClick={closeModalConfirmation}
          primaryTextButton="Fechar"
        >
          <Text size="xSmall">Alteração salva com sucesso!</Text>
        </Modal>
      )}
    </>
  )
}
