import React from 'react'
import { FaSpinner } from 'react-icons/fa'
import * as S from './styles'

export interface SpinProps {
  spinning?: boolean
  size?: 'small' | 'default' | 'large'
  tip?: string
  delay?: number
  fullscreen?: boolean
  indicator?: React.ReactNode
}

const Spin = ({ spinning = true, size = 'default', tip, delay, fullscreen, indicator = <FaSpinner /> }: SpinProps) => {
  return (
    <S.SpinWrapper
      spinning={spinning}
      size={size}
      tip={tip}
      delay={delay}
      fullscreen={fullscreen}
      hasIcon={!!indicator}
    >
      {indicator && <S.Icon>{indicator}</S.Icon>}
    </S.SpinWrapper>
  )
}

export default Spin
