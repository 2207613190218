import React, { useEffect, useState } from "react";
import { noop } from "lodash";
import { FilterBar } from "componentsNew/FilterBar";

import Button from "componentsNew/Button";
import {
  getAdvancedMap,
  getDomains,
  getMentionsByTheme,
} from "componentsNew/PoliticalVox/services/advancedFilters";
import { useOverview } from "componentsNew/PoliticalVox/hooks/useOverview";
import { getMentionsByTopicN3 } from "componentsNew/PoliticalVox/services/lambdaServices";
import { useFiltersVoxBar } from "componentsNew/PoliticalVox/hooks/useFilterBarVox";
import { useFilterVox } from "hooks/useFilterVox";
import { ALL } from "utils/constants";
import * as S from "./styles";

interface FetchFunction {
  [key: string]: (filters: any) => Promise<any>;
}
interface useAdvancedFiltersProps {
  filters: any;
  fetchFunctions: FetchFunction;
  exclude?: string;
  selectedFilter?: string;
  inLoading?: boolean;
}

const FilterBarVox = ({
  onSearch = noop,
  inLoading,
}: {
  onSearch: () => void;
  inLoading?: boolean;
}) => {
  const [loadingByType, setLoadingByType] = useState({
    city: false,
    theme: false,
    topic_2: false,
    source: false,
    all: false,
  });
  const { candidatoFilter, topicosFilter } = useOverview();
  const {
    filterRaioX,
    rangeDate,
    handleFilters: setGlobalFilter,
    filtersDefault,
  } = useFilterVox();
  const {
    handleFilters,
    resetFilters,
    validateEnableFilters,
    source,
    topic,
    locality,
    theme,
  } = useFiltersVoxBar();

  const filtersDefaultArray = Array.isArray(filtersDefault)
    ? filtersDefault
    : [];

  const candidate_id = candidatoFilter.isActive
    ? filtersDefault?.candidate?.id
    : undefined;

  const [dataLocality, setDataLocality] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dataTopic, setDataTopic] = useState<any[]>([]);
  const [dataTheme, setDataTheme] = useState<any[]>([]);

  const barFilterPayload = {
    domain: source || filtersDefault?.source,
    parent_name:
      topic || (filtersDefault?.topic !== "Todos" && filtersDefault?.topic),
    city: locality || filtersDefault?.city,
    topic_name: theme,
  };
  const filterPayload = {
    positive_words: filtersDefault?.includedWords,
    negative_words: filtersDefault?.excludedWords,
    sub_topics_ids: filtersDefaultArray.map((subtopic: any) => subtopic?.id),
    sentiment: filterRaioX !== ALL && filterRaioX,
    id_cand: candidate_id,
    initial_date: rangeDate.initial_date,
    final_date: rangeDate.end_date,

    ...barFilterPayload,
  };

  const payloadNewTopic = {
    positive_words: filtersDefault?.includedWords,
    negative_words: filtersDefault?.excludedWords,
    sub_topics_ids: filtersDefault?.subtopics.map(
      (subtopic: any) => subtopic?.id,
    ),
    sentiment: filterRaioX !== ALL && filterRaioX,
    id_cand: candidate_id,
    initial_date: rangeDate.initial_date,
    final_date: rangeDate.end_date,
    parent_id:
      topicosFilter?.filters?.topics && topicosFilter?.filters?.topics[0]?.id,
    domain: source || filtersDefault?.source,
    city: locality || filtersDefault?.city,
    topic_name: theme,
  };

  const filterMap = {
    ...filterPayload,
    city: "",
  };

  const filterSource = {
    ...filterPayload,
    domain: "",
  };

  const filterTopic = {
    ...payloadNewTopic,
    parent_name: "",
  };

  const filterTheme = {
    ...filterPayload,
    topic_name: "",
  };

  const FilterResetPayload = {
    positive_words: filtersDefault?.includedWords,
    negative_words: filtersDefault?.excludedWords,
    sub_topics_ids: filtersDefault?.subtopics.map(
      (subtopic: any) => subtopic?.id,
    ),
    sentiment: filterRaioX !== ALL && filterRaioX,
    id_cand: candidate_id,
    initial_date: rangeDate.initial_date,
    final_date: rangeDate.end_date,
    parent_name: filtersDefault?.topic !== "Todos" && filtersDefault?.topic,
  };

  const NewTopicReset = {
    positive_words: filtersDefault?.includedWords,
    negative_words: filtersDefaultArray.map((subtopic: any) => subtopic?.id),
    sentiment: filterRaioX !== ALL && filterRaioX,
    id_cand: candidate_id,
    initial_date: rangeDate.initial_date,
    final_date: rangeDate.end_date,
    parent_id:
      topicosFilter?.filters?.topics && topicosFilter?.filters?.topics[0]?.id,
  };

  useEffect(() => {
    if (inLoading) {
      handleResetFilters();
    }
  }, [inLoading]);

  const fetchMap = async (payload?: any) => {
    setLoadingByType((prev) => ({ ...prev, city: true }));
    try {
      const newData = await getAdvancedMap(payload || filterMap);
      setDataLocality(newData);
      return newData;
    } catch (e) {
      console.log("error", e);
      return [];
    } finally {
      setLoadingByType((prev) => ({ ...prev, city: false }));
    }
  };

  const fetchDomain = async (payload?: any) => {
    setLoadingByType((prev) => ({ ...prev, source: true }));
    try {
      const newData = await getDomains(payload || filterSource);
      setDataSource(newData);
      return newData;
    } catch (e) {
      console.log("error", e);
      return [];
    } finally {
      setLoadingByType((prev) => ({ ...prev, source: false }));
    }
  };

  const fetchTopic = async (payload?: any) => {
    setLoadingByType((prev) => ({ ...prev, topic_lvl_2_name: true }));
    try {
      const newData = await getMentionsByTopicN3(payload || filterTopic);
      setDataTopic(newData);
      return newData;
    } catch (e) {
      console.log("error", e);
      return [];
    } finally {
      setLoadingByType((prev) => ({ ...prev, topic_lvl_2_name: false }));
    }
  };

  const fetchTheme = async (payload?: any) => {
    setLoadingByType((prev) => ({ ...prev, theme: true }));
    try {
      const newData = await getMentionsByTheme(payload || filterTheme);
      setDataTheme(newData);
      return newData;
    } catch (e) {
      console.log("error", e);
      return [];
    } finally {
      setLoadingByType((prev) => ({ ...prev, theme: false }));
    }
  };

  const handleFilterChange = (type: any, value: any, label?: string) => {
    handleFilters(type, value);
    if (type === "topic_lvl_2") {
      handleTopic(value);
    }
    if (type === "source") {
      handleSource(value);
    }
    if (type === "theme") {
      handleTheme(value);
    }
    if (type === "locality") {
      setGlobalFilter("city", label);
      handleMap(value);
    } else {
      setGlobalFilter(type, value);
    }
  };

  const handleMap = (city?: any) => {
    fetchTheme({ ...filterTheme, city });
    fetchDomain({ ...filterSource, city });
    fetchTopic({ ...filterTopic, city });
  };

  const handleSource = (domain?: any) => {
    fetchMap({ ...filterMap, domain });
    fetchTheme({ ...filterTheme, domain });
    fetchTopic({ ...filterTopic, domain });
  };

  const handleTopic = (parent_name?: any) => {
    fetchMap({ ...filterMap, topic_lvl_2_name: parent_name });
    fetchTheme({ ...filterTheme, topic_lvl_2_name: parent_name });
    fetchDomain({ ...filterSource, topic_lvl_2_name: parent_name });
  };

  const handleTheme = (topic_name?: any) => {
    fetchMap({ ...filterMap, topic_name });
    fetchDomain({ ...filterSource, topic_name });
    fetchTopic({ ...filterTopic, topic_name });
  };

  const handleResetFilters = async () => {
    setLoadingByType((prev) => ({ ...prev, all: true }));
    setGlobalFilter("reset", "");
    resetFilters();
    await fetchTheme(FilterResetPayload);
    await fetchMap(FilterResetPayload);
    await fetchDomain(FilterResetPayload);
    await fetchTopic(NewTopicReset);
    setLoadingByType((prev) => ({ ...prev, all: false }));
  };

  return (
    <S.Wrapper>
      <S.ContentFilters>
        <S.BoxBars>
          <FilterBar
            data={dataLocality}
            title="Filtros por localidade"
            handleClickBar={(local: string, label: string) =>
              handleFilterChange("locality", local, label)
            }
            selectedBar={locality}
            loading={loadingByType.all || loadingByType?.city}
            type="locality"
          />
        </S.BoxBars>
        <S.BoxBars>
          <FilterBar
            data={dataSource}
            title="Filtros por fonte"
            valueProperty="value"
            handleClickBar={(source: string, label: string) =>
              handleFilterChange("source", source, label)
            }
            selectedBar={source}
            loading={loadingByType.all || loadingByType?.source}
          />
        </S.BoxBars>
        <S.BoxBars>
          <FilterBar
            data={dataTopic}
            title="Filtros por Tópico"
            handleClickBar={(topic: string, label: string) =>
              handleFilterChange("topic_lvl_2", topic, label)
            }
            selectedBar={filtersDefault?.topic_lvl_2}
            loading={loadingByType.all || loadingByType?.topic_2}
          />
        </S.BoxBars>
        <S.BoxBars>
          <FilterBar
            data={dataTheme}
            title="Filtros por Tema"
            handleClickBar={(theme: string, label: string) =>
              handleFilterChange("theme", theme, label)
            }
            selectedBar={theme}
            loading={loadingByType.all || loadingByType?.theme}
          />
        </S.BoxBars>
      </S.ContentFilters>
      <S.ActionsBox>
        <Button onClick={onSearch}>Aplicar Filtros</Button>
        <Button onClick={() => handleResetFilters()} variant="outline">
          Limpar filtros
        </Button>
      </S.ActionsBox>
    </S.Wrapper>
  );
};

export default FilterBarVox;
