import styled from 'styled-components'

export const ErrorContainer = styled.div`
  background-color: #ffdddd;
  border-left: 6px solid #dd2d23;
  padding: 20px;
  margin: 20px 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
`

export const IconContainer = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ErrorMessage = styled.h2`
  color: #dd2d23;
  margin-top: 0;
  font-size: 24px;
`

export const ErrorAdvice = styled.p`
  color: #666;
  font-size: 18px;
`
