import { BarItem } from 'componentsNew/CockpitComponents/types/validVotesTypes'
import * as S from './styles'

export interface ProgressBarCardProps {
  title: string
  barList: BarItem[]
}

const formatValue = (val: any) => {
  const value = Number(val)

  if (value >= 1000000000000) {
    return `${(value / 1000000000000).toFixed(1)}T`
  }
  if (value >= 1000000000) {
    return `${(value / 1000000000).toFixed(1)}B`
  }
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}M`
  }
  if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}mil`
  }

  return `${value}`
}

export const ProgressBarCard = ({ barList, title }: ProgressBarCardProps) => {
  return (
    <S.Wrapper>
      <S.Title>{title}</S.Title>
      {barList.map(item => (
        <S.WrapperBar key={item.label}>
          <S.LabelBar>{item.label}</S.LabelBar>
          <S.BarContent>
            <S.BarProgress percent={item.percent || 0} color={item.color} />
            <S.BarMarker color={item.color}>{formatValue(item.value) || 0}</S.BarMarker>
          </S.BarContent>
        </S.WrapperBar>
      ))}
    </S.Wrapper>
  )
}
