import { toast } from 'react-toastify'

export const useToast = () => {
  const toastSuccess = (message: string) => toast.success(message)
  const toastError = (message: string) => toast.error(message)
  const toastWarning = (message: string) => toast.warning(message)
  const toastInfo = (message: string) => toast.info(message)

  return { toastSuccess, toastError, toastWarning, toastInfo }
}
