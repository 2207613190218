import React from 'react'
import { ConfigProvider, Select, theme } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FaSearch } from 'react-icons/fa'

import * as S from './styles'

interface SelectFilterProps extends SelectProps {
  defaultValue?: any
  width?: string
  type_search?: boolean
}

export const SelectFilter: React.FC<SelectFilterProps> = ({
  defaultValue,
  options = [],
  width = '260px',
  type_search,
  ...rest
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            algorithm: true,
            optionSelectedBg: 'rgb(218, 147, 6)',
            selectorBg: 'rgb(21, 21, 21)',
            optionSelectedColor: 'rgb(0, 0, 0)',
            colorBorder: 'rgb(233, 233, 240)',
            optionActiveBg: 'rgba(255, 255, 255, 0.04)',
            colorPrimaryHover: 'rgb(233, 233, 240)',
            colorPrimary: 'rgb(233, 233, 240)',
            borderRadius: 4,
          },
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <S.SelectionBox width={width} className={`${type_search ? 'is_search' : ''}`}>
        <S.SelectCustom
          placeholder="---"
          dropdownStyle={{ border: '1px solid #E9E9F0' }}
          optionLabelProp="label"
          allowClear
          notFoundContent={null}
          virtual={false}
          {...rest}
        >
          {options.map((item, index) => (
            <Select.Option
              key={`${item.value}_${index}`}
              value={item.value}
              label={item.label}
              data={item}
              style={{ margin: '4px 0' }}
            >
              <p>{item.label}</p>
            </Select.Option>
          ))}
        </S.SelectCustom>

        {type_search && <FaSearch size={10} className="icon_search" />}
      </S.SelectionBox>
    </ConfigProvider>
  )
}
