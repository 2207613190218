// DiagnosisFilterContext.js
import React, { createContext, useContext, useState, ReactNode } from 'react'

interface FilterContextType {
  filters: {
    year: any[]
    state: any[]
    city: any[]
    position?: any[]
    party: any[]
    candidate?: any[]
    neighborhood: any[]
    round?: any[]
  }
  setFilters: (filters: FilterContextType['filters']) => void
}

const DiagnosisFilterContext = createContext<FilterContextType | undefined>(undefined)

export const DiagnosisFilterProvider = ({ children }: { children: ReactNode }) => {
  const [filters, setFilters] = useState<FilterContextType['filters']>({
    year: [],
    state: [],
    city: [],
    position: [],
    party: [],
    candidate: [],
    neighborhood: [],
    round: [],
  })

  return <DiagnosisFilterContext.Provider value={{ filters, setFilters }}>{children}</DiagnosisFilterContext.Provider>
}

export const useDiagnosisFilter = (): FilterContextType => {
  const context = useContext(DiagnosisFilterContext)
  if (!context) {
    throw new Error('useDiagnosisFilter must be used within a DiagnosisFilterProvider')
  }
  return context
}
