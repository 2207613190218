import * as S from './styles'

interface HeadingFormProps {
  title: string
}

export const HeadingForm = ({ title }: HeadingFormProps) => {
  return (
    <S.Wrapper>
      <S.Divider />
      <S.Title>{title}</S.Title>
      <S.Divider />
    </S.Wrapper>
  )
}
