import { styled } from 'styled-components'

export const Wrapper = styled.main`
  width: 100%;
  background: #161717;
  border-radius: 8px;
  padding: 16px;
`

export const Title = styled.h1`
  font-size: 16px;
  font-weight: 700;
  color: #e9e9f0;

  span {
    color: #ffc000;
  }
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 15px;
  color: #e9e9f0;
`

export const ContentTable = styled.div`
  margin-top: 25%;
  margin-bottom: 90px;
  position: relative;
`

export const RowTable = styled.div`
  &:nth-child(even) {
    background-color: #1d1d1d;
  }

  &:nth-child(odd) {
    background-color: #111111;
  }
`

export const ColumnTable = styled.div`
  padding: 16px;
`

export const RowPackage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ListOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  width: 70%;
`

export const OptionText = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #e9e9f0;
  width: 100%;
  max-width: 180px;
`

export const TitleBox = styled.div``

export const TitleTable = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #e9e9f0;
`

export const ListPackage = styled.div`
  margin-left: 8px;
`

export const TitlePackage = styled.p`
  font-size: 15px;
  font-weight: 500;
  color: #e9e9f0;
`

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 24px;
  background-color: black;
`

export const ModalTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
`

export const ModalButtonContent = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: -132px;
`
