import styled from 'styled-components'

export const Wrapper = styled.main`
  width: 100%;
`

export const CardList = styled.div<{ wrapped: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${props => props.wrapped && 'space-between'};
  gap: 6px;
  transition: all 3s ease-in-out;
`
