import React from 'react'
import Card from '../../../../componentsNew/Card'
import { Container } from './styles'
import Text from '../../../../componentsNew/Text'
import iconMicrophone from '../../../../assets/iconMicrophone.svg'
import iconArchetype1 from '../../../../assets/icon-archetype-1.svg'
import iconArchetype2 from '../../../../assets/icon-archetype-2.svg'
import iconArchetype3 from '../../../../assets/icon-archetype-3.svg'
import iconArchetype4 from '../../../../assets/icon-archetype-4.svg'
import iconArchetype5 from '../../../../assets/icon-archetype-5.svg'
import iconArchetype6 from '../../../../assets/icon-archetype-6.svg'

const getArchetypeIcon = (archetypeId: number) => {
  switch (archetypeId) {
    case 1:
      return iconArchetype1
    case 2:
      return iconArchetype2
    case 3:
      return iconArchetype3
    case 4:
      return iconArchetype4
    case 5:
      return iconArchetype5
    case 6:
      return iconArchetype6
    default:
      return iconMicrophone
  }
}

const CardHeader = ({ archetype, city }: any) => {
  const archetypeIcon = getArchetypeIcon(archetype?.archetype_id)

  return (
    <Container>
      <Card title={`Arquétipo Predominante em ${city}`}>
        {archetype?.length !== 0 ? (
          <div className="wrapper-header">
            <div className="title">
              <div className="icon-title">{archetypeIcon && <img src={archetypeIcon} alt="Archetype Icon" />}</div>
              <Text size="xlarge" bold>
                {archetype?.name}
              </Text>
            </div>
            <Text>{archetype?.description}</Text>
          </div>
        ) : (
          <Text>Nenhuma informação de adesão encontrada para o candidato</Text>
        )}
      </Card>
    </Container>
  )
}

export default CardHeader
