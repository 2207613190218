import { Switch, SwitchProps } from 'antd'
import styled from 'styled-components'

export const SwitchCustom = styled(Switch)<SwitchProps>`
  width: 100%;
  &.ant-switch.ant-switch-checked {
    .ant-switch-handle {
      &::before {
        background: #ffc000 !important;
      }
    }
  }
`
