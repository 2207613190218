import styled from 'styled-components'

export const ButtonContainer = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: ${({ isActive }) => (isActive ? '#FFC000' : 'transparent')};
  border: 1px solid #ffffff;
  border-radius: 24px;
  width: 100%;
  cursor: pointer;
  color: #ffffff;

  &:hover {
    border: 1px solid #0a0a0a;
    background-color: #ffc000;
    color: #0a0a0a;
  }

  .icon {
    margin-right: 10px;
  }
`

export const ButtonTitle = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: center;
`
