import { apiPortifolio } from 'services/apiService'
import iconPartidoABC from '../../../assets/partidoABC.svg'
import iconPartidoXYZ from '../../../assets/partidoXYZ.svg'
import iconPartidoMNO from '../../../assets/partidoMNO.svg'
import {
  PartyData,
  TransformedPartyData,
  StateData,
  TransformedStateData,
  CityData,
  TransformedCityData,
  TransformedCandsData,
} from '../types/allApplicationsTypes'

const getIcon = (partyAcronym: string) => {
  switch (partyAcronym) {
    case 'XYZ':
      return iconPartidoXYZ
    case 'ABC':
      return iconPartidoABC
    case 'MNO':
      return iconPartidoMNO
    default:
      return iconPartidoABC
  }
}

export const getPartyImage = async (partyAcronym: string) => {
  try {
    const { data } = await apiPortifolio.get(`/candidate-portfolio/party/${partyAcronym}/image`)
    return data
  } catch (e) {
    console.log(e)
    console.error(`Erro em buscar a imagem do partido ${partyAcronym}`, e)
    return false
  }
}

export const calculateTotalPositions = (
  partiesData: PartyData[]
): { prefeitos: number; vices: number; vereadores: number } => {
  let prefeitosTotal = 0
  let vicesTotal = 0
  let vereadoresTotal = 0

  partiesData.forEach(party => {
    party.totals.forEach(total => {
      if (total.position === 'PREFEITO') {
        prefeitosTotal += total.count
      } else if (total.position === 'VICE-PREFEITO') {
        vicesTotal += total.count
      } else if (total.position === 'VEREADOR') {
        vereadoresTotal += total.count
      }
    })
  })

  return {
    prefeitos: prefeitosTotal,
    vices: vicesTotal,
    vereadores: vereadoresTotal,
  }
}

const transformParties = (partiesData: PartyData[]): TransformedPartyData[] => {
  const processedData = partiesData.map((party: any) => {
    return {
      id: party.party_id,
      icon: getIcon(party.party_acronym),
      title: party.party_acronym,
      valueCandidates: party.totals.map((total: any) => ({
        name: total.position,
        value: total.count.toString(),
      })),
    }
  })

  return processedData
}

const transformStates = (statesData: StateData[]): TransformedStateData[] => {
  const processedData = statesData.map((state: any) => {
    return {
      state: state.state,
      acronym: state.acronym,
      total_count: state.total_count,
      total_portion: state.total_portion,
      totals: state.totals.map((total: any) => ({
        position: total.position,
        value: total.count,
      })),
    }
  })

  return processedData
}

const transformCities = (citiesData: CityData[]): TransformedCityData[] => {
  const processedData = citiesData.map((city: any) => {
    return {
      city: city.city,
      totals: city.totals.map((total: any) => ({
        position: total.position,
        value: total.value,
      })),
    }
  })

  return processedData
}

export const transformCands = (candsData: any[]): TransformedCandsData[] => {
  const processedData = candsData.map(cand => ({
    position: cand.position,
    candidates: cand.candidates.map((candidate: any) => ({
      id: candidate.id,
      name: candidate.name,
    })),
  }))

  return processedData
}

export const getPartyTotals = async (): Promise<
  { prefeitos: number; vices: number; vereadores: number } | { message: string }
> => {
  try {
    const { data } = await apiPortifolio.get(
      `/candidate-portfolio/national-panorama/parties?type_election=Municipal&election_year=2020`
    )
    const totals = calculateTotalPositions(data)
    return totals
  } catch (e) {
    console.error('Error calculating total positions:', e)
    return { message: 'Não foi possível calcular os totais das posições.' }
  }
}

export const getParties = async (): Promise<TransformedPartyData[] | { message: string }> => {
  try {
    const { data } = await apiPortifolio.get(
      `/candidate-portfolio/national-panorama/parties?type_election=Municipal&election_year=2020`
    )

    return transformParties(data)
  } catch (e) {
    console.error('Error fetching parties:', e)
    return { message: 'Não foi possível buscar os partidos cadastrados.' }
  }
}

export const getPartyId = async (id: number): Promise<TransformedPartyData[] | { message: string }> => {
  try {
    const { data } = await apiPortifolio.get(
      `/candidate-portfolio/national-panorama/parties?type_election=Municipal&parties_ids=${id}&election_year=2020`
    )

    return transformParties(data)
  } catch (e) {
    console.error('Error fetching parties:', e)
    return { message: 'Não foi possível buscar os partidos cadastrados.' }
  }
}

export const getStates = async (id: number): Promise<TransformedStateData[] | { message: string }> => {
  try {
    const { data } = await apiPortifolio.get(
      `/candidate-portfolio/national-panorama/state?party_id=${id}&type_election=Municipal&election_year=2020`
    )

    return transformStates(data)
  } catch (e) {
    console.error('Error fetching states:', e)
    return { message: 'Não foi possível buscar os estados do partido cadastrado.' }
  }
}

export const getCitiesIdUf = async (id: number, uf: string): Promise<TransformedCityData[] | { message: string }> => {
  try {
    const { data } = await apiPortifolio.get(
      `/candidate-portfolio/national-panorama/city?party_id=${id}&type_election=Municipal&state=${uf}&election_year=2020`
    )

    return data
  } catch (e) {
    console.error('Error fetching cities:', e)
    return { message: 'Não foi possível buscar as cidades do partido cadastrado.' }
  }
}

export const getCandsIdUfCity = async (
  id: number,
  uf: string,
  city: string
): Promise<TransformedCandsData[] | { message: string }> => {
  try {
    const { data } = await apiPortifolio.get(
      `/candidate-portfolio/national-panorama/candidates?party_id=${id}&type_election=Municipal&city=${city}&election_year=2020&state=${uf}`
    )

    return transformCands(data)
  } catch (e) {
    console.error('Error fetching candidates:', e)
    return { message: 'Não foi possível buscar os candidatos do partido cadastrado.' }
  }
}
