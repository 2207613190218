import { ReactComponent as IconAnalystAdvisor } from "assets/advisors/icon-analyst-advisor.svg";
import { ReactComponent as IconStrategistAdvisor } from "assets/advisors/icon-strategist-advisor.svg";
import { ReactComponent as IconMarketingAdvisor } from "assets/advisors/icon-marketing-advisor.svg";
import { ReactComponent as IconResearcherAdvisor } from "assets/advisors/icon-researcher-advisor.svg";
import { ReactComponent as IconHelpDeskAdvisor } from "assets/advisors/icon-help-desk-advisor.svg";

export const mapSidebarAdvisor = [
  {
    name: "Analista",
    type: "advisor",
    title: ``,
    icon: IconAnalystAdvisor,
    filter: false,
    path: "",
    disabled: false,
  },
  {
    name: "Estrategista",
    type: "advisor",
    icon: IconStrategistAdvisor,
    title: ``,
    filter: false,
    path: "",
    disabled: false,
  },
  {
    name: "Pesquisador",
    type: "advisor",
    icon: IconResearcherAdvisor,
    title: ``,
    filter: false,
    path: "",
    disabled: true,
  },
  {
    name: "Marketing",
    type: "advisor",
    icon: IconMarketingAdvisor,
    title: ``,
    filter: false,
    path: "",
    disabled: false,
  },
  {
    name: "Relações Públicas",
    type: "advisor",
    icon: IconStrategistAdvisor,
    title: ``,
    filter: false,
    path: "",
    disabled: true,
  },
  {
    name: "Cientista Político",
    type: "advisor",
    icon: IconStrategistAdvisor,
    title: ``,
    filter: false,
    path: "",
    disabled: false,
  },
  {
    name: "Help Desk",
    type: "advisor",
    icon: IconHelpDeskAdvisor,
    title: ``,
    filter: false,
    path: "",
    disabled: true,
  },
  {
    name: "Chamadas Agendadas",
    type: "advisor",
    icon: IconHelpDeskAdvisor,
    title: ``,
    filter: false,
    path: "",
    disabled: false,
  },
];
