import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import {StyledSelect} from "../styles";

interface SelectFieldProps {
  field: ControllerRenderProps;
  options: { label: string | number; value: string | number }[];
  placeholder: string;
  onChange?: any;
}

const SelectField: React.FC<SelectFieldProps> = ({ field, options, placeholder, onChange }) => (
  <StyledSelect
    onChange={onChange ? onChange : field.onChange}
    onBlur={field.onBlur}
    showSearch
    size="large"
    placeholder={placeholder}
    notFoundContent="não foram encontrados resultados"
    options={options}
    filterOption={(input, option) =>
      (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
    }
  />
);

export default SelectField;
