import React, { useEffect, useState } from "react";
import Gauge from "components/CardGoals/ProgressArc";
import Text from "componentsNew/Text";
import {
  CardGoalsTypes,
  GoalData,
} from "componentsNew/CockpitComponents/types/validVotesTypes";
import { getProgress } from "componentsNew/CockpitComponents/hooks/useData";
import { ProgressBarCard } from "../ProgressBarCard";
import { CardGoalsPropsExtend } from "./types";

import * as S from "./styles";
import calculateProgressAndColor from "../../../../utils/utilsProgressCardGoals";

export interface CardGoalsProps extends CardGoalsPropsExtend {
  width?: string;
  apiEndpoint: string;
}

export const CardGoals = ({
  width,
  status,
  title,
  subtitle,
  bar_titles,
  apiEndpoint,
  isCockpit,
}: CardGoalsProps) => {
  const [validVotes, setValidVotes] = useState<CardGoalsTypes | null>(null);
  const [dataGoals, setDataGoals] = useState<any>();

  const arc_data = validVotes?.arc_data;
  const progress_data = validVotes?.bar_progress;

  useEffect(() => {
    fetchValidVotes(apiEndpoint);
  }, []);

  const fetchValidVotes = async (url: string) => {
    const res = await getProgress(url);

    if ("message" in res) {
      console.log(res.message);
    } else {
      const data = res.goal_data;
      setDataGoals(data);
      const formatProgress = formattedValues(data);
      setValidVotes({
        status_color: formatProgress.rithmColor,
        arc_data: {
          initialValue: data.initial_value,
          goalValue: data.goal,
          currentValue: data.current_value,
          minimumGoal: data.minimum_goal,
          firstGoal: data.first_partial_goal,
          secondGoal: data.second_partial_goal,
        },
        bar_progress: [
          {
            title: `${subtitle} / semana` || "Votos válidos / semana",
            barList: [
              {
                label: "Ritmo",
                percent: formatProgress.rithmPercentage,
                color: formatProgress.rithmColor,
                value: data.rithm,
              },
              {
                label: "Meta",
                percent: formatProgress.weeklyGoalPercentage,
                color: "white",
                value: data.weekly_goal,
              },
            ],
          },
          {
            title: subtitle || "Votos válidos",
            barList: [
              {
                label: "Projeção",
                percent: formatProgress.projectionPercentage,
                color: formatProgress.projectionColor,
                value: data.projection,
              },
              {
                label: "Meta",
                percent: formatProgress.goalPercentage,
                color: "white",
                value: data.goal,
              },
            ],
          },
        ],
      });
    }
  };

  const formattedValues = (data: GoalData) => {
    return calculateProgressAndColor({
      rithmValue: data.rithm,
      weeklyGoalValue: data.weekly_goal,
      projectionValue: data.projection,
      goalValue: data.goal,
    });
  };

  const formatValue = (val: any) => {
    const value = Number(val);

    if (value >= 1000000000000) {
      const newValue = value / 1000000000000;
      return `${newValue.toFixed(1)}T`;
    }

    if (value >= 1000000000) {
      const newValue = value / 1000000000;
      return `${newValue.toFixed(1)}B`;
    }

    if (value >= 1000000) {
      const newValue = value / 1000000;
      return `${newValue.toFixed(1)}M`;
    }

    if (value >= 1000) {
      const newValue = value / 1000;
      return `${newValue.toFixed(1)}mil`;
    }

    return `${value}`;
  };

  const selectColor = () => {
    if (validVotes && validVotes.status_color) {
      return validVotes.status_color;
    }
    if (status === "warning") {
      return "#ffbb00";
    }
    if (status === "error") {
      return "#ff0000";
    }

    return "#3cff00";
  };

  const GaugeData = arc_data && [
    {
      limit: arc_data.minimumGoal,
      color: "#df0000",
    },
    /* {
      limit: arc_data.firstGoal,
      color: '#016DBA',
    },
    {
      limit: arc_data.secondGoal,
      color: '#223458',
    }, */
    {
      limit: 100,
      color: "#00E23F",
    },
  ];

  return (
    <S.Wrapper width={width} isCockpit={isCockpit}>
      <S.Header>
        <S.Heading>{title}</S.Heading>
        <S.Pointer background={selectColor()} />
      </S.Header>

      <S.ProgressWrapper>
        {!!arc_data && (
          <Gauge
            formatLabel={(val) => formatValue(val)}
            labels={{
              max: formatValue(arc_data.goalValue * 1.2),
              min: formatValue(arc_data.initialValue),
            }}
            defaultData={GaugeData}
            maxValue={arc_data.goalValue * 1.2}
            currentValue={arc_data.currentValue}
            sizeStyle="200px"
            minimalGoal={dataGoals?.minimum_goal}
            goal={dataGoals?.goal}
            isCockpit={isCockpit}
          />
        )}
      </S.ProgressWrapper>

      <S.BoxProgress>
        {progress_data &&
          progress_data?.length > 0 &&
          progress_data.map((item) => (
            <ProgressBarCard
              key={item.title}
              barList={item.barList}
              title={item.title}
            />
          ))}
      </S.BoxProgress>

      <Text className="text-cockpit">.</Text>
    </S.Wrapper>
  );
};
