import styled from 'styled-components'
import '../../styles/global'

interface TitleProps {
  top?: string
}

export const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  color: #e9e9f0;
`

export const Content = styled.div`
  width: 100%;

  @media screen and (min-width: 1190px) {
    max-width: 1200px;
    min-width: 1024px;
  }
`
