import React, { useState, useEffect } from "react";
import TabsCustom from "componentsNew/Tabs";
import { UnderConstruction } from "componentsNew/UnderConstruction";
import Spin from "componentsNew/Spin";
import PBIDash from "componentsNew/PBIDash";
import { useCandidates } from "hooks/useCandidates";
import { useReport } from "global/ReportContext";
import { useTab } from "global/TabContext";
import { useDiagnosisFilter } from "global/DiagnosisFilterContext";
import * as S from "./styles";

const labels = [
  {
    label: "Mapa Votos ",
    sub_label: "vs. Cidade (Nacionais e Estaduais)",
    key: "tab_party_diagnosis_map_of_key_national",
  },
  {
    label: "Mapa Votos ",
    sub_label: "vs. Cidade (Municipais)",
    key: "tab_party_diagnosis_map_of_key_cities",
  },
  {
    label: "Mapa Partidos ",
    sub_label: "vs. Top Cidades",
    key: "tab_party_diagnosis_map_parties",
    disabled: true,
  },
  {
    label: "Perfil dos",
    sub_label: "Partidos",
    key: "tab_party_diagnosis_parties_profile",
    disabled: true,
  },
  {
    label: "Mapa Desempenho",
    sub_label: "vs. Cargo",
    key: "tab_party_diagnosis_map_by_position",
  },
];

type ReportNamesType = {
  [key in (typeof labels)[number]["key"]]: string;
};

const reportNames: ReportNamesType = {
  tab_party_diagnosis_map_of_key_national: "party_analysis_maps",
  tab_party_diagnosis_map_of_key_cities: "votes_map_city",
  tab_party_diagnosis_map_by_position: "position_map",
};

const content: React.ReactNode[] = [
  <PBIDash reportName="party_analysis_maps" />,
  <PBIDash reportName="votes_map_city" />,
  <UnderConstruction />,
  <UnderConstruction />,
  <PBIDash reportName="position_map" />,
];

export const PartyDiagnosis = () => {
  const [activeTab, setActiveTab] = React.useState(
    "tab_party_diagnosis_map_of_key_national",
  );
  const { isLoadedCandidates } = useCandidates();
  const { setReportName } = useReport();
  const { setActiveTabContext } = useTab();
  const { filters } = useDiagnosisFilter();
  const [filterKey, setFilterKey] = useState(Date.now());

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  useEffect(() => {
    setReportName(reportNames[activeTab]);
  }, [activeTab, setReportName]);

  useEffect(() => {
    setFilterKey(Date.now());
  }, [filters]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  const changePoliticalTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={content.map((comp, index) =>
            React.cloneElement(comp as React.ReactElement, {
              key: `${filterKey}-${index}`,
            }),
          )}
          onChange={(key) => changePoliticalTab(key)}
        />
      </S.Content>
    </S.Wrapper>
  );
};
