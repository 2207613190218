import styled, { css } from 'styled-components'
import { CheckboxProps } from '.'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Input = styled.input`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: 1.2rem;
    height: 1.2rem;
    border: 0.1rem solid ${theme.COLORS.YELLOW_1};

    border-radius: 0.2rem;
    transition: background border ${theme.transition.fast};
    position: relative;
    outline: none;

    &:before {
      content: '';
      width: 0.4rem;
      height: 0.7rem;
      border: 0.2rem solid ${theme.COLORS.BLACK_2};

      border-top: 0;
      border-left: 0;
      transform: rotate(33deg);
      position: absolute;
      top: 0.01rem;

      opacity: 0;
      transition: ${theme.transition.fast};
    }

    &:hover {
      border-color: ${theme.COLORS.GRAY_2};
      transition: ${theme.transition.fast};
    }

    &:checked {
      border-color: ${theme.COLORS.PRIMARY};
      background: ${theme.COLORS.YELLOW_1};

      &:before {
        opacity: 1;
      }
    }
  `}
`

export const Label = styled.label<Pick<CheckboxProps, 'labelColor'>>`
  ${({ theme, labelColor }) => css`
    cursor: pointer;
    padding-left: 6px;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    color: ${theme.COLORS[labelColor || 'BLACK_2']};
    font-family: ${theme.FONT_FAMILY.PRIMARY};
    line-height: 1.8rem;
    margin-left: 0.2rem;
    font-size: 14px;
  `}
`
