"use client";

import React, { useEffect, useRef } from "react";
import Button from "componentsNew/Button";
import { useUser } from "global/UserContext";
import { useIframeCommunication } from "../../../hooks/useIframeCommunication";
import useRedirectToPortfolio from "microfrontends/hooks/useRedirectToPortfolio";
import { apiAdvisorsNext } from "microfrontends/config/api";
import * as S from "../styles";

const IframeContainer: React.FC = () => {
  const { user } = useUser();
  const userId = user?.person?.id;
  const iframeUrl = `${apiAdvisorsNext}/advisorplatform/calls/schedule?userId=${userId}`;

  const { iframeRef, sendMessageToIframe } = useIframeCommunication(iframeUrl);
  const { redirectToPortfolio } = useRedirectToPortfolio();

  return (
    <>
      <div>
        <iframe
          ref={iframeRef}
          src={iframeUrl}
          width="100%"
          height="570px"
          title="Next.js App"
        />
      </div>
      <S.Container>
        <Button variant="minimal" onClick={redirectToPortfolio}>
          Ver Portfólio de Advisors
        </Button>
      </S.Container>
    </>
  );
};

export default IframeContainer;
