import React, { useEffect, useState } from "react";
import Card from "componentsNew/Card";
import Text from "componentsNew/Text";
import { getPartyImage } from "pages/AllApplications/services/allApplicationsServices";
import { Container } from "./styles";

interface ITotals {
  position: string;
  count: number;
}
interface ICardAllApplications {
  id?: number | string;
  titleCard?: string;
  title: string;
  icon: any;
  valueCandidates: any[];
  onClick?: any;
  isClickable?: boolean;
}

interface ICandidate {
  name: string;
  position?: string;
  value?: number;
  count: number;
}

const CardAllApplications = ({
  id,
  title,
  icon,
  valueCandidates = [],
  titleCard,
  onClick,
  isClickable = true,
}: ICardAllApplications) => {
  const [partyImage, setPartyImage] = useState<any>("");

  useEffect(() => {
    const fetchImage = async () => {
      const imageUrl = await getPartyImage(title);
      setPartyImage(imageUrl);
    };

    fetchImage();
  }, [title]);

  const defaultCandidates = [
    { name: "PREFEITO", value: 0, count: 0 },
    { name: "VICE-PREFEITO", value: 0, count: 0 },
    { name: "VEREADOR", value: 0, count: 0 },
  ];

  const positionsOrder = ["PREFEITO", "VICE-PREFEITO", "VEREADOR"];

  const mergeWithDefaultCandidates = (
    currentCandidates: ICandidate[] = [],
  ): ICandidate[] => {
    const normalizedCandidates = currentCandidates.map((candidate) => ({
      ...candidate,
      name: candidate.name ? candidate.name.toUpperCase() : "",
    }));

    const mergedCandidates = defaultCandidates.map(
      (defaultCandidate: ICandidate) => {
        const foundCandidate = normalizedCandidates.find(
          (candidate: ICandidate) => candidate.name === defaultCandidate.name,
        );
        return (
          foundCandidate || { ...defaultCandidate, name: defaultCandidate.name }
        );
      },
    );

    return mergedCandidates;
  };

  const mergedCandidates = mergeWithDefaultCandidates(valueCandidates ?? []);

  const getPositionPriority = (position: string) => {
    return positionsOrder.indexOf(position);
  };

  const orderedCandidates = mergedCandidates.sort((a, b) => {
    return (
      getPositionPriority(a.name.toUpperCase()) -
      getPositionPriority(b.name.toUpperCase())
    );
  });

  const slug = title?.slice(0, 4);

  return (
    <Container
      onClick={() => isClickable && onClick && onClick(id)}
      isClickable={isClickable}
    >
      <Card title={titleCard}>
        <div className="wrapper">
          <div className="cardTitle">
            {partyImage ? (
              <div className="party-image">
                <img src={partyImage?.image_url} alt={`${title}`} />
              </div>
            ) : (
              <div className="avatarText">{slug}</div>
            )}
            <Text bold size="medium">
              {title}
            </Text>
          </div>
          <div className="wrapperCard">
            {orderedCandidates.map((candidate: ICandidate) => {
              return (
                <div className="card" key={candidate.name}>
                  <Text size="small">{candidate.name}</Text>
                  <Text size="xSmall" bold>
                    {candidate.value || candidate.count}
                  </Text>
                </div>
              );
            })}
          </div>
        </div>
      </Card>
    </Container>
  );
};

export default CardAllApplications;
