/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import { parseInt } from 'lodash'

export function removePontos(numeroComPontos: string) {
  return parseInt(numeroComPontos.replace(/\./g, ''), 10)
}

function translateSeriesNames(series: any[]) {
  return series.map((item: any) => {
    switch (item?.name) {
      case 'Negative':
        return 'Negativo'

      case 'Positive':
        return 'Positivo'

      case 'Neutral':
        return 'Neutro'

      default:
        break
    }
    return item
  })
}

export function validateCPF(cpf: any) {
  let cpfValid
  cpf = cpf.replace(/[^\d]+/g, '')

  if (cpf.length !== 11) {
    cpfValid = false
  }

  if (/^(\d)\1+$/.test(cpf)) {
    cpfValid = false
  }

  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i)
  }

  let remainder = (sum * 10) % 11
  if (remainder === 10 || remainder === 11) {
    remainder = 0
  }

  if (remainder !== parseInt(cpf.charAt(9))) {
    cpfValid = false
  }

  sum = 0
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i)
  }

  remainder = (sum * 10) % 11
  if (remainder === 10 || remainder === 11) {
    remainder = 0
  }

  if (remainder !== parseInt(cpf.charAt(10))) {
    cpfValid = false
  } else {
    cpfValid = true
  }

  return cpfValid
}

export function formatarData(entrada: string): string {
  const [ano, mes, dia] = entrada.split('T')[0].split('-')

  return `${dia}/${mes}/${ano}`
}
