import React from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import { ErrorContainer, ErrorMessage, ErrorAdvice, IconContainer } from './styles'

interface ErrorDisplayProps {
  message: string
  advice: string
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ message, advice }) => {
  return (
    <ErrorContainer>
      <IconContainer>
        <FaExclamationTriangle size={54} />
      </IconContainer>
      <div>
        <ErrorMessage>{message}</ErrorMessage>
        <ErrorAdvice>{advice}</ErrorAdvice>
      </div>
    </ErrorContainer>
  )
}

export default ErrorDisplay
