import styled from 'styled-components'

export const Container = styled.div<any>`
  margin: 8px;
`
export const ContainerCard = styled.div<any>`
  background: #161717;
  border-radius: 8px;
  padding: 8px;
  height: 56px;
  border: 1px solid #e9e9f0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title-card {
    width: 200px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .status-cadidate {
    width: 400px;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .action {
    width: 400px;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
