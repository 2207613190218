// MenuContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react'

interface MenuContextProps {
  activeMenu: string
  setActiveMenu: (menu: string) => void
  activeSubmenu: string
  setActiveSubmenu: (submenu: string) => void
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined)

export const useMenu = () => {
  const context = useContext(MenuContext)
  if (!context) {
    throw new Error('useMenu must be used within a MenuProvider')
  }
  return context
}

interface ProviderProps {
  children: ReactNode
}

export const MenuProvider: React.FC<ProviderProps> = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState<string>('')
  const [activeSubmenu, setActiveSubmenu] = useState<string>('')

  return (
    <MenuContext.Provider value={{ activeMenu, setActiveMenu, activeSubmenu, setActiveSubmenu }}>
      {children}
    </MenuContext.Provider>
  )
}
