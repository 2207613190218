import { Avatar } from '@mui/material'
import React from 'react'

interface IAvatar {
  img: string
  size?: any
}

const Avatars = ({ img, size }: IAvatar) => <Avatar src={img} sx={size} />

export default Avatars
