import { useState } from 'react'
import { FooterOnboarding } from 'componentsNew/OnboardingComponents/Footer'
import { AnimatePresence } from 'framer-motion'

import { useStep } from 'componentsNew/OnboardingComponents/Hooks/useStep'
import { CustomInput } from 'componentsNew/FormGenerator/components/CustomInput'

import * as S from './styles'

interface CandTypes {
  cpf: string
  birth_date: string
  first_name: string
  last_name: string
  email: string
  phone: string
}

export const SelectCand = () => {
  const { setCurrentStep, currentStep } = useStep()
  const [candSelected, setCandSelected] = useState({} as CandTypes)

  const handlePasswordChange = (value: string) => {
    console.log(value)
  }

  const handleChange = (type: string, value: string) => {
    console.log(value)
    setCandSelected({ ...candSelected, [type]: value })
  }

  const next = () => {
    /*  setCurrentStep(prevStep => prevStep + 1) */
  }

  const prev = () => {
    setCurrentStep(prevStep => prevStep - 1)
  }

  const FormData = [
    {
      type: 'text',
      label: 'cpf',
      placeholder: 'CPF do candidato',
      mask: '999.999.999-99',
      required: true,
      value: candSelected.cpf,
      onChange: (e: any) => handleChange('cpf', e.target.value),
    },
    {
      type: 'text',
      label: 'birth_date',
      placeholder: 'Data de Nascimento',
      mask: '99/99/9999',
      required: true,
      value: candSelected.birth_date,
      onChange: (e: any) => handleChange('birth_date', e.target.value),
    },
    {
      type: 'text',
      label: 'first_name',
      placeholder: 'Nome do candidato',
      required: true,
      value: candSelected.first_name,
      onChange: (e: any) => handleChange('first_name', e.target.value),
    },
    {
      type: 'text',
      label: 'last_name',
      placeholder: 'Sobrenome do candidato',
      required: true,
      value: candSelected.last_name,
      onChange: (e: any) => handleChange('last_name', e.target.value),
    },
    {
      type: 'text',
      label: 'email',
      placeholder: 'Email do candidato',
      required: true,
      value: candSelected.email,
      onChange: (e: any) => handleChange('email', e.target.value),
    },
    {
      type: 'text',
      label: 'phone',
      mask: '(99) 9 9999-9999',
      placeholder: 'Telefone do candidato',
      required: true,
      value: candSelected.phone,
      onChange: (e: any) => handleChange('phone', e.target.value),
    },
  ]

  return (
    <S.Wrapper>
      <S.Heading>Olá, José!</S.Heading>
      <AnimatePresence mode="wait">
        <S.Content
          key={currentStep}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <S.Text>Insira os dados do candidato representado::</S.Text>

          <S.ContentForm>
            {FormData.map((item, index) => (
              <CustomInput
                key={index}
                id={item.label}
                mask={item?.mask}
                placeholder={item.placeholder}
                required={item.required}
                value={item.value}
                onChange={(e: any) => handleChange(item.label, e.target.value)}
              />
            ))}
          </S.ContentForm>
        </S.Content>
      </AnimatePresence>
      {/*  <Button type="primary" disabled={!isStrongPassword}>
        Submit
      </Button> */}

      <FooterOnboarding current={currentStep} totalSteps={3} next={next} prev={prev} />
    </S.Wrapper>
  )
}
