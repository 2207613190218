import React from 'react'
import InputTextColor from 'componentsNew/InputTextColor'
import {
  Badge,
  Container,
  InputRaduisBase,
  InputSelectBase,
  InputSelectBaseBadge,
  Wrapper,
  WrapperMetodos,
} from './styles'

import Text from '../../componentsNew/Text'
import InputRangeColor from '../../componentsNew/InputRangeColor'
import { ISetGoals } from './ISetGoals'
import useSetGoals from './useSetGoals'

interface ISetGoalsProps {
  dataValue: ISetGoals
  onGoalsChange: any
  endpoint: string
}

const SetGoals = ({ dataValue, onGoalsChange, endpoint }: ISetGoalsProps) => {
  const { goals, handleChange } = useSetGoals(dataValue, endpoint)

  const handleSliderChange = (value: number, slug: keyof ISetGoals) => {
    handleChange(value, slug)
    onGoalsChange(slug, value)
  }

  return (
    <Container>
      <Text size="medium" bold>
        Definir metas
      </Text>
      <InputSelectBase>
        <InputRaduisBase type="radio" checked />
        <Text>Simplificado</Text>
      </InputSelectBase>
      <Wrapper>
        <InputTextColor
          color="white"
          title="Valor Inicial"
          slug="initial_value"
          value={goals.initial_value}
          handleChange={handleSliderChange}
        />
        <InputTextColor color="green" title="Meta" slug="goal" value={goals.goal} handleChange={handleSliderChange} />
        <InputRangeColor
          color="red"
          title="Meta Miníma"
          slug="minimum_goal"
          value={goals.minimum_goal}
          handleChange={handleSliderChange}
        />
        <InputRangeColor
          color="blue"
          title="Meta Parcial 1"
          slug="first_partial_goal"
          value={goals.first_partial_goal}
          handleChange={handleSliderChange}
        />
        <InputRangeColor
          color="cyan"
          title="Meta Parcial 2"
          slug="second_partial_goal"
          value={goals.second_partial_goal}
          handleChange={handleSliderChange}
        />
        <InputTextColor
          color="yellow"
          title="Valor atual"
          slug="current_value"
          value={goals.current_value}
          handleChange={handleSliderChange}
        />
        <WrapperMetodos>
          <Text size="medium" bold>
            Métodos adicionais
          </Text>
          <InputSelectBaseBadge>
            <div className="title">
              <InputRaduisBase type="radio" />
              <Text>Especifico</Text>
            </div>
          </InputSelectBaseBadge>
          <InputSelectBaseBadge>
            <div className="title">
              <InputRaduisBase type="radio" />
              <Text>Especifico Avançado</Text>
            </div>
            <Badge>+</Badge>
          </InputSelectBaseBadge>
          <InputSelectBaseBadge>
            <div className="title">
              <InputRaduisBase type="radio" />
              <Text>Especifico Candidaturas</Text>
            </div>
            <Badge>+</Badge>
          </InputSelectBaseBadge>
        </WrapperMetodos>
      </Wrapper>
    </Container>
  )
}

export default SetGoals
