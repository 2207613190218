import React from 'react'
import Button from 'componentsNew/Button'
import * as S from './styles'

interface FooterProps {
  current: number
  totalSteps: number
  prev: () => void
  next: () => void
  onNext?: () => void
  disableNext?: boolean
}

export const FooterOnboarding = ({ current, totalSteps, prev, next, onNext, disableNext }: FooterProps) => (
  <S.Footer>
    {current > 0 ? (
      <Button style={{ margin: '0 8px' }} onClick={prev} variant="minimal">
        Voltar
      </Button>
    ) : (
      <div />
    )}
    {current > 0 && <p> {`${current}/${totalSteps - 1}`}</p>}
    <Button type="primary" onClick={onNext || next} disabled={disableNext}>
      {current === totalSteps - 1 ? 'Finalizar' : 'Próximo'}
    </Button>
  </S.Footer>
)
