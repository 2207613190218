import React, { createContext, useContext, useState, ReactNode } from 'react'

interface ReportContextType {
  reportName: string
  setReportName: (name: string) => void
}

const ReportContext = createContext<ReportContextType | undefined>(undefined)

export const ReportProvider = ({ children }: { children: ReactNode }) => {
  const [reportName, setReportName] = useState<string>('')

  return <ReportContext.Provider value={{ reportName, setReportName }}>{children}</ReportContext.Provider>
}

export const useReport = (): ReportContextType => {
  const context = useContext(ReportContext)
  if (!context) {
    throw new Error('useReport must be used within a ReportProvider')
  }
  return context
}
