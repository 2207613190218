import { Select, SelectProps } from 'antd'
import { styled } from 'styled-components'

export const LabelLine = styled.div<{ withValue?: boolean; backgroundColor?: string }>`
  position: absolute;
  top: 30%;
  font-size: 14px;
  color: #a0a0a0;
  padding: 0 15px;
  margin: 0px 20px;
  transition: 0.2s ease;
  background: ${({ backgroundColor }) => backgroundColor || '#121212'};

  ${({ withValue }) =>
    withValue &&
    `
    color: #ffffff;
    height: 18px;
    line-height: 20px;
    transform: translate(-15px, -22px) scale(0.88);
    z-index: 21;
  `}
`

export const Wrapper = styled.main`
  position: relative;

  .ant-select-focused + ${LabelLine} {
    color: #ffffff;
    height: 18px;
    line-height: 20px;
    transform: translate(-15px, -22px) scale(0.88);
    z-index: 21;
  }
`
export const SelectCustom = styled(Select)<SelectProps>`
  width: 100%;
  height: 45px;

  @media screen and (max-width: 650px) {
    width: 100%;
  }

  .ant-select-selector {
    border-radius: 4px;
  }

  &::placeholder {
    display: none;
    opacity: 0;
    color: transparent;
  }

  &.ant-select-focused {
    color: #ffffff;
    border-color: #ffffff;
  }
`
