import React, { useState } from "react";

import { noop } from "lodash";

import Checkbox from "componentsNew/CheckBox";
import * as S from "./styles";

interface ProfileProps {
  avatar?: React.ReactNode | string;
  name?: string;
  type?: string;
  status?: "double-plus" | "plus" | "even" | "less";
}

interface filters {
  sentiment?: "positive" | "negative";
}
interface CardMentionsProps {
  label?: string;
  disputed_elections?: string;
  /*   profile?: ProfileProps */
  withProfile?: boolean;
  children?: React.ReactNode;
  filter?: boolean;
  options?: any[];
  default_mentions?: any;
  component?: (mentions: string[]) => React.ReactNode;
}

export const CardMentions = ({
  label,
  disputed_elections,
  withProfile,
  children,
  filter,

  options = [],
  default_mentions,
  component,
}: CardMentionsProps) => {
  const [mentions, setMentions] = useState<any[]>([]);

  const items = options?.map((item) => ({
    ...item,
    key: item?.id,
    label: (
      <RenderMenuItem
        defaultCheck={item?.is_listening}
        key={item?.id}
        label={item?.theme_name}
        type={item?.topic_name}
        onCheck={() => toggleLabelInMentions(item?.theme_name)}
      />
    ),
  }));

  const toggleLabelInMentions = (label: string) => {
    let updatedMentions: string[] = [...mentions];
    if (mentions.includes(label)) {
      updatedMentions = updatedMentions.filter((item) => item !== label);
    } else {
      updatedMentions.push(label);
    }

    setMentions(updatedMentions);
  };

  return (
    <S.Wrapper>
      <S.HeaderContainer>
        <S.Label>{label}</S.Label>

        {/* {!!filter && items.length > 0 && (
          <ButtonDropdown items={items}>
            <InternalButton background="#FFC000" icon={<MdFilterList />} variant="outline">
              Filtrar
            </InternalButton>
          </ButtonDropdown>
        )} */}
      </S.HeaderContainer>

      <S.Content>{component ? component(mentions) : ""}</S.Content>
    </S.Wrapper>
  );
};

export const RenderMenuItem = React.memo(
  ({
    label,
    onCheck = noop,
    type,
    defaultCheck,
  }: {
    label: string;
    type: string;
    onCheck?: () => void;
    defaultCheck?: boolean;
  }) => {
    const handleChange = (e: any) => {
      e.stopPropagation();
      onCheck();
    };

    return (
      <S.RenderItem onClick={(e) => handleChange(e)}>
        <Checkbox label={label} defaultChecked={defaultCheck} />
      </S.RenderItem>
    );
  },
);
