import React, { useEffect } from "react";
import TabsCustom from "componentsNew/Tabs";
import Spin from "componentsNew/Spin";
import { UnderConstruction } from "componentsNew/UnderConstruction";
import { GoalsTimeLine } from "../GoalsTimeLine";
import { GoalsValidVotes } from "../GoalsValidVotes";
import { GoalsActivities } from "../GoalsActivities";
import { GoalsExpenses } from "../GoalsExpenses";
import { GoalsDonations } from "../GoalsDonations";
import { useCandidates } from "../../hooks/useCandidates";
import { useTab } from "../../global/TabContext";
import * as S from "./styles";

const labels = [
  {
    label: "Cronograma",
    key: "tab_application_goals_timeline",
  },
  {
    label: "Votos Válidos",
    key: "tab_application_goals_valid_votes",
  },
  {
    label: "Atividades",
    key: "tab_application_goals_activities",
  },
  {
    label: "Despesas",
    key: "tab_application_goals_expenses",
  },
  {
    label: "Doações",
    key: "tab_application_goals_donations",
  },
  {
    label: "Segmentos Alvos",
    key: "tab_application_goals_target_segments",
  },
];

const content: React.ReactNode[] = [
  <GoalsTimeLine />,
  <GoalsValidVotes />,
  <GoalsActivities />,
  <GoalsExpenses />,
  <GoalsDonations />,
  <UnderConstruction />,
];

export const DashboardTrajectoryGoals: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState(
    "tab_application_goals_timeline",
  );
  const { isLoadedCandidates } = useCandidates();
  const { setActiveTabContext } = useTab();

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  const changePoliticalTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={content}
          onChange={(key) => changePoliticalTab(key)}
        />
      </S.Content>
    </S.Wrapper>
  );
};
