import { Topic } from "componentsNew/PoliticalListeningComponents/services/types/mentionsServiceTypes";
import IconFacebook from "assets/icon-facebook-listening.svg";
import IconInstagram from "assets/icon-instagram.svg";
import IconYouTube from "assets/icon-youtube.svg";
import IconLinkedIn from "assets/icon-linkedin-listening.svg";
import IconReddit from "assets/icon-reddit.svg";
import IconTT from "assets/icon-x-listening.svg";
import IconGoogle from "assets/iconGoogle.svg";
import IconTelegram from "assets/icon-telegram.svg";
import { handleServiceColor } from "utils/utilityFunctions";
import devicesIcon from "assets/devices.svg";
import infraIcon from "assets/antena.svg";
import iotIcon from "assets/iot.svg";
import tumblerIcon from "assets/tumbler.svg";
import networkIcon from "assets/network.svg";

type TransformedDataItem = {
  type: string;
  icon: string;
  value: number;
  label: string;
};

const iconMappings: Record<string, { icon: string; label: string }> = {
  ig_count: { icon: IconInstagram, label: "Instagram" },
  tt_count: { icon: IconTT, label: "Twitter" },
  yt_count: { icon: IconYouTube, label: "YouTube" },
  fb_count: { icon: IconFacebook, label: "Facebook" },
  in_count: { icon: IconLinkedIn, label: "LinkedIn" },
  rd_count: { icon: IconReddit, label: "Reddit" },
  gg_count: { icon: IconGoogle, label: "Google" },
  // tumblr_count: { icon: tumblerIcon, label: 'Tumbler' },
  tl_count: { icon: IconTelegram, label: "Telegram" },

  iot_count: { icon: iotIcon, label: "Assitente IOT " },
  infra_count: { icon: infraIcon, label: "Infraestrutura" },
  network_count: { icon: networkIcon, label: "Redes" },
  devices_count: { icon: devicesIcon, label: "Dispositivos " },
};

const colors = [
  "#6E2F9D",
  "#059D4A",
  "#B28801",
  "#650202",
  "#FF0000",
  "#9B008C",
  "#016DBA",
  "#813B0C",
  "#223458",
];

function column(apiData: Topic[], iconOrder: string[]): TransformedDataItem[] {
  const resultArray: TransformedDataItem[] = [];
  const counts: Record<string, number> = {};

  apiData.forEach((topic) => {
    Object.entries(topic).forEach(([key, value]) => {
      if (key !== "topic" && key !== "parent_name" && key !== "topic_count") {
        counts[key] = (counts[key] || 0) + value;
      }
    });
  });

  const orderedKeys = iconOrder.filter((key) => counts[key] !== undefined);

  orderedKeys.forEach((key) => {
    const { icon, label } = iconMappings[key] || {
      icon: IconTT,
      label: "Test",
    };
    const value = counts[key];

    resultArray.push({
      type: key,
      icon,
      value,
      label,
    });
  });

  return resultArray;
}

function row(apiData: Topic[], iconOrder: string[]): any[] {
  const rows: Record<string, any> = {};

  apiData.forEach((topic) => {
    const { topic_count, ...dadosPrimary } = topic;
    const serviceTopic = dadosPrimary?.parent_name || dadosPrimary.topic;
    const topicLabel = dadosPrimary.topic;
    const iconData = handleServiceColor(serviceTopic);

    if (!rows[topicLabel]) {
      rows[topicLabel] = {
        icon: iconData.icon || false,
        color: iconData.color,
        label: topicLabel,
        total_values: 0,
        values: {},
      };
    }

    Object.entries(dadosPrimary).forEach(([key, value]) => {
      if (key !== "topic" && key !== "parent_name" && key !== "topic_count") {
        rows[topicLabel].total_values += value || 0;
        rows[topicLabel].values[key] =
          (rows[topicLabel].values[key] || 0) + (value || 0);
      }
    });
  });

  return Object.values(rows).map((row) => ({
    ...row,
    values: iconOrder
      .filter((key) => row.values[key] !== undefined)
      .map((key) => row.values[key]),
  }));
}

export const formatter = (data: Topic[], orderFormatter: string[]) => {
  return {
    row: row(data, orderFormatter),
    column: column(data, orderFormatter),
  };
};
