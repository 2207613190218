import { CardGoals } from "componentsNew/CockpitComponents/components/CardGoals";
import { useCockPit } from "componentsNew/CockpitComponents/hooks/useCockpit";

import * as S from "./styles";

const SectionGoals = ({ isCockpit = false }) => {
  const { filters } = useCockPit();

  const cardTypes = [
    {
      type: "valid_votes",
      title: "Votos válidos (Eleitores)",
      subtitle: "Eleitores",
      status: "success",
      apiEndpoint: "/political-trajectory/valid-votes/",
    },
    {
      type: "activity",
      title: "Atividade (Interações)",
      subtitle: "Interações",
      status: "success",
      apiEndpoint: "/political-trajectory/activities/",
    },
    {
      type: "expenses",
      title: "Despesas (R$)",
      subtitle: "R$",
      status: "warning",
      apiEndpoint: "/political-trajectory/expenses/",
    },
    {
      type: "donations",
      title: "Doações (R$)",
      subtitle: "R$",
      status: "success",
      apiEndpoint: "/political-trajectory/donations/",
    },
  ];
  const shouldShowAll = !filters || filters.length === 0;

  return (
    <S.Wrapper>
      <S.CardList wrapped={filters ? filters.length >= 4 : true}>
        {cardTypes.map((cardType) =>
          shouldShowAll || (filters && filters.includes(cardType.type)) ? (
            <CardGoals
              width="100px"
              key={cardType.type}
              title={cardType.title}
              subtitle={cardType.subtitle}
              status={cardType.status}
              apiEndpoint={cardType.apiEndpoint}
              isCockpit={isCockpit}
            />
          ) : null,
        )}
      </S.CardList>
    </S.Wrapper>
  );
};

export { SectionGoals };
