import React from "react";
import { FaPlus, FaMinus, FaEquals } from "react-icons/fa";

export type SentimentType = "positive" | "negative" | "neutral" | undefined;

const useSentimentIcon = (sentiment: SentimentType, size = 8) => {
  switch (sentiment) {
    case "positive":
      return <FaPlus color="#00E23F" size={size} />;
    case "negative":
      return <FaMinus color="#FF0000" size={size} />;
    case "neutral":
      return <FaEquals color="#FFC000" size={size} />;
    default:
      return null;
  }
};

export default useSentimentIcon;
