import React from "react";
import { Carousel } from "antd";

interface IMediaCarouselProps {
  mediaUrls: string[];
  author: string;
}

const isVideo = (url: string) => {
  return url.includes("mp4") || url.includes("video");
};

const MediaCarousel: React.FC<IMediaCarouselProps> = ({
  mediaUrls,
  author,
}) => {
  return (
    <Carousel>
      {mediaUrls.map((url, index) => {
        const isMediaVideo = isVideo(url);

        return (
          <div key={index}>
            {isMediaVideo ? (
              <video controls width="100%" style={{ borderRadius: "16px" }}>
                <source src={url} type="video/mp4" />
                Seu navegador não suporta o formato do vídeo.
              </video>
            ) : (
              <img
                src={url}
                alt={`${author}-${index}`}
                style={{ borderRadius: "16px", width: "100%" }}
              />
            )}
          </div>
        );
      })}
    </Carousel>
  );
};

export default MediaCarousel;
