import React, { useState } from 'react'
import { FaStop } from 'react-icons/fa6'
import { ReactComponent as SendIcon } from '../../../../assets/helper/send-icon.svg'
import * as S from './styles'

const MessageInput = ({
  onSendMessage,
  isIAResponding,
  onStop,
}: {
  onSendMessage: (message: string) => void
  isIAResponding: boolean
  onStop: () => void
}) => {
  const [message, setMessage] = useState('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value)
  }

  const handleSend = () => {
    if (message.trim() && !isIAResponding) {
      onSendMessage(message)
      setMessage('')
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !isIAResponding) {
      handleSend()
    }
  }

  return (
    <S.InputContainer>
      <S.StyledInput
        value={message}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder="Escreva sua mensagem..."
        disabled={isIAResponding}
      />
      <S.SendButton onClick={isIAResponding ? onStop : handleSend}>
        {isIAResponding ? <FaStop /> : <SendIcon />}
      </S.SendButton>
    </S.InputContainer>
  )
}

export default MessageInput
