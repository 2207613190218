import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledCard = styled.div<{ isDropdownActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: #161617;
  width: 100%;
  height: ${({ isDropdownActive }) => (isDropdownActive ? "auto" : "56px")};
  overflow: hidden;
  transition: height 0.3s ease;
`;

export const DropdownHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  cursor: pointer;
`;

export const HeaderText = styled.div`
  display: flex;
  align-items: end;
  gap: 6px;

  .seven-days {
    height: 12px;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 300;
    line-height: 11.72px;
    color: #e9e9f0;
  }
`;

export const DropdownToggle = styled.div`
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TimeLine = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
`;

export const Goals = styled.div`
  // display: flex;
  padding: 8px;
`;

export const SectionContent = styled.div`
  width: 100%;
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ActionsBox = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  .ant-dropdown-menu-item-active {
    &:hover {
      color: #ffc000 !important;
    }
  }
`;

export const SectionHeading = styled.h3`
  display: flex;
  font-family: "Roboto", sans-serif;
  color: #e9e9f0;
  font-weight: 500;
  font-size: 24px;
  width: 100%;
  text-align: left;
  gap: 8px;

  .text-socials-mentions {
    display: flex;
    margin-top: 12px;
  }
`;

export const RenderItem = styled.div`
  color: white;
  min-width: 300px;
  display: flex;
  align-items: center;

  &:hover {
    color: #ffc000;
  }
`;
