import { CustomInput } from 'componentsNew/FormGenerator/components/CustomInput'

import { Progress } from 'antd'
import { z, ZodError } from 'zod'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'

import { useState } from 'react'
import { FooterOnboarding } from 'componentsNew/OnboardingComponents/Footer'
import { AnimatePresence } from 'framer-motion'

import { useStep } from 'componentsNew/OnboardingComponents/Hooks/useStep'
import * as S from './styles'

const schema = z
  .object({
    password: z
      .string()
      .min(8, 'A senha deve ter pelo menos 8 caracteres')
      .refine(val => /[A-Z]/.test(val), {
        message: 'A senha deve conter pelo menos uma letra maiúscula',
      })
      .refine(val => /[a-z]/.test(val), {
        message: 'A senha deve conter pelo menos uma letra minúscula',
      })
      .refine(val => /[0-9]/.test(val), {
        message: 'A senha deve conter pelo menos um número',
      })
      .refine(val => /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/.test(val), {
        message: 'A senha deve conter pelo menos um símbolo',
      }),
    confirmPassword: z.string(),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'As senhas não coincidem',
  })

type FormValues = {
  password: string
  confirmPassword: string
}

export const PassWordForm = () => {
  const { setCurrentStep, currentStep, passwordData, handlePassword } = useStep()
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [formError, setFormError] = useState<string | null>(null)
  const [passwordStrength, setPasswordStrength] = useState<number>(0)
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [validationResults, setValidationResults] = useState<{
    uppercase: boolean
    lowercase: boolean
    number: boolean
    symbol: boolean
  }>({
    uppercase: false,
    lowercase: false,
    number: false,
    symbol: false,
  })

  const handlePasswordChange = (value: string) => {
    setPassword(value)
    handlePassword(value)
    setIsTyping(true)
    validatePassword(value)
  }

  const handleConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value)
    validateConfirmPassword(value)
  }

  const validatePassword = (value: string) => {
    const validationResults = {
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      symbol: /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/.test(value),
    }
    setValidationResults(validationResults)
    setPasswordStrength(calculatePasswordStrength(validationResults))
    try {
      schema.parse({ password: value })
      setFormError(null)
    } catch (error) {
      if (error instanceof ZodError) {
        if (error.errors[0]?.message === 'As senhas não coincidem') {
          const errorMessage = error.errors.map(err => err.message).join(' ')
          setFormError(errorMessage)
        } else {
          setFormError(null)
        }
      }
    }
  }

  const validateConfirmPassword = (value: string) => {
    try {
      schema.parse({ password, confirmPassword: value })
      setFormError(null)
    } catch (error) {
      if (error instanceof ZodError && error.errors[0]?.message === 'As senhas não coincidem') {
        const errorMessage = error.errors.map(err => err.message).join(' ')
        setFormError(errorMessage)
      } else {
        setFormError(null)
      }
    }
  }

  const calculatePasswordStrength = (results: {
    uppercase: boolean
    lowercase: boolean
    number: boolean
    symbol: boolean
  }) => {
    const { uppercase, lowercase, number, symbol } = results
    let strength = 0
    if (uppercase) strength += 25
    if (lowercase) strength += 25
    if (number) strength += 25
    if (symbol) strength += 25
    return strength
  }

  const next = () => {
    setCurrentStep(prevStep => prevStep + 1)
  }

  const prev = () => {
    setCurrentStep(prevStep => prevStep - 1)
  }

  const validateDisabled = passwordStrength !== 100 || !password || !confirmPassword || confirmPassword !== password

  return (
    <S.Wrapper>
      <S.Heading>Olá, José!</S.Heading>
      <AnimatePresence mode="wait">
        <S.Content
          key={currentStep}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <S.Text>
            Coloque uma nova senha.
            <br /> Use uma senha forte que você não esteja usando em nenhum outro lugar.
          </S.Text>

          <S.ContentForm>
            <CustomInput
              type="password"
              id="password"
              placeholder="Senha"
              required
              value={password}
              onChange={(e: any) => handlePasswordChange(e.target.value)}
            />
            <CustomInput
              type="password"
              id="confirmPassword"
              placeholder="Confirme a senha"
              required
              value={confirmPassword}
              onChange={(e: any) => handleConfirmPasswordChange(e.target.value)}
            />
          </S.ContentForm>

          {formError && isTyping && (
            <S.BoxMessage initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <S.ErrorMessage>{formError}</S.ErrorMessage>
            </S.BoxMessage>
          )}

          {isTyping && (
            <S.WrapperValidatePassword>
              <S.HeadingItems>Progresso para uma senha forte:</S.HeadingItems>
              <S.ProgressBox initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                {passwordStrength > 0 && (
                  <Progress
                    size="small"
                    percent={passwordStrength}
                    status={passwordStrength >= 50 ? 'success' : 'exception'}
                  />
                )}
              </S.ProgressBox>
              <ul>
                <li>
                  <S.ItemValidate valid={validationResults.uppercase}>
                    Pelo menos uma letra maiúscula <IoIosCheckmarkCircleOutline />
                  </S.ItemValidate>
                </li>
                <li>
                  <S.ItemValidate valid={validationResults.lowercase}>
                    Pelo menos uma letra minúscula <IoIosCheckmarkCircleOutline />
                  </S.ItemValidate>
                </li>
                <li>
                  <S.ItemValidate valid={validationResults.number}>
                    Pelo menos um número <IoIosCheckmarkCircleOutline />
                  </S.ItemValidate>
                </li>
                <li>
                  <S.ItemValidate valid={validationResults.symbol}>
                    Pelo menos um símbolo <IoIosCheckmarkCircleOutline />
                  </S.ItemValidate>
                </li>
              </ul>
            </S.WrapperValidatePassword>
          )}
        </S.Content>
      </AnimatePresence>
      {/*  <Button type="primary" disabled={!isStrongPassword}>
        Submit
      </Button> */}

      <FooterOnboarding current={currentStep} totalSteps={3} next={next} prev={prev} disableNext={validateDisabled} />
    </S.Wrapper>
  )
}
