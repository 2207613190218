import styled, { css } from "styled-components";

interface ICandCard {
  candidateCardOpen: boolean;
}

export const Container = styled.div`
  margin-top: -2rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;
`;

export const Section = styled.div`
  width: 100%;
  max-width: 1200px;
`;
export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ActionsBox = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  .ant-dropdown-menu-item-active {
    &:hover {
      color: #ffc000 !important;
    }
  }
`;

export const SectionHeading = styled.h3`
  display: flex;
  font-family: "Roboto", sans-serif;
  color: #e9e9f0;
  font-weight: 500;
  font-size: 24px;
  width: 100%;
  text-align: left;
  gap: 8px;

  .text-socials-mentions {
    display: flex;
    margin-top: 12px;
  }
`;

export const SectionContent = styled.div`
  width: 100%;
`;

export const CardList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const CardProgress = styled.div`
  width: 100%;
  background: #161717;
  border-radius: 8px;
  height: 120px;
`;

export const ActiveCandidateCard = styled.div<ICandCard>`
  margin-left: ${({ candidateCardOpen }) => (candidateCardOpen ? "12rem" : 0)};
  transition: margin-left 0.3s ease-in-out;
`;

export const AlertBanner = styled.div<{ sidebarOpen: boolean }>`
  ${({ sidebarOpen }) => css`
    background: #0a0a0a;
    height: 27px;
    width: 100%;
    border-top: 1px solid #494949;
    border-bottom: 1px solid #494949;
    position: fixed;
    z-index: 4;
    top: 64px;
    left: 0;
    margin-left: ${sidebarOpen ? "7.87rem" : "2.8rem"};
    transition: 0.3s;
  `}
`;

export const RenderItem = styled.div`
  color: white;
  min-width: 300px;
  display: flex;
  align-items: center;

  &:hover {
    color: #ffc000;
  }
`;
