import React, { useState } from 'react'
import { BaseTextFieldProps } from '@mui/material/TextField'
import { InputAdornment, IconButton } from '@mui/material'
import { MdError, MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { GiConfirmed } from 'react-icons/gi'

import theme from 'styles/theme'
import { CustomInput } from './styles'

export interface CustomInputProps extends BaseTextFieldProps {
  placeholder?: string
  icon?: 'error' | 'success'
  buttonEnd?: React.ReactNode
  iconStart?: React.ReactNode
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  [key: string]: any
}

export const Input = ({
  icon = 'error',
  error,
  buttonEnd,
  variant = 'outlined',
  iconStart,
  type = 'text',
  ...rest
}: CustomInputProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword(!showPassword)

  const selectIcon = () => {
    if (icon === 'success') {
      return <GiConfirmed />
    }

    if (icon === 'error' && error) {
      return <MdError color={theme.COLORS.red10} size={20} />
    }

    if (type === 'password') {
      return (
        <IconButton onClick={toggleShowPassword} edge="end">
          {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
        </IconButton>
      )
    }

    if (buttonEnd) {
      return buttonEnd
    }
    return null
  }

  return (
    <CustomInput
      className={`custom_input ${error ? 'error' : ''}`}
      variant={variant}
      error={error}
      type={showPassword ? 'text' : type}
      InputProps={{
        startAdornment: !!iconStart && <InputAdornment position="start">{iconStart}</InputAdornment>,
        endAdornment: !!selectIcon && <InputAdornment position="end">{selectIcon()}</InputAdornment>,
      }}
      {...rest}
    />
  )
}
