// components/Input.tsx
import React from "react";
import { useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";

interface IInput {
  name: string;
  label: string;
  type?: string;
  defaultValue?: string;
  disabled?: boolean;
  host: any;
  endpoint: string;
  width?: string;
}

const Input: React.FC<IInput> = ({
  name,
  label,
  type = "text",
  defaultValue,
  disabled,
  host,
  endpoint,
  width,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  console.log(name, defaultValue);

  return (
    <TextField
      {...register(name)}
      label={label}
      type={type}
      defaultValue={defaultValue}
      disabled={disabled}
      error={!!errors[name]}
      helperText={errors[name]?.message?.toString()}
      variant="outlined"
      fullWidth
    />
  );
};

export default Input;
