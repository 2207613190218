const AUTH_TOKEN = 'authToken'

const ROTES = {
  LOGIN: '/login',
  ONBOARDING: 'onboarding',
  LOGOUT: '/logout',
  REGISTER_CANDIDATE: 'register-candidate',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RECOVER_PASSWORD: '/recover-password',
  PASSWORD_RECOVER: '/forgot-password/:userId/:token/:boolean',
  REGISTRATION_PROCESS: '/registration-process/:userId/:token/:boolean',
  REGISTER_PASSWORD: '/register-password',
  REGISTER_PROFILE_CONFIRM: '/register-profile-confirm',
  REGISTER_CAND_OBJECTIVE: '/register-cand-objective',
  REGISTER_CAND_AUTHENTICATION: '/register-cand-authentication',
  REGISTER_CAND_AUTHENTICATION_MESSAGE: '/register-cand-authentication-message',
  REGISTER_REPRESENTATIVE_CAND_DATA: '/register-representative-cand-data',
  DASHBOARD: '/dashboard/*',
  DASHBOARD_COCKPIT: 'cockpit',
  APPLICATION_STRATEGY_ANALYSIS: 'application-strategy/strategy',
  APPLICATION_STRATEGY_GOALS: 'application-strategy/goals',
  APPLICATION_MANAGEMENT_TEAM: 'application-management/team',
  VOTER_DIAGNOSIS: 'political-intelligence/voter-diagnosis',
  ELECTION_DIAGNOSIS: 'political-intelligence/election-diagnosis',
  ELECTION_POOLS: 'political-intelligence/election-polls',
  POLITICAL_LISTENING: 'political-intelligence/political-listening',
  PREFERRED_CANDIDATE: 'political-intelligence/preferred-candidate',
  POLITICAL_ARCHETYPE: 'political-intelligence/political-archetype',
  POLITICAL_PROFILE: 'political-trajectory/political-profile',
  PARTY_DIAGNOSIS: 'portfolio/party-diagnosis',
  SPONSORED_APPLICATIONS: 'sponsored-applications',
  SPONSORED_APPLICATIONS_NATIONAL_PANORAMA: 'sponsored-applications/national-panorama/:id',
  ALL_SPONSORED: 'all-applications',
  ALL_SPONSORED_NATIONAL_PANORAMA: 'all-applications/national-panorama/:id',
  POLITICAL_VOX: 'political-vox',
  POLITICAL_VOX_SEARCH: 'political-intelligence/political-vox-search',
  ELECTORAL_WIZARD: 'electoral-profile/wizard',
  ACQUISITIONS: 'acquisitions/signature',
  SETTINGS_PROFILE: 'settings/profile',
  NOT_FOUND: 'not-found',
  HUBSPOT: 'hubspot',
  HUB_SPOT: 'hub-spot',

  GOALS_TIMELINE: 'trajectory/goals/timeline',
  GOALS_VALID_VOTES: 'trajectory/goals/valid-votes',
  ACQUISITIONS_SIGNATURE: 'acquisitions/signature/order',

  MANAGEMENT_TEAM: 'application-management/team',
}

const POSITIVE = 'positive'
const NEGATIVE = 'negative'
const ALL = 'all'

export { AUTH_TOKEN, ROTES, POSITIVE, NEGATIVE, ALL }
