import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { api } from 'services/apiService'
import { PayloadPostTheme } from '../types/ENVIRONMENT_DIAGNOSIS'
import {
  PersonEnvThemeListResponseTypes,
  ThemesListResponse,
  UpdateWizardStatusTypes,
  WizardGetResponse,
} from './types/macroEnvServicesTypes'

interface ErrorMessage {
  message: string
}

type responseGetWizards = WizardGetResponse | ErrorMessage
type responseThemeList = ThemesListResponse | ErrorMessage

export const getWizards = async (): Promise<responseGetWizards> => {
  try {
    const { data } = await api.get(`/electoral-intelligence/environment-diagnosis-wizards/`)
    /*   const { data } = await simulateRequest(MOCK_RESPONSE_WIZARD) */

    if (data) {
      return data
    }

    return { message: 'fail test' }
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}

export const getThemes = async (): Promise<responseThemeList> => {
  try {
    const { data } = await api.get(`/electoral-intelligence/environment-themes/`)
    /* const { data } = await simulateRequest(MOCK_THEMES_ENV) */

    if (data) {
      return data
    }

    return { message: 'Não foi possivel buscar dados cadastrados.' }
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}

export const getPersonEnv = async (): Promise<any> => {
  try {
    const { data }: AxiosResponse<PersonEnvThemeListResponseTypes[]> = await api.get(
      `/electoral-intelligence/person-environment-diagnosis-themes/`
    )

    return data
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}

export const acceptWizard = async ({
  wizard_id,
  status,
}: {
  wizard_id: number
  status: UpdateWizardStatusTypes
}): Promise<any> => {
  try {
    const { data } = await api.put(`/electoral-intelligence/environment-diagnosis-wizards/${wizard_id}/`, {
      status,
    })

    return data
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}

export const sendTheme = async ({ theme_data }: { theme_data: PayloadPostTheme }): Promise<any> => {
  try {
    const { data } = await api.post(`/electoral-intelligence/person-environment-diagnosis-themes/`, {
      ...theme_data,
    })

    return data
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}

export const updateListener = async ({
  theme_id,
  is_listening,
}: {
  theme_id: number
  is_listening: boolean
}): Promise<any> => {
  try {
    const { data } = await api.put(`/electoral-intelligence/person-environment-diagnosis-themes/${theme_id}/`, {
      is_listening,
    })

    return data
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}

export const deleteTheme = async (id: number | string) => {
  try {
    const url = `/electoral-intelligence/person-environment-diagnosis-themes/${id}/`
    const { data } = await api.delete(url)

    toast.success('Tema removido com sucesso.')
  } catch (e) {
    console.error('Erro na requisição:', e)
    toast.error('Falha ao remover o tema, tente novamente.')
  }
}
