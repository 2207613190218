/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { IoMdAdd, IoMdRemove } from 'react-icons/io'
import { toast } from 'react-toastify'
import wizard_icon from 'icons/wizard_icon.svg'
import { removeTopic, updateTopic } from 'componentsNew/ElectionStrategyComponents/services/updateByTopic'
import { useUser } from 'global/UserContext'
import {
  acceptWizard,
  deleteTheme,
  getPersonEnv,
  getThemes,
  getWizards,
  sendTheme,
  updateListener,
} from '../../services/macroEnvServices'
import { WizardResultData } from '../../services/types/macroEnvServicesTypes'
import { useMacroEnv } from '../../hooks/useMacroEnv'
import { CardWizard } from '../../components/CardWizard'
import { CardSelection } from '../../components/CardSelection'
import * as S from './styles'

interface PostThemeDataProps {
  topicList: any[]
  topicIndex: number
  themeIndex: number
  newValue: any
  isPositive?: boolean
  themeName?: string
}

const MacroEnv: React.FC = () => {
  const {
    topicNegativeList,
    topicPositiveList,
    updatePositiveList,
    updateListByTopic,
    changeSelect,
    updateNegativeList,
    defaultSelect,
  } = useMacroEnv()
  const [visiblePositiveWizards, setVisiblePositiveWizards] = useState<WizardResultData[] | null>(null)
  const [visibleNegativeWizards, setVisibleNegativeWizards] = useState<WizardResultData[] | null>([])
  const { user } = useUser()

  useEffect(() => {
    fetchThemes()
    fetchPersonEnv()
    fetchWizardData()
  }, [])

  const fetchWizardData = async () => {
    try {
      const wizardList = await getWizards()
      if ('message' in wizardList) {
        toast.error(wizardList.message)
      } else {
        const positiveWizards = wizardList.results.filter(wizard => wizard.is_positive) || []
        const negativeWizards = wizardList.results.filter(wizard => !wizard.is_positive) || []

        setVisiblePositiveWizards(positiveWizards.length > 0 ? positiveWizards : null)
        setVisibleNegativeWizards(negativeWizards.length > 0 ? negativeWizards : null)
      }
    } catch (error) {
      toast.error('Error fetching wizard data')
    }
  }

  const fetchThemes = async () => {
    const themes = await getThemes()
    if (!('message' in themes)) {
      changeSelect(themes.results)
    }
  }

  const handleCardClick = (isPositive: boolean, data: any) => {
    const wizardLabel = isPositive ? data.positive_name : data.negative_name
    const visibleWizards = isPositive ? visiblePositiveWizards : visibleNegativeWizards

    if (visibleWizards && visibleWizards.length > 0) {
      const clickedWizard = visibleWizards.find(wizard => wizard.id === data.id)
      const selectedTopic = topicPositiveList.find(topic => topic.topic_name === data.topic_name)

      if (clickedWizard && selectedTopic?.theme_list) {
        const clickedWIzardData = {
          ...clickedWizard,
          id: clickedWizard.id,
          name: clickedWizard.theme_name,
          is_recommended: true,
          value: clickedWizard.id,
          label: clickedWizard.theme_name,
        }

        updateListByTopic(isPositive, clickedWIzardData)
      }

      const updatedWizards = visibleWizards.filter(wizard => wizard.id !== data.id)

      // eslint-disable-next-line no-unused-expressions
      isPositive
        ? setVisiblePositiveWizards(updatedWizards.length > 0 ? updatedWizards : null)
        : setVisibleNegativeWizards(updatedWizards.length > 0 ? updatedWizards : null)
    }
  }

  const handleRemoveCard = (isPositive: boolean, wizardId: number) => {
    acceptWizard({ wizard_id: wizardId, status: 'rejected' })
    const updatedWizards = isPositive
      ? (visiblePositiveWizards || []).filter(wizard => wizard.id !== wizardId)
      : (visibleNegativeWizards || []).filter(wizard => wizard.id !== wizardId)

    isPositive
      ? setVisiblePositiveWizards(updatedWizards.length > 0 ? updatedWizards : null)
      : setVisibleNegativeWizards(updatedWizards.length > 0 ? updatedWizards : null)
  }

  const fetchPersonEnv = async () => {
    try {
      const selected_themes = await getPersonEnv()
      if ('message' in selected_themes) {
        toast.error(selected_themes.message)
      } else {
        const default_themes = selected_themes.results

        const positiveTheme = topicPositiveList.map(topicItem => {
          const themeList = default_themes
            .filter((defaultTheme: any) => defaultTheme.topic_name === topicItem.topic_name && defaultTheme.is_positive)
            .map((item: any) => ({
              ...item,
              name: item?.positive_name,
              label: item?.positive_name,
            }))

          return {
            ...topicItem,
            theme_list: [...themeList, ...topicItem.theme_list],
          }
        })

        const negativeTheme = topicNegativeList.map(topicItem => {
          const themeList = default_themes
            .filter(
              (defaultTheme: any) => defaultTheme.topic_name === topicItem.topic_name && !defaultTheme.is_positive
            )
            .map((item: any) => ({
              ...item,
              name: item?.negative_name,
              label: item?.negative_name,
            }))

          return {
            ...topicItem,
            theme_list: [...themeList, ...topicItem.theme_list],
          }
        })
        updatePositiveList(positiveTheme)
        updateNegativeList(negativeTheme)

        console.log('list 1', positiveTheme)
        console.log('list 2', negativeTheme)
      }
    } catch (error) {
      console.log('Error fetching themes')
    }
  }

  const postThemeData = async ({
    topicList,
    topicIndex,
    themeIndex,
    newValue,
    isPositive,
    themeName,
  }: PostThemeDataProps) => {
    const themeLabel = isPositive ? newValue.positive_name : newValue.negative_name
    const apiValue = await sendTheme({
      theme_data: { ...newValue?.data, person: user?.id, is_positive: isPositive, theme_name: themeLabel },
    })
    const newData = {
      id: apiValue?.id,
      value: apiValue?.id,
      theme: apiValue?.id,
    }
    const newData2 = updateTopic(topicList, topicIndex, themeIndex, { ...newValue?.data, ...newData })
    isPositive ? updatePositiveList(newData2) : updateNegativeList(newData2)
  }

  const updateListnerById = (checked: boolean, id: number) => {
    updateListener({ theme_id: id, is_listening: checked })
  }

  const removeTheme = ({ topicList, topicIndex, themeIndex, newValue }: PostThemeDataProps) => {
    deleteTheme(newValue?.id)
    removeTopic(topicList, topicIndex, themeIndex)
  }
  return (
    <S.Wrapper>
      <S.Section>
        <S.Box>
          <S.Heading>
            <S.WizardIcon src={wizard_icon} alt="wizard icon" />
            Wizard Eleitoral
          </S.Heading>

          <S.ContentWizard>
            {visiblePositiveWizards &&
              visiblePositiveWizards.slice(0, 1).map((wizard: WizardResultData) => (
                <CardWizard
                  key={wizard.id}
                  data={{
                    ...wizard,
                    theme_name: wizard.is_positive ? (wizard as any).positive_name : (wizard as any).negative_name,
                  }}
                  onCardSelect={data => handleCardClick(true, data)}
                  onRemove={() => handleRemoveCard(true, wizard.id)}
                  actionCard={data => acceptWizard({ wizard_id: wizard.id, status: 'approved' })}
                />
              ))}

            {visibleNegativeWizards &&
              visibleNegativeWizards.slice(0, 1).map((wizard: WizardResultData) => (
                <CardWizard
                  key={wizard.id}
                  data={{
                    ...wizard,
                    theme_name: wizard.is_positive ? (wizard as any).positive_name : (wizard as any).negative_name,
                  }}
                  onCardSelect={data => handleCardClick(true, data)}
                  onRemove={() => handleRemoveCard(true, wizard.id)}
                  actionCard={data => acceptWizard({ wizard_id: wizard.id, status: 'approved' })}
                />
              ))}
          </S.ContentWizard>
        </S.Box>
      </S.Section>

      <S.GridListSelection>
        <S.ContentList>
          <S.Box>
            <S.TitleList onClick={() => console.log('lk post', topicPositiveList[0])}>
              POSITIVOS (<IoMdAdd color="#00E23F" size={16} /> )
            </S.TitleList>
          </S.Box>
          {topicPositiveList.map((item, topicIndex) => {
            const themeList = defaultSelect
              .filter(theme => theme.topic_name === item?.topic_name)
              .map((item: any) => ({
                ...item,
                name: item?.positive_name,
              }))
            return (
              <S.BoxListGrid key={item.id}>
                <CardSelection
                  keyType={item.topic}
                  withIcon
                  default_theme={themeList}
                  dataSelection={item}
                  onChangeListener={(checked, id) => updateListnerById(checked, id)}
                  onChange={(index: any, value: any) =>
                    postThemeData({
                      topicList: topicPositiveList,
                      topicIndex,
                      themeIndex: index,
                      newValue: value,
                      isPositive: true,
                      themeName: value,
                    })
                  }
                  onRemoveTheme={(index: any, value: any) =>
                    removeTheme({
                      topicList: topicPositiveList,
                      topicIndex,
                      themeIndex: index,
                      newValue: value,
                      isPositive: true,
                      themeName: value,
                    })
                  }
                />
              </S.BoxListGrid>
            )
          })}
        </S.ContentList>

        <S.ContentList>
          <S.Box>
            <S.TitleList>
              NEGATIVOS (<IoMdRemove color="#D70708" />)
            </S.TitleList>
          </S.Box>

          {topicNegativeList.map((item, topicIndex) => {
            const themeList = defaultSelect
              .filter(theme => theme.topic_name === item?.topic_name)
              .map((item: any) => ({
                ...item,
                name: item?.negative_name,
              }))
            return (
              <S.BoxListGrid key={item.id}>
                <CardSelection
                  withIcon
                  isMore={false}
                  dataSelection={item}
                  default_theme={themeList}
                  onChangeListener={(checked, id) => updateListnerById(checked, id)}
                  onChange={(index: any, value: any) =>
                    postThemeData({
                      topicList: topicNegativeList,
                      topicIndex,
                      themeIndex: index,
                      newValue: value,
                      isPositive: false,
                      themeName: value,
                    })
                  }
                  onRemoveTheme={(index: any, value: any) =>
                    removeTheme({
                      topicList: topicNegativeList,
                      topicIndex,
                      themeIndex: index,
                      newValue: value,
                      isPositive: false,
                      themeName: value,
                    })
                  }
                />
              </S.BoxListGrid>
            )
          })}
        </S.ContentList>
      </S.GridListSelection>
    </S.Wrapper>
  )
}

export default MacroEnv
