import React, { useEffect, useState } from 'react'
import Spin from 'componentsNew/Spin'
import { Container } from './styles'

const HubSpotMeetings: React.FC = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
    script.async = true
    script.onload = () => {
      setLoading(false)
    }
    script.onerror = () => {
      setLoading(false)
    }
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      {loading && <Spin fullscreen />}
      <Container>
        <div
          className="meetings-iframe-container"
          data-src="https://meetings.hubspot.com/comercial-candidattus/agenda?embed=true"
        />
      </Container>
    </>
  )
}

export default HubSpotMeetings
