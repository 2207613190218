import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "@mui/material";
import {
  FaRegCopy,
  FaThumbsUp,
  FaThumbsDown,
  FaVolumeUp,
  FaStop,
  FaArrowDown,
} from "react-icons/fa";
import { IoReload } from "react-icons/io5";
import ReactMarkdown from "react-markdown";
import { apiCopilot } from "services/apiService";
import * as S from "./styles";

interface IMessage {
  id?: string;
  chat_id?: string;
  text: string;
  content?: string;
  type: "human" | "ia";
  finished: boolean;
  title?: string;
  message_id?: string;
  liked?: boolean;
  disliked?: boolean;
  feedback?: string;
  payload?: any;
}

interface IStreamingMarkdownViewer {
  messages: IMessage[];
  externalRef?: React.RefObject<HTMLDivElement>;
  onReloadMessage: (messageId: string, payload: any, chat_id?: string) => void;
}

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

const updateFeedback = async (
  message: IMessage,
  feedback: "positive" | "negative" | null,
  setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>,
) => {
  const messageId = message.id || message.message_id;
  if (!message.chat_id || !messageId) {
    console.error("chat_id or message_id is missing");
    return;
  }

  const feedbackType = feedback ? `?feedback=${feedback}` : "";
  try {
    await apiCopilot.patch(
      `/copilot/v1/chats/${message.chat_id}/messages/${messageId}${feedbackType}`,
    );
    setMessages((prevMessages: any) => {
      return prevMessages.map((m: any) => {
        if (m.id === message.id || m.message_id === message.message_id) {
          console.log(
            `Updating message ${m.id || m.message_id} with feedback: ${feedback}`,
          );
          return {
            ...m,
            liked:
              feedback === "positive"
                ? true
                : feedback === null
                  ? false
                  : m.liked,
            disliked:
              feedback === "negative"
                ? true
                : feedback === null
                  ? false
                  : m.disliked,
            feedback: feedback || null,
          };
        }
        return m;
      });
    });
  } catch (error) {
    console.error("Failed to update feedback:", error);
  }
};

const toggleLike = (
  message: IMessage,
  setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>,
  setLocalFeedback: React.Dispatch<
    React.SetStateAction<{
      [key: string]: { liked: boolean; disliked: boolean };
    }>
  >,
  localFeedback: { [key: string]: { liked: boolean; disliked: boolean } },
) => {
  console.log("toggleLike", message);
  const feedback = message.liked ? null : "positive";
  setLocalFeedback((prevFeedback) => ({
    ...prevFeedback,
    [message.id || message.message_id || ""]: {
      liked: feedback === "positive",
      disliked: feedback !== "positive" && false,
    },
  }));
  updateFeedback(message, feedback, setMessages);
};

const toggleDislike = (
  message: IMessage,
  setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>,
  setLocalFeedback: React.Dispatch<
    React.SetStateAction<{
      [key: string]: { liked: boolean; disliked: boolean };
    }>
  >,
  localFeedback: { [key: string]: { liked: boolean; disliked: boolean } },
) => {
  console.log("toggleDislike", message);
  const feedback = message.disliked ? null : "negative";
  setLocalFeedback((prevFeedback) => ({
    ...prevFeedback,
    [message.id || message.message_id || ""]: {
      liked: feedback !== "negative" && false,
      disliked: feedback === "negative",
    },
  }));
  updateFeedback(message, feedback, setMessages);
};

const speakText = (
  text: string,
  // eslint-disable-next-line no-undef
  voice: SpeechSynthesisVoice | null,
  rate: number,
  setSpeaking: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const utterance = new SpeechSynthesisUtterance(text);
  if (voice) {
    utterance.voice = voice;
  }
  utterance.rate = rate;
  utterance.onend = () => setSpeaking(false);
  setSpeaking(true);
  window.speechSynthesis.speak(utterance);
};

const stopSpeaking = (
  setSpeaking: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  window.speechSynthesis.cancel();
  setSpeaking(false);
};

const stripMarkdown = (text: string) => {
  // eslint-disable-next-line no-useless-escape
  return text.replace(/[\*_\[\]\(\)#\-`]/g, "");
};

const StreamingMarkdownViewer: React.FC<IStreamingMarkdownViewer> = ({
  messages,
  externalRef,
  onReloadMessage,
}) => {
  const localRef = useRef<HTMLDivElement>(null);
  const ref = externalRef || localRef;

  const [displayMessages, setDisplayMessages] = useState<IMessage[]>([]);
  const [localFeedback, setLocalFeedback] = useState<{
    [key: string]: { liked: boolean; disliked: boolean };
  }>({});
  const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
  const [selectedVoice, setSelectedVoice] =
    useState<SpeechSynthesisVoice | null>(null);
  const [speechRate, setSpeechRate] = useState<number>(2);
  const [isSpeaking, setIsSpeaking] = useState<boolean>(false);

  const [autoScroll, setAutoScroll] = useState<boolean>(true);
  const [showScrollToBottom, setShowScrollToBottom] = useState<boolean>(false);

  console.log("MESSAGES: ", messages);

  useEffect(() => {
    const loadVoices = () => {
      const voices = window.speechSynthesis.getVoices();
      setVoices(voices);
      const femaleVoice = voices.find(
        (voice) =>
          voice.name.includes("Microsoft") && voice.name.includes("Maria"),
      );
      setSelectedVoice(femaleVoice || voices[0]);
    };
    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;
  }, []);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage) {
      if (lastMessage.type === "ia" && !lastMessage.finished) {
        setDisplayMessages((prev) => [...prev.slice(0, -1), lastMessage]);
      } else {
        setDisplayMessages((prev) => [...prev, lastMessage]);
      }
    }
  }, [messages]);

  useEffect(() => {
    if (ref.current && autoScroll) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [displayMessages, ref, autoScroll]);

  const handleScroll = () => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setAutoScroll(true);
        setShowScrollToBottom(false);
      } else {
        setAutoScroll(false);
        setShowScrollToBottom(true);
      }
    }
  };

  const scrollToBottom = () => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
      setAutoScroll(true);
      setShowScrollToBottom(false);
    }
  };

  const renderers = {
    link: ({ href, children }: any) => (
      <a
        href={href}
        style={{ color: "#ffc000", fontWeight: "bold", fontStyle: "italic" }}
      >
        {children}
      </a>
    ),
  };

  return (
    <S.Container>
      <S.ChatCopilot ref={ref} onScroll={handleScroll}>
        {messages.map((msg) => {
          const localStatus = localFeedback[msg.id || msg.message_id || ""] || {
            liked: msg.liked,
            disliked: msg.disliked,
          };
          return (
            <React.Fragment key={msg.id || msg.message_id}>
              {msg.type === "human" && (
                <S.UserMessage>{msg.text || msg.content}</S.UserMessage>
              )}
              <S.IAMessage>
                {msg.type !== "human" && (
                  <ReactMarkdown components={renderers}>
                    {msg.content}
                  </ReactMarkdown>
                )}
                {msg.finished && msg.type !== "human" && (
                  <S.MessageActions>
                    <Tooltip title="Copiar">
                      <div>
                        <FaRegCopy
                          onClick={() => copyToClipboard(msg.content || "")}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="Boa resposta">
                      <div>
                        <FaThumbsUp
                          onClick={() =>
                            toggleLike(
                              msg,
                              setDisplayMessages,
                              setLocalFeedback,
                              localFeedback,
                            )
                          }
                          color={
                            localStatus.liked || msg.feedback === "positive"
                              ? "#ffc000"
                              : "inherit"
                          }
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="Má resposta">
                      <div>
                        <FaThumbsDown
                          onClick={() =>
                            toggleDislike(
                              msg,
                              setDisplayMessages,
                              setLocalFeedback,
                              localFeedback,
                            )
                          }
                          color={
                            localStatus.disliked || msg.feedback === "negative"
                              ? "#ffc000"
                              : "inherit"
                          }
                        />
                      </div>
                    </Tooltip>
                    {msg.payload && (
                      <Tooltip title="Regenerar">
                        <div>
                          <IoReload
                            onClick={() =>
                              onReloadMessage(
                                msg.id || "",
                                msg.payload,
                                msg?.chat_id,
                              )
                            }
                          />
                        </div>
                      </Tooltip>
                    )}
                    {isSpeaking ? (
                      <Tooltip title="Parar leitura em voz alta">
                        <div>
                          <FaStop onClick={() => stopSpeaking(setIsSpeaking)} />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Ler em voz alta">
                        <div>
                          <FaVolumeUp
                            onClick={() =>
                              speakText(
                                stripMarkdown(msg.content || ""),
                                selectedVoice,
                                speechRate,
                                setIsSpeaking,
                              )
                            }
                          />
                        </div>
                      </Tooltip>
                    )}
                  </S.MessageActions>
                )}
              </S.IAMessage>
            </React.Fragment>
          );
        })}
      </S.ChatCopilot>
      {showScrollToBottom && (
        <S.ScrollToBottom onClick={scrollToBottom}>
          <FaArrowDown />
        </S.ScrollToBottom>
      )}
    </S.Container>
  );
};

export default StreamingMarkdownViewer;
