import React from 'react'
import { useNavigate } from 'react-router-dom'
import Text from 'componentsNew/Text'
import Button from 'componentsNew/Button'
import * as S from './styles'

export const UnderConstruction: React.FC = () => {
  const history = useNavigate()
  const backDashboard = () => {
    history('/dashboard/cockpit')
  }

  return (
    <S.Wrapper>
      <S.Container>
        <S.Icon />
        <S.Title>
          <Text size="xlarge" bold>
            EM CONSTRUÇÃO...
          </Text>
          <Button onClick={backDashboard}>Ir para o Dashboard Principal</Button>
        </S.Title>
      </S.Container>
    </S.Wrapper>
  )
}
