import React from 'react'
import { ConfigProvider, theme } from 'antd'

import { InputProps, TextAreaProps } from 'antd/es/input'
import * as S from './styles'

type InputTypeCustom = 'input' | 'textarea'

type InputFilterPropsInput = InputProps & {
  defaultValue?: any
  width?: string
  typeComponent?: 'input'
}

type InputFilterPropsTextArea = TextAreaProps & {
  defaultValue?: any
  width?: string
  typeComponent?: 'textarea'
}

type InputFilterProps = InputFilterPropsInput | InputFilterPropsTextArea

export const InputFilter: React.FC<InputFilterProps> = ({
  defaultValue,
  width = '260px',
  typeComponent = 'input',
  ...rest
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            algorithm: true,
            colorBorder: 'rgb(233, 233, 240)',
            colorPrimaryHover: 'rgb(233, 233, 240)',
            colorPrimary: 'rgb(233, 233, 240)',
            borderRadius: 2,
            borderRadiusLG: 2,
            borderRadiusSM: 2,
          },
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      {typeComponent === 'input' && <S.CustomInput {...(rest as InputFilterPropsInput)} />}
      {typeComponent === 'textarea' && <S.CustomInput.TextArea {...(rest as InputFilterPropsTextArea)} />}
    </ConfigProvider>
  )
}
