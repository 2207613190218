import { keyframes, styled } from 'styled-components'

const animate = keyframes`
  0% {
      opacity: 0.5;
  }
  50%, 100% {
      opacity: 1;
  }
`

export const Wrapper = styled.main<{ position?: number; best?: boolean }>`
  border: 2px solid #393939;
  transition: 0.5s;
  position: absolute;
  top: -263px;
  height: 134%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  max-width: 180px;
  padding: 0 8px;
  padding-top: 28px;
  padding-bottom: 18px;

  border-color: ${props => (props.best ? '#ffc000' : '#393939')};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Badge = styled.span`
  animation: ${animate} 3s linear infinite;
  color: #ffc000;
  font-weight: 900;
  font-size: 10px;
  margin-top: -20px;
  text-align: right;
  text-transform: uppercase;
`

export const TitleCard = styled.h3`
  font-size: 24px;
  font-weight: 500;
  color: #d9d9d9;
`

export const Description = styled.p`
  color: #e9e9f0;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
`
