import { toast } from 'react-toastify'
import { api, apiPortifolio } from 'services/apiService'
import { MOCK_Ally_THEME_LIST_RESPONSE } from '../MOCK/allyMock'
import { MOCK_ACCEPT_WIZARD_PARAMS } from '../MOCK/competitiveMock'

import {
  AllyThemeListResponse,
  AcceptWizardParams,
  GetAllyWizardResponse,
  CandCreatePayload,
  SelectedAllyTheme,
} from '../types/ALLY_TYPES'
import simulateRequest from "../../../utils/simulateRequest";

interface ErrorMessage {
  message: string
}

type ResponseGetWizards = GetAllyWizardResponse | ErrorMessage
type ResponseThemeList = AllyThemeListResponse | ErrorMessage
type ResponsePersonThemes = SelectedAllyTheme[] | ErrorMessage
type ResponseAcceptWizard = any

// Serviço para obter os wizards de aliados
export const getWizards = async (): Promise<ResponseGetWizards> => {
  try {
    const { data } = await api.get(`/electoral-intelligence/allies-diagnosis-wizards/`)

    if (data) {
      return data
    }

    return { message: 'Falha no teste.' }
  } catch (error) {
    return { message: 'Não foi possível buscar dados cadastrados.' }
  }
}

// Serviço para obter a lista de temas de aliados
export const getThemes = async (): Promise<ResponseThemeList> => {
  try {
    const { data } = await simulateRequest(MOCK_Ally_THEME_LIST_RESPONSE)

    if (data) {
      return data
    }

    return { message: 'Não foi possível buscar dados cadastrados.' }
  } catch (error) {
    return { message: 'Não foi possível buscar dados cadastrados.' }
  }
}

// Serviço para obter os temas de aliados
export const getPersonThemes = async (): Promise<ResponsePersonThemes> => {
  try {
    /*  const { data } = await simulateRequest(MOCK_Ally_SELECTION_RESPONSE) */
    const { data } = await api.get('/electoral-intelligence/person-allies-diagnosis-themes/')

    const formatData = data?.results.map((result: any) => ({
      ...result,

      label: result?.cand_name,
      value: result?.id_cand,
    }))

    return formatData
  } catch (error) {
    return { message: 'Não foi possível buscar dados cadastrados.' }
  }
}

export const getAvatar = async (id_cand: string): Promise<{ url: string } | ErrorMessage> => {
  try {
    const { data } = await apiPortifolio.get(`/candidate-portfolio/candidate/${id_cand}/image`)

    return {
      url: data?.image_url,
    }
  } catch (e) {
    console.log(e)
    return { message: 'erro: busca do avatar' }
  }
}

export const acceptWizard = async ({ wizard_id, status }: AcceptWizardParams): Promise<ResponseAcceptWizard> => {
  try {
    const { data } = await simulateRequest(MOCK_ACCEPT_WIZARD_PARAMS)

    return data
  } catch (error) {
    return { message: 'Não foi possível buscar dados cadastrados.' }
  }
}

export const createAlly = async (payload: CandCreatePayload): Promise<any> => {
  try {
    /*  const { data } = await simulateRequest(MOCK_CREATE_Ally_THEME_PAYLOAD) */
    const { data } = await api.post('/electoral-intelligence/person-allies-diagnosis-themes/', {
      ...payload,
    })

    const formatData = {
      ...data,
      label: data?.cand_name,
      value: data?.id_cand,
    }

    return formatData
  } catch (error) {
    return { message: 'Não foi possível criar o tema de aliados.' }
  }
}

export const deleteAdversary = async (id: number | string) => {
  try {
    const url = `/electoral-intelligence/person-allies-diagnosis-themes/${id}/`
    const { data } = await api.delete(url)

    toast.success('Candidato removido com sucesso.')
  } catch (e) {
    console.error('Erro na requisição:', e)
    toast.error('Falha ao remover o candidato, tente novamente.')
  }
}

export const updateListener = async ({
  theme_id,
  is_listening,
  id_cand,
  topic,
}: {
  theme_id: number
  is_listening: boolean
  id_cand: string
  topic: string
}): Promise<any> => {
  try {
    const { data } = await api.put(`/electoral-intelligence/person-allies-diagnosis-themes/${theme_id}/`, {
      is_listening,
      id_cand,
      topic,
    })

    return data
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}
