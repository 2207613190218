export function buildQueryString(params: Record<string, any>): string {
  const queryString = Object.entries(params)
    .filter(([, value]) => {
      if (!value) return false
      if (typeof value === 'string' && value.trim() === '') return false
      if (Array.isArray(value) && value.length === 0) return false
      if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) return false
      return true
    })
    .map(([key, value]) => {
      if (Array.isArray(value) && value.every(item => typeof item === 'string' || typeof item === 'number')) {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value.join(','))}`
      }
      if (Array.isArray(value) || typeof value === 'object') {
        return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })
    .join('&')

  return queryString ? `?${queryString}` : ''
}

export const formatChartData = (data: any[] = []) => {
  if (!data) {
    return {
      series: [],
      options: [],
    }
  }

  const uniqueData = new Map()
  data.forEach(item => {
    if (!uniqueData.has(item.region)) {
      uniqueData.set(item.region, item.total_count)
    }
  })

  const series = Array.from(uniqueData.values())
  const options = Array.from(uniqueData.keys())

  return {
    series,
    options,
  }
}
