import React from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

interface IForm {
  children: React.ReactNode;
  onSubmit: SubmitHandler<any>;
}

const Form: React.FC<IForm> = ({ children, onSubmit }) => {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

export default Form;
