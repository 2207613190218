import React, { useState, useEffect } from 'react'
import { Avatar, Skeleton } from 'antd'
import { searchCandidate } from 'componentsNew/ElectionStrategyComponents/services/SearchCandidate'
import { FaUser } from 'react-icons/fa'

import { noop } from 'lodash'
import { CandidateType } from 'componentsNew/ElectionStrategyComponents/types/candidateTypes'
import { getAvatar } from 'componentsNew/ElectionStrategyComponents/services/allyServices'
import debounce from 'lodash/debounce'
import { CustomSelect } from '../CustomSelect'
import * as S from './styles'

interface defaultLabelValueProps {
  label: string
  value: number | string
}

interface ProfileCandTypes extends CandidateType {
  avatar?: string
  category?: string
  name?: string
}

interface ProfileSelectProps {
  profile_data?: ProfileCandTypes
  defaultValue?: defaultLabelValueProps
  width?: string
  isLoading?: boolean
  categoryLabel?: boolean
  disabled?: boolean
  searchEndpoint?: string
  handleAdd?: (data: any) => void
  onChangeListener?: (check: boolean, id?: number | string) => void
}

export const ProfileSelect: React.FC<ProfileSelectProps> = ({
  profile_data,
  defaultValue,
  width,
  isLoading,
  categoryLabel,
  disabled,
  searchEndpoint,
  handleAdd = noop,
  onChangeListener = noop,
}: ProfileSelectProps) => {
  const [searchResults, setSearchResults] = useState<defaultLabelValueProps[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const options = defaultValue ? [...searchResults, defaultValue] : searchResults
  const [profile, setProfile] = useState<ProfileCandTypes | undefined>(profile_data)

  const [avatar, setAvatar] = useState('')

  const performSearch = async (term: string) => {
    if (term.length >= 3) {
      try {
        const data = await searchCandidate({ search: term })

        if (!('message' in data)) {
          setSearchResults(data)
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error)
      } finally {
        setLoading(false)
      }
    }
  }

  const debouncedSearch = debounce(performSearch, 2000)

  useEffect(() => {
    if (searchTerm) {
      setLoading(true)
      debouncedSearch(searchTerm)
    }

    return () => {
      debouncedSearch.cancel()
    }
  }, [searchTerm])

  const handleSearchInputChange = (value: string) => {
    setSearchTerm(value)
  }

  const onSelect = (val: any, data: ProfileCandTypes) => {
    setProfile(val)
    handleAdd(data)
  }

  useEffect(() => {
    fetchAvatar()
  }, [])

  const fetchAvatar = async () => {
    const newAvatar = await getAvatar(profile?.candidate_id || profile?.id_cand || '')

    if (!('message' in newAvatar)) {
      setAvatar(newAvatar.url)
    }
  }

  if (disabled) {
    return (
      <S.Wrapper>
        <S.Content>
          <S.DisabledAvatar isLoading={isLoading}>
            <Skeleton.Avatar shape="circle" />
          </S.DisabledAvatar>
          <S.SkeletonInput />
        </S.Content>
      </S.Wrapper>
    )
  }

  return (
    <S.Wrapper>
      {categoryLabel && <S.Heading>{profile?.category || 'Selecione um perfil'}</S.Heading>}

      <S.Content>
        <S.AvatarBox isLoading={isLoading}>
          <Avatar src={avatar} alt="Avatar" icon={<FaUser />} />
        </S.AvatarBox>
        <CustomSelect
          width={width}
          options={options}
          value={profile}
          onSelect={(val: any, data) => onSelect(val, data?.data)}
          defaultValue={!disabled && defaultValue}
          showSearch
          allowClear={false}
          loading={loading}
          notFoundContent={null}
          style={{ width: '100%' }}
          optionFilterProp="label"
          size="middle"
          defaultSwitch={profile?.is_listening}
          placeholder="Busque por um candidato..."
          onSearch={handleSearchInputChange}
          handleListener={checked => onChangeListener(checked, profile?.candidate_id || profile?.id_cand)}
        />
      </S.Content>
    </S.Wrapper>
  )
}
