import React, { useEffect } from "react";
import { AcceptUsers } from "componentsNew/ManagementComponents/AcceptUsers";
import TabsCustom from "componentsNew/Tabs";
import Spin from "componentsNew/Spin";
import { useCandidates } from "hooks/useCandidates";
import Portfolio from "microfrontends/Advisors/Portfolio";
import { useTab } from "global/TabContext";
import * as S from "./styles";

const labels = [
  {
    label: "Usuários Vinculados",
    key: "tab_user",
  },
  {
    label: "Portfólio de ",
    sub_label: "Advisors",
    key: "tab_team_advisors_portfolio",
  },
];

const content: React.ReactNode[] = [<AcceptUsers />, <Portfolio />];

export const ManagementTeam = () => {
  const [activeTab, setActiveTab] = React.useState(
    "tab_team_advisors_portfolio",
  );
  const { isLoadedCandidates } = useCandidates();
  const { setActiveTabContext } = useTab();

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  const changeTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={content}
          onChange={(key) => changeTab(key)}
        />
      </S.Content>
    </S.Wrapper>
  );
};
