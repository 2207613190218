/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { /*  BsArrowDown, */ BsArrowLeft } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'
import Spin from 'componentsNew/Spin'
import Card from 'componentsNew/Card'
import Text from 'componentsNew/Text'
import Modal from 'componentsNew/Modal'
import TabsCustom from 'componentsNew/Tabs'
import CardCandidate from 'componentsNew/CardCadindate'
import CardSponsored from 'componentsNew/CardSponsored'
import CustomCollapse from 'componentsNew/CustomCollapse'
import CustomCollapseMinimal from 'componentsNew/CustomCollapse/componets/CustomCollapseMinimal'
import MapaHexagonal from 'pages/AllApplications/components/MapaHexagonal'
import { getCandsAcronymUfCity } from 'pages/SponsoredApplicattions/services/sponsoredApplications'
import {
  TransformedCandsData,
  TransformedCandsDataSponsored,
  TransformedPartyData,
} from '../../types/sponsoredApplicationsTypes'
import CardCandidateWirzad from '../../components/CardCandidateWirzad'
import { Container, ContainerButtonLink, ContainerMaster } from './styles'
import usePartyData from '../../hooks/usePartyData'
import { useCandidates } from '../../../../hooks/useCandidates'
import { useTab } from '../../../../global/TabContext'

const labels = [
  {
    label: 'Panorama Nacional',
    key: 'tab_panorama_national_sponsored_applications',
  },
]

interface ITotal {
  position: string
  count: number
}

interface IStateData {
  state: string
  acronym: string
  totals: ITotal[]
}

interface ICityData {
  city: string
  totals: ICityTotal[]
}

interface ICityTotal {
  position: string
  count: number
}

interface IStateWithCities {
  state: string
  acronym: string
  cities: ICityData[]
}

interface CandidatosPorPosicao {
  PREFEITO: TransformedCandsData[]
  VICE_PREFEITO: TransformedCandsData[]
  VEREADOR: TransformedCandsData[]
}

interface CandidateType {
  id: string
  name: string
}

interface StateAbbreviationsToNames {
  [key: string]: string
}

const NationalPanoramaSponsored = () => {
  const [activeTab, setActiveTab] = React.useState('tab_panorama_national_sponsored_applications')
  const { setActiveTabContext } = useTab()
  const { candidates, selectedCandidate, setSelectedCandidate } = useCandidates()
  const history = useNavigate()
  const params = useParams<{ id: string }>()
  const partyAcronym = params?.id || null
  const {
    partyData,
    statesData,
    citiesData,
    candidatesData,
    loading,
    fetchParty,
    fetchStates,
    fetchCities,
    fetchCandidates,
  } = usePartyData()
  const [selectedCity, setSelectedCity] = useState('')
  const [candidatos, setCandidatos] = useState<TransformedCandsDataSponsored[]>([])
  const [selectedParty, setSelectedParty] = useState('')
  const [selectedCargo, setSelectedCargo] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [selectedStateDefault, setSelectedState] = useState('')
  const [statePortions, setStatePortions] = useState<{ acronym: string; total_portion: number }[]>([])
  const [loadingCandidate, setLoadingCandidate] = useState(false)
  const [selectedCandidateName, setSelectedCandidateName] = useState('')
  const [selectedCandidateId, setSelectedCandidateId] = useState('')
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const stateAbbreviationsToNames: StateAbbreviationsToNames = {
    AC: 'Acre',
    AL: 'Alagoas',
    AP: 'Amapá',
    AM: 'Amazonas',
    BA: 'Bahia',
    CE: 'Ceará',
    DF: 'Distrito Federal',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MT: 'Mato Grosso',
    MS: 'Mato Grosso do Sul',
    MG: 'Minas Gerais',
    PA: 'Pará',
    PB: 'Paraíba',
    PR: 'Paraná',
    PE: 'Pernambuco',
    PI: 'Piauí',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul',
    RO: 'Rondônia',
    RR: 'Roraima',
    SC: 'Santa Catarina',
    SP: 'São Paulo',
    SE: 'Sergipe',
    TO: 'Tocantins',
  }

  useEffect(() => {
    setActiveTabContext(activeTab)
  }, [activeTab, setActiveTabContext])

  useEffect(() => {
    if (partyAcronym) {
      fetchParty(partyAcronym)
      fetchStates(partyAcronym)
    }
  }, [partyAcronym])

  useEffect(() => {
    const updatedStatePortions = statesData.map(stateData => ({
      acronym: stateData.state,
      total_portion: stateData.total_portion,
    }))

    setStatePortions(updatedStatePortions)
  }, [statesData])

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleSelectedState = (state: string) => {
    setSelectedState(state)
  }

  const order = ['PREFEITO', 'VICE-PREFEITO', 'VEREADOR']
  const reorderTotals = (totals: any) => {
    const ordered = new Array(order.length).fill(null)

    totals.forEach((total: any) => {
      const index = order.indexOf(total.position)
      if (index !== -1) {
        ordered[index] = total
      }
    })

    return ordered.filter(el => el !== null)
  }

  const handleCityClick = async (city: string, stateAcronym: string) => {
    setSelectedCity(city)
    try {
      setLoadingCandidate(true)
      if (partyAcronym !== null && !Number.isNaN(partyAcronym)) {
        const candidatesResponse = await getCandsAcronymUfCity(partyAcronym, stateAcronym, city)
        if (!('message' in candidatesResponse)) {
          console.log('isCandidate', candidatesResponse)
          setCandidatos(candidatesResponse)
        }
      }
    } catch (error) {
      console.error('Error fetching candidates:', error)
    } finally {
      setLoadingCandidate(false)
    }
  }

  const confirmGoToCockpit = (candidateName: string, candidateId: string) => {
    setSelectedCandidateName(candidateName)
    setSelectedCandidateId(candidateId)
    setOpenModal(true)
  }

  const handleGoToCockpit = () => {
    const candidateToSelect = candidates.find((candidate: any) => candidate.id_cand === selectedCandidateId)

    if (candidateToSelect) {
      setSelectedCandidate(candidateToSelect)
      history('/dashboard/cockpit', { state: { reload: true } })
      handleClose()
    } else {
      console.error('Candidato não encontrado:', selectedCandidateId)
    }
  }

  const handleItemClick = (index: number) => {
    if (openIndex === index) {
      setOpenIndex(null)
    } else {
      setOpenIndex(index)
    }
  }

  return (
    <TabsCustom
      labels={labels}
      activeTab={activeTab}
      content={[
        <ContainerMaster>
          {loading && <Spin size="large" fullscreen />}
          <div>
            <ContainerButtonLink onClick={() => history('/dashboard/sponsored-applications')}>
              <BsArrowLeft />
              Alterar partido
            </ContainerButtonLink>

            <div className="card-candidate-wirzad">
              <CardCandidateWirzad />
            </div>

            {partyData
              ?.filter((dataParty: TransformedPartyData) => dataParty.party_acronym === partyAcronym)
              .map(dataParty => (
                <CardSponsored
                  key={dataParty.party_acronym}
                  id={dataParty.party_acronym}
                  valueCandidates={dataParty.totals}
                  title={dataParty.party_acronym}
                  icon={dataParty.icon}
                  titleCard="Partido"
                  isClickable={false}
                />
              ))}

            <Container>
              <MapaHexagonal
                handleSelectedState={handleSelectedState}
                selectedStateDefault={selectedStateDefault}
                statesData={statePortions}
              />

              <div>
                <div className="card-title-states">
                  <Card>
                    <div className="card-title-items">
                      <div className="card-title-uf">
                        <Text>UF</Text>
                      </div>
                      <div className="card-title-position">
                        <Text /* bold */>Prefeito{/*  <BsArrowDown /> */}</Text>
                        <Text>Vice-Prefeito</Text>
                        <Text>Vereador</Text>
                      </div>
                    </div>
                  </Card>
                </div>

                <div className="descripiton">
                  {statesData.length > 0 &&
                    statesData &&
                    statesData.map(dataState => {
                      const stateAbbreviation = dataState?.state
                      const stateName = stateAbbreviation
                        ? stateAbbreviationsToNames[stateAbbreviation] || stateAbbreviation
                        : ''
                      const stateCities = citiesData[dataState?.state] || []
                      console.log('stateCities', stateCities)

                      return (
                        <CustomCollapse
                          title={stateName}
                          uf={dataState?.state}
                          key={dataState?.acronym}
                          totals={dataState?.totals}
                          isActiveDefault={false}
                          selectedStateDefault={selectedStateDefault}
                          onChande={handleSelectedState}
                          onClick={() => {
                            if (partyAcronym !== null && dataState?.acronym) {
                              fetchCities(partyAcronym, dataState.state)
                            }
                          }}
                        >
                          {stateCities.map((cityData, index) => (
                            <CustomCollapseMinimal
                              key={cityData.city}
                              title={cityData.city}
                              totals={
                                Array.isArray(cityData.totals)
                                  ? cityData.totals.map(total => ({
                                      position: total.position,
                                      value: total.count,
                                    }))
                                  : []
                              }
                              index={index}
                              isActive={openIndex === index}
                              onClick={() => {
                                if (openIndex !== index) {
                                  handleCityClick(cityData.city, dataState.state)
                                  setOpenIndex(index)
                                } else {
                                  setOpenIndex(null)
                                }
                              }}
                            >
                              {loadingCandidate ? (
                                <Spin fullscreen />
                              ) : (
                                <>
                                  {candidatos.some(cand => cand.position === 'Prefeito') && (
                                    <div className="cand-card">
                                      <Text size="small" bold className="candidates-position">
                                        Candidatos | Prefeitos
                                      </Text>
                                      <div className="card-candidate">
                                        {candidatos
                                          .filter(cand => cand.position === 'Prefeito')
                                          .flatMap(cand => cand.candidates)
                                          .map(candidate => (
                                            <CardCandidate
                                              img={candidate.avatar}
                                              key={candidate.id}
                                              name={candidate.name}
                                              status="top"
                                              wizard
                                              onClick={() => confirmGoToCockpit(candidate.name, candidate.id)}
                                            />
                                          ))}
                                      </div>
                                    </div>
                                  )}

                                  {candidatos.some(cand => cand.position === 'Vice Prefeito') && (
                                    <div className="cand-card">
                                      <Text size="small" bold className="candidates-position">
                                        Candidatos | Vice-Prefeitos
                                      </Text>
                                      <div className="card-candidate">
                                        {candidatos
                                          .filter(cand => cand.position === 'Vice Prefeito')
                                          .flatMap(cand => cand.candidates)
                                          .map(candidate => (
                                            <CardCandidate
                                              img={candidate.avatar}
                                              key={candidate.id}
                                              name={candidate.name}
                                              status="top"
                                              wizard
                                              onClick={() => confirmGoToCockpit(candidate.name, candidate.id)}
                                            />
                                          ))}
                                      </div>
                                    </div>
                                  )}

                                  {candidatos.some(cand => cand.position === 'Vereador') && (
                                    <div className="cand-card">
                                      <Text size="small" bold className="candidates-position">
                                        Candidatos | Vereadores
                                      </Text>
                                      <div className="card-candidate">
                                        {candidatos
                                          .filter(cand => cand.position === 'Vereador')
                                          .flatMap(cand => cand.candidates)
                                          .map(candidate => (
                                            <CardCandidate
                                              img={candidate.avatar}
                                              key={candidate.id}
                                              name={candidate.name}
                                              status="top"
                                              wizard
                                              onClick={() => confirmGoToCockpit(candidate.name, candidate.id)}
                                            />
                                          ))}
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </CustomCollapseMinimal>
                          ))}
                        </CustomCollapse>
                      )
                    })}
                </div>
              </div>
            </Container>
          </div>
          ,
          {openModal && (
            <Modal
              closeModal={handleClose}
              title="CONFIRMAÇÃO"
              onPrimaryClick={handleGoToCockpit}
              onSecondaryClick={handleClose}
              primaryTextButton="Ir para cockpit"
              secondaryTextButton="Cancelar"
            >
              Deseja realmente ir para o cockpit de {selectedCandidateName}?
            </Modal>
          )}
        </ContainerMaster>,
      ]}
    />
  )
}

export default NationalPanoramaSponsored
