import React, { createContext, useState, useContext, ReactNode } from 'react'

interface ExpensesData {
  id: number
  rithm: number
  weekly_goal: number
  projection: number
  initial_value: number
  goal: number
  minimum_goal: number
  first_partial_goal: number
  second_partial_goal: number
  current_value: number
  initial_date: string
  final_date: string
  created: string
  updated: string
  person: number
}

interface TrajectoryExpensesContextProps {
  expensesData: ExpensesData | null
  setExpensesData: (data: ExpensesData | null) => void
}

const TrajectoryExpensesContext = createContext<TrajectoryExpensesContextProps | undefined>(undefined)

export const TrajectoryExpensesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [expensesData, setExpensesData] = useState<ExpensesData | null>(null)

  return (
    <TrajectoryExpensesContext.Provider value={{ expensesData, setExpensesData }}>
      {children}
    </TrajectoryExpensesContext.Provider>
  )
}

export const useTrajectoryExpenses = (): TrajectoryExpensesContextProps => {
  const context = useContext(TrajectoryExpensesContext)
  if (!context) {
    throw new Error('useTrajectoryExpenses must be used within a TrajectoryExpensesProvider')
  }
  return context
}
