/* eslint-disable no-plusplus */
import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from '@mui/material'
import { ALL } from 'utils/constants'
import { useCandidates } from 'hooks/useCandidates'
import { buildQueryString } from 'utils/buildQueryString'
import { apiMentions } from '../../../../../services/apiService'
import { formatter } from '../../../services/utils/formatterMentions'
import Spin from '../../../../Spin'
import { useFilterGlobal } from '../../../../../hooks/useFilterGlobal'
import * as S from './styles'

const defaultOrders: string[] = [
  'fb_count',
  'ig_count',
  'tt_count',
  'gg_count',
  'yt_count',
  'rd_count',
  // 'tumblr_count',
  'tl_count',
  /*   'infra_count',
  'iot_count',
  'network_count',
  'devices_count', */
]

interface IChartMentions {
  cand_id?: string
  target_local?: string
}

const ChartMentions = ({ cand_id, target_local }: IChartMentions) => {
  const [byTopic, setByTopic] = useState<any>(false)
  const [totalCount, setTotalCount] = useState<any>(0)
  const [allCounts, setAllCount] = useState<any>(null)
  const [loading, setLoading] = useState<any>(true)
  const ref = useRef<HTMLHeadingElement>(null)
  const { filterMentions, setFilterMentions, filterRaioX } = useFilterGlobal()
  function totalValues(values: number[]): number {
    return values.reduce((acc, value) => acc + value, 0)
  }

  function percentile(arr: number[], value: number) {
    let count = 0
    arr.forEach(v => {
      if (v < value) {
        count += 1
      } else if (v === value) {
        count += 0.5
      }
    })
    return (100 * count) / arr.length
  }

  function normalizeValues(values: number[], percentage: number): number[] {
    return values.map(v => percentile(allCounts, v))
  }
  const { selectedCandidate } = useCandidates()

  useEffect(() => {
    if (selectedCandidate) {
      getByTopic()
    }
  }, [selectedCandidate, filterRaioX])

  const extractCountNumbers = (data: any) => {
    const countNumbers: number[] = []

    data.forEach((item: any) => {
      Object.keys(item).forEach(key => {
        if (key.includes('count')) {
          countNumbers.push(item[key])
        }
      })
    })

    return countNumbers
  }

  const getByTopic = async () => {
    const newSentiment = filterRaioX !== 'all' ? filterRaioX : ''
    const params = { id_cand: cand_id, city: target_local, sentiment: newSentiment }
    setLoading(true)
    try {
      const { data } = await apiMentions.get(`/mentions-by-topic/${buildQueryString(params)}`)
      if (data?.mentions_by_topic.length > 0) {
        await setByTopic(formatter(data?.mentions_by_topic, defaultOrders))
        await setTotalCount(data.total_count)

        const allCounts = extractCountNumbers(data?.mentions_by_topic)
        setAllCount(allCounts)

        setLoading(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleColumnClick = () => {
    console.log('label', byTopic)
  }

  return (
    <S.Wrapper>
      {!byTopic || loading ? (
        <Spin spinning />
      ) : (
        <>
          <S.HeaderBox>
            <S.Heading onClick={handleColumnClick}>Total: {totalCount} interações</S.Heading>
            <S.WrapperIconsHeader>
              {byTopic?.column?.map((column: any, index: any) => (
                <Tooltip title={column.label} key={index} placement="top">
                  <S.IconWithLabel key={index}>
                    <S.IconHeaderBox>
                      <S.Icon src={column.icon} alt={column.label} />
                    </S.IconHeaderBox>
                    <p>{column.value}</p>
                  </S.IconWithLabel>
                </Tooltip>
              ))}
            </S.WrapperIconsHeader>
          </S.HeaderBox>

          {byTopic?.row?.map((row: any, index: any) => (
            <S.RowMentions
              key={index}
              borderColor={row.color || '#FFF'}
              onClick={() => setFilterMentions((prev: any) => (prev === row?.label ? 'all' : row?.label))}
              isActive={row?.label === filterMentions || filterMentions === ALL}
            >
              <S.WrapperText>
                {row.icon && (
                  <S.IconBox>
                    <S.Icon src={row.icon} alt={row.label} />
                  </S.IconBox>
                )}
                <S.TextRowTitle>{row.label}</S.TextRowTitle>
                <S.TextRow>{totalValues(row.values)}</S.TextRow>
              </S.WrapperText>

              <S.WrapperCircle>
                {normalizeValues(row.values, 100).map((normalizedValue, idx) => (
                  <S.CircleBox key={idx}>
                    <S.Circle size={normalizedValue} />
                  </S.CircleBox>
                ))}
              </S.WrapperCircle>
            </S.RowMentions>
          ))}
        </>
      )}
    </S.Wrapper>
  )
}

export default ChartMentions
