import styled from 'styled-components'
import '../../styles/global'

export const Container = styled.div`
  background-color: #0a0a0a;
  min-width: 887px;
  max-width: 1198px;
  flex: 1;
  height: 100vh;
  margin-top: 33px;
  margin-left: -64px;
`
