import HeatMap from "components/HeatMap";
import AreaChart from "componentsNew/AreaChart";
import ChartMentionSection from "componentsNew/ChartMentionSection";

import { Empty } from "componentsNew/Empty";

import { BaseCard } from "componentsNew/PoliticalListeningComponents/BaseCard";
import Spin from "componentsNew/Spin";
import { useFilterVox } from "hooks/useFilterVox";
import { noop } from "lodash";
import { ReactNode } from "react";

import { handleMapColor } from "utils/utilityFunctions";
import { MentionsMessage } from "../OverView/components/PrincialMensage";
import { CountSection } from "./components/CountSection";

import * as S from "./styles";

interface awaitLoadingProps {
  loading?: boolean;
  children: ReactNode;
}

export const ListeningCity = ({
  data,
  handleSubtopic = noop,
  selectedMention,
  handleTopic = noop,
  loadingSearch,
  loadGovernament,
  loadElection,
}: {
  data: any;
  handleSubtopic?: any;
  selectedMention?: any;
  handleTopic?: any;
  loadingSearch?: boolean;
  loadGovernament?: boolean;
  loadElection?: boolean;
}) => {
  const {
    governmentWordCloud,
    electorWordCloud,
    electorChart,
    governmentChart,
    topMentions,
    loadChart,
  } = useFilterVox();
  const mapData = data?.dataMap || [];
  const mentionsSentiment = data?.sentiment || [];
  const mentions = topMentions || [];
  const wordCloud = governmentWordCloud || [];

  const chartMentions = governmentChart;
  const mentionByTheme = electorChart;
  const wordColors = handleMapColor(data?.selected_topic || "");
  const count = {
    positive: data?.totalCount?.positive_mentions || 0,
    negative: data?.totalCount?.negative_mentions || 0,
    neutral: data?.totalCount?.neutral_mentions || 0,
    positive_percent: data?.totalCount?.percent_positive || 0,
    negative_percent: data?.totalCount?.percent_negative || 0,
    neutral_percent: data?.totalCount?.percent_neutral || 0,
  };
  const author_count = data?.totalCount?.author_count || 0;
  const mention_count = data?.totalCount?.total_mentions || 0;
  const relevance_index = data?.totalCount?.relevance_index || 0;

  return (
    <S.Wrapper>
      <S.NewSection>
        <S.CounterBox>
          <S.CounterContent>
            <CountSection title="Curadoria de dados" />

            <CountSection
              description="Total de Menções"
              value={mention_count}
            />
            <CountSection
              description="Total de Autores Únicos"
              value={author_count}
            />
            <CountSection
              description="Índice de Relevância (IR)"
              value={relevance_index}
            />
          </S.CounterContent>
          <S.CounterContent>
            <CountSection title="Sentimento" />
            <CountSection
              description="Menções | Positivas"
              value={count?.positive}
              percent={count?.positive_percent}
              typeStatus="positive"
            />
            <CountSection
              description="Menções | Negativas"
              value={count?.negative}
              percent={count?.negative_percent}
              typeStatus="negative"
            />
            <CountSection
              description="Menções | Neutras"
              value={count?.neutral}
              percent={count?.neutral_percent}
              typeStatus="neutral"
            />
          </S.CounterContent>
        </S.CounterBox>
        <S.Box height="312px">
          <S.Heading>Análise de sentimento</S.Heading>
          <BaseCard>
            <AwaitToLoading loading={loadingSearch}>
              {mentionsSentiment?.options?.xaxis?.categories?.length > 0 ? (
                <AreaChart
                  options={mentionsSentiment?.options}
                  series={mentionsSentiment?.series}
                />
              ) : (
                <div className="centered_div-empty">
                  <Empty />
                </div>
              )}
            </AwaitToLoading>
          </BaseCard>
        </S.Box>
      </S.NewSection>
      <ChartMentionSection
        title="Raio-X do governo - Menções"
        words={wordCloud}
        chartMentions={chartMentions}
        colors={wordColors}
        isLoadingDefault={loadGovernament}
        loadingMention={loadingSearch}
        word_sizes={{ height: 360 }}
        loadingChart={loadChart.government}
      />
      <ChartMentionSection
        title="Eleitor - Temas mais relevantes"
        words={electorWordCloud}
        chartMentions={mentionByTheme}
        colors={wordColors}
        isLoadingDefault={loadElection}
        loadingMention={loadingSearch}
        loadingChart={loadChart.elector}
      />
      <S.NewSection>
        <S.Box height="548px">
          <S.Heading>Principais mensagens</S.Heading>
          <S.SectionMessage>
            <AwaitToLoading loading={loadingSearch}>
              {mentions?.length > 0 ? (
                <MentionsMessage mentions={mentions} />
              ) : (
                <div className="centered_div-empty">
                  <Empty />
                </div>
              )}
            </AwaitToLoading>
          </S.SectionMessage>
        </S.Box>

        <S.Box height="548px">
          <AwaitToLoading loading={loadingSearch}>
            {mapData.length > 0 ? (
              <HeatMap
                mapData={mapData}
                loading={false}
                height="435px"
                titleCard="Menções"
              />
            ) : (
              <div className="centered_div-empty">
                <Empty />
              </div>
            )}
          </AwaitToLoading>
        </S.Box>
      </S.NewSection>
    </S.Wrapper>
  );
};

const AwaitToLoading = ({ loading, children }: awaitLoadingProps) => {
  if (!loading) {
    return <>{children}</>;
  }

  return (
    <div className="centered_div-empty">
      <Spin spinning />
      <p>Carregando...</p>
    </div>
  );
};
