import React, { createContext, useContext, useState, ReactNode } from 'react'
import { ListByTopicTypes, SwotWizard } from 'componentsNew/ElectionStrategyComponents/types/SWOT_DIAGNOSIS_TYPES'
import { MOCK_SWOT_SELECTION_POSITIVE } from 'componentsNew/ElectionStrategyComponents/MOCK/swotMock'
import { SelectTypes } from '../useMacroEnv/types'

interface SwotContextProps {
  topicList: ListByTopicTypes[]

  updateTopicList: (list: ListByTopicTypes[]) => void

  updateSelectList: (topic_name: string, data: any) => void

  visibleWizard: SwotWizard[] | null
  updateVisibleWizards: (wizards: SwotWizard[] | null) => void

  updateListByTopic: (wizard: any) => void
  defaultSelect: SelectTypes[]
  changeSelect: (data: any) => void
}

const SwotContext = createContext<SwotContextProps | undefined>(undefined)

interface SwotProviderProps {
  children: ReactNode
}

export const SwotProvider: React.FC<SwotProviderProps> = ({ children }) => {
  const [visibleWizard, setVisibleWizards] = useState<SwotWizard[] | null>(null)
  const [defaultSelect, setDefaultSelect] = useState<SelectTypes[]>([])

  const [topicList, setTopicList] = useState<ListByTopicTypes[]>(MOCK_SWOT_SELECTION_POSITIVE)

  const updateTopicList = (list: ListByTopicTypes[]) => {
    setTopicList(list)
  }

  const changeSelect = (data: any) => {
    setDefaultSelect(data)
  }

  const updateSelectList = (topic_name: string, data: any) => {
    const newValues = topicList.map(topic => {
      if (topic.topic_name === topic_name) {
        return {
          ...topic,
          selection_list: topic.selection_list.map(option => {
            if (option.name === data?.theme_name) {
              return { ...option, active: true, name: data?.theme_name, is_recommended: true }
            }
            return option
          }),
        }
      }
      return topic
    })

    setTopicList(newValues)
  }

  const updateVisibleWizards = (wizards: SwotWizard[] | null) => {
    setVisibleWizards(wizards)
  }

  const updateListByTopic = (wizard: any) => {
    setTopicList(prevTopics => {
      const newTopics = [...prevTopics]
      const topicIndex = newTopics.findIndex(topic => topic.topic === wizard.topic_name)

      if (topicIndex !== -1) {
        const index = newTopics[topicIndex].theme_list.findIndex(item => item === undefined)
        if (index !== -1) {
          newTopics[topicIndex].theme_list[index] = { ...wizard, label: wizard?.theme_name, value: wizard?.id } // Usando o ID do wizard como valor
        }
      }

      return newTopics
    })
  }

  return (
    <SwotContext.Provider
      value={{
        updateListByTopic,
        topicList,
        defaultSelect,
        updateVisibleWizards,
        updateTopicList,
        updateSelectList,
        changeSelect,
        visibleWizard,
      }}
    >
      {children}
    </SwotContext.Provider>
  )
}

export const useSwot = () => {
  const context = useContext(SwotContext)
  if (!context) {
    throw new Error('useSwot deve ser usado dentro de um SwotProvider')
  }
  return context
}
