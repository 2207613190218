import { CompetitiveProvider } from '../hooks/useCompetitive'
import { Competitive } from '../templates/Competitive'

export const CompetitiveTemplate = () => {
  return (
    <CompetitiveProvider>
      <Competitive />
    </CompetitiveProvider>
  )
}
