import React, { useState, useEffect } from 'react'
import TabsCustom from 'componentsNew/Tabs'
import { OverView } from 'componentsNew/PoliticalVox'
import { OverviewProvider } from 'componentsNew/PoliticalVox/hooks/useOverview'
import { FiltersVoxBarProvider } from 'componentsNew/PoliticalVox/hooks/useFilterBarVox'
import NewsMarquee from 'componentsNew/NewsMarquee'
import { useTab } from '../../global/TabContext'

import * as S from './styles'

const labels = [
  {
    label: 'Visão Geral',
    key: 'tab_politica_vox_search',
  },
]

const content: React.ReactNode[] = [<OverView />]

export const PoliticalVoxSearch = () => {
  const [activeTab, setActiveTab] = useState('tab_politica_vox_search')
  const { setActiveTabContext } = useTab()

  useEffect(() => {
    setActiveTabContext(activeTab)
  }, [activeTab, setActiveTabContext])

  return (
    <OverviewProvider>
      <FiltersVoxBarProvider>
        <S.Wrapper>
          <S.Content>
            <S.NewsBox>
              <NewsMarquee />
            </S.NewsBox>
            <TabsCustom labels={labels} content={content} title="Politica Vox®" activeTab="tab_politica_vox_search" />
          </S.Content>
        </S.Wrapper>
      </FiltersVoxBarProvider>
    </OverviewProvider>
  )
}
