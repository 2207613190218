import { useEffect, useState } from 'react'
import { parseISO, isBefore, isValid } from 'date-fns'
import { api } from '../../services/apiService'

export const useGoalsTimeLine = () => {
  const [timeCardStart, setTimeCardStart] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const getApiTimeCardStart = async () => {
    setLoading(true)
    try {
      const { data } = await api.get('/political-trajectory/timeline/')
      setTimeCardStart(data[0])
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getApiTimeCardStart()
  }, [])

  const validateDataOrder = (newTimeCardStart: any) => {
    const { initial_date, pre_campaign_date, campaign_date, election_fund_date } = newTimeCardStart
    const minDate = parseISO('2023-10-07')

    const dates = [initial_date, pre_campaign_date, campaign_date, election_fund_date]
      .filter(d => d)
      .map(d => parseISO(d))
      .filter(isValid)

    if (dates.some(date => !isValid(date))) {
      setErrorMessage('Uma ou mais datas são inválidas.')
      return false
    }

    if (dates.length > 0 && !isBefore(minDate, dates[0])) {
      setErrorMessage('A primeira data válida é anterior a 07/10/2023.')
      return false
    }

    for (let i = 1; i < dates.length; i += 1) {
      if (!isBefore(dates[i - 1], dates[i])) {
        setErrorMessage('As datas não estão em ordem cronológica.')
        return false
      }
    }

    setError(false)
    return true
  }

  const updateTimeCardStart = async (data: any) => {
    setLoading(true)
    try {
      await api.patch(`/political-trajectory/timeline/${timeCardStart.id}/`, data)
      getApiTimeCardStart()
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  const handleChangeTimeCardStart = async (slug: any, value: any) => {
    const newTimeCardStart = { ...timeCardStart, [slug]: value }

    if (!validateDataOrder(newTimeCardStart)) {
      setError(true)
      return
    }

    setTimeCardStart(newTimeCardStart)
    const data = { [slug]: value }

    updateTimeCardStart(data)
  }

  return { loading, timeCardStart, handleChangeTimeCardStart, error, errorMessage }
}
