import React, { useEffect, useState } from "react";
import Header from "components/Header";
import Helper from "componentsNew/Helper";
import ModalNotAffliations from "componentsNew/ModalNotAffiliations";
import { Container, Content, Wrapper } from "./styles";
import { useUser } from "../../global/UserContext";
import { apiPortifolio, apiUser } from "../../services/apiService";
import SidBar from "../SibBar";
import { useSidBar } from "../../hooks/useSideBar";
import { useCandidates } from "../../hooks/useCandidates";
import Spin from "../Spin";

interface ILayout {
  children: React.ReactNode | React.ReactNode[];
}

const Layout = ({ children }: ILayout) => {
  const { open, setOpen } = useSidBar();
  const { setCandidates } = useCandidates();
  const { isLoadedCandidates, selectedCandidate } = useCandidates();
  const { setUser, user } = useUser();
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setUser(null);
  };

  useEffect(() => {
    if (user) {
      setCandidates(user.person?.affiliations);
      if (user.person?.affiliations?.length === 0) {
        setShowModal(true);
      }
    } else {
      fetchAndSetUserData();
    }
  }, [user]);

  const fetchAndSetUserData = async () => {
    try {
      const userData = await apiUser.get("/user/me");
      const cand_id = userData?.data?.person?.id_cand;
      const { data: apiImage } = await apiPortifolio.get(
        `/candidate-portfolio/candidate/${cand_id}/image`,
      );
      const formattedData = {
        ...userData.data,
        person: {
          ...userData?.data?.person,
          image_url: apiImage?.image_url,
        },
      };
      setUser(formattedData);
      setCandidates(userData.data.person?.affiliations);

      if (userData.data.person?.affiliations?.length === 0) {
        setShowModal(true);
      }
    } catch (error) {
      console.error("Erro ao buscar dados do usuário:", error);
    }
  };

  if (isLoadedCandidates && !isLoadedCandidates && !showModal) {
    return <Spin fullscreen />;
  }

  return (
    <Container>
      <SidBar />
      <Header />
      <Wrapper open={open}>
        <Content>{children}</Content>
      </Wrapper>
      <Helper />
      {showModal && <ModalNotAffliations closeModal={closeModal} />}
    </Container>
  );
};

export default Layout;
