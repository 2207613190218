import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;

  .text-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    h2 {
      font-size: 1.25rem;
    }

    a {
      all: unset;
      cursor: pointer;
      color: #ee930c;
      font-size: 1.25rem;
    }
  }

  .not-found-video {
    width: 25%;

    img {
      width: 100%;
    }
  }
`
