import React from "react";
import { Container } from "./styles";
import { useCandidates } from "../../hooks/useCandidates";
import Spin from "../../componentsNew/Spin";

export const AcquisitionsSignature: React.FC = () => {
  const { isLoadedCandidates } = useCandidates();

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }
  return (
    <Container>
      <div>Acquisitions Signature</div>
    </Container>
  );
};
