import styled, { css, keyframes } from "styled-components";
import { CardGoalsProps } from ".";

type WrapperProps = Pick<CardGoalsProps, "width" | "isCockpit">;

const zoomIn = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

const fadeInOut = (background: string) => keyframes`
  0%, 100% {
    opacity: 0.6;
    box-shadow: 0 0 5px ${background && `${background}33`};
  }
  50% {
    opacity: 1;
    box-shadow: 0 0 10px ${background && `${background}53`};
  }
`;

export const Wrapper = styled.main<WrapperProps>`
  ${({ width, isCockpit }) => css`
    flex: 1;
    max-width: 260px;
    background: ${isCockpit ? "#0A0A0A" : "#161717"};
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: ${zoomIn} 0.3s ease-out;
    transition: all 1s;
  `}

  .text-cockpit {
    color: transparent;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Heading = styled.h3`
  color: #e9e9f0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
`;
export const Pointer = styled.span<{ background: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${(props) => props.background || "#00e23f"};
  animation: ${(props) => fadeInOut(props.background)} 2.5s ease-in-out infinite;
  display: inline-block;
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
`;

export const BoxProgress = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;
