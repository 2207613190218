import React, { useEffect } from "react";
import { CandidateCard } from "components/CandidateCard";
import NewsMarquee from "componentsNew/NewsMarquee";
import GoalsCockpit from "./components/GoalsCockpit";
import CompetitiveAnalysis from "./components/CompetitiveAnalysis";
import IntegratedFeed from "./components/IntegratedFeed";
import { useCandidateCard } from "hooks/useCandidateCard";
import { useSidBar } from "hooks/useSideBar";
import { useTab } from "global/TabContext";
import * as S from "./styles";

const CockpitComponents = () => {
  const [activeTab, setActiveTab] = React.useState("tab_cockpit");
  const { setActiveTabContext } = useTab();
  const { open } = useSidBar();
  const { isVisible } = useCandidateCard();

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  return (
    <S.ActiveCandidateCard candidateCardOpen={isVisible}>
      <S.AlertBanner sidebarOpen={open}>
        <NewsMarquee />
      </S.AlertBanner>
      <CandidateCard />
      <S.Container>
        <S.Section>
          <GoalsCockpit />
        </S.Section>
        <S.Section>
          <CompetitiveAnalysis />
        </S.Section>
        <S.Section>
          <IntegratedFeed isCandidate />
        </S.Section>
      </S.Container>
    </S.ActiveCandidateCard>
  );
};

export { CockpitComponents };
