import { styled } from 'styled-components'
import theme from 'styles/theme'

export const Wrapper = styled.main``

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px;
  flex-wrap: wrap;
  gap: 24px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 285px;
  flex: 1;
`

export const Content = styled.div`
  height: 248px;
  max-height: 248px;
  overflow: auto;
  flex: 1;
  padding-right: 8px;
`

export const Heading = styled.h4`
  color: ${theme.COLORS.gray10};
  font-size: 16px;
  font-weight: 500;
`

export const ListCandidate = styled.ul`
  max-width: 285px;
`

export const Candidate = styled.li`
  margin: 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`

export const CardCandidate = styled.div`
  height: 32px;
  border: 1px solid #e9e9f0;
  border-radius: 8px;
  min-width: 213px;
  max-width: 213px;
  padding: 4px 8px;
  color: #e9e9f0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const AvatarBox = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  background: #111111;
`

export const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const RemoveIcon = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: #d70708;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
  }
`

export const WrapperFilter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  height: 100%;
`

export const FieldValues = styled.div``

export const ContentActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
  margin-top: 25px;

  button {
    padding: 4px 20px;

    &:hover {
      transform: scale(1);
    }
  }
`
