import React, { createContext, useContext, useState } from 'react'
import { IChildTypes } from 'types/childrenTypes'

const SidBarContext = createContext<any>(null)

const SidBarProvider = ({ children }: IChildTypes) => {
  const [open, setOpen] = useState<boolean>(true)
  const [titleMenu, setTitleMenu] = useState<boolean>(true)

  return <SidBarContext.Provider value={{ open, setOpen, setTitleMenu, titleMenu }}>{children}</SidBarContext.Provider>
}

const useSidBar = () => {
  return useContext(SidBarContext)
}

export { SidBarProvider, useSidBar }
