import React, { createContext, useContext, useState } from 'react'

type PROFILE_TYPES = 'profile_type' | 'public_role' | 'public_person' | string

interface ProfileDataType {
  profile_type: any
  public_role: boolean
  public_person: boolean
}

interface StepContextType {
  currentStep: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  passwordData: string
  handlePassword: (val: string) => void
  profileData: ProfileDataType
  handleProfileData: ({ type, value }: { type: PROFILE_TYPES; value: string }) => void
}

const StepContext = createContext<StepContextType | undefined>(undefined)

interface StepProviderProps {
  children: React.ReactNode
}
export const StepProvider = ({ children }: StepProviderProps) => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [passwordData, setPasswordData] = useState<string>('')
  const [profileData, setProfileData] = useState({} as ProfileDataType)

  const handlePassword = (val: string) => {
    setPasswordData(val)
  }

  const handleProfileData = ({ type, value }: { type: PROFILE_TYPES; value: string }) => {
    console.log('is_here: ', { type, value })
    setProfileData(prev => ({ ...prev, [type]: value }))
  }

  return (
    <StepContext.Provider
      value={{ currentStep, setCurrentStep, passwordData, handlePassword, profileData, handleProfileData }}
    >
      {children}
    </StepContext.Provider>
  )
}

export const useStep = () => {
  const context = useContext(StepContext)
  if (!context) {
    throw new Error('useStep must be used within a StepProvider')
  }
  return context
}
