import { useState, useEffect, useCallback } from "react";
import { apiVox } from "services/apiService";
import { ICandidateType } from "componentsNew/CockpitComponents/types/adversaryTypes";
import { debounce } from "lodash";

interface IShareOfVoiceResponse {
  sources: string[];
  data: {
    id_cand: string;
    data: number[];
  }[];
  cands: string[];
  totals: { [key: string]: number };
  pages_totals: { [key: string]: number };
}

interface IProcessedShareOfVoiceData {
  id: string;
  candidate_name: string;
  candidate_id: string;
  data: number[];
  total_mentions: number;
  color: string;
}

const useShareOfVoice = (
  candidateIds: string,
  socialNetworks: string,
  initial_date?: string,
  final_date?: string,
  selectedAdversary?: ICandidateType[],
) => {
  const [data, setData] = useState<IProcessedShareOfVoiceData[] | null>(null);
  const [sources, setSources] = useState<string[]>([]);
  const [candidates, setCandidates] = useState<string[]>([]);
  const [totals, setTotals] = useState<{ [key: string]: number }>({});
  const [pagesTotals, setPagesTotals] = useState<{ [key: string]: number }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    if (!candidateIds || !socialNetworks) {
      setData([]);
      return;
    }

    try {
      setLoading(true);
      const params = new URLSearchParams({
        id_cand: candidateIds,
        page_type_name: socialNetworks,
      });

      if (initial_date) {
        params.append("initial_date", initial_date);
      }
      if (final_date) {
        params.append("final_date", final_date);
      }

      const response = await apiVox.get<IShareOfVoiceResponse>(
        `/vox/v1/political-vox/share-of-voice-summary?${params.toString()}`,
      );

      const {
        data: apiData,
        sources,
        cands,
        totals,
        pages_totals,
      } = response.data;

      setSources(sources);
      setCandidates(cands);
      setTotals(totals);
      setPagesTotals(pages_totals);

      const processedData: IProcessedShareOfVoiceData[] = apiData.map(
        (series) => {
          const candidateData = selectedAdversary?.find(
            (adversary) => adversary.candidate_id === series.id_cand,
          );

          return {
            id: series.id_cand,
            candidate_name: candidateData?.candidate_name || series.id_cand,
            candidate_id: series.id_cand,
            data: series.data,
            total_mentions: series.data.reduce((sum, value) => sum + value, 0),
            color: candidateData?.color || "#FFFFFF",
          };
        },
      );

      processedData.sort((a, b) => b.total_mentions - a.total_mentions);

      setData(processedData);
    } catch (err) {
      console.error("Failed to fetch Share of Voice data:", err);
      setError("Falha ao buscar dados de Share of Voice");
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(debounce(fetchData, 2000), [
    candidateIds,
    socialNetworks,
    initial_date,
    final_date,
    selectedAdversary,
  ]);

  useEffect(() => {
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  return {
    data,
    sources,
    candidates,
    totals,
    pagesTotals,
    loading,
    error,
  };
};

export default useShareOfVoice;
