import React, { useEffect, useState } from "react";
import { ListeningCity } from "componentsNew/PoliticalListeningComponents/ListeningCity";
import { ListeningPestal } from "componentsNew/PoliticalListeningComponents/ListeningPestal";
import TabsCustom from "componentsNew/Tabs";
import Spin from "componentsNew/Spin";
import { ListeningSwot } from "componentsNew/PoliticalListeningComponents/ListeningSwot";
import { ListeningAdversary } from "componentsNew/PoliticalListeningComponents/ListeningAdversary";
import { Allies } from "componentsNew/PoliticalListeningComponents/Allies";
import { ListeningCand } from "componentsNew/PoliticalListeningComponents/ListeningCand";
import { useCandidates } from "hooks/useCandidates";
import { useTab } from "global/TabContext";
import * as S from "./styles";

const labels = [
  {
    label: "Candidato",
    key: "tab_political_listening_candidate",
  },
  {
    label: "Cidade Alvo",
    key: "tab_political_listening_city",
  },
  {
    label: "Macro Ambiente (PESTAL)",
    key: "tab_political_listening_pestal",
  },
  {
    label: "Campanha (SWOT)",
    key: "tab_political_listening_swot",
    disabled: true,
  },
  {
    label: "Adversários",
    key: "tab_political_listening_competitive",
  },
  {
    label: "Aliados",
    key: "tab_political_listening_allies",
  },
];

export const PoliticalListening = () => {
  const [activeTab, setActiveTab] = React.useState(
    "tab_political_listening_candidate",
  );
  const { setActiveTabContext } = useTab();
  const [mapLoading, setMapLoading] = useState(false);
  const [map, setMap] = useState([]);

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  const [mapCity, setMapCity] = useState([]);
  const [mapLoadingCity, setMapLoadingCity] = useState(true);

  const { isLoadedCandidates } = useCandidates();

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }
  const changePoliticalTab = (item: string) => {
    if (item === "tab_political_listening_candidate") {
      setMap([]);
      setMapLoading(true);
    }
    if (item === "tab_political_listening_city") {
      setMapCity([]);
      setMapLoadingCity(true);
    }
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <TabsCustom
          title="Politica Vox®"
          labels={labels}
          activeTab={activeTab}
          content={[
            <ListeningCand
              activeTab={activeTab}
              map={map}
              setMap={setMap}
              mapLoading={mapLoading}
              setMapLoading={setMapLoading}
            />,
            <ListeningCity
              activeTab={activeTab}
              map={mapCity}
              setMapLoading={setMapLoadingCity}
              setMap={setMapCity}
              mapLoading={mapLoadingCity}
            />,
            <ListeningPestal activeTab={activeTab} />,
            <ListeningSwot activeTab={activeTab} />,
            <ListeningAdversary activeTab={activeTab} />,
            <Allies activeTab={activeTab} />,
          ]}
          onChange={(key) => changePoliticalTab(key)}
        />
      </S.Content>
    </S.Wrapper>
  );
};
