import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 38px;
`;

export const Wrapper = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 5px solid #ffc000;
  position: relative;

  .icon-archetype {
    max-width: 16px;
  }
`;

export const AvatarImageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const Icon = styled.span`
  position: absolute;
  right: -25px;
  bottom: -21px;
  width: 53px;
  height: 53px;
  border-radius: 50%;
  background: #ffc000;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 32px;
    height: 32px;
  }

  img {
    width: 32px;
    height: 32px;
  }
`;

export const Message = styled.span`
  position: absolute;
  right: -27px;
  top: 50px;
  width: 38px;
  height: 20px;

  border-radius: 4px;

  background: #ffc000;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 900;
  color: #161717;
`;
