import { z } from "zod";

export const validationSchema = z.object({
  email: z.string().email("Formato de email inválido"),
  password: z.string().min(6, "A senha deve ter no mínimo 6 caracteres"),
  number: z.number().positive("O número deve ser positivo"),
  cpf: z
    .string()
    .regex(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "Formato de CPF inválido"),
  date: z
    .string()
    .refine((value) => !isNaN(Date.parse(value)), "Formato de data inválido"),
});
