import { FooterOnboarding } from 'componentsNew/OnboardingComponents/Footer'
import { FormDataTypes } from 'componentsNew/FormGenerator'
import { CustomSelect } from 'componentsNew/FormGenerator/components/CustomSelect'
import { AnimatePresence } from 'framer-motion'
import { useStep } from 'componentsNew/OnboardingComponents/Hooks/useStep'
import { PEP_CHECK_LABEL, PUBLIC_CHECK_LABEL, SELECT_OPTIONS } from './HardCodedData'
import * as S from './styles'

export const Campaign = () => {
  const { setCurrentStep, currentStep } = useStep()

  const handlePasswordChange = (value: string) => {
    console.log(value)
  }

  const handleConfirmPasswordChange = (value: string) => {
    console.log(value)
  }

  const next = () => {
    /*  setCurrentStep(prevStep => prevStep + 1) */
  }

  const prev = () => {
    setCurrentStep(prevStep => prevStep - 1)
  }

  const formData: FormDataTypes = {
    profile_type: {
      inputType: 'select',
      label: 'profile_type',
      placeholder: 'Qual o seu perfil?',
      required: false,
      options: SELECT_OPTIONS,
    },
    public_role: {
      inputType: 'checkbox',
      label: 'public_role',
      required: false,
      placeholder: PUBLIC_CHECK_LABEL,
    },

    public_person: {
      inputType: 'checkbox',
      label: 'public_person',
      required: false,
      placeholder: PEP_CHECK_LABEL,
    },
  }

  return (
    <S.Wrapper>
      <S.Heading>Olá, José!</S.Heading>
      <AnimatePresence mode="wait">
        <S.Content
          key={currentStep}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <S.Text>
            Informe quais são os <strong>objetivos</strong> da campanha eleitoral:
          </S.Text>

          <S.ContentForm>
            <CustomSelect id="selected_profile" placeholder="Cargo - Alvo" options={SELECT_OPTIONS} />
            <CustomSelect id="selected_profile" placeholder="Ano da eleição" options={SELECT_OPTIONS} />
            <CustomSelect id="selected_profile" placeholder="Localidade" options={SELECT_OPTIONS} className="full" />
            {/*        <CustomSelect id="selected_profile" placeholder="Cidade - Alvo" options={SELECT_OPTIONS} className="full" /> */}
          </S.ContentForm>
        </S.Content>
      </AnimatePresence>
      {/*  <Button type="primary" disabled={!isStrongPassword}>
        Submit
      </Button> */}

      <FooterOnboarding current={currentStep} totalSteps={3} next={next} prev={prev} />
    </S.Wrapper>
  )
}
