import {
  addHours,
  formatDistanceToNow,
  differenceInHours,
  format,
} from "date-fns";

import { ptBR } from "date-fns/locale";

import iconAdm from "assets/iconAdministration.svg";
import ecoLogy from "assets/helper/ecology.svg";
import globeThin from "assets/helper/globe_thin.svg";
import iconHealth from "assets/iconHealth.svg";
import iconEducation from "assets/iconEducation.svg";
import iconPublicSecurity from "assets/iconPublicMon.svg";
import iconConstruction from "assets/iconConstruction.svg";
import iconPubService from "assets/iconServPublic.svg";
import iconAssSocial from "assets/iconAssistSocial.svg";
import iconSport from "assets/iconSportLeisure.svg";
import iconFarm from "assets/iconFarm.svg";
import iconTwitter from "assets/icon-x.svg";
import iconTopicos from "assets/iconTopicos.svg";
import iconInstagram from "assets/icon-instagram.svg";
import iconGoogle from "assets/iconGoogle.svg";
import iconFacebook from "assets/icon-facebook.svg";
import iconReddit from "assets/icon-reddit.svg";
import iconTumblr from "assets/icon-tumblr.png";
import iconYouTube from "assets/icon-youtube.svg";
import iconDevices from "assets/devices.svg";
import iconIOT from "assets/iot.svg";
import iconAntena from "assets/antena.svg";
import iconRedes from "assets/network.svg";

const serviceColors = [
  { slug: "Administração", icon: iconAdm, color: "#6E2F9D" },
  { slug: "Saúde", icon: iconHealth, color: "#059D4A" },
  { slug: "Educação", icon: iconEducation, color: "#B28801" },
  { slug: "Segurança Pública", icon: iconPublicSecurity, color: "#650202" },
  { slug: "Obras", icon: iconConstruction, color: "#FF0000" },
  { slug: "Serviço Público", icon: iconPubService, color: "#016DBA" },
  { slug: "Assistência Social", icon: iconAssSocial, color: "#9B008C" },
  { slug: "Esporte/Lazer", icon: iconSport, color: "#813B0C" },
  { slug: "Fazenda", icon: iconFarm, color: "#223458" },
  { slug: "Economia", icon: iconFarm, color: "#223458" },
  { slug: "Ecossistema Político", icon: globeThin, color: "#bebebe" },
];

const handleServiceColor = (slug: string) => {
  switch (slug) {
    case "Administração":
      return { icon: iconAdm, color: "#6E2F9D" };
    case "Saúde":
      return { icon: iconHealth, color: "#059D4A" };
    case "Educação":
      return { icon: iconEducation, color: "#B28801" };
    case "Segurança Pública":
      return { icon: iconPublicSecurity, color: "#650202" };
    case "Obras":
      return { icon: iconConstruction, color: "#FF0000" };
    case "Serviço Público":
      return { icon: iconPubService, color: "#016DBA" };
    case "Assistência Social":
      return { icon: iconAssSocial, color: "#9B008C" };
    case "Cultura e Turismo":
      return { icon: iconSport, color: "#813B0C" };
    case "Fazenda":
      return { icon: iconFarm, color: "#223458" };
    case "Economia":
      return { icon: iconFarm, color: "#223458" };
    case "journal_count":
      return { icon: iconFarm, color: "#223458" };
    case "Meio Ambiente":
      return { icon: ecoLogy, color: "#00b118" };
    case "Ecossistema Político":
      return { icon: globeThin, color: "#bebebe" };
    default:
      return { icon: iconFarm, color: "#929292" };
  }
};

const handleMapColor = (slug: string) => {
  switch (slug) {
    case "Administração":
      return ["#2E0E4D", "#6E2F9D", "#B456CF", "#E1A4FF"];
    case "Saúde":
      return ["#005E2B", "#059D4A", "#30C873", "#75E6A4"];
    case "Educação":
      return ["#8B6401", "#B28801", "#D4A741", "#F2D580"];
    case "Segurança Pública":
      return ["#3C0101", "#650202", "#8C0303", "#C20808"];
    case "Obras":
      return ["#990000", "#FF0000", "#FF3333", "#FF6666"];
    case "Serviço Público":
      return ["#004073", "#016DBA", "#4391C9", "#7EB8DB"];
    case "Assistência Social":
      return ["#6D0068", "#9B008C", "#BF4CAE", "#E17FD3"];
    case "Cultura e Turismo":
      return ["#4E2608", "#813B0C", "#A86230", "#D19670"];
    case "Fazenda":
      return ["#132033", "#223458", "#394F6E", "#5B7493"];
    case "Economia":
      return ["#172634", "#223458", "#30547A", "#4A6F9D"];
    case "Meio Ambiente":
      return ["#135e00", "#309d05", "#35c830", "#75e67b"];
    case "Ecossistema Político":
      return ["#a3a3a3", "#a7a7a7", "#cccccc", "#d3d3d3"];
    default:
      return [
        "#6E2F9D",
        "#059D4A",
        "#B28801",
        "#650202",
        "#FF0000",
        "#9B008C",
        "#016DBA",
        "#813B0C",
        "#223458",
      ];
  }
};

const iconRedMens = (socialNetwork: string) => {
  switch (socialNetwork) {
    case "X":
      return { icon: iconTwitter };
    case "x":
      return { icon: iconTwitter };
    case "Twitter":
      return { icon: iconTwitter };
    case "Topicos":
      return { icon: iconTopicos };
    case "Instagram":
      return { icon: iconInstagram };
    case "Online News":
      return { icon: iconGoogle };
    case "Blogs":
      return { icon: iconGoogle };
    case "Facebook":
      return { icon: iconFacebook };
    case "Facebook Public":
      return { icon: iconFacebook };
    case "Forums":
      return { icon: iconGoogle };
    case "Reddit":
      return { icon: iconReddit };
    case "Review":
      return { icon: iconGoogle };
    case "Tumblr":
      return { icon: iconTumblr };
    case "YouTube":
      return { icon: iconYouTube };
    case "Assistente IOT":
      return { icon: iconIOT };
    case "Infraestrutura":
      return { icon: iconAntena };
    case "Redes":
      return { icon: iconRedes };
    case "Dispositivos":
      return { icon: iconDevices };
    default:
      return { icon: null };
  }
};

const formatDateTime = (dateTime: string) => {
  const newDateTime = new Date(dateTime);
  const adjustedDateTime = addHours(newDateTime, -3);

  const hoursDifference = differenceInHours(new Date(), adjustedDateTime);

  if (hoursDifference > 24) {
    return format(adjustedDateTime, "'em' dd/MM/yyyy 'às' HH:mm", {
      locale: ptBR,
    });
  } else {
    return formatDistanceToNow(adjustedDateTime, {
      addSuffix: true,
      locale: ptBR,
    });
  }
};

export {
  handleServiceColor,
  handleMapColor,
  iconRedMens,
  formatDateTime,
  serviceColors,
};
