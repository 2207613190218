import styled from 'styled-components'

export const Container = styled.div`
  width: 36rem;
  height: 44rem;
  border-radius: 8px;
  background-color: #161717;
  padding: 1rem;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const WrapperMetodos = styled.div`
  border-top: 1px solid #4f4f4f;
  margin-top: 1rem;
  padding-top: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Badge = styled.div`
  width: 1.12rem;
  height: 1.12rem;
  border-radius: 100%;
  background: #ffc000;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InputSelectBase = styled.div`
  width: 100%;
  height: 1.87rem;
  border-radius: 8px;
  border: 1px solid #4f4f4f;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin: 1rem 0;
`

export const InputSelectBaseBadge = styled.div`
  width: 100%;
  height: 1.87rem;
  border-radius: 8px;
  border: 1px solid #4f4f4f;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin: 0.5rem 0;
  justify-content: space-between;
  color: #161717;
  cursor: pointer;

  .title {
    display: flex;
  }
`
export const InputRaduisBase = styled.input`
  margin-right: 0.5rem;
`
