import styled from "styled-components";

export const ContainerCard = styled.div<{
  color: string;
  fullWidth?: boolean;
  hasBorder?: boolean;
}>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "296px")};
  max-width: ${({ fullWidth }) => (fullWidth ? "unset" : "330px")};
  height: auto;
  min-height: 100px;
  border: ${({ hasBorder, color }) =>
    hasBorder ? `2px solid ${color}` : "none"};
  border-radius: 8px;
  overflow: hidden;
  background: #0a0a0a;
  display: flex;

  .iconHeader {
    margin-bottom: 8px;
  }

  .mark {
    width: 8px;
    background: ${({ hasBorder, color }) =>
      hasBorder ? color : "transparent"};
  }

  .wrapper {
    padding: 8px;
    width: 100%;
  }

  .header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 12px 0;
  }

  .title {
    display: flex;
    align-items: center;

    .icon-default {
      width: 24px;
      height: 24px;
      background: #a19d9d;
      color: #161717;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      border-radius: 50%;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .external-link {
      cursor: pointer;
    }
  }

  .icon {
    width: 24px;
  }

  .status-report {
    display: flex;
    width: 100%;
    margin-top: 16px;
    align-items: center;
    justify-content: space-between;

    .posted-on {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
      text-align: right;
      color: #e9e9f0;
      margin-right: 8px;
    }
  }
`;

export const Author = styled.span`
  color: #e9e9f0;
  font-weight: 400;
  line-height: 20px;
  font-size: 16px;
  margin-right: 4px;
  text-decoration: underline;
`;

export const TextMore = styled.p`
  font-size: 10px;
  color: #ffc000;
  cursor: pointer;
`;

export const ImagesPost = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  img {
    max-width: 400px;
    height: auto;
    border-radius: 16px;
  }
`;

export const PostReportContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PostReport = styled.span<{ opacity: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
  gap: 2px;
  color: #eaeaf0;
  font-size: 10px;
  font-weight: 900;
  opacity: ${({ opacity }) => opacity};
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin-right: 12px;
`;
