/* import { motion, AnimatePresence } from 'framer-motion'
 */ import { PassWordForm } from 'componentsNew/OnboardingComponents/Forms/PassWord'
import { useStep } from '../Hooks/useStep'
import { SelectProfile } from '../Forms/SelectProfile'

import * as S from './styles'
import { Campaign } from '../Forms/Campaign'
import { SelectCand } from '../Forms/SelectCand'

export const OnboardingSteps = () => {
  const { currentStep, profileData } = useStep()

  const steps = [
    {
      title: 'Cadastro de senha',
      content: <PassWordForm />,
    },
    {
      title: 'Seleção de perfil',
      content: <SelectProfile />,
    },
    {
      title: 'Objetivo na campanha || Seleção de Candidato',
      content: profileData.profile_type === 2 ? <Campaign /> : <SelectCand />,
    },
  ]

  return (
    <S.Wrapper>
      <S.Content>{steps[currentStep].content}</S.Content>
    </S.Wrapper>
  )
}
