import { useState, useEffect, useCallback } from "react";
import { apiVox } from "services/apiService";
import { debounce } from "lodash";

interface ISentimentData {
  id_cand: string;
  total: number;
  percent_positive: number;
  percent_neutral: number;
  percent_negative: number;
}

interface IAggregateData {
  total: number;
  positive: number;
  neutral: number;
  negative: number;
  percent_positive: number;
  percent_neutral: number;
  percent_negative: number;
}

interface ISentimentAnalysisResponse {
  data: ISentimentData[];
  agg_data: IAggregateData;
}

const useSentimentAnalysis = (
  candidateIds: string,
  socialNetworks: string,
  initial_date?: string,
  final_date?: string,
) => {
  const [data, setData] = useState<ISentimentData[] | null>(null);
  const [aggData, setAggData] = useState<IAggregateData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    if (!candidateIds || !socialNetworks) {
      return;
    }

    try {
      setLoading(true);
      const params = new URLSearchParams({
        id_cand: candidateIds,
        page_type_name: socialNetworks,
      });

      if (initial_date) {
        params.append("initial_date", initial_date);
      }
      if (final_date) {
        params.append("final_date", final_date);
      }

      const response = await apiVox.get(
        `/vox/v1/political-vox/sentiment-cockpit-summary?${params.toString()}`,
      );

      const { data, agg_data } = response.data;
      setData(data);
      setAggData(agg_data);
    } catch (err) {
      console.error("Failed to fetch Sentiment Analysis data:", err);
      setError("Falha ao buscar dados de Análise de Sentimento");
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(debounce(fetchData, 2000), [
    candidateIds,
    socialNetworks,
    initial_date,
    final_date,
  ]);

  useEffect(() => {
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  return { data, aggData, loading, error };
};

export default useSentimentAnalysis;
