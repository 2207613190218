interface SimulatedResponse<T> {
  success: boolean
  data: T
}

const simulateRequest = async <T>(mockData: T): Promise<SimulatedResponse<T>> => {
  return new Promise(resolve => {
    setTimeout(() => {
      const success = Math.random() < 1

      const response: SimulatedResponse<T> = {
        success,
        data: success ? mockData : (null as unknown as T),
      }

      resolve(response)
    }, 1000)
  })
}

export default simulateRequest
