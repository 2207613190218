import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 97%;
  margin: 4px;
`;

export const Section = styled.div``;

export const SectionTitle = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  color: #e9e9f0;
  margin: 4px;
`;

export const EmptyMessage = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 200px;
`;

export const Pagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  max-width: 350px;
`;

export const PageNumber = styled.span<{ active: boolean }>`
  margin: 0 5px;
  cursor: pointer;
  font-size: 12px;
  color: ${({ active }) => (active ? "#ffc000" : "#ffffff")};
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  &:hover {
    text-decoration: underline;
  }
`;
