import React from 'react'
import { Container } from './styles'
import Text from '../../../../componentsNew/Text'

interface ITableMark {
  isActive?: boolean
  value: string
  intensity: number
}
const TableMark = ({ isActive = false, value, intensity }: ITableMark) => {
  return (
    <Container isActive={isActive} intensity={intensity}>
      <Text color={intensity > 0.7 ? 'black' : 'white'} bold>
        {value}
      </Text>
    </Container>
  )
}

export default TableMark
