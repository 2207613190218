import React, { ChangeEvent, useState } from 'react'
import { IoIosStarOutline, IoMdStar } from 'react-icons/io'
import { HiDotsVertical } from 'react-icons/hi'
import Spin from 'componentsNew/Spin'
import * as S from './styles'

interface IMessage {
  id: string
  type: 'analyst' | 'strategist' | 'marketing'
  title: string
  date: string
  favorite: boolean
}

interface IHistoryCardProps {
  message: IMessage
  onToggleFavorite: (id: string) => void
  onResumeConversation: (id: string) => void
  onDeleteConversation: (id: string) => void
  onRenameConversation: (id: string, editedTitle: string) => void
  actionLoading: boolean
  openDropdownId: string | null
  setOpenDropdownId: React.Dispatch<React.SetStateAction<string | null>>
}

const HistoryCard: React.FC<IHistoryCardProps> = ({
  message,
  onToggleFavorite,
  onResumeConversation,
  onDeleteConversation,
  onRenameConversation,
  actionLoading,
  openDropdownId,
  setOpenDropdownId,
}) => {
  const { id, type, title, date, favorite } = message
  const [isEditing, setIsEditing] = useState(false)
  const [editedTitle, setEditedTitle] = useState(title)

  const toggleDropdown = () => {
    setOpenDropdownId(openDropdownId === id ? null : id)
  }

  const startEditing = () => {
    setEditedTitle(title)
    setIsEditing(true)
    setOpenDropdownId(null)
  }

  const cancelEditing = () => {
    setIsEditing(false)
  }

  const saveTitle = () => {
    onRenameConversation(id, editedTitle)
    setIsEditing(false)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditedTitle(event.target.value)
  }

  console.log('Data HistoricCard: ', date)

  return (
    <>
      <S.Container>
        <S.Content>
          <S.Header>
            <S.FavoriteIcon onClick={() => onToggleFavorite(id)}>
              {favorite ? <IoMdStar color="#ffc000" size="13px" /> : <IoIosStarOutline color="#ffc000" size="13px" />}
            </S.FavoriteIcon>
            <S.TitleCopilot>
              {type === 'analyst' && 'Analista'}
              {type === 'strategist' && 'Estrategista'}
              {type === 'marketing' && 'Marketing Digital'}
            </S.TitleCopilot>
            <S.Date>{date}</S.Date>
          </S.Header>
          <S.BodyContent>
            {actionLoading && <Spin fullscreen />}
            {!actionLoading && (
              <>
                <S.TitleChat>
                  {isEditing ? (
                    <input
                      type="text"
                      value={editedTitle}
                      onBlur={saveTitle}
                      onChange={handleInputChange}
                      onKeyUp={e => e.key === 'Enter' && saveTitle()}
                    />
                  ) : (
                    title
                  )}
                </S.TitleChat>
                <S.Options onClick={toggleDropdown}>
                  <HiDotsVertical size="16px" />
                </S.Options>
                {openDropdownId === id && (
                  <S.DropdownMenu>
                    <S.DropdownItem onClick={() => onResumeConversation(id)}> Retomar conversa </S.DropdownItem>
                    <S.DropdownItem onClick={() => onDeleteConversation(id)}> Deletar </S.DropdownItem>
                    <S.DropdownItem onClick={startEditing}>Renomear</S.DropdownItem>
                    <S.DropdownItem onClick={() => onToggleFavorite(id)}>
                      {favorite ? 'Desfavoritar' : 'Favoritar'}
                    </S.DropdownItem>
                  </S.DropdownMenu>
                )}
              </>
            )}
          </S.BodyContent>
        </S.Content>
      </S.Container>
    </>
  )
}

export default HistoryCard
