import styled from 'styled-components'

interface propsContainer {
  open: boolean
}

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_DARK};
  transition: padding-left 0.5s;
  overflow: hidden;
`

export const Wrapper = styled.div<propsContainer>`
  width: 100%;
  overflow: auto;
  height: calc(100% - 56px);
  padding-left: ${({ open }) => (open ? '230px' : '160px')};
  padding-top: 16px;
  padding-bottom: 16px;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_DARK};
  display: flex;
  justify-content: center;
  position: relative;
  transition: padding-left 0.3s;
`

export const Content = styled.div`
  max-width: 1115px;
  width: 100%;
  margin: 16px;

  background: ${({ theme }) => theme.COLORS.BACKGROUND_DARK};
`
