import styled from 'styled-components'

export const Wrapper = styled.main``

export const Section = styled.section`
  width: 100%;
  gap: 16px;
  margin: 16px 0;
  background: #161717;
  padding: 12px;
  border-radius: 8px;
  min-height: 200px;

  @media screen and (max-width: 1099px) {
    gap: 23px;
    flex-direction: column;
  }
`

export const Heading = styled.h3`
  font-size: 16px;
  color: #e9e9f0;
  font-weight: 500;
  margin-bottom: 16px;
`

export const BoxList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
`

export const Box = styled.div`
  height: 100%;
  flex: 1;
  background: #111111;
  padding: 8px;
  border-radius: 8px;
  @media screen and (max-width: 1099px) {
    width: 100%;
  }
`
