import React from 'react'
import { IoMdAdd, IoMdRemove } from 'react-icons/io'
import { noop } from 'lodash'
import { CustomSelect } from '../CustomSelect'
import IconeEscutaPolitica from '../../../../icons/icon-political-listening.svg'
import * as S from './styles'

interface CardSelectionProps<T> {
  isMore?: boolean | null
  dataSelection: any
  withIcon?: boolean
  onChange?: (index: number, data: any) => void
  default_theme?: any[]
  fixed_theme?: any[]
  onChangeListener?: (checked: boolean, id: number) => void
  onRemoveTheme?: (index: number, data: any) => void
  keyType?: string
}

export const CardSelection: React.FC<CardSelectionProps<any>> = ({
  isMore = true,
  dataSelection,
  withIcon = false,
  onChange = noop,
  default_theme = [],
  fixed_theme = null,
  onChangeListener = noop,
  onRemoveTheme = noop,
  keyType,
}) => {
  const selection = dataSelection?.theme_list || []

  const selectedValues = new Set(selection.map((item: any) => item?.id))

  const formattedOptions = default_theme
    .filter(theme => theme.topic_name === dataSelection?.topic_name || theme.topic_name === dataSelection?.name)
    .map((option: any) => ({
      ...option,
      value: option.id,
      label: option.name || option?.theme_name,
    }))
    .filter(option => !selectedValues.has(option?.id))

  const fixedThemeFormatted =
    fixed_theme &&
    fixed_theme
      .map((option: any) => ({
        ...option,
        value: option.id,
        label: option.name || option?.theme_name,
        themeName: option.theme_name,
      }))
      .filter(option => !selectedValues.has(option?.id))
  const limitedSelection = selection.slice(0, 8)

  return (
    <S.Wrapper>
      <S.Heading>
        {dataSelection.topic_name || dataSelection?.name}
        {withIcon ? <>({isMore ? <IoMdAdd color="#00E23F" size={16} /> : <IoMdRemove color="#D70708" />})</> : null}
      </S.Heading>
      <S.WrapperSelection>
        {limitedSelection.map((selectedValue: any, index: any) => (
          <CustomSelect
            key={`${keyType}_${selectedValue?.data?.id || selectedValue?.id}_${index}`}
            keyOption={selectedValue?.data?.id || selectedValue?.id}
            width="unset"
            style={{ maxWidth: '194px' }}
            options={fixedThemeFormatted || formattedOptions}
            defaultValue={selectedValue}
            defaultSwitch={selectedValue?.is_listening}
            disableSwitch={!selectedValue}
            is_recommended={selectedValue?.is_recommended}
            handleClear={data => onRemoveTheme(index, data)}
            onSelect={(val, newValue) => {
              onChange(index, {
                ...newValue,
                data: {
                  ...newValue?.data,
                  is_recommended: false,
                  is_listening: true,
                  theme: newValue?.data?.id,
                  theme_name: newValue?.data?.label,
                },
              })
            }}
            handleListener={checked => onChangeListener(checked, selectedValue?.data?.id || selectedValue?.id)}
          />
        ))}
        <S.Separator />
        <S.IconBox className="rightIcon">
          <S.Icon src={IconeEscutaPolitica} alt="icon listening political" />
        </S.IconBox>
        <S.IconBox className="leftIcon">
          <S.Icon src={IconeEscutaPolitica} alt="icon listening political" />
        </S.IconBox>
      </S.WrapperSelection>
    </S.Wrapper>
  )
}
