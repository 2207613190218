import { api } from 'services/apiService'
import { Campaign, GoalData, ResponseGoalDataTypes } from '../types/validVotesTypes'

type errorMessage = {
  message: string
}

type responseValidVotesApi = ResponseGoalDataTypes | errorMessage
type responseTimeLine = Campaign | errorMessage

export const getProgress = async (url: string): Promise<responseValidVotesApi> => {
  try {
    const { data } = await api.get<GoalData[]>(url)

    if (data) {
      return {
        goal_data: data[0],
      }
    }

    return { message: 'Não foi possivel buscar dados cadastrados.' }
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}

export const getTimeLine = async (): Promise<responseTimeLine> => {
  try {
    const { data } = await api.get('/political-trajectory/timeline/')

    if (data) {
      return data[0]
    }

    return { message: 'Não foi possivel buscar dados cadastrados.' }
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}
