import React, { useEffect } from "react";
import Spin from "componentsNew/Spin";
import Card from "componentsNew/Card";
import WordcloudCand from "componentsNew/Wordcloud";
import useSocialMentionsWc from "pages/PoliticalListening/hooks/useSocialMentionsWC";
import { Container } from "./styles";

const CardWordc = ({ width = 500 }: any) => {
  const {
    getWordPositive,
    wordsPositive,
    selectedSN,
    isLoadingPositive,
    isLoadingNegative,
  } = useSocialMentionsWc();

  useEffect(() => {
    getWordPositive();
  }, [selectedSN]);

  const isFeeting = isLoadingPositive || isLoadingNegative;
  console.log("palavras", wordsPositive);
  return (
    <Card title="Características desejadas">
      <Container>
        {isFeeting ? (
          <Spin spinning />
        ) : (
          <WordcloudCand words={wordsPositive} width={width} height={110} />
        )}
      </Container>
    </Card>
  );
};

export default CardWordc;
