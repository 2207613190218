import React from 'react'
import { Container, ProgressBarRitmo } from './styles'
import Text from '../Text'

type ColorType = 'white' | 'green' | 'red' | '00E23F'

interface IProgressBar {
  label?: string
  value?: number
  data?: number
  color?: any
}
const ProgressBar = ({ label, value, data, color }: IProgressBar) => {
  return (
    <Container>
      <Text className="subtitle-metric">{label}</Text>
      <ProgressBarRitmo value={value} max={100} color={color} />
      <Text className="subtitle-metric" color={color}>
        {data || 0}
      </Text>
    </Container>
  )
}

export default ProgressBar
