import { styled } from 'styled-components'
import theme from 'styles/theme'

export const Wrapper = styled.main<{ withTitle?: boolean; percent?: boolean }>`
  background: #161717;
  border-radius: 8px;
  flex: 1;
  height: 100%;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: ${({ withTitle }) => withTitle && 'center'};
  padding: 6px;
  min-height: 70px;
  min-width: 197px;
`

export const TitleCount = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: ${theme.COLORS.gray10};
  align-self: center;
  justify-self: center;
`

export const Description = styled.h3`
  font-size: 12px;
  font-weight: 500;
  color: ${theme.COLORS.gray10};
  align-self: flex-start;
`
export const TextCount = styled.p<{ percent?: number; colorPercent?: string }>`
  font-size: 22px;
  font-weight: 700;
  color: ${theme.COLORS.yellow10};
  display: flex;
  width: 100%;
  gap: 4px;
  align-items: center;
  justify-content: ${({ percent }) => (percent ? 'space-between' : 'center')};
  align-self: center;
  padding: 0 12px;

  &.positive {
    span {
      color: ${theme.COLORS.green10};
    }
  }

  &.negative {
    span {
      color: ${theme.COLORS.red10};
    }
  }

  &.neutral {
    span {
      color: #c2c2c2;
    }
  }
`
