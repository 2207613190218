import React from 'react'
import { StyledProgress } from './styles'

interface IProgressMinimal {
  value: string | number
  size?: 'small' | 'medium' | 'xMedium' | 'large'
}
const ProgressMinimal = ({ value, size = 'medium' }: IProgressMinimal) => {
  return (
    <StyledProgress size={size}>
      <progress value={value} max="100" />
    </StyledProgress>
  )
}

export default ProgressMinimal
