import wizard_icon from 'icons/wizard_icon.svg'
import React, { useState, useEffect } from 'react'
import { ConfigProvider, Select, theme } from 'antd'
import { DefaultOptionType, SelectProps } from 'antd/es/select'
import { noop } from 'lodash'
import { CustomSwitch } from '../CustomSwitch'
import * as S from './styles'

interface CustomSelectProps extends SelectProps {
  suffixIcon?: React.ReactNode
  onSelectItem?: (data: DefaultOptionType) => void
  fixedValue?: { value: number | string; label: string }
  width?: string
  hiddenSwitch?: boolean
  defaultSwitch?: boolean
  handleListener?: (checked: boolean) => void
  disabled?: boolean
  is_recommended?: boolean
  disableSwitch?: boolean
  defaultValue?: any
  handleClear?: (value: any) => void
  keyOption?: string
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
  options = [],
  suffixIcon,
  onSelectItem,
  fixedValue,
  width,
  hiddenSwitch,
  onSelect = noop,
  defaultSwitch,
  handleListener = noop,
  disabled,
  is_recommended,
  disableSwitch,
  defaultValue,
  handleClear = noop,
  keyOption,
  ...rest
}) => {
  const [listenActive, setListenActive] = useState(defaultSwitch)
  const [selectedValue, setSelectedValue] = useState(defaultValue)
  const selectOptionsWithDefault = [...options]

  useEffect(() => {
    setSelectedValue(defaultValue)
  }, [defaultValue])

  const handleListen = (check: boolean) => {
    setListenActive(check)
    handleListener(check)
  }

  const handleSelect = (newValue: any, option: DefaultOptionType) => {
    setListenActive(true)
    setSelectedValue(option?.data)
    onSelect(option?.data, option)
  }

  const clearWithData = () => {
    handleClear(selectedValue)
    setSelectedValue(null)
    setListenActive(false)
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            algorithm: true,
            optionSelectedBg: 'rgb(218, 147, 6)',
            selectorBg: 'rgb(21, 21, 21)',
            optionSelectedColor: 'rgb(0, 0, 0)',
            colorBorder: 'rgb(233, 233, 240)',
            optionActiveBg: 'rgba(255, 255, 255, 0.04)',
            colorPrimaryHover: 'rgb(233, 233, 240)',
            colorPrimary: 'rgb(233, 233, 240)',
          },
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <S.SelectionBox withIcon width={width} disabled={disabled}>
        <S.SelectCustom
          placeholder="---"
          size="small"
          dropdownStyle={{ border: '1px solid #E9E9F0' }}
          suffixIcon={suffixIcon}
          optionLabelProp="label"
          style={{ paddingLeft: '5px' }}
          onSelect={handleSelect}
          disabled={disabled}
          value={selectedValue}
          allowClear
          onClear={clearWithData}
          {...rest}
        >
          {selectOptionsWithDefault.map((item, index) => (
            <Select.Option
              key={`option_${keyOption}_${index}_${item.value}`}
              value={item.value}
              label={item.label}
              data={item}
              style={{ margin: '4px 0' }}
            >
              <p>{item.label}</p>
            </Select.Option>
          ))}
        </S.SelectCustom>
        {is_recommended && <S.Icon src={wizard_icon} />}
        {!hiddenSwitch && (
          <S.SwitchBox>
            <CustomSwitch
              onChange={handleListen}
              checked={listenActive}
              defaultChecked={defaultSwitch}
              disabled={disableSwitch}
            />
          </S.SwitchBox>
        )}
      </S.SelectionBox>
    </ConfigProvider>
  )
}
