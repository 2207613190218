import { useCandidates } from 'hooks/useCandidates'
import React, { lazy, Suspense, useEffect, useState } from 'react'

import { sendTheme } from '../services/getListeners'

import * as S from './styles'

const CardDiagnosisLazy = lazy(() => import('../CardDiagnosis'))

export const ListeningPestal = ({ activeTab }: any) => {
  const [filterList, setFilterList] = useState<any[]>([])
  const TOPIC_NAMES = ['Política', 'Economia', 'Social', 'Tecnologia', 'Ambiental', 'Legal']
  const { selectedCandidate } = useCandidates()
  const target_locality = selectedCandidate?.locality
  useEffect(() => {
    fetchMentions()
  }, [])

  const fetchMentions = async () => {
    const themes = await sendTheme()
    if (!('message' in themes)) {
      setFilterList(themes)
    }
  }

  if (activeTab !== 'tab_political_listening_pestal') return null

  return (
    <Suspense fallback={<div>Carregando...</div>}>
      <S.Wrapper>
        {TOPIC_NAMES.map(mention => (
          <CardDiagnosisLazy
            topic={mention}
            filters={filterList?.filter(item => item?.topic_name === mention)}
            target_locality={target_locality}
          />
        ))}
      </S.Wrapper>
    </Suspense>
  )
}
