import React from 'react'
import { IoCheckmark, IoClose } from 'react-icons/io5'
import { MdOutlineSpeed } from 'react-icons/md'
import { BsFillDiagram3Fill } from 'react-icons/bs'
import Card from '../../../../componentsNew/Card'
import iconWizard from '../../../../assets/iconWirzad.svg'
import { Container, ContainerCard } from './styles'
import InternalButton from '../../../../componentsNew/InternalButton'
import theme from '../../../../styles/theme'
import Text from '../../../../componentsNew/Text'
import Avatar from '../../../../componentsNew/Avatar'
import avatar from '../../../../assets/avatar-default.png'
import iconArrowDown from '../../../../assets/iconArrowDown.svg'
import iconArrowTop from '../../../../assets/iconArrawTop.svg'
import iconDoubleTop from '../../../../assets/iconDoubleTop.svg'
import iconNeutron from '../../../../assets/iconNeutron.svg'

const CardCandidateWirzad = () => {
  const handleIcon = (status: string) => {
    switch (status) {
      case 'down':
        return iconArrowDown
      case 'top':
        return iconArrowTop
      case 'doubleTop':
        return iconDoubleTop
      default:
        return iconNeutron
    }
  }

  return (
    <Card title="Wizard Eleitoral" icon={iconWizard}>
      <Container>
        <ContainerCard>
          <div className="title-card">
            <Avatar img={avatar} size="medium" />
            <img src={iconWizard} alt="icon" />
            <Text size="xSmall">Nome do Candidato</Text>
          </div>
          <div className="status-cadidate">
            <div>
              <img src={handleIcon('doubleTop')} alt="icon" />
            </div>
            <div>
              <Text size="xSmall">Cargo</Text>
            </div>
            <div>
              <Text size="xSmall">UF | Cidade</Text>
            </div>
          </div>

          <div className="action">
            <InternalButton
              background="transparent"
              icon={<MdOutlineSpeed />}
              style={{ color: theme.COLORS.yellow10, border: '1px solid', borderColor: theme.COLORS.yellow10 }}
              disabled
            >
              Cockpit
            </InternalButton>
            <InternalButton
              background="transparent"
              icon={<BsFillDiagram3Fill />}
              style={{ color: theme.COLORS.yellow10, border: '1px solid', borderColor: theme.COLORS.yellow10 }}
              disabled
            >
              Portifólio
            </InternalButton>
            <InternalButton background={theme.COLORS.yellow10} icon={<IoCheckmark />} disabled>
              Aceitar
            </InternalButton>
            <InternalButton
              background={theme.COLORS.red10}
              icon={<IoClose />}
              style={{ color: theme.COLORS.gray10 }}
              disabled
            >
              Recusar
            </InternalButton>
          </div>
        </ContainerCard>
      </Container>
    </Card>
  )
}

export default CardCandidateWirzad
