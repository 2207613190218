import styled from "styled-components";

interface ICardWrapper {
  isActive: boolean;
  borderColor?: string;
  isClickable: boolean;
}

export const CardWrapper = styled.div<ICardWrapper>`
  padding: 2px;
  border-radius: 5px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  display: flex;
  align-items: center;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
  border-style: solid;
  background-color: #161617;
  border-width: ${({ isActive }) => (isActive ? "2px 2px 2px 5px" : "2px")};
  border-color: ${({ isActive, borderColor }) =>
    isActive && borderColor ? borderColor : "transparent"};
  min-height: 32px;
  width: 170px;
`;

export const CandidateImage = styled.div`
  margin-right: 8px;
`;

export const CandidateInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
