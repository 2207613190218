import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MdFilterList,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import Text from "componentsNew/Text";
import { SectionTimeLine } from "componentsNew/CockpitComponents/Sections/SectionTimeLine";
import ButtonDropdown from "componentsNew/ButtonDropdown";
import InternalButton from "componentsNew/InternalButton";
import Checkbox from "componentsNew/CheckBox";
import { SectionGoals } from "componentsNew/CockpitComponents/Sections/SectionGoals";
import { useCockPit } from "componentsNew/CockpitComponents/hooks/useCockpit";
import * as S from "./styles";

const GoalsCockpit = () => {
  const navigate = useNavigate();
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const { filters, changeFilter } = useCockPit();

  const handleFilter = (type: string) => {
    if (filters) {
      const isTypeSelected = filters.includes(type);
      if (isTypeSelected) {
        const newSelectedFilters = filters.filter(
          (selectedType) => selectedType !== type,
        );
        changeFilter(newSelectedFilters);
      } else {
        const newSelectedFilters = [...filters, type];
        changeFilter(newSelectedFilters);
      }
    } else {
      changeFilter([type]);
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <RenderMenuItem
          label="Votos válidos"
          onCheck={(type: any) => handleFilter(type)}
          type="valid_votes"
        />
      ),
    },
    {
      key: "2",
      label: (
        <RenderMenuItem
          label="Atividade"
          onCheck={(type: any) => handleFilter(type)}
          type="activity"
        />
      ),
    },
    {
      key: "3",
      label: (
        <RenderMenuItem
          label="Despesas"
          onCheck={(type: any) => handleFilter(type)}
          type="expenses"
        />
      ),
    },
    {
      key: "4",
      label: (
        <RenderMenuItem
          label="Doações"
          onCheck={(type: any) => handleFilter(type)}
          type="donations"
        />
      ),
    },
  ];

  return (
    <S.Content>
      <S.StyledCard isDropdownActive={isDropdownActive}>
        <S.DropdownHeader
          onClick={() => setIsDropdownActive(!isDropdownActive)}
        >
          <S.HeaderText>
            <Text size="medium" bold>
              Metas
            </Text>
          </S.HeaderText>
          <S.DropdownToggle>
            {isDropdownActive ? (
              <MdKeyboardArrowUp size={36} color="#FFC000" />
            ) : (
              <MdKeyboardArrowDown size={36} color="#FFC000" />
            )}
          </S.DropdownToggle>
        </S.DropdownHeader>

        {isDropdownActive && (
          <S.Wrapper>
            <S.TimeLine>
              <SectionTimeLine isCockpit />
            </S.TimeLine>

            <S.Goals>
              <S.HeaderSection>
                <S.ActionsBox>
                  <S.SectionHeading></S.SectionHeading>
                </S.ActionsBox>

                <S.ActionsBox>
                  <ButtonDropdown items={items}>
                    <InternalButton
                      background="#FFC000"
                      icon={<MdFilterList />}
                      variant="outline"
                    >
                      Filtrar
                    </InternalButton>
                  </ButtonDropdown>
                  <InternalButton
                    background="#FFC000"
                    variant="outline"
                    onClick={() =>
                      navigate("/dashboard/application-strategy/goals")
                    }
                  >
                    Definir metas
                  </InternalButton>
                </S.ActionsBox>
              </S.HeaderSection>

              <S.SectionContent>
                <SectionGoals isCockpit />
              </S.SectionContent>
            </S.Goals>
          </S.Wrapper>
        )}
      </S.StyledCard>
    </S.Content>
  );
};

const RenderMenuItem = ({
  label,
  onCheck,
  type,
}: {
  label: string;
  type: string;
  onCheck: (label: string) => void;
}) => {
  const handleChange = (e: any) => {
    e.stopPropagation();
  };
  return (
    <S.RenderItem onClick={(e) => handleChange(e)}>
      <Checkbox label={label} onCheck={() => onCheck(type)} />
    </S.RenderItem>
  );
};

export default GoalsCockpit;
