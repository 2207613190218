import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative; /* Adicionado para o posicionamento absoluto funcionar corretamente */
`;

export const ChatCopilot = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  max-width: 98%;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-y: auto;
  height: 100%;

  .max-width-control {
    max-width: 100% !important;
    word-wrap: break-word !important;
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;
  }

  a {
    color: #ffc000;
    font-weight: bold;
  }
`;

export const ContainerAlert = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid #ffc000;
  border-radius: 8px;
  padding: 12px;
`;

export const UserMessage = styled.div`
  padding: 8px 16px;
  border-radius: 8px;
  align-self: flex-end;
  max-width: 80%;
  color: #ffffff;
  background-color: #161717;
  border-radius: 24px;
  margin-top: 16px;
`;

export const IAMessage = styled.div`
  background-color: transparent;
  align-self: flex-start;
  margin-bottom: 16px;
  color: #ffffff;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const MessageInputContainer = styled.div`
  display: flex;
  padding: 10px;
  background-color: #161717;

  input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #2e2e2e;
    font-family: Roboto, sans-serif;
  }
`;

export const MessageActions = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 16px;
  justify-content: flex-start;

  svg {
    cursor: pointer;
    &:hover {
      color: #ffc000;
    }
  }
`;

export const ScrollToBottom = styled.button`
  position: absolute;
  bottom: 16px;
  right: 16px;
  background-color: #ffc000;
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;

  svg {
    font-size: 24px;
    color: #161717;
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;

  label {
    font-size: 14px;
  }

  select,
  input {
    padding: 5px;
    font-size: 14px;
  }
`;
