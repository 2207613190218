import React, { useEffect } from "react";
import TabsCustom from "componentsNew/Tabs";
import Spin from "componentsNew/Spin";
import { MacroEnvTemplate } from "componentsNew/ElectionStrategyComponents/pages/MacroEnvTemplate";
import { CompetitiveTemplate } from "componentsNew/ElectionStrategyComponents/pages/CompetitivePage";
import { AllyTemplate } from "componentsNew/ElectionStrategyComponents/pages/AllyPage";
import { SwotTemplate } from "componentsNew/ElectionStrategyComponents/pages/SwotPage";
import { useCandidates } from "hooks/useCandidates";
import { useTab } from "global/TabContext";
import * as S from "./styles";

const labels = [
  {
    label: "Diagnóstico",
    sub_label: "Macro Ambiente",
    key: "tab_strategic_analysis_macro_environmental",
  },
  {
    label: "Diagnóstico",
    sub_label: "Candidatura SWOT",
    key: "tab_strategic_analysis_swot_application",
  },
  {
    label: "Diagnóstico",
    sub_label: "Competitivo",
    key: "tab_strategic_analysis_competitive",
  },
  {
    label: "Mapa de",
    sub_label: "Aliados",
    key: "tab_strategic_analysis_allies_map",
  },
];

const content: React.ReactNode[] = [
  <MacroEnvTemplate />,
  <SwotTemplate />,
  <CompetitiveTemplate />,
  <AllyTemplate />,
];

export const ElectionStrategy = () => {
  const [activeTab, setActiveTab] = React.useState(
    "tab_strategic_analysis_macro_environmental",
  );
  const { isLoadedCandidates } = useCandidates();
  const { setActiveTabContext } = useTab();

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  const changePoliticalTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={content}
          onChange={(key) => changePoliticalTab(key)}
        />
      </S.Content>
    </S.Wrapper>
  );
};
