import { FooterOnboarding } from 'componentsNew/OnboardingComponents/Footer'
import Checkbox from 'componentsNew/CheckBox'
import { CustomSelect } from 'componentsNew/FormGenerator/components/CustomSelect'
import { AnimatePresence } from 'framer-motion'
import { useStep } from 'componentsNew/OnboardingComponents/Hooks/useStep'
import { PEP_CHECK_LABEL, PUBLIC_CHECK_LABEL, SELECT_OPTIONS } from './HardCodedData'
import * as S from './styles'

export const SelectProfile = () => {
  const { setCurrentStep, currentStep, handleProfileData, profileData } = useStep()

  const handleProfile = (type: string, value: any) => {
    handleProfileData({ type, value })
  }

  const next = () => {
    setCurrentStep(prevStep => prevStep + 1)
  }

  const prev = () => {
    setCurrentStep(prevStep => prevStep - 1)
  }

  return (
    <S.Wrapper>
      <S.Heading>Olá, José!</S.Heading>
      <AnimatePresence mode="wait">
        <S.Content
          key={currentStep}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <S.Text>Por favor, confirme as informações de perfil:</S.Text>

          <S.ContentForm>
            <CustomSelect
              id="selected_profile"
              placeholder="Selecione o seu Perfil"
              options={SELECT_OPTIONS}
              onSelect={val => handleProfile('profile_type', val)}
            />
            <Checkbox label={PUBLIC_CHECK_LABEL} onCheck={val => handleProfile('public_role', val)} />
            <Checkbox label={PEP_CHECK_LABEL} onCheck={val => handleProfile('public_person', val)} />
          </S.ContentForm>
        </S.Content>
      </AnimatePresence>
      {/*  <Button type="primary" disabled={!isStrongPassword}>
        Submit
      </Button> */}

      <FooterOnboarding current={currentStep} totalSteps={3} next={next} prev={prev} />
    </S.Wrapper>
  )
}
