import React, { useEffect, useMemo, useState } from 'react'
import Particles, { initParticlesEngine } from '@tsparticles/react'
import { loadSlim } from '@tsparticles/slim'
import * as S from './styles'

const ParticlesComponent = () => {
  const [init, setInit] = useState(false)

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async engine => {
      await loadSlim(engine)
    }).then(() => {
      setInit(true)
    })
  }, [])

  const particlesLoaded = async (container: any): Promise<void> => {
    console.log(container)
  }

  const options: any = useMemo(
    () => ({
      background: {
        color: {
          value: '#2e2e2e',
        },
      },
      fpsLimit: 250,
      interactivity: {
        events: {
          onClick: {
            enable: false,
            mode: 'push',
          },
          onHover: {
            enable: false,
            mode: 'repulse',
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: '#1d1d1d',
        },
        links: {
          color: '#000000',
          distance: 200,
          enable: true,
          opacity: 0.5,
          width: 2,
        },
        move: {
          direction: 'none',
          enable: true,
          outModes: {
            default: 'bounce',
          },
          random: false,
          speed: 2,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 200,
        },
        opacity: {
          value: 1,
        },
        shape: {
          type: 'circle',
        },
        size: {
          value: { min: 3, max: 6 },
        },
      },
      detectRetina: true,
    }),
    []
  )
  if (init) {
    return (
      <S.Wrapper>
        <Particles id="tsparticles" particlesLoaded={particlesLoaded} options={options} />
      </S.Wrapper>
    )
  }

  return <></>
}

export default ParticlesComponent
