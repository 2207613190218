import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledCard = styled.div<{ isDropdownActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: #161617;
  width: 100%;
  height: ${({ isDropdownActive }) => (isDropdownActive ? "auto" : "56px")};
  overflow: hidden;
  transition: height 0.3s ease;
`;

export const DropdownHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  cursor: pointer;
`;

export const HeaderText = styled.div`
  display: flex;
  align-items: end;
  gap: 6px;

  .seven-days {
    height: 12px;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 300;
    line-height: 11.72px;
    color: #e9e9f0;
  }
`;

export const DropdownToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const FiltersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
`;

export const SocialAndMentions = styled.div`
  width: 60%;
  padding: 8px;
`;

export const TotalAndClouds = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  margin: 88px 8px 0 0;
  gap: 8px;
`;

export const MentionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: auto;
`;

export const TotalMentions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TotalsCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
`;

export const Clouds = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`;

export const ContainerWord = styled.div`
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  place-content: center;
  place-items: center;
`;

export const ListIcon = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
  height: 56px;
  gap: 2px;
`;

export const SortingHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

export const TimeDropdown = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  justify-self: self-start;
  align-self: self-start;
  margin-left: -64px;
  background-color: #161617;
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #eaeae0;
  ${TimeDropdown}:hover & {
    display: block;
  }
`;

export const DropdownItem = styled.div`
  color: white;
  padding: 8px 16px;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: #0a0a0a;
    border-radius: 8px;
  }
`;

export const Pagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
`;

export const PageNumber = styled.span<{ active: boolean }>`
  margin: 0 5px;
  cursor: pointer;
  font-size: 12px;
  color: ${({ active }) => (active ? "#ffc000" : "#ffffff")};
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  &:hover {
    text-decoration: underline;
  }
`;
