export const updateTopic = (topic_list: any[], topicIndex: number, themeIndex: any, newValue: any) => {
  const newTopics = [...topic_list]
  newTopics[topicIndex].theme_list[themeIndex] = newValue?.data || newValue

  return newTopics
}
export const removeTopic = (topic_list: any[], topicIndex: number, themeIndex: any) => {
  const newTopics = [...topic_list]
  newTopics[topicIndex].theme_list[themeIndex] = undefined
  return newTopics
}
