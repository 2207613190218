import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  gap: 1rem;
  margin-top: 16px;
  background-color: '#FFFFFF';
`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`
