import styled from "styled-components";

export const GaugeContainer = styled.div<{ maxSize?: string }>`
  width: 100%;
  max-width: ${(props) => (props.maxSize ? props.maxSize : "200px")};
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #ffffff;
  position: relative;
  overflow: hidden;
`;

export const GaugeBody = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: #1d1d1d;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
`;

export const GaugeFill = styled.div<{ color: string | null }>`
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  background: ${(props) => props.color || "#ffc000"};
  transform-origin: center top;
  transform: rotate(0.25turn);
  transition: transform 0.2s ease-out;
`;

export const GaugeCover = styled.div<{ isCockpit?: boolean }>`
  width: 75%;
  height: 150%;
  background: ${(props) => (props.isCockpit ? "#0A0A0A" : "#161717")};
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25%;
  box-sizing: border-box;
  z-index: 3;
`;

export const TextBox = styled.div<{ isCockpit?: boolean }>`
  width: 100%;
  position: absolute;
  background: ${(props) => (props.isCockpit ? "#0A0A0A" : "#161717")};
  z-index: 3;
  bottom: -5px;
  padding: 3px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #e9e9f0;
    font-size: 16px;
    font-weight: 900;
  }
`;
