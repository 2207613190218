import React from 'react'

import { Text } from '@visx/text'
import Wordcloud from '@visx/wordcloud/lib/Wordcloud'
import { Empty } from 'componentsNew/Empty'

export interface IWordData {
  text: string
  value: number
}

export interface ISocialMentionsProps {
  words: IWordData[]
  width?: number
  height?: number
  colors?: string[]
}

const color = ['#6E2F9D', '#059D4A', '#B28801', '#650202', '#FF0000', '#9B008C', '#016DBA', '#813B0C', '#223458']

const WC_WIDTH = 400

const WordcloudCand: React.FC<ISocialMentionsProps> = ({
  words = [],
  width = WC_WIDTH,
  height = width / 2,
  colors = color,
}) => {
  const fontSizeSetter = (datum: IWordData) =>
    Math.max(Math.min(datum.value, 1) * Math.max(limitWords.length, (50 * width * height) / 144000), 10)
  const fixedValueGenerator = () => 0.5

  const limitWords = words.slice(0, 200)

  if (limitWords.length === 0) {
    return <Empty />
  }

  return (
    <Wordcloud
      words={limitWords}
      width={width}
      height={height}
      fontSize={fontSizeSetter}
      font="Roboto, sans-serif"
      padding={2}
      spiral="archimedean"
      rotate={0}
      random={fixedValueGenerator}
    >
      {cloudWords =>
        cloudWords.map((w, i) => (
          <Text
            key={w.text}
            fill={colors[i % colors.length]}
            textAnchor="middle"
            transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
            fontSize={w.size}
            fontFamily={w.font}
          >
            {w.text}
          </Text>
        ))
      }
    </Wordcloud>
  )
}

export default WordcloudCand
