import React, { useState } from 'react'
import dayjs from 'dayjs'
import Text from '../../componentsNew/Text'
import ProgressBar from '../../componentsNew/ProgressBar'
import ControlledComponent from '../../componentsNew/InputDataPicker'
import Gauge from './ProgressArc'
import {
  VotingDataContainer,
  MetricContainer,
  DateContainer,
  MetricsAndDateContainer,
  WrapperData,
  Line,
  GaugeBox,
} from './styles'
import calculateProgressAndColor from "../../utils/utilsProgressCardGoals";

interface VotingDataProps {
  data: {
    id: number
    initial_value: number
    rithm: number
    weekly_goal: number
    projection: number
    goal: number
    current_value: number
    minimum_goal: number
    first_partial_goal: number
    second_partial_goal: number
    initial_date: string
    final_date: string
  }
  title: string
  subtitle: {
    week: string
    geral: string
  }
  handleChange: (value: any, key: any) => void
  onError: (error: boolean, errorMessage: string) => void
}

const VotingDataComponent: React.FC<VotingDataProps> = ({ data, title, subtitle, handleChange, onError }) => {
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const dataPie = [
    {
      initialValue: data.initial_value,
      goalValue: data.goal,
      currentValue: data.current_value,
      minimumGoal: data.minimum_goal,
      firstGoal: data.first_partial_goal,
      secondGoal: data.second_partial_goal,
    },
  ]

  const rithmValue = data.rithm
  const weeklyGoalValue = data.weekly_goal
  const projectionValue = data.projection
  const goalValue = data.goal
  const subtitleWeek = subtitle.week
  const subtitleGeral = subtitle.geral
  const defaultColor = 'white'

  const { rithmPercentage, weeklyGoalPercentage, rithmColor, projectionPercentage, goalPercentage, projectionColor } =
    calculateProgressAndColor({
      rithmValue: data.rithm,
      weeklyGoalValue: data.weekly_goal,
      projectionValue: data.projection,
      goalValue: data.goal,
    })

  const mockData = dataPie[0]

  const formatValue = (val: any) => {
    const value = Number(val)

    if (value >= 1000000000000) {
      const newValue = value / 1000000000000
      return `${newValue.toFixed(1)}T`
    }

    if (value >= 1000000000) {
      const newValue = value / 1000000000
      return `${newValue.toFixed(1)}B`
    }

    if (value >= 1000000) {
      const newValue = value / 1000000
      return `${newValue.toFixed(1)}M`
    }

    if (value >= 1000) {
      const newValue = value / 1000
      return `${newValue.toFixed(1)}mil`
    }

    return `${value}`
  }

  const GaugeData = [
    {
      limit: mockData.minimumGoal,
      color: '#df0000',
    },
    {
      limit: mockData.firstGoal,
      color: '#016DBA',
    },
    {
      limit: mockData.secondGoal,
      color: '#223458',
    },
    {
      limit: 100,
      color: '#00E23F',
    },
  ]

  const handleDataChange = (newValue: string, key: 'initial_date' | 'final_date') => {
    const newDates = { ...data, [key]: newValue }

    let errorOccurred = false
    let newErrorMessage = ''

    const initialDateIsEmpty = !newDates.initial_date || newDates.initial_date.trim() === ''
    const finalDateIsEmpty = !newDates.final_date || newDates.final_date.trim() === ''

    if (initialDateIsEmpty || finalDateIsEmpty) {
      errorOccurred = false
      newErrorMessage = 'Uma ou ambas as datas não foram definidas.'
    } else if (!dayjs(newDates.initial_date).isValid() || !dayjs(newDates.final_date).isValid()) {
      errorOccurred = true
      newErrorMessage = 'Uma ou ambas as datas são inválidas.'
    } else if (dayjs(newDates.initial_date).isAfter(dayjs(newDates.final_date))) {
      errorOccurred = true
      newErrorMessage = 'A data inicial deve ser anterior à data final.'
    } else if (dayjs(newDates.final_date).isBefore(dayjs(newDates.initial_date))) {
      errorOccurred = true
      newErrorMessage = 'A data final deve ser posterior à data inicial.'
    }

    setError(errorOccurred)
    setErrorMessage(newErrorMessage)
    onError(errorOccurred, newErrorMessage)

    if (!errorOccurred) {
      handleChange(newValue, key)
    }
  }

  return (
    <VotingDataContainer>
      <Text size="medium" bold>
        {title}
      </Text>
      <GaugeBox>
        <Gauge
          formatLabel={val => formatValue(val)}
          labels={{ max: formatValue(mockData.goalValue * 1.2), min: formatValue(mockData.initialValue) }}
          defaultData={GaugeData}
          maxValue={mockData.goalValue * 1.2}
          sizeStyle="250px"
          currentValue={mockData.currentValue}
          minimalGoal={mockData.minimumGoal}
          goal={mockData.goalValue}
        />
      </GaugeBox>

      <MetricsAndDateContainer>
        <MetricContainer>
          <Text size="small" color="gray">
            {subtitleWeek}
          </Text>
          <ProgressBar label="Ritmo" value={rithmPercentage} data={rithmValue} color={rithmColor} />
          <ProgressBar label="Meta" value={weeklyGoalPercentage} data={weeklyGoalValue} color={defaultColor} />
        </MetricContainer>

        <MetricContainer>
          <Text size="small" color="gray">
            {subtitleGeral}
          </Text>
          <ProgressBar label="Projeção" value={projectionPercentage} data={projectionValue} color={projectionColor} />
          <ProgressBar label="Meta" value={goalPercentage} data={goalValue} color={defaultColor} />
        </MetricContainer>
        <WrapperData>
          <Line />
          <Text size="medium" bold>
            Datas (ritmo e projeção)
          </Text>
          <DateContainer>
            <div className="date-box-input">
              <Text size="small">Data Inicial</Text>
              <ControlledComponent
                label="Data Inicial"
                onChange={(e: any) => handleDataChange(e, 'initial_date')}
                defaultValue={data.initial_date}
                error={error && errorMessage.includes('inicial')}
                helperText={errorMessage}
              />
            </div>
            <div className="date-box-input">
              <Text size="small">Data Final</Text>
              <ControlledComponent
                label="Data Final"
                onChange={(e: any) => handleDataChange(e, 'final_date')}
                defaultValue={data.final_date}
                error={error && errorMessage.includes('final')}
                helperText={errorMessage}
              />
            </div>
          </DateContainer>
        </WrapperData>
      </MetricsAndDateContainer>
    </VotingDataContainer>
  )
}

export default VotingDataComponent
