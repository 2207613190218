import { api } from 'services/apiService'
import { toast } from 'react-toastify'
import {
  SwotThemeListResponse,
  AcceptWizardParams,
  SelectedSwotThemeListResponse,
  CreateSwotThemePayload,
  CreatedSwotThemeResponse,
  GetSwotWizardResponse,
  PayloadPostTheme,
} from '../types/SWOT_DIAGNOSIS_TYPES'
import { MOCK_CREATE_SWOT_THEME_PAYLOAD, MOCK_CREATED_SWOT_THEME_RESPONSE } from '../MOCK/swotMock'
import simulateRequest from "../../../utils/simulateRequest";

interface ErrorMessage {
  message: string
}

type ResponseGetWizards = GetSwotWizardResponse | ErrorMessage
type ResponseThemeList = SwotThemeListResponse | ErrorMessage
type ResponsePersonThemes = SelectedSwotThemeListResponse | ErrorMessage
type ResponseAcceptWizard = any
type ResponseCreateSwotTheme = CreatedSwotThemeResponse | ErrorMessage

// Serviço para obter os wizards
export const getWizards = async (): Promise<ResponseGetWizards> => {
  try {
    /*  const { data } = await simulateRequest(MOCK_SWOT_WIZARDS) */
    const { data } = await api.get(`/electoral-intelligence/swot-diagnosis-wizards/`)

    if (data) {
      return data
    }

    return { message: 'Falha no teste.' }
  } catch (error) {
    return { message: 'Não foi possível buscar dados cadastrados.' }
  }
}

// Serviço para obter a lista de temas
export const getThemes = async (): Promise<ResponseThemeList> => {
  try {
    /*  const { data } = await simulateRequest(MOCK_SWOT_THEME_LIST_RESPONSE) */
    const { data } = await api.get(`/electoral-intelligence/swot-themes/`)

    if (data) {
      const formatterData = {
        ...data,
        results: data.results?.map((item: any) => ({
          ...item,
          topic_name: translateIdentificationToName(item.topic_name),
        })),
      }
      return formatterData
    }

    return { message: 'Não foi possível buscar dados cadastrados.' }
  } catch (error) {
    return { message: 'Não foi possível buscar dados cadastrados.' }
  }
}

// Serviço para obter os temas de uma pessoa
export const getPersonThemes = async (): Promise<ResponsePersonThemes> => {
  try {
    /*  const { data } = await simulateRequest(MOCK_SWOT_SELECTION_RESPONSE) */
    const { data } = await api.get(`/electoral-intelligence/person-swot-diagnosis-themes/`)

    const formatterData = {
      ...data,
      results: data.results?.map((item: any) => ({
        ...item,
        topic_name: translateIdentificationToName(item.topic_name),
      })),
    }
    return formatterData
  } catch (error) {
    return { message: 'Não foi possível buscar dados cadastrados.' }
  }
}

// Serviço para aceitar um wizard
export const acceptWizard = async ({ wizard_id, status, topic }: AcceptWizardParams): Promise<ResponseAcceptWizard> => {
  try {
    /* const { data } = await simulateRequest(MOCK_ACCEPT_WIZARD_PARAMS) */
    const { data } = await api.put(`/electoral-intelligence/swot-diagnosis-wizards/${wizard_id}/`, {
      status,
      topic,
    })

    return data
  } catch (error) {
    return { message: 'Não foi possível buscar dados cadastrados.' }
  }
}

// Serviço para criar um tema SWOT
export const createSwotTheme = async (payload: CreateSwotThemePayload): Promise<ResponseCreateSwotTheme> => {
  try {
    const { data } = await simulateRequest(MOCK_CREATE_SWOT_THEME_PAYLOAD)
    return MOCK_CREATED_SWOT_THEME_RESPONSE
  } catch (error) {
    return { message: 'Não foi possível criar o tema SWOT.' }
  }
}

type TopicMapping = {
  [key: string]: string
}

type valueMapping = {
  [key: string]: boolean
}

const topicMapping: TopicMapping = {
  strengths: 'Forças (S)',
  weaknesses: 'Fraqueza (W)',
  opportunities: 'Oportunidades (O)',
  threats: 'Ameaças (T)',
}

export function translateIdentificationToName(identification: string): string {
  return topicMapping[identification.toLowerCase()] || identification
}

export function translateValue(identification: string): boolean {
  const topicValueMapping: valueMapping = {
    strengths: true,
    weaknesses: false,
    opportunities: true,
    threats: false,
  }
  return topicValueMapping[identification.toLowerCase()]
}

export const sendTheme = async ({ theme_data }: { theme_data: PayloadPostTheme }): Promise<any> => {
  try {
    const { data } = await api.post(`/electoral-intelligence/person-swot-diagnosis-themes/`, {
      ...theme_data,
    })

    return data
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}

export const updateListener = async ({
  theme_id,
  is_listening,
}: {
  theme_id: number
  is_listening: boolean
}): Promise<any> => {
  try {
    const { data } = await api.put(`/electoral-intelligence/person-swot-diagnosis-themes/${theme_id}/`, {
      is_listening,
    })

    return data
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}

export const deleteTheme = async (id: number | string) => {
  try {
    const url = `/electoral-intelligence/person-swot-diagnosis-themes/${id}/`
    const { data } = await api.delete(url)

    toast.success('Tema removido com sucesso.')
  } catch (e) {
    console.error('Erro na requisição:', e)
    toast.error('Falha ao remover o tema, tente novamente.')
  }
}
