import styled, { css } from 'styled-components'

export const Wrapper = styled.main`
  width: 100%;
  background: #161717;
  border-radius: 12px;
  padding: 16px;
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`

export const NameContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 3;
  width: 40%;
  p {
    color: #e9e9f0;
    font-weight: 500;
    font-size: 16px;
  }
`

export const BadgeStatus = styled.div<{ status: string }>`
  ${({ status }) => css`
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background: ${status === 'accepted' ? '#00E23F' : '#FF0000'};
  `}
`

export const DefaultText = styled.span`
  color: #e9e9f0;
  font-size: 16px;
  font-weight: 300;
  text-align: center;

`

export const ButtonActions = styled.div`
  color: #ffc000;
  cursor: pointer;
  margin-right: 5%;
`

export const ProfileContainer = styled.div`
  margin-left: -30%;
  padding-right: 10%;
  text-align: center;
  align-content: center;
  padding-left: 10%;
  z-index: 2;
`;
