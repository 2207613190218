import { useEffect, useState } from 'react'
import { Empty } from 'componentsNew/Empty'
import CardDiagnosis from '../CardDiagnosis'
import { getAllPersonThemes } from '../services/getListeners'
import { PersonThemeObject } from '../services/types/candidateTypes'

import * as S from './styles'

export const Allies = ({ activeTab }: any) => {
  const [adversaryList, setAdversaryList] = useState<PersonThemeObject[]>([])

  useEffect(() => {
    fetchMentions()
  }, [])

  const fetchMentions = async () => {
    const list = await getAllPersonThemes()
    if (!('message' in list)) {
      setAdversaryList(list)
    }
  }

  if (activeTab !== 'tab_political_listening_allies') return null

  return (
    <>
      {adversaryList && adversaryList.length > 0 ? (
        <S.Wrapper>
          {adversaryList.map(profile => (
            <CardDiagnosis
              key={profile.id_cand}
              topicIcon={profile?.topic}
              profile_candidate={{
                ...profile,
                candidate_id: profile?.id_cand,
                candidate_name: profile?.cand_name,
                candidate_party: profile?.party,
              }}
            />
          ))}
        </S.Wrapper>
      ) : (
        <Empty description="Nenhum dado para ser retornado" />
      )}
    </>
  )
}
