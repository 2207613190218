import React, { createContext, useState, useContext, ReactNode } from 'react'

interface HorizontalMenuContextProps {
  activeHorizontalMenu: string
  setActiveHorizontalMenu: (submenu: string) => void
}

const HorizontalMenuContext = createContext<HorizontalMenuContextProps | undefined>(undefined)

export const useHorizontalSubmenu = () => {
  const context = useContext(HorizontalMenuContext)
  if (!context) {
    throw new Error('useHorizontalSubmenu must be used within a HorizontalSubmenuProvider')
  }
  return context
}

interface HorizontalSubmenuProviderProps {
  children: ReactNode
}

export const HorizontalSubmenuProvider: React.FC<HorizontalSubmenuProviderProps> = ({ children }) => {
  const [activeHorizontalMenu, setActiveHorizontalMenu] = useState<string>('timeline')

  return (
    <HorizontalMenuContext.Provider value={{ activeHorizontalMenu, setActiveHorizontalMenu }}>
      {children}
    </HorizontalMenuContext.Provider>
  )
}
