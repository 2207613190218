import React from 'react'
import { Tooltip } from '@mui/material'
import { IoChevronUpOutline } from 'react-icons/io5'
import { HiOutlineExternalLink } from 'react-icons/hi'
import { noop } from 'lodash'
import Text from '../../Text'
import { Container, ContainerMaster, Wrapper } from './styles'
import { IChildTypes } from '../../../types/childrenTypes'

interface ICustomCollapseMinimal {
  minimal?: boolean
  title: string
  children?: IChildTypes | any
  onClick?: (event: any, isActive: boolean) => void
  isLink?: boolean
  totals?: { position: string; value: number }[]
  index: any
  isActive: boolean
}

interface ITotal {
  position: string
  value: number
}

const CustomCollapseMinimal = ({
  minimal = false,
  title,
  children,
  onClick = noop,
  isLink = false,
  totals,
  index,
  isActive,
}: ICustomCollapseMinimal) => {
  const toggleIcon = () => {
    onClick(null, !isActive)
  }

  const handleToggle = (e: any) => {
    onClick(e, !isActive)
  }

  const ensurePositions = (totals: ITotal[], positions: string[]) => {
    const totalsMap: { [key: string]: number } = {}

    totals.forEach(total => {
      const normalizedPosition = total.position.toUpperCase()
      totalsMap[normalizedPosition] = total.value
    })

    const normalizedPositions = positions.map(position => position.toUpperCase())

    return normalizedPositions.map(position => ({
      position,
      value: totalsMap[position] ?? 0,
    }))
  }

  const positionsToEnsure = ['Prefeito', 'Vice-Prefeito', 'Vereador']

  const processedTotals = ensurePositions(totals || [], positionsToEnsure)

  return (
    <ContainerMaster minimal={minimal} index={index} onClick={handleToggle}>
      <Container isActive={isActive} onClick={handleToggle} minimal={minimal}>
        <Wrapper isActive={isActive} isLink={isLink}>
          <div className="card-title">
            <Text size="xSmall" bold>
              {title}
            </Text>
          </div>
          <div className="card-text">
            {processedTotals?.map((total, index) => (
              <div className="card-items" key={index}>
                <Tooltip title={total.position} placement="left-start" arrow>
                  <span>
                    <Text size="small">{total.value || 0}</Text>
                  </span>
                </Tooltip>
              </div>
            ))}
          </div>
          {isLink ? (
            <HiOutlineExternalLink onClick={e => onClick(e, isActive)} />
          ) : (
            <IoChevronUpOutline
              onClick={e => {
                toggleIcon()
                onClick(e, isActive)
              }}
            />
          )}
        </Wrapper>
      </Container>
      {isActive && <div className="contante">{children}</div>}
    </ContainerMaster>
  )
}

export default CustomCollapseMinimal
