import React from 'react'
import Chart from 'react-apexcharts'
import _merge from 'lodash/merge'

interface IApexChartAreaProps {
  options: any
  series: {
    name: string
    data: number[]
  }[]
  xAxisTickAmount?: number
}

interface XAxisOptions {
  labels?: {
    formatter?: (value: any, timestamp?: number) => string
  }
}

const options: XAxisOptions = {
  labels: {
    formatter(value, timestamp) {
      const date = new Date(timestamp || value)
      const day = date.getDate()
      const monthIndex = date.getMonth()
      const monthNames: string[] = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      return `${day}/${monthNames[monthIndex]}`
    },
  },
}

const xAxisOptions: XAxisOptions = options

const defaultOptions: IApexChartAreaProps['options'] = {
  chart: {
    id: 'default-chart-id',
    type: 'area',
    height: '100%',
    width: '100%',
    toolbar: {
      show: false,
    },
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true,
    },
  },
  stroke: {
    curve: 'smooth',
  },
  tooltip: {
    theme: 'dark',
    style: {
      fontFamily: 'Roboto',
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    labels: {
      colors: '#FFFFFF',
    },
  },
  grid: {
    show: false,
  },
  xaxis: {
    datetimeFormatter: {
      year: 'yyyy',
      month: "MMM 'yy",
      day: 'dd MMM',
      hour: 'HH:mm',
    },
    type: 'category',
    categories: [], // Adicione aqui as suas categorias de dados
    tickAmount: 4,
    labels: {
      rotate: 0,
      formatter: (value: any, timestamp: any) => {
        const date = new Date(value || timestamp)
        const day = date.getDate()
        const monthIndex = date.getMonth()
        const monthNames: string[] = [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ]
        return `${day}/${monthNames[monthIndex]}`
      },
      style: {
        colors: '#FFFFFF',
      },
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
}

const AreaChart: React.FC<IApexChartAreaProps> = ({ series, options, xAxisTickAmount }) => {
  const xAxisTickAmountValue = xAxisTickAmount !== undefined ? xAxisTickAmount : defaultOptions.xaxis.tickAmount

  const customOptions = {
    ...defaultOptions,
    xaxis: {
      ...defaultOptions.xaxis,
      tickAmount: xAxisTickAmountValue,
    },
  }

  const mergedOptions = _merge({}, customOptions, options)

  return <Chart options={mergedOptions} series={series} type="area" height="100%" width="100%" />
}

export default AreaChart
