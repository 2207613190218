import React from "react";
import Text from "componentsNew/Text";
import { CardFilterMentions, ContainerPoliticalListening } from "./styles";
import { serviceColors } from "../../../utils/utilityFunctions";
import { useFilterGlobal } from "../../../hooks/useFilterGlobal";

const CardFilterMenssoens = ({ item, filterMentions, handleFilter }: any) => {
  const isActive = filterMentions === "all" || filterMentions === item?.slug;
  return (
    <CardFilterMentions
      color={item?.color}
      isActive={isActive}
      onClick={() => handleFilter(item?.slug)}
    >
      <div className="divider" />
      <div className="line">
        <Text size="xSmall">{item?.slug}</Text>
      </div>
      <img src={item?.icon} alt="item?.slug" />
    </CardFilterMentions>
  );
};
const FilterPoliticalListening = () => {
  const { filterMentions, setFilterMentions } = useFilterGlobal();

  const handleFilter = (filter: string) => {
    setFilterMentions((prev: any) => (prev === filter ? "all" : filter));
  };

  return (
    <ContainerPoliticalListening>
      <Text size="medium" bold color="yellow">
        Raio-X do governo
      </Text>
      {serviceColors?.map((item, index) => {
        return (
          <CardFilterMenssoens
            key={index}
            item={item}
            filterMentions={filterMentions}
            handleFilter={handleFilter}
          />
        );
      })}
    </ContainerPoliticalListening>
  );
};

export default FilterPoliticalListening;
