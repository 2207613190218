import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: stretch;
  background-color: transparent;
`;

export const ChartContainer = styled.div`
  width: 80%;
  background-color: transparent;
  margin: 24px 0;
`;

export const ChartContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: transparent;
`;

export const SpinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 220px;
`;

export const AdversaryContainer = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  background-color: transparent;
`;

export const AdversaryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: transparent;
`;

export const TotalsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 -20px 0;
  width: 40%;
  gap: 4px;
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: -20px 0 0 0;
  width: 40%;

  img {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 24px;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #ff0000;
  font-family: Roboto;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  padding: 16px;
`;
