import { useEffect, useState } from "react";
import { useUser } from "global/UserContext";

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const useFormattedUserData = () => {
  const { user } = useUser();
  const [formattedUserData, setFormattedUserData] = useState({
    name: "",
    surname: "",
    cpf: "",
    birthDate: "",
  });

  useEffect(() => {
    if (user?.person) {
      setFormattedUserData({
        name: user.person.user?.first_name,
        surname: user.person.user?.last_name,
        cpf: user.person.social_security_number,
        birthDate: user.person.date_of_birth
          ? formatDate(user.person.date_of_birth)
          : "",
      });
    }
  }, [user]);

  return formattedUserData;
};

export default useFormattedUserData;
