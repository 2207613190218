import { useForm, UseFormProps } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { validationSchema } from "../utils/validationSchema";

const useFormValidation = (options?: UseFormProps) => {
  return useForm({
    resolver: zodResolver(validationSchema),
    ...options,
  });
};

export default useFormValidation;
