export type TabContextKeys = keyof typeof mapActiveTabContext;

export const mapActiveTabContext = {
  tab_election_diagnosis_result: {
    title: "Resultados das Eleições",
    second_level_title: "Diagnóstico Eleições",
    third_level_title: "Resultados das Eleições",
  },
  tab_election_diagnosis_evolution_of_applications: {
    title: "Evolução de Candidaturas",
    second_level_title: "Diagnóstico Eleições",
    third_level_title: "Evolução de Candidaturas",
  },
  tab_election_diagnosis_candidates_profile: {
    title: "Perfil de Candidatos",
    second_level_title: "Diagnóstico Eleições",
    third_level_title: "Perfil de Candidatos",
  },
  tab_election_diagnosis_x_ray_campaigns: {
    title: "Raio-X Campanhas",
    second_level_title: "Diagnóstico Eleições",
    third_level_title: "Raio-X Campanhas",
  },
  tab_election_diagnosis_x_ray_donations: {
    title: "Raio-X Doações",
    second_level_title: "Diagnóstico Eleições",
    third_level_title: "Raio-X Doações",
  },
  tab_election_diagnosis_historical_comparison: {
    title: "Comparação Histórica",
    second_level_title: "Histórico",
    third_level_title: "Comparação",
  },
  tab_election_diagnosis_campaigns_dashboard: {
    title: "Painel de Campanhas",
    second_level_title: "Diagnóstico Eleições",
    third_level_title: "Painel de Campanhas",
  },
  tab_voter_diagnosis_profile: {
    title: "Perfil de Eleitores",
    second_level_title: "Diagnóstico Eleitor",
    third_level_title: "Perfil de Eleitores",
  },
  tab_voter_diagnosis_city_indicators: {
    title: "Indicadores de Cidades",
    second_level_title: "Diagnóstico Eleitor",
    third_level_title: "Indicadores de Cidades",
  },
  tab_voter_diagnosis_x_ray_votes: {
    title: "Raio-X Votos",
    second_level_title: "Diagnóstico Eleitor",
    third_level_title: "Raio-X Votos",
  },

  tab_politica_vox_party: {
    title: "Politica Vox | Partido",
    second_level_title: "Politica Vox",
    third_level_title: "Partido",
  },
  tab_politica_vox_search: {
    title: "Politica Vox | Pesquisa",
    second_level_title: "Politica Vox",
    third_level_title: "Pesquisa",
  },
};
