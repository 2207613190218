import React, { createContext, useContext, useState, ReactNode } from "react";

interface CandidateCardContext {
  isVisible: boolean;
  toggleVisibility: () => void;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CandidateCardContext = createContext<CandidateCardContext | undefined>(
  undefined,
);

export const useCandidateCard = () => {
  const context = useContext(CandidateCardContext);
  if (!context) {
    throw new Error(
      "useCandidateCard must be used within a CandidateCardProvider",
    );
  }
  return context;
};

export const CandidateCardProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <CandidateCardContext.Provider
      value={{ isVisible, toggleVisibility, setIsVisible }}
    >
      {children}
    </CandidateCardContext.Provider>
  );
};
