import { StepProvider } from 'componentsNew/OnboardingComponents/Hooks/useStep'
import ParticlesComponent from 'componentsNew/OnboardingComponents/Particles'
import { OnboardingSteps } from 'componentsNew/OnboardingComponents/Steps'
import logo from '../../images/logo.png'

import * as S from './styles'

export const OnboardingPage = () => {
  return (
    <StepProvider>
      <S.Wrapper>
        <S.ContentLogo>
          <S.ImageLogo src={logo} alt="Logo Candidattus" />
        </S.ContentLogo>
        <ParticlesComponent />
        <OnboardingSteps />
      </S.Wrapper>
    </StepProvider>
  )
}
