export const buildUrl = (
  isDiagnosis: boolean,
  filters: any,
  payloadData: any,
): string => {
  let url = "/copilot/v1/chat/chat_interact?";

  if (isDiagnosis) {
    if (filters.year?.length) {
      url += `&aa_eleicao=${filters.year[0]}`;
    }
    if (filters.round?.length) {
      const roundNumbers = filters.round.map((round: string) =>
        round.includes("1") ? 1 : 2,
      );
      url += `&nr_turno=${roundNumbers[0]}`;
    }
    if (filters.state?.length) {
      url += `&sg_uf=${filters.state[0]}`;
    }
    if (filters.city?.length) {
      url += `&nm_municipio=${filters.city[0]}`;
    }
    if (filters.neighborhood?.length && filters.neighborhood?.length < 10) {
      url += `&nm_bairro=${filters.neighborhood.join(",")}`;
    }
    if (filters.party?.length) {
      url += `&sg_partido=${filters.party.join(",")}`;
    }
    if (filters.position?.length) {
      url += `&ds_cargo=${filters.position[0]}`;
    }
    if (filters.candidate?.length) {
      url += `&nm_votavel=${filters.candidate.join(",")}`;
    }
  } else {
    if (payloadData?.candidate?.id) {
      url += `&id_cand=${payloadData.candidate.id}`;
    }
    if (payloadData?.sentiment && payloadData.sentiment !== "all") {
      url += `&sentiment=${payloadData.sentiment}`;
    }
    if (payloadData?.rangeDate?.initial_date) {
      url += `&date_start=${payloadData.rangeDate.initial_date}`;
    }
    if (payloadData?.rangeDate?.end_date) {
      url += `&date_end=${payloadData.rangeDate.end_date}`;
    }
    if (
      Array.isArray(payloadData?.subtopics) &&
      payloadData.subtopics.length > 0
    ) {
      url += `&topic_id=${payloadData.subtopics.join(",")}`;
    }
    if (payloadData?.topic) {
      url += `&parent_name=${payloadData.topic}`;
    }
    if (
      Array.isArray(payloadData?.includedWords) &&
      payloadData.includedWords.length > 0
    ) {
      url += `&positive_words=${payloadData.includedWords.join(",")}`;
    }
    if (
      Array.isArray(payloadData?.excludedWords) &&
      payloadData.excludedWords.length > 0
    ) {
      url += `&negative_words=${payloadData.excludedWords.join(",")}`;
    }
  }

  return url;
};
