import React, { useEffect, useState } from 'react'
import { Container, InputNumber, MarkColor } from './styles'
import Text from '../Text'

interface InputRangeColorProps {
  color: 'white' | 'green' | 'yellow'
  title: string
  slug: string
  value: any
  handleChange: (value: any, key: any) => void
}

const InputTextColor: React.FC<InputRangeColorProps> = ({ color, title, slug, value, handleChange }) => {
  const [dataValue, setValue] = useState(value)
  const colorMap: { [key: string]: string } = {
    white: '#FFFFFF',
    green: '#00E23F',
    yellow: '#FFC000',
  }

  const sliderColor = colorMap[color]

  const formatNumber = (inputValue: string) => {
    if (!inputValue) return null
    const numericValue = inputValue.replace(/[^\d,]/g, '')
    const valueForNumber = numericValue.replace(',', '.')
    const numberValue = Number(valueForNumber)

    handleChange(numberValue, slug)

    return numberValue
  }

  useEffect(() => {
    setValue(value)
  }, [value])

  return (
    <Container>
      <MarkColor color={sliderColor} />
      <div className="text">
        <Text size="xSmall">{title}</Text>
      </div>
      <InputNumber
        type="text"
        value={dataValue}
        defaultValue={dataValue}
        onChange={(e: any) => setValue(formatNumber(e.target.value))}
      />
    </Container>
  )
}

export default InputTextColor
