import styled, { css } from "styled-components";

const normalizePositionPercentage = (position: number, maxProgress: number) => {
  const newVal = (position - maxProgress) * -1;
  const normalizedPosition = (newVal / maxProgress) * 100;
  return Math.max(0, Math.min(100, normalizedPosition));
};

export const Wrapper = styled.main<{
  width?: `${number}px` | `${number}%`;
  isCockpit?: boolean;
}>`
  width: ${(props) => props.width};
  height: 164px;
  min-width: 600px;
  background: ${(props) => (props.isCockpit ? "#0A0A0A" : "#161717")};
  padding: 16px;
  padding-right: 18px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 19px;

  p {
    margin: 0;
  }
`;

export const HeadingProgress = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeadingText = styled.h4`
  margin: 0;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;

  span {
    font-weight: 900;
  }
`;

export const labelHeading = styled.p<{ color?: string | null }>`
  margin: 0;
  color: ${(props) => props.color || "#e9e9f0"};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;

  span {
    font-weight: 800;
  }
`;

export const ProgressBarWrapper = styled.div`
  width: 95%;
  height: 30px;
  border-radius: 4px;
  background-color: #1d1d1d;
  position: relative;
  margin-top: 30px;
`;

export const ProgressIndicator = styled.div<{
  progress: number;
  color: string;
  maxPercent: number;
}>`
  ${({ progress, color, maxPercent }) => css`
    height: 100%;
    border-radius: 2px;
    width: ${normalizePositionPercentage(progress - 2.5, maxPercent)}%;
    background-color: ${color};
    transition: width 0.5s ease-in-out;
    max-width: 100%;
  `}
`;

export const PointerMarker = styled.div<{
  position: number;
  color: string;
  top: boolean;
  maxPercent: number;
}>`
  ${({ position, color, top, maxPercent }) => css`
    position: absolute;
    top: ${top ? "35px" : "-12px"};
    left: ${normalizePositionPercentage(position + 2.5, maxPercent)}%;
    transform: ${!top && "rotate(180deg)"};
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 6px solid ${color};
  `}
`;

export const PointerLabel = styled.p<{
  position: number;
  top: boolean;
  maxPercent: number;
}>`
  ${({ position, top, maxPercent }) => css`
    position: absolute;
    top: ${top ? "-27px" : "35px"};
    color: white;
    left: ${normalizePositionPercentage(position + 7, maxPercent)}%;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #e9e9f0;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 43px;
    p {
      margin: 0;
    }
  `}
`;
