import Button from 'componentsNew/Button'
import InternalButton from 'componentsNew/InternalButton'
import { InputFilter } from 'componentsNew/PoliticalVox/components/CustomInput'
import { useFilterVox } from 'hooks/useFilterVox'

import { useState } from 'react'
import { IoCheckmark, IoCloseOutline } from 'react-icons/io5'

import * as S from './styles'

export const FilterTopics = () => {
  const { changeIncludedTopics, includedExcludedFilter, handleFilters } = useFilterVox()
  const [selectedWord, setSelectedWord] = useState('')
  const includedWord = includedExcludedFilter.filters.included_topics || []
  const excludedWord = includedExcludedFilter.filters.excluded_topics || []

  const changeIncludedWord = () => {
    const existingTag = includedWord.filter((tag: any) => tag === selectedWord)
    if (existingTag.length > 0) {
      const newList = includedWord.filter((tag: any) => tag !== selectedWord)
      changeIncludedTopics('included_topics', newList)
      handleFilters('includedWords', newList)
    } else {
      const newList = [...includedWord, selectedWord] as any
      changeIncludedTopics('included_topics', newList)
      handleFilters('includedWords', newList)
    }

    setSelectedWord('')
  }

  const changeExcludeTopic = () => {
    const existingTag = excludedWord.filter((tag: any) => tag === selectedWord)
    if (existingTag.length > 0) {
      const newList = excludedWord.filter((tag: any) => tag !== selectedWord)

      changeIncludedTopics('excluded_topics', newList)
      handleFilters('excludedWords', newList)
    } else {
      const newList = [...excludedWord, selectedWord] as any

      changeIncludedTopics('excluded_topics', newList)
      handleFilters('excludedWords', newList)
    }

    setSelectedWord('')
  }

  const removeWord = (type: 'exclude' | 'include', word: string) => {
    if (type === 'include') {
      const newWord = includedWord.filter((tag: any) => tag !== word)
      changeIncludedTopics('included_topics', newWord)
      handleFilters('includedWords', newWord)
    } else {
      const newWord = excludedWord.filter((tag: any) => tag !== word)
      handleFilters('excludedWords', newWord)
      changeIncludedTopics('excluded_topics', newWord)
    }
  }

  const handleClearFilter = () => {
    changeIncludedTopics('included_topics', [])
    changeIncludedTopics('excluded_topics', [])
    handleFilters('includedWords', [])
    handleFilters('excludedWords', [])
    setSelectedWord('')
  }

  return (
    <S.Wrapper>
      <S.Content>
        <S.SearchWordBox>
          <S.Heading>Buscar por tópico político</S.Heading>

          <S.SearchContent>
            <InputFilter
              placeholder="Adicionar tópico político"
              width="100%"
              value={selectedWord}
              type="input"
              onChange={(e: any) => setSelectedWord(e.target.value)}
            />

            <S.SearchActions>
              <InternalButton
                background="#FFC000"
                icon={<IoCheckmark size={19} />}
                onClick={changeIncludedWord}
                disabled={selectedWord.length <= 2}
              >
                Incluir
              </InternalButton>
              <InternalButton
                background="#D70708"
                icon={<IoCloseOutline size={19} />}
                onClick={changeExcludeTopic}
                disabled={selectedWord.length <= 2}
              >
                evitar
              </InternalButton>
            </S.SearchActions>
          </S.SearchContent>
        </S.SearchWordBox>

        <S.IncludedTopicBox>
          <S.Heading>Tópicos políticos incluídas</S.Heading>

          <S.WordBox>
            {includedWord.map((item: any) => (
              <InternalButton
                key={item}
                icon={<IoCloseOutline size={19} onClick={() => removeWord('include', item)} />}
                iconPosition="right"
                background="#FFC000"
                variant="outline"
              >
                {item}
              </InternalButton>
            ))}
          </S.WordBox>
        </S.IncludedTopicBox>

        <S.ExcludedTopicBox>
          <S.Heading>Tópicos políticos evitadas</S.Heading>

          <S.WordBox>
            {excludedWord.map((item: any) => (
              <InternalButton
                key={item}
                icon={<IoCloseOutline size={19} onClick={() => removeWord('exclude', item)} />}
                iconPosition="right"
                background="#D70708"
                variant="outline"
              >
                {item}
              </InternalButton>
            ))}
          </S.WordBox>
        </S.ExcludedTopicBox>
      </S.Content>

      <S.ContentActions>
        <Button
          variant="outline"
          onClick={handleClearFilter}
          disabled={includedWord.length === 0 && excludedWord.length === 0}
        >
          Limpar filtro
        </Button>
        {/*  <Button>Aplicar filtro</Button> */}
      </S.ContentActions>
    </S.Wrapper>
  )
}
