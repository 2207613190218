import { useState } from "react";
import { useCandidates } from "hooks/useCandidates";
import { IWordData } from "componentsNew/Wordcloud";
import { apiElectoralIntelligence } from "services/apiService";

const useSocialMentionsWc = () => {
  const [selectedSN, setSelectedSN] = useState("");
  const [wordsPositive, setWordsPositive] = useState<IWordData[]>([]);
  const [wordsNegative, setWordsNegative] = useState<IWordData[]>([]);
  const [wordsDefault, setWordsDefault] = useState<IWordData[]>([]);
  const [isLoadingPositive, setLoadingPositive] = useState(false);
  const [isLoadingNegative, setLoadingNegative] = useState(false);
  const [isLoadingDefault, setLoadingDefault] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [additionalNetworks, setAdditionalNetworks] = useState([]);
  const { selectedCandidate } = useCandidates();
  const cand_id = selectedCandidate?.id_cand;

  const getWordPositive = async () => {
    setLoadingPositive(true);
    const data = await fetchDataWords({
      socialNetwork: selectedSN,
      sentiment: "positive",
      candidate_id: cand_id,
    });
    setWordsPositive(data);
    setLoadingPositive(false);
  };

  const getWordNegative = async () => {
    setLoadingNegative(true);
    const data = await fetchDataWords({
      socialNetwork: selectedSN,
      sentiment: "negative",
      candidate_id: cand_id,
    });
    setWordsNegative(data);
    setLoadingNegative(false);
  };

  const getWordDefault = async ({
    socialNetwork,
    sentiment,
    parent_name,
    topic_name,
    mentions,
    topic_lvl,
    dateStart,
    endDate,
    city,
    candidate_id,
  }: any) => {
    try {
      setLoadingDefault(true);
      const data = await fetchDataWords({
        socialNetwork,
        sentiment,
        parent_name,
        topic_name,
        mentions,
        topic_lvl,
        dateStart,
        endDate,
        city,
        candidate_id,
      });
      setWordsDefault(data);
    } catch (error) {
      console.error("Erro ao buscar dados da API:", error);
    } finally {
      setLoadingDefault(false);
    }
  };
  async function fetchDataWords({
    socialNetwork,
    sentiment,
    parent_name,
    topic_name,
    mentions,
    topic_lvl,
    dateStart,
    endDate,
    city,
    candidate_id,
  }: any) {
    const newSentiment = sentiment ? `&sentiment=${sentiment}` : "";
    const newData: IWordData[] = [];
    const newSocialNetwork = socialNetwork
      ? `&page_type_name=${socialNetwork}`
      : "";
    const newTopic_lvl = topic_lvl ? `&topic_lvl=${topic_lvl}` : "";
    const newMentions = mentions ? `&mentions=${mentions}` : "";
    const dateRange = dateStart ? `&date_start=${dateStart}` : "";
    const endRange = endDate ? `&date_end=${endDate}` : "";
    const newCity = city ? `&cd_city=${city}` : "";
    const newCandId = candidate_id ? `&candidate_id=${candidate_id}` : "";
    const parentName = parent_name ? `&parent_name=${parent_name}` : "";
    const topicName = topic_name ? `&topic_name=${topic_name}` : "";
    try {
      const resp = await apiElectoralIntelligence.get(
        `/wordcloud?${newSentiment}${newSocialNetwork}${newTopic_lvl}${newMentions}${dateRange}${endRange}${newCity}${newCandId}${parentName}${topicName}`,
      );
      if (resp) {
        resp.data.forEach((item: any) => {
          newData.push({ text: item.word, value: item.value });
        });
      }
    } catch (error) {
      console.error("Erro ao buscar dados da API:", error);
    }
    return newData;
  }

  const addNetwork = (network: string) => {
    setSelectedSN(network);
    setAdditionalNetworks(additionalNetworks.filter((n) => n !== network));
    setShowDropdown(false);
  };
  return {
    wordsDefault,
    isLoadingDefault,
    getWordDefault,
    getWordPositive,
    getWordNegative,
    wordsPositive,
    wordsNegative,
    selectedSN,
    setSelectedSN,
    isLoadingPositive,
    isLoadingNegative,
    showDropdown,
    setShowDropdown,
    additionalNetworks,
    addNetwork,
  };
};

export default useSocialMentionsWc;
