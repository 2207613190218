import { Avatar } from 'antd'
import { useEffect, useState } from 'react'
import { FaEquals } from 'react-icons/fa'
import { IoMdAdd, IoMdRemove } from 'react-icons/io'
import { getDisputedElection } from '../services/candidateServices'
import * as S from './styles'

interface ProfileViewerProps {
  avatar?: string
  cand_name: string
  party?: string
  cand_id: string
  topicIcon?: string
}

export const ProfileViewer = ({ cand_id, cand_name, party, avatar, topicIcon }: ProfileViewerProps) => {
  const [disputedElection, setDisputedElection] = useState<number | null>(null)

  useEffect(() => {
    if (cand_id) {
      fetchElectionDisputed()
    }
  }, [])

  const fetchElectionDisputed = async () => {
    const result = await getDisputedElection(cand_id)

    if (!('message' in result)) {
      setDisputedElection(result.disputed_elections)
    }
  }

  return (
    <S.Wrapper>
      <S.AvatarBox>
        <Avatar src={avatar} alt={cand_name} />
      </S.AvatarBox>
      <S.LabelContent>
        <S.Label>
          {cand_name} {party && `| ${party}`} {topicIcon && wizardTopicType(topicIcon)}
        </S.Label>
        <S.Description>Eleições disputadas: {disputedElection}</S.Description>
      </S.LabelContent>
    </S.Wrapper>
  )
}

const wizardTopicType = (topic?: string) => {
  const iconPositive = <IoMdAdd color="#00E23F" size={18} />
  const iconNegative = <IoMdRemove color="#D70708" size={18} />
  if (topic === 'very good') {
    return (
      <>
        {iconPositive}
        {iconPositive}
      </>
    )
  }
  if (topic === 'good') {
    return iconPositive
  }
  if (topic === 'bad') {
    return iconNegative
  }
  if (topic === 'very bad') {
    return (
      <>
        {iconNegative}
        {iconNegative}
      </>
    )
  }
  return <FaEquals size={18} color="#FFC000" />
}
