import React from "react";
import { useCandidates } from "hooks/useCandidates";
import Avatars from "componentsNew";
import * as S from "./styles";

export const AvatarCandidateCard = () => {
  const { selectedCandidate, userDataCandidateSelected } = useCandidates();

  return (
    <S.Container>
      <S.Wrapper>
        <S.AvatarImageBox>
          <Avatars
            img={selectedCandidate?.image_url}
            size={{ width: "100%", height: "100%" }}
          />
        </S.AvatarImageBox>
        {userDataCandidateSelected?.is_pep && <S.Message>PEP</S.Message>}
      </S.Wrapper>
    </S.Container>
  );
};
