import React, { useEffect, useState } from 'react'
import { Tooltip } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { Bar, Container, Wrapper } from './styles'
import Text from '../Text'
import ModalSubMenu from '../ModalSubMenu'
import { useSidBar } from '../../hooks/useSideBar'

const DEFAULT_LOGIN_REDIRECT = 'Portifólio de candidaturas'

interface IButtonSibBar {
  isActive?: boolean
  path: string
  title: string
  icon: React.ReactNode | any
  subMenu: any[]
  isSubMenu?: boolean
  label: string
}

const ButtonSibBar = ({ isActive = false, path, title, icon, subMenu, isSubMenu = false, label }: IButtonSibBar) => {
  const { pathname, state } = useLocation()
  const activeState = state?.defaultActive
  const validateDefaultActive = title === DEFAULT_LOGIN_REDIRECT && activeState
  const [openSubMenu, setOpenSubMenu] = useState(validateDefaultActive)
  const { open, setTitleMenu } = useSidBar()
  const history = useNavigate()

  const isActiveLocation = pathname === path || subMenu?.some((item: any) => item.path === pathname)
  const isActiveLocations = subMenu?.find((item: any) => item.path === pathname)
  console.log('test', activeState, title, label, isActiveLocation, isActiveLocations)

  useEffect(() => {
    if (isActiveLocations || isActiveLocation) {
      setTitleMenu(isActiveLocations?.label || label)
    }
  }, [isActiveLocations, subMenu])

  const handleLinkOrMenu = () => {
    if (isActive) {
      if (subMenu) {
        setOpenSubMenu(!openSubMenu)
      } else {
        setTitleMenu(label)
        history(path)
      }
    }
  }

  const closeSubMenu = () => {
    setOpenSubMenu(false)
  }

  const validation = !isSubMenu && isActiveLocation && open
  const isVisibleTitle = open || isSubMenu
  return (
    <Container open={false} isSelected={isActiveLocation} isActive={isActive} onClick={handleLinkOrMenu}>
      {validation && <Bar />}
      {isActiveLocation && isSubMenu && <Bar />}
      <Tooltip title={!open && title} placement="right-start" arrow>
        <Wrapper>
          <img src={icon} alt="Cockpit" />
          {isVisibleTitle && <Text bold={isActiveLocation}>{title}</Text>}
        </Wrapper>
      </Tooltip>
      {openSubMenu && <ModalSubMenu closeSubMenu={closeSubMenu} subMenu={subMenu} title={title} />}
    </Container>
  )
}

export default ButtonSibBar
