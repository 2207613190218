import { useState } from "react";
import { useFilterGlobal } from "hooks/useFilterGlobal";
import { useFilterVox } from "hooks/useFilterVox";
import { useLocation } from "react-router-dom";
import Filter from "./Filter";
import { FloatButtons } from "./FloatMenu";
import { Container, Divisor } from "./styles";

const Helper = () => {
  const [activeFilter, setActiveFilter] = useState<any | null>(null);
  const [filterOpened, setFilterOpened] = useState(false);
  const [translateXDrawerFilters, setTranslateXDrawerFilters] = useState("0px");
  const location = useLocation();
  const {
    filterRaioX: filterRaioXGlobal,
    filterMentions,
    rangeDate: rangeDateGlobal,
    renderFilter,
  } = useFilterGlobal();
  const { rangeDate, filterRaioX, topicosFilter, subTopic, filtersDefault } =
    useFilterVox();

  const validateRender = (path: string) => {
    if (path.includes("cockpit")) {
      return {
        topic: filterMentions,
        sentiment: filterRaioXGlobal,
        // candidate: renderFilter(),
        rangeDate: {
          end_date: rangeDateGlobal.end_date,
          initial_date: rangeDateGlobal.initial_date,
        },
      };
    }
    if (path.includes("political-intelligence/political-listening")) {
      return {
        topic: filterMentions,
        sentiment: filterRaioXGlobal,
        candidate: renderFilter(),
        rangeDate: {
          end_date: rangeDateGlobal.end_date,
          initial_date: rangeDateGlobal.initial_date,
        },
      };
    }
    if (path.includes("political-vox")) {
      return {
        ...filtersDefault,
        subtopics: topicosFilter?.filters?.subtopics,
        sentiment: filterRaioX,
        rangeDate: {
          end_date: rangeDate.end_date,
          initial_date: rangeDate.initial_date,
        },
      };
    }
    if (path.includes("political-intelligence/political-vox-search")) {
      return {
        ...filtersDefault,
        subtopics: topicosFilter?.filters?.subtopics,
        sentiment: filterRaioX,
        rangeDate: {
          end_date: rangeDate.end_date,
          initial_date: rangeDate.initial_date,
        },
      };
    }

    return null;
  };

  const toggleFilter = () => {
    setFilterOpened((prev) => !prev);
  };

  const currentPath = location.pathname;
  const payloadData = validateRender(currentPath);

  console.log("payloadData", payloadData);

  return (
    <Container>
      <Filter
        onClick={toggleFilter}
        open={filterOpened}
        currentPath={currentPath}
        onActiveFilterChange={setActiveFilter}
        onTranslateXChange={setTranslateXDrawerFilters}
        payloadData={payloadData}
      />
      <Divisor>
        <FloatButtons
          activeFilter={activeFilter}
          data={payloadData}
          // translateXDrawerFilters={translateXDrawerFilters}
        />
      </Divisor>
    </Container>
  );
};

export default Helper;
