import React from 'react'
import wizard_icon from 'icons/wizard_icon.svg'
import { noop } from 'lodash'
import InternalButton from 'componentsNew/InternalButton'
import { MdAdd, MdOutlineRemove } from 'react-icons/md'
import { IoCheckmark, IoClose } from 'react-icons/io5'
import theme from 'styles/theme'
import { Avatar } from 'antd'
import { FaUser } from 'react-icons/fa'

import * as S from './styles'

interface DataDefaultsTypes {
  theme_name: string
  is_positive?: boolean
  topic_name?: string
  name?: string
  profile_name?: string
  positive_name?: string
  negative_name?: string
}

interface CardWizardProps<T = any> {
  avatar?: string | React.ReactNode
  data: T & DataDefaultsTypes
  actionCard?: (data: T & DataDefaultsTypes) => void
  onCardSelect?: (data: T & DataDefaultsTypes) => void
  onRemove?: () => void
  positiveIcon?: boolean
}

export const CardWizard: React.FC<CardWizardProps> = <T,>({
  avatar,
  data,
  actionCard = noop,
  onCardSelect = noop,
  onRemove = noop,
  positiveIcon = true,
}: CardWizardProps<T>) => {
  const isAvatarString =
    typeof avatar === 'string' ? (
      <Avatar src={avatar} alt="Avatar" icon={<FaUser />} />
    ) : (
      <Avatar>{data?.profile_name}</Avatar>
    )

  const handleApprove = () => {
    onCardSelect(data)
    actionCard(data)
  }

  const handleReject = () => {
    onRemove()
  }

  return (
    <S.Wrapper
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{ duration: 0.8 }}
    >
      <S.ProfileContent>
        {!!avatar && <S.AvatarBox>{isAvatarString}</S.AvatarBox>}
        <S.LabelBox>
          <S.WizardIcon src={wizard_icon} alt="wizard icon" />
          <S.Label>{data?.profile_name || data.topic_name}</S.Label>
        </S.LabelBox>
      </S.ProfileContent>

      <S.AttrContent>
        <S.AttrText>
          {data?.name || data.theme_name}
          {positiveIcon ? (
            <>
              {data.is_positive ? <MdAdd color="#00E23F" size={16} /> : <MdOutlineRemove color="#D70708" size={16} />}{' '}
            </>
          ) : null}
        </S.AttrText>
      </S.AttrContent>

      <S.ActionsContent>
        <InternalButton background={theme.COLORS.yellow10} icon={<IoCheckmark />} onClick={handleApprove}>
          aceitar
        </InternalButton>
        <InternalButton
          background={theme.COLORS.red10}
          icon={<IoClose />}
          style={{ color: theme.COLORS.gray10 }}
          onClick={handleReject}
        >
          recusar
        </InternalButton>
      </S.ActionsContent>
    </S.Wrapper>
  )
}
