import { noop } from 'lodash'
import React from 'react'
import { CiShoppingTag } from 'react-icons/ci'
import * as S from './styles'

interface CardTopicProps {
  slug: string
  color?: string
  icon?: string
  onClick?: () => void
  isActive?: boolean
}

export const CardTopic = ({ slug, color, icon, onClick = noop, isActive }: CardTopicProps) => {
  return (
    <S.Wrapper color={color || '#ff0000'} onClick={onClick} active={isActive}>
      <S.Text>{slug}</S.Text>
      {icon ? <S.Icon src={icon} alt={`icone de ${slug}`} /> : <CiShoppingTag color={color} size={18} />}
    </S.Wrapper>
  )
}
