import { useState } from 'react'
import Button from 'componentsNew/Button'
import { InputFilter } from 'componentsNew/PoliticalVox/components/CustomInput'
import { ModalVox } from 'componentsNew/PoliticalVox/components/ModalVox'

import { useUser } from 'global/UserContext'
import { createFilter } from 'componentsNew/PoliticalVox/services/overViewServices'
import { toast } from 'react-toastify'
import { noop } from 'lodash'
import { useFilterVox } from 'hooks/useFilterVox'

import { useOverview } from 'componentsNew/PoliticalVox/hooks/useOverview'
import * as S from './styles'

interface SaveSearchProps {
  open: boolean
  onClose?: () => void
}

export const SaveSearch = ({ open, onClose = noop }: SaveSearchProps) => {
  const { includedExcludedFilter, topicosFilter, filtersDefault } = useFilterVox()
  const { candidateList, handleFilterName, filterName, candidatoFilter } = useOverview()
  const { user } = useUser()
  const [saveLoad, setSaveLoad] = useState(false)

  const handleCreate = async () => {
    setSaveLoad(true)
    const words = includedExcludedFilter.isActive
      ? {
          positive_words: filtersDefault?.includedWords,
          negative_words: filtersDefault?.excludedWords,
        }
      : undefined

    const candidates = candidateList.list?.map((cand: any) => cand?.id)
    const { title, description } = filterName
    const themes = (filtersDefault?.subtopics || []).map((subtopic: any) => subtopic?.id)

    const topics =
      filtersDefault?.topic !== 'Todos' && (topicosFilter?.filters?.topics || []).map((subtopic: any) => subtopic?.id)

    const positive_words = words?.positive_words?.length > 0 ? words?.positive_words.join(',') : null
    const negative_words = words?.negative_words?.length > 0 ? words?.negative_words.join(',') : null
    if (user) {
      const payload = {
        candidates,
        title,
        description,
        themes,
        topics,
        positive_words,
        negative_words,
        is_candidates_active: candidatoFilter.isActive,
        is_topics_active: topicosFilter.isActive,
        is_words_active: includedExcludedFilter.isActive,
        person: user.person.id,
      }

      const listFilter = await createFilter(payload)

      if ('message' in listFilter) {
        toast.error(listFilter.message?.message)
      }

      handleFilterName('title', '')
      handleFilterName('description', '')
      toast.success('Busca salva com sucesso.')
      onClose()
    }

    setSaveLoad(false)
  }

  const Footer = () => {
    return (
      <S.Footer>
        <Button variant="outline" onClick={onClose}>
          Cancelar
        </Button>
        <Button onClick={handleCreate} disabled={saveLoad}>
          Salvar
        </Button>
      </S.Footer>
    )
  }
  return (
    <ModalVox open={open} onCancel={onClose} title="Salvar Busca" actionsBox={<Footer />}>
      <S.Wrapper>
        <S.Form>
          <InputFilter
            placeholder="Titulo da pesquisa"
            width="100%"
            value={filterName.title}
            onChange={(val: any) => handleFilterName('title', val.target.value)}
            required
          />

          <InputFilter
            typeComponent="textarea"
            rows={4}
            placeholder="Descrição"
            maxLength={255}
            value={filterName.description}
            onChange={(val: any) => handleFilterName('description', val.target.value)}
            required
          />
        </S.Form>
      </S.Wrapper>
    </ModalVox>
  )
}
