import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "componentsNew/Card";
import Spin from "componentsNew/Spin";
import Text from "componentsNew/Text";
import CardSponsored from "../../componentsNew/CardSponsored";
import { Container } from "../AllApplications/styles";
import { TransformedPartyData } from "./types/sponsoredApplicationsTypes";
import { getParties, getPartyTotals } from "./services/sponsoredApplications";
import { useCandidates } from "../../hooks/useCandidates";

const SponsoredApplicattions = () => {
  const history = useNavigate();
  const [partiesData, setPartiesData] = useState<TransformedPartyData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalsPositions, setTotalsPositions] = useState<any>();

  const { isLoadedCandidates } = useCandidates();

  const defaultPositions = [
    /* { key: 'presidentes', label: 'PRESIDENTES' },
    { key: 'vice_presidentes', label: 'VICE-PRESIDENTES' },
    { key: 'governadores', label: 'GOVERNADORES' },
    { key: 'vice_governadores', label: 'VICE-GOVERNADORES' },
    { key: 'senadores', label: 'SENADORES' },
    { key: 'dep_federais', label: 'DEPUTADOS FEDERAIS' },
    { key: 'dep_estaduais', label: 'DEPUTADOS ESTADUAIS' },
    { key: 'dep_distritais', label: 'DEPUTADOS DISTRITAIS' },
    { key: 'dep_primeiro_suplente', label: '1º SUPLENTE SENADOR' },
    { key: 'dep_segundo_suplente', label: '2º SUPLENTE SENADOR' }, */
    { key: "prefeitos", label: "Prefeitos" },
    { key: "vices", label: "Vice Prefeitos" },
    { key: "vereadores", label: "Vereadores" },
  ];

  useEffect(() => {
    const fetchParties = async () => {
      setLoading(true);
      try {
        const partiesDataResponse = await getParties();
        const totals = await getPartyTotals();

        console.log("totals", totals);

        if (!("message" in partiesDataResponse)) {
          setPartiesData(partiesDataResponse);
          setTotalsPositions(totals);
        }

        if (!("message" in partiesDataResponse)) {
          const sortedParties = partiesDataResponse.sort((a, b) => {
            if (a.party_acronym < b.party_acronym) return -1;
            if (a.party_acronym > b.party_acronym) return 1;
            return (
              b.totals.reduce(
                (acc, cur) => acc + parseInt(cur.value || "0", 10),
                0,
              ) -
              a.totals.reduce(
                (acc, cur) => acc + parseInt(cur.value || "0", 10),
                0,
              )
            );
          });
          setPartiesData(sortedParties);
          setTotalsPositions(totals);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    fetchParties();
  }, [isLoadedCandidates]);

  const handleCardClick = (party_acronym: string) => {
    history(
      `/dashboard/sponsored-applications/national-panorama/${party_acronym}`,
    );
  };

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  return (
    <Container>
      {/*  {partiesData.length > 0 && (
        <Card>
          <Text className="title-totals-positions" size="medium" bold>
            TOTAL DE CARGOS
          </Text>
          <div className="card-totals-positions">
            {totalsPositions?.presidentes > 0 && (
              <Text className="text-card" size="medium" color="yellow" bold>
                <Text>PRESIDENTES</Text>
                {totalsPositions?.presidentes}
              </Text>
            )}
            {totalsPositions?.vice_presidentes > 0 && (
              <Text className="text-card" size="medium" color="yellow" bold>
                <Text>VICE-PRESIDENTES</Text>
                {totalsPositions?.vice_presidentes}
              </Text>
            )}
            {totalsPositions?.governadores > 0 && (
              <Text className="text-card" size="medium" color="yellow" bold>
                <Text>GOVERNADORES</Text>
                {totalsPositions?.governadores}
              </Text>
            )}
            {totalsPositions?.vice_governadores > 0 && (
              <Text className="text-card" size="medium" color="yellow" bold>
                <Text>VICE-GOVERNADORES</Text>
                {totalsPositions?.vice_governadores}
              </Text>
            )}
            {totalsPositions?.senadores > 0 && (
              <Text className="text-card" size="medium" color="yellow" bold>
                <Text>SENADORES</Text>
                {totalsPositions?.senadores}
              </Text>
            )}
            {totalsPositions?.dep_federais > 0 && (
              <Text className="text-card" size="medium" color="yellow" bold>
                <Text>DEPUTADOS FEDERAIS</Text>
                {totalsPositions?.dep_federais}
              </Text>
            )}
            {totalsPositions?.dep_estaduais > 0 && (
              <Text className="text-card" size="medium" color="yellow" bold>
                <Text>DEPUTADOS ESTADUAIS</Text>
                {totalsPositions?.dep_estaduais}
              </Text>
            )}
            {totalsPositions?.dep_distritais > 0 && (
              <Text className="text-card" size="medium" color="yellow" bold>
                <Text>DEPUTADOS DISTRITAIS</Text>
                {totalsPositions?.dep_distritais}
              </Text>
            )}
            {totalsPositions?.dep_primeiro_suplente > 0 && (
              <Text className="text-card" size="medium" color="yellow" bold>
                <Text>1º SUPLENTE SENADOR</Text>
                {totalsPositions?.dep_primeiro_suplente}
              </Text>
            )}
            {totalsPositions?.dep_segundo_suplente > 0 && (
              <Text className="text-card" size="medium" color="yellow" bold>
                <Text>2º SUPLENTE SENADOR</Text>
                {totalsPositions?.dep_segundo_suplente}
              </Text>
            )}
            {totalsPositions?.prefeitos > 0 && (
              <Text className="text-card" size="medium" color="yellow" bold>
                <Text>PREFEITOS</Text>
                {totalsPositions?.prefeitos}
              </Text>
            )}
            {totalsPositions?.vices > 0 && (
              <Text className="text-card" size="medium" color="yellow" bold>
                <Text>VICE-PREFEITOS</Text>
                {totalsPositions?.vices}
              </Text>
            )}
            {totalsPositions?.vereadores > 0 && (
              <Text className="text-card" size="medium" color="yellow" bold>
                <Text>VEREADORES</Text>
                {totalsPositions?.vereadores}
              </Text>
            )}
          </div>
        </Card>
      )} */}

      {partiesData.length > 0 && (
        <Card>
          <Text className="title-totals-positions" size="medium" bold>
            TOTAL DE CARGOS
          </Text>
          <div className="card-totals-positions">
            {defaultPositions.map(({ key, label }) => (
              <Text
                className="text-card"
                size="medium"
                color="yellow"
                bold
                key={key}
              >
                <Text bold>{label}</Text>
                {totalsPositions[key] > 0 ? totalsPositions[key] : 0}
              </Text>
            ))}
          </div>
        </Card>
      )}

      {loading ? (
        <Spin fullscreen />
      ) : (
        partiesData.length > 0 &&
        partiesData.map((dataParty) => (
          <CardSponsored
            key={dataParty.party_acronym}
            id={dataParty.party_acronym}
            icon={dataParty.icon}
            title={dataParty.party_acronym}
            valueCandidates={dataParty.totals}
            onClick={() => handleCardClick(dataParty.party_acronym)}
          />
        ))
      )}
    </Container>
  );
};

export default SponsoredApplicattions;
