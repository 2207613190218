import styled, { css } from 'styled-components'

interface CircleProps {
  size: number
}

export const Wrapper = styled.main`
  width: 100%;
`

export const HeaderBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  gap: 64px;
  margin-bottom: 9px;
`
export const Heading = styled.div`
  flex: 0.3;
  font-size: 11px;
  font-weight: 500;
  color: #e9e9f0;
`

export const WrapperIconsHeader = styled.div`
  flex: 0.7;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const IconHeaderBox = styled.div`
  width: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  img {
    object-fit: cover;
    width: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

export const IconWithLabel = styled.div`
  display: flex;
  text-align: center;
  color: #e9e9f0;
  width: 32px;

  flex-direction: column;
  align-items: center;
`

export const RowMentions = styled.div<{ borderColor: string; isActive: boolean }>`
  ${({ borderColor }) => css`
    cursor: pointer;
    opacity: ${(props: any) => (props.isActive ? '1' : '0.3')};
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1d1d1d;
    border-left: 4px solid ${borderColor};
    padding: 3px 12px;
    height: 34px;
    gap: 60px;
    &:nth-child(even) {
      background-color: #111111;
    }

    &:hover {
      opacity: 0.6;
    }

    &:last-child() {
      display: none;
    }
  `}
`

export const WrapperText = styled.div`
  flex: 0.3;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
  color: #e9e9f0;
`

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  img,
  svg {
    width: 14px;
    height: 14px;
  }
`
export const TextRowTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #e9e9f0;
  width: 100px;
`
export const TextRow = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #e9e9f0;
  width: 0px;
`

export const WrapperCircle = styled.div`
  flex: 0.7;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CircleBox = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Circle = styled.span<CircleProps>`
  /*   width: ${props => (props.size !== 0 ? `${props.size}%` : '0')};
  height: ${props => (props.size !== 0 ? `${props.size}%` : '0')};
  max-width: 28px;
  max-height: 28px;
  border-radius: 50%;
  background: #ffc000;
  opacity: ${props => (props.size !== 0 ? '1' : '0')}; */
  width: ${props => Math.max(4, (props.size / 100) * 28)}px;
  height: ${props => Math.max(4, (props.size / 100) * 28)}px;
  max-width: 28px;
  max-height: 28px;
  border-radius: 50%;
  background: #ffc000;
  opacity: ${props => (props.size !== 0 ? '1' : '0')};
`

export const Icon = styled.img`
  width: 100%;
  object-fit: cover;
`
