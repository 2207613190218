import React, { createContext, useContext, useState, ReactNode } from 'react'
import { ListByTopicTypes, CompetitiveWizard } from '../../types/COMPETITIVE_TYPES'
import { MOCK_Competitive_SELECTION_POSITIVE } from '../../MOCK/competitiveMock'

interface CompetitiveContextProps {
  topicList: ListByTopicTypes[]

  updateTopicList: (list: ListByTopicTypes[]) => void

  updateSelectList: (topic_name: string, data: any) => void
  visibleWizard: CompetitiveWizard[] | null

  updateVisibleWizard: (wizards: CompetitiveWizard[] | null) => void

  handlePositiveCardClick: (clickedId: number) => void
  updateListByTopic: (wizard: any) => void
}

const CompetitiveContext = createContext<CompetitiveContextProps | undefined>(undefined)

interface CompetitiveProviderProps {
  children: ReactNode
}

export const CompetitiveProvider: React.FC<CompetitiveProviderProps> = ({ children }) => {
  const [visibleWizard, setVisibleWizards] = useState<CompetitiveWizard[] | null>(null)
  const [topicList, setTopicList] = useState<ListByTopicTypes[]>(MOCK_Competitive_SELECTION_POSITIVE)

  const updateTopicList = (list: ListByTopicTypes[]) => {
    setTopicList(list)
  }

  const updateListByTopic = (wizard: any) => {
    setTopicList(prevTopics => {
      const newTopics = [...prevTopics]
      const topicIndex = newTopics.findIndex(topic => topic.topic === wizard.topic_name)
      console.log('pass 2', newTopics, wizard)
      if (topicIndex !== -1) {
        const index = newTopics[topicIndex].theme_list.findIndex(item => item === undefined)
        if (index !== -1) {
          newTopics[topicIndex].theme_list[index] = { ...wizard, label: wizard?.theme_name, value: wizard?.id } // Usando o ID do wizard como valor
        }
      }
      return newTopics
    })
  }

  const updateSelectList = (topic_name: string, data: any) => {
    const newValues = topicList.map(topic => {
      if (topic.topic_name === topic_name) {
        return {
          ...topic,
          selection_list: topic.selection_list.map(option => {
            if (option.name === data?.theme_name) {
              return { ...option, active: true, name: data?.theme_name, is_recommended: true }
            }
            return option
          }),
        }
      }
      return topic
    })

    setTopicList(newValues)
  }

  const updateVisibleWizard = (wizards: CompetitiveWizard[] | null) => {
    setVisibleWizards(wizards)
  }

  const handlePositiveCardClick = (clickedId: number) => {
    if (visibleWizard && visibleWizard.length > 0) {
      const updatedWizard = visibleWizard.filter(wizard => wizard.id !== clickedId)
      updateVisibleWizard(updatedWizard.length > 0 ? updatedWizard : null)
    }
  }

  return (
    <CompetitiveContext.Provider
      value={{
        updateListByTopic,
        topicList,
        visibleWizard,
        updateTopicList,
        updateSelectList,
        updateVisibleWizard,
        handlePositiveCardClick,
      }}
    >
      {children}
    </CompetitiveContext.Provider>
  )
}

export const useCompetitive = () => {
  const context = useContext(CompetitiveContext)
  if (!context) {
    throw new Error('useCompetitive deve ser usado dentro de um CompetitiveProvider')
  }
  return context
}
