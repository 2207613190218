import { styled } from 'styled-components'

export const Container = styled.div<{ height?: string }>`
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: #161717;
  height: ${({ height }) => height || '570px'};

  .leaflet-control-attribution {
    display: none;
  }

  .spin-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 600px;
  }
`

export const MapContainer = styled.div<{ height?: string }>`
  /* height: ${({ height }) => height || '570px'}; */
  height: 90%;
  width: calc(100% - 1rem);
  margin: 0.5rem;
  background: #161717;
  overflow: hidden;
  filter: invert(1);
`
