import { Input, InputProps } from 'antd'
import styled from 'styled-components'

export const CustomInput = styled(Input)<InputProps>`
  width: 100%;
  height: 35px;

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`

export const SelectionBox = styled.div<{ withIcon?: boolean; width?: string; disabled?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 10px;
  position: relative;
  width: ${({ width }) => width};
  opacity: ${({ disabled }) => disabled && 0.5};
  .ant-select-selection-item {
    margin-left: ${({ withIcon }) => withIcon && '14px'} !important;
  }

  @media screen and (max-width: 650px) {
    margin-bottom: 6px;
    width: 100%;
  }
`

export const SwitchBox = styled.div`
  width: 25px;
`

export const Icon = styled.img`
  position: absolute;
  left: 10px;
`
