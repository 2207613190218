import React, { useEffect, useState } from 'react'
import Spin from 'componentsNew/Spin'
import { useCandidates } from 'hooks/useCandidates'
import Card from '../../../../componentsNew/Card'
import { Container, Wrapper } from './styles'
import TableRowTop from '../TableRowTop'
import iconAdm from '../../../../assets/iconAdministration.svg'
import iconHealth from '../../../../assets/iconHealth.svg'
import iconEducation from '../../../../assets/iconEducation.svg'
import iconPublicSecurity from '../../../../assets/iconPublicMon.svg'
import iconConstruction from '../../../../assets/iconConstruction.svg'
import iconAssSocial from '../../../../assets/iconAssistSocial.svg'
import iconSport from '../../../../assets/iconSportLeisure.svg'
import iconFarm from '../../../../assets/iconFarm.svg'
import iconGlobe from '../../../../assets/iconGlobo.svg'
import iconPubService from '../../../../assets/iconServPublic.svg'
import { apiPredeterminedCandidate } from '../../../../services/apiService'

const TopServices = () => {
  const [loading, setLoading] = useState(true)
  const [byTopic, setByTopic] = useState([])
  const { selectedCandidate } = useCandidates()
  const target_locality = selectedCandidate?.locality || 'BRA.SP.Sao Paulo'
  const iconService = (icon: string) => {
    switch (icon) {
      case 'Administração':
        return iconAdm
      case 'Saúde':
        return iconHealth
      case 'Educação':
        return iconEducation
      case 'Segurança Pública':
        return iconPublicSecurity
      case 'Obras':
        return iconConstruction
      case 'Serviço Público':
        return iconPubService
      case 'Assistência Social':
        return iconAssSocial
      case 'Esporte/Lazer':
        return iconSport
      case 'Fazenda':
        return iconFarm
      case 'Ecossistema Político':
        return iconGlobe
      default:
        return iconAdm
    }
  }

  const colorService = (color: string) => {
    switch (color) {
      case 'Administração':
        return '#6E2F9D'
      case 'Saúde':
        return '#059D4A'
      case 'Educação':
        return '#B28801'
      case 'Segurança Pública':
        return '#650202'
      case 'Obras':
        return '#F00'
      case 'Serviço Público':
        return '#9B008C'
      case 'Assistência Social':
        return '#016DBA'
      case 'Esporte/Lazer':
        return '#813B0C'
      case 'Fazenda':
        return '#223458'
      default:
        return '#223458'
    }
  }

  const getByTopic = async () => {
    setLoading(true)
    try {
      const { data } = await apiPredeterminedCandidate.get(`/top-topics/?city=${target_locality}`)
      setByTopic(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getByTopic()
  }, [])

  return (
    <Container>
      <Card title="TOP 5 serviços">
        <Wrapper>
          {loading ? (
            <Spin spinning />
          ) : (
            byTopic?.map((currentTopic: any, index: any) => {
              return (
                <TableRowTop
                  borderColor={colorService(currentTopic.topic)}
                  service={currentTopic.topic}
                  value={currentTopic.total_mentions}
                  progress={Math.ceil(currentTopic.percentual)}
                  hasIcon
                  iconName={iconService(currentTopic.topic)}
                  index={index}
                />
              )
            })
          )}
        </Wrapper>
      </Card>
    </Container>
  )
}

export default TopServices
