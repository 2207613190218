/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useRef, useState } from "react";
import * as S from "./styles";

interface ColoredLineProps {
  color: string;
  rotation?: number;
  size?: number;
  cutPercentage?: number;
}

const ColoredLine: React.FC<ColoredLineProps> = ({
  color,
  rotation = 1,
  size = 0.3,
  cutPercentage = 0,
}) => {
  const rotate = rotation * 1.5;
  const cappedRotation = Math.min(rotate, 180);

  const style = {
    color,
    backgroundColor: color,
    transform: `rotate(${cappedRotation}deg)`,
    border: "none",
    borderBottom: `${size}rem solid ${color}`,
    borderRadius: `0 0 ${cutPercentage}% ${cutPercentage}%`,
    overflow: "hidden",
    transition: "all .5s ease",
    width: `${cappedRotation <= 2 ? "100px" : "100%"}`,
  };

  return <hr style={style} />;
};

interface GaugeProps {
  defaultData?: { limit: any; color: string }[];
  maxValue: number;
  currentValue: number;
  formatLabel: (val: any) => string;
  sizeStyle?: string;
  labels: {
    max: string;
    min: string;
  };
  goal?: number;
  minimalGoal?: number;
  isCockpit?: boolean;
}

const Gauge: React.FC<GaugeProps> = ({
  defaultData = [],
  sizeStyle,
  maxValue,
  currentValue,
  labels,
  formatLabel,
  goal,
  minimalGoal,
  isCockpit,
}) => {
  const gaugeRef = useRef<HTMLDivElement>(null);

  const [activePointerColor, setActivePointerColor] = useState<string | null>(
    null,
  );

  useEffect(() => {
    const gauge: any = gaugeRef.current;

    const setGaugeValue = (value: number) => {
      const normalizedValue = Math.max(0, Math.min(1, value / maxValue));

      if (gauge) {
        gauge.querySelector(".gauge__fill").style.transform =
          `rotate(${normalizedValue / 2}turn)`;
        gauge.querySelector(".gauge__cover").textContent =
          formatLabel(currentValue);
      }
    };

    let newColor;

    if (currentValue <= ((minimalGoal ?? 0) * (goal ?? 100)) / 100) {
      newColor = "#D70708";
    } else if (currentValue >= (goal ?? 100)) {
      newColor = "#00E23F";
    } else {
      newColor = "#FFC000";
      /* // eslint-disable-next-line no-plusplus
      for (let i = defaultData.length - 1; i >= 0; i--) {
        const pointer = defaultData[i]
        const absoluteLimit = (pointer.limit / 100) * maxValue
        if (currentValue >= absoluteLimit) {
          newColor = pointer.color
          break
        }
      } */
    }

    if (newColor !== activePointerColor) {
      setActivePointerColor(newColor || null);
    }

    setGaugeValue(currentValue);
  }, [
    currentValue,
    minimalGoal,
    goal,
    defaultData,
    maxValue,
    activePointerColor,
  ]);

  const memoizedComponent = useMemo(() => {
    return (
      <S.GaugeContainer ref={gaugeRef} maxSize={sizeStyle}>
        <S.GaugeBody>
          <S.GaugeFill className="gauge__fill" color={activePointerColor} />

          <S.GaugeCover className="gauge__cover" isCockpit={isCockpit} />
        </S.GaugeBody>

        <div
          style={{
            position: "relative",
            transform: "translateY(-13px)",
            zIndex: 2,
          }}
        >
          {defaultData.map((item, index) => (
            <ColoredLine key={index} color={item.color} rotation={item.limit} />
          ))}
        </div>

        <S.TextBox isCockpit={isCockpit}>
          <span>{labels.min}</span>
          <span>{labels.max}</span>
        </S.TextBox>
      </S.GaugeContainer>
    );
  }, [activePointerColor, labels.max, labels.min, defaultData, currentValue]);

  return memoizedComponent;
};

export default Gauge;
