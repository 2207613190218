import { apiPortifolio, apiUser } from 'services/apiService'
import {
  PartyData,
  TransformedPartyData,
  StateData,
  TransformedStateData,
  CityData,
  TransformedCityData,
  TransformedCandsDataSponsored,
} from '../types/sponsoredApplicationsTypes'

export const getPartyImage = async (partyAcronym: string) => {
  try {
    const { data } = await apiUser.get(`/candidate-portfolio/party/${partyAcronym}/image`)
    return data
  } catch (e) {
    console.log(e)
    console.error(`Erro em buscar a imagem do partido ${partyAcronym}`, e)
    return false
  }
}

export const calculateTotalPositions = (partiesData: PartyData[]) => {
  const totals = {
    presidentes: 0,
    vice_presidentes: 0,
    governadores: 0,
    vice_governadores: 0,
    senadores: 0,
    dep_federais: 0,
    dep_estaduais: 0,
    dep_distritais: 0,
    primeiro_suplente_senador: 0,
    segundo_suplente_senador: 0,
    prefeitos: 0,
    vices: 0,
    vereadores: 0,
  }

  const positionMapping = {
    Presidente: 'presidentes',
    'Vice Presidente': 'vice_presidentes',
    Governador: 'governadores',
    'Vice Governador': 'vice_governadores',
    Senador: 'senadores',
    'Deputado Federal': 'dep_federais',
    'Deputado Estadual': 'dep_estaduais',
    'Deputado Distrital': 'dep_distritais',
    '1º Suplente Senador': 'primeiro_suplente_senador',
    '2º Suplente Senador': 'segundo_suplente_senador',
    Prefeito: 'prefeitos',
    'Vice Prefeito': 'vices',
    Vereador: 'vereadores',
  }

  partiesData.forEach(party => {
    party.totals.forEach(total => {
      const positionKey = positionMapping[total.position as keyof typeof positionMapping]
      if (positionKey) {
        if (Object.prototype.hasOwnProperty.call(totals, positionKey)) {
          totals[positionKey as keyof typeof totals] += total.count
        } else {
          console.warn(`Chave de posição não encontrada em totals: ${positionKey}`)
        }
      } else {
        console.warn(`Chave de mapeamento não encontrada para posição: ${total.position}`)
      }
    })
  })

  return totals
}

const transformParties = (partiesData: PartyData[]): TransformedPartyData[] => {
  const processedData = partiesData.map((party: any) => {
    return {
      id: party?.party_id || party?.party_acronym,
      party_acronym: party.party_acronym,
      totals: party.totals.map((total: any) => ({
        position: total.position,
        count: total.count.toString(),
      })),
    }
  })

  return processedData
}

const transformStates = (statesData: StateData[]): TransformedStateData[] => {
  const processedData = statesData.map((state: any) => {
    return {
      state: state.state.replace('BRA.', ''),
      acronym: state.acronym,
      total_count: state.total_count,
      total_portion: state.total_portion,
      totals: state.totals.map((total: any) => ({
        position: total.position,
        value: total.count,
      })),
    }
  })

  return processedData
}

const transformCities = (citiesData: CityData[]): TransformedCityData[] => {
  const processedData = citiesData.map((city: any) => {
    return {
      city: city.city,
      totals: city.totals.map((total: any) => ({
        position: total.position,
        value: total.value,
      })),
    }
  })

  return processedData
}

export const transformCands = (candsData: any[]): TransformedCandsDataSponsored[] => {
  const processedData = candsData.map(cand => ({
    position: cand.position,
    candidates: cand.candidates.map((candidate: any) => ({
      ...candidate,
      id: candidate.id,
      name: candidate.name,
    })),
  }))

  return processedData
}

export const getPartyTotals = async (): Promise<
  | {
      presidentes: number
      vice_presidentes: number
      governadores: number
      vice_governadores: number
      senadores: number
      dep_federais: number
      dep_estaduais: number
      dep_distritais: number
      primeiro_suplente_senador: number
      segundo_suplente_senador: number
      prefeitos: number
      vices: number
      vereadores: number
    }
  | { message: string }
> => {
  try {
    const { data } = await apiUser.get(`/candidate-portfolio/affiliated-persons-by-party`)
    console.log('data 1111', data)
    const totals = calculateTotalPositions(data)
    return totals
  } catch (e) {
    console.error('Error calculating total positions:', e)
    return { message: 'Não foi possível calcular os totais das posições.' }
  }
}

export const getParties = async (): Promise<TransformedPartyData[] | { message: string }> => {
  try {
    const { data } = await apiUser.get(`/candidate-portfolio/affiliated-persons-by-party`)
    console.log('data 2222', data)
    return transformParties(data)
  } catch (e) {
    console.error('Error fetching parties:', e)
    return { message: 'Não foi possível buscar os partidos cadastrados.' }
  }
}

export const getParty = async (acronym: string): Promise<TransformedPartyData[] | { message: string }> => {
  try {
    const { data } = await apiUser.get(`/candidate-portfolio/affiliated-persons-by-party?party=${acronym}`)

    return transformParties(data)
  } catch (e) {
    console.error('Error fetching parties:', e)
    return { message: 'Não foi possível buscar os partidos cadastrados.' }
  }
}

export const getStates = async (acronym: string): Promise<TransformedStateData[] | { message: string }> => {
  try {
    const { data } = await apiUser.get(`/candidate-portfolio/affiliated-persons-by-state?party=${acronym}`)

    return transformStates(data)
  } catch (e) {
    console.error('Error fetching states:', e)
    return { message: 'Não foi possível buscar os estados do partido cadastrado.' }
  }
}

export const getCitiesAcronymUf = async (
  partyAcronym: string,
  uf: string
): Promise<TransformedCityData[] | { message: string }> => {
  const newUf = uf.replace('BRA.', '')
  try {
    const { data } = await apiUser.get(
      `/candidate-portfolio/affiliated-persons-by-city?party=${partyAcronym}&state=${newUf}`
    )

    return data
  } catch (e) {
    console.error('Error fetching cities:', e)
    return { message: 'Não foi possível buscar as cidades do partido cadastrado.' }
  }
}

export const getCandsAcronymUfCity = async (
  acronym: string,
  uf: string,
  city: string
): Promise<TransformedCandsDataSponsored[] | { message: string }> => {
  const newUf = uf.replace('BRA.', '')
  try {
    const { data } = await apiUser.get(
      `/candidate-portfolio/affiliated-persons-by-city-candidate?party=${acronym}&state=${newUf}&city=BRA.${newUf}.${city}`
    )

    const newData = await Promise.all(
      data.map(async (cand: any) => {
        const result = await Promise.all(
          cand?.candidates.map(async (profile: any) => {
            const avatarImage = await getAvatar(profile.id)
            return {
              ...profile,
              avatar: avatarImage?.url,
            }
          })
        )

        return {
          ...cand,
          candidates: result,
        }
      })
    )

    return transformCands(newData)
  } catch (e) {
    console.error('Error fetching candidates:', e)
    return { message: 'Não foi possível buscar os candidatos do partido cadastrado.' }
  }
}

export const getAvatar = async (id_cand: string) => {
  try {
    const { data } = await apiPortifolio.get(`/candidate-portfolio/candidate/${id_cand}/image`)

    return {
      url: data?.image_url,
    }
  } catch (e) {
    console.log(e)
    return { message: 'erro: busca do avatar' }
  }
}
