import {useEffect, useState} from 'react'
import {useUser} from 'global/UserContext';
import {Box, Skeleton, Typography} from '@mui/material'
import {InfoCircleOutlined} from '@ant-design/icons'

import InternalButton from 'componentsNew/InternalButton'
import {ModalVox} from 'componentsNew/PoliticalVox/components/ModalVox'
import {CardManager} from '../CardManager'
import { createUser, updateUser, deleteUser} from '../services/AcceptServices'
import {FormUser, ModeType} from '../FormUser'

import {ConfirmDialog} from '../Dialogs'

import * as S from './styles'
import {api} from "../../../services/apiService";

const titles: { [key in ModeType]: string } = {
  [ModeType.Create]: "Adicionar Novo Usuário",
  [ModeType.View]: "Visualizar Usuário",
  [ModeType.Edit]: "Editar Usuário",
  [ModeType.Remove]: "Remover Usuario"
};

import { message } from 'antd';

export const AcceptUsers = () => {
  // Data
  const [userList, setUserList] = useState<any[]>([])
  const {user} = useUser();
  const [userListLoading, setUserListLoading] = useState(true)
  const [form, setForm] = useState({
    mode: ModeType.Create,
    loading: false,
    user: {},
    userId: 0,
    planId: undefined,
    open: false,
    onCancel: () => setForm({...form, open: false}),
    onEdit: (user:any) => openUserForm(ModeType.Edit, user),
  })
  const [dialog, setDialog] = useState({
    open: false,
    onClose: () => setDialog({...dialog, open: false}),
    onConfirm: () => Promise.resolve(),
    textAction: "Deseja realmente salvar as alterações feitas?",
    secondyText: "Alteração salva com sucesso!",
    closeText: "Salvar",
    title: 'CONFIRMAÇÃO'
  })

  const [messageApi, contextHolder] = message.useMessage();

  // handler's
  useEffect(() => {
    fetchUserList()
  }, [])
  const fetchUserList = () => {
    setUserListLoading(true);

    api.get('/user/me')
      .then((response) => {
        console.log("Afiliados: ", response?.data?.person?.affiliations)
        console.log("Usuario: ", user)
        const filteredList = (response?.data?.person?.affiliations ?? []).filter((user: {
          id: string;
        }) => user.id != response?.data?.person?.id)
          .sort((a:any, b:any) => a.name.localeCompare(b.name));
        setUserList(filteredList);

      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => setUserListLoading(false))

  };

  const submitFormV2 = async (modeType: ModeType, data: any) => {
    console.debug("SubmitFormV2 data: ", data)


    if (modeType === ModeType.Create) {
      createNewUser(modeType, data)
    } else if (modeType === ModeType.Edit) {
      updateUserV2(modeType, data)
    }


  }

  const updateUserV2 = async (modeType: ModeType, data: any) => {


    await updateUser(data, form.userId)
      .then(res => {
        console.log("Res: ", res)
        setForm({...form, open: false, loading: false})
        messageApi.open({
          type: 'success',
          content: 'Atualizado com sucesso!',
          style: {
            marginTop: '10vh',
          },
          duration: 3
        });
        fetchUserList()
      })
      .catch(e => {
        console.error("Error: ", e)
        const {status, data} = e.response
        let message = "Ocorreu um erro ao tentar criar o usuário"
        if (status == "400" && data?.error == "Username already exists") {
          message = "E-mail já cadastrado no sistema! Use outro e-mail."
        }

        messageApi.open({
          type: 'error',
          content: message,
          style: {
            marginTop: '10vh',
          },
          duration: 3
        });
      })
  }

  const createNewUser = async (modeType: ModeType, data: any) => {

    createUser({...data}, user.person.id)
      .then(res => {
        console.log("Res: ", res)
        setForm({...form, open: false, loading: false})
        messageApi.open({
          type: 'success',
          content: 'Cadastrado com sucesso!',
          style: {
            marginTop: '10vh',
          },
          duration: 3
        });
        fetchUserList()
      })
      .catch(e => {
        console.error("Error: ", e)
        const {status, data} = e.response
        let message = "Ocorreu um erro ao tentar criar o usuário"
        if (status == "400" && data?.error == "Username already exists") {
          message = "E-mail já cadastrado no sistema! Use outro e-mail."
        } else if (status == "400" && data?.error == "Social Security Number already exists") {
          message = "Usuário já cadastrado no sistema! Use outro CPF."
        }

        messageApi.open({
          type: 'error',
          content: message,
          style: {
            marginTop: '10vh',
          },
          duration: 3
        });

      })
  }

  const openUserForm = (mode: ModeType, user: any) => {
    setForm({
      ...form,
      open: true,
      user,
      planId: user.plan,
      userId: user.id,
      loading: false,
      mode: mode
    })
  }

  const submitForm: { [key in ModeType]: (data: any) => void } = {
    [ModeType.View]: (userData: any) => setForm({...form, open: true, loading: false, mode: ModeType.Edit}),

    [ModeType.Create]: (userData: any) => {
      setForm({...form, loading: true})


      setDialog({
        ...dialog,
        open: true,
        textAction: `Vamos enviar um email para "${userData.email}"`,
        closeText: "Enviar email",
        onClose: async () => {

          createUser(userData, user.person.id)
            .then(res => {
              console.log("Res: ", res)
              fetchUserList()
            })
            .catch(e => {
              console.error("Error: ", e)
              setDialog({...dialog, secondyText: 'Ocorreu um problema! Tente mais tarde!'});
            })


        }

      });


    },
    [ModeType.Edit]: (userData: any) => {
      setForm({...form, loading: true})

      console.log("OnConfirm Edit Save", userData)

      let userOld: any = form.user;
      let fromId = userOld.id ?? {};

      setDialog({
        ...dialog,
        open: true,
        textAction: "Deseja realmente salvar as alterações feitas?",
        secondyText: "Alteração salva com sucesso!",
        closeText: "Salvar",
        onClose: () => setDialog({...dialog, open: false}),
        onConfirm: async () => {
          await updateUser(userData, fromId)
            .then(res => {
              console.log("Res: ", res)
              setForm({...form, open: false, loading: false})
              fetchUserList()
            }).catch((e) => {
              console.error("Error: ", e)
              setDialog({...dialog, secondyText: 'Ocorreu um problema! Tente mais tarde!'});
              setForm({...form, loading: false})
            })
        }
      });
    },
    [ModeType.Remove]: (data: any) => {
      console.log("Excluir: ", data)
      setForm({...form, loading: true})
      let fromId = data.id ?? {};

      setDialog({
        ...dialog,
        open: true,
        textAction: `Deseja realmente excluir o usuario '${data.name}'?`,
        secondyText: "Usuário excluido com sucesso!",
        closeText: "Excluir",
        onClose: () => setDialog({...dialog, open: false}),
        onConfirm: async () => {
          console.log("OnConfirm Remove")
          await deleteUser(fromId)
            .then(res => {
              console.log("Res: ", res)
              setForm({...form, open: false, loading: false})
              fetchUserList()
            }).catch((e) => {
              console.error("Error: ", e)
              setDialog({...dialog, secondyText: 'Ocorreu um problema! Tente mais tarde!'});
              setForm({...form, loading: false})
            })
        }
      });
    }
  };

  return (
    <S.Wrapper>
      <S.Content>
        <S.ActionBar>
          <span className="title"> Usuários vinculados ({userList.length}) </span>
          <InternalButton
            disabled={true}
            background="#FFC000" variant="outline"
                          onClick={() => setForm({
                            ...form,
                            open: true,
                            user: {},
                            loading: false,
                            mode: ModeType.Create
                          })}>
            Adicionar novo usuário
          </InternalButton>
        </S.ActionBar>

        <S.HeaderTable>
          <S.TextTable className="name_field">Nome </S.TextTable>
          <S.TextTable>Perfil </S.TextTable>
          <S.TextTable>Ações</S.TextTable>
        </S.HeaderTable>
        {!userListLoading && userList.length < 1 &&
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mt={4}>
            <InfoCircleOutlined color="info" style={{fontSize: 40, marginBottom: 8, marginTop: 8}}/>
            <Typography variant="h6" color="textSecondary">
              Nenhum usuário encontrado!!
            </Typography>
          </Box>
        }
        <S.ListContent>
          {userListLoading &&
            <>
              <Skeleton
                sx={{bgcolor: 'grey.900'}}
                variant="rectangular"
                height={32}/>
              <Skeleton
                sx={{bgcolor: 'grey.900'}}
                variant="rectangular"
                height={32}/>
            </>
          }
          {userList.length > 0 &&
            userList.map(user => (
              <CardManager
                key={user?.id}
                name={user?.name}
                last_name={user?.last_name}
                profile={user?.profile_name}
                license={user?.person_license?.name}
                last_access={user?.updated}
                status={user?.status_affiliation}
                actions={{
                  read: () => openUserForm(ModeType.View, user),
                  edit: () => openUserForm(ModeType.Edit, user),
                  delete: () => submitForm[ModeType.Remove](user)
                }}
                permission={user?.from_person?.permission?.name}
              />
            ))}
        </S.ListContent>
      </S.Content>

      <ModalVox open={form.open} title={titles[form.mode]}>

        <FormUser {...form} onSubmit={(data: any) => submitFormV2(form.mode, data)}/>
      </ModalVox>

      <ConfirmDialog {...dialog}/>

      {contextHolder}
    </S.Wrapper>
  );
}
