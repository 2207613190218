import React, { useEffect, useMemo, useState } from "react";
import Text from "componentsNew/Text";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FaCircle } from "react-icons/fa6";
import IconFacebook from "assets/icon-facebook.svg";
import IconInstagram from "assets/icon-instagram.svg";
import IconX from "assets/icon-x.svg";
import IconYouTube from "assets/icon-youtube.svg";
import IconGoogle from "assets/icon-google.svg";
import IconGoogleTrends from "assets/icon-google-trends.svg";
import IconDrop from "assets/icon-drop.svg";
import Spin from "componentsNew/Spin";
import AdversaryFilterCard from "componentsNew/CockpitComponents/components/AdversaryFilterCard";
import IconActive from "componentsNew/CockpitComponents/components/IconActive";
import SentimentAnalysis from "componentsNew/CockpitComponents/components/SentimentAnalysis";
import VolumeOfMentionsChart from "componentsNew/CockpitComponents/components/VolumeOfMentionsChart";
import ShareOfVoiceChart from "componentsNew/CockpitComponents/components/ShareOfVoiceChart";
import GoogleTrendsChart from "componentsNew/CockpitComponents/components/GoogleTrendsChart";
import useSentimentAnalysis from "componentsNew/CockpitComponents/hooks/useSentimentAnalysis";
import useShareOfVoice from "componentsNew/CockpitComponents/hooks/useShareOfVoice";
import useVolumeOfMentions from "componentsNew/CockpitComponents/hooks/useVolumeOfMentions";
import useGoogleTrends from "componentsNew/CockpitComponents/hooks/useGoogleTrends";
import { ICandidateType } from "componentsNew/CockpitComponents/types/adversaryTypes";
import { getAllAdversary } from "componentsNew/CockpitComponents/hooks/useAllAdversary";
import { getAvatar } from "componentsNew/CockpitComponents/hooks/useAvatar";
import { useCandidates } from "hooks/useCandidates";
import { useFilterGlobal } from "hooks/useFilterGlobal";
import * as S from "./styles";

const CompetitiveAnalysis = () => {
  const colors = ["#FF5733", "#33FF57", "#3357FF", "#FF33A8", "#FFC300"];
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [showGoogleTrends, setShowGoogleTrends] = useState(true);
  const [adversaryList, setAdversaryList] = useState<ICandidateType[]>([]);
  const [selectedAdversary, setSelectedAdversary] = useState<ICandidateType[]>(
    [],
  );
  const [selectedAdversaryPersonIds, setSelectedAdversaryPersonIds] = useState<
    string[]
  >([]);
  const [selectedSocialNetworks, setSelectedSocialNetworks] = useState<
    string[]
  >(["Facebook", "Instagram", "X", "YouTube", "Google"]);
  const [selectedTime, setSelectedTime] = useState<string>("past-7-days");
  const [viewMode, setViewMode] = useState<"ranking" | "score">("score");
  const [chartType, setChartType] = useState<"area" | "line">("area");
  const [avatars, setAvatars] = useState<{ [key: string]: string }>({});

  const { selectedCandidate } = useCandidates();
  const { rangeDate } = useFilterGlobal();
  const candidateIds = selectedAdversary
    .map((adversary) => adversary.candidate_id)
    .join(",");
  const socialNetworks = selectedSocialNetworks.join(",");

  const useFormattedNumbers = (number: number | null | undefined) => {
    const formattedNumber = useMemo(() => {
      if (number == null) {
        return "-";
      }
      if (number >= 1000000) {
        return (number / 1000000).toFixed(2).replace(".", ",") + "M";
      } else {
        return number.toLocaleString("de-DE");
      }
    }, [number]);

    return formattedNumber;
  };

  const {
    data: sentimentData,
    aggData,
    loading: loadingSentiment,
    error: sentimentError,
  } = useSentimentAnalysis(
    candidateIds,
    socialNetworks,
    rangeDate.initial_date,
    rangeDate.end_date,
  );

  const {
    data: dataShareOfVoice,
    sources: sourcesShareOfVoice,
    candidates: candidatesShareOfVoice,
    totals: totalsShareOfVoice,
    pagesTotals: pagesTotalsShareOfVoice,
    loading: loadingShareOfVoice,
    error: errorShareOfVoice,
  } = useShareOfVoice(
    candidateIds,
    socialNetworks,
    rangeDate.initial_date,
    rangeDate.end_date,
    selectedAdversary,
  );

  const {
    data: dataVolumeOfMentions,
    dates: datesVolumeOfMentions,
    cands: candsVolumeOfMentions,
    totals: totalsVolumeOfMentions,
    loading: loadingVolumeOfMentions,
    error: errorVolumeOfMentions,
    totalsSum: totalsSumVolumeOfMentions,
  } = useVolumeOfMentions(
    candidateIds,
    socialNetworks,
    rangeDate.initial_date,
    rangeDate.end_date,
    selectedAdversary,
  );

  const keywords = selectedAdversary
    .map((adversary) => adversary.candidate_name)
    .join(",");

  const {
    data: googleTrendsData,
    loading: loadingGoogleTrends,
    error: errorGoogleTrends,
  } = useGoogleTrends(
    selectedTime as "past-hour" | "past-day" | "past-7-days" | "past-90-days",
    keywords,
  );

  useEffect(() => {
    fetchAdversaryList();
  }, []);

  const fetchAdversaryList = async () => {
    const data = await getAllAdversary();
    if (!("message" in data) && data.results) {
      const sortedResults = data.results.sort((a, b) =>
        (a.candidate_name || "").localeCompare(b.candidate_name || ""),
      );

      const adversaryWithColors = sortedResults.map((adversary, index) => ({
        ...adversary,
        color: colors[index % colors.length],
      }));

      const selectedCandidateWithColor: ICandidateType = {
        id: selectedCandidate.id,
        candidate_id: selectedCandidate.id_cand,
        candidate_name: selectedCandidate.name?.toUpperCase(),
        candidate_party: selectedCandidate.party,
        is_listening: true,
        color: "#FFC000",
        person: selectedCandidate.user?.id,
      };

      const updatedAdversaryList = [
        selectedCandidateWithColor,
        ...adversaryWithColors,
      ];

      setAdversaryList(updatedAdversaryList);

      updatedAdversaryList.forEach(async (adversary) => {
        const avatarResponse = await getAvatar(adversary.candidate_id || "");
        if (avatarResponse.url) {
          setAvatars((prev) => ({
            ...prev,
            [adversary.candidate_id || ""]: avatarResponse.url,
          }));
        }
      });

      const initialSelected = updatedAdversaryList?.slice(0, 5);
      setSelectedAdversary(initialSelected);
      setSelectedAdversaryPersonIds(
        initialSelected.map((adversary) => adversary.id),
      );
    }
  };

  const handleToggleAdversary = (
    adversary: ICandidateType,
    isActive: boolean,
  ) => {
    setSelectedAdversaryPersonIds((prevSelectedIds) => {
      if (isActive) {
        if (prevSelectedIds.length >= 5) {
          alert("Você pode selecionar no máximo 5 adversários.");
          return prevSelectedIds;
        }
        return [...prevSelectedIds, adversary.id];
      } else {
        return prevSelectedIds.filter((id) => id !== adversary.id);
      }
    });

    setSelectedAdversary((prevSelectedAdversary) => {
      if (isActive) {
        if (prevSelectedAdversary.length >= 5) {
          return prevSelectedAdversary;
        }
        return [...prevSelectedAdversary, adversary];
      } else {
        return prevSelectedAdversary.filter((item) => item.id !== adversary.id);
      }
    });
  };

  const toggleGoogleTrends = () => {
    setShowGoogleTrends((prev) => !prev);
  };

  const toggleSocialNetwork = (network: string) => {
    setSelectedSocialNetworks((prevState) =>
      prevState?.includes(network)
        ? prevState.filter((sn) => sn !== network)
        : [...prevState, network],
    );
  };

  const toggleTimeSelection = (time: string) => {
    setSelectedTime(time);
  };

  const anyActive = selectedSocialNetworks.length > 0;

  const totalMentionsAnalysisSentiment =
    useFormattedNumbers(aggData?.total) || "-";
  const totalMentionsShareOfVoice =
    useFormattedNumbers(totalsSumVolumeOfMentions) || "-";
  const totalMentionsVolumeOfMentions =
    useFormattedNumbers(totalsSumVolumeOfMentions) || "-";

  return (
    <S.Content>
      <S.StyledCard isDropdownActive={isDropdownActive}>
        <S.DropdownHeader
          onClick={() => setIsDropdownActive(!isDropdownActive)}
        >
          <S.HeaderText>
            <Text size="medium" bold>
              Análise Competitiva
            </Text>
          </S.HeaderText>
          <S.DropdownToggle>
            {isDropdownActive ? (
              <MdKeyboardArrowUp size={36} color="#FFC000" />
            ) : (
              <MdKeyboardArrowDown size={36} color="#FFC000" />
            )}
          </S.DropdownToggle>
        </S.DropdownHeader>

        {isDropdownActive && (
          <S.Wrapper>
            <S.FiltersHeader>
              <S.CandidateListHeader>
                <Text size="xSmall" bold>
                  Lista de Candidatos (Máximo de 5)
                </Text>
                <S.AdversaryList>
                  {adversaryList.map((adversary) => (
                    <AdversaryFilterCard
                      key={adversary.id}
                      candidate={adversary}
                      isActive={selectedAdversaryPersonIds.includes(
                        adversary.id,
                      )}
                      onToggle={handleToggleAdversary}
                      borderColor={adversary.color}
                      avatarUrl={avatars[adversary.candidate_id || ""]}
                    />
                  ))}
                </S.AdversaryList>
              </S.CandidateListHeader>

              <S.SearchToolsHeader>
                <Text size="xSmall" bold>
                  Ferramentas de Busca
                </Text>
                <S.SocialNetworkSelection>
                  <IconActive
                    active={showGoogleTrends}
                    onToggle={() => toggleGoogleTrends()}
                    image={IconGoogleTrends}
                    anyActive={!showGoogleTrends}
                  />
                </S.SocialNetworkSelection>
              </S.SearchToolsHeader>

              <S.SocialMediaListHeader>
                <Text size="xSmall" bold>
                  Redes Sociais (Mais Populares)
                </Text>
                <S.SocialNetworkSelection>
                  <IconActive
                    active={selectedSocialNetworks.includes("Facebook")}
                    onToggle={() => toggleSocialNetwork("Facebook")}
                    image={IconFacebook}
                    anyActive={anyActive}
                  />
                  <IconActive
                    active={selectedSocialNetworks.includes("Instagram")}
                    onToggle={() => toggleSocialNetwork("Instagram")}
                    image={IconInstagram}
                    anyActive={anyActive}
                  />
                  <IconActive
                    active={selectedSocialNetworks.includes("X")}
                    onToggle={() => toggleSocialNetwork("X")}
                    image={IconX}
                    anyActive={anyActive}
                  />
                  <IconActive
                    active={selectedSocialNetworks.includes("YouTube")}
                    onToggle={() => toggleSocialNetwork("YouTube")}
                    image={IconYouTube}
                    anyActive={anyActive}
                  />
                  <IconActive
                    active={selectedSocialNetworks.includes("Google")}
                    onToggle={() => toggleSocialNetwork("Google")}
                    image={IconGoogle}
                    anyActive={anyActive}
                  />
                  <IconActive
                    active={selectedSocialNetworks.includes("New")}
                    anyActive={anyActive}
                    icon={<FaCircle size={48} color="#161617" />}
                  />
                </S.SocialNetworkSelection>
              </S.SocialMediaListHeader>
            </S.FiltersHeader>

            {selectedAdversary.length > 0 &&
              selectedSocialNetworks.length > 0 && (
                <S.SentimentAnalysisContainer>
                  <Text size="xSmall" bold>
                    Totais de Menções no Período - Análise de Sentimento por
                    Candidato
                  </Text>
                  {!loadingSentiment ? (
                    <Text size="small" color="gray">
                      (Total de {totalMentionsAnalysisSentiment} menções)
                    </Text>
                  ) : (
                    ""
                  )}
                  <S.SentimentAnalysisContent>
                    {loadingSentiment ? (
                      <Spin />
                    ) : sentimentError ? (
                      <S.ErrorMessage>{sentimentError}</S.ErrorMessage>
                    ) : sentimentData ? (
                      <SentimentAnalysis
                        selectedAdversary={selectedAdversary}
                        avatars={avatars}
                        selectedSocialNetworks={selectedSocialNetworks}
                        sentimentData={
                          Array.isArray(sentimentData) ? sentimentData : []
                        }
                        aggData={aggData || undefined}
                      />
                    ) : null}
                  </S.SentimentAnalysisContent>
                </S.SentimentAnalysisContainer>
              )}

            {selectedAdversary.length > 0 &&
              selectedSocialNetworks.length > 0 && (
                <S.ShareOfVoiceContainer>
                  <Text size="xSmall" bold>
                    Posicionamento Digital - Participação Percentual por Rede
                    Social
                  </Text>
                  {!loadingShareOfVoice ? (
                    <Text size="small" color="gray">
                      (Total de {totalMentionsShareOfVoice} menções)
                    </Text>
                  ) : (
                    ""
                  )}
                  <S.ShareOfVoiceContent>
                    {loadingShareOfVoice ? (
                      <Spin />
                    ) : errorShareOfVoice ? (
                      <S.ErrorMessage>{errorShareOfVoice}</S.ErrorMessage>
                    ) : dataShareOfVoice ? (
                      <ShareOfVoiceChart
                        avatars={avatars}
                        adversaryList={adversaryList}
                        selectedAdversary={selectedAdversary}
                        socialNetworks={selectedSocialNetworks}
                        sources={sourcesShareOfVoice}
                        candidates={candidatesShareOfVoice}
                        totals={totalsShareOfVoice}
                        pagesTotals={pagesTotalsShareOfVoice}
                        data={dataShareOfVoice}
                        loading={loadingShareOfVoice}
                        error={errorShareOfVoice}
                        onToggleAdversary={() => console.log("Adversary")}
                      />
                    ) : null}
                  </S.ShareOfVoiceContent>
                </S.ShareOfVoiceContainer>
              )}

            {selectedAdversary.length > 0 &&
              selectedSocialNetworks.length > 0 && (
                <S.EvolutionAverageContainer>
                  <S.HeaderVolumeOfMentions>
                    <div>
                      <Text size="xSmall" bold>
                        Evoluções de Menções no Período - Média Diária
                      </Text>
                      {!loadingVolumeOfMentions ? (
                        <Text size="small" color="gray">
                          (Total de {totalMentionsVolumeOfMentions} menções)
                        </Text>
                      ) : (
                        ""
                      )}
                    </div>
                    <S.ChartTypeSwitch>
                      <S.SwitchOption
                        isActive={chartType === "line"}
                        onClick={() => setChartType("line")}
                      >
                        Linha
                      </S.SwitchOption>
                      <S.SwitchOption
                        isActive={chartType === "area"}
                        onClick={() => setChartType("area")}
                      >
                        Área empilhado
                      </S.SwitchOption>
                    </S.ChartTypeSwitch>
                  </S.HeaderVolumeOfMentions>
                  <S.VolumeOfMentionsContent>
                    <VolumeOfMentionsChart
                      chartType={chartType}
                      adversaryList={adversaryList}
                      selectedAdversary={selectedAdversary}
                      selectedAdversaryPersonIds={selectedAdversaryPersonIds}
                      avatars={avatars}
                      onToggleAdversary={handleToggleAdversary}
                      selectedSocialNetworks={selectedSocialNetworks}
                      dataVolumeOfMentions={dataVolumeOfMentions}
                      datesVolumeOfMentions={datesVolumeOfMentions}
                      candsVolumeOfMentions={candsVolumeOfMentions}
                      totalsVolumeOfMentions={totalsVolumeOfMentions}
                      loadingVolumeOfMentions={loadingVolumeOfMentions}
                      errorVolumeOfMentions={errorVolumeOfMentions}
                    />
                  </S.VolumeOfMentionsContent>
                </S.EvolutionAverageContainer>
              )}

            {showGoogleTrends && selectedAdversary.length > 0 && (
              <S.GoogleTrendsContainer>
                <S.GoogleTrendsHeader>
                  <S.GoogleTrendsTitleIcon>
                    <Text size="xSmall" bold>
                      Google Trends
                    </Text>
                    <img
                      src={IconGoogleTrends}
                      alt="Google Trends"
                      width={16}
                    />
                  </S.GoogleTrendsTitleIcon>
                  <div style={{ display: "flex", gap: "16px" }}>
                    <S.ButtonGroup>
                      <S.FilterButtonLeft
                        isActive={viewMode === "ranking"}
                        onClick={() => setViewMode("ranking")}
                      >
                        Ranking
                      </S.FilterButtonLeft>
                      <S.FilterButtonRight
                        isActive={viewMode === "score"}
                        onClick={() => setViewMode("score")}
                      >
                        Score
                      </S.FilterButtonRight>
                    </S.ButtonGroup>
                    <S.TimeDropdown>
                      <S.DropdownToggle>
                        <Text size="xSmall" bold>
                          {selectedTime === "past-hour"
                            ? "Última Hora"
                            : selectedTime === "past-day"
                              ? "Último Dia"
                              : selectedTime === "past-7-days"
                                ? "Últimos 7 Dias"
                                : "Últimos 90 Dias"}
                        </Text>
                        <img src={IconDrop} alt="Dropdown Icon" />
                        <S.DropdownContent>
                          <S.DropdownItem
                            onClick={() => toggleTimeSelection("past-hour")}
                          >
                            Última Hora
                          </S.DropdownItem>
                          <S.DropdownItem
                            onClick={() => toggleTimeSelection("past-day")}
                          >
                            Último Dia
                          </S.DropdownItem>
                          <S.DropdownItem
                            onClick={() => toggleTimeSelection("past-7-days")}
                          >
                            Últimos 7 Dias
                          </S.DropdownItem>
                          <S.DropdownItem
                            onClick={() => toggleTimeSelection("past-90-days")}
                          >
                            Últimos 90 Dias
                          </S.DropdownItem>
                        </S.DropdownContent>
                      </S.DropdownToggle>
                    </S.TimeDropdown>
                  </div>
                </S.GoogleTrendsHeader>

                <S.GoogleTrendsContent>
                  <GoogleTrendsChart
                    selectedAdversary={selectedAdversary}
                    selectedTime={
                      selectedTime as
                        | "past-hour"
                        | "past-day"
                        | "past-7-days"
                        | "past-90-days"
                    }
                    avatars={avatars}
                    viewMode={viewMode}
                    onToggleAdversary={handleToggleAdversary}
                    data={googleTrendsData}
                    loading={loadingGoogleTrends}
                    error={errorGoogleTrends}
                  />
                </S.GoogleTrendsContent>
              </S.GoogleTrendsContainer>
            )}
          </S.Wrapper>
        )}
      </S.StyledCard>
    </S.Content>
  );
};

export default CompetitiveAnalysis;
