import styled, { css } from 'styled-components'

interface IProgressMinimal {
  size: 'small' | 'medium' | 'xMedium' | 'large'
}

const varianteSize = {
  small: () => css`
    height: 10px;
  `,
  medium: () => css`
    height: 15px;
  `,
  large: () => css`
    height: 30px;
  `,
  xMedium: () => css`
    height: 20px;
  `,
}

export const StyledProgress = styled.div<IProgressMinimal>`
  height: 30px;
  width: 100%;
  border: none;
  border-radius: 0;
  overflow: hidden; /* Garante que o preenchimento não ultrapasse as bordas */
  ${({ size }) => varianteSize[size]};
  & progress {
    appearance: none;
    background: #1d1d1d;
    height: 100%;
    width: 100%;
  }

  & progress::-webkit-progress-bar {
    background-color: transparent;
  }

  & progress::-webkit-progress-value {
    background: linear-gradient(to right, #ffc000, #ffc000); /* Substitua pela cor desejada */
  }

  & progress::-moz-progress-bar {
    background-color: #ffc000; /* Substitua pela cor desejada */
  }
`
