import React from "react";
import { CSSProperties } from "styled-components";
import { Collapse, Switch } from "antd";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { noop } from "lodash";

/* import Button from 'componentsNew/Button' */

import * as S from "./styles";

interface CollapseBaseProps {
  title: string;
  content: React.ReactNode;
  disabledFilter?: boolean;
  onActiveFilter?: (val: boolean) => void;
  isActive?: boolean;
  defaultOpen?: boolean;
  isOpen: boolean;
  onToggleCollapse: (key: string | string[]) => void;
}

const CollapseBase: React.FC<CollapseBaseProps> = ({
  title,
  content,
  disabledFilter,
  onActiveFilter = noop,
  isActive,
  defaultOpen,
  isOpen,
  onToggleCollapse,
}) => {
  const panelStyle: CSSProperties = {
    marginBottom: 24,
    background: "#161717",
    borderRadius: "8px",
    border: "none",
  };

  const handlePanelClick = (key: string | string[]) => {
    onToggleCollapse(key);
    const isActivating = Array.isArray(key)
      ? key.includes(title)
      : key === title;
    onActiveFilter(isActivating);
  };

  const handleToggle = (check: boolean) => {
    onActiveFilter(check);
  };

  return (
    <Collapse
      bordered={false}
      activeKey={isOpen ? [title] : []}
      expandIcon={({ isActive }) =>
        isActive ? (
          <IoIosArrowUp size={22} color="#FFC000" />
        ) : (
          <IoIosArrowDown size={22} color="#FFC000" />
        )
      }
      expandIconPosition="right"
      style={{ background: "#161717" }}
      ghost
      onChange={handlePanelClick}
    >
      <Collapse.Panel
        header={
          <S.Header>
            <Switch
              onClick={() => handlePanelClick(isOpen ? [] : title)}
              checked={isOpen}
              key={`unic_switch_key=${title}`}
              onChange={(val, e) => {
                e.stopPropagation();
                handleToggle(val);
              }}
              disabled={disabledFilter}
            />
            <p> {title}</p>
          </S.Header>
        }
        key={title}
        style={panelStyle}
      >
        {content}
      </Collapse.Panel>
    </Collapse>
  );
};

export default CollapseBase;
