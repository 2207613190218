import { useEffect, useState } from "react";
import { differenceInSeconds } from "date-fns";

const useActiveMeetingTimer = (meetingDetails: any) => {
  const [remainingTime, setRemainingTime] = useState("");

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (meetingDetails) {
      const { start_at, finish_at } = meetingDetails;
      const now = new Date();

      if (new Date(start_at) <= now && now <= new Date(finish_at)) {
        const totalDurationInSeconds = differenceInSeconds(
          new Date(finish_at),
          new Date(start_at),
        );

        const elapsedSeconds = differenceInSeconds(now, new Date(start_at));
        let remainingSeconds = totalDurationInSeconds - elapsedSeconds;

        const updateTimer = () => {
          if (remainingSeconds > 0) {
            remainingSeconds -= 1;

            const minutes = Math.floor(remainingSeconds / 60);
            const seconds = remainingSeconds % 60;

            setRemainingTime(
              `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`,
            );
          } else {
            clearInterval(interval);
            setRemainingTime("");
          }
        };

        updateTimer();
        interval = setInterval(updateTimer, 1000);
      } else {
        setRemainingTime("");
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [meetingDetails]);

  return remainingTime;
};

export default useActiveMeetingTimer;
