import { styled } from 'styled-components'

export const WrapperFilter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  height: 100%;
`

export const FieldValues = styled.div``
