import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-height: 200px;
  margin-top: 16px;
`;

export const CandidateCard = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const NameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  max-width: 120px;
  text-align: center;
  min-height: 32px;
`;

export const CandidateInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2px 0 8px 0;
  width: 100%;
  height: 100%;
`;

export const PercentageSession = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 2px;
  max-width: 87px;
`;

export const PercentageItem = styled.div<{ color: string }>`
  font-family: Roboto;
  font-size: 10px;
  font-weight: 700;
  color: ${({ color }) => color};
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  display: flex;
  overflow: hidden;
  margin: 2px 0;
  max-width: 87px;
`;

export const ProgressSegment = styled.div<{ color: string; width: string }>`
  height: 100%;
  background-color: ${({ color }) => color};
  width: ${({ width }) => width};
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #ff0000;
  font-family: Roboto;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  padding: 16px;
`;
