import styled, { css } from "styled-components";

import { TextDangerProps } from ".";

type TextDangerStyleProps = Pick<
  TextDangerProps,
  | "width"
  | "withMaxLengthText"
  | "maxLineText"
  | "fontSize"
  | "fontWeight"
  | "justify"
  | "color"
>;

const textDangerStylesModifier = {
  withMaxLengthText: (maxLineText?: number) => css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${maxLineText || 7};
    -webkit-box-orient: vertical;
    transition: 0.4s;
  `,
};

export const Container = styled.div<TextDangerStyleProps>`
  ${({
    width,
    withMaxLengthText,
    maxLineText,
    fontWeight = 400,
    justify,
    color,
  }) => css`
    width: ${width};

    text-align: ${justify};
    line-height: 1.5;

    font-weight: ${fontWeight};
    color: #e9e9f0;

    font-size: 12px;
    font-weight: 400;

    margin-bottom: 4px;
    strong {
      font-weight: 700;
      color: yellow;
    }

    p {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      color: yellow;
    }

    margin-bottom: 4px;

    ${!!withMaxLengthText &&
    textDangerStylesModifier.withMaxLengthText(maxLineText)}
  `}
`;
