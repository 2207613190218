import React, {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {Grid, TextField, Box, MenuItem, Skeleton} from '@mui/material';

import Button from 'componentsNew/Button'

import {formatarData} from 'utils/ultilsFunction';

import InputMask from 'react-input-mask';
import {menuOptions} from 'componentsNew/RegisterComponents/Schemas/FormData';
import {formUserSchema} from './schema/validateForm';

import {getPlans, getProfiles} from '../services/AcceptServices'

import * as S from '../../FormGenerator/styles'
import MaskedInputComponent from "../../RegisterComponents/Forms/RegisterCandidate/InputMask";
import formatPhone from "../../Helper/utils/formatPhone";


export enum ModeType {
  Create = "CREATE",
  View = "VIEW",
  Edit = "EDIT",
  Remove = "REMOVE"
}

export const FormUser = ({user, mode, loading, onCancel, onSubmit, onEdit}: {
  user: any,
  mode: ModeType,
  loading: boolean,
  onCancel: () => void,
  onSubmit: (data: any) => void,
  onEdit: (data: any) => void
}) => {

  // Data
  const {control, handleSubmit, setValue, formState: {errors}} = useForm({resolver: zodResolver(formUserSchema)});
  const [planList, setPlanList] = useState<{ name: string; id: number; }[]>([]);
  const [profileList, setProfileList] = useState<{ name: string; id: string; }[]>([]);

  // handler
  const fetchPlains = async () => {
    const response = await getPlans()
    const newData = response || []
    setPlanList(
      newData.map((plan: any) => ({"name": plan.name, "id": plan.id})
      ))
  }
  const fetchProfiles = async () => {
    return getProfiles().then((res: any) => {
      if (res && res.length > 0) setProfileList(res.map((profile: any) => ({"name": profile.name, "id": profile.id})))
      else setProfileList(menuOptions.map((option: any) => ({"name": option.label, "id": option.value})))

      if (user) setValue('profile', user?.profile?.id || '');

    }).catch((e) => {
      console.error("e: ", e);
    })
  }

  useEffect(() => {

    if (user) {
      console.log("Person: ", user)
      setValue('name', user?.name || '');
      setValue('last_name', user?.user?.last_name || '');
      setValue('date_of_birth', formatarData(user?.date_of_birth || ''));
      setValue('social_security_number', user?.social_security_number || '');
      setValue('email', user?.user?.email || '');
      setValue('phone', formatPhone(user?.phone || ''));
      setValue('plan_id', user?.plan || '');

      fetchProfiles()
        .then(() => {
          setValue('profile', user?.profile_id);
        })

    } else {
      fetchProfiles()
    }

  }, [user]);
  const valid = (data: any) => {
    console.log('Call Validator', data);

    onSubmit({
      ...data,
      date_of_birth: data.date_of_birth
        .split('/')
        .reverse()
        .join('-')
    });
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(valid)} sx={{p: 2}}>
        {loading ? (
            // Mostra skeletons enquanto está carregando
            <Grid container spacing={1} rowSpacing={3}>
              <Grid item xs={12} sm={5}>
                <Skeleton variant="rectangular" height={32}/>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Skeleton variant="rectangular" height={32}/>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton variant="rectangular" height={32}/>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Skeleton variant="rectangular" height={32}/>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Skeleton variant="rectangular" height={32}/>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Skeleton variant="rectangular" height={32}/>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Skeleton variant="rectangular" height={32}/>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Skeleton variant="rectangular" height={32}/>
              </Grid>
            </Grid>
          ) :
          (<Grid container spacing={1} rowSpacing={3}>
              <Grid item xs={12} sm={5}>
                <Controller
                  name="name"
                  control={control}
                  disabled={mode !== ModeType.Create}
                  render={({field}) => (
                    <TextField
                      {...field}
                      label="Nome"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.name}
                      helperText={errors.name?.message?.toString() || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="last_name"
                  disabled={mode !== ModeType.Create}
                  control={control}
                  render={({field}) => (
                    <TextField
                      {...field}
                      label="Sobrenome"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      error={!!errors.last_name}
                      helperText={errors.last_name?.message?.toString() || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="date_of_birth"
                  control={control}
                  render={({field}) => (
                    <InputMask //disabled={mode === ModeType.View}
                      mask="99/99/9999" name='date_of_birth'
                      value={field.value}
                      disabled={mode !== ModeType.Create}
                      onChange={(value): void => {
                        field.onChange(value)
                      }}
                      onBlur={field.onBlur}
                    >
                      <TextField
                        type="text"
                        label="Data de Nascimento"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        name="cnpj"
                        fullWidth
                        placeholder="DD/MM/AAAA"
                        error={!!errors.date_of_birth}
                        helperText={errors.date_of_birth?.message?.toString() || ''}
                      />

                    </InputMask>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Controller
                  name="social_security_number"
                  control={control}
                  render={({field}) => (
                    <InputMask
                      mask="999.999.999-99"
                      maskChar=" "
                      name='social_security_number'
                      disabled={mode !== ModeType.Create}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}>
                      <TextField
                        label="CPF"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled={mode !== ModeType.Create}
                        fullWidth
                        placeholder="000.000.000-00"
                        error={!!errors.social_security_number}
                        helperText={errors.social_security_number?.message?.toString() || ''}
                      />
                    </InputMask>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <Controller
                  name="profile"
                  defaultValue={1}
                  control={control}
                  disabled={mode === ModeType.View || profileList.length === 0}
                  render={({field}) => (
                    <TextField
                      {...field}
                      label="Qual o perfil?"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      select
                      fullWidth
                      error={!!errors.profile}
                      helperText={errors.profile?.message?.toString() || ''}>
                      {profileList.map((option) => (
                        <MenuItem selected={option.id == field.value} key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <Controller
                  name="email"
                  control={control}
                  disabled={mode !== ModeType.Create}
                  render={({field}) => (
                    <TextField
                      {...field}
                      label="Email"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email?.message?.toString() || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Controller
                  name="phone"
                  control={control}
                  disabled={mode !== ModeType.Create}
                  render={({field}) => (
                    <InputMask
                      mask="(99) 9 9999-9999"
                      maskChar={null}
                      maskPlaceholder={null}
                      alwaysShowMask={false}
                      disabled={mode !== ModeType.Create}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}>
                      <TextField
                        label="Telefone"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        placeholder="Telefone"
                        error={!!errors.phone}
                        helperText={errors.phone?.message?.toString() || ''}
                      />

                    </InputMask>
                  )}
                />
              </Grid>
            </Grid>
          )}
        {(mode === ModeType.Edit || mode === ModeType.Create) &&
          <S.SubmitBox actionStyle='secondary'>
            <Button type="submit" disabled={loading} loading={loading}>
              {!loading &&
                <>Salvar</>
              }
            </Button>
            <Button type="button" variant="outline" onClick={() => onCancel()}>
              Cancelar
            </Button>
          </S.SubmitBox>
        }
        {mode === ModeType.View &&
          <S.SubmitBox actionStyle='secondary'>
            <Button type="button" variant="minimal" onClick={() => onEdit(user)}>
              Editar usuário
            </Button>
            <Button type="button" variant="outline" onClick={() => onCancel()}>
              Fechar
            </Button>
          </S.SubmitBox>
        }
      </Box>
    </>
  );
};
