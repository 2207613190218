// useIframeCommunication.tsx
import { useEffect, useRef } from "react";

export const useIframeCommunication = (iframeUrl: string) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const sendMessageToIframe = (message: string) => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(message, iframeUrl);
    }
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin === iframeUrl) {
        console.log("Mensagem do Next.js:", event.data);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [iframeUrl]);

  return { iframeRef, sendMessageToIframe };
};
