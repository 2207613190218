import { useState, useEffect } from "react";
import { apiAdvisor } from "pages/SettingsProfile/api/apiService";
import { useUser } from "global/UserContext";

const useAdvisor = () => {
  const [isAdvisor, setIsAdvisor] = useState<boolean>(false);
  const [idAdvisor, setIdAdvisor] = useState<string | null>(null);
  const { user } = useUser();

  useEffect(() => {
    const fetchAdvisors = async () => {
      try {
        console.log("Fetching advisors...");
        const response = await apiAdvisor.get("/advisor/v1/advisors/");
        console.log("Advisors fetched successfully:", response.data.items);

        const advisors = response.data.items;

        for (const advisor of advisors) {
          console.log("Checking advisor:", advisor);
          if (advisor.id_user === user.person?.id.toString()) {
            console.log("Advisor found:", advisor);
            setIsAdvisor(true);
            setIdAdvisor(advisor.id);
            break;
          }
        }
      } catch (error) {
        console.error("Failed to fetch advisors:", error);
      }
    };

    if (user?.person?.id) {
      console.log("User ID found:", user.person.id);
      fetchAdvisors();
    } else {
      console.log("No user ID found.");
    }
  }, [user]);

  console.log("Final state:", { isAdvisor, idAdvisor });

  return { isAdvisor, idAdvisor };
};

export default useAdvisor;
