import { faker } from '@faker-js/faker'
import {
  AllyTheme,
  SelectedAllyTheme,
  AllyThemeListResponse,
  CreateAllyThemePayload,
  CreatedAllyThemeResponse,
  AcceptWizardParams,
  AllyWizard,
  BaseResponseApi,
  GetAllyWizardResponse,
  ListByAllyTypes,
  RandomSelectOption,
  User,
} from '../types/ALLY_TYPES'

const { animal, date, number, music } = faker

export const TOPIC_NAMES = ['very good', 'good', 'neutral', 'bad', 'very bad']

export const TOPIC_POSITIVE = ['Amigos (A)', 'Família (F)']
export const TOPIC_NEGATIVE = ['Colegas (C)', 'Parceiros (P)']

export const generateBaseResponse = <T>(results: T[]): BaseResponseApi & { results: T[] } => {
  return {
    count: results.length,
    next: faker.internet.url(),
    previous: faker.internet.url(),
    results,
  }
}

const generateRandomAllyTheme = (): AllyTheme => ({
  id: number.int({ max: 50 }),
  name: animal.dog(),
  created: date.anytime(),
  updated: date.anytime(),
})

const generateRandomAllyWizard = (): AllyWizard => {
  const topic = faker.helpers.arrayElement(TOPIC_NAMES) as 'very good' | 'good' | 'neutral' | 'bad' | 'very bad'
  return {
    id: number.int(),
    theme_name: music.genre(),
    topic,
    avatar: faker.image.avatar(),
    status: 'pending',
    created: date.anytime(),
    updated: date.anytime(),
    person: number.int(),
    theme: number.int(),
    topic_name: topic,
    profile_name: faker.person.fullName(),
    is_positive: faker.datatype.boolean({ probability: 0.5 }),
  }
}

export const MOCK_Ally_THEME_LIST: AllyTheme[] = TOPIC_NAMES.map(() => generateRandomAllyTheme())

const Ally_WIZARDS_ARRAY = Array.from({ length: 30 }).map(() => generateRandomAllyWizard())

export const MOCK_Ally_WIZARDS: GetAllyWizardResponse = generateBaseResponse(Ally_WIZARDS_ARRAY)

const generateRandomSelectOption = (topic_name: string): RandomSelectOption => {
  const theme_name = faker.animal.dog()
  return {
    id: faker.number.int({ max: 50 }),
    topic: faker.number.int(),
    name: theme_name,
    theme_name,
    created: faker.date.anytime(),
    updated: faker.date.anytime(),
    label: topic_name,
    value: faker.number.int({ max: 100 }),
    topic_name,
    active: false,
  }
}

const generateRandomAllySelectionPositiveItem = (topic: string): ListByAllyTypes => {
  const id = number.int({ max: 100 })
  return {
    id,
    topic,
    topic_name: topic,
    selection_list: Array.from({ length: 20 }).map(() => generateRandomSelectOption(topic)),
    selected_values: [],
    theme_list: Array.from({ length: 8 }),
  }
}

export const MOCK_Ally_SELECTION_POSITIVE: ListByAllyTypes[] = TOPIC_NAMES.map(topic =>
  generateRandomAllySelectionPositiveItem(topic)
)

export const MOCK_Ally_SELECTION_NEGATIVE: ListByAllyTypes[] = TOPIC_NEGATIVE.map(topic =>
  generateRandomAllySelectionPositiveItem(topic)
)

const generateRandomAllySelectionResponse = (topic: string): SelectedAllyTheme => {
  const id = number.int()
  const theme_name = animal.dog()
  return {
    id,
    theme_name,
    is_recommended: true,
    is_listening: true,
    topic,
    topic_name: topic,
    created: date.anytime(),
    updated: date.anytime(),
    person: number.int(),
    theme: number.int(),
  }
}

export const MOCK_Ally_SELECTION_RESPONSE_THEME = TOPIC_NAMES.map(topic => generateRandomAllySelectionResponse(topic))

export const MOCK_Ally_THEME_LIST_RESPONSE: AllyThemeListResponse = {
  count: MOCK_Ally_THEME_LIST.length,
  next: 'http://example.com',
  previous: 'http://example.com',
  results: MOCK_Ally_THEME_LIST,
}

export const MOCK_CREATE_Ally_THEME_PAYLOAD: CreateAllyThemePayload = {
  is_recommended: true,
  is_listening: true,
  topic: 'Amigos',
  person: number.int(),
  theme: number.int(),
}

export const MOCK_CREATED_Ally_THEME_RESPONSE: CreatedAllyThemeResponse = {
  id: number.int(),
  theme_name: animal.dog(),
  is_recommended: true,
  is_listening: true,
  topic: 'Amigos',
  created: date.anytime(),
  updated: date.anytime(),
  person: number.int(),
  theme: number.int(),
}

export const MOCK_ACCEPT_WIZARD_PARAMS: AcceptWizardParams = {
  wizard_id: number.int(),
  status: 'approved',
}

const generateProfileList = () => {
  const name = faker.person.firstName()
  return {
    label: name,
    value: faker.string.uuid(),
    name,
  }
}

const generateCandidate = (topic: string): User => {
  const name = faker.person.firstName()
  const avatar = faker.image.avatarLegacy()
  return {
    id: faker.number.int({ max: 100 }),
    avatar,
    category: faker.person.jobTitle(),
    name,
    topic,
  }
}

export const MOCK_PROFILE_LIST = Array.from({ length: 20 }, () => generateProfileList())

export const MOCK_ALLY_CANDIDATE_LIST = TOPIC_NAMES.map(topic => generateCandidate(topic))
