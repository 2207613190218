import styled from "styled-components";

export const Container = styled.div<{ open: boolean; visibleCard: boolean }>`
  background-color: #161717;
  height: 100vh;
  position: fixed;
  top: 92px;
  display: ${({ visibleCard }) => (!visibleCard ? "flex" : "block")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  left: ${({ open }) => (open ? "75px" : "-6px")};
  transform: ${({ visibleCard }) => visibleCard && "translateX(15%)"};
  transition: all 0.3s ease;
  z-index: 4;
  overflow-y: ${({ visibleCard }) => visibleCard && "auto"};

  @media screen and (max-height: 600px) {
    left: ${({ open }) => (open ? "63px" : "2px")};
    min-height: 720px;
    .info {
      top: 3px;
      left: 48px;
    }
  }

  .candidate-card {
    background-color: #161717;
    width: 17.1rem;
    height: 100vh;
    min-height: 900px;
    max-height: 1200px;

    > button {
      margin-left: 14.125rem;
    }

    .candidate-username {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
    }

    .candidattus-coins {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-top: 2rem;
      margin-left: 1.32rem;
      border-bottom: 1px solid #494949;
      margin-right: 1.32rem;
      font-weight: 300;
    }

    .cand-coins-value {
      display: flex;
      flex: 1;
      align-items: center;
      gap: 1rem;
    }

    .coins {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-top: 0.5rem;
    }

    .candidate-info {
      margin: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: start;
    }

    .change-application {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 12px;
    }

    .plan-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem 3.375rem;
    }

    .subtitle-card {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-top: 2rem;
      margin-left: 1.32rem;
      font-weight: 300;
      border-bottom: 1px solid #494949;
      margin-right: 1.32rem;
    }

    .text-info {
      color: #e9e9f0;
      margin-left: 1.32rem;
      font-weight: 300;
    }
  }
`;

export const TitleCard = styled.text`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffc000;
  border-radius: 1.5rem;
  background: #ffc000;
  padding: 0.1875rem 0.5rem;
  margin: -1rem 5rem;
  margin-bottom: 1rem;
  color: #181717;
  text-align: center;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const PepBadge = styled.div<any>`
  display: ${(props) => (props.isPep ? "block" : "none")};
  background-color: yellow;
  color: black;
  padding: 0.25rem 0.5rem;
  border-radius: 0.75rem;
  position: absolute;
  top: 5.625rem;
  right: 1rem;
  font-weight: bold;
  z-index: 10;
`;

export const PlanIcon = styled.div<any>`
  display: ${(props) => (props.plan === "Premium" ? "block" : "none")};
  background-color: gold;
  color: black;
  padding: 0.25rem 0.5rem;
  border-radius: 0.75rem;
  position: absolute;
  top: 6.875rem;
  right: 1rem;
  font-weight: bold;
  z-index: 10;
`;

export const CoinsIcon = styled.img`
  width: 2.2rem;
  height: 2.2rem;
  flex-shrink: 0;
  margin-left: 1.3rem;
  margin-top: 0.5rem;
`;

export const Coins = styled.div`
  color: #ffc000;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 0.5rem;
  font-family: Roboto;
  font-size: 2rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
`;

export const EyeButton = styled.button`
  color: #ffc000;
  background: transparent;
  display: flex;
  justify-content: end;
  align-items: center;
  border: none;
  cursor: pointer;
  width: auto;
`;

export const CloseButton = styled.button.attrs({
  "aria-label": "Fechar",
})`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 15.875rem;
`;

export const InfoButton = styled.button.attrs({
  "aria-label": "Informações",
})`
  background-color: #ffc000;
  border: none;
  border-radius: 50%;
  width: 1.29rem;
  height: 1.29rem;
  color: #161717;
  cursor: pointer;
  position: absolute;
  top: 4px;
  left: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-height: 600px) {
    top: 3px;
    left: 48px;
  }
`;
