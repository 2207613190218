import React, { useEffect, useState } from "react";
import Card from "componentsNew/Card";
import Text from "componentsNew/Text";
import { getPartyImage } from "pages/AllApplications/services/allApplicationsServices";
import { Container } from "./styles";

interface ICardSponsored {
  id?: number | string;
  titleCard?: string;
  title: string;
  icon: any;
  valueCandidates: ICandidate[];
  onClick?: any;
  isClickable?: boolean;
}

interface ICandidate {
  position: string;
  count: number;
}

interface IPartyData {
  party_acronym: string;
  totals: ICandidate[];
}

const defaultPositions = ["Prefeito", "Vice Prefeito", "Vereador"];

const CardSponsored = ({
  id,
  title,
  icon,
  valueCandidates = [],
  titleCard,
  onClick,
  isClickable = true,
}: ICardSponsored) => {
  const [partyImage, setPartyImage] = useState<any>("");

  useEffect(() => {
    const fetchImage = async () => {
      const imageUrl = await getPartyImage(title);
      setPartyImage(imageUrl);
    };

    fetchImage();
  }, [title]);

  const mergedCandidates: ICandidate[] = defaultPositions?.map((position) => {
    const positionCount =
      valueCandidates.find(
        (total) => total.position.toUpperCase() === position.toUpperCase(),
      )?.count || 0;

    return { position, count: positionCount };
  });

  const slug = title?.slice(0, 4);

  return (
    <Container
      onClick={() => isClickable && onClick && onClick(id)}
      isClickable={isClickable}
    >
      <Card title={titleCard}>
        <div className="wrapper">
          <div className="cardTitle">
            {partyImage ? (
              <div className="party-image">
                <img src={partyImage?.image_url} alt={`${title}`} />
              </div>
            ) : (
              <div className="avatarText">{slug}</div>
            )}
            <Text bold size="medium">
              {title}
            </Text>
          </div>
          <div className="wrapperCard">
            {mergedCandidates.map((candidate) => (
              <div className="card" key={candidate.position}>
                <Text size="small">{candidate.position}</Text>
                <Text size="xSmall" bold>
                  {candidate.count}
                </Text>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </Container>
  );
};

export default CardSponsored;
