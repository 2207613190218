import styled from 'styled-components'

export const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  width: 230px;

  .progress {
    width: 180px;
    display: flex;
  }
  .topArcPercet {
    width: 50px;
    margin-left: 16px;
  }

  .wrapper-progress {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 0 8px;
    .bird {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background: black;
      border-radius: 50%;
    }
    img {
      width: 18px;
      height: 18px;
    }
  }
`
export const WrapperCard = styled.div`
  width: 230px;
`
