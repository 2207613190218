import axios from 'axios'
import { searchCandidate } from 'componentsNew/PoliticalVox/services/SearchCandidate'

import { debounce } from 'lodash'
import { useEffect, useState } from 'react'

import { SelectFilter } from '../SelectFilter'
import * as S from './styles'
import ALL_STATE_LIST from "../../../../../utils/location";

interface searchProps {
  city?: string
  position?: string
  state?: string
  party?: string
  candidate?: string
}
interface FormCandidateProps {
  onAddCandidate: (candidate: any) => void
}

export const FormCandidate = ({ onAddCandidate }: FormCandidateProps) => {
  const [loadingDistrict, setLoadingDistrict] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [allDistrict, setAllDistrict] = useState<{ label: string; value: any }[]>([])
  const [partyList, setPartyList] = useState<{ label: string; value: any }[]>([])
  const [roleList, setRoleList] = useState<{ label: string; value: any }[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filterSearch, setFilterSearch] = useState({} as searchProps)
  const [searchResults, setSearchResults] = useState<{ label: string; value: any }[]>([])

  useEffect(() => {
    fetchParty()
    fetchPositions()
  }, [])

  const formatStateList = ALL_STATE_LIST.map(state => ({
    label: state.nome,
    value: state.codigo,
  }))

  const handleChangeState = (state: string) => {
    if (state) {
      fetchDistrictList(state)
    }
  }

  const fetchDistrictList = async (state: string) => {
    setLoadingDistrict(true)
    try {
      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
      )
      const districtList = response.data.map((district: any) => ({
        value: district?.id,
        label: district?.nome,
      }))
      setAllDistrict(districtList)
    } catch (error) {
      console.error('Erro ao buscar municípios:', error)
    } finally {
      setLoadingDistrict(false)
    }
  }

  const fetchParty = async () => {
    try {
      const response = await axios.get('https://dadosabertos.camara.leg.br/api/v2/partidos?ordem=ASC&ordenarPor=sigla')
      const partyListFormatter = response.data?.dados?.map((party: any) => ({
        ...party,
        value: party?.id,
        label: party?.sigla,
      }))
      setPartyList(partyListFormatter)
    } catch (error) {
      console.error('Erro ao buscar municípios:', error)
    }
  }

  const fetchPositions = async () => {
    try {
      const response = await axios.get('https://udd2hcn349.execute-api.us-east-1.amazonaws.com/api/analysis/positions')
      const positionListFormatter = response.data?.map((party: any) => ({
        ...party,
        value: party?.cd_cargo,
        label: party?.ds_cargo,
      }))
      console.log('klow', positionListFormatter)
      setRoleList(positionListFormatter)
    } catch (error) {
      console.error('Erro ao buscar municípios:', error)
    }
  }

  const performSearch = async (term: string) => {
    try {
      const data = await searchCandidate(filterSearch)

      if (!('message' in data)) {
        setSearchResults(data)
      }
    } catch (error) {
      console.error('Erro ao buscar dados:', error)
    } finally {
      setLoadingSearch(false)
    }
  }

  useEffect(() => {
    if (searchTerm && searchTerm.length > 2) {
      setLoadingSearch(true)
      debouncedSearch(searchTerm)
    } else {
      setLoadingSearch(false)
    }

    return () => {
      debouncedSearch.cancel()
    }
  }, [searchTerm])

  const debouncedSearch = debounce(performSearch, 2000)

  const handleAddCandidate = (candidate: any) => {
    onAddCandidate(candidate)
  }

  const onChangeForm = (name: string, val?: any) => {
    if (name === 'state') {
      setAllDistrict([])
      fetchDistrictList(val)

      setFilterSearch(prev => ({ ...prev, [name]: val, city: undefined }))
    } else {
      setFilterSearch(prev => ({ ...prev, [name]: val }))

      if (name === 'candidate') {
        setSearchTerm(val)
      }
    }
  }

  return (
    <S.WrapperFilter>
      <S.FieldValues>
        <SelectFilter
          width="260px"
          options={formatStateList}
          placeholder="Selecione Estado"
          onSelect={(_, options) => onChangeForm('state', options?.data?.value)}
          onClear={() => onChangeForm('state')}
          value={filterSearch.state}
        />
      </S.FieldValues>{' '}
      <S.FieldValues>
        <SelectFilter
          width="260px"
          options={allDistrict}
          disabled={allDistrict.length === 0}
          value={filterSearch.city}
          placeholder="Selecione um Município"
          loading={loadingDistrict}
          onSelect={(_, options) => onChangeForm('city', options?.data?.label)}
          onClear={() => onChangeForm('city')}
        />
      </S.FieldValues>{' '}
      <S.FieldValues>
        <SelectFilter
          width="260px"
          options={partyList}
          placeholder="Selecione um Partido"
          disabled={partyList.length === 0}
          onSelect={(_, options) => onChangeForm('party', options?.data?.label)}
          onClear={() => onChangeForm('party')}
          value={filterSearch.party}
        />
      </S.FieldValues>{' '}
      <S.FieldValues>
        <SelectFilter
          width="260px"
          options={roleList}
          placeholder="Selecione um Cargo"
          disabled={roleList.length === 0}
          onSelect={(_, options) => onChangeForm('position', options?.data?.value)}
          onClear={() => onChangeForm('position')}
          value={filterSearch.position}
        />
      </S.FieldValues>{' '}
      <S.FieldValues>
        <SelectFilter
          /*  suffixIcon={
            loadingSearch ? (
              <Spin size="small" indicator={<FaSpinner size={14} />} />
            ) : (
              <FaSearch size={14} className="icon_search" />
            )
          } */
          type_search
          width="260px"
          showSearch
          placeholder="Buscar figura política"
          notFoundContent={<p>Nenhum Candidato encontrado</p>}
          loading={loadingSearch}
          optionFilterProp="label"
          options={searchResults}
          onSearch={val => onChangeForm('candidate', val)}
          onSelect={(val, options) => handleAddCandidate(options?.data)}
          onClear={() => onChangeForm('candidate')}
          suffixIcon={loadingSearch ? undefined : false}
        />
      </S.FieldValues>
    </S.WrapperFilter>
  )
}
