import Button from "componentsNew/Button";
import { useUser } from "global/UserContext";
import { useFilterVox } from "hooks/useFilterVox";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { ALL } from "utils/constants";
import CollapseBase from "../components/CollapseBase";
import { LoadingSearch } from "../components/LoadingSearch";
import { useFiltersVoxBar } from "../hooks/useFilterBarVox";
import { useOverview } from "../hooks/useOverview";
import { ListeningCity } from "../ListeningCity";
import {
  getMapData,
  getMentionsByTheme,
  getMentionsByTopic,
  getSentiment,
  getTopMentions,
  getTotalMentions,
  getWordCloud,
} from "../services/lambdaServices";
import { getCandidateList, getTopicList } from "../services/overViewServices";
import { SaveSearch, SelectFilter } from "./components/Dialogs";
import { ConfirmAction } from "./components/Dialogs/ConfirmAction";
import FilterBarVox from "./components/FilterBarVox";
import { FilterCandidate } from "./components/FilterCandidate";
import { FilterTags } from "./components/FilterTags";
import { FilterTopics } from "./components/FilterTopics";
import * as S from "./styles";

interface SearchFilterProps {
  locality: string;
  theme: string;
  source: string;
  topic: string;
}

export const OverView = () => {
  const {
    filterRaioX,
    rangeDate,
    handleMentionsChart,
    filtersDefault,
    topicosFilter,
    handleWordCloud,
    handleTopMentions,
    handleChart,
    resetAllFilters,
    includedExcludedFilter,
    isKeyWordOpen,
    isPoliticaVoxOpen,
    isCandidateOpen,
    handleToggleCandidateCollapse,
    handleTogglePoliticaVoxCollapse,
    handleToggleKeyWordCollapse,
    changeActive,
  } = useFilterVox();
  const { candidatoFilter, changeCandidateList } = useOverview();
  const { activeFiltersBar, handleActiveAdvancedFilters, theme } =
    useFiltersVoxBar();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadAdvanced, setLoadAdvanced] = useState(false);
  const [loadGovernament, setLoadingGovernament] = useState(false);
  const [loadElection, setLoadElection] = useState(false);
  const [startedFilters, setStartedFilters] = useState(false);
  const [filteredData, setFilteredData] = useState<any | null>(null);
  const [dialogSearch, setDialogSearch] = useState(false);
  const [dialogListFilter, setDialogListFilter] = useState(false);
  const { user } = useUser();
  const [topicList, setTopicList] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  const words = isKeyWordOpen
    ? {
        positive_words: filtersDefault?.includedWords,
        negative_words: filtersDefault?.excludedWords,
      }
    : undefined;
  const validateTopic =
    topicosFilter.filters &&
    isPoliticaVoxOpen &&
    filtersDefault?.topic !== "Todos" &&
    filtersDefault?.topic;
  const validateCand = isCandidateOpen && filtersDefault?.candidate?.id;
  const includedCount = words?.positive_words?.length || 0;
  const excludedCount = words?.negative_words?.length || 0;
  const validateIncluded =
    (includedCount > 0 || excludedCount > 0) && isKeyWordOpen;
  const validateSearch = validateTopic || validateCand || validateIncluded;

  const isButtonDisabled = !validateSearch;

  useEffect(() => {
    fetchCandidateList();
    fetchAllTopics();
  }, []);

  const fetchCandidateList = async () => {
    const candidate_list = await getCandidateList(user?.person?.id);
    changeCandidateList({
      list: candidate_list?.candidateList,
      list_id: candidate_list?.fastListId,
    });
  };

  const fetchAllTopics = async () => {
    try {
      const topic_list = await getTopicList();
      setTopicList(topic_list);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSaveFilter = () => {
    setDialogSearch(true);
  };

  const handleDialogSearch = () => {
    setDialogListFilter(true);
  };

  const candidate_id = isCandidateOpen
    ? filtersDefault?.candidate?.id
    : undefined;

  const topic_and_subtopic = isPoliticaVoxOpen
    ? {
        sub_topics_ids: topicosFilter?.filters?.subtopics || [],
        parent_name: filtersDefault?.topic !== "Todos" && filtersDefault?.topic,
      }
    : undefined;

  const defaultPayload = {
    parent_name: topic_and_subtopic?.parent_name,
    positive_words: words?.positive_words,
    negative_words: words?.negative_words,
    sub_topics_ids: topic_and_subtopic?.sub_topics_ids,
    sentiment: filterRaioX !== ALL && filterRaioX,
  };

  const payloadMentions = {
    ...defaultPayload,
    id_cand: candidate_id,
    initial_date: rangeDate.initial_date,
    final_date: rangeDate.end_date,
  };
  const payloadMapMentions = {
    ...defaultPayload,
    id_cand: candidate_id,
    initial_date: rangeDate.initial_date,
    final_date: rangeDate.end_date,
  };
  const payloadElection = {
    ...defaultPayload,
    id_cand: candidate_id,
    initial_date: rangeDate.initial_date,
    final_date: rangeDate.end_date,
    topic_lvl: "2",
  };
  const payloadWordCloud = {
    ...defaultPayload,
    candidate_id,
    date_start: rangeDate.initial_date,
    end_date: rangeDate.end_date,
    parent_name: topic_and_subtopic?.parent_name,
  };
  const payloadElectionWordCloud = {
    ...defaultPayload,
    candidate_id,
    date_start: rangeDate.initial_date,
    end_date: rangeDate.end_date,
    topic_lvl: "2",
  };

  const searchListening = async (externalPayload?: any) => {
    try {
      const totalCount = await getTotalMentions({
        ...payloadMentions,
        ...externalPayload,
      });
      const sentiment = await getSentiment({
        ...payloadWordCloud,
        ...externalPayload,
      });
      const dataMap = await getMapData({
        ...payloadMapMentions,
        ...externalPayload,
      });

      setFilteredData({
        sentiment,
        dataMap,
        totalCount,
        selected_topic: topic_and_subtopic?.parent_name,
      });
    } catch (e) {
      toast.error("Error in search.");
    } finally {
      setLoadingSearch(false);
      setLoadAdvanced(false);
    }
  };

  const fetchMentionsByTopic = async (externalPayload?: any) => {
    try {
      const wordCloud = await getMentionsByTopic({
        ...payloadMentions,
        ...externalPayload,
      });
      handleMentionsChart("government", wordCloud);
    } catch (e) {
      console.log(e);
    } finally {
      handleChart({ type: "government", loading: false });
    }
  };

  const fetchTopMentions = async (externalPayload?: any) => {
    try {
      const topMentions = await getTopMentions({
        ...payloadMentions,
        ...externalPayload,
      });
      handleTopMentions(topMentions);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchMentionsByTheme = async (externalPayload?: any) => {
    try {
      const wordCloud = await getMentionsByTheme({
        ...payloadElection,
        ...externalPayload,
      });
      handleMentionsChart("elector", wordCloud);
    } catch (e) {
      console.log(e);
    } finally {
      handleChart({ type: "elector", loading: false });
    }
  };

  const fetchWordCloudGovernment = async (externalPayload?: any) => {
    try {
      const wordCloud = await getWordCloud({
        ...payloadWordCloud,
        ...externalPayload,
      });
      handleWordCloud("government", wordCloud);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingGovernament(false);
    }
  };

  const fetchWordElection = async (externalPayload?: any) => {
    try {
      const wordCloudElection = await getWordCloud({
        ...payloadElectionWordCloud,
        ...externalPayload,
      });
      handleWordCloud("elector", wordCloudElection);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadElection(false);
    }
  };

  const defaultSearch = async () => {
    if (isSearching) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      setIsSearching(false);
      setLoadingSearch(false);
      setLoadingGovernament(false);
      setLoadElection(false);
      handleChart({ loading: false });
      return;
    }

    setStartedFilters(true);
    setLoadingSearch(true);
    setLoadingGovernament(true);
    setLoadElection(true);
    setIsSearching(true);
    handleChart({ loading: true });

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const requests = [
      searchListening({ signal: abortController.signal }),
      fetchMentionsByTopic({ signal: abortController.signal }),
      fetchMentionsByTheme({ signal: abortController.signal }),
      fetchWordCloudGovernment({ signal: abortController.signal }),
      fetchWordElection({ signal: abortController.signal }),
      fetchTopMentions({ signal: abortController.signal }),
    ];

    Promise.allSettled(requests).then((results) => {
      results.forEach((result) => {
        if (result.status === "rejected") {
          console.error("Falha na requisição:", result.reason);
        }
      });
      setLoadingSearch(false);
      setLoadingGovernament(false);
      setLoadElection(false);
      setIsSearching(false);
      handleChart({ loading: false });
    });
  };

  const advancedSearch = async () => {
    const advancedPayload = {
      city: filtersDefault?.city,
      domain: filtersDefault?.source,
      sentiment: filterRaioX !== ALL && filterRaioX,
      topic_name: theme,
    };

    setLoadAdvanced(true);
    setLoadingGovernament(true);
    setLoadElection(true);

    const requests = [
      searchListening(advancedPayload),
      fetchWordCloudGovernment(advancedPayload),
      fetchWordElection(advancedPayload),
      fetchMentionsByTopic(advancedPayload),
      fetchMentionsByTheme(advancedPayload),
      fetchTopMentions(advancedPayload),
    ];

    Promise.allSettled(requests).then((results) => {
      results.forEach((result) => {
        if (result.status === "rejected") {
          console.error("Failed request:", result.reason);
        }
      });
      setLoadingGovernament(false);
      setLoadElection(false);
      setLoadAdvanced(false);
    });
  };

  const onReset = () => {
    resetAllFilters();
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <S.Wrapper>
      <CollapseBase
        title="Filtro por figura política"
        content={<FilterCandidate />}
        isActive={candidatoFilter.isActive}
        onActiveFilter={(val) => changeActive("candidate", val)}
        isOpen={isCandidateOpen}
        onToggleCollapse={handleToggleCandidateCollapse}
      />
      <CollapseBase
        title="Filtro por Politica Vox"
        content={<FilterTags topicList={topicList} />}
        isActive={topicosFilter.isActive}
        onActiveFilter={(val) => changeActive("topic", val)}
        isOpen={isPoliticaVoxOpen}
        onToggleCollapse={handleTogglePoliticaVoxCollapse}
      />
      <CollapseBase
        title="Filtro por Palavra-Chave"
        content={<FilterTopics />}
        isActive={includedExcludedFilter.isActive}
        onActiveFilter={(val) => changeActive("included", val)}
        isOpen={isKeyWordOpen}
        onToggleCollapse={handleToggleKeyWordCollapse}
      />

      <S.ActionsBox>
        <S.SaveFilterBox>
          <Button
            variant="minimal"
            onClick={handleSaveFilter}
            disabled={loadingSearch || isButtonDisabled}
          >
            Salvar busca
          </Button>

          <Button variant="minimal" onClick={handleDialogSearch}>
            Buscas salvas
          </Button>
        </S.SaveFilterBox>

        <S.SearchFilterBox>
          <Button
            size="medium"
            onClick={defaultSearch}
            disabled={isButtonDisabled}
            variant={loadingSearch ? "red" : "primary"}
          >
            {loadingSearch ? "Cancelar busca" : "Buscar"}
          </Button>

          <Button
            variant="outline"
            onClick={handleOpenDialog}
            disabled={loadingSearch || isButtonDisabled}
            size="medium"
          >
            Limpar Todos os Filtros
          </Button>
        </S.SearchFilterBox>
      </S.ActionsBox>
      {!filteredData && !loadingSearch && (
        <S.ActionsBox className="empty_param">
          <S.StartContent>Selecione um parametro</S.StartContent>
        </S.ActionsBox>
      )}
      {loadingSearch ? (
        <LoadingSearch load={loadingSearch} />
      ) : (
        <>
          {startedFilters && (
            <div style={{ marginTop: "22px" }}>
              <CollapseBase
                title="Filtros avançados"
                content={
                  <FilterBarVox
                    onSearch={advancedSearch}
                    inLoading={startedFilters}
                  />
                }
                isActive={activeFiltersBar}
                onActiveFilter={(val) => handleActiveAdvancedFilters(val)}
                isOpen={activeFiltersBar}
                onToggleCollapse={() =>
                  handleActiveAdvancedFilters(!activeFiltersBar)
                }
              />
              {loadAdvanced ? (
                <LoadingSearch load={loadAdvanced} />
              ) : (
                <ListeningCity
                  data={filteredData}
                  loadingSearch={loadingSearch}
                  loadGovernament={loadGovernament}
                  loadElection={loadElection}
                />
              )}
            </div>
          )}
        </>
      )}

      <SaveSearch open={dialogSearch} onClose={() => setDialogSearch(false)} />
      <SelectFilter
        open={dialogListFilter}
        onClose={() => setDialogListFilter(false)}
      />

      <ConfirmAction
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={onReset}
        textAction="Deseja realmente limpar todos os filtros?"
        buttonRemove
        withConfirmation={false}
        closeText="Limpar"
      />
    </S.Wrapper>
  );
};
