import { getTimeLine } from "componentsNew/CockpitComponents/hooks/useData";
import { Campaign } from "componentsNew/CockpitComponents/types/validVotesTypes";
import ProgressBar from "componentsNew/ProgressElection";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import calculateDaysUntil from "utils/dateUtils";

export const SectionTimeLine = ({ isCockpit = false }) => {
  const [timeCardStart, setTimeCardStart] = useState({} as Campaign);

  useEffect(() => {
    fetchValidVotes();
  }, []);

  const fetchValidVotes = async () => {
    const res = await getTimeLine();

    if ("message" in res) {
      toast.error(res.message);
    } else {
      setTimeCardStart(res);
    }
  };

  const daysUntilElectionForInitialDate = calculateDaysUntil(
    timeCardStart?.initial_date,
  );
  const daysUntilElectionForPreCampaign = calculateDaysUntil(
    timeCardStart?.pre_campaign_date,
  );
  const daysUntilElectionForCampaign = calculateDaysUntil(
    timeCardStart?.campaign_date,
  );
  const daysUntilElectionForElectionFund = calculateDaysUntil(
    timeCardStart?.election_fund_date,
  );

  const currentDate = new Date().toISOString().split("T")[0];
  const daysUntilElection = calculateDaysUntil(currentDate);

  const data = [
    {
      position: daysUntilElectionForInitialDate,
      color: "#E9E9F0",
      label: "início",
      top: false,
    },
    {
      position: daysUntilElectionForPreCampaign,
      color: "#00E23F",
      label: "pré campanha",
      top: true,
    },
    {
      position: daysUntilElectionForCampaign,
      color: "#FFC000",
      label: "campanha",
      top: false,
    },
    {
      position: daysUntilElectionForElectionFund,
      color: "#D70708",
      label: "fundo eleitoral",
      top: true,
    },
  ];

  return (
    <ProgressBar
      pointers={data}
      progress={daysUntilElection}
      daysToElection={daysUntilElectionForInitialDate}
      width="100%"
      isCockpit={isCockpit}
    />
  );
};
