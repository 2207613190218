import { useState, useEffect, useRef } from 'react'
import { debounce } from 'lodash'
import { api } from '../../services/apiService'
import { ISetGoals } from '../../components/SetGoals/ISetGoals'

const useGoalsDonations = () => {
  const [dataValue, setDataValue] = useState<ISetGoals | undefined>(undefined)
  const updateDataDebouncedRef = useRef<ReturnType<typeof debounce>>()

  const handleChange = (key: string, value: string) => {
    setDataValue((prev: any) => ({ ...prev, [key]: value }))
    const updatedGoals = { ...dataValue, [key]: value }
    updateDataDebouncedRef.current?.(updatedGoals)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.get<ISetGoals[]>('/political-trajectory/donations/')
        setDataValue(data[0])
      } catch (error) {
        console.error('Error fetching data', error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    updateDataDebouncedRef.current = debounce(async (updatedGoals: ISetGoals) => {
      if (!dataValue) return

      try {
        const { data } = await api.patch<ISetGoals>(`/political-trajectory/donations/${dataValue.id}/`, updatedGoals)
        setDataValue(data)
      } catch (error) {
        console.error('Error updating data', error)
      }
    }, 500)
  }, [dataValue?.id])

  return { dataValue, handleChange }
}

export default useGoalsDonations
