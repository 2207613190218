import React from "react";
import Chart from "react-apexcharts";
import Spin from "componentsNew/Spin";
import { ICandidateType } from "componentsNew/CockpitComponents/types/adversaryTypes";
import AdversaryFilterCard from "componentsNew/CockpitComponents/components/AdversaryFilterCard";
import * as S from "./styles";

interface IVolumeOfMentionsChart {
  chartType: "line" | "area";
  adversaryList: ICandidateType[];
  selectedAdversary: ICandidateType[];
  selectedAdversaryPersonIds: string[];
  avatars: { [key: string]: string };
  onToggleAdversary: (candidate: ICandidateType, isActive: boolean) => void;
  selectedSocialNetworks: string[];
  dataVolumeOfMentions: any;
  datesVolumeOfMentions: string[];
  candsVolumeOfMentions: string[];
  totalsVolumeOfMentions: { [key: string]: number };
  loadingVolumeOfMentions: boolean;
  errorVolumeOfMentions: string | null;
}

const VolumeOfMentionsChart: React.FC<IVolumeOfMentionsChart> = ({
  chartType,
  adversaryList,
  selectedAdversary,
  selectedAdversaryPersonIds,
  avatars,
  onToggleAdversary,
  selectedSocialNetworks,
  dataVolumeOfMentions,
  datesVolumeOfMentions,
  candsVolumeOfMentions,
  totalsVolumeOfMentions,
  loadingVolumeOfMentions,
  errorVolumeOfMentions,
}) => {
  if (loadingVolumeOfMentions) {
    return <Spin />;
  }

  if (
    errorVolumeOfMentions ||
    !dataVolumeOfMentions ||
    dataVolumeOfMentions.length === 0
  ) {
    return (
      <S.ErrorMessage>
        {errorVolumeOfMentions || "Nenhum dado disponível"}
      </S.ErrorMessage>
    );
  }

  const sortedData =
    dataVolumeOfMentions.sort(
      (a: any, b: any) => b.total_mentions - a.total_mentions,
    ) || [];

  const series = sortedData.map((candidate: any) => ({
    name: candidate.candidate_name,
    data: candidate.coordinates.map((coord: any) => coord.y),
  }));

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: chartType === "line" ? "area" : chartType,
      stacked: chartType === "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: datesVolumeOfMentions,
      labels: {
        show: true,
        style: {
          colors: "#E9E9F0",
          fontFamily: "Roboto",
          fontSize: "10px",
          fontWeight: 500,
        },
      },
      tooltip: {
        enabled: false,
      },
      tickAmount: 10,
      sorted: true,
    },
    yaxis: {
      show: true,
      labels: {
        formatter: (val: number) =>
          val.toLocaleString("pt-BR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        style: {
          colors: "#4f4f4f",
          fontFamily: "Roboto",
          fontSize: "10px",
          fontWeight: 500,
        },
      },
    },
    colors: sortedData.map((candidate: any) => {
      const matchingAdversary = selectedAdversary.find(
        (adversary) => adversary.candidate_id === candidate.candidate_id,
      );
      return matchingAdversary?.color || "#FFFFFF";
    }),
    fill: {
      opacity: 1,
      type: "solid",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    tooltip: {
      theme: "dark",
      style: {
        fontSize: "12px",
        fontFamily: "Roboto",
      },
      x: {
        format: "dd/MMM",
      },
      y: {
        formatter: (val: number, { seriesIndex, dataPointIndex, w }) => {
          const total = w.globals.stackedSeriesTotals[dataPointIndex];
          const percentage = ((val / total) * 100).toFixed(0);
          const formattedVal = val.toLocaleString("pt-BR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
          return `${formattedVal} menções (${percentage}%)`;
        },
      },
      shared: true,
      intersect: false,
    },
    legend: {
      show: false,
      position: "bottom",
      labels: {
        colors: "#E9E9F0",
        useSeriesColors: false,
      },
    },
  };

  const sortedAdversaryList = selectedAdversary.sort((a, b) => {
    const aData = dataVolumeOfMentions.find(
      (item: any) => item.candidate_id === a.candidate_id,
    );
    const bData = dataVolumeOfMentions.find(
      (item: any) => item.candidate_id === b.candidate_id,
    );
    return (bData?.total_mentions || 0) - (aData?.total_mentions || 0);
  });

  return (
    <S.Container>
      <S.Content>
        <S.ChartContainer>
          <Chart
            options={options}
            series={series}
            type={chartType}
            height="360px"
          />
        </S.ChartContainer>
        <S.AdversaryContainer>
          <S.AdversaryList>
            {sortedAdversaryList.map((adversary) => (
              <AdversaryFilterCard
                key={adversary.id}
                candidate={adversary}
                isActive={selectedAdversaryPersonIds.includes(adversary.id)}
                onToggle={onToggleAdversary}
                borderColor={adversary.color}
                avatarUrl={avatars[adversary.candidate_id || ""]}
                isClickable={false}
              />
            ))}
          </S.AdversaryList>
        </S.AdversaryContainer>
      </S.Content>
    </S.Container>
  );
};

export default VolumeOfMentionsChart;
