import { FaTools } from 'react-icons/fa'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75%;
  border: 1px solid #ffc000;
  border-radius: 16px;
  margin-top: 16px;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 64px;
`

export const Icon = styled(FaTools)`
  font-size: 140px;
  color: white;
`

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px;
  gap: 24px;
`
