import { api , apiPlains, apiUser } from 'services/apiService'

export const getUserList = async () => {
  try {
    const { data } = await api.get('/user/retrieve-affiliation/')

    return data?.results
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getPlans = async () => {
  try {
    const { data } = await api.get('/user/plan')
    return data;
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getProfiles = async () => {
  try {
    const { data } = await api.get('/user/profile')
    return data;
  } catch (error) {
    console.log(error)
    return []
  }
}

export const createUser = async (userData: any,
                                 affilated_person: number) => {
  return await apiUser.post('/user/create-person', {
      ...userData,
      password: userData.social_security_number.replace(/\D/g, ''),
      affilated_person,
      is_team: true
    })
}

export const getUser = async (userId: number) => {
  try {

    const response = await apiUser.get(`/user/${userId}/`)

    return response.data;

  } catch (error: any) {
    console.log('erros message: ', error)
    return {success: false}
  }
}

export const deleteUser = async (userId: number) => {
  try {

    const response = await apiUser.delete(`/user/${userId}/`)

    return response.data;

  } catch (error: any) {
    console.log('erros message: ', error)
    return {success: false}
  }
}

export const updateUser = async (userData: any, userId: number) => {
  try {
    const userResponse = await getUser(userId);

    console.log("UserResponse: ", userResponse);
    let updateData = {
      id: userResponse.id,
      plan: userData.plan,
      date_of_birth: userData.date_of_birth,
      social_security_number: userData.social_security_number,
      name: userData.name,
      phone: userData.phone,
      email: userData.email,
      profile:{
        id: userData.profile
      },
      user:{
        id: userResponse.user.id,
        last_name: userData.last_name,
        first_name: userData.name,

        email: userData.email,
        username: userData.email
      }
    }

    if(userData.email !== userResponse.user?.username){
      updateData.user.username = userData.email;
    }else delete updateData.user.username;

    const response = await apiUser.patch(`/user/${userId}/`, updateData)

    return response.data;

  } catch (error: any) {
    console.log('erros message: ', error)
    return {success: false}
  }
}
