import React from "react";
import { Card } from "antd";
import { TextField } from "@mui/material";
import useFormattedUserData from "../../hooks/useFormattedUserData";

const PersonalData: React.FC = () => {
  const userData = useFormattedUserData();

  return (
    <Card style={{ backgroundColor: "#161617", border: "none" }}>
      <p
        style={{
          color: "#E0E0E9",
          fontWeight: "500",
          marginTop: "-8px",
          marginBottom: "16px",
        }}
      >
        Dados Pessoais
      </p>
      <div
        style={{
          gap: "8px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <TextField
          label="Nome"
          value={userData.name}
          disabled
          style={{ width: "25%" }}
          InputProps={{
            classes: {
              disabled: "cursor-not-allowed",
            },
          }}
        />
        <TextField
          label="Sobrenome"
          value={userData.surname}
          disabled
          style={{ width: "25%" }}
          InputProps={{
            classes: {
              disabled: "cursor-not-allowed",
            },
          }}
        />
        <TextField
          label="CPF"
          value={userData.cpf}
          disabled
          style={{ width: "25%" }}
          InputProps={{
            classes: {
              disabled: "cursor-not-allowed",
            },
          }}
        />
        <TextField
          label="Data de Nascimento"
          value={userData.birthDate}
          disabled
          style={{ width: "25%" }}
          InputProps={{
            classes: {
              disabled: "cursor-not-allowed",
            },
          }}
        />
      </div>
    </Card>
  );
};

export default PersonalData;
