import { apiMentions } from 'services/apiService'
import { buildQueryString } from 'utils/buildQueryString'

const BASE_MENTIONS = `/mentions-domain-counter`
const BASE_MAP = `/mentions-map`

interface filterMapTypes {
  city?: string
  candidate_id?: string
  sentiment?: string
  initial_date?: string
  final_date?: string
  negative_words?: string[]
  positive_words?: string[]
  sub_topics_ids?: string[]
  domain?: string
  state?: string
  parent_name?: string
  topic_name?: string
}

interface mentionByThemeTypes {
  cd_city?: string
  id_cand?: string
  mentions?: string
  date_start?: string
  sentiment?: string
  end_date?: string
  negative_words?: string[]
  positive_words?: string[]
  sub_topics_ids?: string[]
  domain?: string
}

export const getDomains = async (params: filterMapTypes): Promise<{ count: number; domain: string }[]> => {
  try {
    const url = `/mentions-domain-counter/${buildQueryString(params)}`
    const { data } = await apiMentions.get(url)

    const formattedData = data.mentions_list?.map((pointer: any) => ({
      value: pointer?.full_domain,
      count: pointer?.total_count,
      label: pointer?.domain,
    }))

    /*     const formattedData = data.mentions_list
      ?.map((pointer: any) => ({
        ...pointer,

        category_list: categoryList,
        total_count,
      }))
      .filter((point: any) => point.latitude !== 'None') */

    return formattedData
  } catch (e) {
    console.error('Erro na requisição:', e)
    return []
  }
}

export async function getAdvancedMap(params: filterMapTypes) {
  try {
    const url = `/mentions-map/${buildQueryString(params)}`
    const { data } = await apiMentions.get(url)
    /* const groupedData = data.mentions_list?.reduce((acc: Record<string, any>, pointer: any) => {
      const key = pointer.region
      if (!acc[key]) {
        acc[key] = { ...pointer, count: pointer.total_count, label: pointer?.city }
      } else {
        acc[key].count += pointer.total_count
      }
      return acc
    }, {}) */

    const groupedData = data.mentions_list?.map((item: any) => ({
      ...item,
      label: item?.city,
      count: item?.total_count,
    }))

    const formattedData = Object.values(groupedData)
    return formattedData
  } catch (e) {
    console.error('Erro na requisição:', e)
    return []
  }
}

export const getMentionsByTheme = async (params: mentionByThemeTypes): Promise<any> => {
  try {
    const { data } = await apiMentions.get(`/mentions-by-theme/${buildQueryString(params)}`)
    const themes = data?.mentions_by_theme
    const formattData = themes?.map((theme: any) => ({
      parent_name: theme?.parent_name,
      count: theme?.topic_count,
      label: theme?.topic,
    }))

    return formattData
  } catch (e) {
    console.log(e)
    return []
  }
}

export const getMentionsByTopic = async (params: mentionByThemeTypes): Promise<any> => {
  try {
    const { data } = await apiMentions.get(`/mentions-by-topic/${buildQueryString(params)}`)
    const topic_list = data?.mentions_by_topic
    const formattData = topic_list?.map((theme: any) => ({
      parent_name: theme?.parent_name,
      count: theme?.topic_count,
      label: theme?.topic,
    }))

    return formattData
  } catch (e) {
    console.log(e)
    return []
  }
}

export function formatLocation({ state, city }: { state: string; city: string }) {
  const countryCode = 'BRA'

  return `${countryCode}.${state.toUpperCase()}.${city}`
}
