import styled from 'styled-components'

export const Wrapper = styled.main`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 25px;
`

export const AvatarBox = styled.div`
  width: 39px;
  height: 39px;
  border-radius: 50%;
  background: #111111;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  svg {
    color: #e9e9f08f;
  }
`

export const Avatar = styled.img`
  width: 100%;
  object-fit: cover;
`

export const LabelContent = styled.div``

export const Label = styled.h3`
  font-size: 16px;
  color: #e9e9f0;
  font-weight: 500;
  display: flex;
  aling-items: center;
  gap: 8px;

  svg {
    margin-top: 5px;
  }
`

export const Description = styled.p`
  font-size: 12px;
  color: #e9e9f0;
  font-weight: 400;
`
