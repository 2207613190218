import React from "react";
import Chart from "react-apexcharts";
import { Avatar } from "antd";
import Spin from "componentsNew/Spin";
import AdversaryFilterCard from "componentsNew/CockpitComponents/components/AdversaryFilterCard";
import { IProcessedGoogleTrendsResponse } from "componentsNew/CockpitComponents/hooks/useGoogleTrends";
import { ICandidateType } from "componentsNew/CockpitComponents/types/adversaryTypes";
import { useDrawerState } from "global/DrawerContext";
import * as S from "./styles";

interface IGoogleTrendsChart {
  selectedAdversary: ICandidateType[];
  selectedTime: "past-hour" | "past-day" | "past-7-days" | "past-90-days";
  avatars: { [key: string]: string };
  onToggleAdversary: (candidate: ICandidateType, isActive: boolean) => void;
  viewMode: "ranking" | "score";
  data: IProcessedGoogleTrendsResponse | null;
  loading: boolean;
  error: string | null;
}
const GoogleTrendsChart: React.FC<IGoogleTrendsChart> = ({
  selectedAdversary,
  selectedTime,
  avatars,
  onToggleAdversary,
  viewMode,
  data,
  loading,
  error,
}) => {
  const { isOpenMenus, openDrawer } = useDrawerState();

  if (loading) {
    return <Spin />;
  }

  if (error || !data || data.ranking.length === 0) {
    return <S.ErrorMessage>{error || "Nenhum dado disponível"}</S.ErrorMessage>;
  }

  const sortedAdversaries = [...selectedAdversary].sort((a, b) => {
    const totalA = data.totals[a.candidate_name?.toUpperCase() || ""] || 0;
    const totalB = data.totals[b.candidate_name?.toUpperCase() || ""] || 0;

    if (viewMode === "score") {
      return totalB - totalA;
    } else {
      const rankA =
        data.ranking[0]?.candidates.find(
          (c) =>
            c.candidate.trim().toLowerCase() ===
            a.candidate_name?.trim().toLowerCase(),
        )?.rank || 0;

      const rankB =
        data.ranking[0]?.candidates.find(
          (c) =>
            c.candidate.trim().toLowerCase() ===
            b.candidate_name?.trim().toLowerCase(),
        )?.rank || 0;

      return rankA - rankB;
    }
  });

  const categories = data.ranking.map((day) =>
    selectedTime === "past-hour" || selectedTime === "past-day"
      ? day.hour
      : day.day,
  );

  const series = sortedAdversaries.map((candidate) => {
    const dataPoints = data.ranking.map((day) => {
      const candidateData = day.candidates.find(
        (c) =>
          c.candidate.trim().toLowerCase() ===
          candidate.candidate_name?.trim().toLowerCase(),
      );
      const value =
        viewMode === "ranking" && candidateData
          ? -candidateData?.rank || 0
          : candidateData?.score || 0;

      return value;
    });

    return {
      name: candidate.candidate_name,
      data: dataPoints,
    };
  });

  const showToolbar = isOpenMenus || openDrawer;

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: showToolbar ? false : true,
        tools: {
          download: false,
          reset: true,
          zoom: false,
        },
      },
    },
    grid: {
      show: false,
    },
    stroke: {
      curve: "smooth",
      width: viewMode === "ranking" ? 8 : 4,
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: "#E9E9F0",
          fontFamily: "Roboto",
          fontSize: "10px",
          fontWeight: 500,
        },
        offsetX: 5,
      },
      tickAmount: 10,
      sorted: true,
    },
    yaxis: {
      show: viewMode === "ranking" ? false : true,
      labels: {
        formatter: (val: number) =>
          viewMode === "ranking" ? `${-val}` : `${val}`,
      },
      tickAmount: 5,
    },
    colors: sortedAdversaries.map((candidate) => candidate.color),
    fill: {
      opacity: 0.5,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: "dark",
      x: {
        show: true,
      },
      y: {
        formatter: (val: number) =>
          viewMode === "ranking" ? `${-val}` : `${val.toLocaleString("pt-BR")}`,
      },
      marker: {
        show: true,
      },
    },
    legend: {
      show: false,
    },
  };

  const chartHeight =
    sortedAdversaries.length <= 2
      ? 160
      : 160 + (sortedAdversaries.length - 2) * 40;

  return (
    <S.Container>
      {/*  <S.AvatarContainer>
        {selectedAdversary.map((candidate, index) => (
          <S.AvatarWrapper
            key={candidate.id}
            style={{
              top: `${index * 42}px`,
            }}
          >
            <Avatar
              src={avatars[candidate.candidate_id || ""]}
              alt={candidate.candidate_name}
            />
          </S.AvatarWrapper>
        ))}
      </S.AvatarContainer> */}
      <S.ChartContainer>
        <Chart
          options={options}
          series={series}
          type="line"
          height={chartHeight}
        />
      </S.ChartContainer>
      <S.AdversaryContainer>
        <S.AdversaryList>
          {sortedAdversaries.map((candidate) => (
            <AdversaryFilterCard
              key={candidate.id}
              candidate={candidate}
              isActive
              onToggle={onToggleAdversary}
              borderColor={candidate.color}
              avatarUrl={avatars[candidate.candidate_id || ""]}
              isClickable={false}
            />
          ))}
        </S.AdversaryList>
      </S.AdversaryContainer>
    </S.Container>
  );
};

export default GoogleTrendsChart;
