import React, { useRef, useState } from "react";
import { RiArrowDropDownFill } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import Text from "componentsNew/Text";
import { defaultFilters } from "componentsNew/Helper/DiagnosisFilter/utils/defaultFilters";
import { useCandidates } from "hooks/useCandidates";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useUser } from "global/UserContext";
import { useDiagnosisFilter } from "global/DiagnosisFilterContext";
import { ContainerButton, ContainerDrop, TitleName } from "./styles";

interface Candidate {
  name: string;
  position: string;
  party_acronym: string;
  locality: string;
}

const SelectCandidatesCustom = () => {
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  const { setFilters } = useDiagnosisFilter();

  const { candidates, selectedCandidate, setSelectedCandidate } =
    useCandidates();
  const ref = useRef<HTMLHeadingElement>(null);

  const { pathname } = useLocation();

  const isCandidateOptionsRoute = (pathname: any) => {
    const baseRoutes = [
      "/dashboard/political-vox",
      "/dashboard/sponsored-applications",
      "/dashboard/all-applications",
    ];

    return baseRoutes.some((baseRoute) => pathname.startsWith(baseRoute));
  };

  const candidateOptions = isCandidateOptionsRoute(pathname) && user?.person;

  useOnClickOutside(ref, () => setOpen(false));
  const handleSelectCandidate = (candidate: any) => {
    setSelectedCandidate(candidate);
    setFilters(defaultFilters);
    localStorage.removeItem("diagnosisFilters");
    setOpen(false);
  };

  const handleLocalCandidates = (local: string) => {
    const targetLocality = local || "BRA.SP.Sao Paulo";
    const parts = targetLocality.split(".");
    const object = {
      country: parts[0],
      state: parts[1],
      city: parts?.slice(2).join("."),
    };
    return object;
  };

  const sortedCandidates = candidates?.sort((a: Candidate, b: Candidate) =>
    a.name.localeCompare(b.name),
  );

  return (
    <>
      {candidateOptions ? (
        <TitleName>{candidateOptions?.name}</TitleName>
      ) : (
        <ContainerButton onClick={() => setOpen(!open)}>
          <Text size="small" bold className="title_name">
            {selectedCandidate?.name}
          </Text>
          <RiArrowDropDownFill
            className="wrapper-icon"
            size={24}
            color="white"
          />
        </ContainerButton>
      )}

      {open && (
        <ContainerDrop ref={ref}>
          {sortedCandidates?.map((candidate: Candidate, index: number) => {
            const local = handleLocalCandidates(candidate?.locality);

            return (
              <div
                className="row-drop"
                onClick={() => handleSelectCandidate(candidate)}
              >
                <Text size="xSmall">
                  {`${index + 1}. ${candidate?.name || "Nome Indisponível"}`} |{" "}
                  {candidate?.position || "Sem Cargo"} |{" "}
                  {candidate?.party_acronym || "Sem Partido"} |{" "}
                  {local?.city || "Cidade Não Informada"} |{" "}
                  {local?.state || "Estado Não Informado"}
                </Text>
              </div>
            );
          })}
        </ContainerDrop>
      )}
    </>
  );
};

export default SelectCandidatesCustom;
