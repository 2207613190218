import styled from 'styled-components'

export const WrapperText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
`

export const TabText = styled.p<{ withTitle?: boolean; disabled?: boolean }>`
  display: block;
  white-space: normal;
  color: #e9e9f0;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  line-height: 14px;
  width: ${({ withTitle }) => (withTitle ? 'fit-content' : '108px')};
  //width: fit-content;
  //max-width: 96px;
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
`

export const ContentTab = styled.div`
  flex: 1;
  margin-bottom: 20px;
`
