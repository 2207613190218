import { faker } from '@faker-js/faker'
import {
  CompetitiveTheme,
  SelectedCompetitiveTheme,
  CompetitiveThemeListResponse,
  SelectedCompetitiveThemeListResponse,
  CreateCompetitiveThemePayload,
  CreatedCompetitiveThemeResponse,
  AcceptWizardParams,
  CompetitiveWizard,
  BaseResponseApi,
  GetCompetitiveWizardResponse,
  ListByTopicTypes,
  RandomSelectOption,
} from '../types/COMPETITIVE_TYPES'

const { animal, date, number, music } = faker

export const TOPIC_NAMES = ['Forças (S)', 'Fraqueza (W)']
export const TOPIC_POSITIVE = ['Forças (S)', 'Oportunidades (O)']
export const TOPIC_NEGATIVE = ['Fraqueza (W)', 'Ameaças (T)']

export const generateBaseResponse = <T>(results: T[]): BaseResponseApi & { results: T[] } => {
  return {
    count: results.length,
    next: faker.internet.url(),
    previous: faker.internet.url(),
    results,
  }
}

const generateProfileList = () => {
  const id = faker.string.uuid()
  const name = faker.person.fullName()
  const avatar = faker.image.avatarLegacy()
  return {
    id,
    label: name,
    value: id,
    name,
    avatar,
    category: faker.person.jobTitle(),
  }
}

const generateCandidate = () => {
  const name = faker.person.fullName()
  const avatar = faker.image.avatarLegacy()
  return {
    id: faker.number.int({ max: 100 }),
    avatar,
    category: faker.person.jobTitle(),
    name,
    disabled: true,
    selected_profile: {
      label: name,
      value: faker.string.uuid(),
      avatar,
    },
    profile_list: Array.from({ length: 20 }, () => generateProfileList()),
  }
}

const generateRandomCompetitiveTheme = (): CompetitiveTheme => ({
  id: number.int({ max: 50 }),
  name: animal.dog(),
  created: date.anytime(),
  updated: date.anytime(),
})

const generateRandomCompetitiveWizard = (): CompetitiveWizard => {
  const topic = faker.helpers.arrayElement(TOPIC_NAMES)
  const avatar = faker.image.avatarLegacy()
  return {
    id_cand: faker.string.uuid(),
    id: number.int(),
    theme_name: music.genre(),
    topic,
    avatar,
    label: faker.person.gender(),
    value: faker.string.uuid(),
    status: 'pending',
    created: date.anytime(),
    updated: date.anytime(),
    person: number.int(),
    theme: number.int(),
    topic_name: topic,
    profile_name: faker.person.fullName(),
    is_positive: faker.datatype.boolean({ probability: 0.5 }),
  }
}

export const MOCK_Competitive_THEME_LIST: CompetitiveTheme[] = TOPIC_NAMES.map(() => generateRandomCompetitiveTheme())

const Competitive_WIZARDS_ARRAY = Array.from({ length: 30 }).map(() => generateRandomCompetitiveWizard())

export const MOCK_Competitive_WIZARDS: GetCompetitiveWizardResponse = generateBaseResponse(Competitive_WIZARDS_ARRAY)

const generateRandomSelectOption = (topic_name: string): RandomSelectOption => {
  const theme_name = faker.animal.dog()
  return {
    id: faker.number.int({ max: 50 }),
    topic: faker.number.int(),
    name: theme_name,
    theme_name,
    created: faker.date.anytime(),
    updated: faker.date.anytime(),
    label: topic_name,
    value: faker.number.int({ max: 100 }),
    topic_name,
    active: false,
  }
}

const generateRandomCompetitiveSelectionPositiveItem = (topic: string): ListByTopicTypes => {
  const id = number.int({ max: 100 })
  return {
    id,
    topic,
    topic_name: topic,
    selection_list: Array.from({ length: 20 }).map(() => generateRandomSelectOption(topic)),
    selected_values: [],
    theme_list: Array.from({ length: 8 }),
  }
}

export const MOCK_Competitive_SELECTION_POSITIVE: ListByTopicTypes[] = TOPIC_NAMES.map(topic =>
  generateRandomCompetitiveSelectionPositiveItem(topic)
)

export const MOCK_Competitive_SELECTION_NEGATIVE: ListByTopicTypes[] = TOPIC_NEGATIVE.map(topic =>
  generateRandomCompetitiveSelectionPositiveItem(topic)
)

const generateRandomCompetitiveSelectionResponse = (topic: string): SelectedCompetitiveTheme => {
  const id = number.int()
  const theme_name = animal.dog()
  return {
    id,
    theme_name,
    is_recommended: true,
    is_listening: true,
    topic,
    topic_name: topic,
    created: date.anytime(),
    updated: date.anytime(),
    person: number.int(),
    theme: number.int(),
  }
}

export const MOCK_Competitive_SELECTION_RESPONSE_THEME = TOPIC_NAMES.map(topic =>
  generateRandomCompetitiveSelectionResponse(topic)
)

export const MOCK_Competitive_THEME_LIST_RESPONSE: CompetitiveThemeListResponse = {
  count: MOCK_Competitive_THEME_LIST.length,
  next: 'http://example.com',
  previous: 'http://example.com',
  results: MOCK_Competitive_THEME_LIST,
}

export const MOCK_Competitive_SELECTION_RESPONSE: SelectedCompetitiveThemeListResponse = {
  count: MOCK_Competitive_SELECTION_POSITIVE.length,
  next: 'http://example.com',
  previous: 'http://example.com',
  results: MOCK_Competitive_SELECTION_RESPONSE_THEME,
}

export const MOCK_CREATE_Competitive_THEME_PAYLOAD: CreateCompetitiveThemePayload = {
  is_recommended: true,
  is_listening: true,
  topic: 'Politica',
  person: number.int(),
  theme: number.int(),
}

export const MOCK_CREATED_Competitive_THEME_RESPONSE: CreatedCompetitiveThemeResponse = {
  id: number.int(),
  theme_name: animal.dog(),
  is_recommended: true,
  is_listening: true,
  topic: 'Politica',
  created: date.anytime(),
  updated: date.anytime(),
  person: number.int(),
  theme: number.int(),
}

export const MOCK_ACCEPT_WIZARD_PARAMS: AcceptWizardParams = {
  wizard_id: number.int(),
  status: 'approved',
}

export const MOCK_PROFILE_LIST = Array.from({ length: 20 }, () => generateProfileList())

export const MOCK_COMPETITIVE_CANDIDATE_LIST = Array.from({ length: 2 }, () => generateCandidate())

export const DefaultCandidate = TOPIC_NAMES.map(item => ({
  name: item,
  theme_list: Array.from({ length: 8 }),
}))

export const CANDIDATEMOCK = [
  {
    id: 235235,
    theme_name: 'Federacoes',
    file: '#',
    id_cand: '8114924764982801640222',
    cand_name: 'IVONETE MACEDO',
    party: 'PTB',
    topic: 'weaknesses',
    status: 'pending',
    created: '2024-01-28T20:08:13.199934Z',
    updated: '2024-01-28T20:08:13.199934Z',
    person: 87,
    theme: 1,
    topic_name: 'Fraqueza (W)',
    name: 'Federacoes',
    is_recommended: true,
    value: 1,
    label: 'IVONETE MACEDO',
    nm_candidato: 'IVONETE MACEDO',
    disabled: true,
    topicList: TOPIC_NAMES.map(item => ({
      name: item,
      theme_list: Array.from({ length: 8 }),
    })),
  },
  {
    id: 12312,
    theme_name: 'Federacoes',
    file: '#',
    id_cand: '811492476498280346346',
    cand_name: 'IVONETE MACEDO',
    party: 'PTB',
    topic: 'weaknesses',
    status: 'pending',
    created: '2024-01-28T20:08:13.199934Z',
    updated: '2024-01-28T20:08:13.199934Z',
    person: 87,
    theme: 1,
    topic_name: 'Fraqueza (W)',
    name: 'Federacoes',
    is_recommended: true,
    value: 1,
    label: 'IVONETE MACEDO',
    nm_candidato: 'IVONETE MACEDO',
    disabled: true,
    topicList: TOPIC_NAMES.map(item => ({
      name: item,
      theme_list: Array.from({ length: 8 }),
    })),
  },
]
