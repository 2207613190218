import React, { useEffect, useState } from 'react'
import Spin from 'componentsNew/Spin'
import { useCandidates } from 'hooks/useCandidates'
import Card from '../../../../componentsNew/Card'
import TableMark from './TableMark'
import { ContainerTable, RowTable } from './styles'
import Text from '../../../../componentsNew/Text'
import { apiPredeterminedCandidate } from '../../../../services/apiService'

const VoterProfile = () => {
  const [elector, setElector] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const { selectedCandidate } = useCandidates()
  const target_locality = selectedCandidate?.locality || 'BRA.SP.Sao Paulo'
  const getElector = async () => {
    setLoading(true)
    try {
      const { data } = await apiPredeterminedCandidate.get(`/best-candidate/elector/?city=${target_locality}`)

      console.log('best-candidate-elector', data)

      const voterData = Object.entries(data?.values).map(([role, value]) => ({
        role,
        value,
      }))
      setElector(voterData)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getElector()
  }, [])

  return (
    <Card title="Perfil do Eleitor">
      <ContainerTable>
        {loading ? (
          <Spin spinning />
        ) : (
          elector.map((voterCategory: any) => (
            <RowTable key={voterCategory.role}>
              <div className="title-card">
                <Text size="xSmall">{voterCategory.role}</Text>
              </div>
              {voterCategory.value.map((voterDetail: any, index: any) => (
                <TableMark
                  key={`${voterCategory.role}-${index}`}
                  value={voterDetail.message}
                  isActive={voterDetail.is_active}
                  intensity={voterDetail.intensity}
                />
              ))}
            </RowTable>
          ))
        )}
      </ContainerTable>
    </Card>
  )
}

export default VoterProfile
