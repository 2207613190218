import styled from 'styled-components'

export const ContainerMaster = styled.div`
  background: #161717;
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 8px;
  .contante {
    height: 100%;
    padding: 16px;
  }
`
export const Container = styled.div`
  display: flex;
  background: #161717;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
`
export const Divisor = styled.div<any>`
  width: 8px;
  height: 100%;
  background: ${({ isActive }) => isActive && '#ffc000'};
`

export const Wrapper = styled.div<any>`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 8px;
  justify-content: space-between;

  .card-title-primary {
    display: flex;
    width: 350px;
    gap: 8px;
  }

  .card-text {
    display: flex;
    width: 400px;
    margin-right: -64px;
  }
  .card-itens {
    width: 150px;
  }

  svg {
    cursor: pointer;
    transform: rotate(${({ isActive }) => (isActive ? 0 : 180)}deg);
    transition: transform 0.3s ease;
    color: #ffc000;
  }
`
