import React, { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { getItemStorage } from 'utils/persistenceUtils'
import { AUTH_TOKEN } from 'utils/constants'

interface PrivateRouteProps {
  children: ReactElement | null
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const auth = getItemStorage(AUTH_TOKEN)
  return auth ? children ?? <></> : <Navigate to="/login" replace />
}
