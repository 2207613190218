import React, { useEffect, useState } from 'react'
import * as powerbiClient from 'powerbi-client'
import * as models from 'powerbi-models'
import Spin from 'componentsNew/Spin'
import { apiPBI } from '../../services/apiService'
import ErrorDisplay from './Components/ErrorDisplay'
import { useDiagnosisFilter } from '../../global/DiagnosisFilterContext'
import { useCandidateDiagnosticFilterContext } from '../../global/CandidateDiagnosticFilterContext'
import './PBIDash.css'

interface IPBIDash {
  reportName: string
}

interface IResponseState {
  isLoading: boolean
  error: boolean
  data?: any
}

const createBasicFilter = (table: string, column: string, values: any[]): models.IBasicFilter => {
  return {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table,
      column,
    },
    operator: 'In',
    values: values || [],
    filterType: 1,
  }
}

const mapFilters = (filters: any): models.IBasicFilter[] => {
  console.log('Mapeando filtros:', filters)
  return [
    createBasicFilter('Boletim_de_Urna_2018-2022', 'ANO_ELEICAO', filters?.year),
    createBasicFilter('Boletim_de_Urna_2018-2022', 'DS_CARGO_PERGUNTA', filters?.position),
    createBasicFilter('db_Turno', 'NM_TURNO', filters?.round),
    createBasicFilter('db_municípios', 'db_estados.uf', filters?.state),
    createBasicFilter('db_municípios', 'Cidade', filters?.city),
    createBasicFilter('public local_votacao', 'nm_bairro', filters?.neighborhood),
  ]
}

const reportsWithFilters = [
  // Voter Diagnosis
  'voter_pofile',
  // 'city_indexes',
  'x_ray_votes',

  // Elections Diagnosis
  'election_results',
  // 'election_history',
  // 'x_ray_campaigns',
  // 'campaing_panel',
]

const PBIDash: React.FC<IPBIDash> = ({ reportName }) => {
  const { filters } = useDiagnosisFilter()
  const { defaultFilters } = useCandidateDiagnosticFilterContext()
  const [currentFilters, setCurrentFilters] = useState(defaultFilters)

  useEffect(() => {
    setCurrentFilters(defaultFilters)
  }, [defaultFilters])

  useEffect(() => {
    const isFiltersInitialized = Object.values(filters).some(filter => filter.length > 0)
    if (isFiltersInitialized) {
      setCurrentFilters((prevFilters: any) => ({ ...prevFilters, ...filters }))
    }
  }, [filters])

  console.log('Received filters in PBIDash:', filters)
  console.log('Using filters in PBIDash:', currentFilters)

  const mappedFilters = mapFilters(currentFilters)
  console.log('Mapped filters:', mappedFilters)

  return (
    <div id="pbi-wrapper">
      <PBIInterface reportName={reportName} filters={mappedFilters} />
      <div id="embedContainer" style={{ opacity: 0, transition: 'opacity 0.3s ease' }} />
    </div>
  )
}

const PBIInterface: React.FC<IPBIDash & { filters: models.IBasicFilter[] }> = ({ reportName, filters }) => {
  const [resp, setResp] = useState<IResponseState>({ isLoading: true, error: false })
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    fetchPBIEmbedToken(reportName)
      .then(response => {
        if (response) {
          setResp({ isLoading: false, error: false, data: response.data })
          setOpacity(1)
        } else {
          setResp({ isLoading: false, error: true })
        }
      })
      .catch(err => {
        console.error('Erro ao buscar dados da API Interface: ', err)
        setResp({ isLoading: false, error: true })
      })
  }, [reportName])

  useEffect(() => {
    const embedContainer = document.getElementById('embedContainer')

    if (!embedContainer) {
      console.error('Embed container not found')
      return
    }

    if (!resp.data) return

    if (powerbiClient && powerbiClient.service.Service.prototype.reset) {
      try {
        powerbiClient.service.Service.prototype.reset(embedContainer)
      } catch (error) {
        console.error('Erro ao resetar o relatório:', error)
      }
    }

    const { embedUrl, reportId } = resp.data.embedUrl[0]

    embedPowerBIReport(resp.data.accessToken, embedUrl, reportId, filters, reportName, resp.data.pageName)
  }, [filters, resp.data, reportName])

  if (resp.isLoading && !resp.error) {
    return (
      <div className="spin">
        <Spin />
      </div>
    )
  }

  if (!resp.isLoading && resp.error) {
    return (
      <ErrorDisplay
        message="Falha ao carregar o relatório"
        advice="Por favor, tente novamente mais tarde ou entre em contato com o suporte."
      />
    )
  }

  if (!resp.data) return null

  return (
    <div id="spinPBI" className="spin" style={{ opacity }}>
      <Spin />
    </div>
  )
}

export default PBIDash

async function fetchPBIEmbedToken(reportName: string) {
  try {
    const response = await apiPBI.get(`/pbi/getEmbedToken?reportName=${reportName}`)
    return response.data ? response : null
  } catch (error) {
    console.error('Erro ao buscar dados da API EmbedToken :', error)
    return null
  }
}

function embedPowerBIReport(
  accessToken: string,
  embedUrl: string,
  embedReportId: string,
  diagnosisFilter: models.IBasicFilter[],
  reportName: string,
  pageName?: string
) {
  const powerbi: powerbiClient.service.Service = new powerbiClient.service.Service(
    powerbiClient.factories.hpmFactory,
    powerbiClient.factories.wpmpFactory,
    powerbiClient.factories.routerFactory
  )

  const config: models.IReportEmbedConfiguration = {
    type: 'report',
    id: embedReportId,
    filters: reportsWithFilters.includes(reportName) ? diagnosisFilter : [],
    embedUrl,
    tokenType: models.TokenType.Embed,
    accessToken,
    permissions: models.Permissions.All,
    pageName,
    settings: {
      panes: {
        filters: { visible: false },
        pageNavigation: { visible: false },
      },
      bars: { statusBar: { visible: false } },
      background: models.BackgroundType.Transparent,
    },
  }

  const embedContainer = document.getElementById('embedContainer')

  if (!embedContainer) return

  if (powerbi && powerbi.reset) {
    try {
      powerbi.reset(embedContainer)
    } catch (error) {
      console.error('Erro ao resetar o relatório:', error)
    }
  }

  const report = powerbi.embed(embedContainer, config) as powerbiClient.Report

  report.on('loaded', () => {
    loadedResolve(embedContainer)
    report.off('loaded')
  })

  report.on('error', event => {
    console.error('PowerBI report error:', event.detail)
  })

  report.on('rendered', () => {
    renderedResolve(embedContainer)
    report.off('rendered')
  })
}

function loadedResolve(embedContainer: HTMLElement) {
  return ''
}

function renderedResolve(embedContainer: HTMLElement) {
  const spinPBI = document.getElementById('spinPBI')
  if (spinPBI) {
    spinPBI.style.display = 'none'
  }
  if (embedContainer) {
    // eslint-disable-next-line no-param-reassign
    embedContainer.style.opacity = '1'
  }
}
