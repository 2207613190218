import {validateCPF} from 'utils/ultilsFunction'
import {z} from 'zod'

const today = new Date()
const cutoffDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())

export const formUserSchema = z.object({
  name: z.string().min(3, "Nome é obrigatório"),
  last_name: z.string().min(1, { message: "Digite seu sobrenome" }),
  date_of_birth: z.string().refine(
    value => {
      const [dia, mes, ano] = value.split('/')

      if (!ano) return false;

      const userDate = new Date(`${ano}-${mes}-${dia}`)
      return userDate <= cutoffDate
    },
    {
      message: 'Você deve ter mais de 18 anos para se cadastrar.',
    }
  ).transform((value) => {
    const [dia, mes, ano] = value.split('/');
    return `${ano}-${mes}-${dia}`;
  }),
  social_security_number: z.string().refine(value => validateCPF(value), {
    message: 'CPF inválido.',
  }),
  profile: z.coerce.number().min(1, "Por favor, escolha um Perfil"),
  phone:  z.string()
    .refine((value) => {
      console.log("Valor do telefone:", value)
      return /\(\d{2}\)\s\d\s\d{4}-\d{4}/.test(value)}, {
      message: "Digite um telefone válido",
    }).transform((value) => "+55" + value.replace(/\D/g, '')),
  email: z.string().email({message: 'Por favor, forneça um endereço de e-mail válido.'}),
})
