import { styled } from 'styled-components'
import theme from 'styles/theme'

export const Wrapper = styled.main``

export const ContentFilters = styled.div`
  background: #111111;
  // border-radius: 8px;
  width: 224px;
  min-height: 372px;
  padding: 8px 0;
  max-height: 372px;
  overflow-y: auto;
`

export const FilterList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ItemList = styled.li`
  all: unset;
  background-color: transparent;
  font-weight: 700;
  font-size: 12px;
  color: ${theme.COLORS.gray10};
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 8px;
  // border-radius: 4px;

  &:nth-child(odd) {
    background-color: #1d1d1d;
    padding: 8px;
  }

  p {
    color: #ffc000;
  }

  &.type_list {
    display: flex;
    gap: 4px;
    align-items: flex-start;
    flex-direction: column;
  }
`

export const KeyText = styled.h4`
  font-size: 12px;
  font-weight: 500;
  color: ${theme.COLORS.gray10};
`

export const ListTextBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* max-width: 40%; */
  gap: 3px;
  /* flex-direction: column;  */
`
