import React, { createContext, useContext, useState, ReactNode } from 'react'

interface Filters {
  locality: string
  theme: string
  source: string
  topic: string
}

interface FiltersVoxBarContextType {
  filters: Filters
  handleFilters: (type: 'locality' | 'theme' | 'source' | 'topic' | 'reset', value: string) => void
  locality: string
  theme: string
  source: string
  topic: string
  activeFiltersBar: boolean
  validateEnableFilters: boolean

  resetFilters: () => void
  handleActiveAdvancedFilters: (active: boolean) => void
}

const FiltersVoxBarContext = createContext<FiltersVoxBarContextType | undefined>(undefined)

export const FiltersVoxBarProvider = ({ children }: { children: ReactNode }) => {
  const [filters, setFilters] = useState<Filters>({ locality: '', theme: '', source: '', topic: '' })
  const [locality, setLocality] = useState('')
  const [theme, setTheme] = useState('')
  const [source, setSource] = useState('')
  const [topic, setTopic] = useState('')
  const [activeFiltersBar, setActiveFiltersBar] = useState(false)

  const validateEnableFilters = !!topic || !!locality || !!theme || !!source

  const handleActiveAdvancedFilters = (isActive?: boolean) => {
    if (isActive) {
      setActiveFiltersBar(isActive)
    } else {
      setActiveFiltersBar(prev => !prev)
    }
  }

  const handleFilters = (type: 'locality' | 'theme' | 'source' | 'topic' | 'reset', value: string) => {
    setActiveFiltersBar(true)
    setFilters(prev => ({ ...prev, [type]: value }))
    if (type === 'locality') {
      setLocality(value)
    }
    if (type === 'theme') {
      setTheme(value)
    }
    if (type === 'source') {
      setSource(value)
    }
    if (type === 'topic') {
      setTopic(value)
    }
  }

  const resetFilters = () => {
    setLocality('')
    setTheme('')
    setSource('')
    setTopic('')
  }

  return (
    <FiltersVoxBarContext.Provider
      value={{
        activeFiltersBar,
        validateEnableFilters,
        handleActiveAdvancedFilters,
        topic,
        locality,
        theme,
        source,
        filters,
        handleFilters,
        resetFilters,
      }}
    >
      {children}
    </FiltersVoxBarContext.Provider>
  )
}

export const useFiltersVoxBar = (): FiltersVoxBarContextType => {
  const context = useContext(FiltersVoxBarContext)
  if (context === undefined) {
    throw new Error('useFiltersVoxBar must be used within a FiltersVoxBarProvider')
  }
  return context
}
