import * as S from './styles'

interface CountSectionProps {
  title?: string
  description?: string
  value?: any
  percent?: any
  typeStatus?: string
}
export const CountSection = ({ title, description, value, percent, typeStatus }: CountSectionProps) => {
  return (
    <S.Wrapper withTitle={!!title}>
      {title && <S.TitleCount>{title}</S.TitleCount>}
      {description && <S.Description>{description}</S.Description>}
      {!title && (
        <S.TextCount percent={percent} className={typeStatus}>
          {formatNumber(value)} {!!percent && <span>{roundNumber(percent, 2)}</span>}
        </S.TextCount>
      )}
    </S.Wrapper>
  )
}

function formatNumber(number: number) {
  if (number < 1) return '0'
  return number.toLocaleString('pt-BR')
}

function roundNumber(value: number, decimal: number): string {
  if (!value) return ''
  const factor = 10 ** decimal
  const finalValue = Math.round(value * factor) / factor
  return `${finalValue}%`
}
