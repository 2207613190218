import { styled } from 'styled-components'
import theme from 'styles/theme'

export const Wrapper = styled.main``

export const Content = styled.main`
  flex: 1;
  display: flex;
  gap: 16px;
`

export const Heading = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.COLORS.gray10};
  margin-bottom: 16px;
`

export const SearchWordBox = styled.div`
  flex: 1;
`

export const SearchContent = styled.div``

export const SearchActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`

export const IncludedTopicBox = styled.div`
  flex: 1;
`

export const ExcludedTopicBox = styled.div`
  flex: 1;
`

export const WordBox = styled.div`
  border: 1px solid #4f4f4f;
  border-radius: 8px;
  padding: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  min-height: 111px;
  gap: 8px;
  align-content: flex-start;
  max-height: 111px;
`

export const WordTag = styled.span``

export const ContentActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
  margin-top: 25px;

  button {
    padding: 4px 20px;

    &:hover {
      transform: scale(1);
    }
  }
`
