import axios from 'axios'

interface CandidateResp {
  id_cand: string
  nm_candidato: string
  party: string
  value: number | string
  label: string
}

interface ErrorMessage {
  message: string
}

type SearchCandidateResponse = CandidateResp[] | ErrorMessage

export const searchCandidate = async ({ search }: { search: string }): Promise<SearchCandidateResponse> => {
  const searchToUpperCase = search.toUpperCase()
  try {
    const { data } = await axios.get(
      `https://udd2hcn349.execute-api.us-east-1.amazonaws.com/api/analysis/candidates/?candidate=${searchToUpperCase}`
    )

    const formatterData = data?.map((item: CandidateResp) => ({
      ...item,
      value: item?.id_cand,
      label: item?.nm_candidato,
    }))

    return formatterData
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}
