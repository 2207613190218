import React from 'react'
import { useFilterGlobal } from 'hooks/useFilterGlobal'
import Text from 'componentsNew/Text'
import ControlledComponent from '../../InputDataPicker'
import { ContainerData } from './styles'

const FilterDate = () => {
  const { rangeDate, handleRangeDate } = useFilterGlobal()
  return (
    <ContainerData>
      <Text size="medium" bold color="yellow">
        Filtrar por data
      </Text>
      <ControlledComponent
        onChange={(date: any) => handleRangeDate({ type: 'initial_date', date })}
        defaultValue={rangeDate.initial_date}
      />
      <ControlledComponent
        onChange={(date: any) => handleRangeDate({ type: 'end_date', date })}
        defaultValue={rangeDate.end_date}
      />
    </ContainerData>
  )
}

export default FilterDate
