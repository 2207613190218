import { motion } from 'framer-motion'
import { css, styled } from 'styled-components'
import theme from 'styles/theme'

export const Wrapper = styled.main`
  position: relative;
  width: 100%;
  padding: 12px;
  .x_text_filter-bar {
    fill: ${theme.COLORS.gray10};
  }

  .y_text_filter-bar {
    fill: ${theme.COLORS.gray10};
  }

  .apexcharts-series {
    path {
      cursor: pointer;
    }
  }
`

export const Heading = styled.h3`
  font-size: 12px;
  font-weight: 700;
  color: ${theme.COLORS.gray10};
  margin-bottom: 12px;

  text-transform: uppercase;
`

export const WrapperBars = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  border: 1px solid #646464;
  border-radius: 4px;
  padding: 12px;
  overflow-y: auto;
  height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
`

export const ContentBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`
export const Label = styled.p`
  font-size: 11px;
  font-weight: 400;
  color: #e9e9f0;
  min-width: 170px;
  max-width: 170px;
  margin-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ProgressBar = styled(motion.div)<{ percent?: number }>`
  ${({ percent }) => css`
    height: 15px;
    min-height: 15px;
    width: ${percent}%;
    max-width: 100%;
    background: #947003;
    border-radius: 4px;
    position: relative;
    min-width: 14px;
    transition: width 0.8s;
  `}
`

export const ProgressLabel = styled.p`
  font-size: 11px;
  font-weight: 400;
  color: ${theme.COLORS.gray10};
  position: absolute;
  right: 8%;
`

export const EmptyBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
