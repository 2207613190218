import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { FaPlus } from 'react-icons/fa'
import { debounce, noop } from 'lodash'
import Button from 'componentsNew/Button'
import { searchCandidate } from 'componentsNew/ElectionStrategyComponents/services/SearchCandidate'
import { createCandidate } from 'componentsNew/ElectionStrategyComponents/services/competitiveServices'
import { useUser } from 'global/UserContext'
import { CandidateType } from 'componentsNew/ElectionStrategyComponents/types/candidateTypes'
import * as S from './styles'
import { CustomSelect } from '../CustomSelect'

interface CardToggleOverlayProps {
  isDisabled: boolean
  addCard?: (data: CandidateType) => void
}

const CardToggleOverlay = ({ isDisabled, addCard = noop }: CardToggleOverlayProps) => {
  const [disabled, setDisabled] = useState(isDisabled)
  const [searchResults, setSearchResults] = useState<CandidateType[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [selectedCandidate, setSelectedCandidate] = useState<any>(null)
  const { user } = useUser()

  const performSearch = async (term: string) => {
    if (term.length >= 3) {
      try {
        const data = await searchCandidate({ search: term })

        if (!('message' in data)) {
          setSearchResults(data)
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error)
      } finally {
        setLoading(false)
      }
    }
  }

  const debouncedSearch = debounce(performSearch, 2000)

  useEffect(() => {
    if (searchTerm) {
      setLoading(true)
      debouncedSearch(searchTerm)
    }

    return () => {
      debouncedSearch.cancel()
    }
  }, [searchTerm])

  const handleSearchInputChange = (value: string) => {
    setSearchTerm(value)
  }

  const handleToggle = (e: any) => {
    e.stopPropagation()
    setDisabled(!disabled)
  }

  const onSelectOption = (data: CandidateType) => {
    setSelectedCandidate(data)
  }

  const handleCandidate = () => {
    addCard(selectedCandidate)
    createCandidate({
      candidate_id: selectedCandidate?.id_cand,
      candidate_name: selectedCandidate?.nm_candidato,
      candidate_party: selectedCandidate?.party,
      is_listening: false,
      person: user?.person?.id || 0,
    })
    setSelectedCandidate(null)
    setDisabled(!disabled)
  }

  return (
    <S.Wrapper>
      <S.AddButton>
        <AnimatePresence>
          <S.Content>
            <motion.div
              style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              transition={{ duration: 0.5, ease: 'easeIn' }}
            >
              <motion.div
                initial={{ y: 0 }}
                animate={disabled ? { y: 0 } : { y: -20 }}
                transition={{ duration: 0.5, ease: 'easeIn' }}
              >
                <S.IconWrapper onClick={handleToggle}>
                  {disabled ? <FaPlus size={32} /> : <FaPlus size={32} />}
                </S.IconWrapper>
              </motion.div>
            </motion.div>
            {!disabled && (
              <motion.div
                key="inputs"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: -12 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.6, ease: 'easeIn' }}
              >
                <S.InputBox>
                  <CustomSelect
                    options={searchResults}
                    showSearch
                    onSelect={(val, option) => onSelectOption(option?.data)}
                    loading={loading}
                    notFoundContent={null}
                    defaultActiveFirstOption={false}
                    style={{ width: '300px' }}
                    optionFilterProp="label"
                    hiddenSwitch
                    size="middle"
                    placeholder="Busque por um candidato..."
                    onSearch={handleSearchInputChange}
                  />
                </S.InputBox>
              </motion.div>
            )}

            {selectedCandidate && (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Button size="medium" variant="minimal" onClick={handleCandidate}>
                  Adicionar
                </Button>
              </motion.div>
            )}
          </S.Content>
        </AnimatePresence>
      </S.AddButton>
    </S.Wrapper>
  )
}

export default CardToggleOverlay
