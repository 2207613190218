import { DefaultCandidate } from 'componentsNew/ElectionStrategyComponents/MOCK/competitiveMock'
import { useCandidates } from 'hooks/useCandidates'
import { useEffect, useState } from 'react'
import CardDiagnosis from '../CardDiagnosis'
import { getAllAdversary } from '../services/candidateServices'
import { AdversaryObject } from '../services/types/candidateTypes'

import * as S from './styles'

export const ListeningAdversary = ({ activeTab }: any) => {
  const [adversaryList, setAdversaryList] = useState<AdversaryObject[]>([])
  const { selectedCandidate } = useCandidates()

  const defaultCandidate = {
    ...selectedCandidate,
    topicList: DefaultCandidate,
    candidate_name: selectedCandidate?.name,
    label: selectedCandidate?.name,
    candidate_id: selectedCandidate?.id_cand,
    candidate_party: selectedCandidate?.party_acronym,
  }
  useEffect(() => {
    fetchMentions()
  }, [])

  const fetchMentions = async () => {
    const list = await getAllAdversary()
    if (!('message' in list)) {
      setAdversaryList(list)
    }
  }

  if (activeTab !== 'tab_political_listening_competitive') return null

  return (
    <S.Wrapper>
      <CardDiagnosis profile_candidate={defaultCandidate} />
      {adversaryList.map(profile => (
        <CardDiagnosis key={profile.candidate_id} profile_candidate={profile} />
      ))}
    </S.Wrapper>
  )
}
