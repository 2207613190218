import { css, styled } from 'styled-components'
import theme from 'styles/theme'

export const Wrapper = styled.main``

export const Content = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: 26px;
`

export const Heading = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.COLORS.gray10};
  margin-bottom: 16px;
`

export const ContentFilterTag = styled.div`
  flex: 1.4;
`

export const ListFilterTag = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`
export const ContentListTags = styled.div`
  flex: 0.6;
`

export const ListTags = styled.div``

export const TagBox = styled.div`
  width: 275px;
  height: 160px;
  border-radius: 8px;
  border: 1.5px solid ${theme.COLORS.gray10};
  overflow: auto;
  padding: 4px;
`

export const TagItem = styled.p<{ active?: boolean }>`
  ${({ active }) => css`
    font-size: 16px;
    font-weight: 400;
    color: ${active ? '#121212' : theme.COLORS.gray10};
    background: ${active ? '#ffc000' : 'transparent'};
    width: 100%;
    cursor: pointer;
    padding: 2px 4px;
    margin: 4px 0;

    &:hover {
      opacity: 0.7;
    }
  `}
`

export const ContentActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
  margin-top: 25px;

  button {
    padding: 4px 20px;

    &:hover {
      transform: scale(1);
    }
  }
`
