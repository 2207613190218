import dayjs from "dayjs";
import React, { createContext, useContext, useState } from "react";
import { IChildTypes } from "types/childrenTypes";
import { ALL } from "utils/constants";

interface RangeDateTypes {
  initial_date: string | null;
  end_date: string | null;
}

interface Candidate {
  name: string;
  id: string;
}

interface LoadChartState {
  elector: boolean;
  government: boolean;
}

const FilterVoxContext = createContext<any>(null);
const dateFormat = "YYYY-MM-DD";
const default_date = dayjs().subtract(7, "day").format(dateFormat);
const now_default = dayjs().format(dateFormat);

export const FilterVoxProvider = ({ children }: IChildTypes) => {
  const [rangeDate, setRangeDate] = useState<RangeDateTypes>({
    initial_date: default_date,
    end_date: null,
  });
  const [filterRaioX, setFilterRaioX] = useState<any>(ALL);
  const [subTopic, setSubTopic] = useState("");
  const [activeAdvancedFilters, setActiveAdvancedFilters] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<any>(null);
  const [themeList, setThemeList] = useState<any[]>([]);
  const [displayedFilters, setDisplayedFilters] = useState({
    candidate: "",
    topic_sub: "",
    city: "",
    topic: "",
  });
  const [subtopics, setSubtopics] = useState<string[]>([]);
  const [topic, setTopic] = useState("");
  const [topic_lvl_2, setTopicLvl2] = useState("");
  const [candidate, setCandidate] = useState({ name: "", id: "" });
  const [includedWords, setIncludedWords] = useState<string[]>([]);
  const [excludedWords, setExcludedWords] = useState<string[]>([]);
  const [source, setSource] = useState("");
  const [theme, setTheme] = useState("");
  const [city, setCity] = useState("");
  const [governmentWordCloud, setGovernmentWordCloud] = useState([]);
  const [electorWordCloud, setElectorWordCloud] = useState([]);
  const [governmentChart, setGovernmentChart] = useState([]);
  const [electorChart, setElectorChart] = useState([]);
  const [topMentions, setTopMentions] = useState([]);
  const [loadChart, setLoadChart] = useState({
    elector: false,
    government: false,
  });
  const [topicosFilter, setTopicosFilterState] = useState({
    isActive: false,
    filters: {},
  });
  const [includedExcludedFilter, setIncludedExcludedFilterState] = useState({
    isActive: false,
    filters: {},
  });
  const [isCandidateOpen, setIsCandidateOpen] = useState(false);
  const [isPoliticaVoxOpen, setIsPoliticaVoxOpen] = useState(false);
  const [isKeyWordOpen, setIsKeyWordOpen] = useState(false);

  const handleChart = ({
    type,
    loading,
  }: {
    type?: "government" | "elector";
    loading?: any;
  }) => {
    setLoadChart((prev: LoadChartState) => ({
      ...prev,
      [type || ""]: loading,
    }));
  };

  const [candidatoFilter, setCandidatoFilterState] = useState({
    isActive: false,
    filters: {},
  });

  const handleMentionsChart = (type: "government" | "elector", data: any) => {
    if (type === "government") {
      setGovernmentChart(data);
    }
    if (type === "elector") {
      setElectorChart(data);
    }
  };
  const handleWordCloud = (type: "government" | "elector", data: any) => {
    if (type === "government") {
      setGovernmentWordCloud(data);
    }
    if (type === "elector") {
      setElectorWordCloud(data);
    }
  };

  const handleTopMentions = (data: any) => {
    setTopMentions(data);
  };

  const filtersDefault = {
    includedWords,
    excludedWords,
    candidate,
    topic,
    subtopics,
    source,
    theme,
    city,
    topic_lvl_2,
  };

  const handleFilters = (
    type:
      | "candidate"
      | "subtopics"
      | "excludedWords"
      | "includedWords"
      | "topic"
      | "source"
      | "theme"
      | "city"
      | "reset"
      | "topic_lvl_2",
    value: string | string[] | Candidate,
  ) => {
    const actions: { [key: string]: (value: any) => void } = {
      candidate: setCandidate,
      subtopics: setSubtopics,
      topic: setTopic,
      excludedWords: setExcludedWords,
      includedWords: setIncludedWords,
      source: setSource,
      theme: setTheme,
      city: setCity,
      topic_lvl_2: setTopicLvl2,
    };

    if (type === "candidate" && typeof value === "object") {
      actions[type](value);
    } else {
      const action = actions[type];
      if (action) {
        if (value === "Todos" || value === "all" || value === "all_tags") {
          action("");
        } else {
          action(value);
        }
      }
    }

    if (type === "reset") {
      setCity("");
      setTheme("");
      setSource("");
      setTopicLvl2("");
    }
  };

  const handleRangeDate = ({
    type,
    date,
  }: {
    type: "initial_date" | "end_date";
    date: string;
  }) => {
    setRangeDate((prev) => ({ ...prev, [type]: date }));
  };

  const handleSubtopic = (data: string) => {
    setSubTopic(data);
  };

  const handleDisplayFilters = (
    type: "candidate" | "topic_sub" | "city" | "topic",
    value: string,
  ) => {
    setDisplayedFilters((prev) => ({ ...prev, [type]: value }));
  };

  const changeTopic = (type: "topics" | "subtopics", topics: string[]) => {
    setTopicosFilterState((prev) => ({
      isActive: true,
      filters: {
        ...prev.filters,
        [type]: topics,
      },
    }));
  };

  const resetAllFilters = () => {
    setSelectedCandidate("");
    setDisplayedFilters({
      candidate: "",
      topic_sub: "",
      city: "",
      topic: "",
    });
    setCandidate({ name: "", id: "" });
    setFilterRaioX(ALL);
    setRangeDate({ initial_date: default_date, end_date: null });
    setTopicosFilterState((prev) => ({
      ...prev,
      filters: {},
    }));
    setIncludedExcludedFilterState((prev) => ({
      ...prev,
      filters: {},
    }));
    setSubtopics([]);
    setTopic("");
    setThemeList([]);
    setExcludedWords([]);
    setIncludedWords([]);
  };

  const changeIncludedTopics = (
    type: "included_topics" | "excluded_topics",
    filters: string[],
  ) => {
    setIncludedExcludedFilterState((prev) => ({
      isActive: true,
      filters: {
        ...prev.filters,
        [type]: filters,
      },
    }));
  };

  const loadSavedFilters = (data: any) => {
    setIsCandidateOpen(true);
    setIsPoliticaVoxOpen(true);
    setIsKeyWordOpen(true);
    const topicName = data?.topics?.length > 0 && data?.topics[0]?.name;
    setIncludedWords(data?.positive_words);
    setExcludedWords(data?.negative_words);
    setIncludedExcludedFilterState((prev) => ({
      isActive: true,
      filters: {
        included_topics: data?.positive_words,
        excluded_topics: data?.negative_words,
      },
    }));
    setTopicosFilterState((prev) => ({
      isActive: true,
      filters: {
        topics: data?.topics,
        subtopics: data?.themes,
      },
    }));
    setSubtopics(data?.subtopics);
    setTopic(topicName);
  };

  const handleToggleCandidateCollapse = () => {
    setIsCandidateOpen(!isCandidateOpen);
  };

  const handleTogglePoliticaVoxCollapse = () => {
    setIsPoliticaVoxOpen(!isPoliticaVoxOpen);
  };

  const handleToggleKeyWordCollapse = () => {
    setIsKeyWordOpen(!isKeyWordOpen);
  };

  const handleActiveAdvancedFilters = (isActive?: boolean) => {
    setActiveAdvancedFilters(
      isActive !== undefined ? isActive : !activeAdvancedFilters,
    );
  };

  const changeActive = (
    type: "candidate" | "topic" | "included" | "advanced_filters",
    val: boolean,
  ) => {
    if (type === "candidate") {
      setCandidatoFilterState((prev: any) => ({ ...prev, isActive: val }));
    }
    if (type === "topic") {
      setTopicosFilterState((prev: any) => ({ ...prev, isActive: val }));
    }
    if (type === "included") {
      setIncludedExcludedFilterState((prev: any) => ({
        ...prev,
        isActive: val,
      }));
    }
  };

  return (
    <FilterVoxContext.Provider
      value={{
        handleToggleCandidateCollapse,
        handleTogglePoliticaVoxCollapse,
        handleToggleKeyWordCollapse,
        loadSavedFilters,
        themeList,
        setThemeList,
        filtersDefault,
        handleFilters,
        displayedFilters,
        handleDisplayFilters,
        activeAdvancedFilters,
        handleActiveAdvancedFilters,
        subTopic,
        handleSubtopic,
        rangeDate,
        filterRaioX,
        handleRangeDate,
        setFilterRaioX,
        governmentWordCloud,
        electorWordCloud,
        handleWordCloud,
        governmentChart,
        electorChart,
        handleMentionsChart,
        topMentions,
        handleTopMentions,
        handleChart,
        loadChart,
        resetAllFilters,
        selectedCandidate,
        setSelectedCandidate,
        topicosFilter,
        changeTopic,
        includedExcludedFilter,
        changeIncludedTopics,
        isKeyWordOpen,
        isPoliticaVoxOpen,
        isCandidateOpen,
        changeActive,
      }}
    >
      {children}
    </FilterVoxContext.Provider>
  );
};

export const useFilterVox = () => {
  const context = useContext(FilterVoxContext);
  if (!context) {
    throw new Error(
      "useFilterVox deve ser usado dentro de um FilterVoxProvider",
    );
  }
  return context;
};
