import styled from 'styled-components'

export const ContainerMaster = styled.div`
  width: 100%;
  font-size: 12px;
  padding: 8px;
  .intercalada {
    height: 20px;
    width: 100%;
    padding: 4px;
  }
  .intercalada:nth-child(even) {
    background-color: #1d1d1d;
  }

  .intercalada:nth-child(odd) {
    background-color: #111111;
  }
`

export const Container = styled.div`
  width: 450px;
  max-height: 400px;
  overflow-y: auto;

  .card-header {
    margin: 8px;
  }

  .position {
    margin: 12px 0;
  }
`
