import React from "react";
import { ProfileImage } from "pages/SettingsProfile/components";

const ProfilePicture: React.FC = () => {
  return (
    <div>
      <ProfileImage />
    </div>
  );
};

export default ProfilePicture;
