import { Skeleton } from 'antd'
import styled, { css, keyframes } from 'styled-components'
import theme from 'styles/theme'

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

export const Heading = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.COLORS.gray10};
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`

export const AvatarBox = styled.div<{ isLoading?: boolean }>`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #111111;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  position: relative;
  z-index: 1;

  ${props =>
    props.isLoading &&
    css`
      &::before {
        content: '';
        width: 95%;
        height: 95%;
        position: absolute;

        border: 1px solid transparent;
        background: linear-gradient(
          0deg,
          rgba(255, 192, 0, 1) 0%,
          rgba(255, 192, 0, 0.3085609243697479) 10%,
          rgba(255, 192, 0, 0.20211834733893552) 90%,
          rgba(255, 192, 0, 1) 100%
        );
        border-image-slice: 1;
        border-radius: 50%;
        animation: ${spinAnimation} 2s linear infinite;
        animation-delay: 0.1s;
      }
    `}

  svg {
    color: ${theme.COLORS.gray10};
    width: 20px;
    height: 20px;
    z-index: 2;
  }
`

export const AvatarProfile = styled.img`
  width: 100%;
  object-fit: cover;
`

export const boxDisabled = styled.div``

export const DisabledAvatar = styled(AvatarBox)`
  background: #c9c9c9;
  width: 35px;
  height: 35px;
`

export const SkeletonInput = styled(Skeleton.Input)`
  background: #c9c9c9;
  border-radius: 8px;
  width: 320px !important;
  height: 25px;
`
