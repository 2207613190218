import React from 'react'
import ReactDOM from 'react-dom/client'
import { getItemStorage } from 'utils/persistenceUtils'
import { AUTH_TOKEN } from 'utils/constants'
import App from './App'
import { defaultsHeadersAxios, defaultsHeadersAxiosUser } from './services/apiService'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const token = getItemStorage(AUTH_TOKEN)

defaultsHeadersAxios(token || '')
defaultsHeadersAxiosUser(token || '')

root.render(<App />)
