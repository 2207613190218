import React from 'react'
import { ContainerCard } from './styles'
import Text from '../Text'

interface ICard {
  children: any
  title?: string
  icon?: any
}

const Card = ({ children, title, icon }: ICard) => {
  return (
    <ContainerCard>
      {title && (
        <div className="title">
          {icon && <img src={icon} alt="icon" />}
          <Text bold size="xSmall">
            {title}
          </Text>
        </div>
      )}
      <div className="wrapper-card">{children}</div>
    </ContainerCard>
  )
}

export default Card
