import React, { useEffect } from "react";
import { ALL } from "utils/constants";
import { useCandidates } from "hooks/useCandidates";
import WordcloudCand from "../../../../componentsNew/Wordcloud";
import { handleMapColor } from "../../../../utils/utilityFunctions";
import Spin from "../../../../componentsNew/Spin";
import ChartMentions from "../../../../componentsNew/PoliticalListeningComponents/ListeningCity/components/ChartMentions";
import useSocialMentionsWc from "pages/PoliticalListening/hooks/useSocialMentionsWC";
import { useFilterGlobal } from "../../../../hooks/useFilterGlobal";
import * as S from "./styles";

interface IRaioXProps {
  cand_id?: string;
  target_local?: string;
}

const RaioX = ({ cand_id, target_local }: IRaioXProps) => {
  const { wordsDefault, isLoadingDefault, getWordDefault } =
    useSocialMentionsWc();
  const { filterMentions, rangeDate, filterRaioX } = useFilterGlobal();
  const { selectedCandidate } = useCandidates();
  /* const target_local = selectedCandidate?.locality || 'BRA.SP.Sao Paulo'
  const cand_id = selectedCandidate?.id_cand */

  useEffect(() => {
    const sentiment = filterRaioX !== ALL ? `&sentiment=${filterRaioX}` : "";

    if (filterMentions !== ALL && cand_id) {
      getWordDefault({
        parent_name: filterMentions,
        dateStart: rangeDate.initial_date,
        endDate: rangeDate.end_date,
        sentiment,
        city: target_local,
        candidate_id: cand_id,
      });
    } else {
      getWordDefault({
        dateStart: rangeDate.initial_date,
        endDate: rangeDate.end_date,
        sentiment,
        city: target_local,
        candidate_id: cand_id,
      });
    }
  }, [filterMentions, rangeDate, filterRaioX, cand_id]);

  return (
    <S.Section>
      <S.ContentSection>
        <S.Box>
          <S.Heading>Raio-X do governo - Menções</S.Heading>
          <S.HorizontalSection>
            <S.Box style={{ minWidth: "42%" }}>
              {!isLoadingDefault ? (
                <WordcloudCand
                  words={wordsDefault}
                  width={400}
                  height={350}
                  colors={handleMapColor(filterMentions)}
                />
              ) : (
                <S.LoadingBox>
                  <Spin spinning />
                </S.LoadingBox>
              )}
            </S.Box>
            <S.Box style={{ flex: "1.5" }}>
              <ChartMentions cand_id={cand_id} target_local={target_local} />
            </S.Box>
          </S.HorizontalSection>
        </S.Box>
      </S.ContentSection>
    </S.Section>
  );
};

export default RaioX;
