import React from 'react'
import * as S from './styles'

interface IButtonCopilot {
  title: string
  isActive?: boolean
  onClick?: () => void
}

const ButtonCopilot: React.FC<IButtonCopilot> = ({ title, isActive = false, onClick }) => {
  return (
    <S.ButtonContainer isActive={isActive} onClick={onClick}>
      <S.ButtonTitle className="title">{title}</S.ButtonTitle>
    </S.ButtonContainer>
  )
}

export default ButtonCopilot
