import React, { createContext, useContext, useState, ReactNode } from 'react'

interface CockPitContextProps {
  filters: string[] | null
  changeFilter: (filter: string[] | null) => void
}

const CockPitContext = createContext<CockPitContextProps | undefined>(undefined)

interface CockPitProviderProps {
  children: ReactNode
}

export const CockPitProvider: React.FC<CockPitProviderProps> = ({ children }) => {
  const [filters, setFilters] = useState<string[] | null>(null)

  const changeFilter = (filter: string[] | null) => {
    if (filter) {
      setFilters(filter)
    }
  }

  return (
    <CockPitContext.Provider
      value={{
        filters,
        changeFilter,
      }}
    >
      {children}
    </CockPitContext.Provider>
  )
}

export const useCockPit = () => {
  const context = useContext(CockPitContext)
  if (!context) {
    throw new Error('useCockpit deve ser usado dentro de um CockPitProvider')
  }
  return context
}
