import { useEffect, useState } from 'react'
import { IoMdAdd, IoMdClose } from 'react-icons/io'
import InternalButton from 'componentsNew/InternalButton'

import { apiPortifolio } from 'services/apiService'
import * as S from './styles'
import { ConfirmAction } from '../Dialogs/ConfirmAction'

interface ProfileCandidateProps {
  addCandidate: () => void
  candidate: any
  existingCandidate: boolean
  removeCandidate: () => void
}

export const ProfileCandidate = ({
  addCandidate,
  candidate,
  existingCandidate,
  removeCandidate,
}: ProfileCandidateProps) => {
  const [avatar, setAvatar] = useState(null)
  const [loadingAvatar, setLoadingAvatar] = useState(false)
  const [openAction, setOpenAction] = useState(false)
  const [openRemoveAction, setOpenRemoveAction] = useState(false)

  useEffect(() => {
    if (candidate) {
      getAvatar()
    }
  }, [candidate])

  const getAvatar = async () => {
    setLoadingAvatar(true)
    try {
      const { data } = await apiPortifolio.get(
        `/candidate-portfolio/candidate/${candidate.candidate_id || candidate.id_cand}/image`
      )

      setAvatar(data?.image_url)
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingAvatar(false)
    }
  }

  const handleAddCandidate = () => {
    setOpenAction(true)
  }

  const handleRemoveCandidate = () => {
    setOpenRemoveAction(true)
  }

  return (
    <S.Wrapper>
      <S.Content>
        <S.AvatarBox isLoading={loadingAvatar}>
          <S.AvatarCustom src={avatar} alt="Candidato X" />
        </S.AvatarBox>

        <S.DescriptionWrapper>
          <S.DescriptionTitle>{candidate?.nm_candidato || candidate?.candidate_name}</S.DescriptionTitle>

          <S.DescriptionContent>
            {candidate?.role && <S.DescriptionLabel>Cargo Atual: {candidate?.role}</S.DescriptionLabel>}
            {candidate?.city && candidate?.state && (
              <S.DescriptionLabel>
                Cidade: {candidate?.city} | {candidate?.state}{' '}
              </S.DescriptionLabel>
            )}
            <S.DescriptionLabel>Partido: {candidate?.party}</S.DescriptionLabel>
          </S.DescriptionContent>
        </S.DescriptionWrapper>
      </S.Content>

      <S.ActionBox>
        <S.ActionText>Acesso rápido:</S.ActionText>
        <S.Actions>
          <InternalButton
            background="#FFC000 "
            icon={<IoMdAdd color="#000000" />}
            onClick={handleAddCandidate}
            disabled={existingCandidate}
          >
            incluir
          </InternalButton>

          <InternalButton
            background="#d70708"
            icon={<IoMdClose color="#000000" />}
            disabled={!existingCandidate}
            onClick={handleRemoveCandidate}
          >
            remover
          </InternalButton>
        </S.Actions>
      </S.ActionBox>

      <ConfirmAction
        onConfirm={addCandidate}
        open={openAction}
        onClose={() => setOpenAction(false)}
        textAction={
          <p>
            Deseja realmente incluir <span>{candidate?.nm_candidato || candidate?.candidate_name}</span> no acesso
            rápido?
          </p>
        }
      />
      <ConfirmAction
        buttonRemove
        onConfirm={removeCandidate}
        open={openRemoveAction}
        onClose={() => setOpenRemoveAction(false)}
        textAction={
          <p>
            Deseja ralmente remover <span>{candidate?.nm_candidato || candidate?.candidate_name}</span> do acesso
            rápido?
          </p>
        }
      />
    </S.Wrapper>
  )
}
