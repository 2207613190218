import { useNavigate } from "react-router-dom";
import { useTab } from "global/TabContext";

const useRedirectToPortfolio = () => {
  const navigate = useNavigate();
  const { setActiveTabContext } = useTab();

  const redirectToPortfolio = () => {
    setActiveTabContext("tab_team_advisors_portfolio");
    navigate("/dashboard/application-management/team");
  };

  return { redirectToPortfolio };
};

export default useRedirectToPortfolio;
