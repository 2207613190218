import React, { createContext, useState, useContext, ReactNode } from 'react'

interface ValidVotesData {
  id: number
  rithm: number
  weekly_goal: number
  projection: number
  initial_value: number
  goal: number
  minimum_goal: number
  first_partial_goal: number
  second_partial_goal: number
  current_value: number
  initial_date: string
  final_date: string
  created: string
  updated: string
  person: number
}

interface TrajectoryValidVotesContextProps {
  validVotesData: ValidVotesData | null
  setValidVotesData: (data: ValidVotesData | null) => void
}

const TrajectoryValidVotesContext = createContext<TrajectoryValidVotesContextProps | undefined>(undefined)

export const TrajectoryValidVotesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [validVotesData, setValidVotesData] = useState<ValidVotesData | null>(null)

  return (
    <TrajectoryValidVotesContext.Provider value={{ validVotesData, setValidVotesData }}>
      {children}
    </TrajectoryValidVotesContext.Provider>
  )
}

export const useTrajectoryValidVotes = (): TrajectoryValidVotesContextProps => {
  const context = useContext(TrajectoryValidVotesContext)
  if (!context) {
    throw new Error('useTrajectoryValidVotes must be used within a TrajectoryValidVotesProvider')
  }
  return context
}
