import React, { useEffect, useState } from "react";
import Spin from "componentsNew/Spin";
import TabsCustom from "componentsNew/Tabs";
import Mentions from "./componets/Mentions";
import CardWordc from "./componets/CardWordc";
import VoterProfile from "./componets/VoterProfile";
import TopServices from "./componets/TopSevices";
import TopThemes from "./componets/TopThemes";
import { apiPredeterminedCandidate } from "services/apiService";
import { useCandidates } from "hooks/useCandidates";
import TopArchetypes from "./componets/TopArchetypes";
import KeyFeature from "./componets/KeyFeature";
import CardAdherenceToTheArchetype from "./componets/CardAdherenceToTheArchetype";
import CardHeader from "./componets/CardHeader";
import OverlayMessage from "./OverLay";
import { ContainerPage } from "./styles";

const labels = [
  {
    label: "Candidato Preferido",
    key: "tab_preferred_candidate",
  },
];

const PreferredCandidate = () => {
  const [activeTab, setActiveTab] = React.useState("tab_preferred_candidate");
  const [archetype, setArchetype] = useState([]);
  const { isLoadedCandidates, selectedCandidate } = useCandidates();
  const target_locality = selectedCandidate?.locality || "BRA.SP.Sao Paulo";
  const city = target_locality?.replace("BRA.", "").replace(/\./g, " | ");

  const getArchetype = async () => {
    try {
      const { data } = await apiPredeterminedCandidate.get(
        `/best-candidate/archetype/?city=${target_locality}`,
      );
      setArchetype(data);
    } catch (e) {
      setArchetype([]);
      console.log(e);
    }
  };

  useEffect(() => {
    if (isLoadedCandidates) return;
    getArchetype();
  }, [isLoadedCandidates]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  const changePoliticalTab = (item: string) => {
    setActiveTab(item);
  };

  return (
    <ContainerPage>
      <TabsCustom
        title=""
        labels={labels}
        activeTab={activeTab}
        content={[
          <div>
            <div className="header-page">
              <CardHeader archetype={archetype[0]} city={city} />
              <CardAdherenceToTheArchetype
                archetype={archetype[0]}
                city={city}
              />
            </div>
            <div className="header-page-card">
              <KeyFeature archetype={archetype[0]} />
              <TopArchetypes archetype={archetype} />
              <CardWordc width={260} />
            </div>
            <div className="header-page-card-flow">
              <VoterProfile />
            </div>
            <div className="header-page-card-flow">
              <TopServices />
              <TopThemes />
            </div>
            <Mentions />
            <OverlayMessage />
          </div>,
        ]}
        onChange={(key) => changePoliticalTab(key)}
      />
    </ContainerPage>
  );
};

export default PreferredCandidate;
