import { ConfigProvider, theme } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { useState } from 'react'
import * as S from './styles'

interface CustomSelectProps extends SelectProps {
  placeholder?: string
  options?: any[]
  className?: string
  backgroundColor?: string
}

export const CustomSelect = ({ options, placeholder, className, backgroundColor, ...rest }: CustomSelectProps) => {
  const [value, setValue] = useState('')
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            algorithm: true,
            optionSelectedBg: 'rgb(218, 147, 6)',
            selectorBg: 'rgba(245, 7, 7, 0)',
            optionSelectedColor: 'rgb(0, 0, 0)',
            colorBorder: 'rgb(233, 233, 240)',
            optionActiveBg: 'rgba(255, 255, 255, 0.04)',
            colorPrimaryHover: 'rgb(233, 233, 240)',
            colorPrimary: 'rgb(233, 233, 240)',
          },
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <S.Wrapper className={`custom_select ${className}`}>
        <S.SelectCustom
          options={options}
          className={`custom_select ${className}`}
          showSearch
          optionFilterProp="label"
          {...rest}
          onChange={val => setValue(val)}
        />
        <S.LabelLine className="line" withValue={!!value} backgroundColor={backgroundColor}>
          {placeholder}
        </S.LabelLine>
      </S.Wrapper>
    </ConfigProvider>
  )
}
