import React from 'react'
import iconAvatar from 'assets/avatar-default.png'
import { Container, ContainerIcon } from './styles'
import iconNeutron from '../../assets/iconNeutron.svg'
import iconArrowDown from '../../assets/iconArrowDown.svg'
import iconWizard from '../../assets/iconWirzad.svg'
import iconArrowTop from '../../assets/iconArrawTop.svg'
import iconDoubleTop from '../../assets/iconDoubleTop.svg'
import Text from '../Text'
import Avatar from '../Avatar'

interface ICardCandidate {
  name: string
  wizard?: boolean
  status: 'neutron' | 'down' | 'top' | 'doubleTop'
  img?: any
  onClick: () => void
}

const CardCandidate = ({ name, wizard = false, status, img = iconAvatar, onClick }: ICardCandidate) => {
  const handleIcon = (status: string) => {
    switch (status) {
      case 'down':
        return iconArrowDown
      case 'top':
        return iconArrowTop
      case 'doubleTop':
        return iconDoubleTop
      default:
        return iconNeutron
    }
  }
  return (
    <Container onClick={onClick}>
      <div className="card-header">
        <Avatar img={img} />
        {wizard && (
          <ContainerIcon>
            <img src={iconWizard} alt="icon-status" />
          </ContainerIcon>
        )}
        <Text size="xSmall">{name}</Text>
      </div>
      <ContainerIcon>
        <img src={handleIcon(status)} alt="icon-status" />
      </ContainerIcon>
    </Container>
  )
}

export default CardCandidate
