import React from 'react';
import InputMask from 'react-input-mask';
import { StyledInput } from '../styles';

interface MaskedInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  mask: string;
  placeholder: string;
}

const MaskedInputComponent: React.FC<MaskedInputProps> = (
    {
      value,
      onChange,
      onBlur,
      mask,
      placeholder,
    }
  ) => {
  return (
      <InputMask
        mask={mask}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        maskChar={null}
        maskPlaceholder={null}
        alwaysShowMask={false}
      >
        <StyledInput
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          size="large"
        />
      </InputMask>
    );
};

export default MaskedInputComponent;
