import { useState } from 'react'
import { ISetGoals } from './ISetGoals'

const useSetGoals = (initialValues: ISetGoals, endpoint: string) => {
  const [goals, setGoals] = useState<ISetGoals>(initialValues)

  const handleChange = (value: number | string, slug: keyof ISetGoals) => {
    setGoals(prev => ({ ...prev, [slug]: value }))
  }

  return {
    goals,
    setGoals,
    handleChange,
  }
}

export default useSetGoals
