import CardMentions from "componentsNew/CardMentions";
import {
  formatDateTime,
  handleServiceColor,
  iconRedMens,
} from "utils/utilityFunctions";
import * as S from "./styles";

interface MentionsMessageProps {
  mentions: any[];
}

export const MentionsMessage = ({ mentions }: MentionsMessageProps) => {
  const renderMentions = (mentions: any) => {
    return mentions.map((mention: any, index: any) => {
      const service = mention?.parent_name;
      const serviceData = handleServiceColor(service);

      return (
        <CardMentions
          key={index}
          color={serviceData?.color || "defaultColor"}
          iconSocial={iconRedMens(mention.page_type_name).icon}
          message={mention.full_text}
          user={mention.author}
          dataCreate={formatDateTime(mention.added)}
          iconServices={serviceData?.icon}
          fullWidth
          page_type_name={mention?.page_type_name}
          url={mention?.url}
        />
      );
    });
  };

  return <S.Content>{renderMentions(mentions)}</S.Content>;
};
