interface ProgressCardGoals {
  rithmValue: number
  weeklyGoalValue: number
  projectionValue: number
  goalValue: number
}

interface ProgressResult {
  rithmPercentage: number
  weeklyGoalPercentage: number
  rithmColor: 'green' | 'red' | 'white'
  projectionPercentage: number
  goalPercentage: number
  projectionColor: 'green' | 'red' | 'white'
}

const calculateProgressAndColor = ({
  rithmValue,
  weeklyGoalValue,
  projectionValue,
  goalValue,
}: ProgressCardGoals): ProgressResult => {
  let rithmPercentage = 100
  let weeklyGoalPercentage = 100
  let rithmColor: 'green' | 'red' | 'white' = 'green'
  let projectionPercentage = 100
  let goalPercentage = 100
  let projectionColor: 'green' | 'red' | 'white' = 'white'

  if (rithmValue === null || weeklyGoalValue === null) {
    rithmPercentage = 0
    projectionPercentage = 0
  } else {
    if (rithmValue < weeklyGoalValue) {
      rithmPercentage = (rithmValue / weeklyGoalValue) * 100
      rithmColor = 'red'
    }
    if (rithmValue >= weeklyGoalValue) {
      weeklyGoalPercentage = (weeklyGoalValue / rithmValue) * 100
      rithmColor = 'green'
    }
  }

  if (projectionValue === null || goalValue === null) {
    weeklyGoalPercentage = 0
    goalPercentage = 0
  } else {
    if (projectionValue < goalValue) {
      projectionPercentage = (projectionValue / goalValue) * 100
      projectionColor = 'red'
    }
    if (projectionValue >= goalValue) {
      goalPercentage = (goalValue / projectionValue) * 100
      projectionColor = 'green'
    }
  }

  return {
    rithmPercentage,
    weeklyGoalPercentage,
    rithmColor,
    projectionPercentage,
    goalPercentage,
    projectionColor,
  }
}

export default calculateProgressAndColor
