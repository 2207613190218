import { styled } from 'styled-components'

export const Wrapper = styled.main`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  filter: blur(2px);
`
