import React from 'react'
import styled from 'styled-components'
import { IconType } from 'react-icons'

interface IconButtonProps {
  icon: IconType
  onClick: () => void
  title?: string
  disabled?: boolean
}

const Button = styled.button`
  width: 21px;
  height: 21px;
  background: #ffc000;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  /* &:hover {
    background: rgba(255, 255, 255, 0.1);
  } */

  svg {
    fill: #000000;
    width: 12px;
    height: 12px;
  }
`

const IconButton: React.FC<IconButtonProps> = ({ icon: Icon, onClick, title, disabled = false }) => (
  <Button disabled={disabled} onClick={onClick} title={title}>
    <Icon />
  </Button>
)

export default IconButton
