import { AllyWizard, ListByAllyTypes } from 'componentsNew/ElectionStrategyComponents/types/ALLY_TYPES'
import React, { createContext, useContext, useState, ReactNode } from 'react'

import { MOCK_Ally_SELECTION_POSITIVE } from '../../MOCK/allyMock'

interface AllyContextProps {
  allyList: ListByAllyTypes[]

  updateAllyList: (list: ListByAllyTypes[]) => void

  updateSelectList: (ally_name: string, data: any) => void
  visibleWizard: AllyWizard[] | null

  updateVisibleWizard: (wizards: AllyWizard[] | null) => void

  handlePositiveCardClick: (clickedId: number) => void
  updateListByTopic: (wizard: any) => void
}

const AllyContext = createContext<AllyContextProps | undefined>(undefined)

interface AllyProviderProps {
  children: ReactNode
}

export const AllyProvider: React.FC<AllyProviderProps> = ({ children }) => {
  const [visibleWizard, setVisibleWizards] = useState<AllyWizard[] | null>(null)
  const [allyList, setAllyList] = useState<ListByAllyTypes[]>(MOCK_Ally_SELECTION_POSITIVE)

  const updateAllyList = (list: ListByAllyTypes[]) => {
    setAllyList(list)
  }

  const updateSelectList = (ally_name: string, data: any) => {
    const newValues = allyList.map(ally => {
      if (ally.ally_name === ally_name) {
        return {
          ...ally,
          selection_list: ally.selection_list.map(option => {
            if (option.name === data?.theme_name) {
              return { ...option, active: true, name: data?.theme_name, is_recommended: true }
            }
            return option
          }),
        }
      }
      return ally
    })

    setAllyList(newValues)
  }

  const updateListByTopic = (wizard: any) => {
    setAllyList(prevTopics => {
      const newTopics = [...prevTopics]
      const topicIndex = newTopics.findIndex(topic => topic.topic === wizard.topic_name)
      console.log('pass 2', newTopics, wizard)
      if (topicIndex !== -1) {
        const index = newTopics[topicIndex].theme_list.findIndex(item => item === undefined)
        if (index !== -1) {
          newTopics[topicIndex].theme_list[index] = { ...wizard, label: wizard?.theme_name, value: wizard?.id } // Usando o ID do wizard como valor
        }
      }
      return newTopics
    })
  }

  const updateVisibleWizard = (wizards: AllyWizard[] | null) => {
    setVisibleWizards(wizards)
  }

  const handlePositiveCardClick = (clickedId: number) => {
    if (visibleWizard && visibleWizard.length > 0) {
      const updatedWizard = visibleWizard.filter(wizard => wizard.id !== clickedId)
      updateVisibleWizard(updatedWizard.length > 0 ? updatedWizard : null)
    }
  }

  return (
    <AllyContext.Provider
      value={{
        allyList,
        updateListByTopic,
        visibleWizard,
        updateAllyList,
        updateSelectList,
        updateVisibleWizard,
        handlePositiveCardClick,
      }}
    >
      {children}
    </AllyContext.Provider>
  )
}

export const useAlly = () => {
  const context = useContext(AllyContext)
  if (!context) {
    throw new Error('useAlly deve ser usado dentro de um AllyProvider')
  }
  return context
}
