import styled from "styled-components";
import "../../styles/global";
import BREAKPOINTS from "../../styles/deviceBreakpoints";
import background_image from "../../assets/login/image-background.png";

export const Background = styled.div`
  background-image: url(${background_image});
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  overflow-y: scroll;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.WIDTH.MD}) {
    grid-template-columns: 1fr;
    text-align: center;
    justify-items: center;
    align-items: center;
    gap: 2rem;
  }

  .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ImageLogo = styled.img`
  // position: absolute;
  width: 17.5rem;
  height: 2.87rem;
  margin-top: 2rem;

  @media (max-width: ${BREAKPOINTS.WIDTH.MD}) {
    position: static;
    width: 28rem;
    height: 4.6rem;
  }
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: end;
  align-items: end;

  @media (max-width: ${BREAKPOINTS.WIDTH.MD}) {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const Image = styled.img`
  display: flex;
  width: 80%;
`;

export const FormContainer = styled.div`
  background-color: ${({ theme }) => theme.COLORS.shade20};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 1rem;
  width: 32rem;
  height: 36rem;
`;

export const StyledForm = styled.div`
  display: flex;
  width: 87%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 6rem;

  .social-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    gap: 2rem;
  }

  .check-forgot {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
    margin-bottom: 1rem;
  }

  .text-form {
    display: flex;
    margin-top: 0.7rem;
    margin-bottom: 1rem;
  }

  .error-message {
    display: flex;
    justify-content: start;
    margin-top: 1rem;
  }
`;

export const Footer = styled.div`
  width: 12.5rem;
  height: auto;
  text-align: center;
  margin-top: 1rem;

  .no-account {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

export const Error = styled.div`
  display: flex;
  justify-content: self-start;
  align-items: self-start;
  margin-top: -1.5rem;
  margin-bottom: 1rem;
`;
