import React from 'react'
import Text from 'componentsNew/Text'

import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'
import { useFilterVox } from 'hooks/useFilterVox'
import { ContainerRaioX } from './styles'
import theme from '../../../styles/theme'
import { ALL, POSITIVE, NEGATIVE } from '../../../utils/constants'

const FilterRaioX = () => {
  const { filterRaioX, setFilterRaioX } = useFilterVox()
  const handleFilter = (filter: string) => {
    return filterRaioX === filter
  }

  const isActiveAll = handleFilter(ALL)
  const isActivePositive = handleFilter(POSITIVE)
  const isActiveNegative = handleFilter(NEGATIVE)
  return (
    <ContainerRaioX>
      <Text size="medium" bold color="yellow">
        Filtros de Escuta Política
      </Text>
      <button type="button" className={isActiveAll ? 'raioX' : 'raioXActive'} onClick={() => setFilterRaioX(ALL)}>
        {!isActiveAll ? (
          <MdRadioButtonUnchecked size={20} color="white" />
        ) : (
          <MdRadioButtonChecked color={isActiveAll ? theme.COLORS.yellow10 : 'white'} size={20} />
        )}
        <Text size="xSmall" bold={isActiveAll} color={isActiveAll ? 'black' : 'white'}>
          Tudo
        </Text>
      </button>
      <button
        type="button"
        className={isActivePositive ? 'raioX' : 'raioXActive'}
        onClick={() => setFilterRaioX(POSITIVE)}
      >
        {!isActivePositive ? (
          <MdRadioButtonUnchecked size={20} color="white" />
        ) : (
          <MdRadioButtonChecked color={isActivePositive ? theme.COLORS.yellow10 : 'white'} size={20} />
        )}

        <Text size="xSmall" bold={isActivePositive} color={isActivePositive ? 'black' : 'white'}>
          Menções Positivas
        </Text>
      </button>
      <button
        type="button"
        className={isActiveNegative ? 'raioX' : 'raioXActive'}
        onClick={() => setFilterRaioX(NEGATIVE)}
      >
        {!isActiveNegative ? (
          <MdRadioButtonUnchecked size={20} color="white" />
        ) : (
          <MdRadioButtonChecked color={isActiveNegative ? theme.COLORS.yellow10 : 'white'} size={20} />
        )}

        <Text size="xSmall" bold={isActiveNegative} color={isActiveNegative ? 'black' : 'white'}>
          Menções Negativas
        </Text>
      </button>
    </ContainerRaioX>
  )
}

export default FilterRaioX
