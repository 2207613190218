import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const SelectContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
  gap: 1.5rem;
`

export const SelectContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const Label = styled.label`
  position: absolute;
  top: -8px;
  left: 10px;
  background: #0a0a0a;
  padding: 0 4px;
  font-size: 12px;
  color: #e9e9f0;
  font-family: Roboto, sans-serif;
  z-index: 10;
`

export const Title = styled.h3`
  font-size: 14px;
  color: #ffc000;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 32px;
  gap: 16px;
`
