export const customStyles = {
  control: (base: any) => ({
    ...base,
    backgroundColor: '#0A0A0A',
    borderColor: '#e9e9f0',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ffc000',
      cursor: 'pointer',
    },
    paddingBottom: '0px',
    minHeight: '32px',
  }),
  menu: (base: any) => ({
    ...base,
    backgroundColor: '#0A0A0A',
    color: '#EAEAF0',
    cursor: 'pointer',
    zIndex: 100,
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isFocused ? '#ffc000' : '#0A0A0A',
    color: state.isFocused ? '#000000' : '#EAEAF0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ffc000',
      color: '#000000',
    },
  }),
  multiValue: (base: any) => ({
    ...base,
    backgroundColor: '#ffc000',
    color: '#000000',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 4px',
    '&:hover': {
      backgroundColor: '#ffb000',
    },
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    color: '#000000',
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    color: '#000000',
    cursor: 'pointer',
    '&:hover': {
      // backgroundColor: '#e9e9f0',
      color: '#D70708',
    },
  }),
  singleValue: (base: any) => ({
    ...base,
    color: '#eaeaf0',
    cursor: 'pointer',
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#E9E9F0',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    paddingBottom: '0px',
  }),
  input: (base: any) => ({
    ...base,
    color: '#E9E9F0',
  }),
}

export const selectTheme = (theme: any) => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary25: '#ffc000',
    primary: 'gray',
  },
})
