import styled from "styled-components";

export const ContainerPage = styled.div<any>`
  width: 98%;
  display: flex;
  justify-content: center;
  flex: 1;
  padding-bottom: 16px;

  .header-page {
    width: 100%;
    display: flex;
    flex: 1;
    gap: 16px;
    margin: 16px 0;
  }
  .header-page-card {
    width: 100%;
    display: flex;
    gap: 16px;
    margin: 16px 0;
    height: 180px;
  }
  .header-page-card-flow {
    width: 100%;
    display: flex;
    flex: 1;
    gap: 16px;
    margin: 16px 0;
  }
`;

export const Container = styled.div<any>`
  display: flex;
  gap: 16px;
  width: 100%;
  flex: 1;

  .card-scroll {
    width: 100%;
    flex: 1;
    height: fit-content;
    max-height: 600px;
    overflow-y: scroll;
    background-color: transparent;
  }
  .constioner-card {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    gap: 16px;
  }
`;

export const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 16px;
  width: 100%;
`;
