import React, { createContext, useContext, useState, useEffect } from 'react'
import { apiPBI } from 'services/apiService'
import { useCandidates } from 'hooks/useCandidates'
import { statesData } from 'utils/statesData'
import {
  defaultFilters as initialDefaultFilters,
  values as initialValues,
} from 'componentsNew/Helper/DiagnosisFilter/utils/defaultFilters'

interface FilterContextProps {
  defaultFilters: any
  values: any
}

const CandidateDiagnosticFilterContext = createContext<FilterContextProps | undefined>(undefined)

export const CandidateDiagnosticFilterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [defaultFilters, setDefaultFilters] = useState(initialDefaultFilters)
  const [values, setValues] = useState(initialValues)
  const { selectedCandidate } = useCandidates()

  const fetchCities = async (stateCode: number): Promise<any[]> => {
    try {
      const response = await apiPBI.get(`/locations-data/ufs/${stateCode}/municipios`)
      return response.data.map((city: { cd_municipio: string; nm_municipio: string }) => ({
        cd_municipio: city.cd_municipio,
        nm_municipio: city.nm_municipio,
      }))
    } catch (error) {
      console.error('Erro ao buscar municípios:', error)
      return []
    }
  }

  const fetchNeighborhoods = async (cityCodes: string[]): Promise<string[]> => {
    try {
      const response = await apiPBI.post('/locations-data/municipios/bairros', { codigos: cityCodes })
      const neighborhoods = cityCodes.flatMap(
        cityCode => response.data[cityCode]?.map((bairro: { nm_bairro: string }) => bairro.nm_bairro).sort() || []
      )
      return [...new Set(neighborhoods)] // Elimina bairros duplicados
    } catch (error) {
      console.error('Erro ao buscar bairros:', error)
      return []
    }
  }

  const normalizeString = (str: string) =>
    str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toUpperCase()

  useEffect(() => {
    const initializeFilters = async () => {
      const locality = selectedCandidate?.locality || ''
      const [country, stateCandidate, ...cityParts] = locality.split('.')
      const cityCandidate = cityParts.join('.')

      const selectedState = statesData.find(state => state.uf === stateCandidate)
      if (!selectedState) return

      const cities = await fetchCities(selectedState.cd_uf)
      const selectedCity = cities.find(city => normalizeString(city.nm_municipio) === normalizeString(cityCandidate))
      if (!selectedCity) return

      const neighborhoods = await fetchNeighborhoods([selectedCity.cd_municipio])

      const updatedFilters = {
        ...initialDefaultFilters,
        state: [selectedState.uf],
        city: [selectedCity.nm_municipio],
        neighborhood: neighborhoods,
      }

      const updatedValues = {
        ...initialValues,
        election_results: [
          {
            ...initialValues.election_results[0],
            state: [selectedState.uf],
            city: [selectedCity.nm_municipio],
            neighborhood: neighborhoods,
          },
        ],
        voter_pofile: [
          {
            ...initialValues.voter_pofile[0],
            state: [selectedState.uf],
            city: [selectedCity.nm_municipio],
            neighborhood: neighborhoods,
          },
        ],
      }

      setDefaultFilters(updatedFilters)
      setValues(updatedValues)

      console.log('CONTEXT GERAL: ', updatedFilters, updatedValues)
    }

    initializeFilters()
  }, [selectedCandidate])

  return (
    <CandidateDiagnosticFilterContext.Provider value={{ defaultFilters, values }}>
      {children}
    </CandidateDiagnosticFilterContext.Provider>
  )
}

export const useCandidateDiagnosticFilterContext = (): FilterContextProps => {
  const context = useContext(CandidateDiagnosticFilterContext)
  if (!context) {
    throw new Error('useCandidateDiagnosticFilterContext must be used within a CandidateDiagnosticFilterProvider')
  }
  return context
}
