import styled from 'styled-components'
import theme from 'styles/theme'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  min-height: 100px;
`

export const EmptyData = styled.div`
  text-align: center;
  color: ${theme.COLORS.gray10};
  font-size: 13px;

  opacity: 0.6;
`
