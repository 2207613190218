import styled from 'styled-components'

interface propsContainer {
  open: boolean
}

export const Container = styled.div<propsContainer>`
  ${({ theme, open }) => `
    width: 8.3rem;
    min-height: 100vh;
    height: 100%;
    position: fixed;
    color: white;
    display: flex;
    flex-direction: column;
    z-index: 103;
    width: ${open ? '8.3rem' : '2.75rem'};
    padding-bottom: 0.5rem;
    background-color: ${theme.COLORS.yellow30};
    transition: width 0.3s;
    justify-content: space-between;
    border-right: 1px solid ${theme.COLORS.shade50};
  `}

  .avatar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 0.5rem;
    flex-direction: column;
    ${({ open }) => `
      margin-bottom: ${open ? '0' : '2.3rem'};
    `}
  }

  .candidato {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid white;
    padding: 2px;
    cursor: pointer;
    gap: 2px;
  }
`

export const ButtonToggle = styled.div<propsContainer>`
  width: 1.29rem;
  height: 1.29rem;
  border-radius: 100%;
  position: fixed;
  top: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;

  ${({ theme, open }) => `
    background: ${theme.COLORS.yellow20};
    color:${theme.COLORS.shade40};
    left: ${open ? '7.6rem' : '2.2rem'};
  `}

  :hover {
    cursor: pointer;
    transform: scale(1.08);
  }
`

export const WrapperMenu = styled.div``
export const WrapperFooter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  .user {
    padding-left: 0.75rem;
  }

  .plan {
    border-top: 1px solid ${({ theme }) => theme.COLORS.shade40};
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.shade40};
    padding: 0.5rem 0.75rem;
  }

  button {
    background: none;
    border: none;
    height: 1.87rem;
  }
`
