import { css, styled } from 'styled-components'
import theme from 'styles/theme'

const FooterModifier = {
  start: () => css`
    justify-content: flex-start;
  `,
  center: () => css`
    justify-content: center;
  `,
  end: () => css`
    justify-content: flex-end;
  `,
  space: () => css`
    justify-content: space-between;
  `,
}

export const Wrapper = styled.main``

export const Header = styled.div`
  width: 100%;
  text-align: center;
  padding: 6px 0;
  border-bottom: 1px solid ${theme.COLORS.gray10};
  display: flex;
  flex-direction: column;
`

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: ${theme.COLORS.gray10};
  text-transform: uppercase;
`

export const ContentModal = styled.div`
  padding: 12px;
  flex: 1;
`

export const Footer = styled.div<{ align?: 'start' | 'end' | 'center' | 'space' }>`
  ${({ align }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 12px;

    gap: 24px;

    button {
      padding: 12px 16px;
      min-width: 154px;
    }

    ${!!align && FooterModifier[align]()};
  `}
`
