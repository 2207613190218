import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
  .text {
    width: 11rem;
  }

  .percent-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    margin: 0 8px;
  }
`
export const MarkColor = styled.div<any>`
  width: 0.5rem;
  height: 1.12rem;
  background: ${props => props.color};
  margin-right: 1rem;
`
