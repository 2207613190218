import React, { createContext, useState, useContext, ReactNode } from 'react'

interface TimeLineData {
  id: number
  initial_date: string
  pre_campaign_date: string
  campaign_date: string
  election_fund_date: string
  created: string
  updated: string
  person: number
}

interface TrajectoryTimeLineContextProps {
  timeLineData: any[]
  setTimeLineData: (data: any) => void
}

const TrajectoryTimeLineContext = createContext<TrajectoryTimeLineContextProps | undefined>(undefined)

export const TrajectoryTimeLineProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [timeLineData, setTimeLineData] = useState<any[]>([])

  return (
    <TrajectoryTimeLineContext.Provider value={{ timeLineData, setTimeLineData }}>
      {children}
    </TrajectoryTimeLineContext.Provider>
  )
}

export const useTrajectoryTimeLine = (): TrajectoryTimeLineContextProps => {
  const context = useContext(TrajectoryTimeLineContext)
  if (!context) {
    throw new Error('useTrajectoryTimeLine must be used within a TrajectoryTimeLineProvider')
  }
  return context
}
