import React from 'react'
import { Container } from './styles'
import FilterDate from './FilterDate'
import FilterRaioX from './FilterRaioX'
import FilterPoliticalListening from './FilterPoliticalListening'

const PoliticalListening = () => {
  return (
    <Container>
      <FilterDate />
      <FilterRaioX />
      <FilterPoliticalListening />
    </Container>
  )
}

export default PoliticalListening
