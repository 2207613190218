import React from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { ManagementTeam } from 'pages/Management/Team'
import { ROTES } from 'utils/constants'
import { PoliticalVoxSearch } from 'pages/PoliticalVoxSearch'
import { PrivateRoute } from './PrivateRoutes'
import RouteTransition from './RouteTansition'
import Layout from '../componentsNew/Layout'
import { Login } from '../pages/Login'
import Logout from '../componentsNew/Logout'
import { OnboardingPage } from '../pages/Onboarding'
import RegisterCandidate from '../pages/RegisterCandidate'
/* 
import { Register } from '../pages/Register'
import { ForgotPassword } from '../pages/ForgotPassword'
import { PasswordRecover } from '../pages/PasswordRecover'
import { RecoverPassword } from '../pages/RecoverPassword'
import { PasswordCreation } from '../pages/PasswordCreation'
import { RegistrationProcess } from '../pages/RegistrationProcess'
import { ProfileConfirm } from '../pages/ProfileConfirm'
import { CandidateObjective } from '../pages/CandidateObjective'
import { CandidateAuthetication } from '../pages/CandidateAuthetication'
import { CandidateAutheticationMessage } from '../pages/CandidateAutheticationMessage'
import { RepresentativeCandData } from '../pages/RepresentativeCandData' 
*/
import { DashboardCockpit } from '../pages/DashboardCockpit'
import { DashboardTrajectoryGoals } from '../pages/DashboardTrajectoryGoals'
import { GoalsTimeLine } from '../pages/GoalsTimeLine'
import { GoalsValidVotes } from '../pages/GoalsValidVotes'
import { ApplicationManagementTeam } from '../pages/ApplicationManagementTeam'
import { PoliticalProfile } from '../pages/PoliticalProfile'
import { PoliticalArchetype } from '../pages/PoliticalArchetype'
import { ElectionStrategy } from '../pages/ElectionStrategy'

import { VoterDiagnosis } from '../pages/VoterDiagnosis'
import { ElectionDiagnosis } from '../pages/ElectionDiagnosis'
import { ElectionPools } from '../pages/ElectionPolls'
import { PoliticalListening } from '../pages/PoliticalListening'
import PreferredCandidate from '../pages/PrederredCandidate'

import { PartyDiagnosis } from '../pages/PartyDiagnosis'
import SponsoredApplications from '../pages/SponsoredApplicattions'
import NationalPanoramaSponsored from '../pages/SponsoredApplicattions/pages/NationalPanoramaSponsored'
import AllApplications from '../pages/AllApplications'
import NationalPanorama from '../pages/AllApplications/pages/NationalPanorama'
import { PoliticalVoxPage } from '../pages/PoliticalVox'

import { Acquisitions } from '../pages/Acquisitions'
import { AcquisitionsSignature } from '../pages/AcquisitionsSignature'
import { WizardElectoralProfile } from '../pages/WizardElectoralProfile'
import { SettingsProfile } from '../pages/SettingsProfile'

import Page404 from '../pages/Errors/404'

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route element={<RouteTransition />}>
        <Route path={ROTES.LOGIN} element={<Login />} />
        <Route path={ROTES.ONBOARDING} element={<OnboardingPage />} />
        <Route path={ROTES.LOGOUT} element={<Logout />} />
        <Route path={ROTES.REGISTER_CANDIDATE} element={<RegisterCandidate />} />

        {/*
        <Route path={ROTES.REGISTER} element={<Register />} />
        <Route path={ROTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROTES.PASSWORD_RECOVER} element={<PasswordRecover />} />
        <Route path={ROTES.RECOVER_PASSWORD} element={<RecoverPassword />} />
        <Route path={ROTES.REGISTRATION_PROCESS} element={<RegistrationProcess />} />
        <Route path={ROTES.REGISTER_PASSWORD} element={<PasswordCreation />} />
        <Route path={ROTES.REGISTER_PROFILE_CONFIRM} element={<ProfileConfirm />} />
        <Route path={ROTES.REGISTER_CAND_OBJECTIVE} element={<CandidateObjective />} />
        <Route path={ROTES.REGISTER_CAND_AUTHENTICATION} element={<CandidateAuthetication />} />
        <Route path={ROTES.REGISTER_CAND_AUTHENTICATION_MESSAGE} element={<CandidateAutheticationMessage />} />
        <Route path={ROTES.REGISTER_REPRESENTATIVE_CAND_DATA} element={<RepresentativeCandData />} />
        */}

        <Route
          path={ROTES.DASHBOARD}
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route
            path={ROTES.MANAGEMENT_TEAM}
            element={
              <PrivateRoute>
                <ManagementTeam />
              </PrivateRoute>
            }
          />
          <Route
            index
            path={ROTES.DASHBOARD_COCKPIT}
            element={
              <PrivateRoute>
                <DashboardCockpit />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.APPLICATION_STRATEGY_ANALYSIS}
            element={
              <PrivateRoute>
                <ElectionStrategy />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.APPLICATION_STRATEGY_GOALS}
            element={
              <PrivateRoute>
                <DashboardTrajectoryGoals />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.APPLICATION_MANAGEMENT_TEAM}
            element={
              <PrivateRoute>
                <ApplicationManagementTeam />
              </PrivateRoute>
            }
          />
          <Route
            index
            path={ROTES.VOTER_DIAGNOSIS}
            element={
              <PrivateRoute>
                <VoterDiagnosis />
              </PrivateRoute>
            }
          />
          <Route
            index
            path={ROTES.ELECTION_DIAGNOSIS}
            element={
              <PrivateRoute>
                <ElectionDiagnosis />
              </PrivateRoute>
            }
          />
          <Route
            index
            path={ROTES.ELECTION_POOLS}
            element={
              <PrivateRoute>
                <ElectionPools />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.POLITICAL_LISTENING}
            element={
              <PrivateRoute>
                <PoliticalListening />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.PREFERRED_CANDIDATE}
            element={
              <PrivateRoute>
                <PreferredCandidate />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.POLITICAL_ARCHETYPE}
            element={
              <PrivateRoute>
                <PoliticalArchetype />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.POLITICAL_PROFILE}
            element={
              <PrivateRoute>
                <PoliticalProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.PARTY_DIAGNOSIS}
            element={
              <PrivateRoute>
                <PartyDiagnosis />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.SPONSORED_APPLICATIONS}
            element={
              <PrivateRoute>
                <SponsoredApplications />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.SPONSORED_APPLICATIONS_NATIONAL_PANORAMA}
            element={
              <PrivateRoute>
                <NationalPanoramaSponsored />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.ALL_SPONSORED}
            element={
              <PrivateRoute>
                <AllApplications />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.ALL_SPONSORED_NATIONAL_PANORAMA}
            element={
              <PrivateRoute>
                <NationalPanorama />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.POLITICAL_VOX}
            element={
              <PrivateRoute>
                <PoliticalVoxPage />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.POLITICAL_VOX_SEARCH}
            element={
              <PrivateRoute>
                <PoliticalVoxSearch />
              </PrivateRoute>
            }
          />

          <Route
            path={ROTES.ELECTORAL_WIZARD}
            element={
              <PrivateRoute>
                <WizardElectoralProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.ACQUISITIONS}
            element={
              <PrivateRoute>
                <Acquisitions />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.ACQUISITIONS_SIGNATURE}
            element={
              <PrivateRoute>
                <AcquisitionsSignature />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.SETTINGS_PROFILE}
            element={
              <PrivateRoute>
                <SettingsProfile />
              </PrivateRoute>
            }
          />

          <Route
            path={ROTES.GOALS_TIMELINE}
            element={
              <PrivateRoute>
                <GoalsTimeLine />
              </PrivateRoute>
            }
          />
          <Route
            path={ROTES.GOALS_VALID_VOTES}
            element={
              <PrivateRoute>
                <GoalsValidVotes />
              </PrivateRoute>
            }
          />
          <Route
            path="*"
            element={
              <PrivateRoute>
                <Page404 />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="/*" element={<Navigate to={ROTES.LOGIN} />} />
        <Route path="*" element={<Navigate to={ROTES.NOT_FOUND} />} />
        <Route path="/not-found" element={<Page404 />} />
      </Route>
    </Routes>
  )
}
