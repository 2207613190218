import React from "react";
import { Avatar } from "antd";
import Text from "componentsNew/Text";
import { ICandidateType } from "componentsNew/CockpitComponents/types/adversaryTypes";
import * as S from "./styles";

interface IAdversaryFilterCard {
  candidate: ICandidateType;
  isActive: boolean;
  onToggle: (candidate: ICandidateType, isActive: boolean) => void;
  borderColor?: string;
  avatarUrl: string;
  isClickable?: boolean;
}

const AdversaryFilterCard: React.FC<IAdversaryFilterCard> = ({
  candidate,
  isActive,
  onToggle,
  borderColor,
  avatarUrl,
  isClickable = true,
}) => {
  const handleToggle = () => {
    if (isClickable) {
      onToggle(candidate, !isActive);
    }
  };

  return (
    <S.CardWrapper
      isActive={isActive}
      onClick={handleToggle}
      borderColor={borderColor}
      isClickable={isClickable}
    >
      <S.CandidateImage>
        <Avatar src={avatarUrl} alt={candidate.candidate_name} />
      </S.CandidateImage>
      <S.CandidateInfo>
        <Text size="small" bold>
          {candidate.candidate_name}
        </Text>
      </S.CandidateInfo>
    </S.CardWrapper>
  );
};

export default AdversaryFilterCard;
