import { css, styled } from 'styled-components'
import theme from 'styles/theme'

const WrapperModifier = {
  active: (color?: string) => css`
    border: 2px solid ${color};
    border-left: 10px solid ${color};
    border-radius: 8px;
    opacity: 1;
  `,
}

export const Wrapper = styled.main<{ color?: string; active?: boolean }>`
  ${({ color, active }) => css`
    width: 196px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #111111;
    cursor: pointer;
    border-left: 10px solid ${color};
    border-radius: 8px;
    opacity: 0.6;

    
    ${active && WrapperModifier.active(color)}
  `}
`

export const Text = styled.p`
  color: ${theme.COLORS.gray10};
  font-size: 12px;
  font-weight: 500;
`

export const Icon = styled.img`
  width: 17px;
`
