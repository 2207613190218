import { MacroEnvProvider } from '../hooks/useMacroEnv'
import MacroEnv from '../templates/MacroEnv'

export const MacroEnvTemplate = () => {
  return (
    <MacroEnvProvider>
      <MacroEnv />
    </MacroEnvProvider>
  )
}
