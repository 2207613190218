import React, { createContext, useContext, useState } from 'react'

interface CandidatoFilter {
  name?: string
  city?: string
  party?: string
  id_cand?: string
  candidate_id?: string
}

interface FiltersCandidate {
  selectedCandidate?: CandidatoFilter
  candidate_api?: any[]
}

interface TopicosFilter {
  topics?: any[]
  subtopics?: string[]
}

interface IncludedExcludedFilter {
  included_topics?: string[]
  excluded_topics?: string[]
}

interface filterNameProps {
  title?: string
  description?: string
}

interface activeFilters {
  candidate?: boolean
  topic?: boolean
  word?: boolean
}

type changeCandidateFilterTypes = (filter?: FiltersCandidate) => void
type SetTopicosFilter = (filter?: TopicosFilter) => void
type SetIncludedExcludedFilter = (filter?: IncludedExcludedFilter) => void
type ChangeTopicsType = (type: 'included_topics' | 'excluded_topics', filters: string[]) => void
type changeFilterNameType = (type: 'title' | 'description', name: string) => void
type ChangeTopicTypes = (type: 'topics' | 'subtopics', topics: any[]) => void
type changeSelectedFilter = (data: any) => void

interface CandidateListTypes {
  list: any[]
  list_id?: number
}

interface OverViewContextType {
  mentions: string
  candidatoFilter: {
    isActive: boolean
    filters: FiltersCandidate
  }
  topicosFilter: {
    isActive: boolean
    filters: TopicosFilter
  }
  includedExcludedFilter: {
    isActive: boolean
    filters: IncludedExcludedFilter
  }
  filterName: filterNameProps
  candidateList: CandidateListTypes
  selectedFilter: any

  changeSelectedFilter: changeSelectedFilter
  changeCandidateList: (data: CandidateListTypes) => void
  addToCandidateList: (candidate: any) => void
  handleFilterName: changeFilterNameType
  changeFilterCandidate: changeCandidateFilterTypes
  setTopicosFilter: SetTopicosFilter
  setIncludedExcludedFilter: SetIncludedExcludedFilter
  changeIncludedTopics: ChangeTopicsType
  changeTopic: ChangeTopicTypes
  changeFilter: (data: any) => void
  changeMentions: (mention: string) => void
  changeActive: (type: 'candidate' | 'topic' | 'included' | 'advanced_filters', val: boolean) => void
}

const OverViewContext = createContext<OverViewContextType | undefined>(undefined)

interface OverviewProviderProps {
  children: React.ReactNode
}

export const OverviewProvider = ({ children }: OverviewProviderProps) => {
  const [mentions, setMentions] = useState('')
  const [selectedFilter, setSelectedFilter] = useState<any>({})

  const [candidateList, setCandidateList] = useState<CandidateListTypes>({
    list: [],
  })

  const [candidatoFilter, setCandidatoFilterState] = useState({
    isActive: false,
    filters: {},
  })
  const [topicosFilter, setTopicosFilterState] = useState({
    isActive: false,
    filters: {},
  })
  const [includedExcludedFilter, setIncludedExcludedFilterState] = useState({
    isActive: false,
    filters: {},
  })
  const [filterName, setFilterName] = useState({
    title: '',
    description: '',
  })

  const handleFilterName = (type: 'title' | 'description', val: string) => {
    setFilterName(prev => ({ ...prev, [type]: val }))
  }

  const changeActive = (type: 'candidate' | 'topic' | 'included' | 'advanced_filters', val: boolean) => {
    if (type === 'candidate') {
      setCandidatoFilterState(prev => ({ ...prev, isActive: val }))
    }
    if (type === 'topic') {
      setTopicosFilterState(prev => ({ ...prev, isActive: val }))
    }
    if (type === 'included') {
      setIncludedExcludedFilterState(prev => ({ ...prev, isActive: val }))
    }
  }

  const changeFilterCandidate: changeCandidateFilterTypes = filter => {
    setCandidatoFilterState({ isActive: true, filters: filter || {} })
  }

  const setTopicosFilter: SetTopicosFilter = filter => {
    setTopicosFilterState({ isActive: true, filters: filter || {} })
  }

  const setIncludedExcludedFilter: SetIncludedExcludedFilter = filter => {
    setIncludedExcludedFilterState({ isActive: true, filters: filter || {} })
  }

  const changeIncludedTopics = (type: 'included_topics' | 'excluded_topics', filters: string[]) => {
    setIncludedExcludedFilterState(prev => ({
      isActive: true,
      filters: {
        ...prev.filters,
        [type]: filters,
      },
    }))
  }

  const changeTopic = (type: 'topics' | 'subtopics', topics: string[]) => {
    setTopicosFilterState(prev => ({
      isActive: true,
      filters: {
        ...prev.filters,
        [type]: topics,
      },
    }))
  }

  const changeCandidateList = (newItem: CandidateListTypes) => {
    setCandidateList(newItem)
  }

  const addToCandidateList = (candidate: any) => {
    const candidate_id = candidate?.id_cand || candidate?.candidate_id
    const filterCandidate = candidateList.list.filter((cand: any) => cand?.candidate_id === candidate_id)

    if (filterCandidate.length > 0) {
      const newCandidateList = candidateList.list.filter((cand: any) => cand?.candidate_id !== candidate_id)

      setCandidateList(prev => ({ ...prev, list: newCandidateList }))
    } else {
      setCandidateList(prev => ({ ...prev, list: [...prev.list, candidate] }))
    }
  }

  const changeMentions = (newMention: string) => {
    setMentions(newMention)
  }

  const changeSelectedFilter = (data: any) => {
    setSelectedFilter(data)
  }

  const changeFilter = (data: any) => {
    /*  setCandidateList(data?.candidates) */
    setIncludedExcludedFilterState({
      isActive: data?.is_words_active,
      filters: {
        positive_words: data?.positive_words,
        negative_words: data?.negative_words,
      },
    })
    setTopicosFilterState({
      isActive: data?.is_topics_active,
      filters: {
        topics: data?.topics[0],
      },
    })
  }

  const contextValue: OverViewContextType = {
    candidateList,
    mentions,
    filterName,
    candidatoFilter,
    topicosFilter,
    includedExcludedFilter,
    selectedFilter,
    changeFilter,
    changeSelectedFilter,
    changeCandidateList,
    changeActive,
    addToCandidateList,
    changeTopic,
    handleFilterName,
    changeFilterCandidate,
    setTopicosFilter,
    setIncludedExcludedFilter,
    changeIncludedTopics,
    changeMentions,
  }

  return <OverViewContext.Provider value={contextValue}>{children}</OverViewContext.Provider>
}

export const useOverview = () => {
  const context = useContext(OverViewContext)
  if (!context) {
    throw new Error('useOverview deve ser usado dentro de um OverviewProvider')
  }
  return context
}
