import { useState, useEffect, useCallback } from "react";
import { apiVox } from "services/apiService";
import { ICandidateType } from "componentsNew/CockpitComponents/types/adversaryTypes";
import { debounce } from "lodash";

interface IVolumeOfMentionsDataPoint {
  x: string;
  y: number;
}

interface IVolumeOfMentionsResponse {
  data: { [candidateId: string]: IVolumeOfMentionsDataPoint[] };
  cands: string[];
  totals: { [candidateId: string]: number };
}

interface IProcessedMentionData {
  candidate_name: string;
  candidate_id: string;
  coordinates: {
    x: string;
    y: number;
    y_percent: number;
  }[];
  total_mentions: number;
  color: string;
}

const formatDate = (dateString: string): string => {
  const date = new Date(dateString + "T00:00:00");
  const day = date.getDate().toString().padStart(2, "0");
  const monthNames = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];
  const month = monthNames[date.getMonth()];
  return `${day}/${month}`;
};

const useVolumeOfMentions = (
  candidateIds: string,
  socialNetworks: string,
  initial_date?: string,
  final_date?: string,
  selectedAdversary?: ICandidateType[],
) => {
  const [data, setData] = useState<IProcessedMentionData[] | null>(null);
  const [dates, setDates] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [cands, setCands] = useState<string[]>([]);
  const [totals, setTotals] = useState<{ [key: string]: number }>({});
  const [totalsSum, setTotalsSum] = useState<number>(0);

  const fetchData = async () => {
    if (!candidateIds || !socialNetworks) {
      setData([]);
      return;
    }

    try {
      setLoading(true);
      const params = new URLSearchParams({
        id_cand: candidateIds,
        page_type_name: socialNetworks,
      });

      if (initial_date) params.append("initial_date", initial_date);
      if (final_date) params.append("final_date", final_date);

      const response = await apiVox.get<IVolumeOfMentionsResponse>(
        `/vox/v1/political-vox/volume-of-mentions-summary?${params.toString()}`,
      );

      const { data: apiData, cands, totals } = response.data;
      setCands(cands);
      setTotals(totals);

      const sum = Object.values(totals).reduce(
        (acc, current) => acc + current,
        0,
      );
      setTotalsSum(sum);

      const allDatesSet = new Set<string>();
      Object.values(apiData).forEach((mentions) => {
        mentions.forEach((point) => {
          allDatesSet.add(point.x);
        });
      });

      const allDatesArray = Array.from(allDatesSet).sort();

      const processedData: IProcessedMentionData[] = cands.map(
        (candidateId) => {
          const candidateData = selectedAdversary?.find(
            (adversary) => adversary.candidate_id === candidateId,
          );

          const completeData = allDatesArray.map((date) => ({
            x: formatDate(date),
            y: apiData[candidateId]?.find((point) => point.x === date)?.y || 0,
            y_percent: 0,
          }));

          return {
            candidate_name: candidateData?.candidate_name || candidateId,
            candidate_id: candidateId,
            coordinates: completeData,
            total_mentions: totals[candidateId] || 0,
            color: candidateData?.color || "#FFFFFF",
          };
        },
      );

      allDatesArray.forEach((date, index) => {
        let totalMentionsOnDate = 0;
        processedData.forEach((candidate) => {
          totalMentionsOnDate += candidate.coordinates[index].y;
        });

        processedData.forEach((candidate) => {
          const mentions = candidate.coordinates[index].y;
          candidate.coordinates[index].y_percent = totalMentionsOnDate
            ? parseFloat(((mentions / totalMentionsOnDate) * 100).toFixed(2))
            : 0;
        });
      });

      setDates(allDatesArray.map(formatDate));
      setData(processedData);
    } catch (err) {
      console.error("Error fetching volume of mentions data:", err);
      setError("Falha ao buscar dados de Volume de Menções");
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(debounce(fetchData, 2000), [
    candidateIds,
    socialNetworks,
    initial_date,
    final_date,
    selectedAdversary,
  ]);

  useEffect(() => {
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  return { data, dates, cands, totals, totalsSum, loading, error };
};

export default useVolumeOfMentions;
