import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;

  .wrapperAdherence {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex: 1;
    padding: 4px;
    gap: 8px;
  }

  .title {
    display: flex;
    gap: 8px;
  }

  .barra {
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;

    .adherence-porcent {
      width: 100px;
      text-align: right;
    }
  }
`
