import styled from 'styled-components'

export const Wrapper = styled.main``

export const Box = styled.div`
  background: #161717;
  padding: 16px;
  border-radius: 8px;
  flex: 1;
`

export const WrapperProfileCard = styled.div`
  flex: 1;
  border-radius: 8px;
  padding: 8px;
  gap: 16px;
  background: #161717;
  min-height: 322px;
  display: flex;
  flex-direction: column;
  position: relative;
`
export const Heading = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const ContentList = styled.div`
  display: flex;
  gap: 16px;
  flex: 1;

  @media screen and (max-width: 1190px) {
    flex: 1;
  }
`

export const BoxListGrid = styled(Box)`
  flex: unset;
  padding: 8px;
  width: 100%;
  background: #111111;
`

export const DisabledContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
