import React from 'react'
import Card from '../../../../componentsNew/Card'
import { Container } from './styles'
import Text from '../../../../componentsNew/Text'
import ProgressMinimal from '../../../../componentsNew/ProgressMinimal'

const KeyFeature = ({ archetype }: any) => {
  const calculatePorcetagem = (number: number) => {
    if (!number && number !== 0) return '0'
    const percentage = number * 10
    return percentage.toFixed(0)
  }

  return (
    <Card title="Característica Chave">
      <Container>
        <div className="container-progress">
          <div className="key-feature-card-title">
            <Text size="xSmall">Driver:</Text>
          </div>
          <div className="key-feature-card-sub">
            <Text size="xSmall">{archetype?.driver_name}</Text>
          </div>
          <ProgressMinimal value={calculatePorcetagem(archetype?.score_driver)} />
          <div className="key-feature-card-porcent">
            <Text size="xSmall">{calculatePorcetagem(archetype?.score_driver)}%</Text>
          </div>
        </div>

        <div className="container-progress">
          <div className="key-feature-card-title">
            <Text size="xSmall">Motivador:</Text>
          </div>
          <div className="key-feature-card-sub">
            <Text size="xSmall">{archetype?.first_motivation_name}</Text>
          </div>
          <ProgressMinimal value={calculatePorcetagem(archetype?.first_motivation)} />
          <div className="key-feature-card-porcent">
            <Text size="xSmall">{calculatePorcetagem(archetype?.first_motivation)}%</Text>
          </div>
        </div>

        <div className="container-progress">
          <div className="key-feature-card-title">
            <Text size="xSmall">Bandeira:</Text>
          </div>
          <div className="key-feature-card-sub">
            <Text size="xSmall">{archetype?.second_motivation_name}</Text>
          </div>
          <ProgressMinimal value={calculatePorcetagem(archetype?.second_motivation)} />
          <div className="key-feature-card-porcent">
            <Text size="xSmall">{calculatePorcetagem(archetype?.second_motivation)}%</Text>
          </div>
        </div>
      </Container>
    </Card>
  )
}

export default KeyFeature
