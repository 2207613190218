import { faker } from '@faker-js/faker'
import { translateValue } from '../services/swotServices'
import {
  SwotTheme,
  SelectedSwotTheme,
  SwotThemeListResponse,
  SelectedSwotThemeListResponse,
  CreateSwotThemePayload,
  CreatedSwotThemeResponse,
  AcceptWizardParams,
  SwotWizard,
  BaseResponseApi,
  GetSwotWizardResponse,
  ListByTopicTypes,
  RandomSelectOption,
} from '../types/SWOT_DIAGNOSIS_TYPES'

const { animal, date, number, music } = faker

export const TOPIC_NAMES = ['Forças (S)', 'Oportunidades (O)', 'Fraqueza (W)', 'Ameaças (T)']
export const TOPIC_IDENTIFICATION = ['strengths', 'weaknesses', 'opportunities', 'threats']

const TOPIC = [
  { identification: 'strengths', name: 'Forças (S)' },
  { identification: 'weaknesses', name: 'Fraqueza (W)' },
  { identification: 'opportunities', name: 'Oportunidades (O)' },
  { identification: 'threats', name: 'Ameaças (T)' },
]

export const MOCK_ALL_THEMES = [
  {
    id: 1,
    name: 'Federacoes',
    theme_name: 'Federacoes',
  },
  {
    id: 2,
    name: 'Inseguranca Juridica',
    theme_name: 'Inseguranca Juridica',
    created: '2024-01-23T02:34:59.792543Z',
    updated: '2024-01-23T02:34:59.792561Z',
  },
  {
    id: 3,
    name: 'Alta Tributacao',
    theme_name: 'Alta Tributacao',
    created: '2024-01-23T02:44:00.529934Z',
    updated: '2024-01-23T02:44:00.529952Z',
  },
]

export const TOPIC_POSITIVE = ['Forças (S)', 'Oportunidades (O)']
export const TOPIC_NEGATIVE = ['Fraqueza (W)', 'Ameaças (T)']

export const generateBaseResponse = <T>(results: T[]): BaseResponseApi & { results: T[] } => {
  return {
    count: results.length,
    next: faker.internet.url(),
    previous: faker.internet.url(),
    results,
  }
}

const generateRandomSwotTheme = (): any => ({
  id: number.int({ max: 50 }),
  name: animal.dog(),
  created: date.anytime(),
  updated: date.anytime(),
})

const generateRandomSwotWizard = (): SwotWizard => ({
  id: number.int(),
  theme_name: music.genre(),
  topic: number.int(),
  status: 'pending',
  created: date.anytime(),
  updated: date.anytime(),
  person: number.int(),
  theme: number.int(),
  topic_name: faker.helpers.arrayElement(TOPIC_NAMES),
  is_positive: faker.datatype.boolean({ probability: 0.5 }),
})

export const MOCK_SWOT_THEME_LIST: SwotTheme[] = TOPIC_NAMES.map(() => generateRandomSwotTheme())

const SWOT_WIZARDS_ARRAY = Array.from({ length: 30 }).map(() => generateRandomSwotWizard())

export const MOCK_SWOT_WIZARDS: GetSwotWizardResponse = generateBaseResponse(SWOT_WIZARDS_ARRAY)

const generateRandomSelectOption = (topic_name: string): RandomSelectOption => {
  const theme_name = faker.animal.dog()
  return {
    id: faker.number.int({ max: 50 }),
    topic: faker.number.int(),
    name: theme_name,
    theme_name,
    created: faker.date.anytime(),
    updated: faker.date.anytime(),
    label: topic_name,
    value: faker.number.int({ max: 100 }),
    topic_name,
    active: false,
  }
}

const generateRandomSwotSelectionPositiveItem = (topic: string, identification: string): ListByTopicTypes => {
  const id = number.int({ max: 100 })
  return {
    id,
    topic,
    topic_name: topic,
    is_positive: translateValue(identification),
    selection_list: Array.from({ length: 20 }).map(() => generateRandomSelectOption(topic)),
    selected_values: [],
    theme_list: Array.from({ length: 8 }),
  }
}

export const MOCK_SWOT_SELECTION_POSITIVE: ListByTopicTypes[] = TOPIC.map(topic =>
  generateRandomSwotSelectionPositiveItem(topic.name, topic.identification)
)

const generateRandomSwotSelectionResponse = (topic: string, identification: string): SelectedSwotTheme => {
  const id = number.int()
  const theme_name = animal.dog()
  return {
    id,
    theme_name,
    is_recommended: true,
    is_listening: true,
    topic: identification,
    topic_name: topic,
    created: date.anytime(),
    updated: date.anytime(),
    person: number.int(),
    theme: number.int(),
  }
}

export const MOCK_SWOT_SELECTION_RESPONSE_THEME = TOPIC.map(topic =>
  generateRandomSwotSelectionResponse(topic.name, topic.identification)
)

export const MOCK_SWOT_THEME_LIST_RESPONSE: SwotThemeListResponse = {
  count: MOCK_SWOT_THEME_LIST.length,
  next: 'http://example.com',
  previous: 'http://example.com',
  results: MOCK_SWOT_THEME_LIST,
}

export const MOCK_SWOT_SELECTION_RESPONSE: SelectedSwotThemeListResponse = {
  count: MOCK_SWOT_SELECTION_POSITIVE.length,
  next: 'http://example.com',
  previous: 'http://example.com',
  results: MOCK_SWOT_SELECTION_RESPONSE_THEME,
}

export const MOCK_CREATE_SWOT_THEME_PAYLOAD: CreateSwotThemePayload = {
  is_recommended: true,
  is_listening: true,
  topic: 'Politica',
  person: number.int(),
  theme: number.int(),
}

export const MOCK_CREATED_SWOT_THEME_RESPONSE: CreatedSwotThemeResponse = {
  id: number.int(),
  theme_name: animal.dog(),
  is_recommended: true,
  is_listening: true,
  topic: 'Politica',
  created: date.anytime(),
  updated: date.anytime(),
  person: number.int(),
  theme: number.int(),
}

export const MOCK_ACCEPT_WIZARD_PARAMS: AcceptWizardParams = {
  wizard_id: number.int(),
  status: 'approved',
  topic: number.int(),
}
