import { css, styled } from 'styled-components'

export const Wrapper = styled.main``

export const Content = styled.div`
  height: 420px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CardItem = styled.div<{ activeFilter?: boolean }>`
  ${({ activeFilter }) => css`
    background: #0a0a0a;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    border-color: ${activeFilter && '#FFC000'};
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  `}
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 700;
  color: #e9e9f0;
`

export const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #e9e9f0;
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
`
