import { styled } from "styled-components";


export const Wrapper = styled.main`
padding: 12px;
background: red;
flex: 1;
margin-top: 12px;
background: #161717;
border-radius: 8px;



display: flex;
align-items: center;
justify-content: center;

color: #494949;
font-size: 16px;
font-weight: 400;
min-height: 116px;
`