import { styled } from 'styled-components'

export const Wrapper = styled.main`
  background: #121212f7;
  border-radius: 16px;
  padding: 16px;
  width: 100%;
  max-width: 710px;
  min-height: 450px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`

export const Content = styled.div`
  width: 100%;
  flex: 1;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`
