import IconCockpit from "../../icons/icon-cockpit.svg";
import IconApplicationStrategy from "../../icons/icon-application-strategy.svg";
import IconStrategicAnalysis from "../../icons/icon-strategic-analysis.svg";
import IconApplicationsGoals from "../../icons/icon-meta-cockpit.svg";
import IconApplicationManagement from "../../icons/icon-application-management.svg";
import IconCampaignTeam from "../../icons/icon-campaign-team.svg";
import IconElectoralIntelligence from "../../icons/icon-electoral-intelligence.svg";
import IconAnalysis from "../../icons/icon-analysis.svg";
import IconElectionPools from "../../icons/icon-election-pools.svg";
import IconPoliticalListening from "../../icons/icon-political-listening.svg";
import iconPreferredCandidate from "../../assets/icon-preferred-candidate.svg";
import IconPoliticalArchetype from "../../icons/icon-political-archetype.svg";
import IconPoliticalTrajectory from "../../icons/icon-political-trajectory.svg";
import IconElectoralProfile from "../../icons/icon-electoral-profile.svg";
import IconPortfolioApplications from "../../assets/icon-portfolio-applications.svg";
import IconSponsoredApplications from "../../assets/icon-sponsored-applications.svg";
import IconAllApplications from "../../assets/icon-all-applications.svg";

const sibBarMenu = [
  {
    title: "Cockpit Candidatura",
    label: "Cockpit Candidatura",
    icon: IconCockpit,
    path: "/dashboard/cockpit",
    subMenu: null,
    isActive: true,
  },
  {
    title: "Estratégia De Candidatura",
    icon: IconApplicationStrategy,
    path: null,
    isActive: true,
    subMenu: [
      {
        title: "Análise Estratégica",
        icon: IconStrategicAnalysis,
        label: "Análise Estratégica",
        path: "/dashboard/application-strategy/strategy",
        isActive: true,
      },
      {
        title: "Metas De Candidatura",
        label: "Metas De Candidatura",
        icon: IconApplicationsGoals,
        path: "/dashboard/application-strategy/goals",
        isActive: true,
      },
    ],
  },
  {
    title: "Gestão Da Candidatura",
    icon: IconApplicationManagement,
    path: null,
    isActive: true,
    subMenu: [
      {
        title: "Equipe",
        label: "Equipe",
        icon: IconCampaignTeam,
        path: "/dashboard/application-management/team",
        isActive: true,
      },
    ],
  },
  {
    title: "Inteligência Política",
    icon: IconElectoralIntelligence,
    path: null,
    isActive: true,
    subMenu: [
      {
        title: "Diagnóstico Eleitor",
        icon: IconAnalysis,
        label: "Diagnóstico Eleitor",
        path: "/dashboard/political-intelligence/voter-diagnosis",
        isActive: true,
      },
      {
        title: "Diagnóstico Eleições",
        icon: IconAnalysis,
        label: "Diagnóstico Eleições",
        path: "/dashboard/political-intelligence/election-diagnosis",
        isActive: true,
      },
      {
        title: "Pesquisas Eleitorais",
        icon: IconElectionPools,
        label: "Pesquisas Eleitorais",
        path: "/dashboard/political-intelligence/election-polls",
        isActive: false,
      },
      {
        title: "Politica Vox® Candidatura",
        icon: IconPoliticalListening,
        label: "Politica Vox® Candidatura",
        path: "/dashboard/political-intelligence/political-listening",
        isActive: true,
      },
      {
        title: "Politica Vox® Pesquisa",
        icon: IconPoliticalListening,
        label: "Politica Vox® Pesquisa",
        path: "/dashboard/political-intelligence/political-vox-search",
        isActive: true,
      },
      {
        title: "Candidato Preferido",
        icon: iconPreferredCandidate,
        label: "Candidato Preferido",
        path: "/dashboard/political-intelligence/preferred-candidate",
        isActive: false,
      },
      {
        title: "Arquétipo Político®",
        icon: IconPoliticalArchetype,
        label: "Arquétipo Político®",
        path: "/dashboard/political-intelligence/political-archetype",
        isActive: false,
      },
    ],
  },
  {
    title: "Trajetória Política",
    icon: IconPoliticalTrajectory,
    path: null,
    isActive: true,
    subMenu: [
      {
        title: "Perfil Político",
        icon: IconElectoralProfile,
        label: "Perfil Político",
        path: "/dashboard/political-trajectory/political-profile",
        isActive: false,
      },
    ],
  },
  {
    title: "Portifólio De Candidaturas",
    icon: IconPortfolioApplications,
    path: null,
    isActive: true,
    subMenu: [
      {
        title: "Diagnóstico Partido",
        label: "Diagnóstico Partido",
        icon: IconAnalysis,
        path: "/dashboard/portfolio/party-diagnosis",
        isActive: true,
      },
      {
        title: "Candidaturas Patrocinadas",
        label: "Candidaturas Patrocinadas",
        icon: IconSponsoredApplications,
        path: "/dashboard/sponsored-applications",
        isActive: true,
      },
      {
        title: "Todas As Candidaturas",
        label: "Todas As Candidaturas",
        icon: IconAllApplications,
        path: "/dashboard/all-applications",
        isActive: true,
      },
      {
        title: "Politica Vox® Partido",
        label: "Politica Vox® Partido",
        icon: IconPoliticalListening,
        path: "/dashboard/political-vox",
        isActive: true,
      },
    ],
  },
];

export default sibBarMenu;
