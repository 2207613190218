import { api } from "services/apiService";
import { IAdversaryResponse } from "componentsNew/CockpitComponents/types/adversaryTypes";

export async function getAllAdversary(): Promise<IAdversaryResponse> {
  try {
    const url = `/electoral-intelligence/adversary/`;
    const { data } = await api.get(url);

    const filteredResults = data.results.filter(
      (item: any) => item?.is_listening,
    );

    return {
      count: filteredResults.length,
      next: data.next,
      previous: data.previous,
      results: filteredResults,
    };
  } catch (e) {
    console.error("Erro na requisição:", e);
    return {
      count: 0,
      next: null,
      previous: null,
      results: [],
    };
  }
}
