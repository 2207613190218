import React, { useState, useEffect } from 'react'
import Select, { MultiValue, ActionMeta, GroupBase, OptionsOrGroups } from 'react-select'
import Text from 'componentsNew/Text'
import InternalButton from 'componentsNew/InternalButton'
import { IoCloseOutline } from 'react-icons/io5'
import { FaCheck } from 'react-icons/fa6'
import { useTab } from 'global/TabContext'
import { useCandidateDiagnosticFilterContext } from '../../../global/CandidateDiagnosticFilterContext'
import { useDiagnosisFilter } from '../../../global/DiagnosisFilterContext'
import * as S from './styles'
import { pageFilters } from './utils/defaultFilters'
import { customStyles, selectTheme } from './utils/selectTheme'

interface IDiagnosisFilter {
  reportName: string
  onFiltersChange: (filtersDiagnosis: any) => void
}

interface OptionType {
  value: string
  label: string
}

const DiagnosisFilter: React.FC<IDiagnosisFilter> = ({ reportName, onFiltersChange }) => {
  const { defaultFilters, values } = useCandidateDiagnosticFilterContext()
  const { setFilters } = useDiagnosisFilter()
  const { activeTabContext } = useTab()

  const loadSavedFilters = () => {
    const savedFilters = localStorage.getItem('diagnosisFilters')
    if (savedFilters) {
      return JSON.parse(savedFilters)
    }
    return defaultFilters
  }

  const [selectedYear, setSelectedYear] = useState<OptionType[]>(
    loadSavedFilters().year.map((year: string) => ({ value: year, label: year }))
  )
  const [selectedState, setSelectedState] = useState<OptionType[]>(
    loadSavedFilters().state.map((state: string) => ({ value: state, label: state }))
  )
  const [selectedCity, setSelectedCity] = useState<OptionType[]>(
    loadSavedFilters().city.map((city: string) => ({ value: city, label: city }))
  )
  const [selectedPosition, setSelectedPosition] = useState<OptionType[]>(
    loadSavedFilters().position.map((position: string) => ({ value: position, label: position }))
  )
  const [selectedParty, setSelectedParty] = useState<OptionType[]>(
    loadSavedFilters().party.map((party: string) => ({ value: party, label: party }))
  )
  const [selectedCandidate, setSelectedCandidate] = useState<OptionType[]>(
    loadSavedFilters().candidate.map((candidate: string) => ({ value: candidate, label: candidate }))
  )
  const [selectedNeighborhood, setSelectedNeighborhood] = useState<OptionType[]>(
    loadSavedFilters().neighborhood.map((neighborhood: string) => ({ value: neighborhood, label: neighborhood }))
  )
  const [selectedRound, setSelectedRound] = useState<OptionType[]>(
    loadSavedFilters().round.map((round: string) => ({ value: round, label: round }))
  )

  const filterConfig = pageFilters[reportName as keyof typeof pageFilters] || []
  const valueConfig = values[reportName as keyof typeof values]?.[0] || {}

  useEffect(() => {
    const savedFilters = loadSavedFilters()
    setSelectedYear(savedFilters.year.map((year: string) => ({ value: year, label: year })))
    setSelectedState(savedFilters.state.map((state: string) => ({ value: state, label: state })))
    setSelectedCity(savedFilters.city.map((city: string) => ({ value: city, label: city })))
    setSelectedPosition(savedFilters.position.map((position: string) => ({ value: position, label: position })))
    setSelectedParty(savedFilters.party.map((party: string) => ({ value: party, label: party })))
    setSelectedCandidate(savedFilters.candidate.map((candidate: string) => ({ value: candidate, label: candidate })))
    setSelectedNeighborhood(
      savedFilters.neighborhood.map((neighborhood: string) => ({ value: neighborhood, label: neighborhood }))
    )
    setSelectedRound(savedFilters.round.map((round: string) => ({ value: round, label: round })))
  }, [])

  /* useEffect(() => {
    clearFilters()
  }, [activeTabContext])
 */
  const applyFilters = () => {
    const filtersObject = {
      year:
        selectedYear.length && selectedYear[0].value !== 'TODOS'
          ? selectedYear.map(year => year.value)
          : valueConfig.year || [],
      state: selectedState.length ? selectedState.map(state => state.value) : defaultFilters.state,
      city: selectedCity.length ? selectedCity.map(city => city.value) : defaultFilters.city,
      position:
        selectedPosition.length && selectedPosition[0].value !== 'TODOS'
          ? selectedPosition.map(position => position.value)
          : valueConfig.position ?? [],
      party: selectedParty.length ? selectedParty.map(party => party.value) : valueConfig.partido ?? [],
      candidate: selectedCandidate.length
        ? selectedCandidate.map(candidate => candidate.value)
        : valueConfig.candidato ?? [],
      neighborhood: selectedNeighborhood.length
        ? selectedNeighborhood.map(neighborhood => neighborhood.value)
        : defaultFilters.neighborhood,
      round: selectedRound.length ? selectedRound.map(round => round.value) : valueConfig.round ?? [],
    }

    onFiltersChange(filtersObject)
    setFilters(filtersObject)
    localStorage.setItem('diagnosisFilters', JSON.stringify(filtersObject))
  }

  const clearFilters = () => {
    setSelectedYear(valueConfig.year.map((year: number) => ({ value: year.toString(), label: year.toString() })))
    setSelectedState([{ value: defaultFilters.state[0], label: defaultFilters.state[0] }])
    setSelectedCity([{ value: defaultFilters.city[0], label: defaultFilters.city[0] }])
    setSelectedPosition(valueConfig.position.map((position: string) => ({ value: position, label: position })))
    setSelectedParty([])
    setSelectedCandidate([])
    setSelectedNeighborhood(
      defaultFilters.neighborhood.map((neighborhood: string) => ({ value: neighborhood, label: neighborhood }))
    )
    setSelectedRound([{ value: defaultFilters.round[0], label: defaultFilters.round[0] }])
    setFilters({
      ...defaultFilters,
      year: valueConfig.year,
      position: valueConfig.position,
    })
    localStorage.removeItem('diagnosisFilters')
  }

  const handleNeighborhoodChange = (selected: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    if (selected.some(option => option.value === 'TODOS')) {
      if (selected.length === 1) {
        setSelectedNeighborhood(
          defaultFilters.neighborhood.map((neighborhood: string) => ({ value: neighborhood, label: neighborhood }))
        )
      } else {
        setSelectedNeighborhood([{ value: 'TODOS', label: 'TODOS' }])
      }
    } else {
      setSelectedNeighborhood(selected as OptionType[])
    }
  }

  const handleYearChange = (selected: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    if (selected.some(option => option.value === 'TODOS')) {
      if (selected.length === 1) {
        setSelectedYear(valueConfig.year.map((year: number) => ({ value: year.toString(), label: year.toString() })))
      } else {
        setSelectedYear([{ value: 'TODOS', label: 'TODOS' }])
      }
    } else {
      setSelectedYear(selected as OptionType[])
    }
  }

  const handlePositionChange = (selected: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    if (selected.some(option => option.value === 'TODOS')) {
      if (selected.length === 1) {
        setSelectedPosition(valueConfig.position.map((position: string) => ({ value: position, label: position })))
      } else {
        setSelectedPosition([{ value: 'TODOS', label: 'TODOS' }])
      }
    } else {
      setSelectedPosition(selected as OptionType[])
    }
  }

  const allNeighborhoodsSelected = selectedNeighborhood.length === defaultFilters.neighborhood.length
  const allYearsSelected = selectedYear.length === valueConfig.year.length
  const allPositionsSelected = selectedPosition.length === valueConfig.position.length

  const neighborhoodOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>> = [
    { value: 'TODOS', label: 'TODOS' },
    ...defaultFilters.neighborhood.map((neighborhood: string) => ({
      value: neighborhood,
      label: neighborhood,
    })),
  ]

  const yearOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>> = [
    { value: 'TODOS', label: 'TODOS' },
    ...valueConfig.year.map((year: number) => ({
      value: year.toString(),
      label: year.toString(),
    })),
  ]

  const positionOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>> = [
    { value: 'TODOS', label: 'TODOS' },
    ...valueConfig.position.map((position: string) => ({
      value: position,
      label: position,
    })),
  ]

  return (
    <S.Container>
      <S.SelectContainer>
        <S.Title>
          <Text color="yellow" size="xSmall" bold>
            Filtros Gerais
          </Text>
        </S.Title>

        {filterConfig.some((f: { column: string }) => f.column === 'ANO_ELEICAO') && (
          <S.SelectContent>
            <S.Label>Ano Eleições</S.Label>
            <Select
              options={yearOptions}
              isMulti
              onChange={handleYearChange}
              value={
                selectedYear.length === valueConfig.year.length ? [{ value: 'TODOS', label: 'TODOS' }] : selectedYear
              }
              styles={customStyles}
              placeholder="Ano Eleições"
              theme={selectTheme}
            />
          </S.SelectContent>
        )}

        {filterConfig.some((f: { column: string }) => f.column === 'NM_TURNO') && (
          <S.SelectContent>
            <S.Label>Turno</S.Label>
            <Select
              options={valueConfig.round?.map((round: string) => ({ value: round, label: round }))}
              onChange={selected =>
                setSelectedRound(selected ? [{ value: selected.value, label: selected.label }] : [])
              }
              value={selectedRound}
              styles={customStyles}
              placeholder="Turno"
              theme={selectTheme}
            />
          </S.SelectContent>
        )}

        {filterConfig.some((f: { column: string }) => f.column === 'db_estados.uf') && (
          <S.SelectContent>
            <S.Label>Estados</S.Label>
            <Select
              options={defaultFilters.state.map((state: string) => ({ value: state, label: state }))}
              onChange={selected =>
                setSelectedState(selected ? [{ value: selected.value, label: selected.label }] : [])
              }
              value={selectedState}
              styles={customStyles}
              placeholder="Estados"
              theme={selectTheme}
            />
          </S.SelectContent>
        )}

        {filterConfig.some((f: { column: string }) => f.column === 'Cidade') && (
          <S.SelectContent>
            <S.Label>Município</S.Label>
            <Select
              options={defaultFilters.city.map((city: string) => ({ value: city, label: city }))}
              onChange={selected => setSelectedCity(selected ? [{ value: selected.value, label: selected.label }] : [])}
              value={selectedCity}
              styles={customStyles}
              placeholder="Municípios"
              theme={selectTheme}
            />
          </S.SelectContent>
        )}

        {filterConfig.some((f: { column: string }) => f.column === 'nm_bairro') && (
          <S.SelectContent>
            <S.Label>Bairros</S.Label>
            <Select
              options={neighborhoodOptions}
              isMulti
              onChange={handleNeighborhoodChange}
              value={allNeighborhoodsSelected ? [{ value: 'TODOS', label: 'TODOS' }] : selectedNeighborhood}
              styles={customStyles}
              placeholder="Bairro"
              theme={selectTheme}
            />
          </S.SelectContent>
        )}

        {filterConfig.some(
          (f: { column: string }) =>
            f.column === 'Partido' || f.column === 'DS_CARGO_PERGUNTA' || f.column === 'Candidato'
        ) && (
          <S.Title>
            <Text color="yellow" size="xSmall" bold>
              Filtros Específicos
            </Text>
          </S.Title>
        )}

        {filterConfig.some((f: { column: string }) => f.column === 'Partido') && (
          <S.SelectContent>
            <S.Label>Partido</S.Label>
            <Select
              options={valueConfig.partido?.map((partido: string) => ({ value: partido, label: partido }))}
              onChange={selected =>
                setSelectedParty(selected ? [{ value: selected.value, label: selected.label }] : [])
              }
              value={selectedParty}
              styles={customStyles}
              placeholder="Partido"
              theme={selectTheme}
            />
          </S.SelectContent>
        )}

        {filterConfig.some((f: { column: string }) => f.column === 'DS_CARGO_PERGUNTA') && (
          <S.SelectContent>
            <S.Label>Cargo</S.Label>
            <Select
              options={positionOptions}
              isMulti
              onChange={handlePositionChange}
              value={
                selectedPosition.length === valueConfig.position.length
                  ? [{ value: 'TODOS', label: 'TODOS' }]
                  : selectedPosition
              }
              styles={customStyles}
              placeholder="Cargo"
              theme={selectTheme}
            />
          </S.SelectContent>
        )}

        {filterConfig.some((f: { column: string }) => f.column === 'Candidato') && (
          <S.SelectContent>
            <S.Label>Candidato</S.Label>
            <Select
              options={valueConfig.candidato?.map((candidato: string) => ({ value: candidato, label: candidato }))}
              onChange={selected =>
                setSelectedCandidate(selected ? [{ value: selected.value, label: selected.label }] : [])
              }
              value={selectedCandidate}
              styles={customStyles}
              placeholder="Candidato"
              theme={selectTheme}
            />
          </S.SelectContent>
        )}
      </S.SelectContainer>
      <S.ButtonsContainer>
        <InternalButton textColor="#000000" background="#ffc000" icon={<FaCheck size={14} />} onClick={applyFilters}>
          Aplicar filtros
        </InternalButton>
        <InternalButton background="#D70708" icon={<IoCloseOutline size={19} />} onClick={clearFilters}>
          Limpar filtros
        </InternalButton>
      </S.ButtonsContainer>
    </S.Container>
  )
}

export default DiagnosisFilter
