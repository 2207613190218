import Button from 'componentsNew/Button'
import { Empty } from 'componentsNew/Empty'
import { getThemesByTopic } from 'componentsNew/PoliticalVox/services/overViewServices'
import Spin from 'componentsNew/Spin'
import { useFilterVox } from 'hooks/useFilterVox'
import { useEffect, useState } from 'react'
import { CardTopic } from '../CardTopic'
import * as S from './styles'

export const FilterTags = ({ topicList }: { topicList?: any }) => {
  const { handleFilters, topicosFilter, changeTopic, themeList, setThemeList } = useFilterVox()
  const loadedThemeList = topicosFilter?.filters?.subtopics

  const [activeTags, setActiveTags] = useState<any[]>(loadedThemeList || [])
  const [loadingClear, setLoadingClear] = useState(false)

  const mergedTopicList = topicList?.length > 0 ? [...topicList] : []

  const activeCard = topicosFilter?.filters?.topics && topicosFilter?.filters?.topics[0]

  useEffect(() => {
    if (activeCard) {
      fetchThemesByTopic(activeCard?.id)
    }
  }, [activeCard])

  const fetchThemesByTopic = async (activeId: string) => {
    if (activeId !== 'all_tags') {
      try {
        const topic_list = await getThemesByTopic(activeId)
        setThemeList(topic_list)
      } catch (e) {
        console.log(e)
      }
    } else {
      setThemeList([])
    }
  }

  const handlePrimaryTOpic = ({ id, name }: { id: string; name: string }) => {
    const newData = [{ id, name }]
    setActiveTags([])
    changeTopic('topics', newData)
    handleFilters('topic', name)
    changeTopic('subtopics', [])
    handleFilters('subtopics', [])
  }

  const handleTag = (selectedTag: any) => {
    const existingTag = activeTags.filter(tag => tag?.id === selectedTag?.id)
    if (existingTag.length > 0) {
      const newTagList = activeTags.filter(tag => tag?.id !== selectedTag?.id)
      const dataToApi = newTagList.map(item => item?.id)
      setActiveTags(newTagList)
      changeTopic('subtopics', dataToApi)
      handleFilters('subtopics', newTagList)
    } else {
      const newTagList = [...activeTags, selectedTag]
      const dataToApi = newTagList.map(item => item?.id)
      setActiveTags(newTagList)
      changeTopic('subtopics', dataToApi)
      handleFilters('subtopics', newTagList)
    }
  }

  const handleClearFilter = () => {
    setLoadingClear(true)
    handleFilters('topic', '')
    handleFilters('subtopics', [])
    changeTopic('topics', [{ id: '', name: '' }])
    changeTopic('subtopics', [])
    setActiveTags([])
    setLoadingClear(false)
  }

  const emptyText = /* !activeCard ? 'Nenhum tema encontrado' :  */ 'Nenhum tópico selecionado'

  return (
    <S.Wrapper>
      <S.Content>
        <S.ContentFilterTag>
          <S.Heading>Filtros por tópicos</S.Heading>

          {loadingClear ? (
            <div className="centered_div-empty">
              <Spin />
            </div>
          ) : (
            <S.ListFilterTag>
              {mergedTopicList.map(tag => (
                <CardTopic
                  key={tag.slug || tag?.id}
                  slug={tag.slug || tag?.name}
                  color={tag.color === 'transparent' ? '#bebebe' : tag.color}
                  icon={tag.icon}
                  onClick={() => handlePrimaryTOpic({ id: tag?.id, name: tag?.name || tag.slug })}
                  isActive={tag?.name === activeCard?.name}
                />
              ))}
            </S.ListFilterTag>
          )}
        </S.ContentFilterTag>

        <S.ContentListTags>
          <S.Heading>Filtros por sub-tópicos</S.Heading>

          <S.TagBox>
            {themeList.length > 0 ? (
              themeList.map((item: any) => (
                <S.TagItem
                  key={item?.id}
                  onClick={() => handleTag(item)}
                  active={activeTags.filter(tag => item?.id === tag?.id).length > 0}
                >
                  {item?.name}
                </S.TagItem>
              ))
            ) : (
              <div className="centered_div-empty">
                <Empty description={emptyText} />
              </div>
            )}
          </S.TagBox>
        </S.ContentListTags>
      </S.Content>
      <S.ContentActions>
        <Button variant="outline" onClick={handleClearFilter}>
          Limpar filtro
        </Button>
        {/*  <Button>Aplicar filtro</Button> */}
      </S.ContentActions>
    </S.Wrapper>
  )
}
