import styled from 'styled-components'

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  background-color: #0a0a0a;
  border: 1px solid #ffffff;
  border-radius: 24px;
  width: 100%;
  box-sizing: border-box;
`

export const StyledInput = styled.input`
  flex-grow: 1;
  border: none;
  background-color: transparent;
  color: #ffffff;
  padding: 4px;
  font-size: 12px;

  &::placeholder {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: #ffffff;
    opacity: 0.3;
  }

  &:focus {
    outline: none;
  }
`

export const SendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 4px;
  background: #ffc000;
  width: 22px;
  height: 22px;

  &:hover {
    opacity: 0.8;
  }

  svg {
    height: 17px;
    width: 17px;
  }
`
