import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import Text from '../Text'
import * as S from './styles'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import Button from '../Button'

export interface IModal {
  closeModal: () => void
  title?: string
  children?: any
  onPrimaryClick?: () => void
  primaryTextButton?: string
  onSecondaryClick?: () => void
  secondaryTextButton?: string
  primaryButtonDisabled?: boolean
  secondaryButtonMinimal?: boolean
}

const Modal = ({
  closeModal,
  title,
  children,
  onPrimaryClick,
  primaryTextButton,
  primaryButtonDisabled,
  onSecondaryClick,
  secondaryTextButton = '',
  secondaryButtonMinimal,
}: IModal) => {
  const ref = useRef<HTMLHeadingElement>(null)

  useOnClickOutside(ref, () => closeModal())

  const footerCenter = secondaryTextButton !== ''

  return ReactDOM.createPortal(
    <S.FullScreen>
      <S.Container ref={ref} footerCenter={footerCenter}>
        <div className="header">
          <Text bold size="large">
            {title}
          </Text>
        </div>
        <div className="divider" />

        <div className="content">{children}</div>
        <footer className="footer">
          {secondaryTextButton && onSecondaryClick && (
            <Button onClick={onSecondaryClick} variant={secondaryButtonMinimal ? 'minimal' : 'outline'} fullWidth>
              {secondaryTextButton}
            </Button>
          )}
          {onPrimaryClick && primaryTextButton && (
            <Button
              onClick={onPrimaryClick}
              variant={primaryButtonDisabled ? 'darkBg' : 'primary'}
              fullWidth={footerCenter}
            >
              {primaryTextButton}
            </Button>
          )}
        </footer>
      </S.Container>
    </S.FullScreen>,
    document.body
  )
}

export default Modal
