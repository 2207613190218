import styled from 'styled-components'

export const Wrapper = styled.main`
  color: white;
`

export const ActionBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  .title {
    color: #e9e9f0;
    font-weight: 500;
    font-size: 16px;
  }
`

export const Content = styled.div``
export const ListContent = styled.div`
  max-height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const HeaderTable = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 4px 16px;
  background: #161717;
  border-radius: 8px;
  margin: 8px 0;

  .name_field {
    width: 95px;
    text-align: left;
  }

  .last_access {
    margin-right: -60px;
  }
`

export const TextTable = styled.h3`
  font-weight: 700;
  font-size: 12px;
  color: #e9e9f0;
  text-align: center;
  margin-right: 5%;
`

export const WaitingContent = styled.div`
  min-height: 134px;
  background: #161717;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 16px;

  h1 {
    color: #e9e9f0;
    font-weight: 500;
    font-size: 16px;
  }
`

export const TitleWaiting = styled.h3``
export const CardWaiting = styled.div``
