import { Tooltip } from "antd";
import React, { FC, useState, useEffect } from "react";
import * as S from "./styles";

interface Pointer {
  position: number;
  color: string;
  label: string;
  top: boolean;
}

export interface ProgressBarProps {
  progress: number;
  pointers?: Pointer[];
  daysToElection: number;
  width?: `${number}px` | `${number}%`;
  isCockpit?: boolean;
}

const ProgressBar: FC<ProgressBarProps> = ({
  progress,
  pointers = [],
  daysToElection,
  width = "887px",
  isCockpit = false,
}) => {
  const [activePointerColor, setActivePointerColor] = useState<string | null>(
    null,
  );

  const defaultPointers: Pointer[] = [
    {
      position: 0,
      color: "#E9E9F0",
      label: "início",
      top: false,
    },
    {
      position: 1,
      color: "#E9E9F0",
      label: "eleição",
      top: false,
    },
  ];

  useEffect(() => {
    const findActivePointerColor = () => {
      let activeColor = null;

      for (const pointer of pointers) {
        if (progress <= pointer.position) {
          activeColor = pointer.color;
        } else {
          break;
        }
      }

      return activeColor;
    };

    const newColor = findActivePointerColor();
    if (newColor !== activePointerColor) {
      setActivePointerColor(newColor);
    }
  }, [progress, pointers, activePointerColor]);

  const allPointers = [...defaultPointers, ...pointers];

  return (
    <S.Wrapper width={width} isCockpit={isCockpit}>
      <S.HeadingProgress>
        <S.labelHeading>Dias para a eleição</S.labelHeading>
        <S.HeadingText>
          Dia das eleições: <strong>06 de Outubro de 2024</strong>
        </S.HeadingText>
        <S.labelHeading
          color={(progress === 100 && "#E9E9F0") || activePointerColor}
        >
          Faltam <span>{progress > 1 ? `${progress} dias` : `0 dias`}</span>
        </S.labelHeading>
      </S.HeadingProgress>

      <S.ProgressBarWrapper>
        {daysToElection > 0 &&
          daysToElection <= 365 &&
          allPointers.map((pointer, index) => {
            if (index === 0) {
              return null;
            }
            return pointer.position > 0 ? (
              <>
                <S.PointerMarker
                  key={index}
                  position={pointer.position}
                  color={pointer.color}
                  top={pointer.top}
                  maxPercent={daysToElection}
                />
                <Tooltip title={`${pointer.position} ${pointer.label}`}>
                  <S.PointerLabel
                    key={index}
                    position={pointer.position}
                    top={pointer.top}
                    maxPercent={daysToElection}
                  >
                    {pointer.position > 1
                      ? `${pointer.position} dias (${pointer.label})`
                      : `0 dias (eleição)`}
                  </S.PointerLabel>
                </Tooltip>
              </>
            ) : null;
          })}
        <S.ProgressIndicator
          maxPercent={daysToElection}
          progress={progress}
          color={activePointerColor || "#E9E9F0"}
        />
      </S.ProgressBarWrapper>
    </S.Wrapper>
  );
};

export default ProgressBar;
