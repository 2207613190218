import ReactInputMask from 'react-input-mask'
import styled, { css } from 'styled-components'

import { FormGeneratorProps } from '.'

type FormStyleTypes = Pick<FormGeneratorProps, 'templateMode'>

const FormModifier = {
  responsive: () => css`
    .group {
      display: flex;
      flex-wrap: wrap;
      gap: 28px;
    }

    ${FormGroup} {
      width: unset;
      min-width: 200px;
      margin-bottom: 0;
    }
  `,

  column: () => css`
    .group {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 28px;
      flex: 1;
    }

    ${FormGroup} {
      margin-bottom: 15px;
      width: 100%;
    }
  `,
}

export const Form = styled.form<FormStyleTypes>`
  ${({ templateMode = 'column' }) => css`
    margin: 0;
    width: 100%;
    /*    height: calc(100% - 50px); */
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    /* flex: 1; */
    flex-direction: column;
    justify-content: space-between;

    .group {
      width: 100%;
    }

    ${!!templateMode && FormModifier[templateMode]()};
  `}
`

export const FormGroup = styled.div<{ fullWidthInput?: boolean }>`
  margin-bottom: 15px;
  width: 100%;
  flex: ${({ fullWidthInput }) => (fullWidthInput ? '100% ' : '1')};
  span {
    color: white;
  }
`

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: red;
`

export const Input = styled.input`
  width: 100%;
  display: inline-block;
  width: 100%;
  padding: 4px 12px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  transition: border-color 0.3s;

  &:hover,
  &:focus {
    border-color: #e0af00;
  }
`

export const CustomInput = styled(ReactInputMask)`
  width: 100%;
  height: 32px;
  padding: 6px 8px;
  font-size: 14px;
  line-height: 1.5715;
  color: rgba(175, 175, 175, 1);
  background-color: #fff;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 8px;
  transition: border-color 0.3s;

  &:hover {
    border-color: #1d7707;
  }

  &:focus {
    border-color: #1d7707;
    box-shadow: 0 0 0 2px rgba(42, 170, 10, 0.1);
  }

  &::placeholder {
    color: #bfbfbf;
  }
`

export const Select = styled.select`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
`

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;

  input {
    margin-right: 5px;
  }
`

export const SubmitBox = styled.div<{ actionStyle?: 'primary' | 'secondary' }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*   flex-direction: column; */
  margin-top: 20px;
  gap: 8px;

  button {
    max-width: 200px;
  }

  /*  .fullWidth {
    width: 100%;
  } */

  ${({ actionStyle }) => css`
    ${actionStyle === 'secondary' &&
    css`
      flex-direction: row-reverse;
      justify-content: space-between;
      button {
        max-width: unset;
        /* flex: 1; */
        width: 164px;
        padding: 11px 8px;
      }
    `}
  `}
`

export const ErrorMessage = styled.span`
  color: #d71919;
  font-size: 11px;
  margin-top: 3px;
`
