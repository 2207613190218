import TextField from '@mui/material/TextField'
import styled from 'styled-components'

export const CustomInput = styled(TextField)`
  width: 100%;
  background-color: ${({ theme }) => theme.COLORS.shade20};
  color: white !important;
  border-radius: ${({ theme }) => theme.SPACING.SMALL};
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  label {
    color: ${({ theme }) => theme.COLORS.gray10};
  }
  & input {
    color: ${({ theme }) => theme.COLORS.gray10};
  }
  & .MuiInputLabel-root {
    color: ${({ theme }) => theme.COLORS.gray20};
  }
  & .MuiInputLabel-root.Mui-focused {
    color: ${({ theme }) => theme.COLORS.gray20};
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: ${({ theme }) => theme.COLORS.gray20};
    }

    &:hover fieldset {
      border-color: ${({ theme }) => theme.COLORS.gray10};
    }

    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.COLORS.gray20};
      box-shadow: none;
    }
  }

  & .MuiInput-underline:after,
  & .MuiInput-underline:before {
    border-bottom: none;
  }

  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }

  & input:-webkit-autofill,
  & input:-webkit-autofill:hover,
  & input:-webkit-autofill:focus,
  & input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.COLORS.shade20} inset !important;
    box-shadow: 0 0 0 30px ${({ theme }) => theme.COLORS.shade20} inset !important;
  }

  & input:-webkit-autofill {
    -webkit-text-fill-color: ${({ theme }) => theme.COLORS.gray10} !important;
  }
  & input:-webkit-autofill + label {
    color: red;
  }

  & input:-moz-ui-invalid {
    background: purple !important;
  }

  .filled + label {
    color: transparent;
  }

  &.error {
    .MuiInputBase-root {
      border-color: ${({ theme }) => theme.COLORS.ALERT};

      .MuiInputLabel-root {
        color: ${({ theme }) => theme.COLORS.ALERT};
      }

      input {
        // color: ${({ theme }) => theme.COLORS.ALERT};
      }

      fieldset {
        border-color: ${({ theme }) => theme.COLORS.ALERT} !important;
      }
    }
  }
`
