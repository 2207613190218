import dayjs from 'dayjs'
import React, { createContext, useContext, useState } from 'react'
import { IChildTypes } from 'types/childrenTypes'
import { ALL } from 'utils/constants'
import { useCandidates } from './useCandidates'

interface RangeDateTypes {
  initial_date: string | null
  end_date: string | null
}

const FilterGlobalContext = createContext<any>(null)
const dateFormat = 'YYYY-MM-DD'
const default_date = dayjs().subtract(7, 'day').format(dateFormat)

const FilterGlobalProvider = ({ children }: IChildTypes) => {
  const [filterMentions, setFilterMentions] = useState<any>(ALL)
  const [filterDate, setFilterDate] = useState<any>(false)
  const [filterRaioX, setFilterRaioX] = useState<any>(ALL)
  const [renderType, setRenderType] = useState<'region' | 'candidate'>('region')
  const { selectedCandidate } = useCandidates()
  const target_locality = selectedCandidate?.locality || 'BRA.SP.Sao Paulo'
  const [rangeDate, setRangeDate] = useState<RangeDateTypes>({ initial_date: default_date, end_date: null })

  const renderFilter = () => {
    if (renderType === 'region') {
      return target_locality
    }

    return selectedCandidate
  }

  const handleRangeDate = ({ type, date }: { type: 'initial_date' | 'end_date'; date: string }) => {
    setRangeDate(prev => ({ ...prev, [type]: date }))
  }

  return (
    <FilterGlobalContext.Provider
      value={{
        filterMentions,
        filterDate,
        filterRaioX,
        setFilterMentions,
        setFilterDate,
        setFilterRaioX,
        rangeDate,
        handleRangeDate,
        renderFilter,
        setRenderType,
      }}
    >
      {children}
    </FilterGlobalContext.Provider>
  )
}

const useFilterGlobal = () => {
  return useContext(FilterGlobalContext)
}

export { FilterGlobalProvider, useFilterGlobal }
