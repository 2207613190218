import Button from 'componentsNew/Button'
import { ModalVox } from 'componentsNew/PoliticalVox/components/ModalVox'
import { useOverview } from 'componentsNew/PoliticalVox/hooks/useOverview'
import { deleteFilter, getAllFilters } from 'componentsNew/PoliticalVox/services/overViewServices'
import { useFilterVox } from 'hooks/useFilterVox'
import { noop } from 'lodash'
import { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'

import * as S from './styles'

interface SelectFilterProps {
  open: boolean
  onClose?: () => void
}

interface FilterListTypes {
  id: number
  title: string
  description: string
  [key: string]: any
}

export const SelectFilter = ({ open, onClose = noop }: SelectFilterProps) => {
  const { selectedFilter, changeSelectedFilter, changeFilter } = useOverview()
  const { loadSavedFilters } = useFilterVox()
  const [filterList, setFilterList] = useState<FilterListTypes[]>([])

  useEffect(() => {
    if (open) {
      fetchAllFilters()
    }
  }, [open])

  const saveFilter = async () => {
    const payload = {
      ...selectedFilter,
      positive_words: selectedFilter?.positive_words?.split(','),
      negative_words: selectedFilter?.negative_words?.split(','),
    }
    loadSavedFilters(payload)
    onClose()
  }

  const fetchAllFilters = async () => {
    const all_filters = await getAllFilters()

    setFilterList(all_filters)
  }

  const handleDeleteFilter = async (filter: any) => {
    await deleteFilter({ id: filter?.id })

    setFilterList((prevState: any) => prevState.filter((item: any) => item?.id !== filter?.id))
  }

  const handleSelectFilter = (filter: any) => {
    changeSelectedFilter(filter)
  }

  const Footer = () => {
    return (
      <S.Footer>
        <Button variant="outline" onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="outline" onClick={saveFilter}>
          Selecionar busca
        </Button>
      </S.Footer>
    )
  }
  return (
    <ModalVox open={open} onCancel={onClose} title="Salvar Busca" actionsBox={<Footer />}>
      <S.Wrapper>
        <S.Content>
          {filterList.map(
            (filter, index) =>
              filter?.title && (
                <S.CardItem
                  key={`${filter.id + index + 2}`}
                  activeFilter={selectedFilter?.id === filter?.id}
                  onClick={() => handleSelectFilter(filter)}
                >
                  <S.TextWrapper>
                    <S.Title>{filter.title}</S.Title>
                    <S.Description>{filter.description}</S.Description>
                  </S.TextWrapper>
                  <S.IconBox>
                    <BsTrash color="rgba(255, 0, 0, 0.959)" size={18} onClick={() => handleDeleteFilter(filter)} />
                  </S.IconBox>
                </S.CardItem>
              )
          )}
        </S.Content>
      </S.Wrapper>
    </ModalVox>
  )
}
