import { motion } from 'framer-motion'
import styled from 'styled-components'
import theme from 'styles/theme'

export const Wrapper = styled.main`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export const AddButton = styled(motion.div)`
  all: unset;
  background-color: #131313f2;
  color: #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.COLORS.yellow10};
  position: relative;

  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    background: radial-gradient(
      circle,
      rgba(82, 82, 82, 0.31416316526610644) 0%,
      rgba(0, 0, 0, 0.19931722689075626) 100%
    );
    transition: width 0.45s ease-in-out;
    transform: translateX(-50%);
    z-index: -1;
  }

  &:hover:before {
    width: 200%;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-contetn: center;
  gap: 16px;
  flex-direction: column;
  button {
    padding: unset;
  }
`

export const IconWrapper = styled(motion.div)`
  width: 50px;
  height: 50px;
  background: ${theme.COLORS.yellow10};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${theme.COLORS.BLACK_1};
  }
`

export const iconBox = styled.div``

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;

  gap: 3px;

  align-items: flex-start;
`

export const IconListening = styled.img``

export const Heading = styled.h3`
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  margin-left: 6px;

  color: ${theme.COLORS.gray10};
`
