import { Tooltip } from 'antd'
import { useOverview } from 'componentsNew/PoliticalVox/hooks/useOverview'
import { createCandidate, removeCandidate } from 'componentsNew/PoliticalVox/services/overViewServices'
import { useUser } from 'global/UserContext'
import { useFilterVox } from 'hooks/useFilterVox'
import { useState } from 'react'
import avatarDefault from 'assets/avatar-default.png'
import { MdOutlineClose } from 'react-icons/md'
import Button from 'componentsNew/Button'
import { Empty } from 'componentsNew/Empty'
import { FormCandidate } from '../FormCandidate'
import { ProfileCandidate } from '../ProfileCandidate'
import { ConfirmAction } from '../Dialogs/ConfirmAction'
import * as S from './styles'

export const FilterCandidate = () => {
  const [candidateToRemove, setCandidateToRemove] = useState<any>(null)
  const [openRemoveAction, setOpenRemoveAction] = useState(false)
  const { user } = useUser()
  const { changeFilterCandidate, candidateList, addToCandidateList } = useOverview()
  const { handleFilters, selectedCandidate, setSelectedCandidate } = useFilterVox()
  const validationTypeCandId = selectedCandidate?.id_cand || selectedCandidate?.candidate_id || selectedCandidate?.id

  const existingCandidate =
    candidateList.list.filter(
      (cand: any) => cand?.candidate_id === validationTypeCandId || cand?.id === validationTypeCandId
    ).length > 0

  const addOnFastList = async (candidate: any) => {
    if (user) {
      const formatedPayload = {
        candidate_id: candidate?.id_cand || candidate?.candidate_id,
        candidate_name: candidate?.nm_candidato || candidate?.candidate_name,
        candidate_party: candidate?.party,
        person: user.person.id,
      }

      const newCadidate = await createCandidate({
        payload: formatedPayload,
        candidateList: candidateList.list,
        fastListId: candidateList.list_id,
        person: user?.person?.id,
      })

      addToCandidateList({
        ...newCadidate,
        nm_candidato: candidate?.nm_candidato,
        id: newCadidate?.id,
      })
    }
  }

  const onSelectCandidate = (candidate: any) => {
    handleFilters('candidate', {
      name: candidate?.candidate_name || candidate?.nm_candidato,
      id: candidate?.id_cand || candidate?.candidate_id,
    })
    setSelectedCandidate(candidate)
    changeFilterCandidate({
      selectedCandidate: candidate,
      candidate_api: [Number(candidate?.id_cand || candidate?.candidate_id)],
    })
  }

  const onRemoveCandidate = (candidate: any) => {
    const candidate_id = candidate?.id_cand || candidate?.candidate_id

    if (user) {
      const newList = candidateList.list
        .filter((cand: any) => cand?.candidate_id !== candidate_id)
        .map(item => item.id) as number[]
      addToCandidateList(candidate)
      removeCandidate({ person: user.person.id, candidateList: newList, fastListId: candidateList.list_id })
    }
  }

  const handleOpenRemoveAction = (candidate: any) => {
    setCandidateToRemove(candidate)
    setOpenRemoveAction(true)
  }

  const handleClearFilter = () => {
    handleFilters('candidate', { name: '', id: '' })
    setSelectedCandidate(null)
  }

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <S.Column>
          <S.Heading>Acesso rápido (máx. 20)</S.Heading>

          <S.Content style={{ maxWidth: '320px' }}>
            <S.ListCandidate>
              {candidateList?.list?.length > 0 ? (
                candidateList?.list?.map(candidate => (
                  <S.Candidate
                    key={candidate?.id}
                    onClick={() =>
                      onSelectCandidate({ ...candidate, party: candidate?.candidate_party || candidate?.party })
                    }
                  >
                    <S.AvatarBox>
                      <S.Avatar src={candidate?.avatar || avatarDefault} />
                    </S.AvatarBox>
                    <Tooltip
                      placement="leftTop"
                      title={`${candidate?.label || candidate?.candidate_name || candidate?.nm_candidate} -
                ${candidate?.party || candidate?.candidate_party}`}
                    >
                      <S.CardCandidate>
                        {candidate?.label || candidate?.candidate_name || candidate?.nm_candidate} -
                        {candidate?.party || candidate?.candidate_party}
                      </S.CardCandidate>
                    </Tooltip>

                    <S.RemoveIcon
                      onClick={e => {
                        e.stopPropagation()
                        handleOpenRemoveAction(candidate)
                      }}
                    >
                      <MdOutlineClose />
                    </S.RemoveIcon>
                  </S.Candidate>
                ))
              ) : (
                <Empty description="Inclua um candidato na lista rapida" />
              )}
            </S.ListCandidate>
          </S.Content>
        </S.Column>

        <S.Column>
          <S.Heading>Filtros Gerais</S.Heading>

          <S.Content>
            <FormCandidate onAddCandidate={candidate => onSelectCandidate(candidate)} />
          </S.Content>
        </S.Column>

        <S.Column>
          <S.Heading>Figura política selecionada</S.Heading>

          <S.Content>
            {selectedCandidate && (
              <ProfileCandidate
                addCandidate={() => addOnFastList(selectedCandidate)}
                candidate={selectedCandidate}
                existingCandidate={existingCandidate}
                removeCandidate={() => onRemoveCandidate(selectedCandidate)}
              />
            )}
          </S.Content>
        </S.Column>
      </S.ContentWrapper>

      <S.ContentActions>
        <Button variant="outline" onClick={handleClearFilter} disabled={!selectedCandidate}>
          Limpar filtro
        </Button>
        {/*      <Button>Aplicar filtro</Button> */}
      </S.ContentActions>

      <ConfirmAction
        buttonRemove
        onConfirm={() => onRemoveCandidate(candidateToRemove)}
        open={openRemoveAction}
        onClose={() => setOpenRemoveAction(false)}
        textAction={
          <p>
            Deseja realmente remover <span>{candidateToRemove?.nm_candidate || candidateToRemove?.candidate_name}</span>{' '}
            do acesso rapido?
          </p>
        }
      />
    </S.Wrapper>
  )
}
