import React from 'react'
import { ContainerText } from './styles'

interface ITextProps {
  children: React.ReactNode
  size?: 'small' | 'xSmall' | 'medium' | 'large' | 'xlarge'
  bold?: boolean
  color?: 'white' | 'black' | 'gray' | 'green' | 'red' | 'yellow'
  secondary?: boolean
  className?: string
}
const Text = ({
  children,
  size = 'small',
  bold = false,
  color = 'white',
  secondary = false,
  className,
}: ITextProps) => {
  return (
    <ContainerText size={size} bold={bold} color={color} secondary={secondary} className={className}>
      {children}
    </ContainerText>
  )
}

export default Text
