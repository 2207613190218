import { TabContextKeys } from './activeTabContext'

export const isCopilotAccessible = (activeTabContext: TabContextKeys, locationPathname: string) =>
  activeTabContext &&
  (locationPathname === '/dashboard/political-vox' ||
    locationPathname === '/dashboard/political-intelligence/political-vox-search' ||
    locationPathname === '/dashboard/political-intelligence/election-diagnosis' ||
    locationPathname === '/dashboard/political-intelligence/voter-diagnosis')

export const isMarketingAccessible = (locationPathname: string) =>
  locationPathname === '/dashboard/political-vox' ||
  locationPathname === '/dashboard/political-intelligence/political-vox-search'

export const isVoxPages = (locationPathname: string) =>
  locationPathname === '/dashboard/political-vox' ||
  locationPathname === '/dashboard/political-intelligence/political-vox-search'

export const isDiagnosisPages = (locationPathname: string) =>
  locationPathname === '/dashboard/portfolio/party-diagnosis' ||
  locationPathname === '/dashboard/political-intelligence/voter-diagnosis' ||
  locationPathname === '/dashboard/political-intelligence/election-diagnosis'
