import styled from 'styled-components'

export const ContainerMaster = styled.div`
  max-width: 1018px;

  .card-candidate-wirzad {
    margin-bottom: 16px;
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  gap: 18px;
  max-width: 1018px;
  margin-left: 70px;

  .descripiton {
    width: 80%;
  }

  .card-candidate {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 4px 12px;
    gap: 8px;
  }

  .card-vereador {
    display: flex;
    flex-direction: row;
    gap: 32px;
  }

  .card-title-states {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-bottom: 8px;
  }

  .card-title-items {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin: 0 8px 0 8px;
  }

  .card-title-uf {
    display: flex;
    margin-right: 184px;
  }

  .card-title-position {
    display: flex;
    flex: 1;
    gap: 96px;
  }

  .candidates-position {
    margin: 0 16px;
  }
`

export const ContainerButtonLink = styled.button`
  border: none;
  background: none;
  color: #ffc000;
  font-size: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    color: #ffc000;
    font-size: 18px;
    margin-right: 8px;
  }
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.03);
  }
`
