import React from "react";
import { Avatar } from "antd";
import Text from "componentsNew/Text";
import { ICandidateType } from "componentsNew/CockpitComponents/types/adversaryTypes";
import * as S from "./styles";

interface ISentimentData {
  id_cand: string;
  total: number;
  percent_positive: number;
  percent_neutral: number;
  percent_negative: number;
}

interface IAggregateData {
  total: number;
  positive: number;
  neutral: number;
  negative: number;
  percent_positive: number;
  percent_neutral: number;
  percent_negative: number;
}

interface ISentimentAnalysis {
  selectedAdversary: ICandidateType[];
  avatars: { [key: string]: string };
  selectedSocialNetworks: string[];
  sentimentData: ISentimentData[];
  aggData?: IAggregateData | null;
}

const SentimentAnalysis: React.FC<ISentimentAnalysis> = ({
  selectedAdversary,
  avatars,
  selectedSocialNetworks,
  sentimentData = [],
  aggData,
}) => {
  if (!selectedAdversary || !Array.isArray(selectedAdversary)) {
    return <div>Invalid selected adversary data</div>;
  }

  if (!selectedSocialNetworks || !Array.isArray(selectedSocialNetworks)) {
    return <div>Invalid social networks data</div>;
  }

  const formatTotal = (total: number) => {
    if (total >= 1000000) {
      return `${(total / 1000000).toFixed(1).replace(".", ",")} Milhões`;
    } else if (total >= 1000) {
      return `${(total / 1000).toFixed(0).replace(".", ",")} Mil`;
    } else {
      return total.toLocaleString("pt-BR");
    }
  };

  const sortedAdversaries = selectedAdversary
    .map((candidate) => {
      const sentiment = sentimentData?.find(
        (item) => item.id_cand === candidate.candidate_id,
      );

      return {
        ...candidate,
        totals: sentiment?.total || 0,
        positivePercentage: sentiment?.percent_positive || 0,
        neutralPercentage: sentiment?.percent_neutral || 0,
        negativePercentage: sentiment?.percent_negative || 0,
      };
    })
    .sort((a, b) => b.totals - a.totals);

  return (
    <S.Container>
      {sortedAdversaries.map((candidate, index) => (
        <S.CandidateCard key={candidate.id}>
          <S.NameContainer>
            <Text size="small" bold>
              {`${index + 1}° - ${candidate.candidate_name}`}
            </Text>
          </S.NameContainer>
          <S.CandidateInfo>
            <Avatar
              src={avatars[candidate.candidate_id || ""]}
              alt={candidate.candidate_name}
              style={{ width: "87px", height: "87px" }}
            />
          </S.CandidateInfo>
          <S.PercentageSession>
            <S.PercentageItem color="#FF0000">{`${candidate.negativePercentage.toFixed(0)}%`}</S.PercentageItem>
            <S.PercentageItem color="#FFFFFF">{`${candidate.neutralPercentage.toFixed(0)}%`}</S.PercentageItem>
            <S.PercentageItem color="#00E23F">{`${candidate.positivePercentage.toFixed(0)}%`}</S.PercentageItem>
          </S.PercentageSession>
          <S.ProgressBar>
            <S.ProgressSegment
              color="#FF0000"
              width={`${candidate.negativePercentage}%`}
            />
            <S.ProgressSegment
              color="#FFFFFF"
              width={`${candidate.neutralPercentage}%`}
            />
            <S.ProgressSegment
              color="#00E23F"
              width={`${candidate.positivePercentage}%`}
            />
          </S.ProgressBar>
          <Text size="xSmall" bold>
            {formatTotal(candidate.totals)}
          </Text>
        </S.CandidateCard>
      ))}
    </S.Container>
  );
};

export default SentimentAnalysis;
