import { motion } from 'framer-motion'
import { styled } from 'styled-components'

export const Wrapper = styled.main`
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
`

export const Heading = styled.h3`
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
`

export const Text = styled.p`
  font-size: 16px;
  color: #e9e9f0;
  margin-top: 24px;
  margin-bottom: 34px;
`

export const ContentForm = styled.div`
  margin: 0 auto;
  display: flex;

  justify-content: center;
  gap: 8px;
  flex-direction: column;
  margin-top: 20px;

  .wrapper_input {
    max-width: 440px;
  }
`

export const BoxMessage = styled(motion.div)`
  width: 100%;
  margin-bottom: 16px;
  margin-top: 4px;
`

export const ErrorMessage = styled.span`
  font-size: 14px;
  color: #ff4d4f;
`

export const WrapperValidatePassword = styled.div`
  margin: 20px 0;

  li {
    margin: 4px 0;
    list-style: none;
  }
`

export const ProgressBox = styled(motion.div)`
  width: 200px;
  margin-bottom: 12px;
`

export const HeadingItems = styled.h4`
  font-size: 14px;
  color: #e9e9f0;
  font-weight: bold;
  margin: 10px 0;
`

export const ItemValidate = styled.span<{ valid?: boolean }>`
  color: ${({ valid }) => (valid ? '#00FF00' : '#e9e9f0')};
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
    color: ${({ valid }) => (valid ? '#00FF00' : '#ff4d4f')};
  }
`
