import React from 'react';
import { Form } from 'antd';
import { Controller, ControllerRenderProps } from 'react-hook-form';

interface FormItemProps {
  name: string;
  control: any;
  errors: any;
  placeholder?: string;
  render: (field: ControllerRenderProps) => React.ReactElement;
  size?: 'small' | 'middle' | 'large';
  defaultValue?: string | number | boolean;
  options?: { label: string; value: string }[];
}

const FormItem: React.FC<FormItemProps> = (
  {
    name,
    control,
    errors,
    render,
    defaultValue = '',
  }) => (
    <Form.Item
      name={name}
      help={errors[name]?.message}
      validateStatus={errors[name] ? 'error' : 'success'}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => render(field)}
      />
    </Form.Item>
);

export default FormItem;
