import React, { useEffect } from "react";
import { ALL } from "utils/constants";
import { useCandidates } from "hooks/useCandidates";
import Card from "componentsNew/Card";
import WordcloudCand from "../../../../componentsNew/Wordcloud";
import { handleMapColor } from "../../../../utils/utilityFunctions";
import Spin from "../../../../componentsNew/Spin";
import useSocialMentionsWc from "pages/PoliticalListening/hooks/useSocialMentionsWC";
import * as S from "./styles";
import ChartMentionsRelevantes from "../ChartMentionsRelevantes";
import { useFilterGlobal } from "../../../../hooks/useFilterGlobal";

interface IMapEleitorRelevanteProps {
  cand_id?: string;
  target_local?: string;
}

const MapEleitorRelevante = ({
  cand_id,
  target_local,
}: IMapEleitorRelevanteProps) => {
  const { wordsDefault, isLoadingDefault, getWordDefault } =
    useSocialMentionsWc();
  const { filterMentions, rangeDate, filterRaioX } = useFilterGlobal();
  const { selectedCandidate } = useCandidates();

  useEffect(() => {
    const sentiment = filterRaioX !== ALL ? `&sentiment=${filterRaioX}` : "";

    if (filterMentions !== ALL) {
      getWordDefault({
        topic_name: filterMentions,
        dateStart: rangeDate.initial_date,
        endDate: rangeDate.end_date,
        sentiment,
        city: target_local,
        candidate_id: cand_id,
        topic_lvl: "2",
      });
    } else {
      getWordDefault({
        dateStart: rangeDate.initial_date,
        endDate: rangeDate.end_date,
        sentiment,
        city: target_local,
        candidate_id: cand_id,
        topic_lvl: "2",
      });
    }
  }, [filterMentions, rangeDate, filterRaioX]);
  return (
    <S.Section>
      <S.ContentSection>
        <S.Box>
          <S.Heading>Eleitor - Temas mais relevantes</S.Heading>
          <S.HorizontalSection>
            <S.Box style={{ minWidth: "42%" }}>
              {!isLoadingDefault ? (
                <Card>
                  <WordcloudCand
                    words={wordsDefault}
                    width={380}
                    height={300}
                    colors={handleMapColor(filterMentions)}
                  />
                </Card>
              ) : (
                <S.LoadingBox>
                  <Spin spinning />
                </S.LoadingBox>
              )}
            </S.Box>
            <S.Box style={{ flex: "1.5" }}>
              <ChartMentionsRelevantes
                cand_id={cand_id}
                target_local={target_local}
              />
            </S.Box>
          </S.HorizontalSection>
        </S.Box>
      </S.ContentSection>
    </S.Section>
  );
};

export default MapEleitorRelevante;
