import {TfiMoreAlt} from 'react-icons/tfi'
import {useRef, useState} from 'react'


import {Dropdown} from 'antd'
import {MenuProps} from 'antd/lib'

import {stylesMenu} from 'componentsNew/ButtonDropdown/'

import * as S from './styles'

const styleMenuItem = {
  color: 'white'
}


interface CardManagerProps {
  name: string
  last_name: string
  profile: string
  license: string
  last_access: string
  permission: string
  status: 'accepted' | string

  actions?: {
    read: () => void
    edit: () => void
    delete: () => void
  }
}

export const CardManager = ({
                              name,
                              profile,
                              status,
                              actions
                            }: CardManagerProps) => {

  const refDropMenu = useRef<HTMLHeadingElement>(null)
  const [openDropMenu, setOpenDropMenu] = useState(false)

  const items: MenuProps['items'] = [
    {
      style: styleMenuItem,
      label: 'Ver usuário',
      key: 'read'
    },
    {
      style: styleMenuItem,
      label: 'Editar usuário',
      key: 'edit'
    },
    {
      style: styleMenuItem,
      label: 'Excluir usuário',
      key: 'delete'
    },
  ];

  // handlers
  const handleMenuClick: MenuProps['onClick'] = e => {

    switch (e.key) {
      case 'read':
        return actions?.read();
      case 'edit':
        return actions?.edit();
      case 'delete':
        return actions?.delete();
      default:
        return setOpenDropMenu(false);
    }

  };

  const handleOpenChange = (flag: boolean) => {
    setOpenDropMenu(flag);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <S.NameContent>
          <S.BadgeStatus status={status}/>
          <S.DefaultText>{name} </S.DefaultText>
        </S.NameContent>
        <S.ProfileContainer>
          <S.DefaultText>{profile || ''}</S.DefaultText>
        </S.ProfileContainer>

        <S.ButtonActions>
          <Dropdown
            trigger={['click']}
            onOpenChange={handleOpenChange}
            open={openDropMenu}
            menu={{
              items,
              onClick: handleMenuClick,
              style: stylesMenu
            }}>
            <TfiMoreAlt size={18} onClick={() => setOpenDropMenu(!openDropMenu)}/>
          </Dropdown>
        </S.ButtonActions>
      </S.Content>
    </S.Wrapper>
  )
}
