import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  .text {
    width: 11.25rem;
  }
`
export const MarkColor = styled.div<any>`
  width: 0.5rem;
  height: 1.12rem;
  background: ${props => props.color};
  margin-right: 1rem;
`
export const InputNumber = styled.input`
  width: 110%;
  height: 1.87rem;
  border-radius: 8px;
  background: transparent;
  border: 1px solid #ffc000;
  color: #e9e9f0;
  padding: 0 1rem;
  font-weight: bold;
  font-size: 1rem;
  &:focus {
    outline: none;
    border: 2px solid #ffc000;
  }
`
