import {
  api,
  apiMentions,
  apiElectoralIntelligence,
  apiVox,
} from "services/apiService";
import { buildQueryString } from "utils/buildQueryString";
import { orderByCandidateName } from "./utils/ordenation";

interface ParamsGetWordCloud {
  name_tag?: string[];
  mentions?: any;
  candidate_id?: number | string;
  date_start?: string;
  date_end?: string;
  sentiment?: "positive" | "negative";
  cd_city?: string;
  parent_name?: string;
}

interface ParamsGetMap {
  topic_lvl?: any;
  candidate_id?: number | string;
  initial_date?: string;
  final_date?: string;
  topic_name?: string;
  sentiment?: "positive" | "negative";
  city?: string;
  id_cand?: string;
}

const BASE_URL =
  "https://9dct6bsix6.execute-api.sa-east-1.amazonaws.com/api/electoral-intelligence/political-listening";
const BASE_MAP_URL =
  "https://k0vo8cwl4g.execute-api.sa-east-1.amazonaws.com/api/mentions-map";

export async function getSentiment(params: ParamsGetWordCloud) {
  try {
    const url = `/vox/v1/political-vox/sentiment-analysis${buildQueryString(params)}`;
    const { data } = await apiVox.get(url);

    return data;
  } catch (e) {
    console.error("Erro na requisição:", e);
    return { message: "Não foi possível buscar menções." };
  }
}

export async function getWordCloud(params: ParamsGetWordCloud) {
  try {
    const url = `/wordcloud${buildQueryString(params)}`;
    const { data } = await apiElectoralIntelligence.get(url);

    return data;
  } catch (e) {
    console.error("Erro na requisição:", e);
    return { message: "Não foi possível buscar menções." };
  }
}

export const sendTheme = async (): Promise<any> => {
  try {
    const { data } = await api.get(
      `/electoral-intelligence/person-environment-diagnosis-themes/`,
    );

    return data?.results?.filter((item: any) => item?.is_listening);
  } catch (e) {
    return { message: "Não foi possivel buscar dados cadastrados." };
  }
};

export const getAllPersonThemes = async () => {
  try {
    const { data } = await api.get(
      "/electoral-intelligence/person-allies-diagnosis-themes/",
    );
    const newData = data?.results?.filter((item: any) => item?.is_listening);
    return orderByCandidateName(newData);
  } catch (e) {
    console.log(e);
    return { message: "Erro na busca dos person themes allies" };
  }
};

export async function getMapData(params: ParamsGetMap) {
  try {
    const url = `/mentions-map/${buildQueryString(params)}`;
    const { data } = await apiMentions.get(url);

    const formattedData = data.mentions_list
      ?.map((pointer: any) => ({
        ...pointer,
        name: pointer.city,
        total_votes: pointer.total_count,
      }))
      .filter((point: any) => point.latitude !== "None");

    return formattedData;
  } catch (e) {
    console.error("Erro na requisição:", e);
    return { message: "Não foi possível buscar menções." };
  }
}
