import styled from 'styled-components'

interface ITableMark {
  isActive: boolean
  intensity: number
}
export const Container = styled.div<ITableMark>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 170px;
  min-height: 21px;
  border: 1px solid;
  border-color: white;
  background: ${({ intensity }) => `rgba(255, 192, 0, ${intensity})`};
`
export const RowTable = styled.div`
  display: flex;
  align-items: center;

  .title-card {
    width: 115px;
  }
`
export const ContainerTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 8px;
`
