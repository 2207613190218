import { BaseCard } from 'componentsNew/PoliticalListeningComponents/BaseCard'
import styled from 'styled-components'
import theme from 'styles/theme'

export const Wrapper = styled.main`
  .row_mentions {
    opacity: 1;
    cursor: default;
    &:hover {
      opacity: 1;
      cursor: default;
    }
  }
`

export const NewsBox = styled.div`
  border-top: 1px solid #494949;
  border-bottom: 1px solid #494949;
  margin-bottom: 16px;
`

export const Section = styled.section`
  width: 100%;
  display: flex;
  gap: 16px;
  margin: 16px 0;

  @media screen and (max-width: 1099px) {
    gap: 23px;
    flex-direction: column;
  }
`

export const ContentSection = styled.div<{ height?: string }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: ${({ height }) => height && height};

  @media screen and (max-width: 1099px) {
    gap: 23px;
    flex-direction: column;
    margin: 12px 0;
  }
`

export const Heading = styled.h3`
  color: #e9e9f0;
  font-size: 16px;
  font-weight: 500;
`

export const SectionMessage = styled.div`
  overflow: auto;
  padding: 12px;
  /* height: 3%; */
  height: 503px;
`

export const Box = styled(BaseCard)<{ height?: string }>`
  height: 100%;
  flex: 1;
  max-height: ${({ height }) => height && height};
  min-height: ${({ height }) => height && height};
  max-width: 50%;

  @media screen and (max-width: 1018px) {
    width: 100%;
  }
`

export const HorizontalSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1099px) {
    gap: 23px;
    flex-direction: column;
  }
`

export const BoxFull = styled.div`
  flex: 1;
`

export const LoadingBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const CounterBox = styled.div`
  flex: 0.7;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
`

export const CounterContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding: 4px;
`

export const TitleCount = styled.h3`
  font-size: 12px;
  font-weight: 500;
  color: ${theme.COLORS.gray10};
  align-self: flex-start;
`
export const TextCount = styled.p`
  font-size: 22px;
  font-weight: 700;
  color: ${theme.COLORS.yellow10};
  align-self: center;
`

export const NewSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`
