import React, {useEffect, useState} from "react";
import {SubmitHandler, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {useNavigate} from "react-router-dom";
import axios from "axios";

import {Col, Flex, Form, Row, Spin} from "antd";
import {cpf} from 'cpf-cnpj-validator';

import {HeadingForm} from "../../../FormGenerator/components/HeadingForm";
import {FormContainer, StyledButton, StyledCheckbox, StyledInput, StyledInputPassword} from "./styles";
import MaskedInputComponent from "./InputMask";
import ALL_STATE_LIST from "../../../../utils/location";
import {menuOptions} from "../../Schemas/FormData";
import FormItem from "./FormItem";
import SelectField from "./SelectField";
import {api} from "../../../../services/apiService";

interface Position {
  cd_cargo: number;
  ds_cargo: string;
}

interface City {
  id: number;
  nome: string;
}

interface ApiResponse<T> {
  data: T;
}

export const RegisterCandidateForm = () => {
  const formSchema = z.object({
    first_name: z.string().min(1, { message: "Digite seu nome" }),

    last_name: z.string().min(1, { message: "Digite seu sobrenome" }),

    social_security_number: z.string()
      .refine((value) => cpf.isValid(value), {
        message: "Digite um CPF válido",
      })
      .transform((value) => value.replace(/\D/g, '')),

    date_of_birth: z.string()
      .refine((value) => /(\d{2})\/(\d{2})\/(\d{4})/.test(value), {
        message: "Digite uma data válida no formato DD/MM/AAAA",
      })
      .transform((value) => {
        const [dia, mes, ano] = value.split('/');
        return `${ano}-${mes}-${dia}`;
      }),

    email: z.string()
      .email({ message: "Digite um e-mail válido" }),

    phone: z.string()
      .refine((value) => /\(\d{2}\)\s\d\s\d{4}-\d{4}/.test(value), {
        message: "Digite um telefone válido",
      }).transform((value) => "+55" + value.replace(/\D/g, '')),

    profile: z.number().min(1, { message: "Selecione um perfil" }),

    party: z.number().min(1, { message: "Selecione um partido" })
      .transform(value => parties.find(p => p.value === value)?.label || "Partido não encontrado"),

    actual_position: z.number().min(1, { message: "Selecione sua posição atual" })
      .transform(value => positions.find(p => p.value === value)?.label || "Posição não encontrada"),

    actual_state: z.string().optional().refine((val) => {
      return [1, 2].includes(selectedActualPosition) || val !== '';
    }, { message: "Selecione o estado atual" }),

    address: z.string().min(1, { message: "Digite seu endereço" }),

    actual_city: z.number().optional().refine((val) => {
      return ![11, 12, 13].includes(selectedActualPosition) || val !== 0;
    }, { message: "Selecione a cidade atual" }),

    target_year: z.string()
      .length(4, { message: "Digite um ano válido com 4 dígitos" })
      .regex(/^\d{4}$/, { message: "Digite um ano válido" }),

    target_position: z.number().min(1, { message: "Selecione uma posição alvo" })
      .transform(value => positions.find(p => p.value === value)?.label || "Posição não encontrada"),

    target_state: z.string().refine((val) => {
      return [1, 2].includes(selectedTargetPosition) || val !== '';
    }, { message: "Selecione um estado alvo" }),

    target_city: z.number().optional().refine((val) => {
      return ![11, 12, 13].includes(selectedTargetPosition) || val !== 0;
    }, { message: "Selecione a cidade alvo" }),

    social_media: z.string().min(1, { message: "Digite seu perfil de uma rede social" }),

    password: z.string()
      .min(8, { message: "A senha deve ter pelo menos 8 caracteres" })
      .regex(/[a-z]/, { message: "A senha deve conter pelo menos uma letra minúscula" })
      .regex(/[A-Z]/, { message: "A senha deve conter pelo menos uma letra maiúscula" })
      .regex(/[0-9]/, { message: "A senha deve conter pelo menos um número" }),

    is_pep: z.boolean(),

    has_government_occupation: z.boolean(),
  });

  type FormSchema = z.infer<typeof formSchema>;

  const [positions, setPositions] = useState<{ label: string; value: number }[]>([]);
  const [parties, setParties] = useState<{ label: string; value: number }[]>([]);
  const [actualCities, setActualCities] = useState<{ label: string; value: number }[]>([]);
  const [targetCities, setTargetCities] = useState<{ label: string; value: number }[]>([]);
  const [selectedActualPosition, setSelectedActualPosition] = useState(0);
  const [selectedTargetPosition, setSelectedTargetPosition] = useState(0);
  const [loading, setLoading] = useState(false);

  const states = ALL_STATE_LIST.map(state => ({
    label: state.nome,
    value: state.codigo,
  }));

  const fetchPositions = async (): Promise<{ label: string; value: number }[]> => {
    try {
      const response: ApiResponse<Position[]> = await axios.get('https://udd2hcn349.execute-api.us-east-1.amazonaws.com/api/analysis/positions');
      const positions = response.data.map(({ cd_cargo, ds_cargo }) => ({
        value: cd_cargo,
        label: ds_cargo,
      }));
      return [{ value: 0, label: 'NÃO SE APLICA' }, ...positions];
    } catch (error) {
      console.error('Error fetching positions', error);
      return [{ value: 0, label: 'NÃO SE APLICA' }];
    }
  };

  const fetchParties = async (): Promise<({ label: string; value: number }[])> => {
    setLoading(true);
    try {
      const response = await axios.get('https://dadosabertos.camara.leg.br/api/v2/partidos?ordem=ASC&ordenarPor=sigla')
      const parties = response.data?.dados?.map((party: any) => ({
        ...party,
        value: party?.id,
        label: party?.sigla,
      }))
      return [{ value: 1, label: 'NÃO SE APLICA' }, ...parties];
    } catch (error) {
      console.error('Error fetching parties', error);
      return [{ value: 1, label: 'NÃO SE APLICA' }];
    } finally {
    setLoading(false);
  }
  };

  const fetchCities = async (state: string): Promise<{ label: string; value: number }[]> => {
    setLoading(true);
    try {
      const response: ApiResponse<City[]> = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`);
      return response.data.map(({id, nome}) => ({
        value: id,
        label: nome,
      }));
    } catch (error) {
      console.error('Erro ao buscar municípios:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      setLoading(true);
      try {
        const [positionsData, partiesData] = await Promise.all([fetchPositions(), fetchParties()]);
        setPositions(positionsData);
        setParties(partiesData);
      } catch (error) {
        console.error('Error fetching options', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, []);

  const handleStateChange = async (state: string, target: 'actual' | 'target') => {
    setLoading(true);
    try {
      const Cities = await fetchCities(state);
      if (target === 'actual') {
        setActualCities(Cities);
      } else {
        setTargetCities(Cities);
      }
    } catch (error) {
      console.error('Erro ao carregar cidades:', error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<FormSchema> = async (values: FormSchema) => {
    setLoading(true);
    try {
      const actualCityLabel = actualCities.find(c => c.value === values.actual_city)?.label || "Cidade não encontrada";
      const targetCityLabel = targetCities.find(c => c.value === values.target_city)?.label || "Cidade não encontrada";

      const locality = `BRA.${values.actual_state}${values.actual_city ? '.' + actualCityLabel : ''}`;
      const target_locality = `BRA.${values.target_state}${values.target_city ? '.' + targetCityLabel : ''}`;

      const payload = {
        ...values,
        locality,
        target_locality,
        name: `${values.first_name} ${values.last_name}`
      };

      const response = await api.post('/user/create-person', {
        ...payload,
      });
      console.log('Form submitted successfully:', response.data);
      navigate('/login');
    } catch (error: any) {
      const msgError = error.response?.data?.error == 'Username already exists' ? 'Usuário já cadastrado' : 'Erro ao cadastrar usuário';
      alert(msgError)
      console.error('Submission failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  const { control, formState: { errors }, handleSubmit } = useForm<FormSchema>({
    resolver: zodResolver(formSchema)
  });

  return (
    <FormContainer>
      <HeadingForm title="Cadastro do Proprietário" />
      <Form
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
        name="registerCandidateForm"
        style={{ maxWidth: '100%', background: 'transparent' }}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="social_security_number"
              control={control}
              errors={errors}
              render={(field) => (
                <MaskedInputComponent
                  {...field}
                  mask="999.999.999-99"
                  placeholder="CPF"
                />
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="first_name"
              control={control}
              errors={errors}
              render={(field) => <StyledInput {...field} size="large" placeholder="Nome" />}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="last_name"
              control={control}
              errors={errors}
              render={(field) => <StyledInput {...field} size="large" placeholder="Sobrenome" />}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="date_of_birth"
              control={control}
              errors={errors}
              render={(field) => (
                <MaskedInputComponent
                  {...field} mask="99/99/9999"
                  placeholder="Data de Nascimento"
                />
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="email"
              control={control}
              errors={errors}
              render={(field) => <StyledInput {...field} size="large" placeholder="E-mail" />}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="phone"
              control={control}
              errors={errors}
              render={(field) => (
                <MaskedInputComponent
                  {...field}
                  mask="(99) 9 9999-9999"
                  placeholder="Telefone"
                />
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="profile"
              control={control}
              errors={errors}
              defaultValue={0}
              render={(field) => (
                <SelectField
                  field={field}
                  options={menuOptions}
                  placeholder="Perfil"
                />
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="party"
              control={control}
              errors={errors}
              defaultValue={0}
              render={(field) => (
                <SelectField
                  field={field}
                  options={parties}
                  placeholder="Partido"
                />
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="actual_position"
              control={control}
              errors={errors}
              defaultValue={0}
              render={(field) => <SelectField
                field={field}
                options={positions}
                placeholder="Posição Atual"
                onChange={(e: number) => {
                  field.onChange(e);
                  setSelectedActualPosition(e);
                }}
              />}
            />
          </Col>
          {
            ![1, 2].includes(selectedActualPosition) &&
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <FormItem
                name="actual_state"
                control={control}
                errors={errors}
                defaultValue=''
                render={(field) => <SelectField
                  field={field}
                  options={states}
                  placeholder="Estado"
                  onChange={(e: string) => {
                    field.onChange(e);
                    handleStateChange(e, 'actual');
                  }}
                />}
              />
            </Col>
          }
          {
            [11, 12, 13].includes(selectedActualPosition) &&
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="actual_city"
              control={control}
              errors={errors}
              defaultValue={0}
              render={(field) => (
                <SelectField
                  field={field}
                  options={actualCities}
                  placeholder="Cidade Atual"
                />
              )}
            />
          </Col>
          }
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="address"
              control={control}
              errors={errors}
              render={(field) => <StyledInput {...field} size="large" placeholder="Endereço" />}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="target_position"
              control={control}
              errors={errors}
              defaultValue={0}
              render={(field) =>  <SelectField
                field={field}
                options={positions}
                placeholder="Posição Alvo"
                onChange={(e: number) => {
                  field.onChange(e);
                  setSelectedTargetPosition(e);
                }}
              />}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="target_year"
              control={control}
              errors={errors}
              render={(field) => (
                <StyledInput
                  size="large"
                  {...field}
                  placeholder="Ano alvo"
                />
              )}
            />
          </Col>
          {
            ![1, 2].includes(selectedTargetPosition) &&
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <FormItem
                name="target_state"
                control={control}
                errors={errors}
                defaultValue=''
                render={(field) => <SelectField
                  field={field}
                  options={states}
                  placeholder="Estado Alvo"
                  onChange={(e: string) => {
                    field.onChange(e);
                    handleStateChange(e, 'target');
                  }}
                />}
              />
            </Col>
          }
          {
            [11, 12, 13].includes(selectedTargetPosition) &&
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <FormItem
                name="target_city"
                control={control}
                errors={errors}
                defaultValue={0}
                render={(field) => <SelectField
                  field={field}
                  options={targetCities}
                  placeholder="Cidade Alvo"
                />}
              />
            </Col>
          }
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="social_media"
              control={control}
              errors={errors}
              render={(field) => (
                <StyledInput
                  {...field}
                  size="large"
                  placeholder="Rede Social"
                />
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <FormItem
              name="password"
              control={control}
              errors={errors}
              render={(field) => (
                  <StyledInputPassword
                    {...field}
                    size="large"
                    placeholder="Crie sua senha"
                  />
                )
              }
            />
          </Col>
        </Row>

        <Row>
          <Flex vertical justify="flex-start" align="start">
            <FormItem
              name="is_pep"
              control={control}
              errors={errors}
              defaultValue={false}
              render={(field) => (
                <StyledCheckbox {...field}>
                  É Pessoa Exposta Politicamente?
                </StyledCheckbox>
              )}
            />
            <FormItem
              name="has_government_occupation"
              control={control}
              errors={errors}
              defaultValue={false}
              render={(field) => (
                <StyledCheckbox {...field}>
                  Tem Cargo Governamental?
                </StyledCheckbox>
              )}
            />
          </Flex>
        </Row>

        <Row gutter={16} justify="center" style={{ marginTop: '1rem' }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <StyledButton
              className="cancelButton"
              size="large"
              onClick={() => navigate("/login")}
              style={{ borderColor: 'rgb(255, 192, 0)', backgroundColor: 'black', color: 'rgb(255, 192, 0)' }}>
              Cancelar
            </StyledButton>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <StyledButton
              className="saveButton"
              size="large"
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: 'rgb(255, 192, 0)', color: 'black' }}
              disabled={loading}
            >
              {loading ? <Spin /> : 'Salvar'}
            </StyledButton>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  );
};
