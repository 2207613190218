import styled from 'styled-components'

export const Container = styled.div<any>`
  background: #0a0a0a;
  width: 244px;
  height: 32px;
  border-radius: 8px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .card-header {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`
export const ContainerIcon = styled.div<any>`
  margin-top: 4px;
  img {
    width: 16px;
    height: 16px;
  }
`

export const CardCandidate = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  gap: 8px;
  .card-tile-body {
    display: flex;
    margin-left: 16px;
    flex-wrap: wrap;
    gap: 16px;
  }
`
