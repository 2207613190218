import React from 'react'
import { Row, IconWrapper } from './styles'
import Text from '../../../../componentsNew/Text'
import ProgressMinimal from '../../../../componentsNew/ProgressMinimal'

interface ITableRow {
  borderColor?: any
  iconName?: any
  service: string
  value: number
  progress: number
  hasIcon?: boolean
  index: number
}

const TableRow: React.FC<ITableRow> = ({ borderColor, iconName, service, value, progress, hasIcon = false, index }) => {
  return (
    <Row borderColor={borderColor} index={index}>
      {hasIcon && (
        <IconWrapper>
          <img src={iconName} alt={iconName} />
        </IconWrapper>
      )}
      <div className="text-item">
        <Text size="small">{service}</Text>
      </div>
      <div className="values">
        <div className="text">
          <Text size="small">{value}</Text>
        </div>
        <div className="wrapper-progress">
          <ProgressMinimal value={progress} size="small" />
        </div>
        <div className="percent">
          <Text size="small">{progress}%</Text>
        </div>
      </div>
    </Row>
  )
}

export default TableRow
