import React from 'react'
import Spin from 'componentsNew/Spin'
import Card from 'componentsNew/Card'
import 'leaflet/dist/leaflet.css'
import { Container } from './styles'
import Map from './map'

type MapData = {
  total_votes: number
  longitude: number
  latitude: number
  name: string
}

interface IHeatMap {
  mapData: MapData[]
  height?: string
  title?: string | null
  loading: boolean
  titleCard?: string
}

const HeatMap = ({ title = 'Mapa de calor', height, loading, ...rest }: IHeatMap) => {
  return (
    <Container height={height}>
      <Card title="Mapa de calor">
        {loading ? (
          <div className="spin-loading">
            <Spin />
          </div>
        ) : (
          <Map {...rest} height={height} />
        )}
      </Card>
    </Container>
  )
}

export default HeatMap
