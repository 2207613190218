import React from 'react'
import { Tooltip } from '@mui/material'
import Card from '../../../../componentsNew/Card'
import Text from '../../../../componentsNew/Text'
import { Container, WrapperCard } from './styles'
import ProgressMinimal from '../../../../componentsNew/ProgressMinimal'
import iconGovernante from '../../../../assets/icon-arquetipos-governante.svg'
import iconVisionario from '../../../../assets/icon-arquetipos-visionario.svg'
import iconEstrategista from '../../../../assets/icon-arquetipos-estrategista.svg'
import iconMicrofone from '../../../../assets/iconMicrophone.svg'
import iconGestor from '../../../../assets/icon-arquetipos-gestor.svg'
import iconGuardiao from '../../../../assets/icon-arquetipos-guardiao.svg'

const TopArchetypes = ({ archetype }: any) => {
  const getIconForArchetype = (id: number) => {
    switch (id) {
      case 1:
        return iconEstrategista
      case 2:
        return iconVisionario
      case 3:
        return iconGovernante
      case 4:
        return iconMicrofone
      case 5:
        return iconGestor
      case 6:
        return iconGuardiao
      default:
        return iconEstrategista
    }
  }

  return (
    <WrapperCard>
      <Card title="TOP 3 Arquétipos">
        <Container>
          {archetype.length > 0 ? (
            archetype?.map((archetype: any, index: any) => (
              <div key={index} className="wrapper-progress">
                <Tooltip title={archetype?.name} placement="top-end">
                  <div className="bird">
                    <img src={getIconForArchetype(archetype?.archetype_id)} alt="icon" />
                  </div>
                </Tooltip>
                <div className="progress">
                  <ProgressMinimal value={archetype?.score} size="xMedium" />

                  <Text size="xSmall" className="topArcPercet">
                    {archetype?.score.toFixed(0)}%
                  </Text>
                </div>
              </div>
            ))
          ) : (
            <Text>Nenhuma informação de adesão encontrada para o candidato</Text>
          )}
        </Container>
      </Card>
    </WrapperCard>
  )
}

export default TopArchetypes
