import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Card from 'componentsNew/Card'
import Spin from 'componentsNew/Spin'
import Text from 'componentsNew/Text'
import CardAllApplications from 'componentsNew/CardAllApplications'
import { TransformedPartyData } from './types/allApplicationsTypes'
import { Container } from './styles'
import { getParties, getPartyTotals, getPartyImage } from './services/allApplicationsServices'

const AllApplications = () => {
  const history = useNavigate()
  const [partiesData, setPartiesData] = useState<TransformedPartyData[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [totalsPositions, setTotalsPositions] = useState<any>()
  const [partyImagesLoaded, setPartyImagesLoaded] = useState<boolean>(false)

  const fetchParties = async () => {
    setLoading(true)

    try {
      const partiesData = await getParties()
      const totals = await getPartyTotals()

      if (!('message' in partiesData)) {
        const sortedParties = partiesData.sort((a, b) => {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return (
            b.valueCandidates.reduce((acc, cur) => acc + parseInt(cur.value || '0', 10), 0) -
            a.valueCandidates.reduce((acc, cur) => acc + parseInt(cur.value || '0', 10), 0)
          )
        })

        setPartiesData(sortedParties)
        setTotalsPositions(totals)
        await fetchPartyImages(sortedParties)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchPartyImages = async (partiesData: TransformedPartyData[]) => {
    try {
      const promises = partiesData.map(party => getPartyImage(party.title))
      await Promise.all(promises)
      setPartyImagesLoaded(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchParties()
  }, [])

  const handleCardClick = (id: number) => {
    history(`/dashboard/all-applications/national-panorama/${id}`)
  }

  return (
    <Container>
      {partiesData.length > 0 && partyImagesLoaded && (
        <Card>
          <Text className="title-totals-positions" size="medium" bold>
            TOTAL DE CARGOS
          </Text>
          <div className="card-totals-positions">
            <Text className="text-card" size="medium" color="yellow" bold>
              <Text>Prefeitos</Text>
              {totalsPositions?.prefeitos}
            </Text>
            <Text className="text-card" size="medium" color="yellow" bold>
              <Text>Vice Prefeitos</Text>
              {totalsPositions?.vices}
            </Text>
            <Text className="text-card" size="medium" color="yellow" bold>
              <Text>Vereadores</Text>
              {totalsPositions?.vereadores}
            </Text>
          </div>
        </Card>
      )}

      {loading ? (
        <Spin fullscreen />
      ) : (
        partiesData.length > 0 &&
        /* partyImagesLoaded && */
        partiesData.map(dataCandidate => (
          <>
            <CardAllApplications
              key={dataCandidate.id}
              id={dataCandidate.id}
              icon={dataCandidate.icon}
              title={dataCandidate.title}
              valueCandidates={dataCandidate.valueCandidates}
              onClick={() => handleCardClick(dataCandidate.id)}
            />
          </>
        ))
      )}
    </Container>
  )
}

export default AllApplications
