import { CiCloudOff } from 'react-icons/ci'
import * as S from './styles'

interface EmptyProps {
  title?: string
  description?: string
}

export const Empty = ({ title, description = 'Nenhuma menção encontrada' }: EmptyProps) => {
  return (
    <S.EmptyData>
      <CiCloudOff size={22} />
      {title && <h4>{title}</h4>}
      {description && <p>{description}</p>}
    </S.EmptyData>
  )
}
