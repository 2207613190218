import { apiElectoralIntelligence } from 'services/apiService'
import { MOCK_PESTAL } from './MOCK/PESTAL'
import { Column, MentionsPestalRequest, Row } from './types/mentionsServiceTypes'
import simulateRequest from "../../../utils/simulateRequest";

type ErrorMessage = {
  message: string
}

type Mentions = {
  columns: Column
  rows: Row[]
}

export interface returnMentionsTypes {
  elector: Mentions
  government: Mentions
}

type responseGetMentions = returnMentionsTypes | ErrorMessage

type responseGetPestal = MentionsPestalRequest | ErrorMessage

export const getMentions = async () => {
  // try {
  //   const { data } = await simulateRequest(MENTIONS_MOCK)
  //
  //   // const governamentTopic = formatter(data.government.topics)
  //   // const electorTopic = formatter(data.elector.topics)
  //
  //   const returnData: returnMentionsTypes = {
  //     elector: {
  //       columns: electorTopic.column,
  //       rows: electorTopic.row,
  //     },
  //     government: {
  //       columns: governamentTopic.column,
  //       rows: governamentTopic.row,
  //     },
  //   }
  //
  //   return returnData
  // } catch (e) {
  //   console.error('Erro ao buscar dados:', e)
  //   return { message: 'Não foi possivel buscar dados cadastrados.' }
  // }
}

export const getPestalMentions = async (): Promise<responseGetPestal> => {
  try {
    const { data } = await simulateRequest(MOCK_PESTAL)

    return data
  } catch (e) {
    return { message: 'Não foi possivel buscar menções.' }
  }
}

export const getWordCloud = async (socialNetwork: string, sentiment: string): Promise<any> => {
  try {
    const { data } = await apiElectoralIntelligence.get(
      `/wordcloud?&sentiment=${sentiment}&page_type_name=${socialNetwork}`
    )

    console.log('gg', data)

    return data
  } catch (e) {
    return { message: 'Não foi possivel buscar menções.' }
  }
}
