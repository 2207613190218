import React from 'react'
import { ConfigProvider, SwitchProps, theme } from 'antd'

import * as S from './styles'

interface CustomSwitchTypes extends SwitchProps {
  label?: string
}

export const CustomSwitch = ({ label, ...rest }: CustomSwitchTypes) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Switch: {
            colorPrimary: '#302301',
            colorPrimaryHover: 'rgb(49, 49, 49)',
            handleBg: '#000000',
            colorPrimaryBorder: 'rgb(116, 116, 116)',
            algorithm: true,
            handleSize: 9,
            trackHeight: 12,
            trackMinWidth: 26,
            trackPadding: 2,
          },
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <S.SwitchCustom {...rest} />
    </ConfigProvider>
  )
}
