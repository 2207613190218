import { styled } from "styled-components";

export const Wrapper = styled.main``


export const Form = styled.div`
width: 100%;
display: flex;
flex-direction: column;
margin: 24px 0;
gap: 12px;

`

export const Footer = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 12px;
justify-content: space-between;
`


