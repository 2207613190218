import React, { useEffect, useState } from 'react'
import NewsMarquee from 'componentsNew/NewsMarquee'
import HeatMap from 'components/HeatMap'
import { useFilterGlobal } from 'hooks/useFilterGlobal'
import AreaChart from 'componentsNew/AreaChart'
import RaioX from 'pages/PoliticalListening/components/RaioX'
import PrincipalMessage from 'pages/PoliticalListening/components/PrincipalMessage'
import { Empty } from 'componentsNew/Empty'
import ChartMentionSection from 'componentsNew/ChartMentionSection'
import { useCandidates } from 'hooks/useCandidates'
import { useUser } from 'global/UserContext'
import { BaseCard } from '../BaseCard'
import MapEleitorRelevante from '../../../pages/PoliticalListening/components/MapEleitorRelevantes'
import { getMapData, getSentiment } from '../services/getListeners'
import Spin from '../../Spin'
import { defaultOrderListening, MENTIONS_PARTY_PT, MENTIONS_PARTY_RP } from '../services/MOCK/PARTY_MENTION'
import { formatter } from '../services/utils/formatterMentions'
import * as S from './styles'

export const ListeningCity = ({ activeTab, map, setMap, mapLoading, setMapLoading }: any) => {
  const [loading, setLoading] = useState(true)
  const [mentionsSentiment, setMentionsSentiment] = useState({} as any)
  const { filterMentions, rangeDate, filterRaioX, setRenderType } = useFilterGlobal()
  const { selectedCandidate } = useCandidates()
  const target_locality = selectedCandidate?.locality
  const { user } = useUser()

  const selectMockChart = () => {
    if (user) {
      const party = user?.person?.party_acronym
      switch (party) {
        case 'REPUBLICANOS':
          return formatter(MENTIONS_PARTY_RP, defaultOrderListening)

        case 'PT':
          return formatter(MENTIONS_PARTY_PT, defaultOrderListening)

        default:
          break
      }
    }

    return formatter(MENTIONS_PARTY_PT, defaultOrderListening)
  }

  useEffect(() => {
    if (activeTab === 'tab_political_listening_city') {
      getFeeling()
      fetchMap()
      setRenderType('region')
    }
  }, [filterMentions, rangeDate, filterRaioX])

  const getFeeling = async () => {
    setLoading(true)
    try {
      const params = {
        cd_city: target_locality,
        parent_name: filterMentions !== 'all' && filterMentions,
        date_start: rangeDate.initial_date,
        date_end: rangeDate.end_date,
        sentiment: filterRaioX !== 'all' && filterRaioX,
      }

      const data = await getSentiment(params)
      const state = {
        series: data.series,
        options: {
          chart: {
            id: 'basic-bar',
          },
          colors: ['#00E23F', '#FF0000', '#FFFFFF'],
          xaxis: {
            categories: data.dates,
          },
        },
      }
      setMentionsSentiment({ ...state })
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  const fetchMap = async () => {
    setMapLoading(true)
    try {
      const params = {
        city: target_locality,
        topic_name: filterMentions !== 'all' && filterMentions,
        date_start: rangeDate.initial_date,
        date_end: rangeDate.end_date,
        sentiment: filterRaioX !== 'all' && filterRaioX,
      }

      const data = await getMapData(params)

      setMap(data)
    } catch (e) {
      console.log(e)
    } finally {
      setMapLoading(false)
    }
  }

  if (activeTab !== 'tab_political_listening_city') return null

  return (
    <S.Wrapper>
      <S.NewsBox>
        <NewsMarquee />
      </S.NewsBox>
      <S.Section>
        <S.ContentSection height="740px">
          <S.Box>
            <S.Heading>Principais mensagens</S.Heading>
            <S.SectionMessage>
              <PrincipalMessage />
            </S.SectionMessage>
          </S.Box>
        </S.ContentSection>
        <S.ContentSection style={{ flex: '1.5' }}>
          <S.Box height="220px">
            <S.Heading onClick={() => fetchMap()}>Análise de sentimento</S.Heading>
            {loading ? (
              <S.LoadingBox>
                <Spin spinning />
              </S.LoadingBox>
            ) : (
              <BaseCard>
                {mentionsSentiment.options.xaxis.categories?.length > 0 ? (
                  <AreaChart options={mentionsSentiment?.options} series={mentionsSentiment?.series} />
                ) : (
                  <Empty />
                )}
              </BaseCard>
            )}
          </S.Box>
          <S.Box height="511px">
            {mapLoading ? (
              <S.LoadingBox>
                <Spin spinning />
              </S.LoadingBox>
            ) : (
              activeTab === 'tab_political_listening_city' &&
              map.length > 0 && <HeatMap mapData={map} loading={mapLoading} height="435px" titleCard="Menções" />
            )}
          </S.Box>
        </S.ContentSection>
      </S.Section>
      <RaioX target_local={target_locality} />
      <S.Section>
        <S.ContentSection>
          <MapEleitorRelevante target_local={target_locality} />
        </S.ContentSection>
      </S.Section>

      <S.Section>
        <S.ContentSection>
          <ChartMentionSection
            disabled_card
            title="Programas Estratégicos do Partido [Em desenvolvimento]"
            words={[]}
            chartMentions={{ mentionData: selectMockChart() }}
          />
        </S.ContentSection>
      </S.Section>
    </S.Wrapper>
  )
}
