import React from 'react'
import { Container } from './styles'

interface IAvatar {
  img: any
  size?: 'small' | 'medium' | 'large'
}

const Avatar = ({ img, size = 'small' }: IAvatar) => {
  return (
    <Container size={size}>
      <img src={img} alt="foto avatar" />
    </Container>
  )
}

export default Avatar
