import React from 'react'
import { Position, TransformedCandsData } from 'pages/AllApplications/types/allApplicationsTypes'
import { Container, ContainerMaster } from './styles'
import Card from '../../../../componentsNew/Card'
import Text from '../../../../componentsNew/Text'

interface IModalCandidateProps {
  candidatos: TransformedCandsData[]
  partido: string
  estado: string
  cidade: string
  cargo: string
}

type GroupedCandidatesType = Record<Position, string[]>

const ModalCandidate = ({ candidatos, partido, estado, cidade, cargo }: IModalCandidateProps) => {
  const groupedCandidates = candidatos.reduce<GroupedCandidatesType>((acc, cand) => {
    const position = cand?.position
    if (!acc[position]) {
      acc[position] = []
    }
    cand.candidates.forEach(candidate => acc[position].push(candidate.name))
    return acc
  }, {} as GroupedCandidatesType)

  return (
    <Container>
      {Object.entries(groupedCandidates).map(([position, candidates], index) => (
        <Card key={index}>
          <div className="card-header">
            <Text bold>Partido: {partido}</Text>
            <Text bold>Cargo: {position}</Text>
            <Text bold>Estado: {estado}</Text>
            <Text bold>Minicípio: {cidade}</Text>
          </div>
          <ContainerMaster>
            {candidates.map((name: string, index: any) => (
              <div className="intercalada" key={index}>
                {index + 1}. {name}
              </div>
            ))}
          </ContainerMaster>
        </Card>
      ))}
    </Container>
  )
}

export default ModalCandidate
