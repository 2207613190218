import styled from 'styled-components'

export const HeaderContainer = styled.header<any>`
  background-color: #302301;
  color: white;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ open }) => (open ? '7.87rem' : '3.5rem')};
  transition: padding-left 0.3s;
  padding-right: 2.25rem;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`

export const TitleText = styled.h1`
  color: #e9e9f0;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;
  line-height: normal;
  letter-spacing: 0rem;
  text-align: left;
  margin: 0;
  color: white;
`

export const LogoHeaderImage = styled.img`
  width: 11.75rem;
  height: 1.232rem;
`
