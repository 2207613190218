import styled from 'styled-components'

export const Container = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`
export const Content = styled.div`
  width: 100%;
`
export const InputsDates = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 16px 0;
  flex-wrap: wrap;
`
