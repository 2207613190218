import React, { useEffect, useState } from "react";
import { useCandidates } from "hooks/useCandidates";
import { apiMentions } from "../../../../services/apiService";
import {
  formatDateTime,
  handleServiceColor,
  iconRedMens,
} from "../../../../utils/utilityFunctions";
import CardMentions from "../../../../componentsNew/CardMentions";
import Spin from "../../../../componentsNew/Spin";
import { Wrapper } from "../../../PrederredCandidate/styles";
import { Content } from "./styles";
import { useFilterGlobal } from "../../../../hooks/useFilterGlobal";
import { ALL } from "../../../../utils/constants";

interface IPrincipalMessage {
  isCandidate?: boolean;
}
const PrincipalMessage = ({ isCandidate }: IPrincipalMessage) => {
  const { filterMentions, rangeDate, filterRaioX } = useFilterGlobal();
  const { selectedCandidate } = useCandidates();
  const target_locality = selectedCandidate?.locality || "BRA.SP.Sao Paulo";
  const [mentions, setMentions] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTopMentions = async () => {
    /*     const topicName = filterMentions !== ALL ? `&topic_name=${filterMentions}` : ''
     */
    const parentName =
      filterMentions !== ALL ? `&parent_name=${filterMentions}` : "";
    const initialDate = rangeDate.initial_date
      ? `&initial_date=${rangeDate.initial_date}`
      : "";
    const endDate = rangeDate.endDate ? `&final_date=${rangeDate.endDate}` : "";
    const sentiment = filterRaioX !== ALL ? `&sentiment=${filterRaioX}` : "";
    const cd_city =
      selectedCandidate?.locality && !isCandidate
        ? `city=${selectedCandidate?.locality}`
        : "";
    const id_cand =
      selectedCandidate?.id_cand && isCandidate
        ? `&id_cand=${selectedCandidate?.id_cand}`
        : "";
    try {
      setLoading(true);
      const { data } = await apiMentions.get(
        `/top-mentions/?${cd_city}${parentName}${initialDate}${endDate}${sentiment}${id_cand}`,
      );
      setMentions(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const renderMentions = (mentions: any) => {
    return mentions.map((mention: any, index: any) => {
      const service = mention?.parent_name;
      const serviceData = handleServiceColor(service);

      return (
        <CardMentions
          key={index}
          color={serviceData?.color || "defaultColor"}
          iconSocial={iconRedMens(mention.page_type_name).icon}
          message={mention.full_text}
          user={mention.author}
          dataCreate={formatDateTime(mention.added)}
          iconServices={serviceData?.icon}
          url={mention?.url}
          fullWidth
        />
      );
    });
  };

  useEffect(() => {
    getTopMentions();
  }, [filterMentions, rangeDate, filterRaioX]);
  return (
    <Content>
      {loading ? (
        <Spin spinning />
      ) : (
        <Wrapper>{renderMentions(mentions)}</Wrapper>
      )}
    </Content>
  );
};

export default PrincipalMessage;
