import { TOPIC_NAMES } from 'componentsNew/ElectionStrategyComponents/MOCK/competitiveMock'
import { removeTopic, updateTopic } from 'componentsNew/ElectionStrategyComponents/services/updateByTopic'
import { CandidateType } from 'componentsNew/ElectionStrategyComponents/types/candidateTypes'
import InternalButton from 'componentsNew/InternalButton'
import { MdDeleteForever } from 'react-icons/md'
import { noop } from 'lodash'
import theme from 'styles/theme'
import { useUser } from 'global/UserContext'
import {
  deleteTheme,
  sendTheme,
  updateListener,
} from 'componentsNew/ElectionStrategyComponents/services/competitiveServices'
import { CardSelection } from '../CardSelection'
import CardToggleOverlay from '../DisabledBox'
import { ProfileSelect } from '../ProfileSelect'

import * as S from './styles'

interface profileProps extends CandidateType {
  avatar?: string
  category?: string
  name?: string
}

interface PostThemeDataProps {
  topicList: any[]
  topicIndex: number
  themeIndex: number
  newValue: any
  isPositive?: boolean
}

interface CardCompetitiveCandidateProps {
  profile?: profileProps
  disabled?: boolean
  handleProfile?: (data: CandidateType) => void
  default_list?: any[]
  onRemove?: (id: string) => void
  withRemove?: boolean
  themeData?: any
}

export const CardCompetitiveCandidate = ({
  disabled,
  profile,
  handleProfile = noop,
  default_list = [],
  onRemove = noop,
  withRemove,
  themeData = [],
}: CardCompetitiveCandidateProps) => {
  const { user } = useUser()

  const updateListnerById = (checked: boolean, data?: any) => {
    updateListener({ is_listening: checked, adversary_id: data?.id, person: data?.person })
  }

  const postThemeData = ({ topicList, topicIndex, themeIndex, newValue, isPositive }: PostThemeDataProps) => {
    /* sendTheme() */
    updateTopic(topicList, topicIndex, themeIndex, newValue)
    sendTheme({ theme_data: { ...newValue?.data, person: user?.id, is_positive: isPositive } })
  }

  const removeTheme = ({ topicList, topicIndex, themeIndex, newValue }: PostThemeDataProps) => {
    deleteTheme(newValue?.id)
    removeTopic(topicList, topicIndex, themeIndex)
  }

  return (
    <S.Wrapper>
      <S.WrapperProfileCard>
        {disabled && (
          <CardToggleOverlay
            isDisabled
            addCard={data =>
              handleProfile({
                ...data,
                topicList: TOPIC_NAMES.map(item => ({
                  name: item,
                  theme_list: Array.from({ length: 8 }),
                })),
              })
            }
          />
        )}

        <S.Heading>
          <ProfileSelect
            profile_data={profile}
            width="30%"
            defaultValue={profile}
            disabled={disabled}
            categoryLabel
            onChangeListener={check => updateListnerById(check, profile)}
          />

          {withRemove && (profile?.id_cand || profile?.candidate_id) && (
            <InternalButton
              background={theme.COLORS.gray10}
              variant="outline"
              icon={<MdDeleteForever />}
              onClick={() => onRemove(profile.id_cand)}
            >
              Remover
            </InternalButton>
          )}
        </S.Heading>

        <S.ContentList>
          {!disabled &&
            default_list.map((item, topicIndex) => {
              return (
                <S.BoxListGrid key={item.id}>
                  <CardSelection
                    dataSelection={item}
                    default_theme={themeData}
                    onChange={(index: any, value: any) =>
                      postThemeData({
                        topicList: default_list,
                        topicIndex,
                        themeIndex: index,
                        newValue: value,
                        isPositive: false,
                      })
                    }
                    onRemoveTheme={(index: any, value: any) =>
                      removeTheme({
                        topicList: default_list,
                        topicIndex,
                        themeIndex: index,
                        newValue: value,
                      })
                    }
                  />
                </S.BoxListGrid>
              )
            })}
        </S.ContentList>
      </S.WrapperProfileCard>
    </S.Wrapper>
  )
}
