import React from "react";
import Chart from "react-apexcharts";
import Spin from "componentsNew/Spin";
import IconFacebook from "assets/icon-facebook.svg";
import IconInstagram from "assets/icon-instagram.svg";
import IconX from "assets/icon-x.svg";
import IconYouTube from "assets/icon-youtube.svg";
import IconGoogle from "assets/icon-google.svg";
import Text from "componentsNew/Text";
import AdversaryFilterCard from "componentsNew/CockpitComponents/components/AdversaryFilterCard";
import { IShareOfVoice } from "componentsNew/CockpitComponents/types/shareOfVoiceTypes";
import * as S from "./styles";

const ShareOfVoice: React.FC<IShareOfVoice> = ({
  avatars,
  adversaryList,
  selectedAdversary,
  socialNetworks,
  sources,
  candidates,
  totals,
  pagesTotals,
  data,
  loading,
  error,
  onToggleAdversary,
}) => {
  if (!Array.isArray(adversaryList) || adversaryList.length === 0) {
    return <S.ErrorMessage>Lista de adversários inválida</S.ErrorMessage>;
  }

  if (!Array.isArray(selectedAdversary) || selectedAdversary.length === 0) {
    return (
      <S.ErrorMessage>
        Dados inválidos de adversários selecionados
      </S.ErrorMessage>
    );
  }

  if (!Array.isArray(socialNetworks) || socialNetworks.length === 0) {
    return <S.ErrorMessage>Redes Sociais inválidas</S.ErrorMessage>;
  }

  if (loading) {
    return <Spin />;
  }

  if (error || !data || data.length === 0) {
    return <S.ErrorMessage>{error || "Nenhum dado disponível"}</S.ErrorMessage>;
  }

  const series = data.map((candidate) => ({
    name: candidate.candidate_name,
    data: candidate.data,
    color: candidate.color,
  }));

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: sources,
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: false,
      },
    },
    colors: data.map((candidate) => candidate.color),
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: "dark",
      style: {
        fontSize: "12px",
        fontFamily: "Roboto",
      },
      x: {
        show: true,
      },
      y: {
        formatter: (val: number, { seriesIndex, dataPointIndex, w }) => {
          const total = w.globals.stackedSeriesTotals[dataPointIndex];
          const percentage = ((val / total) * 100).toFixed(0);
          const formattedVal = val.toLocaleString("pt-BR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
          return `${formattedVal} (${percentage}%)`;
        },
      },
      marker: {
        show: true,
      },
    },
    legend: {
      show: false,
    },
  };

  const iconMap = {
    Facebook: IconFacebook,
    Instagram: IconInstagram,
    X: IconX,
    YouTube: IconYouTube,
    Google: IconGoogle,
  };

  const chartHeight = data.length <= 2 ? 160 : 160 + (data.length - 2) * 32;

  const formatTotal = (total: number) => {
    if (total >= 1000000) {
      return `${(total / 1000000).toFixed(1).replace(".", ",")} M`;
    } else if (total >= 1000) {
      return `${(total / 1000).toFixed(0).replace(".", ",")} mil`;
    } else {
      return `${total.toLocaleString("pt-BR")}    `;
    }
  };

  return (
    <S.Container>
      <S.ChartContainer>
        <S.ChartContent>
          <>
            <Chart
              options={options}
              series={series}
              type="bar"
              height={chartHeight}
            />
            <S.IconsContainer>
              {sources.map((network) => (
                <S.TotalsContainer key={network}>
                  <img
                    src={iconMap[network as keyof typeof iconMap]}
                    alt={network}
                    width="24"
                    height="24"
                  />
                  <Text bold size="xSmall">
                    {formatTotal(pagesTotals[network] || 0)}
                  </Text>
                </S.TotalsContainer>
              ))}
            </S.IconsContainer>
          </>
        </S.ChartContent>
      </S.ChartContainer>
      <S.AdversaryContainer>
        <S.AdversaryList>
          {data.map((adversary) => (
            <AdversaryFilterCard
              key={adversary.candidate_id}
              candidate={adversary}
              isActive={true}
              onToggle={onToggleAdversary}
              borderColor={adversary.color}
              avatarUrl={avatars[adversary.candidate_id || ""]}
              isClickable={false}
            />
          ))}
        </S.AdversaryList>
      </S.AdversaryContainer>
    </S.Container>
  );
};

export default ShareOfVoice;
