import React, { useEffect, useState } from 'react'
import { Container, EditButton, DaysCounter, IncioRoot, Day, ContentDay } from './styles'
import Modal from '../../componentsNew/Modal'
import Text from '../../componentsNew/Text'
import ControlledComponent from '../../componentsNew/InputDataPicker'

interface ITimeCardStartProps {
  initialDate: string
  daysUntilElection: number
  title?: string
  slug?: string
  onChange: (value: any, value1: any) => void
  error?: boolean
  helperText?: string
  minMaxDate?: {
    min: string
    max: string
  }
  defaultDisabled?: boolean
}

const TimeCardStart: React.FC<ITimeCardStartProps> = ({
  initialDate,
  daysUntilElection,
  title,
  onChange,
  slug,
  error,
  helperText,
  minMaxDate,
  defaultDisabled,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [value, setValue] = useState<any>(null)
  const [disabled, setDisabled] = useState<boolean>(true)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const toggleModal = () => {
    if (!defaultDisabled) {
      if (disabled) {
        setDisabled(false)
      } else {
        setIsModalOpen(!isModalOpen)
      }
    }
  }

  useEffect(() => {
    if (!initialDate) return
    const newDate = new Date(initialDate)
    const formattedDate = newDate.toISOString() ? newDate.toISOString().split('T')[0] : null
    setValue(formattedDate)
  }, [initialDate])

  const onSubmmit = () => {
    onChange(slug, value)
    closeModal()
    setDisabled(true)
  }

  return (
    <Container>
      <div className="heading_content">
        <IncioRoot>{title}</IncioRoot>
        <EditButton onClick={toggleModal} primary={isModalOpen}>
          {isModalOpen || !disabled ? 'Salvar' : 'Editar'}
        </EditButton>
      </div>

      <ControlledComponent
        onChange={(e: any) => setValue(e)}
        defaultValue={value}
        disabled={disabled}
        label="Data"
        error={error}
        helperText={helperText}
        maxDate={minMaxDate?.max}
        minDate={minMaxDate?.min}
      />

      <ContentDay>
        <DaysCounter>{daysUntilElection}</DaysCounter>
        <Day>dias</Day>
      </ContentDay>

      {isModalOpen && (
        <div className="modal-timeline">
          <Modal
            closeModal={closeModal}
            onPrimaryClick={onSubmmit}
            primaryTextButton="Salvar"
            secondaryTextButton="Cancelar"
            title="CONFIRMAÇÃO"
            onSecondaryClick={closeModal}
          >
            <Text size="xSmall">Deseja realmente salvar as alterações feitas?</Text>
          </Modal>
        </div>
      )}
    </Container>
  )
}

export default TimeCardStart
