import React from "react";
import { Container } from "./styles";
import FilterDate from "./FilterDate";
import FilterSentiment from "./FilterSentiment";

const Cockpit = () => {
  return (
    <Container>
      <FilterDate />
      <FilterSentiment />
    </Container>
  );
};

export default Cockpit;
