import styled from 'styled-components'

export const Container = styled.div<any>`
  display: flex;
  width: 100%;
  text-align: left;

  .wrapper-header {
    max-width: 607px;
    padding: 8px 0;
  }

  .title {
    .icon-title {
      margin-top: 4px;
    }

    display: flex;
    align-items: center;
    gap: 8px;
  }
`
