import React, { useState, useEffect } from "react";
import { subHours, format } from "date-fns";
import { apiCopilot } from "services/apiService";
import Text from "componentsNew/Text";
import Spin from "componentsNew/Spin";
import HistoryCard from "./components/HistoryCard";
import { useUser } from "../../../../global/UserContext";

import * as S from "./styles";

interface IMessage {
  id: string;
  type: "analyst" | "strategist";
  title: string;
  date: string;
  favorite: boolean;
}

interface HistoricProps {
  setActiveFilter: (filter: any) => void;
  setChatIds: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  onResume: (id: string, type: string) => void;
}

const Historic: React.FC<HistoricProps> = ({
  setActiveFilter,
  setChatIds,
  onResume,
}) => {
  const [favoriteMessages, setFavoriteMessages] = useState<IMessage[]>([]);
  const [otherMessages, setOtherMessages] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState<string | null>(null);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const { user } = useUser();
  const userIdCopilot = user?.person?.id;

  const [favoritePage, setFavoritePage] = useState(1);
  const [otherPage, setOtherPage] = useState(1);
  const [favoriteTotalPages, setFavoriteTotalPages] = useState(1);
  const [otherTotalPages, setOtherTotalPages] = useState(1);

  useEffect(() => {
    fetchMessages(true, 1);
    fetchMessages(false, 1);
  }, []);

  const fetchMessages = async (isFavorite = true, page = 1) => {
    setLoading(true);
    try {
      const response = await apiCopilot.get(
        `/copilot/v1/chats?user_id=${userIdCopilot}&is_favorite=${isFavorite}&page=${page}&size=7`,
      );

      const formatMessages = (messages: any) => {
        return messages.items.map((msg: any) => ({
          id: msg.id,
          type: msg.agent_name.toLowerCase(),
          title: msg.title,
          date: format(
            subHours(new Date(msg.created_at), 3),
            "dd/MM/yyyy HH:mm",
          ),
          favorite: msg.is_favorite,
        }));
      };

      const formattedMessages = formatMessages(response.data);
      const totalPages = response.data.pages;

      if (isFavorite) {
        setFavoriteMessages(formattedMessages);
        setFavoritePage(page);
        setFavoriteTotalPages(totalPages);
      } else {
        setOtherMessages(formattedMessages);
        setOtherPage(page);
        setOtherTotalPages(totalPages);
      }
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleFavorite = async (id: string) => {
    setActionLoading(id);
    const message = [...favoriteMessages, ...otherMessages].find(
      (msg) => msg.id === id,
    );
    if (!message) return;

    try {
      await apiCopilot.patch(`/copilot/v1/chats/${id}`, {
        is_favorite: !message.favorite,
      });
      if (message.favorite) {
        setFavoriteMessages((prev) => prev.filter((msg) => msg.id !== id));
        setOtherMessages((prev) => [...prev, { ...message, favorite: false }]);
      } else {
        setFavoriteMessages((prev) => [
          ...prev,
          { ...message, favorite: true },
        ]);
        setOtherMessages((prev) => prev.filter((msg) => msg.id !== id));
      }
    } catch (error) {
      console.error("Failed to toggle favorite:", error);
    } finally {
      setActionLoading(null);
    }
  };

  const handleResumeConversation = (id: string, type: string) => {
    onResume(id, type);
  };

  const handleDeleteConversation = async (id: string) => {
    setActionLoading(id);
    try {
      await apiCopilot.delete(`/copilot/v1/chats/${id}`);
      setFavoriteMessages((prev) => prev.filter((msg) => msg.id !== id));
      setOtherMessages((prev) => prev.filter((msg) => msg.id !== id));
    } catch (error) {
      console.error("Failed to delete conversation:", error);
    } finally {
      setActionLoading(null);
    }
  };

  const handleRenameConversation = async (id: string, newTitle: string) => {
    setActionLoading(id);
    try {
      await apiCopilot.patch(`/copilot/v1/chats/${id}`, { title: newTitle });
      setFavoriteMessages((prev) =>
        prev.map((msg) => (msg.id === id ? { ...msg, title: newTitle } : msg)),
      );
      setOtherMessages((prev) =>
        prev.map((msg) => (msg.id === id ? { ...msg, title: newTitle } : msg)),
      );
    } catch (error) {
      console.error("Failed to rename conversation:", error);
    } finally {
      setActionLoading(null);
    }
  };

  const handlePageChange = (isFavorite: boolean, page: number) => {
    fetchMessages(isFavorite, page);
  };

  const renderPageNumbers = (
    totalPages: number,
    currentPage: number,
    isFavorite: boolean,
  ) => {
    const pageNumbers = [];
    const maxPagesToShow = 3;

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        pageNumbers.push(
          <S.PageNumber
            key={i}
            active={i === currentPage}
            onClick={() => handlePageChange(isFavorite, i)}
          >
            {i}
          </S.PageNumber>,
        );
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        pageNumbers.push(<span key={`ellipsis-${i}`}>...</span>);
      }
    }

    return pageNumbers;
  };

  return (
    <>
      <S.Container>
        {loading ? (
          <Spin fullscreen />
        ) : (
          <>
            {favoriteMessages.length === 0 && otherMessages.length === 0 ? (
              <S.EmptyMessage>
                <Text size="xSmall" bold>
                  Nenhuma conversa encontrada no histórico.
                </Text>
              </S.EmptyMessage>
            ) : (
              <>
                <S.Section>
                  {favoriteMessages.length > 0 && (
                    <S.SectionTitle>Favoritas</S.SectionTitle>
                  )}
                  {favoriteMessages.map((message) => (
                    <HistoryCard
                      key={message.id}
                      message={message}
                      onToggleFavorite={handleToggleFavorite}
                      onResumeConversation={(id: string) =>
                        handleResumeConversation(id, message.type)
                      }
                      onDeleteConversation={handleDeleteConversation}
                      onRenameConversation={handleRenameConversation}
                      actionLoading={actionLoading === message.id}
                      openDropdownId={openDropdownId}
                      setOpenDropdownId={setOpenDropdownId}
                    />
                  ))}
                  {favoriteTotalPages > 1 && (
                    <S.Pagination>
                      {renderPageNumbers(
                        favoriteTotalPages,
                        favoritePage,
                        true,
                      )}
                    </S.Pagination>
                  )}
                </S.Section>
                <S.Section>
                  {otherMessages.length > 0 && favoriteMessages.length > 0 && (
                    <S.SectionTitle>Outras conversas</S.SectionTitle>
                  )}
                  {otherMessages.length > 0 &&
                    favoriteMessages.length === 0 && (
                      <S.SectionTitle>Conversas</S.SectionTitle>
                    )}

                  {otherMessages.map((message) => (
                    <HistoryCard
                      key={message.id}
                      message={message}
                      onToggleFavorite={handleToggleFavorite}
                      onResumeConversation={(id: string) =>
                        handleResumeConversation(id, message.type)
                      }
                      onDeleteConversation={handleDeleteConversation}
                      onRenameConversation={handleRenameConversation}
                      actionLoading={actionLoading === message.id}
                      openDropdownId={openDropdownId}
                      setOpenDropdownId={setOpenDropdownId}
                    />
                  ))}
                  {otherTotalPages > 1 && (
                    <S.Pagination>
                      {renderPageNumbers(otherTotalPages, otherPage, false)}
                    </S.Pagination>
                  )}
                </S.Section>
              </>
            )}
          </>
        )}
      </S.Container>
    </>
  );
};

export default Historic;
