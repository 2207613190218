import React, { createContext, useContext, useState, ReactNode } from 'react'

interface TabContextType {
  activeTabContext: string
  setActiveTabContext: (tab: string) => void
}

const TabContext = createContext<TabContextType | undefined>(undefined)

export const TabProvider = ({ children }: { children: ReactNode }) => {
  const [activeTabContext, setActiveTabContext] = useState<string>('')

  console.log('CONTEXT TAB: ', activeTabContext)

  return <TabContext.Provider value={{ activeTabContext, setActiveTabContext }}>{children}</TabContext.Provider>
}

export const useTab = (): TabContextType => {
  const context = useContext(TabContext)
  if (!context) {
    throw new Error('useTab must be used within a TabProvider')
  }
  return context
}
