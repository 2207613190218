import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContainerData = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  gap: 16px;
  margin-bottom: 24px;
`

export const ContainerRaioX = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  gap: 16px;
  margin-bottom: 24px;

  .button {
    background: none;
    border: none;
  }

  .raioX {
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 30px;
    border-radius: 16px;
    background-color: #f7f7f7;
    gap: 8px;
    border: 1px solid #e9e9f0;
  }

  .raioXActive {
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 30px;
    border-radius: 16px;
    background-color: transparent;
    gap: 8px;
    border: 1px solid #e9e9f0;
  }
`

export const ContainerPoliticalListening = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  gap: 8px;
`

export const CardFilterMentions = styled.div<any>`
  display: flex;
  align-items: center;
  width: 250px;
  height: 40px;
  background: #111111;
  border-radius: 8px;
  overflow: hidden;
  opacity: ${props => (props.isActive ? '1' : '0.5')};
  cursor: pointer;

  img {
    margin-right: 8px;
  }

  .divider {
    background: ${props => (props.color ? props.color : '#111111')};
    width: 8px;
    height: 100%;
    margin-right: 8px;
  }
`
