import styled, { DefaultTheme } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  .subtitle-metric {
    display: flex;
    justify-content: start;
    align-items: center;
    min-width: 48px;
  }

  p {
    width: 50px;
  }
`
type ColorType = 'white' | 'green' | 'red'

const isColorType = (color: any): color is ColorType => {
  return ['white', 'green', 'red'].includes(color)
}

const getBackgroundColor = (color: ColorType | undefined, theme: DefaultTheme): string => {
  const defaultColor = 'white'
  const colorKey = color || defaultColor

  switch (colorKey) {
    case 'white':
      return theme.COLORS.WHITE
    case 'green':
      return theme.COLORS.GREEN
    case 'red':
      return theme.COLORS.ALERT
    default:
      return theme.COLORS.WHITE
  }
}

export const ProgressBarRitmo = styled.progress`
  width: 100%;
  height: 0.5rem;
  -webkit-appearance: none;
  appearance: none;

  &::-webkit-progress-bar {
    background-color: #171616;
    border-radius: 10px;
  }

  &::-webkit-progress-value {
    background-color: ${props => getBackgroundColor(isColorType(props.color) ? props.color : undefined, props.theme)};
    border-radius: 10px;
  }

  &::-moz-progress-bar {
    background-color: ${props => getBackgroundColor(isColorType(props.color) ? props.color : undefined, props.theme)};
    border-radius: 10px;
  }
`
