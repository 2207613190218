import { styled } from 'styled-components'
import theme from 'styles/theme'

export const Wrapper = styled.main`
  .ant-switch.ant-switch-checked {
    .ant-switch-handle {
      &::before {
        background: rgb(255, 192, 0);
      }
    }
  }
  .ant-switch-handle {
    &::before {
      background: rgb(0 0 0);
    }
  }

  .ant-modal-content {
    background: red;
  }
`

export const ActionsBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 22px;
  background: #161717;
  border-radius: 8px;
  padding: 8px 16px;

  &.empty_param {
    margin-top: 20px;
    min-height: 120px;
    justify-content: center;
  }
`

export const Heading = styled.h3`
  font-size: 16px;
  font-weight: 700;
  color: ${theme.COLORS.gray10};
  margin-bottom: 22px;
`

export const BoxAdvancedFilters = styled.div`
  background: #161717;
  border-radius: 8px;
  width: 100%;
  margin-top: 25px;
  padding: 8px 16px;
`

export const ContentAdvancedFilters = styled.div`
  width: 100%;
`

export const SaveFilterBox = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
`
export const SearchFilterBox = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  button {
    border-radius: 4px;
    padding: 8px 30px;
    min-width: 150px;
  }
`

export const StartContent = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #464646ab;
`
