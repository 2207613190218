// src/componentsNew/Helper/DiagnosisFilter/utils/defaultFilters.ts
import { apiPBI } from 'services/apiService'

interface City {
  cd_municipio: string
  nm_municipio: string
}

const fetchCities = async (stateCode: number): Promise<City[]> => {
  try {
    const response = await apiPBI.get(`/locations-data/ufs/${stateCode}/municipios`)
    return response.data.map((city: { cd_municipio: string; nm_municipio: string }) => ({
      cd_municipio: city.cd_municipio,
      nm_municipio: city.nm_municipio,
    }))
  } catch (error) {
    console.error('Erro ao buscar municípios:', error)
    return []
  }
}

const fetchNeighborhoods = async (cityCodes: string[]): Promise<string[]> => {
  try {
    const response = await apiPBI.post('/locations-data/municipios/bairros', { codigos: cityCodes })
    return cityCodes.flatMap(
      cityCode => response.data[cityCode]?.map((bairro: { nm_bairro: string }) => bairro.nm_bairro) || []
    )
  } catch (error) {
    console.error('Erro ao buscar bairros:', error)
    return []
  }
}

export const defaultFilters = {
  year: [2020],
  state: [] as string[],
  city: [] as string[],
  position: ['Prefeito'],
  round: ['1º Turno'],
  neighborhood: [] as string[],
  party: [] as string[],
  candidate: [] as string[],
}

export const pageFilters = {
  // Voter Diagnosis
  voter_pofile: [
    { table: 'Boletim_de_Urna_2018-2022', column: 'ANO_ELEICAO' },
    { table: 'db_Turno', column: 'NM_TURNO' },
    { table: 'db_municípios', column: 'db_estados.uf' },
    { table: 'db_municípios', column: 'Cidade' },
    { table: 'public local_votacao', column: 'nm_bairro' },
  ],
  x_ray_votes: [
    { table: 'Boletim_de_Urna_2018-2022', column: 'ANO_ELEICAO' },
    { table: 'Boletim_de_Urna_2018-2022', column: 'DS_CARGO_PERGUNTA' },
    { table: 'db_Turno', column: 'NM_TURNO' },
    { table: 'db_municípios', column: 'db_estados.uf' },
    { table: 'db_municípios', column: 'Cidade' },
    // { table: 'public local_votacao', column: 'nm_bairro' },
  ],

  // Election Diagnosis
  election_results: [
    { table: 'Boletim_de_Urna_2018-2022', column: 'ANO_ELEICAO' },
    { table: 'Boletim_de_Urna_2018-2022', column: 'DS_CARGO_PERGUNTA' },
    { table: 'db_Turno', column: 'NM_TURNO' },
    { table: 'db_municípios', column: 'db_estados.uf' },
    { table: 'db_municípios', column: 'Cidade' },
    { table: 'public local_votacao', column: 'nm_bairro' },
  ],
  election_history: [
    { table: 'Boletim_de_Urna_2018-2022', column: 'ANO_ELEICAO' },
    { table: 'db_municípios', column: 'db_estados.uf' },
    { table: 'db_municípios', column: 'Cidade' },
    { table: 'Boletim_de_Urna_2018-2022', column: 'DS_CARGO_PERGUNTA' },
    { table: 'db_Turno', column: 'NM_TURNO' },
  ],
  x_ray_campaigns: [
    { table: 'Boletim_de_Urna_2018-2022', column: 'ANO_ELEICAO' },
    { table: 'db_municípios', column: 'db_estados.uf' },
    { table: 'db_municípios', column: 'Cidade' },
    { table: 'db_Turno', column: 'NM_TURNO' },
  ],
  campaing_panel: [
    { table: 'Boletim_de_Urna_2018-2022', column: 'ANO_ELEICAO' },
    { table: 'db_municípios', column: 'db_estados.uf' },
    { table: 'db_municípios', column: 'Cidade' },
    { table: 'Boletim_de_Urna_2018-2022', column: 'DS_CARGO_PERGUNTA' },
  ],
}

export const values = {
  // Voter Diagnosis
  voter_pofile: [
    {
      year: [2020],
      position: ['Prefeito'],
      round: ['1º Turno', '2º Turno'],
      state: [] as string[],
      city: [] as string[],
      neighborhood: [] as string[],
      partido: [],
      candidato: [],
    },
  ],
  x_ray_votes: [
    {
      year: [2012, 2014, 2016, 2018, 2020, 2022],
      position: [
        'Presidente',
        'Senador',
        'Governador',
        'Deputado Federal',
        'Deputado Estadual',
        'Prefeito',
        'Vereador',
      ],
      round: ['1º Turno', '2º Turno'],
      state: [] as string[],
      city: [] as string[],
      partido: [],
      candidato: [],
    },
  ],

  // Election Diagnosis
  election_results: [
    {
      year: [2020],
      position: ['Prefeito', 'Vereador'],
      round: ['1º Turno', '2º Turno'],
      state: [] as string[],
      city: [] as string[],
      neighborhood: [] as string[],
      partido: [],
      candidato: [],
    },
  ],
  election_history: [
    {
      year: [2014, 2016, 2018, 2020, 2022],
      position: [
        'Presidente',
        'Senador',
        'Governador',
        'Deputado Federal',
        'Deputado Estadual',
        'Prefeito',
        'Vereador',
      ],
      round: ['1º Turno', '2º Turno'],
      state: [] as string[],
      city: [] as string[],
      neighborhood: [] as string[],
      partido: [],
      candidato: [],
    },
  ],
  x_ray_campaigns: [
    {
      year: [2012, 2014, 2016, 2020, 2022],
      position: [] as string[],
      round: ['1º Turno', '2º Turno'],
      state: [] as string[],
      city: [] as string[],
      neighborhood: [] as string[],
      partido: [],
      candidato: [],
    },
  ],
  campaing_panel: [
    {
      year: [2012, 2016, 2020],
      position: ['Prefeito', 'Vereador'],
      round: ['1º Turno', '2º Turno'],
      state: [] as string[],
      city: [] as string[],
      partido: [],
      candidato: [],
    },
  ],
}
