import { motion } from "framer-motion";
import styled from "styled-components";

export const FloatWrapper = styled.main`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const WrapperFilter = styled.div`
  position: relative;
`;

export const TimerContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 4px;
`;

export const Coin = styled.div<{ drawerOpen?: boolean; pixelsDrawer: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff0000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 0 4px auto;

  transform: ${(props) =>
    props.drawerOpen
      ? `translateX(-${props.pixelsDrawer})`
      : `translateX(${props.pixelsDrawer})`};
`;

export const Timer = styled.div<{
  drawerOpen?: boolean;
  pixelsDrawer: string;
}>`
  transform: ${(props) =>
    props.drawerOpen
      ? `translateX(-${props.pixelsDrawer})`
      : `translateX(${props.pixelsDrawer})`};
`;

export const CoinContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 4px;
`;

export const MenuContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 50%;
  transform: translateX(70px);
  // transform: translateY(-50%);
  top: -10%;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  // padding: 16px;
`;

export const MenuItem = styled(motion.div)`
  // margin-bottom: 8px;
`;

export const FilterButtonCopilot = styled.button<{
  drawerOpen: boolean;
  disabled?: boolean;
  pixelsDrawer: string;
  isForward?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e9e9f0;
  border-color: transparent;
  height: 67px;
  width: 67px;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  align-self: center;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.75);
  transition: transform 0.3s ease;

  .z-index {
    z-index: 1000;
  }

  transform: ${(props) =>
    props.drawerOpen
      ? `translateX(-${props.pixelsDrawer})`
      : `translateX(${props.pixelsDrawer})`};

  transform: ${(props) => (props.isForward ? "translateX(-45px)" : "")};

  svg {
    width: 28px;
    margin-right: 0px;
  }

  &:disabled {
    cursor: not-allowed;
    &:hover {
      background-color: #cccccc;
    }
  }
`;

export const FilterButton = styled.button<{
  drawerOpen?: boolean;
  pixelsDrawer: string;
}>`
  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: column;
  background-color: #ffc000;
  border-color: transparent;
  height: 67px;
  width: 61px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  margin-bottom: 0.5rem;
  align-self: flex-end;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.75);
  transition: transform 0.3s ease;

  .z-index {
    z-index: 1000;
  }

  transform: ${(props) =>
    props.drawerOpen
      ? `translateX(-${props.pixelsDrawer})`
      : `translateX(${props.pixelsDrawer})`};

  svg {
    margin-right: 6px;
  }
`;

export const FilterTitle = styled.span`
  color: #161717;
  text-align: end;
  margin-right: 6px;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 0.75rem;
  margin-left: 6px;
  margin-top: 1px;
`;

export const FilterTitleCopilot = styled.span<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? "gray" : "#161717")};
  text-align: center;
  margin-right: 6px;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 0.75rem;
  margin-left: 6px;
  margin-top: 1px;
`;

export const DrawerMain = styled.div<{ menuHidden?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 84px;
  background-color: white;
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

export const DrawerContainer = styled.div`
  width: 110%;
  height: 100%;
  position: relative;
  z-index: 1006;
`;

export const SidebarContainer = styled.div`
  background: #161717;
  flex: 1;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 72px;
`;

export const Sidebar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SidebarButton = styled.button<{
  active?: boolean;
  disabled?: boolean;
  menuHidden?: boolean;
}>`
  box-sizing: border-box;
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.active ? "#0A0A0A" : "#161717")};
  opacity: ${(props) => (props.active ? "1" : "0.7")};
  border: none;
  border-left: ${(props) => (props.active ? "4px solid #ffc000" : "none")};

  span {
    font-weight: ${(props) => (props.active ? "700" : "400")};
  }

  &:hover {
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
    //filter: grayscale(1);
    opacity: 0.3;
  }
`;

export const SidebarButtonCopilot = styled.button<{ active?: boolean }>`
  box-sizing: border-box;
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.active ? "#0A0A0A" : "#161717")};
  border: none;
  border-left: ${(props) => (props.active ? "4px solid #ffc000" : "none")};

  span {
    font-weight: ${(props) => (props.active ? "700" : "400")};
  }

  &:hover {
    // opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
    //filter: grayscale(1);
    opacity: 0.3;
  }
`;

export const ButtonTitle = styled.span`
  color: #e9e9f0;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  margin-top: 3px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ContentFilter = styled.div<{ isCopilot?: boolean }>`
  min-width: ${(props) => (props.isCopilot ? "378px" : "286px")};
  background: #0a0a0a;
  position: absolute;
  transform: translateX(-98%);
  padding: 8px;
  z-index: 1005;
  top: 0;
  bottom: 0;
`;

export const HeaderMenuAdvisor = styled.div`
  background-color: #0a0a0a;
  color: #ffc000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0px;
  font-size: 12px;
  gap: 4px;
`;

export const ContentAdvisor = styled.div`
  min-width: 460px;
  background: #0a0a0a;
  position: absolute;
  transform: translateX(-98%);
  padding: 8px;
  z-index: 1005;
  top: 0;
  bottom: 0;
`;

export const BoxContent = styled(motion.div)`
  width: 99%;
  height: 100vh;
  overflow: scroll;
  padding: 4px;
`;

export const BoxInternalContent = styled(motion.div)<{
  isHistoric: boolean;
  isFooter: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  overflow-y: auto;
  // overflow: scroll;
  padding: 4px;
  border: 1px solid;
  margin-top: 8px;
  gap: 16px;
  height: ${(props) => (props.isHistoric ? "86vh" : "80vh")};

  transition: transform 0.3s ease-out;

  position: ${(props) => (props.isFooter ? "fixed" : "relative")};
  bottom: ${(props) => (props.isFooter ? "0" : "auto")};
  left: ${(props) => (props.isFooter ? "0" : "auto")};
  width: ${(props) => (props.isFooter ? "100%" : "auto")};
  z-index: ${(props) => (props.isFooter ? "1000" : "1")};
`;

export const HeaderInternalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderButtonsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const BoxChat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  padding: 10px;
`;

export const BoxButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const BoxInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const TitleInternalContent = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
`;

export const Heading = styled.h3`
  font-family: "Roboto";
  color: #ffc000;
  text-align: start;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  font-weight: 700;
  flex-direction: column;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
  margin: 16px;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const FilterButtonCopilotStyled = styled(FilterButtonCopilot)`
  &:disabled {
    cursor: not-allowed;
    color: #cccccc;
    &:hover {
      background-color: #cccccc;
    }
  }
`;
