import styled from 'styled-components'

export const ContainerCard = styled.div<any>`
  background: #161717;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  height: 100%;
  text-align: left;
  .title {
    display: flex;
    margin-bottom: 16px;

    img {
      margin-right: 8px;
    }
  }
  .wrapper-card {
    width: 100%;
    height: 100%;
  }
`
