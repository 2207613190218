import React, { useEffect, useState } from 'react'
import { IoAddCircle } from 'react-icons/io5'
import { AnimatePresence } from 'framer-motion'
import IconeEscutaPolitica from 'icons/icon-political-listening.svg'
import { createAlly, updateListener } from 'componentsNew/ElectionStrategyComponents/services/allyServices'
import { AiOutlineDelete } from 'react-icons/ai'

import { noop } from 'lodash'
import { CandidateType } from 'componentsNew/ElectionStrategyComponents/types/candidateTypes'
import { Tooltip } from 'antd'
import theme from 'styles/theme'
import { ProfileSelect } from '../ProfileSelect'
import * as S from './styles'

interface ProfileCandTypes extends CandidateType {
  avatar?: string
  category?: string
}

interface defaultLabelValueProps {
  label: string
  value: number | string
}

interface CardSelectionAllyProps {
  defaultValue?: defaultLabelValueProps
  profile_data?: ProfileCandTypes
  profile_list?: any[]
  handleAdd?: (data: any) => void
  topic: string
  onRemove?: (id_cand: string, theme_id: number) => void
}

const variants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
}

export const CardSelectionAlly = ({
  defaultValue,
  profile_data,
  profile_list = [],
  handleAdd,
  topic,
  onRemove = noop,
}: CardSelectionAllyProps) => {
  const [items, setItems] = useState<any>([...profile_list])
  const disabledAction = !items[items.length - 1]?.id && items.length >= 1

  useEffect(() => {
    setItems(profile_list)
  }, [profile_list])

  const addItem = () => {
    if (items.length < 6) {
      setItems([...items, items.length + 1])
    }
  }
  const addTheme = async (data: any) => {
    try {
      const candidateResult = await createAlly({ ...data, topic, cand_name: data?.nm_candidato })
      const newItems = items.map((item: any) => {
        if (typeof item === 'number') {
          return candidateResult
        }
        return item
      })

      setItems(newItems)
    } catch (error) {
      console.error('Erro ao adicionar tema:', error)
    }
  }

  const updateListnerById = (checked: boolean, data?: any) => {
    updateListener({ theme_id: data?.id, ...data, is_listening: checked })
  }

  return (
    <S.Container haveItem={items?.length === 0}>
      <AnimatePresence>
        {items?.length > 0 &&
          items.map((item: any) => (
            <S.Item key={item?.id} initial="hidden" animate="visible" exit="hidden" variants={variants}>
              <ProfileSelect
                profile_data={typeof item !== 'number' && item}
                width="100%"
                handleAdd={data => addTheme(data)}
                onChangeListener={(check, id) => updateListnerById(check, item)}
              />

              <AiOutlineDelete
                color={theme.COLORS.gray10}
                size={18}
                onClick={() => onRemove(item?.id_cand, item?.id)}
              />
            </S.Item>
          ))}
      </AnimatePresence>
      {items.length < 6 && (
        <Tooltip title={disabledAction && 'Finalize a edição do candidato anterior.'}>
          <S.AddButton
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            onClick={addItem}
            disabled={disabledAction}
          >
            <IoAddCircle size={24} />
          </S.AddButton>
        </Tooltip>
      )}

      {items.length >= 1 && (
        <S.IconBox className="leftIcon">
          <S.Icon src={IconeEscutaPolitica} alt="icon listening political" />
        </S.IconBox>
      )}
      {items.length >= 4 && (
        <S.IconBox className="rightIcon">
          <S.Icon src={IconeEscutaPolitica} alt="icon listening political" />
        </S.IconBox>
      )}
    </S.Container>
  )
}
