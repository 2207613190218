import React, { createContext, useState, useContext, ReactNode } from 'react'

interface ActivitiesData {
  id: number
  rithm: number
  weekly_goal: number
  projection: number
  initial_value: number
  goal: number
  minimum_goal: number
  first_partial_goal: number
  second_partial_goal: number
  current_value: number
  initial_date: string
  final_date: string
  created: string
  updated: string
  person: number
}

interface TrajectoryActivitiesContextProps {
  activitiesData: ActivitiesData | null
  setActivitiesData: (data: ActivitiesData | null) => void
}

const TrajectoryActivitiesContext = createContext<TrajectoryActivitiesContextProps | undefined>(undefined)

export const TrajectoryActivitiesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [activitiesData, setActivitiesData] = useState<ActivitiesData | null>(null)

  return (
    <TrajectoryActivitiesContext.Provider value={{ activitiesData, setActivitiesData }}>
      {children}
    </TrajectoryActivitiesContext.Provider>
  )
}

export const useTrajectoryActivities = (): TrajectoryActivitiesContextProps => {
  const context = useContext(TrajectoryActivitiesContext)
  if (!context) {
    throw new Error('useTrajectoryActivities must be used within a TrajectoryActivitiesProvider')
  }
  return context
}
