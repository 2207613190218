import styled from 'styled-components'

export const Wrapper = styled.main`
  padding-bottom: 26px;
`

export const NewsBox = styled.div`
  border-top: 1px solid #494949;
  border-bottom: 1px solid #494949;
  margin-bottom: 16px;
`

export const Section = styled.section`
  width: 100%;
  display: flex;
  gap: 16px;
  margin: 16px 0;

  @media screen and (max-width: 1099px) {
    gap: 23px;
    flex-direction: column;
  }
`

export const Box = styled.div`
  background: #161717;
  padding: 16px;
  border-radius: 8px;
  flex: 1;
`

export const TitleList = styled.h3`
  color: #e9e9f0;
  font-size: 16px;
  font-weight: 400;

  svg {
    margin-top: 4px;
  }
`

export const Heading = styled.h3`
  color: #e9e9f0;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;

  img {
    margin-right: 4px;
  }
`

export const WizardIcon = styled.img``

export const ContentWizard = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  column-gap: 24px;
`

export const WrapperContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`

export const BoxEqual = styled.div`
  background: #161717;
  width: 84px;
  height: 197px;
  flex: unset;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const WrapperProfileCard = styled.div`
  flex: 1;
  border-radius: 8px;
  padding: 8px;
  gap: 16px;
  background: #161717;

  display: flex;
  flex-direction: column;
`

export const GridListSelection = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
  flex-direction: column;

  @media screen and (max-width: 1099px) {
    gap: 23px;
    flex-direction: column;
  }
`

export const ContentList = styled.div`
  display: flex;
  gap: 16px;
  flex: 1;

  @media screen and (max-width: 1099px) {
    flex: 1;
  }
`

export const BoxListGrid = styled(Box)`
  flex: unset;
  padding: 8px;
  width: 100%;
  background: #111111;
`
