import { toast } from 'react-toastify'
import { api } from 'services/apiService'
import {
  /*   MOCK_Competitive_THEME_LIST_RESPONSE,
   */ MOCK_CREATED_Competitive_THEME_RESPONSE,
  MOCK_CREATE_Competitive_THEME_PAYLOAD,
  MOCK_ACCEPT_WIZARD_PARAMS,
  TOPIC_NAMES,
} from '../MOCK/competitiveMock'

import {
  CompetitiveThemeListResponse,
  AcceptWizardParams,
  SelectedCompetitiveThemeListResponse,
  CreateCompetitiveThemePayload,
  CreatedCompetitiveThemeResponse,
  GetCompetitiveWizardResponse,
  CandidateCreatePayload,
} from '../types/COMPETITIVE_TYPES'
import { PayloadPostTheme } from '../types/SWOT_DIAGNOSIS_TYPES'
import simulateRequest from "../../../utils/simulateRequest";

interface ErrorMessage {
  message: string
}

type ResponseGetWizards = GetCompetitiveWizardResponse | ErrorMessage
type ResponseThemeList = CompetitiveThemeListResponse | ErrorMessage
type ResponsePersonThemes = SelectedCompetitiveThemeListResponse | ErrorMessage
type ResponseAcceptWizard = any
type ResponseCreateCompetitiveTheme = CreatedCompetitiveThemeResponse | ErrorMessage

export function translateIdentificationToName(identification: string): string {
  const topicMapping: TopicMapping = {
    strengths: 'Forças (S)',
    weaknesses: 'Fraqueza (W)',
    opportunities: 'Oportunidades (O)',
    threats: 'Ameaças (T)',
  }
  return topicMapping[identification.toLowerCase()] || identification
}

// Serviço para obter os wizards
export const getWizards = async (): Promise<ResponseGetWizards> => {
  try {
    /*  const { data } = await simulateRequest(MOCK_Competitive_WIZARDS) */
    const { data } = await api.get(`/electoral-intelligence/competitive-diagnosis-wizards`)

    if (data) {
      return data
    }

    return { message: 'Falha no teste.' }
  } catch (error) {
    return { message: 'Não foi possível buscar dados cadastrados.' }
  }
}

// Serviço para obter a lista de temas
export const getThemes = async (): Promise<ResponseThemeList> => {
  try {
    /*     const { data } = await simulateRequest(MOCK_Competitive_THEME_LIST_RESPONSE)
     */
    const { data } = await api.get(`/electoral-intelligence/competitive-themes/`)

    if (data) {
      const formatterData = {
        ...data,
        results: data.results?.map((item: any) => ({
          ...item,
          topic_name: translateIdentificationToName(item.topic_name),
        })),
      }
      return formatterData
    }

    /* if (data) {
      return data
    } */

    return { message: 'Não foi possível buscar dados cadastrados.' }
  } catch (error) {
    return { message: 'Não foi possível buscar dados cadastrados.' }
  }
}

// Serviço para obter os temas de uma pessoa
export const getPersonThemes = async (): Promise<ResponsePersonThemes> => {
  try {
    /*  const { data } = await simulateRequest(MOCK_SWOT_SELECTION_RESPONSE) */
    const { data } = await api.get(`/electoral-intelligence/person-competitive-diagnosis-themes/`)

    const formatterData = {
      ...data,
      results: data.results?.map((item: any) => ({
        ...item,
        topic_name: translateIdentificationToName(item.topic_name),
      })),
    }
    return formatterData
  } catch (error) {
    return { message: 'Não foi possível buscar dados cadastrados.' }
  }
}

// Serviço para aceitar um wizard
export const acceptWizard = async ({ wizard_id, status }: AcceptWizardParams): Promise<ResponseAcceptWizard> => {
  try {
    const { data } = await simulateRequest(MOCK_ACCEPT_WIZARD_PARAMS)

    return data
  } catch (error) {
    return { message: 'Não foi possível buscar dados cadastrados.' }
  }
}

export const createCompetitiveTheme = async (
  payload: CreateCompetitiveThemePayload
): Promise<ResponseCreateCompetitiveTheme> => {
  try {
    const { data } = await simulateRequest(MOCK_CREATE_Competitive_THEME_PAYLOAD)
    return MOCK_CREATED_Competitive_THEME_RESPONSE
  } catch (error) {
    return { message: 'Não foi possível criar o tema Competitive.' }
  }
}

export async function createCandidate(params: CandidateCreatePayload) {
  try {
    const url = `/electoral-intelligence/adversary/`
    const { data } = await api.post(url, {
      ...params,
    })

    return data
  } catch (e) {
    console.error('Erro na requisição:', e)
    return { message: 'Não foi possível buscar menções.' }
  }
}

type TopicMapping = {
  [key: string]: string
}

type valueMapping = {
  [key: string]: boolean
}

const topicValueMapping: valueMapping = {
  strengths: true,
  weaknesses: false,
  opportunities: true,
  threats: false,
}

export function translateValue(identification: string): boolean {
  return topicValueMapping[identification.toLowerCase()]
}

export async function getAllAdversary() {
  try {
    const url = `/electoral-intelligence/adversary/`
    const { data } = await api.get(url)

    const formattedData = data.results?.map((profile: any) => ({
      ...profile,
      value: profile?.candidate_id,
      party: profile?.candidate_party,
      label: profile?.candidate_name,
      topicList: TOPIC_NAMES.map(item => ({
        name: item,
        theme_list: Array.from({ length: 8 }),
      })),
    }))

    return formattedData
  } catch (e) {
    console.error('Erro na requisição:', e)
    return { message: 'Não foi possível buscar menções.' }
  }
}

export const deleteAdversary = async (id: number | string) => {
  try {
    const url = `/electoral-intelligence/adversary/${id}/`
    const { data } = await api.delete(url)

    toast.success('Candidato removido com sucesso.')
  } catch (e) {
    console.error('Erro na requisição:', e)
    toast.error('Falha ao remover o candidato, tente novamente.')
  }
}

export const updateListener = async ({
  person,
  is_listening,
  adversary_id,
}: {
  person: number
  is_listening: boolean
  adversary_id: string
}): Promise<any> => {
  try {
    const { data } = await api.put(`/electoral-intelligence/adversary/${adversary_id}/`, {
      is_listening,
      person,
    })

    return data
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}

export const sendTheme = async ({ theme_data }: { theme_data: PayloadPostTheme }): Promise<any> => {
  try {
    const { data } = await api.post(`/electoral-intelligence/person-competitive-diagnosis-themes/`, {
      ...theme_data,
    })

    return data
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}

export const deleteTheme = async (id: number | string) => {
  try {
    const url = `/electoral-intelligence/person-competitive-diagnosis-themes/${id}/`
    const { data } = await api.delete(url)

    toast.success('Tema removido com sucesso.')
  } catch (e) {
    console.error('Erro na requisição:', e)
    toast.error('Falha ao remover o tema, tente novamente.')
  }
}
