import React from 'react'
import { Container } from './styles'
import FilterDate from './FilterDate'
import FilterRaioX from './FilterRaioX'

const PoliticalVox = () => {
  return (
    <Container>
      <FilterDate />
      <FilterRaioX />
    </Container>
  )
}

export default PoliticalVox
