import React from 'react'
// import HubSpotAcquisitions from 'pages/HubSpotAcquisitions'
import TabsCustom from 'componentsNew/Tabs'
import { AcquisitionTable } from 'componentsNew/AcquisitionComponents/AcquisitionTable'
import { UnderConstruction } from 'componentsNew/UnderConstruction'
import { Container } from './styles'

const labels = [
  {
    label: 'Assinaturas',
    key: 'tab_subscriptions',
  },
  {
    label: 'Histórico de Compras',
    key: 'tab_purchases_historic',
  },
  /*   {
    label: 'HubSpot',
    key: 'tab_hubspot',
  }, */
]

const content: React.ReactNode[] = [<AcquisitionTable />, <UnderConstruction />]

export const Acquisitions: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState('tab_subscriptions')
  const changePoliticalTab = (item: string) => {
    setActiveTab(item)
  }

  return (
    <>
      <Container>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={content}
          onChange={key => changePoliticalTab(key)}
        />
      </Container>
    </>
  )
}
