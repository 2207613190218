import { BaseCard } from 'componentsNew/PoliticalListeningComponents/BaseCard'
import styled from 'styled-components'

export const Wrapper = styled.main``

export const NewsBox = styled.div`
  border-top: 1px solid #494949;
  border-bottom: 1px solid #494949;
  margin-bottom: 16px;
`

export const Section = styled.section<{ disabled_card?: boolean }>`
  width: 100%;
  display: flex;
  gap: 16px;
  margin: 16px 0;
  min-height: 300px;
  position: relative;

  @media screen and (max-width: 1099px) {
    gap: 23px;
    flex-direction: column;
  }
`

export const ContentSection = styled.div<{ height?: string }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: ${({ height }) => height && height};
  @media screen and (max-width: 1099px) {
    gap: 23px;
    flex-direction: column;
  }
`

export const Heading = styled.h3`
  color: #e9e9f0;
  font-size: 16px;
  font-weight: 500;
`

export const SectionMessage = styled.div`
  overflow-y: auto;
  padding: 12px;
  height: 92%;
`

export const Box = styled(BaseCard)<{ height?: string }>`
  flex: 1;
  max-height: ${({ height }) => height && height};

  @media screen and (max-width: 1099px) {
    width: 100%;
  }
`

export const HorizontalSection = styled.div`
  width: 100%;
  display: flex;
  min-height: 85%;

  justify-content: space-between;
  @media screen and (max-width: 1099px) {
    gap: 23px;
    flex-direction: column;
  }
`

export const BoxFull = styled.div`
  flex: 1;
`

export const LoadingBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
