import { useState, useEffect } from "react";

const usePlanType = () => {
  const [planType, setPlanType] = useState<string>("");

  useEffect(() => {
    const planObject = localStorage.getItem("planFeatures");
    const planFeatures = planObject ? JSON.parse(planObject) : null;
    setPlanType(planFeatures?.name || "");
  }, []);

  return planType;
};

export default usePlanType;
