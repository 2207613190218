import styled, {css} from 'styled-components';
import {Button, Input, Checkbox, Select} from 'antd';
import { InputProps } from 'antd/es/input';

const baseColor = 'rgba(35, 35, 35, 0.97)';
const hoverColor = 'rgba(46, 46, 46, 0.97)';
const highlightColor = 'rgb(255, 192, 0)';
const whiteColor = 'white';
const blackColor = 'black';

const inputStyle = css`
  caret-color: ${whiteColor} !important;
  color: ${whiteColor} !important;
  background-color: ${baseColor} !important;
  border: 1px solid ${whiteColor} !important;

  &:hover, &:focus {
    background-color: ${hoverColor} !important;
    border-color: ${highlightColor} !important;
  }

  &::placeholder {
    color: ${whiteColor} !important;
  }
`;

interface StyledInputProps extends InputProps {
  hasError?: boolean;
}

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${baseColor};
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem;
  width: 75%;
  overflow: auto;
`;

export const StyledInput = styled(Input)<StyledInputProps>`
  ${inputStyle}
`;

  export const StyledInputPassword = styled(Input.Password)<StyledInputProps>`
  ${inputStyle}
  span, input::placeholder {
    color: white !important;

  }
`;

export const StyledCheckbox = styled(Checkbox)`
  span {
    color: ${whiteColor};

    &.ant-checkbox-inner {
      background-color: ${highlightColor} !important;
      border-color: ${highlightColor} !important;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: ${blackColor};
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;

  &.cancelButton {
    border-color: ${highlightColor};
    background-color: ${blackColor};
    color: ${highlightColor};
    margin-right: 1rem;

    @media (max-width: 1024px) {
      margin-bottom: 1rem;
    }

  &.saveButton {
    background-color: ${highlightColor};
    color: ${blackColor};
  }
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    ${inputStyle}
  }
  .ant-select-arrow {
    color: ${whiteColor} !important;
  }

  .ant-select-selection-item, .ant-select-selection-placeholder {
    color: ${whiteColor} !important;
    display: flex !important;
    justify-content: flex-start !important;
  }
`;
