const generateRandomValues = (): number => {
  return Math.floor(Math.random() * 0)
}

export const defaultOrderListening: string[] = [
  'fb_count',
  'ig_count',
  'tt_count',
  'gg_count',
  'yt_count',
  'rd_count',
  'tumblr_count',
  /*   'infra_count',
    'iot_count',
    'network_count',
    'devices_count', */
]

export const MENTIONS_PARTY_PT = [
  {
    topic_count: 0,
    topic: 'PAC',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'BOLSA FAMÍLIA',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'PROUNI',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'Tarifa Zero',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'Renda Básica de Cidadania',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'bancos comunitários',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'empreendedorismo popular',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'educação inclusiva',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'Minha Casa Minha Vida',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'Mais Médicos',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
]

export const MENTIONS_PARTY_RP = [
  {
    topic_count: 0,
    topic: 'Pode entrar',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'Creche 100%',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'Rodoanel Norte',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'trem intercidades',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'Privatização EMAE',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'Privatização SABESP',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'PEC da Educação',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'Intercâmbio alunos escolas estaduais',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
  {
    topic_count: 0,
    topic: 'Ampliação escolas cívico-militares',
    fb_count: generateRandomValues(),
    ig_count: generateRandomValues(),
    tt_count: generateRandomValues(),
    yt_count: generateRandomValues(),
    in_count: generateRandomValues(),
    journal_count: generateRandomValues(),
    rd_count: generateRandomValues(),
    tumblr_count: generateRandomValues(),
    gg_count: generateRandomValues(),
  },
]
