import React from "react";
import { Card } from "antd";
import Spin from "componentsNew/Spin";

interface ILoadingSpin {
  width?: string;
  height?: string;
}

const LoadingSpin = ({ width = "100%", height = "300px" }: ILoadingSpin) => {
  return (
    <Card
      style={{
        display: "flex",
        backgroundColor: "#0A0A0A",
        border: "none",
        width: width,
        height: height,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin />
    </Card>
  );
};

export default LoadingSpin;
