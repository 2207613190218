import { useEffect, useState } from 'react'
import { useCompetitive } from 'componentsNew/ElectionStrategyComponents/hooks/useCompetitive'
import { toast } from 'react-toastify'
import { CANDIDATEMOCK, DefaultCandidate } from 'componentsNew/ElectionStrategyComponents/MOCK/competitiveMock'
import wizard_icon from 'icons/wizard_icon.svg'

import { IoMdAdd, IoMdRemove } from 'react-icons/io'
import {
  deleteAdversary,
  getAllAdversary,
  getPersonThemes,
  getThemes,
  getWizards,
} from 'componentsNew/ElectionStrategyComponents/services/competitiveServices'
import { CardCompetitiveCandidate } from 'componentsNew/ElectionStrategyComponents/components/CardCompetitiveCandidate'
import { SelectTypes } from 'componentsNew/ElectionStrategyComponents/types/COMPETITIVE_TYPES'
import { CandidateType } from 'componentsNew/ElectionStrategyComponents/types/candidateTypes'
import { translateIdentificationToName } from 'componentsNew/ElectionStrategyComponents/services/swotServices'
import { useCandidates } from 'hooks/useCandidates'
import { CardWizard } from '../../components/CardWizard'
import * as S from './styles'

export const Competitive = () => {
  const { updateVisibleWizard, visibleWizard } = useCompetitive()
  const [adversaryList, setAdversaryList] = useState<CandidateType[]>(CANDIDATEMOCK)
  const { selectedCandidate } = useCandidates()
  const [defaultSelect, setDefaultSelect] = useState<SelectTypes[]>([])

  const defaultCandidate = {
    ...selectedCandidate,
    topicList: DefaultCandidate,
    cand_name: selectedCandidate?.name,
    label: selectedCandidate?.name,
    value: selectedCandidate?.id,
  }

  useEffect(() => {
    fetchAdversaryList()
    fetchThemes()
    fetchWizardData()
    fetchPersonEnv()
  }, [])

  const fetchAdversaryList = async () => {
    const data = await getAllAdversary()
    if (!('message' in data)) {
      setAdversaryList(data)
    }
  }

  const fetchWizardData = async () => {
    try {
      const wizardList = await getWizards()
      if ('message' in wizardList) {
        toast.error(wizardList.message)
      } else {
        const wizardsWithTranslatedTopic = wizardList.results.map(wizard => ({
          ...wizard,
          topic_name: translateIdentificationToName(wizard.topic),
        }))

        updateVisibleWizard(wizardsWithTranslatedTopic)
      }
    } catch (error) {
      console.error('Error fetching wizard data:', error)
      toast.error('Não foi possível buscar os dados dos Wizards.')
    }
  }

  const fetchThemes = async () => {
    const themes = await getThemes()
    if (!('message' in themes)) {
      const newThemeList = (themes?.results || []).map((option: any) => ({
        ...option,
        value: option.id,
        label: option.theme_name || option.name || option?.topic_name,
      }))
      setDefaultSelect(newThemeList)
    }
  }

  const fetchPersonEnv = async () => {
    try {
      const themes = await getPersonThemes()
      if ('message' in themes) {
        console.log(themes.message)
      } else {
        const theme_default = themes.results.map((option: any) => ({
          ...option,
          value: option.id,
          label: option.theme_name || option.name || option?.topic_name,
        }))
        const topicListFiltered = adversaryList.map(card => {
          const topicItem = card.topicList
          const themeList = theme_default.filter(
            (defaultTheme: any) => defaultTheme.topic_name === topicItem.topic_name
          )

          return {
            ...topicItem,
            theme_list: [...themeList, ...topicItem.theme_list],
          }
        })

        setAdversaryList(topicListFiltered)
      }
    } catch (error) {
      console.log('Error fetching themes')
    }
  }
  const handleCardClick = (data: any) => {
    if (visibleWizard && visibleWizard.length > 0) {
      const clickedWizard = visibleWizard.find(wizard => wizard.id === data.id)

      if (clickedWizard) {
        const clickedWIzardData = {
          ...clickedWizard,
          id: clickedWizard.id,
          name: clickedWizard.theme_name,
          is_recommended: true,
          value: clickedWizard.id,
          label: clickedWizard.cand_name || clickedWizard.label,
          nm_candidato: clickedWizard.cand_name,
          party: clickedWizard.party,
        }

        setAdversaryList(prevTopics => {
          const newTopics = [...prevTopics]
          const topicIndex = newTopics.findIndex(topic => topic.id_cand === clickedWIzardData.id_cand)

          if (topicIndex !== -1) {
            const indexTopic = newTopics[topicIndex].topicList?.findIndex(
              (item: any) => item?.name === clickedWIzardData.topic_name
            )
            if (indexTopic !== -1) {
              const topicItem = newTopics[topicIndex].topicList?.[indexTopic] as any
              const index = topicItem?.theme_list.findIndex((item: any) => item === undefined)
              if (index !== -1) {
                topicItem.theme_list[index] = clickedWIzardData
              }
            }
          }

          return newTopics
        })
      }

      const updatedWizards = visibleWizard.filter(wizard => wizard.id !== data.id)

      updateVisibleWizard(updatedWizards.length > 0 ? updatedWizards : null)
    }
  }

  const handleRemoveCard = (wizardId: number) => {
    const updatedWizards = (visibleWizard || []).filter(wizard => wizard.id !== wizardId)

    updateVisibleWizard(updatedWizards.length > 0 ? updatedWizards : null)
  }

  const handleRemoveCandidate = (profile?: any) => {
    const newItems = adversaryList.filter(item => item.candidate_id !== profile.candidate_id)
    setAdversaryList(newItems)
    deleteAdversary(profile?.id)
  }

  return (
    <S.Wrapper>
      <S.Section>
        <S.Box>
          <S.Heading>
            <S.WizardIcon src={wizard_icon} alt="wizard icon" />
            Wizard Eleitoral
          </S.Heading>

          <S.ContentWizard>
            {visibleWizard &&
              visibleWizard.slice(0, 1).map(wizard => (
                <CardWizard
                  key={wizard.id}
                  data={{
                    ...wizard,
                    name: `${wizard.topic_name} - ${wizard.theme_name}`,
                    profile_name: `${wizard?.cand_name} | ${wizard?.party}`,
                  }}
                  avatar={wizard?.file || wizard?.avatar}
                  onCardSelect={data => handleCardClick(data)}
                  onRemove={() => handleRemoveCard(wizard.id)}
                />
              ))}
          </S.ContentWizard>
        </S.Box>
      </S.Section>

      <S.Section>
        <S.Box>
          <S.TitleList>
            POSITIVOS (<IoMdAdd color="#00E23F" size={16} /> )
          </S.TitleList>
        </S.Box>
        <S.Box>
          <S.TitleList>
            NEGATIVOS (<IoMdRemove color="#D70708" />)
          </S.TitleList>
        </S.Box>
      </S.Section>

      <S.GridListSelection>
        <CardCompetitiveCandidate
          profile={{ ...defaultCandidate, category: 'Candidato' }}
          default_list={defaultCandidate.topicList}
          themeData={defaultSelect}
        />
        {defaultSelect?.length > 0 &&
          adversaryList.map((item, index) => {
            return (
              <CardCompetitiveCandidate
                key={item.candidate_id}
                disabled={item.disabled}
                profile={item && { ...item, category: `Adversário ${index + 1}` }}
                default_list={item.topicList}
                withRemove
                onRemove={() => handleRemoveCandidate(item)}
                themeData={defaultSelect}
                handleProfile={data => {
                  setAdversaryList(prev => {
                    const updatedList = [...prev]
                    updatedList[index] = data
                    return updatedList
                  })
                }}
              />
            )
          })}

        <CardCompetitiveCandidate
          disabled
          handleProfile={data => {
            setAdversaryList(prev => [...prev, data])
          }}
        />
      </S.GridListSelection>
    </S.Wrapper>
  )
}
