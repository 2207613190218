import { styled } from 'styled-components'
import ReactInputMask from 'react-input-mask'

export const LabelLine = styled.div<{ backgroundColor?: string }>`
  position: absolute;
  font-size: 0.75rem;
  width: 90%;
  color: #a0a0a0;
  padding: 0 15px;
  margin: 0px 20px;
  line-height: 50px;
  border-radius: 35px;
  transition: 0.2s ease;
  background: ${({ backgroundColor }) => backgroundColor || '#121212'};
`

export const Input = styled.input`
  position: absolute;
  width: 100%;
  outline: none;
  font-size: 16px;
  line-height: 30px;
  height: 45px;
  padding: 0px 21px;
  /* padding-top: 13px; */
  margin: 0;
  border: none;
  outline: none;
  border: 2px solid #a0a0a0;
  background: transparent;
  transition: 0.1s ease;
  z-index: 20;
  border-radius: 4px;

  &::placeholder {
    display: none;
    opacity: 0;
    color: transparent;
  }

  &:focus,
  &:not(:placeholder-shown) {
    color: #ffffff;
    border-color: #ffffff;
    border: 2px solid #ffffff;
  }

  &:focus + ${LabelLine}, &:not(:placeholder-shown) + ${LabelLine} {
    color: #ffffff;
    height: 30px;
    line-height: 30px;
    transform: translate(-15px, -13px) scale(0.88);
    z-index: 21;
  }

  &:-webkit-autofill {
    appearance: none;
    background-image: unset !important;
    background-color: transparent !important;
    color: #ffffff !important;
    /*     caret-color: #ffffff !important; */
  }
`

export const InputMask = styled(ReactInputMask)`
  position: absolute;
  width: 100%;
  outline: none;
  font-size: 16px;
  line-height: 30px;
  height: 45px;
  padding: 0px 21px;
  margin: 0;
  border: none;
  outline: none;
  border: 2px solid #a0a0a0;
  background: transparent;
  transition: 0.1s ease;
  z-index: 20;
  border-radius: 4px;

  &::placeholder {
    display: none;
    opacity: 0;
    color: transparent;
  }

  &:focus,
  &:not(:placeholder-shown) {
    color: #ffffff;
    border-color: #ffffff;
    border: 2px solid #ffffff;
  }

  &:focus + ${LabelLine}, &:not(:placeholder-shown) + ${LabelLine} {
    color: #ffffff;
    height: 30px;
    line-height: 30px;
    transform: translate(-15px, -13px) scale(0.88);
    z-index: 21;
  }

  &:-webkit-autofill {
    appearance: none;
    background-image: unset !important;
    background-color: transparent !important;
    color: #ffffff !important;
    /*   caret-color: #ffffff !important; */
  }
`

export const EntryArea = styled.div<{ error?: boolean }>`
  position: relative;
  height: 55px;
  line-height: 55px;

  input {
    border-color: ${({ error }) => (error ? '#ff0000' : '#a0a0a0')};
  }

  input {
    &:focus + ${LabelLine}, &:not(:placeholder-shown) + ${LabelLine} {
      color: #ffffff;
      height: 23px;
      line-height: 33px;
      transform: translate(-15px, -16px) scale(0.88);
      z-index: 21;
    }
  }
`

export const IconPassword = styled.div`
  position: absolute;
  right: 17px;
  top: 14px;
  z-index: 23;

  svg {
    color: #a0a0a0;
    cursor: pointer;
  }
`

export const Wrapper = styled.main<{ width?: string }>`
  width: 100%;
  position: relative;
  input {
    width: 100%;
  }
`
