import React, { createContext, useContext, useEffect, useState } from "react";
import { IChildTypes } from "types/childrenTypes";
import { api, apiUser, defaultsHeadersAxios } from "services/apiService";
import { getItemStorage, setItemStorage } from "utils/persistenceUtils";

const CandidatesContext = createContext<any>(null);
const CANDIDATES_STORAGE_ID = "CANDIDATES_STORAGE_ID";
const CandidatesProvider = ({ children }: IChildTypes) => {
  const [candidates, setCandidates] = useState<any>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<any>(false);
  const [isLoadedCandidates, setIsLoadedCandidates] = useState(false);
  const [isLocalCandidates, setIsLocalCandidates] = useState({
    pais: "",
    estado: "",
    cidade: "",
  });
  const [userDataCandidateSelected, setUserDataCandidateSelected] =
    useState<any>(null);

  const handlerLocalCandidates = (local: string) => {
    const targetLocality = local || "BRA.SP.Sao Paulo";
    const partes = targetLocality.split(".");
    const objeto = {
      pais: partes[0],
      estado: partes[1],
      cidade: partes?.slice(2).join("."),
    };
    setIsLocalCandidates(objeto);
  };

  const getCandidatesById = async (id: string) => {
    try {
      setIsLoadedCandidates(true);
      const { data } = await apiUser.get(
        `/user/get-auth-token/?person_id=${id}`,
      );
      defaultsHeadersAxios(data.token);
      const { data: newData } = await api.get("/user/me");
      setUserDataCandidateSelected(newData.person);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoadedCandidates(false);
    }
  };

  useEffect(() => {
    if (candidates.length === 0) return;
    setSelectedCandidate(candidates[0]);

    const id = getItemStorage(CANDIDATES_STORAGE_ID);
    if (id) {
      const candidate = candidates.find((c: any) => c.id === +id);
      if (candidate) {
        setSelectedCandidate(candidate);
        getCandidatesById(candidate.id);
        handlerLocalCandidates(candidate.locality);
      }
    } else {
      getCandidatesById(candidates[0]?.id);
      handlerLocalCandidates(candidates[0]?.locality);
      setItemStorage(CANDIDATES_STORAGE_ID, candidates[0]?.id);
    }
  }, [candidates]);

  const handleSelectCandidate = (candidateOrId: any) => {
    const candidate =
      typeof candidateOrId === "object"
        ? candidateOrId
        : candidates.find((c: any) => c.id.toString() === candidateOrId);
    if (candidate) {
      setSelectedCandidate(candidate);
      getCandidatesById(candidate.id);
      setItemStorage(CANDIDATES_STORAGE_ID, candidate.id);
      handlerLocalCandidates(candidate.locality);
    } else {
      console.error("Candidato não encontrado");
    }
  };

  return (
    <CandidatesContext.Provider
      value={{
        candidates,
        setCandidates,
        selectedCandidate,
        setSelectedCandidate: handleSelectCandidate,
        isLoadedCandidates,
        isLocalCandidates,
        userDataCandidateSelected,
        getCandidatesById,
      }}
    >
      {children}
    </CandidatesContext.Provider>
  );
};

const useCandidates = () => {
  const context = useContext(CandidatesContext);

  return context;
};
export { CandidatesProvider, useCandidates };
