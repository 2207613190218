import { useState, useEffect, useCallback } from "react";
import { apiVox } from "services/apiService";
import { debounce } from "lodash";

interface ICandidateTrend {
  candidate: string;
  score: number;
  rank: number;
}

interface IProcessedGoogleTrendsDay {
  day: string;
  hour?: string;
  candidates: ICandidateTrend[];
}

export interface IProcessedGoogleTrendsResponse {
  aggregation: "past-hour" | "past-day" | "past-7-days" | "past-90-days";
  ranking: IProcessedGoogleTrendsDay[];
  totals: Record<string, number>;
}

const formatDateTime = (dateString: string, aggregation: string) => {
  const date = new Date(dateString);
  if (aggregation === "past-hour" || aggregation === "past-day") {
    const hour = date.toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });
    return { hour, day: "" };
  } else {
    const day = date.getUTCDate().toString().padStart(2, "0");
    const monthNames = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];
    const month = monthNames[date.getUTCMonth()];
    return { day: `${day}/${month}`, hour: "" };
  }
};

const useGoogleTrends = (
  aggregation: "past-hour" | "past-day" | "past-7-days" | "past-90-days",
  keywords: string,
) => {
  const [data, setData] = useState<IProcessedGoogleTrendsResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    if (!keywords) {
      setError("Nenhuma keyword fornecida.");
      return;
    }

    try {
      setLoading(true);
      const response = await apiVox.get(
        `/vox/v1/political-vox/google-trends?aggregation=${aggregation}&keywords=${keywords}`,
      );

      if (
        response.data &&
        response.data.data.ranking &&
        response.data.data.ranking.length > 0
      ) {
        const processedData = response.data.data.ranking.map((item: any) => {
          const { day, hour } = formatDateTime(item.date, aggregation);
          return {
            day,
            hour:
              aggregation === "past-hour" || aggregation === "past-day"
                ? hour
                : undefined,
            candidates: item.candidates,
          };
        });

        setData({
          aggregation: response.data.data.aggregation,
          ranking: processedData,
          totals: response.data.totals,
        });
        setError(null);
      } else {
        setError("Nenhum dado encontrado para as keywords fornecidas.");
      }
    } catch (err) {
      console.error("Falha ao buscar dados de Google Trends:", err);
      setError("Falha ao buscar dados de Google Trends");
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(debounce(fetchData, 2000), [
    aggregation,
    keywords,
  ]);

  useEffect(() => {
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  return { data, loading, error };
};

export default useGoogleTrends;
