import React from 'react'
import { SidBarProvider } from 'hooks/useSideBar'
import { MenuTitleProvider } from 'hooks/useMenuTitleContext'
import { FilterGlobalProvider } from 'hooks/useFilterGlobal'
import { FilterVoxProvider } from 'hooks/useFilterVox'
import { CandidatesProvider } from 'hooks/useCandidates'
import { OverviewProvider } from 'componentsNew/PoliticalVox/hooks/useOverview'
import { FiltersVoxBarProvider } from 'componentsNew/PoliticalVox/hooks/useFilterBarVox'
import { TrajectoryTimeLineProvider } from '../global/TrajectoryTimeLineContext'
import { TrajectoryValidVotesProvider } from '../global/TrajectoryValidVotesContext'
import { TrajectoryActivitiesProvider } from '../global/TrajectoryActivitiesContext'
import { TrajectoryExpensesProvider } from '../global/TrajectoryExpensesContext'
import { TrajectoryDonationsProvider } from '../global/TrajectoryDonationsContext'
import { HorizontalSubmenuProvider } from '../global/HorizontalMenuContext'
import { DiagnosisFilterProvider } from '../global/DiagnosisFilterContext'
import { MenuProvider } from '../global/MenuContext'
import { UserProvider } from '../global/UserContext'
import { ReportProvider } from '../global/ReportContext'
import { TabProvider } from '../global/TabContext'
import { IChildTypes } from '../types/childrenTypes'
import { CandidateDiagnosticFilterProvider } from '../global/CandidateDiagnosticFilterContext'
import { DrawerProvider } from '../global/DrawerContext'

const ProviderContext = ({ children }: IChildTypes) => {
  return (
    <MenuTitleProvider>
      <SidBarProvider>
        <TabProvider>
          <UserProvider>
            <CandidatesProvider>
              <OverviewProvider>
                <ReportProvider>
                  <CandidateDiagnosticFilterProvider>
                    <DiagnosisFilterProvider>
                      <DrawerProvider>
                        <FiltersVoxBarProvider>
                          <FilterVoxProvider>
                            <FilterGlobalProvider>
                              <TrajectoryTimeLineProvider>
                                <TrajectoryValidVotesProvider>
                                  <TrajectoryActivitiesProvider>
                                    <TrajectoryExpensesProvider>
                                      <TrajectoryDonationsProvider>
                                        <HorizontalSubmenuProvider>
                                          <MenuProvider>{children}</MenuProvider>
                                        </HorizontalSubmenuProvider>
                                      </TrajectoryDonationsProvider>
                                    </TrajectoryExpensesProvider>
                                  </TrajectoryActivitiesProvider>
                                </TrajectoryValidVotesProvider>
                              </TrajectoryTimeLineProvider>
                            </FilterGlobalProvider>
                          </FilterVoxProvider>
                        </FiltersVoxBarProvider>
                      </DrawerProvider>
                    </DiagnosisFilterProvider>
                  </CandidateDiagnosticFilterProvider>
                </ReportProvider>
              </OverviewProvider>
            </CandidatesProvider>
          </UserProvider>
        </TabProvider>
      </SidBarProvider>
    </MenuTitleProvider>
  )
}

export default ProviderContext
