export const SELECT_OPTIONS = [
  {
    label: 'Assessor Político',
    value: 7,
  },
  {
    label: 'Candidato Político',
    value: 2,
  },
  {
    label: 'Consultoria',
    value: 3,
  },
  {
    label: 'Estrategista',
    value: 4,
  },
  {
    label: 'Financiadores de Campanhas',
    value: 5,
  },
  {
    label: 'Marketing',
    value: 6,
  },
]

export const PUBLIC_CHECK_LABEL = 'Declaro que atualmente eu ocupo função ou cargo público'
export const PEP_CHECK_LABEL = 'Sou uma “Pessoa Exposta Publicamente” (PEP)'
