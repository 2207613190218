import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { deleteItemStorage } from '../../utils/persistenceUtils'
import { AUTH_TOKEN } from '../../utils/constants'

const Logout = () => {
  const history = useNavigate()
  useEffect(() => {
    deleteItemStorage(AUTH_TOKEN)
    history('/')
  }, [])
  return null
}

export default Logout
