/* eslint-disable no-nested-ternary */
import * as React from 'react'
import { useState } from 'react'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import * as S from './styles'

type CustomInputProps = {
  mask?: string
  placeholder?: string
  width?: string
  error?: boolean
  type?: string
  backgroundColor?: string
  classWrapper?: string
} & any

export const CustomInput = ({
  mask,
  placeholder,
  width,
  error,
  type = 'text',
  backgroundColor,
  classWrapper,
  ...props
}: CustomInputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <S.Wrapper width={width} className={`${classWrapper} full wrapper_input`}>
      {type === 'password' ? (
        <S.EntryArea error={error}>
          <S.Input
            placeholder={placeholder || 'Custom Placeholder'}
            type={showPassword ? 'text' : 'password'}
            {...props}
          />

          <S.LabelLine className="line">{placeholder}</S.LabelLine>
        </S.EntryArea>
      ) : (
        <S.EntryArea error={error}>
          {mask ? (
            <S.InputMask
              placeholder={placeholder || 'Custom Placeholder'}
              type={type}
              mask={mask}
              maskChar={null}
              maskPlaceholder={null}
              {...props}
            />
          ) : (
            <S.Input placeholder={placeholder || 'Custom Placeholder'} type={type} {...props} />
          )}
          <S.LabelLine backgroundColor={backgroundColor} className="line">
            {placeholder}
          </S.LabelLine>
        </S.EntryArea>
      )}

      {type === 'password' && (
        <S.IconPassword>
          {showPassword ? (
            <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
          ) : (
            <EyeOutlined onClick={togglePasswordVisibility} />
          )}
        </S.IconPassword>
      )}
    </S.Wrapper>
  )
}
