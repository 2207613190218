import { apiAnalysis } from 'services/apiService'
import { buildQueryString } from 'utils/buildQueryString'

interface CandidateResp {
  id_cand: string
  nm_candidato: string
  party: string
  value: number | string
  label: string
}

interface payloadSearch {
  candidate?: string
  city?: string
  state?: string
  party?: string
  position?: string
}

interface ErrorMessage {
  message: string
}

type SearchCandidateResponse = CandidateResp[] | ErrorMessage

export const searchCandidate = async (payload: payloadSearch): Promise<SearchCandidateResponse> => {
  const formatParams = {
    ...payload,
    candidate: payload?.candidate?.toUpperCase(),
  }
  try {
    const { data } = await apiAnalysis.get(`/analysis/candidates/${buildQueryString(formatParams)}`)

    const formatterData = data?.map((item: CandidateResp) => ({
      ...item,
      value: item?.id_cand,
      label: `${item?.nm_candidato} - ${item?.party}`,
    }))

    return formatterData
  } catch (e) {
    return { message: 'Não foi possivel buscar dados cadastrados.' }
  }
}
