import styled from 'styled-components'

export interface IFullScreen {
  footerCenter: boolean
}

export const FullScreen = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div<IFullScreen>`
  background-color: ${({ theme }) => theme.COLORS.shade30};
  min-width: 400px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .divider {
    border-bottom: 1px solid white;
    width: 100%;
  }

  .header {
    padding: 24px 16px;
    text-align: center;
    max-width: 500px;
  }

  .footer {
    width: 90%;
    flex: 90%;
    display: flex;
    justify-content: ${({ footerCenter }) => (footerCenter ? 'space-between' : 'center')};
    padding: 0 24px;
    padding-bottom: 16px;
    gap: 16px;
  }

  .content {
    padding: 24px;
  }
`
