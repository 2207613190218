import React, { useEffect, useState } from 'react'
import { /* BsArrowDown, */ BsArrowLeft } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'
import Spin from 'componentsNew/Spin'
import Card from 'componentsNew/Card'
import Text from 'componentsNew/Text'
import { TransformedCandsData, TransformedPartyData } from 'pages/AllApplications/types/allApplicationsTypes'
import CardAllApplications from 'componentsNew/CardAllApplications'
import MapaHexagonal from '../../components/MapaHexagonal'
import CustomCollapse from '../../../../componentsNew/CustomCollapse'
import CustomCollapseMinimal from '../../../../componentsNew/CustomCollapse/componets/CustomCollapseMinimal'
import TabsCustom from '../../../../componentsNew/Tabs'
import { Container, ContainerButtonLink, ContainerMaster } from './styles'
import ModalCandidate from '../../components/ModalCandidate'
import Modal from '../../../../componentsNew/Modal'
import usePartyData from '../../hooks/usePartyData'
import { useTab } from '../../../../global/TabContext'

const labels = [
  {
    label: 'Panorama Nacional',
    key: 'tab_panorama_national_all_applications',
  },
]

interface ITotal {
  position: string
  value: number
}

interface IStateData {
  state: string
  acronym: string
  totals: ITotal[]
}

interface ICityTotal {
  position: string
  value: number
}

interface ICityData {
  city: string
  totals: ICityTotal[]
}

const NationalPanorama = () => {
  const params = useParams<{ id: string }>()
  const partyId = params.id ? parseInt(params.id, 10) : null
  const [activeTab, setActiveTab] = React.useState('tab_panorama_national_all_applications')
  const { setActiveTabContext } = useTab()
  const {
    partyData,
    statesData,
    citiesData,
    candidatesData,
    loading,
    fetchParty,
    fetchStates,
    fetchCities,
    fetchCandidates,
  } = usePartyData()

  const [selectedCity, setSelectedCity] = useState('')
  const [candidatos, setCandidatos] = useState<TransformedCandsData[]>([])
  const [selectedParty, setSelectedParty] = useState('')
  const [selectedCargo, setSelectedCargo] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [selectedStateDefault, setSelectedState] = useState('')
  const [statePortions, setStatePortions] = useState<{ acronym: string; total_portion: number }[]>([])
  const history = useNavigate()

  useEffect(() => {
    setActiveTabContext(activeTab)
  }, [activeTab, setActiveTabContext])

  useEffect(() => {
    if (partyId) {
      fetchParty(partyId)
    }
  }, [partyId])

  useEffect(() => {
    if (partyId) {
      fetchStates(partyId)
    }
  }, [partyId])

  useEffect(() => {
    const updatedStatePortions = statesData.map(stateData => ({
      acronym: stateData.acronym,
      total_portion: stateData.total_portion,
    }))

    setStatePortions(updatedStatePortions)
  }, [statesData])

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleOpen = (partido: string, estado: string, cidade: string, cargo: string) => {
    setOpenModal(true)
    setSelectedParty(partido)
    setSelectedState(estado)
    setSelectedCity(cidade)
    setSelectedCargo(cargo)
  }

  const handleSelectedState = (state: string) => {
    setSelectedState(state)
  }

  const candidatosParaModal = candidatesData[`${selectedStateDefault}-${selectedCity}`]
  // eslint-disable-next-line no-nested-ternary
  const candidatosPreparados = Array.isArray(candidatosParaModal)
    ? candidatosParaModal
    : candidatosParaModal
    ? [candidatosParaModal]
    : []

  const handleOpenWithPartyTitle = (partyId: number, stateAcronym: string, city: string, totals: any) => {
    const selectedPartyData = partyData?.find(party => party.id === partyId)

    if (selectedPartyData) {
      const position = Array.isArray(totals) && totals.length > 0 ? totals[0].position : ''
      handleOpen(selectedPartyData.title, stateAcronym, city, position)
    } else {
      console.error('Partido não encontrado!')
    }
  }

  const order = ['PREFEITO', 'VICE-PREFEITO', 'VEREADOR']
  const reorderTotals = (totals: any) => {
    const ordered = new Array(order.length).fill(null)

    totals.forEach((total: any) => {
      const index = order.indexOf(total.position)
      if (index !== -1) {
        ordered[index] = total
      }
    })

    return ordered.filter(el => el !== null)
  }

  return (
    <TabsCustom
      labels={labels}
      activeTab={activeTab}
      content={[
        <ContainerMaster>
          {loading && <Spin size="large" fullscreen />}
          <div>
            <ContainerButtonLink onClick={() => history('/dashboard/all-applications')}>
              <BsArrowLeft />
              Alterar partido
            </ContainerButtonLink>

            {partyData?.map((dataParty: TransformedPartyData) => (
              <CardAllApplications
                key={dataParty.id}
                id={dataParty.id}
                valueCandidates={dataParty.valueCandidates}
                title={dataParty.title}
                icon={dataParty.icon}
                titleCard="Partido"
              />
            ))}

            <Container>
              <MapaHexagonal
                handleSelectedState={handleSelectedState}
                selectedStateDefault={selectedStateDefault}
                statesData={statePortions}
              />

              <div className="container-right">
                <div className="card-title-states">
                  <Card>
                    <div className="card-title-items">
                      <div className="card-title-uf">
                        <Text>UF</Text>
                      </div>
                      <div className="card-title-position">
                        <Text /* bold */>Prefeito{/*  <BsArrowDown /> */}</Text>
                        <Text>Vice-Prefeito</Text>
                        <Text>Vereador</Text>
                      </div>
                    </div>
                  </Card>
                </div>

                <div className="descripiton">
                  {statesData.length > 0 &&
                    statesData &&
                    statesData.map(dataState => {
                      const stateCities = citiesData[dataState?.acronym] || []
                      console.log('statesData', statesData)
                      return (
                        <CustomCollapse
                          title={dataState?.state}
                          uf={dataState?.acronym}
                          key={dataState?.acronym}
                          totals={dataState?.totals}
                          isActiveDefault={false}
                          selectedStateDefault={selectedStateDefault}
                          onChande={handleSelectedState}
                          onClick={() => {
                            if (partyId !== null && dataState?.acronym) {
                              fetchCities(partyId, dataState.acronym)
                            }
                          }}
                        >
                          {stateCities.map((cityData, index) => (
                            <CustomCollapseMinimal
                              key={cityData?.city}
                              title={cityData?.city}
                              totals={
                                Array.isArray(cityData?.totals)
                                  ? cityData?.totals.map((total: any) => ({
                                      position: total.position,
                                      value: total.count,
                                    }))
                                  : []
                              }
                              isLink
                              onClick={() => {
                                if (partyId !== null && dataState?.acronym && cityData.city) {
                                  fetchCandidates(partyId, dataState.acronym, cityData.city)
                                  handleOpenWithPartyTitle(partyId, dataState.acronym, cityData.city, cityData?.totals)
                                }
                              }}
                              index={index}
                              isActive={false}
                            >
                              MODAL LINK
                            </CustomCollapseMinimal>
                          ))}
                        </CustomCollapse>
                      )
                    })}
                </div>
              </div>
            </Container>
          </div>
          ,
          {openModal && (
            <Modal
              closeModal={handleClose}
              title="TODAS AS CANDIDATURAS"
              onSecondaryClick={handleClose}
              secondaryTextButton="Fechar"
            >
              {loading ? (
                <Spin size="large" />
              ) : (
                <ModalCandidate
                  candidatos={candidatosPreparados}
                  partido={selectedParty}
                  estado={selectedStateDefault}
                  cidade={selectedCity}
                  cargo={selectedCargo}
                />
              )}
            </Modal>
          )}
          ,
        </ContainerMaster>,
      ]}
    />
  )
}

export default NationalPanorama
