/* eslint-disable no-unused-expressions */
import { useEffect } from 'react'
import { IoMdAdd } from 'react-icons/io'
import { useSwot } from 'componentsNew/ElectionStrategyComponents/hooks/useSwot'
import { IoRemove } from 'react-icons/io5'
import { toast } from 'react-toastify'
import wizard_icon from 'icons/wizard_icon.svg'
import { useUser } from 'global/UserContext'
import { removeTopic, updateTopic } from 'componentsNew/ElectionStrategyComponents/services/updateByTopic'
import { CardSelection } from '../../components/CardSelection'
import {
  acceptWizard,
  getPersonThemes,
  getThemes,
  getWizards,
  sendTheme,
  deleteTheme,
  translateIdentificationToName,
  translateValue,
  updateListener,
} from '../../services/swotServices'

import { CardWizard } from '../../components/CardWizard'
import * as S from './styles'

interface PostThemeDataProps {
  topicList: any[]
  topicIndex: number
  themeIndex: number
  newValue: any
  isPositive?: boolean
}

export const Swot = () => {
  const { user } = useUser()

  const {
    visibleWizard,
    changeSelect,
    updateListByTopic,
    topicList,
    updateTopicList,
    updateVisibleWizards,
    defaultSelect,
  } = useSwot()

  useEffect(() => {
    fetchThemes()
    fetchWizardData()
    fetchPersonEnv()
  }, [])

  const fetchWizardData = async () => {
    try {
      const wizardList = await getWizards()
      if ('message' in wizardList) {
        toast.error(wizardList.message)
      } else {
        const wizardsWithTranslatedTopic = wizardList.results.map(wizard => ({
          ...wizard,
          topic_api: wizard.topic_name,
          topic_name: translateIdentificationToName(wizard?.topic_name || ''),
          is_positive: translateValue(wizard?.topic_name || ''),
        }))

        if (wizardsWithTranslatedTopic.length > 0) {
          updateVisibleWizards(wizardsWithTranslatedTopic)
        }
      }
    } catch (error) {
      console.error('Error fetching wizard data:', error)
    }
  }

  const fetchThemes = async () => {
    try {
      const themes = await getThemes()
      if ('message' in themes) {
        toast.error(themes.message)
      } else {
        const newThemeList = themes.results.map((option: any) => ({
          ...option,
          value: option.id,
          label: option.theme_name || option.name || option?.topic_name,
        }))

        changeSelect(newThemeList)
      }
    } catch (error) {
      console.log('Error fetching themes')
    }
  }

  const fetchPersonEnv = async () => {
    try {
      const themes = await getPersonThemes()
      if ('message' in themes) {
        console.log(themes.message)
      } else {
        const theme_default = themes.results.map((option: any) => ({
          ...option,
          value: option.id,
          label: option.theme_name || option.name || option?.topic_name,
        }))
        const topicListFiltered = topicList.map(topicItem => {
          const themeList = theme_default.filter(
            (defaultTheme: any) => defaultTheme.topic_name === topicItem.topic_name
          )

          return {
            ...topicItem,
            theme_list: [...themeList, ...topicItem.theme_list],
          }
        })

        updateTopicList(topicListFiltered)
      }
    } catch (error) {
      console.log('Error fetching themes')
    }
  }

  const handleCardClick = (data: any) => {
    if (visibleWizard && visibleWizard.length > 0) {
      const clickedWizard = visibleWizard.find(wizard => wizard.id === data.id)
      const selectedTopic = topicList.find(topic => topic.topic_name === data.topic_name) || null

      if (clickedWizard && selectedTopic?.selection_list) {
        const clickedWIzardData = {
          ...clickedWizard,
          id: clickedWizard.id,
          name: clickedWizard.theme_name,
          is_recommended: true,
          value: clickedWizard.id,
          label: clickedWizard.theme_name,
        }

        updateListByTopic(clickedWIzardData)
      }

      const updatedWizards = visibleWizard.filter(wizard => wizard.id !== data.id)

      updateVisibleWizards(updatedWizards.length > 0 ? updatedWizards : null)
    }
  }

  const handleRemoveCard = (isPositive: boolean, wizardId: number) => {
    acceptWizard({ wizard_id: wizardId, status: 'rejected' })

    const updatedWizards = isPositive
      ? (visibleWizard || []).filter(wizard => wizard.id !== wizardId)
      : (visibleWizard || []).filter(wizard => wizard.id !== wizardId)

    updateVisibleWizards(updatedWizards.length > 0 ? updatedWizards : null)
  }

  const postThemeData = ({ topicList, topicIndex, themeIndex, newValue, isPositive }: PostThemeDataProps) => {
    /* sendTheme() */
    updateTopic(topicList, topicIndex, themeIndex, newValue)
    sendTheme({ theme_data: { ...newValue?.data, person: user?.id, is_positive: isPositive } })
  }

  const updateListnerById = (checked: boolean, id: number) => {
    updateListener({ theme_id: id, is_listening: checked })
  }

  const removeTheme = ({ topicList, topicIndex, themeIndex, newValue }: PostThemeDataProps) => {
    deleteTheme(newValue?.id)
    removeTopic(topicList, topicIndex, themeIndex)
  }

  return (
    <S.Wrapper>
      <S.Section>
        <S.Box>
          <S.Heading>
            <S.WizardIcon src={wizard_icon} alt="wizard icon" />
            Wizard Eleitoral
          </S.Heading>

          <S.ContentWizard>
            {visibleWizard &&
              visibleWizard.slice(0, 2).map(wizard => (
                <CardWizard
                  key={wizard.id}
                  data={wizard}
                  positiveIcon
                  onCardSelect={data => handleCardClick(data)}
                  onRemove={() => handleRemoveCard(true, wizard.id)}
                  actionCard={data =>
                    acceptWizard({
                      wizard_id: wizard.id,
                      topic: wizard.theme || wizard.theme_name,
                      status: 'approved',
                    })
                  }
                />
              ))}
          </S.ContentWizard>
        </S.Box>
      </S.Section>

      <S.GridListSelection>
        <S.ContentList>
          <S.Box>
            <S.TitleList>
              POSITIVOS (<IoMdAdd color="#00E23F" size={16} /> )
            </S.TitleList>
          </S.Box>
          {topicList
            .filter(item => item.is_positive)
            .map((item, topicIndex) => {
              return (
                <S.BoxListGrid key={item.id}>
                  <CardSelection
                    default_theme={defaultSelect}
                    /*   fixed_theme={MOCK_ALL_THEMES} */
                    dataSelection={item}
                    onChangeListener={(checked, id) => updateListnerById(checked, id)}
                    onChange={(index: any, value: any) =>
                      postThemeData({
                        topicList,
                        topicIndex,
                        themeIndex: index,
                        newValue: value,
                        isPositive: true,
                      })
                    }
                    onRemoveTheme={(index: any, value: any) =>
                      removeTheme({
                        topicList,
                        topicIndex,
                        themeIndex: index,
                        newValue: value,
                      })
                    }
                  />
                </S.BoxListGrid>
              )
            })}
        </S.ContentList>

        <S.ContentList>
          <S.Box>
            <S.TitleList>
              NEGATIVOS (<IoRemove color="#D70708" />)
            </S.TitleList>
          </S.Box>
          {topicList
            .filter(item => !item?.is_positive)
            .map((item, topicIndex) => {
              return (
                <S.BoxListGrid key={item.id}>
                  <CardSelection
                    default_theme={defaultSelect}
                    dataSelection={item}
                    onChangeListener={(checked, id) => updateListnerById(checked, id)}
                    onChange={(index: any, value: any) =>
                      postThemeData({
                        topicList,
                        topicIndex,
                        themeIndex: index,
                        newValue: value,
                        isPositive: false,
                      })
                    }
                    onRemoveTheme={(index: any, value: any) =>
                      removeTheme({
                        topicList,
                        topicIndex,
                        themeIndex: index,
                        newValue: value,
                      })
                    }
                  />
                </S.BoxListGrid>
              )
            })}
        </S.ContentList>
      </S.GridListSelection>
    </S.Wrapper>
  )
}
