import * as React from 'react'

import dayjs, { Dayjs } from 'dayjs'
import ptBR from 'antd/es/locale/pt_BR'
import { IoMdCloseCircleOutline } from 'react-icons/io'

import 'dayjs/locale/pt-br'
import { ConfigProvider } from 'antd'
import * as S from './styles'

dayjs.locale('pt-br')

interface IControlledComponent {
  label?: string
  defaultValue?: string
  onChange: (date: string) => void
  disabled?: boolean
  error?: boolean
  helperText?: string
  minDate?: string
  maxDate?: string
}

const dateFormat = 'YYYY-MM-DD'
const displayFormat = 'DD/MM/YYYY'

const ControlledComponent: React.FC<IControlledComponent> = ({
  label,
  defaultValue,
  onChange,
  disabled = false,
  error = false,
  helperText = '',
  minDate,
  maxDate,
}) => {
  const [value, setValue] = React.useState<Dayjs | null>(defaultValue ? dayjs(defaultValue) : null)
  const defaultDate = dayjs(defaultValue)
  const formatMinDate = minDate ? dayjs(minDate) : undefined
  const formatMaxDate = maxDate ? dayjs(maxDate) : undefined
  const validateDIsabled = !!minDate || !!maxDate

  React.useEffect(() => {
    if (defaultValue) {
      setValue(dayjs(defaultValue))
    }
  }, [defaultValue])

  const handleValueChange = (newValue: Dayjs | null) => {
    setValue(newValue)
    onChange(newValue ? newValue.format('YYYY-MM-DD') : '')
  }

  const disabledDate = (current: Dayjs) => {
    if (validateDIsabled) {
      return current && current < dayjs(minDate).startOf('day') && current < dayjs(maxDate).startOf('day')
    }

    return false
  }

  return (
    <ConfigProvider locale={ptBR}>
      <S.ContainerInput
        maxDate={formatMaxDate}
        minDate={formatMinDate}
        className="date_picker"
        disabled={disabled}
        placeholder="Selecione uma data"
        format={displayFormat}
        defaultValue={dayjs(defaultValue, dateFormat)}
        value={value}
        onChange={(val: any) => handleValueChange(val)}
        size="large"
        clearIcon={<IoMdCloseCircleOutline color="rgb(250, 173, 20)" />}
        disabledDate={disabledDate}
      />
    </ConfigProvider>
  )
}

export default ControlledComponent
