import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { FiLock, FiEye } from 'react-icons/fi'

const OverlayContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  border-radius: 8px;
  cursor: pointer;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 14px;
    color: white;
  }
`

interface LockedOverlayProps {
  isOpen: boolean
  text?: string
}

const LockedOverlay: React.FC<LockedOverlayProps> = ({ isOpen, text = 'Em breve' }) => {
  const [showContent, setShowContent] = useState(isOpen)
  const [showEyeIcon, setShowEyeIcon] = useState(false)

  const handleOverlayClick = () => {
    setShowEyeIcon(false)
    setShowContent(false)
  }

  return (
    <OverlayContainer
      onMouseEnter={() => setShowEyeIcon(true)}
      onMouseLeave={() => setShowEyeIcon(false)}
      onClick={handleOverlayClick}
      animate={{ opacity: showContent ? 1 : 0 }}
      transition={{ duration: 0.3 }}
    >
      <IconWrapper>
        {showEyeIcon ? (
          <>
            <FiEye size={26} color="#e9be00" />
            <p>Pré visualização</p>
          </>
        ) : (
          <>
            <FiLock size={26} color="#e9be00" />
            <p>{text}</p>
          </>
        )}
      </IconWrapper>
    </OverlayContainer>
  )
}

export default LockedOverlay
