import React, { createContext, useState, useContext } from 'react'
import { IChildTypes } from 'types/childrenTypes'

const MenuTitleContext = createContext<any>(null)

export const useMenuTitle = () => useContext(MenuTitleContext)

export const MenuTitleProvider = ({ children }: IChildTypes) => {
  const [menuTitle, setMenuTitle] = useState('')

  const updateMenuTitle = (title: string) => {
    setMenuTitle(title)
  }

  return <MenuTitleContext.Provider value={{ menuTitle, updateMenuTitle }}>{children}</MenuTitleContext.Provider>
}
