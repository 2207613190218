import * as Styles from './styles'

export type TextDangerProps = {
  text: string
  width?: string
  withMaxLengthText?: boolean
  maxLineText?: number
  fontSize?: string
  fontWeight?: number
  justify?: 'left' | 'center' | 'right' | 'justify'
  color?: string
}

export const TextDanger = ({
  text,
  width,
  withMaxLengthText,
  maxLineText,
  fontSize,

  fontWeight,
  justify,
  color,
}: TextDangerProps) => {
  return (
    <Styles.Container
      justify={justify}
      className="danger-text"
      color={color}
      width={width}
      fontSize={fontSize}
      fontWeight={fontWeight}
      withMaxLengthText={withMaxLengthText}
      maxLineText={maxLineText}
    >
      {text}
    </Styles.Container>
  )
}
