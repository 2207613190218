import styled from 'styled-components'

export const DrodownRender = styled.div`
  min-width: 370px;
  min-height: 176px;
  background: #161717;
`

export const RenderItem = styled.div`
  color: white;
  min-width: 300px;
  display: flex;
  align-items: center;

  &:hover {
    color: #ffc000;
  }
`
