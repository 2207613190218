import styled from 'styled-components'

export interface IFullScreen {
  open: boolean
}

export const FullScreen = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0.2;
  background-color: #161717;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div<IFullScreen>`
  display: flex;
  width: 114px;
  height: calc(100% - 64px);
  top: 64px;
  left: ${({ open }) => (open ? '8.3rem' : '2.75rem')};
  z-index: 101;
  position: absolute;
  background-color: ${({ theme }) => theme.COLORS.BLACK_4};
  flex-direction: column;

  .header {
    width: 100%;
    padding: 0.5rem;
    text-align: center;
  }
`
