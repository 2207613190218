import { useEffect, useState } from 'react'

import { useAlly } from 'componentsNew/ElectionStrategyComponents/hooks/useAllies'

import { toast } from 'react-toastify'
import { MOCK_ALLY_CANDIDATE_LIST } from 'componentsNew/ElectionStrategyComponents/MOCK/allyMock'
import wizard_icon from 'icons/wizard_icon.svg'

import {
  deleteAdversary,
  getPersonThemes,
  getWizards,
} from 'componentsNew/ElectionStrategyComponents/services/allyServices'
import { IoMdAdd, IoMdRemove } from 'react-icons/io'
import { FaEquals } from 'react-icons/fa6'

import { CardSelectionAlly } from 'componentsNew/ElectionStrategyComponents/components/CardSelectionAlly'

import { CardWizard } from '../../components/CardWizard'
import * as S from './styles'

export const Ally = () => {
  const { updateVisibleWizard, visibleWizard } = useAlly()
  const [list, setList] = useState(MOCK_ALLY_CANDIDATE_LIST)

  useEffect(() => {
    fetchThemes()
    fetchWizardData()
  }, [])

  const fetchWizardData = async () => {
    try {
      const wizardList = await getWizards()
      if ('message' in wizardList) {
        toast.error(wizardList.message)
      } else {
        const allyWizards = wizardList.results

        updateVisibleWizard(allyWizards)
      }
    } catch (error) {
      toast.error('Não foi possível buscar os dados dos Aliados.')
    }
  }

  const fetchThemes = async () => {
    const themes = await getPersonThemes()
    if ('message' in themes) {
      toast.error(themes.message)
    } else {
      const themeResult = themes
      setList(prev =>
        prev.map(item => {
          const newProfileList = themeResult.filter(profile => profile.topic === item.topic)
          return {
            ...item,
            profile_list: newProfileList,
          }
        })
      )
    }
  }

  const handleCardClick = (data: any) => {
    if (visibleWizard && visibleWizard.length > 0) {
      const clickedWizard = visibleWizard.find(wizard => wizard.id === data.id)

      if (clickedWizard) {
        setList(prevTopics => {
          const newTopics = [...prevTopics]
          const topicIndex = newTopics.findIndex(topic => topic.topic === clickedWizard.topic)

          if (topicIndex !== -1) {
            const topicItem = newTopics[topicIndex]
            const profileList = topicItem.profile_list || []

            if (profileList.length < 6) {
              profileList.push({ ...clickedWizard })

              topicItem.profile_list = profileList

              newTopics[topicIndex] = topicItem
            }
          }

          return newTopics
        })
      }

      const updatedWizards = visibleWizard.filter(wizard => wizard.id !== data.id)

      updateVisibleWizard(updatedWizards.length > 0 ? updatedWizards : null)
    }
  }

  const handleRemoveCard = (wizardId: number) => {
    const updatedWizards = (visibleWizard || []).filter(wizard => wizard.id !== wizardId)

    updateVisibleWizard(updatedWizards.length > 0 ? updatedWizards : null)
  }

  const wizardTopicType = (topic: string) => {
    const iconPositive = <IoMdAdd color="#00E23F" size={18} />
    const iconNegative = <IoMdRemove color="#D70708" size={18} />
    if (topic === 'very good') {
      return (
        <>
          {iconPositive}
          {iconPositive}
        </>
      )
    }
    if (topic === 'good') {
      return iconPositive
    }
    if (topic === 'bad') {
      return iconNegative
    }
    if (topic === 'very bad') {
      return (
        <>
          {iconNegative}
          {iconNegative}
        </>
      )
    }
    return <FaEquals size={18} color="#FFC000" />
  }

  const wizardTopicTitle = (topic: string) => {
    switch (topic) {
      case 'very good':
        return 'Aliados Ativos'
      case 'good':
        return 'Aliados Passivos'
      case 'neutral':
        return 'Neutros'
      case 'bad':
        return 'Adversários Passivos'
      case 'very bad':
        return 'Adversários Ativos'
      default:
        return 'Indefinido'
    }
  }

  const handleRemoveCandidate = (topic: string, profileId: string, theme_id: number) => {
    setList(prev => {
      const updatedList = prev.map(item => {
        if (item.topic === topic) {
          const newProfileList = item.profile_list && item.profile_list.filter(profile => profile.id_cand !== profileId)
          return { ...item, profile_list: newProfileList }
        }
        return item
      })
      return updatedList
    })

    deleteAdversary(theme_id)
  }

  return (
    <S.Wrapper>
      <S.Section>
        <S.Box>
          <S.Heading>
            <S.WizardIcon src={wizard_icon} alt="wizard icon" />
            Wizard Aliado
          </S.Heading>

          <S.ContentWizard>
            {visibleWizard &&
              visibleWizard.slice(0, 2).map(wizard => (
                <CardWizard
                  key={wizard.id}
                  data={{
                    ...wizard,
                    name: wizardTopicType(wizard.topic),
                    profile_name: `${wizard?.cand_name} | ${wizard?.party}`,
                  }}
                  avatar={wizard.avatar}
                  onCardSelect={data => handleCardClick(data)}
                  onRemove={() => handleRemoveCard(wizard.id)}
                  positiveIcon={false}
                />
              ))}
          </S.ContentWizard>
        </S.Box>
      </S.Section>

      <S.GridListSelection>
        {list.map(profile => (
          <S.WrapperContent key={profile.id}>
            <S.BoxEqual>{wizardTopicType(profile.topic)}</S.BoxEqual>
            <S.WrapperProfileCard>
              <S.TitleList>
                {wizardTopicTitle(profile.topic)} ({profile.profile_list?.length})
              </S.TitleList>
              <S.ContentList>
                <CardSelectionAlly
                  topic={profile.topic}
                  profile_list={profile.profile_list || []}
                  onRemove={(id, theme) => handleRemoveCandidate(profile.topic, id, theme)}
                />
              </S.ContentList>
            </S.WrapperProfileCard>
          </S.WrapperContent>
        ))}
      </S.GridListSelection>
    </S.Wrapper>
  )
}
