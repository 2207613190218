import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import theme from 'styles/theme'

export const Container = styled.main<{ haveItem?: boolean }>`
  display: flex;
  align-items: center;

  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  height: 140px;
  align-content: flex-start;
  column-gap: 16px;
  row-gap: 8px;
  position: relative;

  ${props =>
    props.haveItem &&
    css`
      justify-content: center;
      align-content: center;
    `}
`

export const Item = styled(motion.div)`
  width: calc(50% - 16px);
  display: flex;
  gap: 4px;
  align-items: center;

  svg {
    cursor: pointer;
  }
`

export const AddButton = styled(motion.button)`
  all: unset;
  background-color: #111111;
  color: #ffffff;
  border-radius: 8px;
  width: calc(50% - 16px);
  height: 32px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.COLORS.yellow10};

  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    background: radial-gradient(
      circle,
      rgba(204, 204, 204, 0.3561799719887955) 0%,
      rgba(212, 212, 212, 0.16010154061624648) 49%,
      rgba(177, 177, 177, 0.10127801120448177) 100%
    );
    transition: width 0.45s ease-in-out;
    transform: translateX(-50%);
    z-index: -1;
  }

  &:hover:before {
    width: 200%;
  }

  &:disabled {
    opacity: 0.5 !important;
    cursor: not-allowed;
    color: #808080;
    &:before {
      display: none;
    }
  }
`

export const IconBox = styled.div`
  position: absolute;

  &.rightIcon {
    right: calc(100% - 96% + -0.4vw);

    top: -19%;
  }

  &.leftIcon {
    transform: translate(-49px, -29px);
  }

  /*   @media screen and (max-width: 650px) {
    &.rightIcon {
      transform: unset;
      top: -9%;
      right: 10px;
    }

    &.leftIcon {
      display: none;
    }
  }

  @media screen and (max-width: 650px) {
    &.rightIcon {
      transform: unset;
      top: -9%;
      right: 10px;
    }

    &.leftIcon {
      display: none;
    }
  } */
`

export const Icon = styled.img``
