import React, { useState } from 'react'
import { AiOutlineGlobal } from 'react-icons/ai'
import { CiCircleAlert } from 'react-icons/ci'
import { TextDanger } from 'componentsNew/TextDanger'
import Button from 'componentsNew/Button'
import theme from 'styles/theme'
import { Tooltip } from 'antd'
import Text from '../Text'
import { ContainerCard, TextMore } from './styles'

interface ICardMentions {
  user: string
  iconServices: any
  message: string
  dataCreate: string
  iconSocial: any
  color: string
  fullWidth?: boolean
  page_type_name?: string
  url?: string
}

const seeMention = (link: string) => {
  window.open(link, '_blank')
  return false
}

const CardMentions = ({
  user,
  message,
  dataCreate,
  iconSocial,
  iconServices,
  color,
  fullWidth,
  page_type_name,
  url,
}: ICardMentions) => {
  const arrTypes = ['Infraestrutura', 'Dispositivos', 'Redes', 'Assistente IOT']
  const [limitLine, setLimitLine] = useState(false)
  const MAX_CHARACTERS = 250

  const showToggle = message.length > MAX_CHARACTERS

  return (
    <ContainerCard color={color} fullWidth={fullWidth} onClick={() => console.log('social', { page_type_name })}>
      <div className="mark" />
      <div className="wrapper">
        <div className="header">
          <div className="title">
            {iconSocial ? (
              <img
                src={iconSocial}
                alt="iconSocial"
                className="icon-default"
                style={{ backgroundColor: 'transparent' }}
              />
            ) : (
              <div className="icon-default">
                <AiOutlineGlobal />
              </div>
            )}

            <Text size="medium" color="white" bold>
              @{user}
            </Text>

            {arrTypes.includes(page_type_name || '') && (
              <Tooltip title="Texto gerado por IA com base na análise anonimizada da menção.">
                <CiCircleAlert style={{ marginLeft: '4px' }} size={18} color={theme.COLORS.gray10} />
              </Tooltip>
            )}
          </div>
          <div className="iconHeader">
            <img src={iconServices} alt={iconServices} className="icon" />
          </div>
        </div>
        {/*  <Text color="white">{message}</Text> */}
        <TextDanger text={message} maxLineText={3} withMaxLengthText={!limitLine} />
        {/*         <TextMore onClick={() => setLimitLine(prev => !prev)}>{limitLine ? 'ver menos' : 'ver mais'}</TextMore>
         */}{' '}
        {showToggle && (
          <TextMore onClick={() => setLimitLine(prev => !prev)}>{limitLine ? 'ver menos' : '...ver mais'}</TextMore>
        )}
        <div className="mentions">
          <Button size="medium" color="yellow" variant="minimal" onClick={() => url && seeMention(url)}>
            Ver menção
          </Button>
          <Text color="white" size="xSmall">
            Postado {dataCreate}
          </Text>
        </div>
      </div>
    </ContainerCard>
  )
}

export default CardMentions
