import React, { createContext, useState, useContext, ReactNode } from 'react'

interface DonationsData {
  id: number
  rithm: number
  weekly_goal: number
  projection: number
  initial_value: number
  goal: number
  minimum_goal: number
  first_partial_goal: number
  second_partial_goal: number
  current_value: number
  initial_date: string
  final_date: string
  created: string
  updated: string
  person: number
}

interface TrajectoryDonationsContextProps {
  donationsData: DonationsData | null
  setDonationsData: (data: DonationsData | null) => void
}

const TrajectoryDonationsContext = createContext<TrajectoryDonationsContextProps | undefined>(undefined)

export const TrajectoryDonationsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [donationsData, setDonationsData] = useState<DonationsData | null>(null)

  return (
    <TrajectoryDonationsContext.Provider value={{ donationsData, setDonationsData }}>
      {children}
    </TrajectoryDonationsContext.Provider>
  )
}

export const useTrajectoryDonations = (): TrajectoryDonationsContextProps => {
  const context = useContext(TrajectoryDonationsContext)
  if (!context) {
    throw new Error('useTrajectoryDonations must be used within a TrajectoryDonationsProvider')
  }
  return context
}
