import { createTheme } from '@mui/material/styles'
import theme from './theme'

const MaterialTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: theme.COLORS.gray10,
    },
    secondary: {
      main: '#E0C2FF',
      light: '#F5EBFF',
      contrastText: '#47008F',
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'red', // Cor do texto
          '&.Mui-selected': {
            color: 'red', // Cor do texto quando a aba está selecionada
          },
          wrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'red',
            background: 'red',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#5C5C5C',
          color: '#5C5C5C',
        },
        root: {
          '&.MuiInputLabel': {
            color: 'white',
          },
          '&:hover': {
            borderColor: 'green',
          },
          '&.Mui-focused': {
            borderColor: theme.COLORS.gray10,
          },
        },
      },
    },
  },
})

export default MaterialTheme
