export const enableCopilot = {
  tab_election_diagnosis_result: true,
  tab_election_diagnosis_evolution_of_applications: false,
  tab_election_diagnosis_candidates_profile: false,
  tab_election_diagnosis_x_ray_campaigns: false,
  tab_election_diagnosis_x_ray_donations: false,
  tab_election_diagnosis_historical_comparison: false,
  tab_election_diagnosis_campaigns_dashboard: false,
  tab_voter_diagnosis_profile: true,
  tab_voter_diagnosis_x_ray_votes: true,
  tab_politica_vox_party: true,
  tab_politica_vox_search: true,
  tab_voter_diagnosis_city_indicators: false,
}

export const enableDiagnosis = {
  tab_election_diagnosis_result: true,
  tab_election_diagnosis_evolution_of_applications: false,
  tab_election_diagnosis_candidates_profile: false,
  tab_election_diagnosis_x_ray_campaigns: false,
  tab_election_diagnosis_x_ray_donations: false,
  tab_election_diagnosis_historical_comparison: false,
  tab_election_diagnosis_campaigns_dashboard: false,
  tab_voter_diagnosis_profile: true,
  tab_voter_diagnosis_x_ray_votes: true,
  tab_map_of_key_cities: false,
  tab_map_of_key_cities_prop: false,
  tab_map_by_position: false,
}

export const enableMarketing = {
  tab_election_diagnosis_result: false,
  tab_election_diagnosis_evolution_of_applications: false,
  tab_election_diagnosis_candidates_profile: false,
  tab_election_diagnosis_x_ray_campaigns: false,
  tab_election_diagnosis_x_ray_donations: false,
  tab_election_diagnosis_historical_comparison: false,
  tab_election_diagnosis_campaigns_dashboard: false,
  tab_voter_diagnosis_profile: false,
  tab_politica_vox_party: true,
  tab_politica_vox_search: true,
  tab_voter_diagnosis_city_indicators: false,
}
