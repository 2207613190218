import { styled } from 'styled-components'

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  button {
    border-radius: 2px;
    font-size: 14px;
    padding: 8px 22px;
  }
`
