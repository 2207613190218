import { SwotProvider } from '../hooks/useSwot'
import { Swot } from '../templates/Swot'

export const SwotTemplate = () => {
  return (
    <SwotProvider>
      <Swot />
    </SwotProvider>
  )
}
