import React, { useEffect } from "react";
import Spin from "componentsNew/Spin";
import TabsCustom from "componentsNew/Tabs";
import { useTab } from "global/TabContext";
import { useCandidates } from "hooks/useCandidates";
import { PersonalDataPage } from "./PersonalDataPage";
import { SecurityData } from "./SecurityData";
import ProfilePicture from "./ProfilePicture";
import * as S from "./styles";

const labels = [
  {
    label: "Dados Pessoais",
    sub_label: "",
    key: "tab_settings_personal_data",
  },
  {
    label: "Segurança",
    sub_label: "",
    key: "tab_settings_security_data",
    disabled: true,
  },
  {
    label: "Foto de Perfil",
    sub_label: "",
    key: "tab_settings_profile_picture",
  },
];

const content: React.ReactNode[] = [
  <PersonalDataPage />,
  <SecurityData />,
  <ProfilePicture />,
];

export const SettingsProfile: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState(
    "tab_settings_personal_data",
  );
  const { isLoadedCandidates } = useCandidates();
  const { setActiveTabContext } = useTab();

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  const changeSettingsProfile = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={content}
          onChange={(key) => changeSettingsProfile(key)}
        />
      </S.Content>
    </S.Wrapper>
  );
};
