import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #161717;
  width: 336px;
  min-height: 53px;
  margin: 2px;
  padding: 4px;
  gap: 4px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

export const BodyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  gap: 24px;
`;

export const FavoriteIcon = styled.div`
  display: flex;
  cursor: pointer;
  width: 80px;
`;

export const TitleCopilot = styled.div`
  display: flex;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  color: #ffc000;
`;

export const Date = styled.div`
  display: flex;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: right;
  color: #e9e9f0;
`;

export const TitleChat = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #e9e9f0;

  > input {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: #0a0a0a;
  }
`;

export const Options = styled.div`
  cursor: pointer;
`;

export const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 120px;
  right: 24px;
  margin-top: -40px;
  padding: 4px;
  background-color: #0a0a0a;
  border-radius: 8px;
  z-index: 3000;
`;

export const DropdownItem = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #e9e9f0;
  padding: 2px;
  background-color: #0a0a0a;
  cursor: pointer;

  &:hover {
    background-color: #ffc000;
    color: #000000;
    border-radius: 4px;
  }
`;
