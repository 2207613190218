import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "assets/login/logo.svg";
import ImageCandidate from "assets/login/image-candidate.png";
import Text from "componentsNew/Text";
import Button from "componentsNew/Button";
import Checkbox from "componentsNew/CheckBox";
import Spin from "componentsNew/Spin";
import { Input } from "componentsNew/NewInput";
import { getItemStorage } from "utils/persistenceUtils";
import { AUTH_TOKEN } from "utils/constants";
import useLogin from "./useLogin";
import * as S from "./styles";

export const Login: React.FC = () => {
  const history = useNavigate();
  const {
    dataValue,
    rememberMe,
    loading,
    errors,
    handleChange,
    handleRememberMeChange,
    fetchLogin,
  } = useLogin();

  const { username, password } = dataValue;
  const auth = getItemStorage(AUTH_TOKEN);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      fetchLogin();
    }
  };

  useEffect(() => {
    if (auth) {
      const planFeatures = JSON.parse(
        localStorage.getItem("planFeatures") || "{}",
      );
      const planName = planFeatures?.name;

      if (planName === "Plano Político") {
        history("/dashboard/political-vox");
      } else {
        history("/dashboard/cockpit");
      }
    }
  }, [auth, history]);

  return (
    <S.Background>
      <S.Container>
        <S.Content>
          <div>
            <S.Card>
              <S.Image src={ImageCandidate} />
            </S.Card>
          </div>

          <div className="form-container">
            <S.FormContainer>
              <S.ImageLogo src={logo} alt="Logo Candidattus" />

              <S.StyledForm onKeyDown={handleKeyDown}>
                <div className="text-form">
                  <Text size="xSmall" color="gray">
                    Entre com e-mail e senha
                  </Text>
                </div>

                <div className="inputs">
                  <>
                    <Input
                      type="email"
                      id="username"
                      label="E-mail"
                      size="medium"
                      placeholder="E-mail"
                      value={username}
                      onChange={handleChange}
                      error={!!errors.username || !!errors.general}
                      data-cy="input-email"
                    />
                  </>
                  <Input
                    type="password"
                    id="password"
                    label="Senha"
                    size="medium"
                    placeholder="Senha"
                    value={password}
                    onChange={handleChange}
                    error={!!errors.password || !!errors.general}
                    data-cy="input-password"
                  />
                </div>

                <div className="error-message">
                  {errors.general && (
                    <Text color="red" size="small" bold>
                      * {errors.general}
                    </Text>
                  )}
                </div>

                <div className="check-forgot">
                  <Checkbox
                    id="rememberme"
                    name="rememberme"
                    type="checkbox"
                    label="Lembrar-me"
                    labelColor="WHITE_2"
                    labelFor="rememberMe"
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                    data-cy="checkbox-rememberme"
                  />
                </div>

                <Button
                  type="submit"
                  title="Entrar"
                  size="medium"
                  variant="primary"
                  fullWidth
                  disabled={!username || !password}
                  onClick={() => fetchLogin()}
                  data-cy="submit-login"
                >
                  Entrar
                </Button>
              </S.StyledForm>
            </S.FormContainer>
          </div>

          {loading && <Spin size="large" fullscreen />}
        </S.Content>
      </S.Container>
    </S.Background>
  );
};
