import { Tooltip } from '@mui/material'

import { noop } from 'lodash'
import { ChartData, ColumnData, RowData } from 'componentsNew/ChartMentionSection/types'

import * as S from './styles'

interface ChartMentionsProps {
  data?: ChartData | null
  changeMentions?: (mention: string) => void
  selectedMention?: string
}

const ChartMentions = ({ data, changeMentions = noop, selectedMention }: ChartMentionsProps) => {
  /*  const [mentionData, setMentionData] = useState(data?.mentionData) */
  const mentionData = data?.mentionData
  const totalCount = data?.total || 0
  const allCounts = data?.allCounts

  function totalValues(values: number[]): number {
    return values.reduce((acc, value) => acc + value, 0)
  }

  function normalizeValues(values: number[], totalCount: number): number[] {
    if (totalCount === 0) return values.map(() => 0)

    const logTotalCount = Math.log(totalCount + 1)

    const normalizedValues = values.map(value => {
      const logValue = Math.log(value + 1)
      return (logValue / logTotalCount) * 100
    })

    return normalizedValues
  }

  const handleMention = (mention: string) => {
    changeMentions(mention)
  }

  return (
    <S.Wrapper>
      {mentionData && (
        <>
          <S.HeaderBox>
            <Tooltip title="Uma menção pode estar em mais de uma categoria. Por isso o total de menções categorizadas pode ser maior que o número total de menções. ">
              <S.Heading>Total: {totalCount} menções categorizadas</S.Heading>
            </Tooltip>
            <S.WrapperIconsHeader>
              {mentionData?.column?.map((column: ColumnData, index: number) => (
                <Tooltip title={column.label} key={index} placement="top">
                  <S.IconWithLabel key={index}>
                    <S.IconHeaderBox>
                      <S.Icon src={column.icon} alt={column.label} />
                    </S.IconHeaderBox>
                    <p>{column.value}</p>
                  </S.IconWithLabel>
                </Tooltip>
              ))}
            </S.WrapperIconsHeader>
          </S.HeaderBox>

          {mentionData?.row?.map((row: RowData, index: number) => {
            const normalizedValues = normalizeValues(row.values, totalCount)
            return (
              <S.RowMentions
                className="row_mentions"
                key={index}
                borderColor={row.color || '#FFF'}
                onClick={() => handleMention(row?.label)}
                isActive={row?.label === selectedMention}
              >
                <S.WrapperText>
                  {row.icon && (
                    <S.IconBox>
                      <S.Icon src={row.icon} alt={row.label} />
                    </S.IconBox>
                  )}
                  <S.TextRowTitle>{row.label}</S.TextRowTitle>
                  <S.TextRow>{totalValues(row.values)}</S.TextRow>
                </S.WrapperText>

                <S.WrapperCircle>
                  {normalizedValues.map((normalizedValue, idx) => (
                    <S.CircleBox key={idx}>
                      <S.Circle size={normalizedValue} />
                    </S.CircleBox>
                  ))}
                </S.WrapperCircle>
              </S.RowMentions>
            )
          })}
        </>
      )}
    </S.Wrapper>
  )
}

export default ChartMentions
