import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Text from '../../../componentsNew/Text'
import Card from '../../../componentsNew/Card'

interface Estado {
  nome: string
  inicial: string
  value: any
}

const initialState: Estado[] = [
  { nome: 'Acre', inicial: 'AC', value: 0.01 }, // 0
  { nome: 'Alagoas', inicial: 'AL', value: 0.01 }, // 1
  { nome: 'Amapá', inicial: 'AP', value: 0.01 }, // 2
  { nome: 'Amazonas', inicial: 'AM', value: 0.01 }, // 3
  { nome: 'Bahia', inicial: 'BA', value: 0.01 }, // 4
  { nome: 'Ceará', inicial: 'CE', value: 0.01 }, // 5
  { nome: 'Distrito Federal', inicial: 'DF', value: 0.01 }, // 6
  { nome: 'Espírito Santo', inicial: 'ES', value: 0.01 }, // 7
  { nome: 'Goiás', inicial: 'GO', value: 0.01 }, // 8
  { nome: 'Maranhão', inicial: 'MA', value: 0.01 }, // 9
  { nome: 'Mato Grosso', inicial: 'MT', value: 0.01 }, // 10
  { nome: 'Mato Grosso do Sul', inicial: 'MS', value: 0.01 }, // 11
  { nome: 'Minas Gerais', inicial: 'MG', value: 0.01 }, // 12
  { nome: 'Pará', inicial: 'PA', value: 0.01 }, // 13
  { nome: 'Paraíba', inicial: 'PB', value: 0.01 }, // 14
  { nome: 'Paraná', inicial: 'PR', value: 0.01 }, // 15
  { nome: 'Pernambuco', inicial: 'PE', value: 0.01 }, // 16
  { nome: 'Piauí', inicial: 'PI', value: 0.01 }, // 17
  { nome: 'Rio de Janeiro', inicial: 'RJ', value: 0.01 }, // 18
  { nome: 'Rio Grande do Norte', inicial: 'RN', value: 0.01 }, // 19
  { nome: 'Rio Grande do Sul', inicial: 'RS', value: 0.01 }, // 20
  { nome: 'Rondônia', inicial: 'RO', value: 0.01 }, // 21
  { nome: 'Roraima', inicial: 'RR', value: 0.01 }, // 22
  { nome: 'Santa Catarina', inicial: 'SC', value: 0.01 }, // 23
  { nome: 'São Paulo', inicial: 'SP', value: 0.01 }, // 24
  { nome: 'Sergipe', inicial: 'SE', value: 0.01 }, // 25
  { nome: 'Tocantins', inicial: 'TO', value: 0.01 }, // 26
]

export const MapContainer = styled.div`
  width: 430px;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: transparent;
`

const transformStateDataForMap = (statesData: any) => {
  return statesData.map((state: any) => ({
    ...state,
    rgbaValue: `rgba(0, 123, 255, ${state.total_portion})`,
  }))
}

const SvgHandler = (): React.ReactElement => {
  return (
    <svg width="54" height="48" viewBox="0 0 54 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 24L13.5 0.617313H40.5L54 24L40.5 47.3827H13.5L0 24Z"
        fill="rgba(255, 204, 0, 0.8)"
        fillOpacity="0.8"
      />
    </svg>
  )
}

const ContainerSVG = ({ estados, handleStateClick, selectedState }: any) => {
  const isActive = selectedState?.inicial === estados?.inicial
  const isSelected = selectedState !== null

  return (
    <HexagonContainerNew
      value={estados?.value}
      onClick={() => handleStateClick(estados)}
      isSelected={isSelected}
      isActive={isActive}
    >
      <Text size="xSmall">{estados?.inicial}</Text>
      <SvgHandler />
    </HexagonContainerNew>
  )
}

const HexagonContainerNew = styled.button<any>`
  background: transparent;
  border: none;
  width: 54px;
  height: 48px;
  opacity: ${({ isActive, isSelected }) => !isActive && isSelected && 0.2};

  svg path {
    fill: ${({ value }) => value && `rgba(255, 204, 0, ${value + 0.04})`};
    cursor: pointer;
  }

  &:hover {
    svg path {
      fill: ${({ value }) => value && `rgba(255, 204, 0, ${value})`};
      cursor: pointer;
    }
  }

  p {
    position: absolute;
    margin-top: 16px;
    margin-left: 16px;
  }
`

const HexagonContainerTransparete = styled.div`
  width: 54px;
  height: 48px;
`

const CollBase = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 26px;
  margin-left: -7px;
`
const CollTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-left: -7px;
`

interface IMapaHexagonal {
  handleSelectedState: any
  selectedStateDefault: any
  statesData?: { acronym: string; total_portion: number }[]
}

const MapaHexagonal = ({ handleSelectedState, selectedStateDefault, statesData }: IMapaHexagonal) => {
  console.log('handleSelectedState, selectedStateDefault, statesData', statesData)
  const [selectedState, setSelectedState] = useState<Estado | null>(null)
  const [estadosAtualizados, setEstadosAtualizados] = useState<Estado[]>(initialState)

  useEffect(() => {
    const updatedEstados = initialState.map(estado => {
      const stateData = statesData?.find(sd => sd.acronym === estado.inicial)
      return {
        ...estado,
        value: stateData ? Math.min(stateData.total_portion * 5, 1) : estado.value,
      }
    })

    setEstadosAtualizados(updatedEstados)
  }, [statesData])

  const handleStateClick = (state: Estado) => {
    setSelectedState(state)
    handleSelectedState(state)
  }

  useEffect(() => {
    if (selectedStateDefault) {
      setSelectedState(selectedStateDefault)
    }
  }, [selectedStateDefault])

  return (
    <MapContainer>
      <Card title="Mapa nacional">
        <MapContainer>
          <CollTop>
            <HexagonContainerTransparete />
            <HexagonContainerTransparete />
            <ContainerSVG
              estados={estadosAtualizados[0]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
          </CollTop>
          <CollBase>
            <ContainerSVG
              estados={estadosAtualizados[22]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[3]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[21]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
          </CollBase>
          <CollTop>
            <HexagonContainerTransparete />
            <ContainerSVG
              estados={estadosAtualizados[13]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[26]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[10]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[11]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
          </CollTop>
          <CollBase>
            <ContainerSVG
              estados={estadosAtualizados[2]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[9]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[6]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[8]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[12]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[15]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[20]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
          </CollBase>
          <CollTop>
            <HexagonContainerTransparete />
            <HexagonContainerTransparete />
            <ContainerSVG
              estados={estadosAtualizados[5]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[17]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[7]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[24]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[23]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
          </CollTop>
          <CollBase>
            <HexagonContainerTransparete />
            <ContainerSVG
              estados={estadosAtualizados[19]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[16]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[4]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[18]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
          </CollBase>
          <CollTop>
            <HexagonContainerTransparete />
            <HexagonContainerTransparete />
            <ContainerSVG
              estados={estadosAtualizados[14]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
            <ContainerSVG
              estados={estadosAtualizados[25]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
          </CollTop>
          <CollBase>
            <HexagonContainerTransparete />
            <HexagonContainerTransparete />
            <ContainerSVG
              estados={estadosAtualizados[1]}
              handleStateClick={handleStateClick}
              selectedState={selectedState}
            />
          </CollBase>
        </MapContainer>
      </Card>
    </MapContainer>
  )
}

export default MapaHexagonal
