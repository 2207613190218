import styled, { keyframes, css } from 'styled-components'
import { motion } from 'framer-motion'
import theme from 'styles/theme'

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Wrapper = styled(motion.div)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  border: 1px solid ${theme.COLORS.gray10};
  border-radius: 12px;
  padding: 0 8px;
  column-gap: 8px;
`

export const ContentBase = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  column-gap: 16px;
`

export const ProfileContent = styled(ContentBase)`
  flex: 1.3;
`

export const AvatarBox = styled.div<{ isLoading?: boolean }>`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #111111;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  position: relative;
  z-index: 1;

  ${props =>
    props.isLoading &&
    css`
      &::before {
        content: '';
        width: 95%;
        height: 95%;
        position: absolute;

        border: 1px solid transparent;
        background: linear-gradient(
          0deg,
          rgba(255, 192, 0, 1) 0%,
          rgba(255, 192, 0, 0.3085609243697479) 10%,
          rgba(255, 192, 0, 0.20211834733893552) 90%,
          rgba(255, 192, 0, 1) 100%
        );
        border-image-slice: 1;
        border-radius: 50%;
        animation: ${spinAnimation} 2s linear infinite;
        animation-delay: 0.1s;
      }
    `}

  svg {
    color: ${theme.COLORS.gray10};
    width: 20px;
    height: 20px;
    z-index: 2;
  }
`

export const AvatarProfile = styled.img`
  width: 100%;
  object-fit: cover;
`
export const LabelBox = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    color: ${theme.COLORS.yellow10};
  }
`
export const Label = styled.h4`
  color: ${theme.COLORS.gray10};
  font-weight: 400;
  font-size: 14px;
`

export const WizardIcon = styled.img``

export const AttrContent = styled(ContentBase)`
  justify-content: center;
`

export const AttrText = styled.p`
  color: ${theme.COLORS.gray10};
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

export const AttrIconBox = styled.div``

export const ActionsContent = styled(ContentBase)`
  column-gap: 8px;
  justify-content: flex-end;
`
