import { Modal, ModalProps } from 'antd'

import React from 'react'
import * as S from './styles'

interface ModalVoxProps extends ModalProps {
  title?: string
  actionsBox?: React.ReactNode
  footerAlign?: 'start' | 'end' | 'center' | 'space'
}

export const ModalVox = ({ title, footer, footerAlign = 'space', actionsBox, children, ...rest }: ModalVoxProps) => {
  return (
    <Modal footer={null} centered closeIcon={null} {...rest} rootClassName="modal_vox">
      <S.Wrapper>
        <S.Header>
          <S.Title>{title}</S.Title>
        </S.Header>

        <S.ContentModal>{children}</S.ContentModal>

        <S.Footer align={footerAlign}>{actionsBox}</S.Footer>
      </S.Wrapper>
    </Modal>
  )
}
