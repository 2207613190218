import React from 'react'
import { Tabs } from 'antd'
import { useTab } from 'global/TabContext' // Ajuste o caminho conforme necessário
import type { TabsProps } from 'antd'
import { noop } from 'lodash'
import * as S from './styles'

interface TabsCustomProps {
  onChange?: (activeTab: string) => void
  labels: any[]
  content: React.ReactNode[]
  title?: string
  subtitle?: string
  activeTab?: string
}

const TabsCustom: React.FC<TabsCustomProps> = ({
  labels,
  title,
  subtitle,
  content,
  onChange = noop,
  activeTab: externalActiveTab,
}) => {
  const { activeTabContext, setActiveTabContext } = useTab()

  const handleChange = (activeKey: string) => {
    onChange(activeKey)
    setActiveTabContext(activeKey)
  }

  const items: TabsProps['items'] = labels.map((label, index) => {
    return {
      key: label?.key,
      label: (
        <S.WrapperText>
          <S.TabText withTitle={!!title} disabled={label?.disabled}>
            {title && (
              <>
                {title}
                <br />
              </>
            )}
            {label?.label}
            <br />
            {label?.sub_label}
          </S.TabText>
        </S.WrapperText>
      ),
      children:
        (externalActiveTab || activeTabContext) === label?.key ? <S.ContentTab>{content[index]}</S.ContentTab> : null,
      disabled: label?.disabled,
    }
  })

  return (
    <Tabs
      activeKey={externalActiveTab || activeTabContext}
      items={items}
      onChange={handleChange}
      style={{ width: '100%' }}
    />
  )
}

export default TabsCustom
