import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  height: 11.5rem;
  border-radius: 8px;
  background: #161717;
  top: 11.12rem;
  left: 17.75rem;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;

  .heading_content {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`

export const EditButton = styled.button<any>`
  background-color: ${({ theme, primary }) => (!primary ? '#161717' : theme.COLORS.BACKGROUND_YELLOW)};
  color: ${({ theme, primary }) => (primary ? '#000000' : theme.COLORS.BACKGROUND_YELLOW)};
  align-self: flex-end;
  border: none;
  border-radius: 12px;
  border: 1px solid #ffc000;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  font-size: 0.75rem;
`

export const IncioRoot = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: #e9e9f0;
  width: 8.75rem;
`

export const Label = styled.label`
  background-color: #181717;
  width: 100%;
  height: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  text-align: left;
  font-size: 0.75rem;
  color: #5c5c5c;
  font-family: Roboto;
`

export const DateInput = styled.input<any>`
  background: transparent;
  border: ${({ disabled }) => (disabled ? '1px solid #5C5C5C' : '1px solid #CECECE')};
  border-radius: 4px;
  color: ${({ disabled }) => (disabled ? '#5C5C5C' : '#CECECE')};
  height: 5rem;
  margin: 0.5rem 0;
  padding: 0.25rem 0.5rem;
  width: 13.12rem;
  font-family: 'Roboto', sans-serif;
  font-size: 1.12rem;
`

export const ContentDay = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const DaysCounter = styled.p`
  color: #ffc000;
  font-size: 1.5rem;
  font-weight: bold;
`

export const Day = styled.p`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #e9e9f0;
`
