import styled from 'styled-components'

export const Wrapper = styled.main`
  flex: 1;
  min-height: 200px;
  display: flex;
  flex-direction: column;
`

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

export const AvatarBx = styled.div`
  width: 39px;
  height: 39px;
  border-radius: 50%;
`
export const Avatar = styled.img``

export const LabelBox = styled.div``
export const Label = styled.h4`
  color: #e9e9f0;
  font-size: 16px;
  font-weight: 500;
`

export const Description = styled.p`
  span {
    font-weight: bold;
  }
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const RenderItem = styled.div`
  color: white;
  min-width: 300px;
  display: flex;
  align-items: center;

  &:hover {
    color: #ffc000;
  }
`
