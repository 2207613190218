import { formatter } from 'componentsNew/PoliticalListeningComponents/services/utils/formatterMentions'
import { apiMentions, apiElectoralIntelligence } from 'services/apiService'
import { buildQueryString } from 'utils/buildQueryString'

const BASE_API_URL = `/`
const BASE_URL = `/electoral-intelligence/political-listening`
const BASE_MAP_URL = `/mentions-map`

const defaultOrders: string[] = [
  'fb_count',
  'ig_count',
  'tt_count',
  'gg_count',
  'yt_count',
  'rd_count',
  // 'tumblr_count',
  'tl_count',
  'infra_count',
  'iot_count',
  'network_count',
  'devices_count',
]

interface filterMentionsParams {
  topic_name?: string
  initial_date?: string
  final_date?: string
  sentiment?: string
  city?: string
  id_cand?: string
  negative_words?: string[]
  positive_words?: string[]
  sub_topics_ids?: string[]
  topic_lvl?: string
  domain?: string
}

interface filterWordCloud {
  cd_city?: string
  candidate_id?: string
  mentions?: string
  date_start?: string
  end_date?: string
  sentiment?: string
  negative_words?: string[]
  positive_words?: string[]
  sub_topics_ids?: string[]
  topic_lvl?: string
  domain?: string
}

interface mentionByThemeTypes {
  cd_city?: string
  id_cand?: string
  mentions?: string
  date_start?: string
  sentiment?: string
  end_date?: string
  negative_words?: string[]
  positive_words?: string[]
  sub_topics_ids?: string[]
  domain?: string
}
interface filterSentiment {
  cd_city?: string
  candidate_id?: string
  mentions?: string
  date_start?: string
  sentiment?: string
  end_date?: string
  negative_words?: string[]
  positive_words?: string[]
  sub_topics_ids?: string[]
  domain?: string
}

interface filterMapTypes {
  city?: string
  id_cand?: string
  sentiment?: string
  initial_date?: string
  final_date?: string
  negative_words?: string[]
  positive_words?: string[]
  sub_topics_ids?: string[]
  domain?: string
}

interface ResponseWordCloud {
  freq: number
  value: number
  word: string
  text?: string
}

interface RequestOptions {
  signal?: AbortSignal
}

export const getTopMentions = async (params: filterMentionsParams, options: RequestOptions = {}) => {
  try {
    const url = `/top-mentions/${buildQueryString(params)}`
    const { data } = await apiMentions.get(url, {
      signal: options.signal,
    })
    return data
  } catch (e) {
    console.error(e)
    return e
  }
}

export const getWordCloud = async (
  params: filterWordCloud,
  options: RequestOptions = {}
): Promise<ResponseWordCloud[] | { message: string }> => {
  try {
    const url = `/wordcloud${buildQueryString(params)}`
    const { data } = await apiElectoralIntelligence.get(url, {
      signal: options.signal,
    })
    const formatterData = (data || []).map((wordData: ResponseWordCloud) => ({
      ...wordData,
      text: wordData.word,
    }))

    return formatterData
  } catch (e) {
    return { message: 'Erro ao buscar word' }
  }
}
export const getSentiment = async (params: filterSentiment, options: RequestOptions = {}) => {
  try {
    const url = `/sentiment-analysis${buildQueryString(params)}`
    const { data } = await apiElectoralIntelligence.get(url, {
      signal: options.signal,
    })

    const state = {
      series: data.series,
      options: {
        chart: {
          id: 'basic-bar',
        },
        colors: ['#00E23F', '#FF0000', '#FFFFFF'],
        xaxis: {
          categories: data.dates,
        },
      },
    }
    return state
  } catch (e) {
    console.log(e)
    return e
  }
}
const extractCountNumbers = (data: any) => {
  const countNumbers: number[] = []

  data.forEach((item: any) => {
    Object.keys(item).forEach(key => {
      if (key.includes('count')) {
        countNumbers.push(item[key])
      }
    })
  })

  return countNumbers
}
export const getMentionsByTopic = async (
  params: filterWordCloud,
  options: RequestOptions = {}
): Promise<any | { message: string }> => {
  try {
    const { data } = await apiMentions.get(`/mentions-by-topic/${buildQueryString(params)}`, {
      signal: options.signal,
    })
    if (data?.mentions_by_topic.length > 0) {
      const mentionData = formatter(data?.mentions_by_topic, defaultOrders)
      const total = data.total_count
      const allCounts = extractCountNumbers(data?.mentions_by_topic)

      return {
        allCounts,
        total,
        mentionData,
      }
    }

    return data
  } catch (e) {
    console.log(e)
    return { message: 'Erro ao buscar mentionsByTopic' }
  }
}

export const getMentionsByTheme = async (
  params: mentionByThemeTypes,
  options: RequestOptions = {}
): Promise<any | { message: string }> => {
  try {
    const { data } = await apiMentions.get(`/mentions-by-theme/${buildQueryString(params)}`, {
      signal: options.signal,
    })
    if (data?.mentions_by_theme.length > 0) {
      const mentionData = formatter(data?.mentions_by_theme, defaultOrders)
      const total = data.total_count
      const allCounts = extractCountNumbers(data?.mentions_by_theme)

      return {
        allCounts,
        total,
        mentionData,
      }
    }

    return data
  } catch (e) {
    console.log(e)
    return { message: 'Erro ao buscar mentionsByTopic' }
  }
}

export const getMentionsByTopicN3 = async (
  params: mentionByThemeTypes,
  options: RequestOptions = {}
): Promise<any | { message: string }> => {
  try {
    const { data } = await apiMentions.get(`/mentions-count-by-lvl-2${buildQueryString(params)}`, {
      signal: options.signal,
    })

    const topic_list = data || []
    const formattData = topic_list?.map((theme: any) => ({
      count: theme?.count_lvl_2,
      label: theme?.topic_lvl_2_name,
    }))

    return formattData
  } catch (e) {
    console.log(e)
    return { message: 'Erro ao buscar mentionsByTopic3' }
  }
}

export async function getMapData(params: filterMapTypes, options: RequestOptions = {}) {
  try {
    const url = `/mentions-map/${buildQueryString(params)}`
    const { data } = await apiMentions.get(url, {
      signal: options.signal,
    })

    const formattedData = data.mentions_list
      ?.map((pointer: any) => ({
        ...pointer,
        name: pointer.city,
        total_votes: pointer.total_count,
      }))
      .filter((point: any) => point.latitude !== 'None')

    return formattedData
  } catch (e) {
    console.error('Erro na requisição:', e)
    return { message: 'Não foi possível buscar menções.' }
  }
}

export async function getTotalMentions(params: filterMapTypes, options: RequestOptions = {}) {
  try {
    const url = `/mentions-total-counter/${buildQueryString(params)}`
    const { data } = await apiMentions.get(url, {
      signal: options.signal,
    })

    console.log('total', data)

    return data
  } catch (e) {
    console.error('Erro na requisição:', e)
    return { message: 'Não foi possível buscar menções.' }
  }
}
