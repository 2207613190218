import { FormDataTypes } from 'componentsNew/FormGenerator'

export const menuOptions = [
  {
    label: 'Assessor Político',
    value: 7,
  },
  {
    label: 'Candidato Político',
    value: 2,
  },
  {
    label: 'Consultoria',
    value: 3,
  },
  {
    label: 'Estrategista',
    value: 4,
  },
  {
    label: 'Financiadores de Campanhas',
    value: 5,
  },
  {
    label: 'Marketing',
    value: 6,
  },
]

export const formDataDefault: FormDataTypes = {
  social_security_number: {
    type: 'text',
    inputType: 'text',
    label: 'CPF',
    mask: '999.999.999-99',
    placeholder: 'Digite um CPF valido',
    required: true,
  },
  name: {
    type: 'text',
    inputType: 'text',
    label: 'Nome',
    placeholder: 'Nome',
    required: true,
  },

  date_of_birth: {
    type: 'text',
    inputType: 'text',
    label: 'Data de Nascimento',
    mask: '99/99/9999',
    placeholder: 'Data de Nascimento',
    required: true,
  },

  email: {
    type: 'text',
    inputType: 'text',
    label: 'E-mail',
    placeholder: 'digite um email válido.',

    required: true,
  },

  password: {
    type: 'password',
    inputType: 'text',
    label: 'Senha',
    required: true,
    placeholder: 'Senha',
  },

  profile: {
    inputType: 'select',
    type: 'select',
    label: 'Perfil Politico',
    placeholder: 'Qual o seu perfil?',

    options: menuOptions,
  },

  phone: {
    type: 'text',
    inputType: 'text',
    label: 'Telefone',
    mask: '(99) 9 9999-9999',
    placeholder: 'telefone do candidato',

    required: false,
  },

  party: {
    inputType: 'select',
    label: 'Partido',
    placeholder: 'Partido',
    required: false,
    options: [],
  },
  /*   party_acronym: {
    type: 'text',
    inputType: 'text',
    label: 'Acrônimo do Partido',
    placeholder: 'Acrônimo do Partido',
    required: false,
  }, */
  position: {
    inputType: 'select',
    label: 'Posição atual',
    placeholder: 'Posição atual',
    required: false,
    options: [],
  },
  locality: {
    type: 'text',
    inputType: 'text',
    label: 'Localidade',
    placeholder: 'Localidade',
    required: false,
  },
  address: {
    type: 'text',
    inputType: 'text',
    label: 'Endereço',
    placeholder: 'Endereço',
    required: false,
  },

  target_position: {
    inputType: 'select',
    label: 'Posição Alvo',
    placeholder: 'Posição Alvo',
    required: false,
    options: [],
  },
  target_year: {
    type: 'text',
    inputType: 'text',
    mask: '9999',
    label: 'Ano Alvo',
    placeholder: 'Ano Alvo',
    required: false,
  },
  target_locality: {
    type: 'text',
    inputType: 'text',
    label: 'Localidade Alvo',
    placeholder: 'Localidade Alvo',
    required: false,
  },

  /*   election_date: {
    type: 'text',
    inputType: 'text',
    label: 'Data da Eleição',
    placeholder: 'Data da Eleição',
    required: false,
  }, */
  /*   user: {
    type: 'text',
    inputType: 'text',
    label: 'Usuário',
    placeholder: 'Usuário',
    required: false,
  }, */
  is_pep: {
    inputType: 'checkbox',
    label: 'É Pessoa Exposta Politicamente?',
    placeholder: 'É Pessoa Exposta Politicamente?',
    required: false,
    fullWidth: true,
  },
  has_government_occupation: {
    inputType: 'checkbox',
    label: 'Tem Cargo Governamental?',
    placeholder: 'Tem Cargo Governamental?',
    required: false,
    fullWidth: true,
  },
}
