import { apiMentions, apiPortifolio, apiUser } from "services/apiService";

import { handleServiceColor } from "utils/utilityFunctions";
import { toast } from "react-toastify";
import {
  PayloadToCreateFilter,
  PutFastLoadParamsTypes,
} from "../types/overViewTypes";

type ErrorMessage = { message: string };

type AllFilterResponse = any | ErrorMessage;
type FilterResponse = any | ErrorMessage;
type CreateFilterResponse = any | ErrorMessage;
type CandidateListResponse = any | ErrorMessage;
type AllTopicListResponse = any | ErrorMessage;
type AllThemesByTopicResponse = any | ErrorMessage;

export const getAllFilters = async (): Promise<AllFilterResponse> => {
  try {
    const { data } = await apiUser.get(
      "/electoral-intelligence/political-listening-filters/",
    );

    return data?.results;
  } catch (e) {
    console.log("Erro na busca dos filtros: ", e);
    return { message: "Erro na busca dos filtros" };
  }
};

export const getFilter = async (
  filter_id: number | string,
): Promise<FilterResponse> => {
  try {
    const { data } = await apiUser.get(
      `/electoral-intelligence/political-listening-filters/${filter_id}`,
    );

    return data?.results;
  } catch (e) {
    console.log("Erro na busca do filtro solicitado: ", e);
    return { message: "Erro na buscca do filtro solicitado" };
  }
};

export const getTopicList = async (): Promise<AllTopicListResponse> => {
  try {
    const { data } = await apiMentions.get("/topics/?topic_lvl=1");

    const formattedTopic = data?.map((item: any) => {
      const newItem = handleServiceColor(item?.name || "");

      return {
        ...item,
        ...newItem,
      };
    });

    return formattedTopic;
  } catch (e) {
    console.log("Erro ao buscar lista de topicos: ", e);
    return { message: "Erro ao buscar lista de topicos" };
  }
};

export const getThemesByTopic = async (
  topic: string,
): Promise<AllThemesByTopicResponse> => {
  try {
    const { data } = await apiMentions.get(`/topics/?parent_id=${topic}`);
    return data;
  } catch (e) {
    console.log("Erro ao buscar lista de topicos: ", e);
    return { message: "Erro ao buscar lista de topicos" };
  }
};

export const createFilter = async (
  params: PayloadToCreateFilter,
): Promise<CreateFilterResponse> => {
  try {
    if (!params.title) {
      throw new Error("para salvar o filtro é necessario adicionar um Titulo");
    }
    if (!params.description) {
      throw new Error(
        "para salvar o filtro é necessario adicionar uma Descrição",
      );
    }
    /*    if(params.themes.length === 0){
            throw new Error('para salvar o filtro é necessario adicionar um tema')
        } */

    const { data } = await apiUser.post(
      `/electoral-intelligence/political-listening-filters/`,
      {
        ...params,
        /*  themes: params.themes.join(','), */
        /* topics: params.topics.join(','), */
      },
    );

    return data;
  } catch (e) {
    console.log("Erro na buscca do filtro solicitado: ", e);
    return { message: e };
  }
};

export const deleteFilter = async ({ id }: { id: number }) => {
  try {
    await apiUser.delete(
      `/electoral-intelligence/political-listening-filters/${id}`,
    );
    toast.success("Filtro excluído com sucesso");
  } catch (e) {
    console.log(e);
  }
};

export const getCandidateList = async (
  person: number,
): Promise<CandidateListResponse> => {
  try {
    const { data } = await apiUser.get(
      `/electoral-intelligence/fast-search-candidates/`,
    );

    const candidates = data?.results[0]?.candidates || [];

    const imageRequests = candidates.map(async (candidate: any) => {
      const candidateId = candidate.candidate_id || candidate.id_cand;
      const { data: imageData } = await apiPortifolio.get(
        `/candidate-portfolio/candidate/${candidateId}/image`,
      );
      return {
        candidateId,
        avatar: imageData?.image_url,
      };
    });

    const avatarData = await Promise.all(imageRequests);

    const formatterData = {
      fastListId: data?.results[0]?.id,
      candidateList: candidates.map((cand: any, index: number) => ({
        ...cand,
        label: cand?.candidate_name,
        value: cand?.id,
        avatar: avatarData[index]?.avatar,
      })),
    };

    return formatterData;
  } catch (e) {
    console.log("Erro na busca da lista de candidatos: ", e);
    return { message: "Erro na busca da lista de candidatos" };
  }
};

export const createCandidate = async ({
  payload,
  candidateList,
  fastListId,
  person,
}: PutFastLoadParamsTypes): Promise<CreateFilterResponse> => {
  try {
    const { data } = await apiUser.post(
      `/electoral-intelligence/filtered-candidates/`,
      {
        ...payload,
      },
    );
    const { data: apiImage } = await apiPortifolio.get(
      `/candidate-portfolio/candidate/${data?.candidate_id || data?.id_cand}/image`,
    );
    const newCandidateList = candidateList
      .map((candidate: any) => Number(candidate?.id))
      .filter((id: number) => Number(id) || id !== Number(data?.id))
      .concat(Number(data?.id));

    if (!fastListId) {
      const newList = await apiUser.post(
        `/electoral-intelligence/fast-search-candidates/`,
        {
          candidates: newCandidateList,
          person,
        },
      );

      const newId = 1;
      const newData = {
        ...data,
        avatar: apiImage?.image_url,
      };

      return newData;
    }

    await apiUser.put(
      `/electoral-intelligence/fast-search-candidates/${fastListId}/`,
      {
        candidates: newCandidateList,
        person: payload?.person,
      },
    );

    const newData = {
      ...data,
      avatar: apiImage?.image_url,
    };

    return newData;
  } catch (e) {
    console.log("Erro na busca do filtro solicitado: ", e);
    return { message: "Erro na busca do filtro solicitado" };
  }
};

export const removeCandidate = async ({
  person,
  candidateList,
  fastListId,
}: {
  person: number;
  candidateList: number[];
  fastListId?: number;
}): Promise<CreateFilterResponse> => {
  try {
    /*  const newCandidateList = candidateList
        .map((candidate: any) => Number(candidate?.id)) // Converter IDs para número
        .filter((id: number) => Number(id) || id !== Number(data?.id)) // Filtrar IDs semelhantes e o novo ID
        .concat(Number(data?.id)); */

    const addFastCandidate = await apiUser.put(
      `/electoral-intelligence/fast-search-candidates/${fastListId}/`,
      {
        person,
        candidates: candidateList,
      },
    );
  } catch (e) {
    console.log("Erro na buscca do filtro solicitado: ", e);
  }
};
