import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .title-totals-positions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-totals-positions {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 48px;
    margin-right: 16px;
  }

  .text-card {
    min-width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .map-list {
    display: flex;

    .collapse-minimal {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
`
