import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ISetGoals } from "components/SetGoals/ISetGoals";
import Spin from "componentsNew/Spin";
import SetGoals from "components/SetGoals";
import CardValidVotes from "components/CardGoals";
import { useCandidates } from "hooks/useCandidates";
import useGoalsExpenses from "./useGoalsExpenses";
import { Container } from "./styles";

export const GoalsExpenses: React.FC = () => {
  const { dataValue, handleChange } = useGoalsExpenses();
  const { isLoadedCandidates } = useCandidates();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (error) {
      toast.error(errorMessage);
    }
  }, [error, errorMessage]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  if (!dataValue) return null;

  const handleIndividualChange = (value: any, key: keyof ISetGoals) => {
    handleChange(key, value);
  };

  const handleError = (hasError: boolean, message: string) => {
    setError(hasError);
    setErrorMessage(message);
  };

  const title = "Despesas (R$)";
  const subtitleData = {
    week: "R$ / semana",
    geral: "R$",
  };

  return (
    <Container>
      <SetGoals
        dataValue={dataValue}
        onGoalsChange={handleChange}
        endpoint="expenses"
      />
      <CardValidVotes
        data={dataValue}
        title={title}
        subtitle={subtitleData}
        handleChange={handleIndividualChange}
        onError={handleError}
      />
    </Container>
  );
};
