import styled, { css } from 'styled-components'
import React from 'react'

interface ITextProps {
  children: React.ReactNode
  size?: 'small' | 'xSmall' | 'medium' | 'large' | 'xlarge'
  bold?: boolean
  color?: 'white' | 'black' | 'gray' | 'green' | 'red' | 'yellow'
  secondary?: boolean
}

const CUSTOM_SIZE = {
  small: css`
    font-size: ${({ theme }) => theme.FONT_SIZE.SMALL};
  `,
  xSmall: css`
    font-size: ${({ theme }) => theme.FONT_SIZE.X_SMALL};
  `,
  medium: css`
    font-size: ${({ theme }) => theme.FONT_SIZE.MEDIUM};
  `,
  large: css`
    font-size: ${({ theme }) => theme.FONT_SIZE.LARGE};
  `,
  xlarge: css`
    font-size: ${({ theme }) => theme.FONT_SIZE.X_LARGE};
  `,
}

const CUSTOM_COLOR = {
  white: css`
    color: ${({ theme }) => theme.COLORS.WHITE};
  `,
  black: css`
    color: ${({ theme }) => theme.COLORS.OPTION_DARK};
  `,
  gray: css`
    color: ${({ theme }) => theme.COLORS.TEXT_FORM};
  `,
  green: css`
    color: ${({ theme }) => theme.COLORS.GREEN};
  `,
  red: css`
    color: ${({ theme }) => theme.COLORS.ALERT};
  `,
  yellow: css`
    color: ${({ theme }) => theme.COLORS.yellow20};
  `,
}

export const ContainerText = styled.p<ITextProps>`
  /*  word-break: break-word; */
  color: ${({ theme }) => theme.COLORS.TEXT_FORM};
  font-weight: ${({ theme, bold }) => (bold ? theme.FONT_WEIGHT.BOLD : theme.FONT_WEIGHT.REGULAR)};
  ${({ size }) => CUSTOM_SIZE[size || 'small']};
  ${({ color }) => CUSTOM_COLOR[color || 'white']};
  font-family: ${({ theme, secondary }) => (!secondary ? theme.FONT_FAMILY.PRIMARY : theme.FONT_FAMILY.SECONDARY)};
`
