import { useState, useEffect } from "react";
import { useForm, UseFormReset } from "react-hook-form";
import { apiAdvisor } from "pages/SettingsProfile/api/apiService";
import { IBiographyData } from "../types/typesConfig";

const useBiographyData = (
  idAdvisor: string,
  reset: UseFormReset<IBiographyData>,
) => {
  const [biographyData, setBiographyData] = useState<IBiographyData>({
    bio: "",
  });

  useEffect(() => {
    const fetchBiography = async () => {
      try {
        const response = await apiAdvisor.get(
          `/advisor/v1/advisors/${idAdvisor}`,
        );
        setBiographyData(response.data);
        reset(response.data);
      } catch (error) {
        console.error("Failed to fetch biography data:", error);
      }
    };

    fetchBiography();
  }, [idAdvisor, reset]);

  const updateBiography = async (data: IBiographyData) => {
    try {
      await apiAdvisor.put(`/advisor/v1/advisors/${idAdvisor}`, data);
      setBiographyData(data);
    } catch (error) {
      console.error("Failed to update biography data:", error);
    }
  };

  return { biographyData, updateBiography };
};

export default useBiographyData;
