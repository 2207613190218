interface Candidate {
  id: number
  id_cand: string
  cand_name: string
  party: string
  is_recommended: boolean
  is_listening: boolean
  topic: string
  created: string
  updated: string
  person: number
}

export function orderByCandidateName(candidates: Candidate[]): any[] {
  return candidates.slice().sort((a, b) => {
    const nameA = a.cand_name.toUpperCase()
    const nameB = b.cand_name.toUpperCase()

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
}
