import { PiFolderOpenFill } from 'react-icons/pi'
import { ReactComponent as IconCopilotWhite } from '../../../../assets/helper/icon-copilot-white.svg'

const PiFolderOpenFillIcon = () => {
  return <PiFolderOpenFill size={32} color="white" />
}

export const mapSidebarCopilot = [
  {
    name: 'Analista',
    type: 'analyst',
    title: `Transforme sua candidatura com nossa I.A. especializada em criar análises eficazes e alcance seus objetivos.`,
    icon: IconCopilotWhite,
    filter: false,
    path: '',
    disabled: false,
  },
  {
    name: 'Estrategista',
    type: 'strategist',
    icon: IconCopilotWhite,
    title: `Transforme sua candidatura com nossa I.A. especializada em criar estratégias eficazes e alcance seus objetivos.`,
    filter: false,
    path: '',
    disabled: false,
  },
  {
    name: 'Pesquisador',
    type: 'researcher',
    icon: IconCopilotWhite,
    title: `Transforme sua candidatura com nossa I.A. especializada em criar pesquisas eficazes e alcance seus objetivos.`,
    filter: false,
    path: '',
    disabled: true,
  },
  {
    name: 'Consultor',
    type: 'consultant',
    icon: IconCopilotWhite,
    title: `Transforme sua candidatura com nossa I.A. especializada em consultorias eficazes e alcance seus objetivos.`,
    filter: false,
    path: '',
    disabled: true,
  },
  {
    name: 'Marketing Digital',
    type: 'marketing',
    title: `Transforme sua candidatura com nossa I.A. especializada em marketing digital e alcance seus objetivos.`,
    icon: IconCopilotWhite,
    filter: false,
    path: '',
    disabled: false,
  },
  {
    name: 'Histórico',
    type: 'historical',
    icon: PiFolderOpenFillIcon,
    filter: false,
  },
]
