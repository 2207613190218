import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from '@mui/material'
import { IoChevronUpOutline } from 'react-icons/io5'
import { Container, ContainerMaster, Divisor, Wrapper } from './styles'
import Text from '../Text'

interface ICustomCollapse {
  title: string
  uf?: string
  children: React.ReactNode
  totals: { position: string; value: number }[]
  isActiveDefault: boolean
  onChande: (state: string) => void
  selectedStateDefault?: any
  onClick?: () => void
}

interface ITotal {
  position: string
  value: number
}

interface ITotalsMap {
  [key: string]: ITotal
}

const CustomCollapse = ({
  children,
  title,
  uf,
  totals,
  isActiveDefault,
  onChande,
  selectedStateDefault,
  onClick,
}: ICustomCollapse) => {
  const [isActive, setIsActive] = useState(isActiveDefault)
  const containerRef = useRef<HTMLDivElement>(null)

  console.log('values totals', totals)

  const toggleIcon = () => {
    setIsActive(!isActive)
    if (!isActive) {
      onChande({ ...selectedStateDefault, inicial: uf })
    } else {
      onChande({ ...selectedStateDefault, inicial: '' })
    }
  }

  /*   setTimeout(() => {
    containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, 100) */

  useEffect(() => {
    setIsActive(isActiveDefault || selectedStateDefault?.inicial === uf)

    if (isActiveDefault || selectedStateDefault?.inicial === uf) {
      onClick?.()
    }
  }, [isActiveDefault, selectedStateDefault])

  /* useEffect(() => {
    if (isActive) {
      const timer = setTimeout(() => {
        containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }, 300)
      return () => clearTimeout(timer)
    }
  }, [isActive]) */

  const ensurePositions = (totals: any[], positions: string[]) => {
    const normalizedPositions = positions.map(position => position.toUpperCase())

    const totalsMap: ITotalsMap = {}
    totals.forEach(total => {
      const normalizedPosition = total.position.toUpperCase()
      totalsMap[normalizedPosition] = total.value
    })

    return normalizedPositions.map(position => ({
      position,
      value: totalsMap[position] ?? 0,
    }))
  }

  const positionsToEnsure = ['Prefeito', 'Vice-Prefeito', 'Vereador']

  const processedTotals = ensurePositions(totals, positionsToEnsure)

  console.log('processedTotals', processedTotals)

  return (
    <ContainerMaster>
      <Container
        onClick={() => {
          onClick?.()
          toggleIcon()
        }}
      >
        <Divisor isActive={isActive} />
        <Wrapper isActive={isActive}>
          <div className="card-title-primary">
            <Text size="xSmall" bold>
              {title}
            </Text>
            <Text size="xSmall" bold>
              ({uf})
            </Text>
          </div>
          <div className="card-text">
            {processedTotals.map((total, index) => (
              <div className="card-itens" key={index}>
                <Tooltip title={total.position} placement="left-start" arrow>
                  <span>
                    <Text size="small">{total.value.toString()}</Text>
                  </span>
                </Tooltip>
              </div>
            ))}
          </div>

          <IoChevronUpOutline />
        </Wrapper>
      </Container>
      {isActive && <div className="contante">{children}</div>}
    </ContainerMaster>
  )
}

export default CustomCollapse
