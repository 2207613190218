import React from "react";
import WordcloudCand from "componentsNew/Wordcloud";
import Spin from "componentsNew/Spin";
import { handleMapColor } from "utils/utilityFunctions";
import LockedOverlay from "componentsNew/LockedOverlay";
import { Empty } from "componentsNew/Empty";
import ChartMentions from "./components/ChartMentions";

import * as S from "./styles";
import { ChartData } from "./types";

export interface IWordData {
  text: string;
  value: number;
}

interface ChartMentionSectionProps {
  words: IWordData[];
  chartMentions?: ChartData | null;
  title: string;
  handleSubtopic?: (mention: string) => void;
  selectedMention?: string;
  colors?: string[];
  isLoadingDefault?: boolean;
  disabled_card?: boolean;
  loadingMention?: boolean;
  word_sizes?: {
    width?: number;
    height?: number;
  };
  loadingChart?: boolean;
}

const ChartMentionSection = ({
  words,
  chartMentions,
  title,
  handleSubtopic,
  selectedMention,
  colors,
  isLoadingDefault,
  disabled_card = false,
  loadingMention,
  word_sizes = { width: 400, height: 260 },
  loadingChart,
}: ChartMentionSectionProps) => {
  const filterMentions = "";

  return (
    <S.Section disabled_card={disabled_card}>
      {disabled_card && <LockedOverlay isOpen={disabled_card} />}
      <S.ContentSection>
        <S.Box>
          <S.Heading>{title}</S.Heading>
          <S.HorizontalSection>
            <S.Box
              style={{ minWidth: "20%", maxWidth: "40%" }}
              className="centered_div-empty"
            >
              {isLoadingDefault ? (
                <S.LoadingBox>
                  <Spin spinning />
                </S.LoadingBox>
              ) : (
                <>
                  {words && words.length > 0 ? (
                    <WordcloudCand
                      words={words}
                      width={word_sizes.width}
                      height={word_sizes.height}
                      colors={colors || handleMapColor(selectedMention || "")}
                    />
                  ) : (
                    <div className="centered_div-empty">
                      <Empty />
                    </div>
                  )}
                </>
              )}
            </S.Box>
            <S.Box style={{ flex: "1.5" }}>
              {loadingChart ? (
                <div className="centered_div-empty">
                  <Spin spinning />
                  <p>Carregando...</p>
                </div>
              ) : (
                <>
                  {chartMentions ? (
                    <ChartMentions
                      data={chartMentions}
                      changeMentions={handleSubtopic}
                    />
                  ) : (
                    <div className="centered_div-empty">
                      <Empty />
                    </div>
                  )}
                </>
              )}
            </S.Box>
          </S.HorizontalSection>
        </S.Box>
      </S.ContentSection>
    </S.Section>
  );
};

export default ChartMentionSection;
