import React from "react";
import { toast } from "react-toastify";
import { SectionTimeLine } from "componentsNew/CockpitComponents/Sections/SectionTimeLine";
import Spin from "componentsNew/Spin";
import TimeCardStart from "components/TimeCardStart";
import { useGoalsTimeLine } from "./useGoalsTimeLine";
import { useCandidates } from "hooks/useCandidates";
import calculateDaysUntil from "utils/dateUtils";
import { Container, Content, InputsDates } from "./styes";

export const GoalsTimeLine: React.FC = () => {
  const { isLoadedCandidates } = useCandidates();
  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }
  const {
    timeCardStart,
    handleChangeTimeCardStart,
    loading,
    error,
    errorMessage,
  } = useGoalsTimeLine();

  const { initial_date, pre_campaign_date, campaign_date, election_fund_date } =
    timeCardStart;
  const defaultStartDisabled = "2023-10-07";
  const defaultEndDisabled = "2024-10-06";

  const daysUntilElectionForInitialDate = calculateDaysUntil(initial_date);
  const daysUntilElectionForPreCampaign = calculateDaysUntil(pre_campaign_date);
  const daysUntilElectionForCampaign = calculateDaysUntil(campaign_date);
  const daysUntilElectionForElectionFund =
    calculateDaysUntil(election_fund_date);

  if (error) {
    toast.error(errorMessage);
  }

  return (
    <Container>
      <Content>
        <InputsDates>
          <TimeCardStart
            initialDate={initial_date}
            daysUntilElection={daysUntilElectionForInitialDate}
            title="Início"
            slug="initial_date"
            onChange={handleChangeTimeCardStart}
            error
            helperText={errorMessage}
            minMaxDate={{
              min: defaultStartDisabled,
              max: pre_campaign_date || defaultEndDisabled,
            }}
          />
          <TimeCardStart
            defaultDisabled={!initial_date}
            initialDate={pre_campaign_date}
            daysUntilElection={daysUntilElectionForPreCampaign}
            title="Pré-Campanha"
            slug="pre_campaign_date"
            onChange={handleChangeTimeCardStart}
            error
            helperText={errorMessage}
            minMaxDate={{
              min: initial_date,
              max: campaign_date || defaultEndDisabled,
            }}
          />
          <TimeCardStart
            defaultDisabled={!pre_campaign_date}
            initialDate={campaign_date}
            daysUntilElection={daysUntilElectionForCampaign}
            title="Campanha"
            slug="campaign_date"
            onChange={handleChangeTimeCardStart}
            error
            helperText={errorMessage}
            minMaxDate={{
              min: pre_campaign_date,
              max: election_fund_date || defaultEndDisabled,
            }}
          />
          <TimeCardStart
            initialDate={election_fund_date}
            daysUntilElection={daysUntilElectionForElectionFund}
            title="Fundo Eleitoral"
            slug="election_fund_date"
            onChange={handleChangeTimeCardStart}
            error
            helperText={errorMessage}
            minMaxDate={{
              min: campaign_date,
              max: defaultEndDisabled,
            }}
          />
        </InputsDates>
        {!loading && <SectionTimeLine />}
      </Content>
    </Container>
  );
};
