import React from "react";
import * as S from "./styles";

interface IconActiveProps {
  icon?: React.ReactNode;
  image?: string;
  active: boolean;
  anyActive: boolean;
  onToggle?: () => void;
  disabled?: boolean;
}

const IconActive = ({
  active,
  icon,
  image,
  anyActive,
  onToggle,
  disabled = false,
}: IconActiveProps) => {
  return (
    <S.IconWrapper
      active={active}
      anyActive={anyActive}
      onClick={onToggle}
      disabled={disabled}
    >
      {icon || (
        <S.Icon
          src={image}
          alt={`icone ${image}`}
          active={active}
          anyActive={anyActive}
        />
      )}
    </S.IconWrapper>
  );
};

export default IconActive;
