import styled from 'styled-components'
import theme from 'styles/theme'

export const ContainerCard = styled.div<{ color: string; fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '296px')};
  max-width: ${({ fullWidth }) => (fullWidth ? 'unset' : '330px')};
  height: 100%;
  min-height: 100px;
  border: 2px solid;
  border-color: ${({ color }) => color};
  border-radius: 8px;
  overflow: hidden;
  background: #161717;
  display: flex;

  .iconHeader {
    margin-bottom: 8px;
  }

  .mark {
    width: 8px;
    background: ${({ color }) => color};
  }

  .wrapper {
    padding: 8px;
    width: 100%;
  }

  .header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .title {
    display: flex;
    align-items: center;

    .icon-default {
      width: 24px;
      height: 24px;
      background: #a19d9d;
      color: #161717;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      border-radius: 50%;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .icon {
    width: 24px;
  }

  .mentions {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 8px 8px 8px -8px;
  }
`

export const TextMore = styled.p`
  font-size: 13px;
  color: ${theme.COLORS.gray10};
  cursor: pointer;
`
