import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ISetGoals } from "components/SetGoals/ISetGoals";
import SetGoals from "components/SetGoals";
import CardValidVotes from "components/CardGoals";
import useGoalsValidVotes from "./useGoalsValidVotes";
import Spin from "componentsNew/Spin";
import { useCandidates } from "hooks/useCandidates";
import { Container } from "./styles";

export const GoalsValidVotes: React.FC = () => {
  const { dataValue, handleChange } = useGoalsValidVotes();
  const { isLoadedCandidates } = useCandidates();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (error) {
      toast.error(errorMessage);
    }
  }, [error, errorMessage]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  if (!dataValue) return null;

  const handleIndividualChange = (value: any, key: keyof ISetGoals) => {
    const updatedGoals = { ...dataValue, [key]: value };
    handleChange(key, value);
  };

  const handleError = (hasError: boolean, message: string) => {
    setError(hasError);
    setErrorMessage(message);
  };

  const title = "Votos válidos (Eleitores)";
  const subtitleData = {
    week: "Eleitores / semana",
    geral: "Eleitores",
  };

  return (
    <Container>
      <SetGoals
        dataValue={dataValue}
        onGoalsChange={handleChange}
        endpoint="valid-votes"
      />
      <CardValidVotes
        data={dataValue}
        title={title}
        subtitle={subtitleData}
        handleChange={handleIndividualChange}
        onError={handleError}
      />
    </Container>
  );
};
