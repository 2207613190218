import AreaChart from 'componentsNew/AreaChart'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { CardMentions } from '../CardMentions'
import CardWordc from '../CardWordc'
import { CHART_MOCK } from '../ListeningCity/mock/CHART_MOCK'
import { getPestalMentions } from '../services/mentionsServices'
import { Political } from '../services/types/mentionsServiceTypes'
import * as S from './styles'

export const ListeningSwot = ({ activeTab }: any) => {
  const [allMentions, setAllMentions] = useState<Political[] | null>(null)

  const state = {
    series: CHART_MOCK.series,
    options: {
      chart: {
        id: 'basic-bar',
      },
      colors: ['#00E23F', '#FF0000', '#FFFFFF'],
      xaxis: {
        categories: CHART_MOCK.dates,
      },
    },
  }

  const fetchMentions = async () => {
    const mentions = await getPestalMentions()
    if ('message' in mentions) {
      toast.error(mentions.message)
    } else {
      setAllMentions(mentions)
    }
  }

  useEffect(() => {
    fetchMentions()
  }, [activeTab])

  if (activeTab !== 'tab_political_listening_swot') return null

  return (
    <S.Wrapper>
      {allMentions &&
        (allMentions || []).map(mention => (
          <S.Section key={mention.name}>
            <S.Heading>{mention.name} (S)</S.Heading>
            <S.BoxList>
              <S.Box>
                <CardMentions label="Análise de sentimento">
                  <AreaChart options={state.options} series={state.series} />
                </CardMentions>
              </S.Box>
              <S.Box>
                <CardMentions label="Menções - Forças" filter>
                  {' '}
                  <CardWordc />
                </CardMentions>
              </S.Box>
              <S.Box>
                <CardMentions label="Menções - Fraquezas" filter>
                  <CardWordc />
                </CardMentions>
              </S.Box>
            </S.BoxList>
          </S.Section>
        ))}
    </S.Wrapper>
  )
}
