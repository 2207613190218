import React, { createContext, useContext, useState, ReactNode } from 'react'

interface PersonData {
  id: number
  name: string
  email: string
  phone: string
  social_security_number: string
  date_of_birth: string
  target_position: string | null
  target_year: number | null
  target_locality: string | null
  is_pep: boolean
  is_registered: boolean
  has_government_occupation: boolean
  created: string
  updated: string
  user: number
  profile: number
  plan: string | null
  id_cand?: string
  image_url?: string
}

interface UserData {
  id: number
  is_admin: boolean
  person: PersonData
}

interface UserContextProps {
  user: any | null
  setUser: React.Dispatch<React.SetStateAction<UserData | null>>
}

const UserContext = createContext<UserContextProps | undefined>(undefined)

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserData | any>(false)

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>
}

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext)

  return context as UserContextProps
}
