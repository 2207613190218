import styled from 'styled-components'

interface IContainerProps {
  isClickable?: boolean
}

export const Container = styled.button<IContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  cursor: ${props => (props.isClickable ? 'pointer' : 'default')};

  .cardTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .party-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    background: #ddd;
    img {
      width: 60%;
      height: auto;
    }
  }

  .avatarText {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #0a0a0a;
    color: grey;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    height: 75px;
  }

  .wrapperCard {
    display: flex;
    gap: 16px;
  }

  .card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 120px;
    height: 60px;
    text-align: center;
    border-radius: 8px;
    background: #0a0a0a;
    gap: 8px;
  }
`
