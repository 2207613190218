import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #0a0a0a;
  padding: 16px;
  border-radius: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const ChartContainer = styled.div`
  flex: 1;
`;

export const AdversaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AdversaryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #ff0000;
  font-family: Roboto;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  padding: 16px;
`;
