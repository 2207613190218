import React from 'react'
import styled, { keyframes, css } from 'styled-components'

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 
  40% {
    transform: scale(1.0);
  }
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
`

const LoadingDot = styled.div<{ delay: string }>`
  width: 4px;
  height: 4px;
  background-color: #e9e9e0;
  border-radius: 100%;
  display: inline-block;
  animation: ${bounce} 1.4s infinite ease-in-out both;
  ${({ delay }) => css`
    animation-delay: ${delay};
  `}
`

const LoadingText = styled.div``

const LoadingIndicator: React.FC = () => {
  return (
    <LoadingContainer>
      <LoadingDot delay="0s" />
      <LoadingDot delay="0.2s" />
      <LoadingDot delay="0.4s" />
    </LoadingContainer>
  )
}

export default LoadingIndicator
