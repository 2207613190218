import { motion } from 'framer-motion'
import { styled } from 'styled-components'

export const Wrapper = styled.main`
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
`

export const Heading = styled.h3`
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
`

export const Text = styled.p`
  font-size: 16px;
  color: #e9e9f0;
  margin-top: 24px;
  margin-bottom: 34px;
`

export const ContentForm = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;

  margin-top: 20px;

  .wrapper_input {
    flex: 1;
    min-width: 40%;
  }
`

export const BoxMessage = styled(motion.div)`
  width: 100%;
  margin-bottom: 16px;
  margin-top: 4px;
`

export const ErrorMessage = styled.span`
  font-size: 14px;
  color: #ff4d4f;
`
