import React, { useEffect, useRef, useState } from 'react'
import Spin from 'componentsNew/Spin'
import WordcloudCand from 'componentsNew/Wordcloud'

import { Empty } from 'componentsNew/Empty'

import { getWordCloud } from '../services/getListeners'
import * as S from './styles'
import useIntersectionObserver from "../../../utils/useIntersectionObserver";

interface CardWordCProps {
  sentiment?: 'positive' | 'negative'
  mentions?: any
  palletColor?: string
  default_mention?: string
  candidate_id?: string
  delayQuery?: boolean
  selectedTopic?: string
  rangeDate?: any
  target_locality?: string
}

const CardWordc = ({
  sentiment = 'positive',
  mentions,
  palletColor,
  default_mention = '',
  candidate_id,
  delayQuery = false,
  selectedTopic,
  rangeDate,
  target_locality,
}: CardWordCProps) => {
  const [loading, setLoading] = useState(false)
  const [wordData, setWordData] = useState<any[]>([])
  const [requesTed, setRequested] = useState(false)
  const listedMentions = mentions?.length > 0 && mentions
  const name_tag = default_mention ? [default_mention] : undefined
  const ref = useRef<HTMLDivElement>(null)

  const isVisible = useIntersectionObserver(ref, {
    rootMargin: '0px',
    threshold: 0.05,
  })

  useEffect(() => {
    if (isVisible && !requesTed) {
      fetchData()
      setRequested(true)
    }
  }, [isVisible])

  useEffect(() => {
    if (!!selectedTopic && requesTed) {
      fetchData()
    }
  }, [selectedTopic, rangeDate])

  useEffect(() => {
    if (!!mentions && requesTed) {
      fetchData()
    }
  }, [mentions])

  const fetchData = async () => {
    setLoading(true)

    const candidatePayload = {
      name_tag,
      mentions: listedMentions,
      sentiment,
      candidate_id,
      parent_name: selectedTopic !== 'all' ? selectedTopic : undefined,
      cd_city: target_locality,
      date_start: rangeDate?.initial_date,
      date_end: rangeDate?.end_date,
    }
    const defaultPayload = {
      name_tag,
      mentions: listedMentions,
      sentiment,
      parent_name: selectedTopic !== 'all' ? selectedTopic : undefined,
      cd_city: target_locality,
      date_start: rangeDate?.initial_date,
      date_end: rangeDate?.end_date,
    }
    try {
      const responseMentions = await getWordCloud(candidate_id ? candidatePayload : defaultPayload)
      if (!('message' in responseMentions)) {
        const result = responseMentions?.map((item: any) => ({ text: item.word, value: item.value }))
        setWordData(result)
      }
    } catch (error) {
      console.error('Erro na requisição:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <S.Container ref={ref}>
      {loading && <Spin spinning />}

      {!loading && <>{wordData.length > 0 ? <WordcloudCand words={wordData} width={320} height={150} /> : <Empty />}</>}
    </S.Container>
  )
}

export default CardWordc
