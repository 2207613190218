import * as React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { useSidBar } from 'hooks/useSideBar'
import { useMenuTitle } from 'hooks/useMenuTitleContext'
import { HeaderContainer, Title, TitleText, LogoHeaderImage } from './styles'
import logoHeader from '../../images/logo_header.png'

interface HeaderProps {
  activeSubmenu?: string
}

const Header: React.FC<HeaderProps> = ({ activeSubmenu }) => {
  const location = useLocation()
  const { open, titleMenu } = useSidBar()
  const { menuTitle, updateMenuTitle } = useMenuTitle()

  useEffect(() => {
    let newTitle = titleMenu

    switch (true) {
      case location.pathname.includes('/dashboard/electoral-profile/wizard'):
        newTitle = 'Wizard Eleitoral'
        break
      case location.pathname.includes('/dashboard/acquisitions/signature'):
        newTitle = 'Aquisições'
        break
      case location.pathname.includes('/dashboard/settings/profile'):
        newTitle = 'Configurações'
        break
      default:
        break
    }

    updateMenuTitle(newTitle)
  }, [location.pathname, titleMenu, updateMenuTitle])

  return (
    <HeaderContainer open={open}>
      <Title>
        <FontAwesomeIcon
          icon={faBell}
          style={{ fontSize: '1.2rem', color: 'white', marginRight: '1.25rem', marginLeft: '1.25rem' }}
        />
        <TitleText>{menuTitle}</TitleText>
      </Title>
      <LogoHeaderImage src={logoHeader} alt="CANDIDATTUS" />
    </HeaderContainer>
  )
}

export default Header
