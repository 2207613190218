import React, { useState } from 'react'
import { Slider } from '@mui/material'
import { Container, MarkColor } from './styles'
import Text from '../Text'

interface InputRangeColorProps {
  color: 'red' | 'blue' | 'cyan'
  title: string
  slug: string
  value: number
  handleChange: (value: any, key: any) => void
}

const InputRangeColor: React.FC<InputRangeColorProps> = ({ color, title, slug, value, handleChange }) => {
  const [dataValue, setValue] = useState(value)
  const [sliderValue, setSliderValue] = useState<number | number[]>(value)

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number)
    handleChange(newValue, slug)
    setSliderValue(newValue)
  }

  const colorMap: { [key: string]: string } = {
    red: '#FF0000',
    blue: '#0000FF',
    cyan: '#155780',
  }

  const sliderColor = colorMap[color]

  return (
    <Container>
      <MarkColor color={sliderColor} />
      <div className="text">
        <Text size="xSmall">{title}</Text>
      </div>
      <Slider
        sx={{
          marginRight: '0.5rem',
          color: sliderColor,
        }}
        value={dataValue}
        onChange={handleSliderChange}
        defaultValue={dataValue}
        aria-label={title}
        valueLabelDisplay="off"
        autoFocus={false}
      />
      <Text size="xSmall" bold className="percent-text">
        {`${dataValue}%`}
      </Text>
    </Container>
  )
}

export default InputRangeColor
