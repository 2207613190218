interface IState {
  cd_uf: number
  nm_uf: string
  uf: string
}

export const statesData: IState[] = [
  { cd_uf: 12, nm_uf: 'Acre', uf: 'AC' },
  { cd_uf: 27, nm_uf: 'Alagoas', uf: 'AL' },
  { cd_uf: 16, nm_uf: 'Amapá', uf: 'AP' },
  { cd_uf: 13, nm_uf: 'Amazonas', uf: 'AM' },
  { cd_uf: 29, nm_uf: 'Bahia', uf: 'BA' },
  { cd_uf: 23, nm_uf: 'Ceará', uf: 'CE' },
  { cd_uf: 53, nm_uf: 'Distrito Federal', uf: 'DF' },
  { cd_uf: 32, nm_uf: 'Espírito Santo', uf: 'ES' },
  { cd_uf: 52, nm_uf: 'Goiás', uf: 'GO' },
  { cd_uf: 21, nm_uf: 'Maranhão', uf: 'MA' },
  { cd_uf: 51, nm_uf: 'Mato Grosso', uf: 'MT' },
  { cd_uf: 50, nm_uf: 'Mato Grosso do Sul', uf: 'MS' },
  { cd_uf: 31, nm_uf: 'Minas Gerais', uf: 'MG' },
  { cd_uf: 15, nm_uf: 'Pará', uf: 'PA' },
  { cd_uf: 25, nm_uf: 'Paraíba', uf: 'PB' },
  { cd_uf: 41, nm_uf: 'Paraná', uf: 'PR' },
  { cd_uf: 26, nm_uf: 'Pernambuco', uf: 'PE' },
  { cd_uf: 22, nm_uf: 'Piauí', uf: 'PI' },
  { cd_uf: 33, nm_uf: 'Rio de Janeiro', uf: 'RJ' },
  { cd_uf: 24, nm_uf: 'Rio Grande do Norte', uf: 'RN' },
  { cd_uf: 43, nm_uf: 'Rio Grande do Sul', uf: 'RS' },
  { cd_uf: 11, nm_uf: 'Rondônia', uf: 'RO' },
  { cd_uf: 14, nm_uf: 'Roraima', uf: 'RR' },
  { cd_uf: 42, nm_uf: 'Santa Catarina', uf: 'SC' },
  { cd_uf: 35, nm_uf: 'São Paulo', uf: 'SP' },
  { cd_uf: 28, nm_uf: 'Sergipe', uf: 'SE' },
  { cd_uf: 17, nm_uf: 'Tocantins', uf: 'TO' },
  { cd_uf: 1, nm_uf: 'Desconhecido', uf: 'XX' },
]
