import React from 'react'
import Card from '../../../../componentsNew/Card'
import Text from '../../../../componentsNew/Text'
import { Container } from './styles'
import ProgressMinimal from '../../../../componentsNew/ProgressMinimal'

const CardAdherenceToTheArchetype = ({ archetype, city }: any) => {
  const adherenceScore = archetype?.adherence_score ? archetype.adherence_score.toFixed(0) : '0'
  return (
    <Container>
      <Card title="Aderência ao arquétipo">
        <div className="wrapperAdherence">
          <Text size="xlarge" bold color="white">
            {city}
          </Text>
          <div className="barra">
            <ProgressMinimal value={archetype?.adherence_score} size="large" />
            <Text size="xlarge" bold color="white" className="adherence-porcent">
              {adherenceScore}%
            </Text>
          </div>
        </div>
      </Card>
    </Container>
  )
}

export default CardAdherenceToTheArchetype
