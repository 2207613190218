import { styled } from 'styled-components'

export const Wrapper = styled.main``

export const ContentFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const BoxBars = styled.div`
  width: calc(100% / 2);
`

export const ActionsBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-bottom: 4px;

  button {
    padding: 6px;
    opacity: 0.7;
    &:hover {
      transform: none;
      opacity: 1;
    }
  }
`
