import { styled } from 'styled-components'

export const Wrapper = styled.main`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 12px 0;
  margin-bottom: 24px;
`

export const Title = styled.h3`
  font-size: 24px;
  color: #e9e9f0;
  width: fit-content;
  margin: 0 5%;
`

export const Divider = styled.div`
  flex: 1;
  background: #e9e9f0;
  height: 3px;
`
