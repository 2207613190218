import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  background-color: #0a0a0a;
  padding: 16px;
  border-radius: 8px;
`;

export const AvatarContainer = styled.div`
  position: absolute;
  margin-top: 16px;
`;

export const AvatarWrapper = styled.div`
  position: absolute;
  left: 0;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #fff;
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  flex: 0.8;
  background-color: #0a0a0a;
  margin-left: 32px;
`;

export const AdversaryContainer = styled.div`
  display: flex;
  flex: 0.2;
  width: 100%;
  background-color: transparent;
  margin-top: 24px;
`;

export const AdversaryList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  padding-left: 16px;
  background-color: transparent;
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #ff0000;
  font-family: Roboto;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  padding: 16px;
`;
