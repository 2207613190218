import styled from 'styled-components'

export const ContainerMaster = styled.div<any>`
  background: ${({ index }) => (index % 2 ? '#1D1D1D' : '#111111')};
  width: 100%;
  height: auto;

  .contante {
    height: 100%;
    padding: 8px;
  }
`
export const Container = styled.div<any>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 28px;
  overflow: hidden;
  padding: 8px;
  border-top: ${({ isActive }) => isActive && '1px solid #ffc000'};
  border-bottom: ${({ isActive }) => isActive && '1px solid #2E2E2E'};
  cursor: pointer;
`
export const Divisor = styled.div<any>`
  width: 8px;
  height: 100%;
  background: ${({ isActive }) => isActive && '#ffc000'};
`

export const Wrapper = styled.div<any>`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 8px 8px;
  justify-content: space-between;
  align-items: center;

  .card-title {
    width: 192px;
  }

  .card-text {
    display: flex;
    width: 300px;
    gap: 100px;
  }
  .card-items {
    display: flex;
    flex: 1;
  }

  svg {
    cursor: pointer;
    transform: ${({ isLink, isActive }) => !isLink && `rotate(${isActive ? 0 : 180}deg)`};
    transition: transform 0.3s ease;
    color: #ffc000;
  }
`
