import React, { useEffect, useState } from 'react'
import Spin from 'componentsNew/Spin'
import Card from '../../../../componentsNew/Card'
import { Container, Wrapper } from './styles'
import TableRowTop from '../TableRowTop'
import { apiPredeterminedCandidate } from '../../../../services/apiService'
import { useCandidates } from '../../../../hooks/useCandidates'

const TopThemes = () => {
  const [loading, setLoading] = useState(true)
  const [themes, setThemes] = useState([])
  const { selectedCandidate } = useCandidates()
  const target_locality = selectedCandidate?.locality || 'BRA.SP.Sao Paulo'
  const getThemes = async () => {
    setLoading(true)
    try {
      const { data } = await apiPredeterminedCandidate.get(`/relevant-themes/?city=${target_locality}`)
      setThemes(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getThemes()
  }, [selectedCandidate])
  return (
    <Container>
      <Card title="TOP 5 temas">
        <Wrapper>
          {loading ? (
            <Spin spinning />
          ) : (
            themes?.map((currentThemes: any, index) => {
              return (
                <TableRowTop
                  service={currentThemes.topic}
                  value={currentThemes.total_count}
                  progress={Math.ceil(currentThemes.percentual)}
                  index={index}
                />
              )
            })
          )}
        </Wrapper>
      </Card>
    </Container>
  )
}

export default TopThemes
