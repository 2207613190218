import React, { useEffect, useState } from "react";
import { Container, Wrapper } from "../../styles";
import Card from "../../../../componentsNew/Card";
import CardMentions from "../../../../componentsNew/CardMentions";
import { apiPredeterminedCandidate } from "../../../../services/apiService";
import {
  formatDateTime,
  handleServiceColor,
  iconRedMens,
} from "../../../../utils/utilityFunctions";
import Spin from "../../../../componentsNew/Spin";
import { useCandidates } from "../../../../hooks/useCandidates";

const Mentions = () => {
  const [loadingTop, setLoadingTop] = useState(true);
  const [loadingMentionsOne, setLoadingMentionsOne] = useState(true);
  const [loadingMentionsTwo, setLoadingMentionsTwo] = useState(true);
  const [topMentions, setTopMentions] = useState([]);
  const [topMentionsOne, setTopMentionsOne] = useState([]);
  const [topMentionsTwo, setTopMentionsTwo] = useState([]);
  const { selectedCandidate } = useCandidates();
  const target_locality = selectedCandidate?.locality || "BRA.SP.Sao Paulo";

  const renderMentions = (mentions: any) => {
    return mentions.map((mention: any, index: any) => {
      const service = mention?.parent_name;
      const serviceData = handleServiceColor(service);

      return (
        <CardMentions
          key={index}
          color={serviceData?.color || "defaultColor"}
          iconSocial={iconRedMens(mention.page_type_name).icon}
          message={mention.full_text}
          user={mention.author}
          dataCreate={formatDateTime(mention.added)}
          iconServices={serviceData?.icon}
          fullWidth
          url={mention?.url}
        />
      );
    });
  };

  const getTopMentions = async () => {
    setLoadingTop(true);
    try {
      const { data } = await apiPredeterminedCandidate.get(
        `/top-mentions/?city=${target_locality}`,
      );
      setTopMentions(data.slice(0, 6));
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingTop(false);
    }
  };
  const getTopMentionsOne = async () => {
    setLoadingMentionsOne(true);
    try {
      const { data } = await apiPredeterminedCandidate.get(
        `/top-mentions?topic_lvl=1&city=${target_locality}`,
      );
      setTopMentionsOne(data.slice(6, 12));
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingMentionsOne(false);
    }
  };
  const getTopMentionsTwo = async () => {
    setLoadingMentionsTwo(true);
    try {
      const { data } = await apiPredeterminedCandidate.get(
        `/top-mentions?topic_lvl=2&city=${target_locality}`,
      );
      setTopMentionsTwo(data.slice(12, -1));
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingMentionsTwo(false);
    }
  };

  useEffect(() => {
    getTopMentions();
    getTopMentionsOne();
    getTopMentionsTwo();
  }, []);

  return (
    <Container>
      <div className="card-scroll">
        <Card title="Menções - Eleitores">
          <div className="constioner-card">
            {loadingTop ? (
              <Spin spinning />
            ) : (
              <Wrapper>{renderMentions(topMentions)}</Wrapper>
            )}
          </div>
        </Card>
      </div>
      <div className="card-scroll">
        <Card title="Menções - Serviços">
          <div className="constioner-card">
            {loadingMentionsOne ? (
              <Spin spinning />
            ) : (
              <Wrapper>{renderMentions(topMentionsOne)}</Wrapper>
            )}
          </div>
        </Card>
      </div>
      <div className="card-scroll">
        <Card title="Menções - Temas">
          <div className="constioner-card">
            {loadingMentionsTwo ? (
              <Spin spinning />
            ) : (
              <Wrapper>{renderMentions(topMentionsTwo)}</Wrapper>
            )}
          </div>
        </Card>
      </div>
    </Container>
  );
};

export default Mentions;
