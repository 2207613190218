import { useState, useEffect } from "react";
import { UseFormReset } from "react-hook-form";
import { apiAdvisor } from "pages/SettingsProfile/api/apiService";
import {
  ISocialLinkData,
  IAdvisorData,
} from "pages/SettingsProfile/types/typesConfig";

const POSSIBLE_SOCIAL_PLATFORMS = [
  "LINKEDIN",
  "YOUTUBE",
  "CALENDLY",
  "X",
  "INSTAGRAM",
  "FACEBOOK",
];

const useSocialLinksData = (
  idAdvisor: string,
  reset: UseFormReset<IAdvisorData>,
) => {
  const [socialLinksData, setSocialLinksData] = useState<ISocialLinkData[]>(
    POSSIBLE_SOCIAL_PLATFORMS.map((platform) => ({
      platform,
      url: "",
    })),
  );

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const response = await apiAdvisor.get(
          `/advisor/v1/advisors/${idAdvisor}`,
        );
        const existingLinks = response.data.links;

        const mergedLinks = POSSIBLE_SOCIAL_PLATFORMS.map((platform) => {
          const existingLink = existingLinks.find(
            (link: any) => link.platform === platform,
          );
          return {
            platform,
            url: existingLink ? existingLink.url : "",
          };
        });

        setSocialLinksData(mergedLinks);
        reset({ links: mergedLinks });
      } catch (error) {
        console.error("Failed to fetch social links data:", error);
      }
    };

    fetchSocialLinks();
  }, [idAdvisor, reset]);

  const updateSocialLinks = async (data: IAdvisorData) => {
    try {
      const links = data.links.map(({ platform, url }) => ({ platform, url }));
      await apiAdvisor.put(`/advisor/v1/advisor/${idAdvisor}/link/`, links);
      setSocialLinksData(links);
    } catch (error) {
      console.error("Failed to update social links data:", error);
    }
  };

  return { socialLinksData, updateSocialLinks };
};

export default useSocialLinksData;
