import styled from 'styled-components'

export const Wrapper = styled.main``

export const WrapperSelection = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  justify-content: space-between;
  position: relative;
  height: 168px;
  column-gap: 8px;
  padding: 0 8px;
  align-items: flex-start;

  @media screen and (max-width: 1190px) {
    max-width: 517px;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
    flex-direction: column;
    height: unset;
    flex-wrap: unset;
    gap: 16px;
    max-width: unset;
    min-width: unset;
  }
`

export const Heading = styled.h3`
  color: #e9e9f0;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  svg {
    margin-top: 4px;
  }
`

export const SelectionBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 650px) {
    margin-bottom: 6px;
    width: 100%;
  }
`

export const SwitchBox = styled.div`
  width: 28px;
`

export const Separator = styled.div`
  height: 90%;
  width: 2px;
  position: absolute;
  background: #494949;
  top: 0;
  left: 50%;
  right: 50%;

  @media screen and (max-width: 650px) {
    display: none;
  }
`

export const IconBox = styled.div`
  position: absolute;

  &.rightIcon {
    right: 1.7%;
    top: -19%;
  }

  &.leftIcon {
    transform: translate(205px, -29px);
  }

  @media screen and (max-width: 650px) {
    &.rightIcon {
      transform: unset;
      top: -9%;
      right: 10px;
    }

    &.leftIcon {
      display: none;
    }
  }
`

export const Icon = styled.img``

export const DisabledBox = styled.div``
