import { styled } from 'styled-components'
import theme from 'styles/theme'

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  p {
    color: ${theme.COLORS.gray10};
    font-size: 16px;
    font-weight: 700;
  }
`

export const ContentActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
  margin-top: 25px;

  button {
    padding: 4px 20px;

    &:hover {
      transform: scale(1);
    }
  }
`
