import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledCard = styled.div<{ isDropdownActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: #161617;
  width: 100%;
  height: ${({ isDropdownActive }) => (isDropdownActive ? "auto" : "56px")};
  overflow: hidden;
  transition: height 0.3s ease;
`;

export const DropdownHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  cursor: pointer;
`;

export const HeaderText = styled.div`
  display: flex;
  align-items: end;
  gap: 6px;
`;

export const DropdownToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-left: 8px;
    width: 16px;
    height: 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #161617;
`;

export const FiltersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
`;

export const CandidateListHeader = styled.div`
  display: flex;
  flex: 45%;
  background-color: #0a0a0a;
  min-height: 200px;
  border-radius: 8px;
  padding: 8px;
  flex-direction: column;
`;

export const AdversaryList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 16px;
  gap: 8px;
`;

export const SearchToolsHeader = styled.div`
  display: flex;
  width: 100%;
  flex: 25%;
  background-color: #0a0a0a;
  min-height: 200px;
  border-radius: 8px;
  padding: 8px;
  flex-direction: column;
`;

export const SocialMediaListHeader = styled.div`
  display: flex;
  width: 100%;
  flex: 30%;
  background-color: #0a0a0a;
  min-height: 200px;
  border-radius: 8px;
  padding: 8px;
  flex-direction: column;
`;

export const SocialNetworkSelection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-self: center;
  justify-self: center;
  margin-top: 16px;
  gap: 8px;
`;

export const TimeListHeader = styled.div`
  display: flex;
  flex: 20%;
  background-color: #0a0a0a;
  min-height: 200px;
  border-radius: 8px;
  padding: 8px;
`;

export const TimeSelection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  margin-left: -16px;
`;

export const TimeOption = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ isSelected }) => (isSelected ? "#FFC000" : "#FFF")};

  & > * {
    margin-right: 8px;
  }

  &:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #ffc000;
    background-color: ${({ isSelected }) => (isSelected ? "#FFC000" : "none")};
    margin-right: 8px;
  }
`;

export const SentimentAnalysisContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #0a0a0a;
  padding: 8px;
  margin: 8px;
  border-radius: 16px;
`;

export const SentimentAnalysisContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
`;

export const VolumeOfMentionsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
`;

export const EvolutionAverageContainer = styled.div`
  margin: 20px 0;
  background-color: #0a0a0a;
  margin: 8px;
  padding: 8px;
  border-radius: 10px;
`;

export const ShareOfVoiceContainer = styled.div`
  margin: 20px 0;
  background-color: #0a0a0a;
  margin: 8px;
  padding: 8px;
  border-radius: 10px;
`;

export const ShareOfVoiceContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
`;

export const GoogleTrendsContainer = styled.div`
  margin: 20px 0;
  background-color: #0a0a0a;
  margin: 8px;
  padding: 8px;
  border-radius: 10px;
`;

export const GoogleTrendsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GoogleTrendsTitleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const GoogleTrendsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
`;

export const ButtonGroup = styled.div`
  display: flex;
  border-radius: 16px;
  background-color: transparent;
`;

export const FilterButtonLeft = styled.button<{ isActive: boolean }>`
  width: 60px;
  padding: 2px 8px;
  border: solid 1px #ffc000;
  border-radius: 16px 0 0 16px;
  background-color: ${({ isActive }) => (isActive ? "#FFC000" : "#161617")};
  color: ${({ isActive }) => (isActive ? "#000" : "#FFC000")};
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #ffc000;
    color: #000;
  }
`;

export const FilterButtonRight = styled.button<{ isActive: boolean }>`
  width: 60px;
  padding: 2px 8px;
  border: solid 1px #ffc000;
  border-radius: 0 16px 16px 0;
  background-color: ${({ isActive }) => (isActive ? "#FFC000" : "#161617")};
  color: ${({ isActive }) => (isActive ? "#000" : "#FFC000")};
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #ffc000;
    color: #000;
  }
`;

export const TimeDropdown = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  justify-self: self-start;
  align-self: self-start;
  margin-top: 16px;
  margin-left: -64px;
  background-color: #161617;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  padding: 8px;
  ${TimeDropdown}:hover & {
    display: block;
  }
`;

export const DropdownItem = styled.div`
  color: white;
  padding: 8px 16px;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: #0a0a0a;
    border-radius: 8px;
  }
`;

export const ChartTypeSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 16px;
`;

export const HeaderVolumeOfMentions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SwitchOption = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #eaeae0;
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};

  &:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #ffc000;
    background-color: ${({ isActive }) =>
      isActive ? "#FFC000" : "transparent"};
    margin-right: 4px;
  }

  &:hover {
    text-decoration: none;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #ff0000;
  font-family: Roboto;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  padding: 16px;
`;
