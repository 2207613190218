import React from "react";
import { RiTimerLine } from "react-icons/ri";

const TimerDisplay = ({ time }: any) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        gap: "4px",
        margin: "4px 0",
      }}
    >
      {time && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#00E23F",
            padding: "5px 10px",
            borderRadius: "48px",
            height: "24px",
          }}
        >
          <span
            role="img"
            aria-label="timer"
            style={{ marginRight: "2px", paddingTop: "4px" }}
          >
            <RiTimerLine size={16} color="black" className="font-bold" />
          </span>
          <span style={{ color: "#000000", fontWeight: "700" }}>
            {time ? time : ""}
          </span>
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#00E23F",
          padding: "2px 4px",
          borderRadius: "50%",
          height: "24px",
          width: "24px",
        }}
      >
        <RiTimerLine size={16} color="black" />
      </div>
    </div>
  );
};

export default TimerDisplay;
