import { useState, useEffect } from "react";
import { apiAdvisor } from "pages/SettingsProfile/api/apiService";

const useProfileImage = (userId: string) => {
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        setLoading(true);
        const response = await apiAdvisor.get(`/core/api/user/${userId}/photo`);
        const imageUrl = response.data?.image_url || null;
        setProfileImage(imageUrl);
      } catch (error) {
        console.error("Failed to fetch profile image:", error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchProfileImage();
    }
  }, [userId]);

  const updateProfileImage = async (base64Image: string) => {
    try {
      setLoading(true);
      const data = { file_in_base64: base64Image };
      await apiAdvisor.put(`/core/api/user/${userId}/photo`, data);
      setProfileImage(`data:image/png;base64,${base64Image}`);
    } catch (error) {
      console.error("Failed to update profile image:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteProfileImage = async () => {
    try {
      setLoading(true);
      const data = { file_in_base64: "" };
      await apiAdvisor.put(`/core/api/user/${userId}/photo`, data);
      setProfileImage(null);
    } catch (error) {
      console.error("Failed to delete profile image:", error);
    } finally {
      setLoading(false);
    }
  };

  return { profileImage, updateProfileImage, deleteProfileImage, loading };
};

export default useProfileImage;
