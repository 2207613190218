import AreaChart from 'componentsNew/AreaChart'
import { Empty } from 'componentsNew/Empty'
import { getAvatar } from 'componentsNew/ElectionStrategyComponents/services/allyServices'
import { useFilterGlobal } from 'hooks/useFilterGlobal'
import { useEffect, useState } from 'react'
import { CardMentions } from '../CardMentions'
import CardWordc from '../CardWordc'

import { CHART_MOCK } from '../ListeningCity/mock/CHART_MOCK'
import { ProfileViewer } from '../ProfileViewer'
import { getSentiment } from '../services/getListeners'
import * as S from './styles'

const defaultData = '2024-01-01'

interface CardDiagnosisTypes {
  topic?: string
  filters?: any[]
  profile_candidate?: any
  topicIcon?: string
  target_locality?: string
}

const state = {
  series: CHART_MOCK.series,
  options: {
    chart: {
      id: 'basic-bar',
    },
    colors: ['#00E23F', '#FF0000', '#FFFFFF'],
    xaxis: {
      categories: [] /* CHART_MOCK.dates */,
    },
  },
}

const CardDiagnosis = ({ topic, filters = [], profile_candidate, topicIcon, target_locality }: CardDiagnosisTypes) => {
  const positiveFilters = filters?.filter((item: any) => item?.is_positive)
  const negativeFilters = filters?.filter((item: any) => !item?.is_positive)
  const { filterMentions, rangeDate, filterRaioX } = useFilterGlobal()
  const [feeling, setFeeling] = useState(state)
  const [avatar, setAvatar] = useState<string | null>(null)
  const topicMention = filterMentions || !profile_candidate?.candidate_id ? [topic || ''] : undefined

  useEffect(() => {
    fetchAvatar()
  }, [])

  useEffect(() => {
    /*  if (feeling?.options.xaxis.categories?.length === 0) {
      fetchFeeling()
    } */
    fetchFeeling()
  }, [rangeDate, filterMentions, filterRaioX])

  console.log('topicMention', target_locality)

  const fetchFeeling = async () => {
    const params = {
      date_start: rangeDate.initial_date,
      date_end: rangeDate.end_date,
      candidate_id: profile_candidate?.candidate_id,
      parent_name: filterMentions !== 'all' && filterMentions,
      sentiment: filterRaioX !== 'all' && filterRaioX,
      ...(topicMention && topicMention.length > 0 && topicMention[0] !== '' && { name_tag: topicMention }),
      ...(target_locality && target_locality !== undefined && target_locality !== '' && { cd_city: target_locality }),
    }

    const res = await getSentiment(params)

    if (!('message' in res)) {
      setFeeling(prev => ({
        ...prev,

        series: res.series,
        options: {
          ...prev.options,
          xaxis: {
            categories: res.dates,
          },
        },
      }))
    }
  }

  const fetchAvatar = async () => {
    const avatar = await getAvatar(profile_candidate?.candidate_id)
    if ('url' in avatar) {
      setAvatar(avatar.url)
    }
  }

  return (
    <S.Section>
      {profile_candidate?.candidate_id ? (
        <ProfileViewer
          cand_id={profile_candidate?.candidate_id}
          cand_name={profile_candidate?.candidate_name}
          party={profile_candidate?.candidate_party}
          avatar={avatar || undefined}
          topicIcon={topicIcon}
        />
      ) : (
        <S.Heading>{topic}</S.Heading>
      )}
      <S.BoxList>
        <S.Box>
          <CardMentions
            label="Analise de sentimento"
            options={[]}
            default_mentions={!profile_candidate?.candidate_id ? topicMention : null}
            component={mentions =>
              feeling?.options.xaxis.categories?.length ? (
                <AreaChart options={feeling.options} series={feeling.series} />
              ) : (
                <Empty />
              )
            }
          />
        </S.Box>
        <S.Box>
          <CardMentions
            label="Menções - Positivas"
            filter
            options={positiveFilters}
            default_mentions={!profile_candidate?.candidate_id ? topicMention : undefined}
            component={mentions => (
              <CardWordc
                rangeDate={rangeDate}
                selectedTopic={filterMentions}
                mentions={!profile_candidate?.candidate_id ? mentions : null}
                default_mention={!profile_candidate?.candidate_id && topic ? topic : ''}
                sentiment="positive"
                candidate_id={profile_candidate?.candidate_id}
                target_locality={target_locality}
              />
            )}
          />
        </S.Box>
        <S.Box>
          <CardMentions
            label="Menções - Negativas"
            filter
            options={negativeFilters}
            default_mentions={!profile_candidate?.candidate_id ? topicMention : undefined}
            component={mentions => (
              <CardWordc
                rangeDate={rangeDate}
                selectedTopic={filterMentions}
                mentions={!profile_candidate?.candidate_id ? mentions : null}
                default_mention={!profile_candidate?.candidate_id && topic ? topic : ''}
                sentiment="negative"
                candidate_id={profile_candidate?.candidate_id}
                target_locality={target_locality}
              />
            )}
          />
        </S.Box>
      </S.BoxList>
    </S.Section>
  )
}

export default CardDiagnosis
