import React, { useState } from "react";
import { RiPencilLine } from "react-icons/ri";
import { IoMdCheckmark } from "react-icons/io";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Card, Button } from "antd";
import { TextField } from "@mui/material";
import useSocialLinksData from "pages/SettingsProfile/hooks/useSocialLinksData";
import {
  ISocialLinks,
  IAdvisorData,
} from "pages/SettingsProfile/types/typesConfig";

const SocialLinks: React.FC<ISocialLinks> = ({ idAdvisor }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { control, handleSubmit, reset } = useForm<IAdvisorData>({
    defaultValues: { links: [] },
  });
  const { fields } = useFieldArray({
    control,
    name: "links",
  });
  const { socialLinksData, updateSocialLinks } = useSocialLinksData(
    idAdvisor,
    reset,
  );

  const onSubmit = async (data: IAdvisorData) => {
    await updateSocialLinks(data);
    setIsEditing(false);
  };

  return (
    <Card
      style={{
        width: "50%",
        height: "auto",
        backgroundColor: "#161617",
        border: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          marginBottom: "16px",
        }}
      >
        <div>
          <p
            style={{
              color: "#E0E0E9",
              fontWeight: "500",
              marginTop: "-8px",
            }}
          >
            Redes Sociais
          </p>
        </div>
        <div>
          {isEditing ? (
            <Button
              size="small"
              color="#ffc000"
              htmlType="submit"
              icon={
                <IoMdCheckmark
                  color="#000000"
                  size="12"
                  style={{ marginRight: -4 }}
                />
              }
              style={{
                color: "#000000",
                backgroundColor: "#FFC000",
                borderRadius: 20,
                fontSize: 10,
              }}
              type="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </Button>
          ) : (
            <Button
              size="small"
              icon={
                <RiPencilLine
                  color="#ffc000"
                  size="12"
                  style={{ marginRight: -4 }}
                />
              }
              style={{
                color: "#FFC000",
                backgroundColor: "transparent",
                borderRadius: 20,
                fontSize: 10,
                border: "1px solid #FFC000",
              }}
              type="default"
              onClick={() => setIsEditing(true)}
            >
              Editar
            </Button>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <Controller
            key={field.id}
            name={`links.${index}.url` as const}
            control={control}
            defaultValue={field.url}
            render={({ field }) => {
              const isCalendly =
                socialLinksData[index]?.platform === "CALENDLY";
              const isDisabled = !isEditing || isCalendly;

              return (
                <TextField
                  {...field}
                  label={socialLinksData[index]?.platform || "Link"}
                  multiline
                  variant="outlined"
                  fullWidth
                  disabled={isDisabled}
                  style={{
                    backgroundColor: "transparent",
                    color: "#EAEAE0",
                    marginBottom: "16px",
                    cursor: isDisabled ? "not-allowed" : "default",
                    opacity: isDisabled ? 0.5 : 1,
                  }}
                  InputLabelProps={{
                    style: { color: "#EAEAE0", opacity: isDisabled ? 0.5 : 1 },
                  }}
                  InputProps={{
                    style: {
                      opacity: isDisabled ? 0.5 : 1,
                      cursor: isDisabled ? "not-allowed" : "default",
                    },
                  }}
                />
              );
            }}
          />
        ))}
      </form>
    </Card>
  );
};

export default SocialLinks;
